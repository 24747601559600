import React from 'react';
import Firebase from 'firebase/app';
import { sortBy, shuffle } from 'lodash';
import { useLocation, useHistory } from 'react-router-dom';
import {
  useCollection,
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useUnstated } from '@gitbook/unstated';
import { useTranslation } from 'react-i18next';

import { getDisplayedName } from '../../common';
import groupBy from '../../actions/helpers/groupBy';
import {
  Loader,
  Heading,
  ItemCard,
  ShopCard,
  Button,
  Text,
  HoverableLink,
} from '../../styleguide';
import { LocationIcon } from '../../styleguide/icons';
import { SPACING } from '../../styles/constants';
import { FullPageHorizontalSlider } from '../../styles/common';
import {
  HomePageContentWrapper,
  HomePageCategoryHeader,
  HomePageCategoryWrapper,
  HeaderTitleWrapper,
  SelectedAdressBlock,
  CategoryCard,
  CategoryPatternBackground,
} from '../../styles/home';
import { Page } from '../common';
import { useTheme, useSession } from '../../hooks';
import { getCategoryIcon } from '../../common/CATEGORIES';
import { BasketContainer } from '../../contexts';

const { REACT_APP_SETTINGS_DOC_ID } = process.env;

const HomePage = props => {
  const theme = useTheme();
  let location = useLocation();
  let history = useHistory();
  const basketContainer = useUnstated(BasketContainer);
  const { user } = useSession();
  const { t } = useTranslation(['common', 'home', 'categories']);

  const [shopsValue, shopLoading, shopError] = useCollection(
    Firebase.firestore()
      .collection('shops')
      .where(`isValidated`, '==', true),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );

  const [settings, settingsLoading, settingsError] = useDocumentData(
    Firebase.firestore().doc(`app/${REACT_APP_SETTINGS_DOC_ID}`),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );

  const [
    selectedItemsValue,
    selectedItemsLoading,
    selectedItemsError,
  ] = useCollectionData(
    Firebase.firestore()
      .collection('shopItems')
      .where(`isInWeeklySelection`, '==', true)
      .where('avaibility', '==', true),
    {
      snapshotListenOptions: { includeMetadataChanges: false },
    }
  );

  const shops = React.useMemo(() => {
    if (!!shopsValue && !shopLoading) {
      return shopsValue.docs.map(doc => ({ ...doc.data(), id: doc.id }));
    }
  }, [shopsValue, shopLoading]);

  const groupedShop = React.useMemo(() => {
    if (!!shops && !shopLoading) {
      return groupBy(shops, 'category');
    }
  }, [shops, shopLoading]);

  const shopsPartner = React.useMemo(() => {
    if (!!shops && !shopLoading) {
      return shuffle(shops.filter(shop => !!shop.isPartner));
    } else {
      return [];
    }
  }, [shops, shopLoading]);

  const orderedGroupedShop = React.useMemo(
    () =>
      groupedShop && !shopLoading
        ? shuffle(
            groupedShop.map(group => ({
              ...group,
              items: sortBy(group.items, [
                function(item) {
                  return item.isPartner ? 0 : 1;
                },
              ]),
            }))
          )
        : [],
    [groupedShop, shopLoading]
  );

  const orderedSelectedItemsValue = React.useMemo(
    () =>
      !!selectedItemsValue && !shopLoading ? shuffle(selectedItemsValue) : [],
    [selectedItemsValue, shopLoading]
  );

  const categories = React.useMemo(
    () =>
      !!orderedGroupedShop
        ? orderedGroupedShop.map(group => ({
            name: t(group.category, { ns: 'categories' }),
            icon: getCategoryIcon(group.category),
          }))
        : [],
    [orderedGroupedShop, t]
  );

  if (settingsLoading || shopLoading || selectedItemsLoading) {
    return (
      <Loader color={theme.primaryColor} background={theme.backgroundColor} />
    );
  }

  if (shopError || selectedItemsError || settingsError) {
    if (selectedItemsError) {
      throw new Error(selectedItemsError.toString());
    } else if (settingsError) {
      throw new Error(settingsError.toString());
    } else if (shopError) {
      throw new Error(shopError.toString());
    }
  }

  const onRedirectToShop = shopID => {
    const to =
      location.state && location.state.from.pathname !== '/'
        ? location.state.from
        : { pathname: '/' };
    history.push(`/shop/${shopID}`, { from: to });
  };

  const onClickScrollTo = category => {
    const element = document.getElementById(category);
    const top = element.offsetTop;
    window.scrollTo({
      top: top - 50,
      behavior: 'smooth',
    });
  };

  const { addresses, defaultAddress } = user;
  const userDefaultAddress = addresses.find(el => el.id === defaultAddress);
  const selectedAdress =
    basketContainer.state.selectedAddress || userDefaultAddress;

  return (
    <Page name="Bienvenue" withoutSpacing>
      <HeaderTitleWrapper theme={theme} style={{ paddingTop: 0, marginTop: 0 }}>
        <Heading size={800} color={theme.primaryColor}>
          {t('welcome', { ns: 'home', user: getDisplayedName(user) })}
        </Heading>
        {!!selectedAdress && (
          <HoverableLink
            underline={false}
            to={{
              pathname: `/account/adresses`,
            }}
          >
            <SelectedAdressBlock theme={theme} marginTop={'0px'}>
              <div>
                <LocationIcon
                  margin={`0 0 0 ${SPACING[100]}`}
                  size="16px"
                  color={theme.headingColor}
                />
              </div>

              <Text
                size={300}
                className="selected-adress-label"
                style={{
                  color: theme.headingColor,
                  textAlign: 'center',
                  marginRight: SPACING[100],
                  textDecoration: 'none',
                }}
              >
                {selectedAdress.name
                  ? selectedAdress.name
                  : `${selectedAdress.street}, ${selectedAdress.city}`}
              </Text>
            </SelectedAdressBlock>
          </HoverableLink>
        )}
      </HeaderTitleWrapper>

      {!settings?.open ? (
        <Alert
          severity="info"
          style={{ margin: `${SPACING[300]} ${SPACING[200]}`, marginBottom: 0 }}
          variant="outlined"
        >
          <AlertTitle>
            {t('opening_alert.title')} {`❌`}
          </AlertTitle>
          {t('opening_alert.message')}
        </Alert>
      ) : null}

      {!!settings?.strongPresence && !!settings?.open ? (
        <Alert
          severity="warning"
          style={{ margin: `${SPACING[300]} ${SPACING[200]}`, marginBottom: 0 }}
          variant="outlined"
        >
          <AlertTitle>
            {t('strong_presence_alert.title')} {`⏱`}
          </AlertTitle>
          {t('strong_presence_alert.message')}
        </Alert>
      ) : null}

      {!!settings?.customMessage ? (
        <Alert
          severity={settings?.customMessage?.severity || 'info'}
          style={{ margin: `${SPACING[200]} ${SPACING[200]}`, marginBottom: 0 }}
          variant="outlined"
        >
          <AlertTitle>{settings?.customMessage?.title}</AlertTitle>
          {settings?.customMessage?.content}
        </Alert>
      ) : null}

      <HomePageContentWrapper>
        <FullPageHorizontalSlider
          theme={theme}
          width="100%"
          className="noPadding"
        >
          {categories.map(({ name, icon }, index) => (
            <CategoryCard
              theme={theme}
              key={index}
              onClick={() => onClickScrollTo(name)}
            >
              <CategoryPatternBackground theme={theme}>
                <Heading size={600}>
                  {icon}{' '}
                  <span style={{ margin: '0 4px', fontWeight: 500 }}>
                    {name}
                  </span>{' '}
                </Heading>
              </CategoryPatternBackground>
            </CategoryCard>
          ))}
        </FullPageHorizontalSlider>
        <HomePageCategoryWrapper
          theme={theme}
          withoutBorder={shopsPartner}
          marginBottom={'0px'}
          style={{ padding: 0 }}
        >
          <HomePageCategoryHeader theme={theme}>
            <Heading size={700}>
              {t('must_try', { ns: 'home' })} {'😍'}
            </Heading>
          </HomePageCategoryHeader>
          <FullPageHorizontalSlider
            theme={theme}
            centered={orderedSelectedItemsValue.length <= 1}
            width="100%"
          >
            {orderedSelectedItemsValue.map((item, index) => (
              <ItemCard
                onClick={() => onRedirectToShop(item.shopID)}
                key={item.id}
                item={item}
                styles={{
                  margin: 0,
                  marginRight: SPACING[100],
                  marginLeft:
                    index === 0 && !orderedSelectedItemsValue.length <= 1
                      ? SPACING[200]
                      : 0,
                }}
                truncatedDescription
                actionsChildren={
                  <Button small primary variant="outlined">
                    {t('discover_button', { ns: 'home' })}
                  </Button>
                }
              />
            ))}
          </FullPageHorizontalSlider>
        </HomePageCategoryWrapper>

        {shopsPartner && (
          <HomePageCategoryWrapper
            theme={theme}
            withoutBorder
            marginBottom={'0px'}
            style={{ paddingBottom: 0 }}
          >
            <HomePageCategoryHeader theme={theme}>
              <Heading size={700}>
                {t('favorites', { ns: 'home' })} {'🥇'}
              </Heading>
            </HomePageCategoryHeader>
            <FullPageHorizontalSlider
              theme={theme}
              centered={shopsPartner.length <= 1}
              width="100%"
            >
              {shopsPartner.map((item, index) => (
                <ShopCard
                  key={item.id}
                  shop={item}
                  onClick={() => onRedirectToShop(item.id)}
                  index={index}
                  truncatedDescription
                  styles={{
                    margin: 0,
                    marginRight: shopsPartner.length > 1 ? SPACING[100] : 0,
                    marginLeft:
                      index === 0 && !shopsPartner.length <= 1
                        ? SPACING[200]
                        : 0,
                  }}
                  actionsChildren={
                    <Button small primary variant="outlined">
                      {t('discover_button', { ns: 'home' })}
                    </Button>
                  }
                />
              ))}
            </FullPageHorizontalSlider>
          </HomePageCategoryWrapper>
        )}

        {orderedGroupedShop.map((group, index) => (
          <HomePageCategoryWrapper
            theme={theme}
            key={index}
            withoutBorder
            id={t(group.category, { ns: 'categories' })}
            marginBottom={'0px'}
          >
            <HomePageCategoryHeader theme={theme}>
              <Heading size={700} styles={{ textTransform: 'capitalize' }}>
                {t(group.category, { ns: 'categories' })}{' '}
                {getCategoryIcon(group.category)}
              </Heading>
            </HomePageCategoryHeader>

            <FullPageHorizontalSlider
              theme={theme}
              centered={group.items.length <= 1}
              width="100%"
            >
              {group.items.map((item, index) => (
                <ShopCard
                  key={item.id}
                  shop={item}
                  onClick={() => onRedirectToShop(item.id)}
                  index={index}
                  truncatedDescription
                  styles={{
                    margin: 0,
                    marginRight:
                      group.items.length > 1
                        ? index + 1 === group.items.length
                          ? SPACING[200]
                          : SPACING[100]
                        : 0,
                    marginLeft:
                      group.items.length <= 1
                        ? 0
                        : index === 0
                        ? SPACING[200]
                        : 0,
                  }}
                  actionsChildren={
                    <Button small primary variant="outlined">
                      {t('discover_button', { ns: 'home' })}
                    </Button>
                  }
                />
              ))}
            </FullPageHorizontalSlider>
          </HomePageCategoryWrapper>
        ))}
      </HomePageContentWrapper>
    </Page>
  );
};

export default HomePage;

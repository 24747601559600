import ReactGA from 'react-ga4';

import authenticatedRequest from '../helpers/authenticatedRequest';
import { API } from '../../common';

const cancelPaymentIntent = async ({ paymentIntentID }) => {
  ReactGA.event({
    category: 'Stripe',
    action: 'Cancel payment intent',
  });

  try {
    await authenticatedRequest('post', `${API.stripe.cancelPaymentIntent}`, {
      paymentIntentID,
    });
  } catch (error) {
    console.error('Error during Payment intent cancel', {
      error,
    });
    return {
      error,
    };
  }
};

export default cancelPaymentIntent;

import React from 'react';
import { Text } from 'evergreen-ui';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { useTheme } from '../../hooks';
import { SPACING, colors } from '../../styles/constants';

const ItemWrapper = styled.div`
  width: 240px;
  display: flex;
  align-items: center;
  padding: ${SPACING[200]};
  margin: ${SPACING[100]} auto;
  border: 1px solid
    ${props => (props.isActive ? colors.lightGray : 'transparent')};
  cursor: pointer;
  border-radius: 4px;
  transition: border 200ms;
`;

const StyledText = styled(Text)`
  color: ${props => props.color};
  padding-left: ${SPACING[300]};
  width: 100%;
`;

const NavItem = props => {
  const { name, path, Icon, toggleDrawer, external } = props;
  const [isActive, setActive] = React.useState(false);
  const theme = useTheme();
  const history = useHistory();
  let location = useLocation();

  const onClick = () => {
    setActive(true);
    toggleDrawer('');

    if (external) {
      window.open(path, '_blank');
      return;
    }

    const { pathname } = location;
    if (pathname !== path) {
      history.push(path, { from: { pathname } });
    }
  };

  return (
    <ItemWrapper
      theme={theme}
      role="button"
      onClick={onClick}
      primaryColor={theme.primaryColor}
      isActive={isActive}
      onMouseEnter={() => setActive(true)}
      onTouchStart={() => setActive(true)}
      onMouseLeave={() => setActive(false)}
      onTouchEnd={() => setActive(false)}
    >
      <Icon size="24px" color={theme.primaryColor} />

      <StyledText size={500} color={theme.headingColor}>
        {name}
      </StyledText>
    </ItemWrapper>
  );
};

export default NavItem;

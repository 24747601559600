import ReactGA from 'react-ga4';

import ORDER_STATUS from './helpers/STATUS';
import { cancelPaymentIntent, capturePaymentIntent } from '../stripe';
import cancelDiscountUsage from '../discounts/cancelDiscountUsage';
import { getOrderDb, updateOrderDoc, flagOrder } from './updateOrder';

const finishOrder = async (order, kind, by) => {
  const orderDb = await getOrderDb(order);

  if (!orderDb) {
    throw new Error("❌ Cette commande n'existe pas");
  }

  if (!!orderDb?.inTreatment) {
    return;
  }

  if (orderDb.status === ORDER_STATUS.canceled) {
    throw new Error(
      'Cette commande est deja annulé, vous ne pouvez pas effectuer cette action.'
    );
  }

  if (orderDb.status === ORDER_STATUS.delivered) {
    throw new Error(
      'Cette commande est deja terminé, vous ne pouvez pas effectuer cette action.'
    );
  }

  if (kind === 'CANCELED') {
    ReactGA.event({
      category: 'Order',
      action: `Canceled order by ${by || 'shop'}`,
    });

    await flagOrder(orderDb.id, true);

    const cancelPaymentIntentError = await cancelPaymentIntent({
      paymentIntentID: orderDb.paymentIntentId,
    });

    if (!!cancelPaymentIntentError && !!cancelPaymentIntentError.error) {
      await flagOrder(orderDb.id, false);
      throw new Error(
        "Une erreur est survenue lors de l'annulation du paiement"
      );
    } else {
      await updateOrderDoc({
        ...orderDb,
        canceledAt: Date.now(),
        status: ORDER_STATUS.canceled,
        canceledBy: by || 'shop',
        inTreatment: false,
      });

      if (!!orderDb.discount?.code) {
        await cancelDiscountUsage({
          code: orderDb.discount?.code,
          userID: orderDb.clientID,
        });
      }
    }
  } else {
    ReactGA.event({
      category: 'Order',
      action: `Finished order`,
    });

    await flagOrder(orderDb.id, true);

    const capturePaymentIntentError = await capturePaymentIntent({
      orderID: orderDb.id,
    });

    if (!!capturePaymentIntentError && !!capturePaymentIntentError.error) {
      if (capturePaymentIntentError.payment_intent_status !== 'succeeded') {
        await flagOrder(orderDb.id, false);

        throw new Error(
          "Une erreur est survenue lors de l'annulation du paiement"
        );
      }
    }

    await updateOrderDoc({
      ...orderDb,
      deliverAt: Date.now(),
      status: ORDER_STATUS.delivered,
      inTreatment: false,
    });
  }
};

export default finishOrder;

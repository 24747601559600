import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Chip, Avatar, Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Heading } from '../../../../../styleguide';
import { useTheme } from '../../../../../hooks';
import { SPACING } from '../../../../../styles/constants';
import { computeEstimatedTime } from '../../../../../actions/orders/helpers/computeTimesHelpers';
import {
  StepContentWrapper,
  ItemsWrapper,
  TimelineRow,
  DetailsWrapper,
  RusherRow,
} from '../../../../../styles/order-tracking';
import ReadyIllustration from '../../../../../assets/images/orders/Ready.svg';
import ProductLine from '../../../../basket/ProductLine';
import MenuLine from '../../../../basket/MenuLine';
import {
  Totals,
  PaymentMethodRow,
  IllustrationHeader,
  Header,
  AddressesBlock,
  MessageBlock,
} from './Commons';

const ReadyStep = props => {
  const { order, delayedDelivery } = props;
  const theme = useTheme();
  const { t } = useTranslation(['common', 'orders']);

  const estimatedDeliveryTime = computeEstimatedTime({
    ts: order.readyAt,
    withDelay: true,
    minutes: 0,
    seconds: order.duration,
  });

  return (
    <StepContentWrapper>
      <IllustrationHeader imgSrc={ReadyIllustration} />

      <Header
        order={order}
        title={t('orders:tracking:ready_title')}
        emoji="📦"
      />
      <Divider style={{ width: '100%', marginBottom: `${SPACING[400]}` }} />

      {delayedDelivery ? (
        <Alert severity="warning" style={{ margin: '8px 0px' }}>
          <AlertTitle>
            {t('common:strong_presence_alert:title')} {`⏱`}
          </AlertTitle>
          {t('common:strong_presence_alert:message')}
        </Alert>
      ) : null}

      <TimelineRow>
        <Heading size={500}>
          {t('orders:tracking:estimated_delivery_hours')}{' '}
        </Heading>
        <Heading
          size={900}
          color={theme.primaryColor}
          styles={{ marginTop: SPACING[100] }}
        >
          {estimatedDeliveryTime}
        </Heading>
      </TimelineRow>
      <Divider style={{ width: '100%', margin: `${SPACING[300]}` }} />

      {!!order.rusher ? (
        <RusherRow>
          <Heading size={400} styles={{ marginRight: SPACING[100] }}>
            {t('orders:tracking:deliver_by')}
          </Heading>
          <Chip
            variant="outlined"
            color="primary"
            label={order.rusher.name}
            avatar={<Avatar src={order.rusher.pictureURL} />}
          />
        </RusherRow>
      ) : null}
      <Divider style={{ width: '100%', margin: `${SPACING[300]}` }} />

      <AddressesBlock order={order} />

      <Divider style={{ width: '100%', marginTop: `${SPACING[300]}` }} />

      <ItemsWrapper>
        {order.itemsList.map((shopItem, index) =>
          shopItem.item.isMenu ? (
            <>
              <MenuLine key={index} shopItem={shopItem} small />
              {order.itemsList.length - 1 !== index && (
                <Divider style={{ width: '100%', margin: `${SPACING[100]}` }} />
              )}
            </>
          ) : (
            <>
              <ProductLine key={index} shopItem={shopItem} small />
              {order.itemsList.length - 1 !== index && (
                <Divider style={{ width: '100%', margin: `${SPACING[100]}` }} />
              )}
            </>
          )
        )}
      </ItemsWrapper>
      <Divider style={{ width: '100%', marginBottom: `${SPACING[300]}` }} />

      <MessageBlock order={order} />

      <DetailsWrapper>
        <PaymentMethodRow paymentMethod={order.paymentMethod} />
      </DetailsWrapper>
      <Divider style={{ width: '100%', marginTop: `${SPACING[300]}` }} />

      <Totals order={order} />
      <Divider style={{ width: '100%', margin: `${SPACING[100]}` }} />
    </StepContentWrapper>
  );
};

export default ReadyStep;

import styled from 'styled-components';

import { SPACING, devices } from './constants';

const BlockTemplate = styled.div`
  padding: ${props => props.padding || SPACING[200]};
  background-color: ${props => props.backgroundColor};
  border-radius: 4px;
`;

const BlocksContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: ${SPACING[200]} 0;
  gap: ${SPACING[400]};
`;

const FlexBlocksContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  margin: ${SPACING[200]} 0;
`;

const BlockContentRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
  padding: ${SPACING[200]} 0;
  gap: ${SPACING[100]};
`;

const BlockContentData = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${SPACING[100]};
`;

const BlockWrapper = styled(BlockTemplate)`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  border: 1px solid ${props => props.theme.lightGray};
`;

const UserBlockDataGridWrapper = styled.div`
  width: 100%;
  height: 350px;
`;

const BlockDialogContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const BlockDialogContentRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const HeaderDialogBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${props =>
    props.align === 'end' ? 'flex-start' : 'center'};
  align-items: ${props => (props.align === 'end' ? 'flex-end' : 'flex-start')};
  flex-direction: column;
`;

const HeaderDialogFlexBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const HeaderDialog = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${SPACING[200]};
`;

const BodyBlocksDialog = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: ${SPACING[100]} 0;
`;

const HeaderBodyBlocksDialog = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${SPACING[100]} 0;
`;

const ItemsListDialogHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${SPACING[100]} 0;
  padding: ${SPACING[200]} 0;
`;

const ContentBlockDialog = styled.div`
  display: flex;
  flex-direction: column;
  padding: ${props => props.padding || `${SPACING[200]} ${SPACING[200]}`};
  border-radius: 4px;
  background-color: ${props => props.theme.cardBg};
  box-shadow: ${props => props.theme.boxShadow.md};
  margin: ${SPACING[100]} 4px;
  justify-content: center;
  width: 100%;
  max-width: '100%';

  @media ${devices.tablet} {
    width: fit-content;
    max-width: ${props => props.maxWidth || '100%'};
  }
`;

const RoleDetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${SPACING[200]};
`;

const RoleDetailsButtonWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: ${SPACING[200]};
`;

const ListLoader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${SPACING[300]};
`;

const ItemsListDialogWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
`;

const HeaderRow = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: ${SPACING[100]} 0;
`;

const BlockContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${SPACING[200]};
  flex-direction: column;
  padding: ${SPACING[200]} 0;

  @media ${devices.tablet} {
    flex-direction: row;
  }
`;

const StatsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  gap: ${SPACING[200]};
  padding: ${SPACING[100]} 0;
  flex-direction: row;
  align-items: center;

  @media ${devices.tablet} {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 0;
  }
`;

const StatCard = styled.div`
  width: 146px;
  height: 96px;
  border-radius: 4px;
  padding: ${SPACING[200]};
  background-color: ${props => props.theme.cardBg};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  gap: 8px;
  border: 1px solid ${props => props.theme.lightGray};
`;

const DashboardPageContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: ${SPACING[200]} 0;
`;

const DashboardPageBlockContainer = styled.div`
  width: 100%;
  height: ${props => props.height || '100vh'};
  padding: ${SPACING[300]} 0;
`;

const BlockStatsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${SPACING[100]};
  gap: ${SPACING[200]};
  flex-direction: row;
  flex-wrap: wrap;

  @media ${devices.tablet} {
    justify-content: flex-start;
  }
`;

const FiltersRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: ${SPACING[100]};
  margin-top: ${SPACING[200]};
`;

export {
  BlocksContainer,
  FlexBlocksContainer,
  BlockWrapper,
  BlockContentRow,
  BlockContentData,
  UserBlockDataGridWrapper,
  BlockDialogContentWrapper,
  BlockDialogContentRow,
  HeaderDialog,
  BodyBlocksDialog,
  ItemsListDialogHeader,
  ContentBlockDialog,
  RoleDetailsWrapper,
  RoleDetailsButtonWrapper,
  ListLoader,
  ItemsListDialogWrapper,
  HeaderDialogBlock,
  HeaderDialogFlexBlock,
  HeaderBodyBlocksDialog,
  HeaderRow,
  StatsWrapper,
  StatCard,
  BlockContentWrapper,
  DashboardPageContainer,
  DashboardPageBlockContainer,
  BlockStatsRow,
  FiltersRow,
};

import React from 'react';
import { useFormik } from 'formik';

import { Button, Heading, Input, Text } from '../../../styleguide';
import { SignupFormPolicyText } from '../../../styles/signup';
import { FormWrapper, ButtonsWrapper } from '../../../styles/form';
import { signupRusherLogo } from '../../../forms';

const PictureForm = props => {
  const { onRusherUpdate, onNext } = props;

  const formik = useFormik({
    initialValues: {
      pictureURL: '',
    },
    validationSchema: signupRusherLogo,
    onSubmit: values => {
      const { pictureURL } = values;
      onRusherUpdate({ pictureURL });
      onNext();
    },
  });

  return (
    <>
      <Heading size={900} centered>
        Votre avatar
      </Heading>
      <FormWrapper onSubmit={formik.handleSubmit}>
        <Input
          name="pictureURL"
          label="Url de votre avatar"
          placeholder="https://exemple.com/mon-avatar.png"
          value={formik.values.pictureURL}
          onChange={formik.handleChange}
          error={formik.touched.pictureURL && Boolean(formik.errors.pictureURL)}
          helperText={formik.touched.pictureURL && formik.errors.pictureURL}
        />

        <SignupFormPolicyText>
          <Text size={300} muted>
            Lien vers votre avatar
          </Text>
        </SignupFormPolicyText>

        <ButtonsWrapper>
          <Button type="submit">Continuer</Button>
        </ButtonsWrapper>
      </FormWrapper>
    </>
  );
};

export default PictureForm;

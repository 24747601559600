import { DAYS, DEFAULT_HOURS } from '../shop/createShop';

const generateShopHoursText = (hours, t) => {
  const daysStrings = hours.map(hour => {
    if (hour.isOpen) {
      return {
        day: t(`common:opening:days:${hour.day}`),
        opening: hour.opening,
        closing: hour.closing,
        closed: false,
      };
    } else {
      return {
        day: t(`common:opening:days:${hour.day}`),
        closed: true,
      };
    }
  });

  const mergedDays = daysStrings.reduce((acc, day) => {
    if (acc.length === 0) {
      return [day];
    }

    const lastDay = acc[acc.length - 1];

    if (lastDay.opening === day.opening && lastDay.closing === day.closing) {
      if (lastDay.closed && !day.closed) {
        return [
          ...acc.slice(0, -1),
          {
            ...lastDay,
            day: `${lastDay.day}/${day.day}`,
            closed: false,
          },
        ];
      }
      return [
        ...acc.slice(0, -1),
        {
          ...lastDay,
          day: `${lastDay.day}/${day.day}`,
        },
      ];
    }

    return [...acc, day];
  }, []);

  const texts = mergedDays.map(day => {
    const splittedDays = day.day.split('/');

    if (splittedDays.length === 1) {
      return {
        label: day.day,
        value: day.closed
          ? ` : ${t(`common:opening:closed`)}`
          : ` : ${day.opening} - ${day.closing}`,
        days: [day.day],
      };
    }

    return {
      label: `${t('common:opening:from_alt')} ${splittedDays[0]} ${t(
        'common:opening:to_alt'
      )} ${splittedDays[splittedDays.length - 1]}`,
      value: day.closed
        ? ` : ${t(`common:opening:closed`)}`
        : ` : ${day.opening} - ${day.closing}`,
      days: splittedDays,
    };
  });

  return texts;
};

const getNextOpeningDay = (shop, t) => {
  const daysAfterToday = DAYS.slice(new Date().getDay(), DAYS.length);
  const openedDays = !!shop.hours
    ? shop.hours.filter(el => {
        return el.isOpen;
      })
    : DEFAULT_HOURS.filter(el => {
        return el.isOpen;
      });
  const nextOpenDay =
    openedDays.find(el =>
      daysAfterToday.find(e => el.day === e && el.isOpen)
    ) || openedDays[0];

  return {
    ...nextOpenDay,
    day: t(`common:opening:days:${nextOpenDay?.day}`),
  };
};

export { generateShopHoursText, getNextOpeningDay };

import React from 'react';
import { Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Heading from './heading';
import Text from './text';
import {
  ItemWrapper,
  ItemBgImage,
  ItemContentWrapper,
  ItemContentHeaderWrapper,
  ItemContentHeaderDetailsWrapper,
  ItemContentButtonsWrapper,
  ItemBgImageChipWrapper,
} from '../styles/shop';
import { useTheme } from '../hooks';
import { PartnerBadge } from '../styleguide';
import { SPACING } from '../styles/constants';
import { parseNumber } from '../common';

const ItemCard = props => {
  const {
    item,
    actionsChildren,
    styles,
    withChip,
    onClick,
    disabled,
    withoutPartnerBadge,
    size,
    truncatedDescription,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation(['shop']);

  const isShopPartner = !!item.shopIsPartner;

  const getTruncatedDescription = text => {
    const max = 80;
    const breakLines = text.split('\n');
    if (breakLines.length > 3) {
      return `${breakLines[0]}\n${breakLines[1]}\n${breakLines[2]}...`;
    }
    return text.length > max ? text.slice(0, max) + '...' : text;
  };

  return (
    <ItemWrapper
      theme={theme}
      color={theme.textColor}
      style={{
        ...(styles || {}),
        ...(!item.avaibility && !withChip ? { opacity: 0.5 } : {}),
      }}
      {...(onClick && !disabled ? { onClick } : {})}
      size={size}
    >
      {withChip ? (
        <ItemBgImage imageSRC={item.pictureURL} theme={theme} size={size}>
          <ItemBgImageChipWrapper spaceBetween={true}>
            {isShopPartner && !withoutPartnerBadge ? (
              <PartnerBadge theme={theme} />
            ) : null}
            <Chip
              label={`${item.avaibility ? 'Disponible' : 'Indisponible'}`}
              color={item.avaibility ? 'primary' : 'secondary'}
              variant={'default'}
            />
          </ItemBgImageChipWrapper>
        </ItemBgImage>
      ) : (
        <ItemBgImage
          imageSRC={item.pictureURL}
          withoutP={disabled}
          theme={theme}
          size={size}
        >
          {withoutPartnerBadge ? null : (
            <ItemBgImageChipWrapper spaceBetween={true}>
              {isShopPartner ? <PartnerBadge theme={theme} /> : null}
            </ItemBgImageChipWrapper>
          )}
        </ItemBgImage>
      )}

      <ItemContentWrapper>
        <ItemContentHeaderWrapper theme={theme}>
          <Heading size={500} styles={{ marginRight: SPACING[100] }}>
            {item.name}{' '}
            {disabled ? ` (${t('shop:unavailable').toLocaleLowerCase()})` : ''}
          </Heading>
          <ItemContentHeaderDetailsWrapper>
            <Chip
              size="small"
              variant="outlined"
              label={
                <Text
                  styles={{ fontWeight: 'bold' }}
                  size={300}
                >{`${parseNumber(item.price).toFixed(2)}€`}</Text>
              }
            />
          </ItemContentHeaderDetailsWrapper>
        </ItemContentHeaderWrapper>
        <Text muted styles={{ whiteSpace: 'pre-line' }}>
          {(!!truncatedDescription
            ? getTruncatedDescription(item.description)
            : item.description
          ).trim()}
        </Text>
      </ItemContentWrapper>
      {actionsChildren ? (
        <ItemContentButtonsWrapper>{actionsChildren}</ItemContentButtonsWrapper>
      ) : null}
    </ItemWrapper>
  );
};

export default ItemCard;

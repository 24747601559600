import Firebase from 'firebase/app';
import 'firebase/messaging';

import { updateNotificationsTokens, deleteNotificationToken } from '../users';

const {
  PUBLIC_URL,
  REACT_APP_FIREBASE_VAPID_TOKEN,
  REACT_APP_FIREBASE_API_KEY,
  REACT_APP_FIREBASE_AUTH_DOMAIN,
  REACT_APP_FIREBASE_DATABASE_URL,
  REACT_APP_FIREBASE_PROJECT_ID,
  REACT_APP_FIREBASE_STORAGE_BUCKET,
  REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
  REACT_APP_FIREBASE_MESSAGING_APP_ID,
  REACT_APP_FIREBASE_MEASUREMENT_ID,
  REACT_APP_FIREBASE_WEBAPP_URL,
} = process.env;

const isSafari =
  navigator.vendor &&
  navigator.vendor.indexOf('Apple') > -1 &&
  navigator.userAgent &&
  navigator.userAgent.indexOf('CriOS') === -1 &&
  navigator.userAgent.indexOf('FxiOS') === -1;

const isSupported =
  'Notification' in window &&
  'serviceWorker' in navigator &&
  'PushManager' in window;

const deleteUserToken = async authID => {
  if (Firebase.messaging.isSupported()) {
    const tokenToDelete = await Firebase.messaging().getToken({
      vapidKey: REACT_APP_FIREBASE_VAPID_TOKEN,
    });

    if (tokenToDelete) {
      // Track the token -> client mapping, by sending to backend server
      // show on the UI that permission is secured
      await deleteNotificationToken(tokenToDelete, authID);
    }
  }
  return;
};

const saveTokenInDB = async (authID, setPermission) => {
  if (Firebase.messaging.isSupported()) {
    try {
      const messaging = Firebase.messaging();
      const registration = await initializeMessagingSW();
      const currentToken = await messaging.getToken({
        vapidKey: REACT_APP_FIREBASE_VAPID_TOKEN,
        serviceWorkerRegistration: registration,
      });
      // console.log({ currentToken, registration });

      if (currentToken) {
        // Track the token -> client mapping, by sending to backend server
        // show on the UI that permission is secured
        await updateNotificationsTokens(currentToken, authID);
        setPermission(true);
      } else {
        // console.log(
        //   'No registration token available. Request permission to generate one.'
        // );
        setPermission(false);
      }
    } catch (error) {
      console.error({ error });
    }
  } else {
    console.info('NOT SUPPORTED BROWSERS FOR FCM');
  }
};

const requestFirebaseNotificationPermission = async (authID, setPermission) => {
  if (!isSupported) {
    return;
  }

  if (isSafari) {
    // console.log('is safari');
    // Safari doesn't return a promise for requestPermissions and it
    // throws a TypeError. It takes a callback as the first argument
    // instead.
    try {
      const permission = await Notification.requestPermission();
      // console.log('Safari callback', { permission });

      if (permission === 'granted') {
        return saveTokenInDB(authID, setPermission);
      }
    } catch (err) {
      console.error(
        'An error occurred while retrieving token for safari. ',
        err
      );
    }
  } else {
    // console.log('others browsers');
    const permission = await Notification.requestPermission();

    // console.log({ permission });

    if (permission === 'granted') {
      try {
        await saveTokenInDB(authID, setPermission);
      } catch (err) {
        console.error('An error occurred while retrieving token. ', err);
      }
    }
  }
};

const initializeMessagingSW = async () => {
  if (isSupported) {
    // console.log('Registration started');
    const firebaseConfig = encodeURIComponent(
      JSON.stringify({
        apiKey: REACT_APP_FIREBASE_API_KEY,
        authDomain: REACT_APP_FIREBASE_AUTH_DOMAIN,
        databaseURL: REACT_APP_FIREBASE_DATABASE_URL,
        projectId: REACT_APP_FIREBASE_PROJECT_ID,
        storageBucket: REACT_APP_FIREBASE_STORAGE_BUCKET,
        messagingSenderId: REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
        appId: REACT_APP_FIREBASE_MESSAGING_APP_ID,
        measurementId: REACT_APP_FIREBASE_MEASUREMENT_ID,
        appUrl: REACT_APP_FIREBASE_WEBAPP_URL,
        dbPath: `https://firestore.googleapis.com/v1/projects/${REACT_APP_FIREBASE_PROJECT_ID}/databases/(default)/documents/users`,
      })
    );

    try {
      const swUrl = `${PUBLIC_URL}/firebase-messaging-sw.js?firebaseConfig=${firebaseConfig}`;

      const alreadyRegistered = await navigator.serviceWorker.getRegistration(
        PUBLIC_URL
      );

      if (alreadyRegistered) {
        // console.info('Service Worker already registered');
        await alreadyRegistered.unregister();
      }

      const registration = await navigator.serviceWorker.register(swUrl);
      return registration;
    } catch (error) {
      console.error('Service worker registration failed, error:', error);
    }
  } else {
    console.warn('Service Workers not supported by this browser.');
  }
};

export {
  requestFirebaseNotificationPermission,
  deleteUserToken,
  initializeMessagingSW,
};

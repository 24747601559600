import React from 'react';
import { toaster } from 'evergreen-ui';

import { Heading } from '../../../../styleguide';
import finishOrder from '../../../../actions/orders/finishOrder';
import { TabContentWrapper } from '../../../../styles/shop';
import {
  OrdersListWrapper,
  EmptyNewOrders,
} from '../../../../styles/orders-cards';
import TabPanel from '../../../shop/tabs/TabPanel';
import { InDeliveryCard } from '../cards/Cards';
import EmptyTab from './EmptyTab';
import DeliveryDialog from '../dialogs/DeliveryDialog';

const EndedTab = props => {
  const { value, orders, onCancelOrder } = props;

  const [confirmModal, setConfirmModal] = React.useState(null);
  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const [isLoading, setLoading] = React.useState(null);

  React.useEffect(() => {
    if (!!selectedOrder && !!confirmModal) {
      const orderToUpdate = orders.find(o => o.id === selectedOrder.id);

      if (!orderToUpdate || !!orderToUpdate?.inTreatment) {
        setConfirmModal(null);
        return;
      }
    }
  }, [orders, selectedOrder, confirmModal]);

  const onConfirm = async order => {
    try {
      setLoading(order.id);
      const orderToUpdate = orders.find(o => o.id === order.id);

      if (!orderToUpdate || !!orderToUpdate?.inTreatment) {
        setConfirmModal(null);
        toaster.warning(
          `⏳ Impossible commande en cours de traitement, réessayez plus tard`
        );
        return;
      }
      await finishOrder(orderToUpdate, 'DELIVERED');
      setConfirmModal(null);

      toaster.success(`Commande livré 🎉`, {
        description: `La commande numéro ${orderToUpdate.id} vous est désormais livré, félicitations 👏`,
      });
      setLoading(null);
    } catch (error) {
      setLoading(null);
      toaster.danger(`Une erreur est survenue`, {
        description: `Impossible de terminer la commande ${order.id}`,
      });
    }
  };

  const hasExistingOrders = !!orders.length;

  return (
    <TabPanel value={value} index={3}>
      <TabContentWrapper>
        {hasExistingOrders ? (
          <>
            <Heading size={800}>Les commandes en livraison</Heading>

            <OrdersListWrapper>
              {orders.map(order => (
                <InDeliveryCard
                  key={order.id}
                  order={order}
                  onClickConfirm={() => {
                    setConfirmModal('confirm');
                    setSelectedOrder(order);
                  }}
                  isLoading={isLoading === order.id}
                  onCancelOrder={onCancelOrder}
                />
              ))}
            </OrdersListWrapper>
            {!!confirmModal ? (
              <DeliveryDialog
                isOpen={!!confirmModal}
                confirmModal={confirmModal}
                order={selectedOrder}
                onClose={() => setConfirmModal(null)}
                onConfirm={() => onConfirm(selectedOrder)}
              />
            ) : null}
          </>
        ) : (
          <EmptyNewOrders>
            <EmptyTab
              title={'Aucune commande en cours de livraison'}
              description={
                'Aucune commande en cours de livraison pour le moment ! 📦'
              }
            />
          </EmptyNewOrders>
        )}
      </TabContentWrapper>
    </TabPanel>
  );
};

export default EndedTab;

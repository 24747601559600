import React from 'react';
import { Chip } from '@material-ui/core';
import { FiltersRow } from '../../../../../styles/admin';

const TableFilters = props => {
  const { displayedCount, setDisplayedCount } = props;

  return (
    <FiltersRow>
      <Chip
        label={`250`}
        color={displayedCount === 250 ? 'primary' : 'default'}
        onClick={() => setDisplayedCount(250)}
      />
      <Chip
        label={`500`}
        color={displayedCount === 500 ? 'primary' : 'default'}
        onClick={() => setDisplayedCount(500)}
      />
      <Chip
        label={`1000`}
        color={displayedCount === 1000 ? 'primary' : 'default'}
        onClick={() => setDisplayedCount(1000)}
      />
      <Chip
        label={`3000`}
        color={displayedCount === 3000 ? 'primary' : 'default'}
        onClick={() => setDisplayedCount(3000)}
      />
      <Chip
        label={'Tous'}
        color={displayedCount === 10000 ? 'primary' : 'default'}
        onClick={() => setDisplayedCount(10000)}
      />
    </FiltersRow>
  );
};

export default TableFilters;

import React from 'react';
import Sticky from 'react-stickynode';
import { useHistory, useLocation } from 'react-router-dom';
import { IconButton, Grid, Badge } from '@material-ui/core';
import { NavBarContainer } from '../../styles/navbar';
import { BasketIcon, MenuIcon } from '../../styleguide/icons';
import { colors } from '../../styles/constants';
import { Logo } from '../../styleguide';
import { useTheme } from '../../hooks';

const NavBar = props => {
  const {
    withLogo = true,
    leftChildren,
    rightChildren,
    minimal,
    openMenu,
    hasBasket,
  } = props;
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();
  const [sticky, setSticky] = React.useState(true);

  const goToBasket = () => {
    history.push('/basket', { from: { pathname: location.pathname } });
  };

  const onStickyChange = status => {
    if (status.status === Sticky.STATUS_FIXED) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  if (minimal) {
    return (
      <Sticky
        onStateChange={onStickyChange}
        innerZ={100}
        top="#app"
        className="inline-nav-block-light"
        innerActiveClass="inline-nav-block-active"
        bottomBoundary="#main-wrapper"
      >
        <NavBarContainer
          justify="center"
          sticky={sticky}
          bg={theme.backgroundColor}
        >
          <Logo />
        </NavBarContainer>
      </Sticky>
    );
  }

  return (
    <Sticky
      onStateChange={onStickyChange}
      innerZ={100}
      top="#app"
      className="inline-nav-block-light"
      innerActiveClass="inline-nav-block-active"
      bottomBoundary="#main-wrapper"
    >
      <NavBarContainer sticky={sticky} bg={theme.backgroundColor}>
        <Grid
          container
          direction="row"
          alignItems="center"
          justify="center"
          alignContent="center"
        >
          <Grid
            item
            xs
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
            }}
          >
            {!leftChildren ? (
              <IconButton aria-label="menu" onClick={openMenu}>
                <MenuIcon size="16px" color={theme.headingColor} />
              </IconButton>
            ) : leftChildren === 'disabled' ? (
              <IconButton disabled style={{ display: 'none' }}>
                <MenuIcon size="16px" color={theme.backgroundColor} />
              </IconButton>
            ) : (
              leftChildren
            )}
          </Grid>

          <Grid
            item
            xs
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            {withLogo ? (
              <Logo light={theme.headingColor === colors.white} />
            ) : null}
          </Grid>

          <Grid
            item
            xs={4}
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            {!rightChildren ? (
              <IconButton aria-label="panier" onClick={goToBasket}>
                <Badge color="primary" variant="dot" invisible={!hasBasket}>
                  <BasketIcon size="16px" color={theme.headingColor} />
                </Badge>
              </IconButton>
            ) : rightChildren === 'disabled' ? (
              <IconButton disabled style={{ display: 'none' }}>
                <BasketIcon size="16px" color={theme.backgroundColor} />
              </IconButton>
            ) : (
              rightChildren
            )}
          </Grid>
        </Grid>
      </NavBarContainer>
    </Sticky>
  );
};

export default React.memo(NavBar);

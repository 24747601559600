import React from 'react';
import { toaster } from 'evergreen-ui';

import ORDER_STATUS from '../../../../actions/orders/helpers/STATUS';
import updateOrder from '../../../../actions/orders/updateOrder';
import { Heading, Button } from '../../../../styleguide';
import { useTheme } from '../../../../hooks';
import { OrdersIcon } from '../../../../styleguide/icons';
import { TabContentWrapper } from '../../../../styles/shop';
import { HeaderTabRow } from '../../../../styles/shoptabs';
import {
  OrdersListWrapper,
  EmptyNewOrders,
} from '../../../../styles/orders-cards';
import TabPanel from '../../tabs/TabPanel';
import { ReadyOrderCard } from '../cards/Cards';
import ReadyOrderDialog from '../dialogs/ReadyOrderDialog';
import EmptyTab from './EmptyTab';

const ReadyTab = props => {
  const { value, orders, toggleOrdersDrawer } = props;
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const theme = useTheme();

  React.useEffect(() => {
    if (!!selectedOrder && !!confirmModal) {
      const orderToUpdate = orders.find(o => o.id === selectedOrder.id);

      if (!orderToUpdate || !!orderToUpdate?.inTreatment) {
        setConfirmModal(null);
        return;
      }
    }
  }, [orders, selectedOrder, confirmModal]);

  const onConfirm = async order => {
    try {
      const orderToUpdate = orders.find(o => o.id === order.id);

      if (!orderToUpdate || !!orderToUpdate?.inTreatment) {
        setConfirmModal(null);
        toaster.warning(
          `⏳ Impossible commande en cours de traitement, réessayez plus tard`
        );
        return;
      }

      await updateOrder(orderToUpdate, {
        status: ORDER_STATUS.inDelivery,
        pickedAt: Date.now(),
      });
      toaster.success(`Commande partie 🚀`, {
        description: `La commande numéro ${orderToUpdate.id} est desormais en livraison`,
      });
      setConfirmModal(null);
    } catch (error) {
      setConfirmModal(null);

      toaster.danger(`Une erreur est survenue`, {
        description: `Une erreur est survenue avec la commande ${order.id}, contactez notre support`,
      });
    }
  };

  const hasExistingOrders = !!orders.length;

  return (
    <TabPanel value={value} index={2}>
      <TabContentWrapper>
        {hasExistingOrders ? (
          <>
            <HeaderTabRow>
              <Heading size={800}>Les commandes prêtes</Heading>
              <Button
                small
                minimal
                startIcon={<OrdersIcon color={theme.black} size="16px" />}
                onClick={toggleOrdersDrawer}
              >
                Toutes les commandes
              </Button>
            </HeaderTabRow>

            <OrdersListWrapper>
              {orders.map(order => {
                return (
                  <ReadyOrderCard
                    key={order.id}
                    onClickConfirm={() => {
                      setConfirmModal(true);
                      setSelectedOrder(order);
                    }}
                    order={order}
                  />
                );
              })}
            </OrdersListWrapper>
            {confirmModal ? (
              <ReadyOrderDialog
                isOpen={confirmModal}
                onClose={() => setConfirmModal(null)}
                onConfirm={() => onConfirm(selectedOrder)}
                order={selectedOrder}
              />
            ) : null}
          </>
        ) : (
          <EmptyNewOrders>
            <EmptyTab
              title={'Pas de commande en attente'}
              description={
                "Vous n'avez aucune commande en attente de livraison pour le moment 🎉"
              }
            />
          </EmptyNewOrders>
        )}
      </TabContentWrapper>
    </TabPanel>
  );
};

export default ReadyTab;

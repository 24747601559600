import React from 'react';
import { toaster } from 'evergreen-ui';

import updateOrder from '../../../../actions/orders/updateOrder';
import { Heading } from '../../../../styleguide';
import { useSession } from '../../../../hooks';
import { TabContentWrapper } from '../../../../styles/shop';
import {
  OrdersListWrapper,
  EmptyNewOrders,
} from '../../../../styles/orders-cards';
import TabPanel from '../../../shop/tabs/TabPanel';
import { ReadyOrderCard } from '../cards/Cards';
import AttributionDialog from '../dialogs/AttributionDialog';
import EmptyTab from './EmptyTab';

const ReadyTab = props => {
  const { value, orders, onCancelOrder } = props;
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const { user } = useSession();

  React.useEffect(() => {
    if (!!selectedOrder && !!confirmModal) {
      const orderToUpdate = orders.find(o => o.id === selectedOrder.id);

      if (!orderToUpdate || !!orderToUpdate?.inTreatment) {
        setConfirmModal(null);
        return;
      }
    }
  }, [orders, selectedOrder, confirmModal]);

  const onConfirm = async order => {
    try {
      const orderToUpdate = orders.find(o => o.id === order.id);

      if (!orderToUpdate || !!orderToUpdate?.inTreatment) {
        setConfirmModal(null);
        toaster.warning(
          `⏳ Impossible commande en cours de traitement, réessayez plus tard`
        );
        return;
      }

      await updateOrder(orderToUpdate, {
        rusher: {
          isAdmin: true,
          available: true,
          isWorked: true,
          isVerified: true,
          name: user.name,
          id: user.id,
          phone: user.phone,
          pictureURL: '',
        },
      });
      toaster.success(`Commande attribué 🚀`, {
        description: `La commande numéro ${orderToUpdate.id} vous est desormais attribué.`,
      });
      setConfirmModal(null);
    } catch (error) {
      setConfirmModal(null);
      toaster.danger(`Une erreur est survenue`, {
        description: `Impossible d'attribuer la commande ${order.id} à ${user.name}`,
      });
    }
  };

  const onClickRemoveRusher = async order => {
    try {
      const orderToUpdate = orders.find(o => o.id === order.id);

      if (!orderToUpdate || !!orderToUpdate?.inTreatment) {
        toaster.warning(
          `⏳ Impossible commande en cours de traitement, réessayez plus tard`
        );
        return;
      }

      if (!orderToUpdate.rusher) {
        toaster.warning(
          `⏳ Impossible commande en cours de traitement, réessayez plus tard`
        );
        return;
      }

      await updateOrder(orderToUpdate, {
        rusher: null,
      });
      toaster.success(`Livreur retiré ✅`, {
        description: `La commande numéro ${order.id} n'est plus attribué à ${order.rusher.name}.`,
      });
    } catch (error) {
      toaster.danger(`Une erreur est survenue`, {
        description: `Impossible de retirer le livreur.`,
      });
    }
  };

  const hasExistingOrders = !!orders.length;

  return (
    <TabPanel value={value} index={2}>
      <TabContentWrapper>
        {hasExistingOrders ? (
          <>
            <Heading size={800}>Les commandes prêtes</Heading>

            <OrdersListWrapper>
              {orders.map(order => {
                return (
                  <ReadyOrderCard
                    key={order.id}
                    onClickConfirm={() => {
                      setConfirmModal(true);
                      setSelectedOrder(order);
                    }}
                    order={order}
                    onCancelOrder={onCancelOrder}
                    onClickRemoveRusher={onClickRemoveRusher}
                  />
                );
              })}
            </OrdersListWrapper>
            {!!confirmModal ? (
              <AttributionDialog
                isOpen={!!confirmModal}
                selectedOrder={selectedOrder}
                confirmModal={confirmModal}
                onClose={() => setConfirmModal(null)}
                onConfirm={() => onConfirm(selectedOrder)}
                isReady
              />
            ) : null}
          </>
        ) : (
          <EmptyNewOrders>
            <EmptyTab
              title={'Pas de commande en attente'}
              description={
                "Vous n'avez aucune commande en attente de livraison pour le moment 🎉"
              }
            />
          </EmptyNewOrders>
        )}
      </TabContentWrapper>
    </TabPanel>
  );
};

export default ReadyTab;

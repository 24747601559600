import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

import { prepareDocForCreate } from '../helpers/firestoreHelpers';

export const USER_ROLE_CLIENT = 'client';
export const USER_ROLE_SHOP_OWNER = 'shop-owner';
export const USER_ROLE_SHOP_STAFF = 'shop-staff';
export const USER_ROLE_RUSHER = 'rusher';
export const USER_ROLE_ADMIN = 'admin';

const createUser = (data, authID, lng) => {
  // console.log('Create user called', { data, authID });
  ReactGA.event({
    category: 'Signup',
    action: 'Create user',
  });

  if (data.address.isDefault) {
    delete data.address.isDefault;
  }

  const user = {
    email: data.email,
    phone: data.phone,
    name: data.name,
    addresses: [
      {
        ...data.address,
      },
    ],
    role: USER_ROLE_CLIENT,
    defaultAddress: data.address.id,
    settings: {
      theme: 'light',
      lng: lng ?? 'fr',
      notifications: false,
      specialOffers: false,
      notificationsTokens: [],
    },
    stripeCustomerId: '',
    isVerified: true,
    verifiedBy: null, // 'phone' or 'email' or null
  };

  try {
    return Firebase.firestore()
      .collection('users')
      .doc(authID)
      .set(prepareDocForCreate(user));
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

export default createUser;

import React from 'react';
import { Chip } from '@material-ui/core';
import { DateTime } from 'luxon';
import { useReactToPrint } from 'react-to-print';

import {
  computeEstimatedTime,
  computeTimeFrom,
  differenceBetweenTS,
} from '../../../../actions/orders/helpers/computeTimesHelpers';
import {
  Button,
  HeaderCard,
  ItemListing,
  OrderDetails,
  OrderTimeDetails,
  OrderTitle,
  OrderTimeEnded,
  Text,
} from '../../../../styleguide';
import { PrintIcon } from '../../../../styleguide/icons';
import { useTheme } from '../../../../hooks';
import computePreparationTime from '../../../../actions/orders/helpers/computePreparationTime';
import {
  OrderCardOverlay,
  OrderCardWrapper,
  OrderCardActions,
  OrderCardHeader,
} from '../../../../styles/orders-cards';
import { SPACING } from '../../../../styles/constants';
import { ClockIcon } from '../../../../styleguide/icons';
import { ClientReceipt, KitchenReceipt } from '../receipts';

const NewOrderCard = props => {
  const { order, onClickConfirm, onClickCancel, cancelInProgress } = props;
  const theme = useTheme();
  const temporaryDisabled = !!order?.inTreatment;

  return (
    <OrderCardWrapper theme={theme}>
      <HeaderCard order={order} />
      <OrderTitle order={order} />
      <ItemListing order={order} />
      <OrderDetails order={order} />
      <OrderCardActions theme={theme}>
        <Button
          onClick={onClickCancel}
          small
          color="primary"
          styles={{ marginRight: SPACING[100], backgroundColor: theme.red }}
          disabled={cancelInProgress || temporaryDisabled}
          isLoading={cancelInProgress}
          loaderPadding={SPACING[100]}
        >
          Refuser
        </Button>
        <Button
          onClick={onClickConfirm}
          small
          disabled={cancelInProgress || temporaryDisabled}
        >
          Accepter
        </Button>
      </OrderCardActions>
    </OrderCardWrapper>
  );
};

const InProgressOrderCard = props => {
  const { order, onClickConfirm } = props;
  const theme = useTheme();
  const ref = React.useRef(null);
  const orderFrom = computeTimeFrom({ ts: order.orderAt });
  const preparationTime =
    order.estimatedTimeToDeliver || computePreparationTime(order.itemsList);

  const estimatedDeliveryTime = computeEstimatedTime({
    ts: order.acceptedAt,
    withDelay: false,
    minutes: preparationTime,
    seconds: order.duration,
  });

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    onPrintError: all => console.error({ all }),
  });

  const temporaryDisabled = !!order?.inTreatment;

  return (
    <OrderCardWrapper theme={theme}>
      <OrderCardHeader>
        <Chip icon={<ClockIcon />} label={estimatedDeliveryTime} />
        <Chip icon={<ClockIcon />} label={orderFrom} />
      </OrderCardHeader>
      <OrderTitle order={order} />
      <OrderTimeDetails order={order} timeColor={theme.primaryColor} />
      <ItemListing order={order} />
      <OrderDetails order={order} type="in-progress" />
      <OrderCardActions theme={theme}>
        <Button
          small
          onClick={handlePrint}
          minimal
          variant="outlined"
          styles={{ marginRight: SPACING[100] }}
          startIcon={<PrintIcon color={theme.headingColor} size="16px" />}
        >
          Ticket cuisine
        </Button>
        <Button onClick={onClickConfirm} small disabled={temporaryDisabled}>
          Terminer
        </Button>
      </OrderCardActions>
      <div style={{ display: 'none' }}>
        <KitchenReceipt
          order={order}
          ref={ref}
          orderAt={DateTime.fromMillis(order.orderAt).toLocaleString(
            DateTime.DATETIME_SHORT
          )}
          estimatedDeliveryTime={computeEstimatedTime({
            ts: Date.now(),
            withDelay: false,
            minutes: preparationTime,
            seconds: order.duration,
          })}
        />
      </div>
    </OrderCardWrapper>
  );
};

const ReadyOrderCard = props => {
  const { order, onClickConfirm } = props;
  const theme = useTheme();
  const ref = React.useRef(null);
  const temporaryDisabled = !!order?.inTreatment;
  const preparationTime =
    order.estimatedTimeToDeliver || computePreparationTime(order.itemsList);

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    onPrintError: all => console.error({ all }),
  });

  return (
    <OrderCardWrapper theme={theme}>
      <HeaderCard order={order} />
      <OrderTitle order={order} />
      <OrderTimeDetails order={order} timeColor={theme.primaryColor} isReady />
      <ItemListing order={order} />
      <OrderDetails type="ready" order={order} />
      <OrderCardActions theme={theme}>
        <Button
          small
          onClick={handlePrint}
          minimal
          variant="outlined"
          styles={{ marginRight: SPACING[100] }}
          startIcon={<PrintIcon color={theme.headingColor} size="16px" />}
        >
          Ticket Client
        </Button>
        <Button
          onClick={onClickConfirm}
          small
          disabled={!order.rusher || temporaryDisabled}
        >
          Départ
        </Button>
      </OrderCardActions>
      <div style={{ display: 'none' }}>
        <ClientReceipt
          order={order}
          ref={ref}
          orderAt={DateTime.fromMillis(order.orderAt).toLocaleString(
            DateTime.DATETIME_SHORT
          )}
          estimatedDeliveryTime={computeEstimatedTime({
            ts: Date.now(),
            withDelay: false,
            minutes: preparationTime,
            seconds: order.duration,
          })}
        />
      </div>
    </OrderCardWrapper>
  );
};

const InDeliveryCard = props => {
  const { order } = props;
  const theme = useTheme();

  return (
    <OrderCardWrapper theme={theme}>
      <OrderCardOverlay>
        <Text
          size={400}
          styles={{
            color: theme.headingColor,
            backgroundColor: theme.cardBg,
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
          }}
        >
          En cours de livraison {' 🛵'}
        </Text>
      </OrderCardOverlay>
      <HeaderCard order={order} />
      <OrderTitle order={order} />
      <OrderTimeDetails
        order={order}
        timeColor={theme.primaryColor}
        inDelivery
      />
      <ItemListing order={order} expanded={false} />
      <OrderDetails type="ready" order={order} expanded={false} />
    </OrderCardWrapper>
  );
};

const DeliveredCard = props => {
  const { order } = props;
  const theme = useTheme();

  return (
    <OrderCardWrapper theme={theme}>
      <OrderCardOverlay>
        <Text
          size={400}
          styles={{
            color: theme.primaryColor,
            backgroundColor: theme.cardBg,
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
          }}
        >
          Cette commande à était livré{' 🎉'}
        </Text>
      </OrderCardOverlay>
      <HeaderCard order={order} />
      <OrderTitle order={order} />
      <OrderTimeEnded order={order} />
      <ItemListing order={order} expanded={false} />
      <OrderDetails order={order} expanded={false} />
    </OrderCardWrapper>
  );
};

const CanceledCard = props => {
  const { order } = props;
  const theme = useTheme();

  const waitingDurationBeforeCancel = differenceBetweenTS(
    order.canceledAt,
    order.orderAt
  );

  const label =
    order?.canceledBy === 'user'
      ? `Le client à annulé cette commande après avoir attendu ${waitingDurationBeforeCancel}`
      : 'Vous avez refusé cette commande';

  return (
    <OrderCardWrapper theme={theme}>
      <OrderCardOverlay bg={theme.shadow}>
        <Text
          size={400}
          styles={{
            color: theme.red,
            backgroundColor: theme.cardBg,
            whiteSpace: 'nowrap',
            fontWeight: 'bold',
          }}
        >
          {`${label} ❌`}
        </Text>
      </OrderCardOverlay>
      <HeaderCard order={order} />
      <OrderTitle order={order} />
      <ItemListing order={order} expanded={false} />
      <OrderDetails order={order} expanded={false} />
    </OrderCardWrapper>
  );
};

export {
  NewOrderCard,
  InProgressOrderCard,
  ReadyOrderCard,
  InDeliveryCard,
  DeliveredCard,
  CanceledCard,
};

import React from 'react';
import Firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Avatar } from '@material-ui/core';

import { onInvoiceCancel } from '../../../../../actions/rusher';
import {
  HeaderDialog,
  HeaderDialogBlock,
  HeaderDialogFlexBlock,
  BodyBlocksDialog,
  ContentBlockDialog,
  RoleDetailsWrapper,
  HeaderBodyBlocksDialog,
} from '../../../../../styles/admin';
import { BasicColumnBlock } from '../../../../../styles/common';
import {
  Text,
  Heading,
  ContentLoader,
  FullscreenDialog,
  Button,
  Backdrop,
} from '../../../../../styleguide';
import { EuroIcon, DeleteIcon } from '../../../../../styleguide/icons';
import { SPACING } from '../../../../../styles/constants';
import { useTheme } from '../../../../../hooks';
import InvoiceResumeTemplate from '../../../../rusher/billing/InvoiceResumeTemplate';
import { flagInvoiceAsPaid } from '../../../../../actions/rusher';
import { parseNumber } from '../../../../../common';

const InvoicesDialog = props => {
  const { invoice, invoices, setEditDialog, ...rest } = props;

  const [value, loading, error] = useCollectionData(
    Firebase.firestore()
      .collection('rushers')
      .where('id', '==', invoice.data.rusherID),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (error) {
    throw new Error(error.toString());
  }

  if (loading) {
    return <Backdrop />;
  }

  const rusher = value[0];

  return (
    <InvoicesDialogComponent
      rusher={rusher}
      invoice={invoice}
      invoices={invoices}
      setEditDialog={setEditDialog}
      {...rest}
    />
  );
};

const InvoicesDialogComponent = props => {
  const { invoice, setEditDialog, rusher, onClose, invoices, ...rest } = props;
  const theme = useTheme();

  const [isLoading, setLoading] = React.useState(false);

  const [orders, ordersLoading, ordersError] = useCollectionData(
    Firebase.firestore()
      .collection('orders')
      .where('metadata.invoiceId', '==', invoice.data.id),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const cancelInvoice = async () => {
    setLoading(true);
    const invoiceToCancel = invoices.find(i => i.id === invoice.data.id);
    await onInvoiceCancel(invoiceToCancel);
    setLoading(false);
    onClose();
  };

  const onClickFlagAsPaid = async () => {
    setLoading(true);
    const invoiceToFlag = invoices.find(i => i.id === invoice.data.id);
    await flagInvoiceAsPaid(invoiceToFlag);
    setLoading(false);
    onClose();
  };

  if (ordersError) {
    throw new Error(ordersError.toString());
  }

  if (ordersLoading) {
    return (
      <FullscreenDialog
        theme={theme}
        title="Facture"
        onClose={onClose}
        {...rest}
      >
        <HeaderDialog></HeaderDialog>
        <BodyBlocksDialog>
          <ContentLoader text="Les données en lien avec la facture sont en cours de chargement..." />
        </BodyBlocksDialog>
      </FullscreenDialog>
    );
  }

  return (
    <FullscreenDialog theme={theme} title="Facture" onClose={onClose} {...rest}>
      <HeaderDialog>
        <Avatar
          src={rusher.pictureURL}
          style={{ width: 48, height: 48, marginRight: SPACING[200] }}
        />
        <HeaderDialogBlock>
          <HeaderDialogFlexBlock style={{ paddingBottom: SPACING[100] }}>
            <Heading size={700} styles={{ paddingRight: SPACING[100] }}>
              {rusher.name}{' '}
            </Heading>
          </HeaderDialogFlexBlock>

          <Text size={500} muted>
            {rusher.id}
          </Text>
        </HeaderDialogBlock>
      </HeaderDialog>

      <RoleDetailsWrapper>
        <Button
          small
          minimal
          variant="outlined"
          onClick={cancelInvoice}
          startIcon={<DeleteIcon color={theme.headingColor} size="16px" />}
          isLoading={isLoading}
          styles={{ marginRight: SPACING[100] }}
        >
          Annuler la facture
        </Button>
        {invoice.data.status === 'paid' ||
        invoice.data.status === 'cancelled' ? null : (
          <Button
            small
            onClick={onClickFlagAsPaid}
            startIcon={<EuroIcon color={theme.white} size="16px" />}
            isLoading={isLoading}
          >
            Payer la facture
          </Button>
        )}
      </RoleDetailsWrapper>

      <BodyBlocksDialog>
        <HeaderBodyBlocksDialog>
          <Heading size={500}>Informations générales</Heading>
        </HeaderBodyBlocksDialog>

        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Crée le
          </Text>
          <Text size={500}>{invoice.data.generatedAt}</Text>
        </ContentBlockDialog>

        <ContentBlockDialog theme={theme} style={{ alignItems: 'center' }}>
          <Text size={400} muted>
            Status
          </Text>
          <Text size={500}>{invoice.data.status}</Text>
        </ContentBlockDialog>

        <ContentBlockDialog theme={theme} style={{ alignItems: 'center' }}>
          <Text size={400} muted>
            Total Net
          </Text>
          <Text size={500}>{parseNumber(invoice.data.net).toFixed(2)}€</Text>
        </ContentBlockDialog>

        <ContentBlockDialog theme={theme} style={{ alignItems: 'center' }}>
          <Text size={400} muted>
            Commission
          </Text>
          <Text size={500}>
            {parseNumber(invoice.data.commission).toFixed(2)}€
          </Text>
        </ContentBlockDialog>
      </BodyBlocksDialog>

      <BodyBlocksDialog>
        <HeaderBodyBlocksDialog>
          <Heading size={500}>Récapitulatif de votre facture</Heading>
        </HeaderBodyBlocksDialog>

        <BasicColumnBlock justify="flex-start">
          <InvoiceResumeTemplate
            rusher={rusher}
            invoice={invoice.data}
            displayList={false}
            date={invoice.data.generatedAt}
            orders={orders}
          />
        </BasicColumnBlock>
      </BodyBlocksDialog>
    </FullscreenDialog>
  );
};

export default InvoicesDialog;

import React from 'react';
import styled, { keyframes } from 'styled-components';
import { SPACING } from '../styles/constants';

const dotColor = `255, 0, 0	`;

const DotAlertWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${SPACING[200]} 0;
  margin-top: ${SPACING[200]};
  font-weight: 600;
  font-size: 1.2em;

  p {
    margin: 0;
  }
`;

const animation = keyframes`
  0% { 
    box-shadow: 0 0 0 0 ${`rgba(${dotColor}, 0.2)`};
    transform: scale(130px); 
  }
  33% { 
    box-shadow: 0 0 2px 4px ${`rgba(${dotColor}, 0.4)`};
    transform: scale(1.02); 
  }
  66% { 
    box-shadow: 0 0 3px 6px ${`rgba(${dotColor}, 0.6)`};
    transform: scale(1.04); 
  }
  100% { 
    box-shadow: 0 0 0 0 ${`rgba(${dotColor}, 0.8)`}; 
    transform: scale(1); 
  }
`;

const DotAlertDot = styled.span`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(${dotColor});
  margin-right: ${SPACING[200]};
  animation: ${animation} 1s infinite;
`;

const AlertDot = props => {
  const { label } = props;

  return (
    <DotAlertWrapper>
      <DotAlertDot />
      {label}
    </DotAlertWrapper>
  );
};

export default AlertDot;

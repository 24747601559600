import React from 'react';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { useUnstated } from '@gitbook/unstated';
import { useTranslation } from 'react-i18next';

import { Button, Backdrop, Input } from '../../styleguide';
import validateOrderDiscount from '../../actions/discounts/validateOrderDiscount';
import { SPACING } from '../../styles/constants';
import { BasicFlexBlock } from '../../styles/common';
import { useTheme, useSession } from '../../hooks';
import { toaster } from 'evergreen-ui';
import { BasketContainer } from '../../contexts';

const OrderDiscountModal = props => {
  const { isOpen, onClose, shop } = props;
  const theme = useTheme();
  const { user } = useSession();
  const [code, setCode] = React.useState('');
  const [loading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const basketContainer = useUnstated(BasketContainer);
  const { t } = useTranslation(['common', 'checkout']);

  const onCodeChange = e => {
    setCode(e.target.value);
  };

  const onCodeSubmit = async () => {
    if (error) {
      setError(null);
    }

    setLoading(true);

    try {
      const {
        data: { status, discount },
      } = await validateOrderDiscount({
        code,
        shopID: shop.id,
        userID: user.id,
      });

      if (status === 'success') {
        basketContainer.updateDiscount(discount);
        setLoading(false);
        onClose();
        setCode('');
        toaster.success(t('checkout:discount_modal:errors:success:title'));
        return;
      }

      if (status === 'error-shop') {
        setLoading(false);
        setError(t('checkout:discount_modal:errors:not_allowed_shop'));
        return;
      }

      if (status === 'error-max-usage-count-by-user') {
        setLoading(false);
        setError(t('checkout:discount_modal:errors:already_used'));
        return;
      }

      setLoading(false);
      setError(t('checkout:discount_modal:errors:invalid'));
    } catch (error) {
      setError(t('checkout:discount_modal:errors:invalid'));
    }

    setLoading(false);
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      {loading && <Backdrop isContentBackdrop />}
      <DialogTitle>{t('checkout:discount_modal:title')}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t('checkout:discount_modal:message')}
        </DialogContentText>

        <BasicFlexBlock>
          <Input
            type="text"
            name="code"
            label="Code"
            size="small"
            variant="outlined"
            required
            placeholder="ex: ABC123"
            fullWidth
            value={code}
            onChange={onCodeChange}
          />
        </BasicFlexBlock>

        {error && (
          <DialogContentText
            style={{
              padding: `${SPACING[200]}px 0`,
            }}
          >
            <span style={{ color: theme.red }}>{error}</span>
          </DialogContentText>
        )}
      </DialogContent>

      <DialogActions>
        <Button small onClick={onClose} minimal>
          {t('common:close')}
        </Button>
        <Button small onClick={onCodeSubmit} disabled={!code.length}>
          {t('common:add')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default OrderDiscountModal;

import React from 'react';
import { add } from 'lodash';
import { DateTime } from 'luxon';

import {
  HeaderDialog,
  BodyBlocksDialog,
  ContentBlockDialog,
  HeaderDialogBlock,
  HeaderBodyBlocksDialog,
} from '../../../styles/admin';
import { Text, Heading, Backdrop, FullscreenDialog } from '../../../styleguide';
import { SPACING } from '../../../styles/constants';
import { useTheme } from '../../../hooks';
import InvoiceDialogOrdersList from './InvoiceDialogOrdersList';
import NewInvoiceResume from './NewInvoiceResume';
import { getInitialDeliveryFees } from '../../../common/fees';
import { parseNumber } from '../../../common';

const NewInvoiceDialog = props => {
  const {
    invoices,
    invoice,
    orders,
    setInvoiceCreation,
    rusher,
    cantConfirm,
    onSubmitNewInvoice,
    creationLoading,
    ...rest
  } = props;
  const [displayList, setListDisplay] = React.useState(true);

  const theme = useTheme();

  const groupedOrders = {
    payed: [],
    toPay: [],
    totalToPay: 0,
    totalPayed: 0,
  };

  orders.map(order => {
    const deliveryFees = order.discount
      ? getInitialDeliveryFees(order.distance)
      : order.deliveryFees;

    if (!!order.metadata.isBilled) {
      groupedOrders.totalPayed = add(groupedOrders.totalPayed, deliveryFees);
      return groupedOrders.payed.push(order);
    }
    groupedOrders.totalToPay = add(groupedOrders.totalToPay, deliveryFees);
    return groupedOrders.toPay.push(order);
  });

  const now = DateTime.fromMillis(Date.now()).toLocaleString(
    DateTime.DATETIME_MED_WITH_WEEKDAY
  );

  return (
    <FullscreenDialog theme={theme} title="Facturation" {...rest}>
      <HeaderDialog>
        <HeaderDialogBlock>
          <Heading size={700} styles={{ paddingBottom: SPACING[100] }}>
            Création facture
          </Heading>
          <Text size={500} muted styles={{ paddingLeft: SPACING[100] }}>
            {now}
          </Text>
        </HeaderDialogBlock>
      </HeaderDialog>

      {creationLoading && <Backdrop />}

      <BodyBlocksDialog>
        <HeaderBodyBlocksDialog>
          <Heading size={500}>Informations générales</Heading>
        </HeaderBodyBlocksDialog>
        <ContentBlockDialog theme={theme} style={{ alignItems: 'center' }}>
          <Text size={400} muted>
            Total HT facturé
          </Text>
          <Text size={500} color={theme.primaryColor}>
            {parseNumber(groupedOrders.totalPayed).toFixed(2)}€
          </Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme} style={{ alignItems: 'center' }}>
          <Text size={400} muted>
            Commande(s) déja facturée(s)
          </Text>
          <Text size={500} color={theme.primaryColor}>
            {groupedOrders.payed.length}
            <span style={{ color: theme.textColor }}> / {orders.length}</span>
          </Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme} style={{ alignItems: 'center' }}>
          <Text size={400} muted>
            Commande(s) à facturer
          </Text>
          <Text size={500}>
            {groupedOrders.toPay.length}
            <span style={{ color: theme.textColor }}> / {orders.length}</span>
          </Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme} style={{ alignItems: 'center' }}>
          <Text size={400} muted>
            Total HT dù
          </Text>
          <Text size={500}>
            {parseNumber(groupedOrders.totalToPay).toFixed(2)}€
          </Text>
        </ContentBlockDialog>

        <InvoiceDialogOrdersList
          orders={groupedOrders.toPay}
          rusher={rusher}
          setInvoiceCreation={setInvoiceCreation}
          invoice={invoice}
          displayList={displayList}
          setListDisplay={setListDisplay}
        />

        <NewInvoiceResume
          rusher={rusher}
          invoice={invoice}
          setInvoiceCreation={setInvoiceCreation}
          disabled={cantConfirm}
          setListDisplay={setListDisplay}
          displayList={displayList}
          now={now}
          onSubmitNewInvoice={onSubmitNewInvoice}
        />
        {/* <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Role:
          </Text>
          <FormControl style={{ width: '120px' }}>
            <Select
              value={!user.updatedData ? user.data.role : user.updatedData.role}
              onChange={event =>
                setEditDialog({
                  ...user,
                  updatedData: { role: event.target.value },
                })
              }
            >
              <MenuItem value={'client'}>Client</MenuItem>
              <MenuItem value={'shop-owner'}>Propriétaire</MenuItem>
              <MenuItem value={'admin'}>Admin</MenuItem>
              <MenuItem value={'rusher'}>Livreur</MenuItem>
            </Select>
          </FormControl>{' '}
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Stripe
          </Text>
          {user.data.stripeURL !== 'Aucun paiement' ? (
            <ExternalHoverableLink href={user.data.stripeURL}>
              Voir le compte
            </ExternalHoverableLink>
          ) : (
            <Text size={500}>Pas encore client</Text>
          )}
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Téléphone
          </Text>
          <Text size={500}>{user.data.phone}</Text>
        </ContentBlockDialog> */}
      </BodyBlocksDialog>
    </FullscreenDialog>
  );
};

export default NewInvoiceDialog;

import React from 'react';
import Firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Alert, AlertTitle } from '@material-ui/lab';

import ORDER_STATUS from '../../../actions/orders/helpers/STATUS';
import groupBy from '../../../actions/helpers/groupBy';
import { Tabs, Tab, Loader, AlertDot } from '../../../styleguide';
import { SPACING } from '../../../styles/constants';
import { useTheme } from '../../../hooks';
import { NewsTab, InProgressTab, EndedTab, ReadyTab } from './tabs';
import OrderDrawer from './drawers/OrderDrawer';

const ShopOrdersTabs = props => {
  const { shop } = props;
  const [currentTab, setCurrentTab] = React.useState(0);
  const [ordersDrawer, setOrdersDrawer] = React.useState(false);

  const theme = useTheme();

  const [orders, loading, error] = useCollectionData(
    Firebase.firestore()
      .collection('orders')
      .where('shop.id', '==', shop.id)
      .orderBy('orderAt', 'desc')
      .limit(60),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const toggleOrdersDrawer = () => setOrdersDrawer(!ordersDrawer);

  const onTabChange = (event, value) => {
    setCurrentTab(value);
  };

  if (loading) {
    return (
      <Loader color={theme.primaryColor} background={theme.backgroundColor} />
    );
  }

  if (error) {
    throw new Error(error.toString());
  }

  const groupedOrders = groupBy(orders, 'status');
  const displayedOrders = {
    news: [],
    inProgress: [],
    ready: [],
    ended: [],
  };

  if (groupedOrders.length) {
    groupedOrders.forEach(group => {
      if (group.category === ORDER_STATUS.inValidation) {
        displayedOrders.news = [...group.items];
      } else if (group.category === ORDER_STATUS.inProgress) {
        displayedOrders.inProgress = [...group.items];
      } else if (group.category === ORDER_STATUS.ready) {
        displayedOrders.ready = [...group.items];
      } else if (group.category === ORDER_STATUS.inDelivery) {
        displayedOrders.ended = [...group.items, ...displayedOrders.ended];
      } else {
        displayedOrders.ended = [...displayedOrders.ended, ...group.items];
      }
    });
  }

  const hasCurrentOrders =
    displayedOrders.news.length ||
    displayedOrders.ready.length ||
    displayedOrders.inProgress.length;

  const waitingOrdersCount = displayedOrders.news.length;

  return (
    <>
      {!!waitingOrdersCount && (
        <AlertDot
          label={
            <p>
              {waitingOrdersCount}{' '}
              {waitingOrdersCount > 1
                ? 'clients en attentes'
                : 'client en attente'}
            </p>
          }
        />
      )}
      <Tabs
        style={{ width: '100%', margin: `${SPACING[300]} 0` }}
        value={currentTab}
        onChange={onTabChange}
      >
        <Tab
          label={
            <span>
              Attente (
              <span
                style={{
                  color:
                    displayedOrders.news.length >= 1 ? theme.red : 'inherit',
                  fontWeight:
                    displayedOrders.news.length >= 1 ? 'bold' : 'normal',
                }}
              >
                {displayedOrders.news.length}
              </span>
              )
            </span>
          }
        />
        <Tab label={`Préparation (${displayedOrders.inProgress.length})`} />
        <Tab label={`Prête (${displayedOrders.ready.length})`} />
        <Tab label={`Terminé (${displayedOrders.ended.length})`} />
      </Tabs>

      {!shop.available && hasCurrentOrders ? (
        <Alert severity="info" style={{ margin: '8px auto' }}>
          <AlertTitle>Établissement fermé</AlertTitle>
          Même si vous avez fermé votre établissement, n'oubliez de terminer les
          commandes en cours 😇
        </Alert>
      ) : null}

      <NewsTab
        value={currentTab}
        shop={shop}
        orders={displayedOrders.news}
        toggleOrdersDrawer={toggleOrdersDrawer}
      />
      <InProgressTab
        value={currentTab}
        shop={shop}
        orders={displayedOrders.inProgress}
        toggleOrdersDrawer={toggleOrdersDrawer}
      />
      <ReadyTab
        value={currentTab}
        shop={shop}
        orders={displayedOrders.ready}
        toggleOrdersDrawer={toggleOrdersDrawer}
      />
      <EndedTab
        value={currentTab}
        shop={shop}
        orders={displayedOrders.ended}
        toggleOrdersDrawer={toggleOrdersDrawer}
      />

      <OrderDrawer
        shop={shop}
        orders={orders}
        isOpen={ordersDrawer}
        onClose={() => setOrdersDrawer(false)}
      />
    </>
  );
};

export default ShopOrdersTabs;

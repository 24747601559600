import Welcome from './Welcome';
import NameForm from './NameForm';
import InfoForm from './InfoForm';
import PictureForm from './PictureForm';
import LegalForm from './LegalForm';
import CongratulationStep from './CongratulationStep';

const STEPS = [
  {
    index: 1,
    name: 'welcome',
    Step: Welcome,
  },
  {
    index: 2,
    name: 'name',
    Step: NameForm,
  },
  {
    index: 3,
    name: 'info',
    Step: InfoForm,
  },
  {
    index: 4,
    name: 'picture',
    Step: PictureForm,
  },
  {
    index: 5,
    name: 'legal',
    Step: LegalForm,
  },
  {
    index: 6,
    name: 'congrats',
    Step: CongratulationStep,
  },
];

export default STEPS;

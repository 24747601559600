import React from 'react';
import styled from 'styled-components';
import Sticky from 'react-stickynode';
import { useTranslation } from 'react-i18next';
import { Avatar } from '@material-ui/core';
import { Text, Heading } from '../../../styleguide';
import { SPACING, devices } from '../../../styles/constants';
import { useTheme } from '../../../hooks';
import { FullPageHorizontalSlider } from '../../../styles/common';
import { getNextOpeningDay } from '../../../actions/helpers/generateShopHoursText';

const InlineNavWrapper = styled.div`
  width: 100%;
  background: ${props => props.theme.backgroundColor};
  box-shadow: ${props => (props.sticky ? props.theme.boxShadow.inset : 'none')};
  padding: 0 ${SPACING[200]};
  padding-top: ${props => (props.sticky ? SPACING[200] : 0)};

  @media ${devices.mobileLG} {
    padding-top: ${props => (props.sticky ? SPACING[200] : 0)};
  }
`;

const NavIntro = styled(Heading)`
  margin-bottom: ${SPACING[100]};
`;

const NavIntroShopResume = styled.div`
  display: ${props => (!!props.isvisible ? 'flex' : 'none')};
  justify-content: flex-start;
  align-items: center;
  width: 100%;
`;

const InlineNavItem = styled.div`
  flex: 1 0 fit-content;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  transition: all 250ms;
  font-size: ${({ lg }) => (!!lg ? '16px' : '14px')};
  white-space: nowrap;
  cursor: pointer;
  margin-right: ${SPACING[200]};
  border-bottom: 1px solid transparent;
  text-decoration: none;
  color: ${props =>
    !!props.active ? props.theme.primaryColor : 'currentColor'};

  &:hover {
    color: ${props =>
      !!props.active ? props.theme.primaryColor : props.theme.textColor};
  }

  @media ${devices.mobileLG} {
    font-size: 16px;
  }
`;

const InfoLineBlock = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
  gap: ${SPACING[100]};
  width: 100%;
  padding-top: ${SPACING[200]};
  padding-bottom: ${SPACING[200]};
  background: ${props => props.theme.backgroundColor};
  padding-left: ${SPACING[100]};

  @media ${devices.mobileLG} {
    padding-left: ${SPACING[200]};
  }
`;

export const InlineCategoriesNav = props => {
  const { categories, activeSection, shop, settings } = props;
  const theme = useTheme();
  const [sticky, setSticky] = React.useState(false);
  const { t } = useTranslation(['shop', 'common']);

  const onStickyChange = status => {
    if (status.status === Sticky.STATUS_FIXED) {
      setSticky(true);
    } else {
      setSticky(false);
    }
  };

  const onTargetClick = target => {
    const el = document.getElementById(target);
    const top = el.offsetTop;
    window.scrollTo({
      top: top - 150,
      behavior: 'smooth',
    });
  };

  const nextOpenDay = React.useMemo(() => {
    return getNextOpeningDay(shop, t);
  }, [shop, t]);

  return (
    <Sticky
      enabled={true}
      top={59}
      onStateChange={onStickyChange}
      innerZ={10}
      className="inline-nav-block-light"
      innerActiveClass="inline-nav-block-active"
      bottomBoundary="#main-wrapper"
    >
      <InlineNavWrapper theme={theme} sticky={sticky}>
        <NavIntroShopResume isvisible={sticky}>
          <Avatar
            src={shop.logoURL}
            style={{ width: 24, height: 24, marginRight: SPACING[100] }}
          />
          <Heading size={500}>{shop.name}</Heading>
        </NavIntroShopResume>
        {!sticky && (
          <NavIntro size={700} color={theme.secondaryColor}>
            {t('shop:menu')}
          </NavIntro>
        )}
        {(!sticky || (!!shop.available && !!settings.open)) && (
          <FullPageHorizontalSlider
            theme={theme}
            width="100%"
            scrollbarH={'5px'}
            style={{
              marginLeft: 2,
            }}
          >
            {categories.map(category => (
              <InlineNavItem
                theme={theme}
                active={activeSection === category}
                onClick={() => onTargetClick(`category-${category}`)}
                key={`category-${category}`}
                lg={!sticky}
              >
                {category}
              </InlineNavItem>
            ))}
          </FullPageHorizontalSlider>
        )}
        {!!sticky && (!shop.available || !settings.open) ? (
          <>
            {!settings.open ? (
              <InfoLineBlock theme={theme}>
                <Heading size={400}>{`Service non disponible ❌`}</Heading>
                <Text size={400} muted>
                  Notre service est actuellement fermé, nous sommes ouverts tout
                  les jours de 11h30 à 22h* (sous réserves de conditions).
                </Text>
              </InfoLineBlock>
            ) : null}

            {!shop.available && !!settings.open ? (
              <InfoLineBlock theme={theme}>
                <Heading size={400}>{`Établissement fermé ❌`}</Heading>
                <Text size={400} muted>
                  Notre établissement est actuellement fermé,
                  {nextOpenDay ? (
                    <>
                      {' nous seront ouvert '}
                      <b>
                        {nextOpenDay.day} de {nextOpenDay.opening} à{' '}
                        {nextOpenDay.closing}
                      </b>
                    </>
                  ) : (
                    " nous serons ouvert d'ici peu. À bientôt !"
                  )}
                </Text>
              </InfoLineBlock>
            ) : null}
          </>
        ) : null}
      </InlineNavWrapper>
    </Sticky>
  );
};

export default InlineCategoriesNav;

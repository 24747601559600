import * as yup from 'yup';

import { DISCOUNT } from '../fields';

const discountSchema = yup.object({
  adminUserID: DISCOUNT.ADMINUID_FIELD_VALIDATION,
  code: DISCOUNT.CODE_FIELD_VALIDATION,
  deliveryFeesValue: DISCOUNT.DELIVERYFEESVALUE_FIELD_VALIDATION,
  appFeesValue: DISCOUNT.APPFEESVALUE_FIELD_VALIDATION,
  active: DISCOUNT.ACTIVE_FIELD_VALIDATION,
  minTotal: DISCOUNT.MINTOTAL_FIELD_VALIDATION,
  exclusive: DISCOUNT.EXCLUSIVE_FIELD_VALIDATION,
  maxUsageCount: DISCOUNT.MAXUSAGECOUNT_FIELD_VALIDATION,
  maxUsageCountByUser: DISCOUNT.MAXUSAGECOUNT_FIELD_VALIDATION,
  exclusiveTo: DISCOUNT.EXCLUSIVETO_FIELD_VALIDATION,
  exclusiveShop: DISCOUNT.EXCLUSIVESHOP_FIELD_VALIDATION,
});

export default discountSchema;

import React from 'react';
import styled from 'styled-components';
import { RotateSpinner } from 'react-spinners-kit';

import { SPACING } from '../styles/constants';
import { useTheme } from '../hooks';
import Text from './text';

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  padding: ${SPACING[300]} 0;
`;

const LoaderWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${SPACING[300]} 0;
`;

const ContentLoader = props => {
  const { text } = props;
  const theme = useTheme();

  return (
    <ContentWrapper>
      <LoaderWrapper>
        <RotateSpinner size={42} color={theme.primaryColor} loading />
      </LoaderWrapper>
      <Text
        muted
        sixe={400}
        styles={{ textAlign: 'center', marginTop: SPACING[200] }}
      >
        {text}
      </Text>
    </ContentWrapper>
  );
};

export default ContentLoader;

import React from 'react';
import ThemeContainer from './containers/ThemeContainer';
import BasketContainer from './containers/BasketContainer';

const UserContext = React.createContext({
  currentUser: null,
});

const AuthUserContext = React.createContext({ authUser: null });

const ThemeContext = React.createContext({});

export {
  UserContext,
  AuthUserContext,
  ThemeContext,
  ThemeContainer,
  BasketContainer,
};

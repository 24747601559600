import React from 'react';
import isEqual from 'lodash/isEqual';
import styled from 'styled-components';
import {
  DialogActions,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core';
import { Input, Button, Text, Backdrop } from '../../../../styleguide';
import { updateShopItemsCategoryOrders } from '../../../../actions/shopItems';
import {
  computeCategoriesWithOrder,
  orderedGroupedItems,
} from '../../../../actions/helpers/orderedGroupedItems';
import { FormWrapper } from '../../../../styles/form';
import { SPACING } from '../../../../styles/constants';
import { FlexFieldsWrapper } from '../../../../styles/shop';
import { parseNumber } from '../../../../common';

const CategoryLabelWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: ${SPACING[100]};

  span {
    width: 120px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`;

const CategoryRowWrapper = styled.div`
  width: fit-content;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const CategoriesModal = props => {
  const { isOpen, onClose, items } = props;
  const orderedItems = orderedGroupedItems(items);
  const initialCategoriesWithOrder = computeCategoriesWithOrder(orderedItems);
  const [isLoading, setLoading] = React.useState(false);
  const [categoriesWithOrder, updateCategoriesWithOrder] = React.useState(
    initialCategoriesWithOrder
  );

  const onSubmit = async () => {
    if (!isEqual(initialCategoriesWithOrder, categoriesWithOrder)) {
      setLoading(true);
      await updateShopItemsCategoryOrders(categoriesWithOrder, items);
      setLoading(false);
    }
    onClose();
  };

  const onChange = (category, value) => {
    const updatedOrders = categoriesWithOrder.map(v => {
      if (v.category === category) {
        v.order = parseNumber(value);
      }
      return v;
    });
    updateCategoriesWithOrder(updatedOrders);
  };

  const onCancel = () => {
    updateCategoriesWithOrder(initialCategoriesWithOrder);
    onClose();
  };

  return (
    <Dialog maxWidth="md" open={!!isOpen} onClose={onClose}>
      <DialogTitle>Réordonner les catégories</DialogTitle>
      <DialogContent dividers style={{ position: 'relative' }}>
        {isLoading && <Backdrop isContentBackdrop />}
        <DialogContentText>
          Pour définir l'ordre d'affichage des catégories, chacune d'entres
          elles contient un ordre (définis par un chiffre). <br />
          Elles seront ensuite triées par ordre croissant.
        </DialogContentText>
        <FormWrapper maxWidth="100%" paddingH={'0px'} paddingV={SPACING[200]}>
          <FlexFieldsWrapper style={{ gap: SPACING[200] }} padding="0px">
            {categoriesWithOrder.map(({ category, order }, index) => (
              <CategoryRowWrapper key={index}>
                <CategoryLabelWrapper>
                  <Text size={500}>{category}</Text>
                </CategoryLabelWrapper>
                <Input
                  type="number"
                  wrapperProps={{ justify: 'center', padding: '0px' }}
                  label="Ordre"
                  size="small"
                  variant="outlined"
                  required
                  placeholder="5"
                  value={order}
                  onChange={event => onChange(category, event.target.value)}
                  style={{ width: 'max-content', maxWidth: '120px' }}
                  inputProps={{
                    min: '0',
                  }}
                />
              </CategoryRowWrapper>
            ))}
          </FlexFieldsWrapper>
        </FormWrapper>
      </DialogContent>

      <DialogActions>
        <Button small onClick={onCancel} minimal disabled={isLoading}>
          Fermer
        </Button>
        <Button
          small
          type="submit"
          primary
          onClick={onSubmit}
          isLoading={isLoading}
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CategoriesModal;

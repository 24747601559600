import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

import { prepareDocForUpdate } from '../helpers/firestoreHelpers';
import ORDER_STATUS from './helpers/STATUS';

export const updateOrderDoc = async order => {
  try {
    return Firebase.firestore().runTransaction(async transaction => {
      const orderRef = await transaction.get(
        Firebase.firestore()
          .collection('orders')
          .doc(order.id)
      );

      if (!orderRef.exists) {
        throw new Error("❌ Cette commande n'existe pas");
      }

      await transaction.update(orderRef.ref, prepareDocForUpdate(order));
    });
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

export const getOrderDb = async order => {
  try {
    const orderRef = await Firebase.firestore()
      .collection('orders')
      .doc(order.id)
      .get();
    const orderDb = {
      ...orderRef.data(),
      id: orderRef.id,
    };

    return orderDb;
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

export const flagOrder = async (orderId, flag) => {
  const order = await getOrderDb({ id: orderId });

  if (!order) {
    throw new Error("❌ Cette commande n'existe pas");
  }

  if (order.inTreatment === flag) {
    return;
  }

  const updatedOrder = {
    ...order,
    inTreatment: flag,
  };

  try {
    await updateOrderDoc(updatedOrder);
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

const updateOrder = async (order, data, authID) => {
  ReactGA.event({
    category: 'Order',
    action: 'Update order',
  });

  const orderDb = await getOrderDb(order);

  if (
    !!orderDb?.inTreatment ||
    orderDb.status === ORDER_STATUS.canceled ||
    orderDb.status === ORDER_STATUS.delivered
  ) {
    throw new Error(
      `⏳ Impossible commande en cours de traitement, réessayez plus tard`
    );
  }

  await flagOrder(orderDb.id, true);

  try {
    await updateOrderDoc({
      ...orderDb,
      ...data,
      inTreatment: false,
    });
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

export default updateOrder;

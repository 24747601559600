// color variables

const colors = {
  green: '#34C47C',
  black: '#1A1824',
  red: '#FF2650',
  grey: '#7E818B',
  white: '#FFFFFF',
  lightGray: 'rgba(26, 24, 36, 20%)',
  lightGrayText: 'rgba(26, 24, 36, 50%)',
  lightWhite: 'rgba(255, 255, 255, 50%)',
  lightShadow: 'rgba(26, 24, 36, 60%)',
  darkShadow: 'rgba(255, 255, 255, 60%)',
  darkCardBg: 'rgba(74, 71, 88, 40%)',
};

export default colors;

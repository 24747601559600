import React from 'react';
import { IconButton } from '@material-ui/core';

import { useTheme } from '../../hooks';
import { DeleteIcon } from '../../styleguide/icons';
import { ItemActionsWrapper } from '../../styles/basket';

const ItemControls = props => {
  const { onDelete, small } = props;
  const theme = useTheme();

  return (
    <ItemActionsWrapper small={small}>
      <IconButton color="primary" onClick={onDelete} style={{ padding: 0 }}>
        <DeleteIcon color={theme.red} size={small ? '18px' : '24px'} />
      </IconButton>
      {/* TODO: -1 & +1 item */}
      {/* <IconButton
          color="primary"
          onClick={() => console.log('duplicate item')}
        >
          <MinusIcon color={theme.textColor} size="24px" />
        </IconButton>
        <ItemsCountWrapper>
          <Text muted>{1}</Text>
        </ItemsCountWrapper>
        <IconButton
          color="primary"
          onClick={() => console.log('delete item')}
        >
          <PlusIcon color={theme.textColor} size="24px" />
        </IconButton> */}
    </ItemActionsWrapper>
  );
};

export default ItemControls;

import React from 'react';
import Firebase from 'firebase/app';
import { DateTime } from 'luxon';
import add from 'lodash/add';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { DataGrid } from '@material-ui/data-grid';
import { IconButton } from '@material-ui/core';

import DashboardHeader from '../components/common/Header';
import StatCard from '../components/common/StatCard';
import BlockHeader from '../components/common/BlockHeader';
import TableFilters from '../components/common/TableFilters';
import { OrderDialog } from '../components/common/Dialogs';
import {
  DashboardPageContainer,
  DashboardPageBlockContainer,
  BlockStatsRow,
  BlockWrapper,
} from '../../../../styles/admin';
import { Container, NavLink } from '../../../../styleguide';
import { ReturnIcon } from '../../../../styleguide/icons';
import { useTheme } from '../../../../hooks';
import { parseNumber } from '../../../../common';
import { Page } from '../../../common';
import { SPACING } from '../../../../styles/constants';
import getReadableStatus from '../../../../actions/orders/helpers/getReadableStatus';
import percentage from '../../../../actions/orders/helpers/percentage';
import { getPercentageWithVAT } from '../../../../actions/orders/helpers/commissionHelpers';
import ORDER_STATUS from '../../../../actions/orders/helpers/STATUS';

const { REACT_APP_STRIPE_DASHBOARD_URL } = process.env;

const columns = [
  {
    field: 'orderAt',
    headerName: 'Crée à',
    width: 150,
  },
  {
    field: 'name',
    headerName: 'Nom client',
    width: 200,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
  },
  {
    field: 'discount',
    headerName: 'Promotion 🏷️',
    width: 150,
    align: 'center',
    valueFormatter: ({ value }) => value || '--',
  },
  {
    field: 'deliveryFees',
    headerName: 'Frais livraison',
    width: 150,
    valueFormatter: ({ value }) => `${parseNumber(value).toFixed(2)}€`,
  },
  {
    field: 'appFees',
    headerName: 'Frais app',
    width: 150,
    valueFormatter: ({ value }) => `${parseNumber(value).toFixed(2)}€`,
  },
  {
    field: 'total',
    headerName: 'Montant',
    width: 150,
    valueFormatter: ({ value }) => `${parseNumber(value).toFixed(2)}€`,
  },
  {
    field: 'amount',
    headerName: '💸💸💸💸💸',
    width: 200,
    valueFormatter: ({ value: { commission, deliveryFees } }) =>
      `${parseNumber(parseNumber(commission).toFixed(2))}€ 
        ${
          deliveryFees > 0 ? ` + ${parseNumber(deliveryFees).toFixed(2)}€` : ''
        }`,
  },
  {
    field: 'shopName',
    headerName: 'Shop',
    width: 200,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 250,
  },
  {
    field: 'clientID',
    headerName: 'ID client',
    width: 300,
  },
  {
    field: 'shopID',
    headerName: 'ID shop',
    width: 250,
  },
  {
    field: 'paymentURL',
    headerName: 'Voir le paiement',
    width: 250,
  },
  {
    field: 'rusher',
    headerName: 'ID livreur',
    width: 250,
  },
  {
    field: 'canceledBy',
    headerName: 'Annulé par',
    width: 200,
  },
  {
    field: 'readyAt',
    headerName: 'Prêt á',
    width: 200,
  },
  {
    field: 'pickedAt',
    headerName: 'Récupéré à',
    width: 200,
  },
  {
    field: 'deliverAt',
    headerName: 'Livré à',
    width: 200,
  },
];

export const getBestShop = orders => {
  const shops = orders.reduce((acc, order) => {
    if (!acc[order.shop.name]) {
      acc[order.shop.name] = 0;
    }
    acc[order.shop.name] += 1;
    return acc;
  }, {});

  const bestShop = Object.keys(shops).reduce(
    (acc, shop) => {
      if (shops[shop] > acc.count) {
        acc.count = shops[shop];
        acc.name = shop;
      }
      return acc;
    },
    { count: 0, name: '' }
  );

  return bestShop.name;
};

export const getBestCity = orders => {
  const cities = orders.reduce((acc, order) => {
    if (!acc[order.selectedAddress.city]) {
      acc[order.selectedAddress.city] = 0;
    }
    acc[order.selectedAddress.city] += 1;
    return acc;
  }, {});

  const bestCity = Object.keys(cities).reduce(
    (acc, city) => {
      if (cities[city] > acc.count) {
        acc.count = cities[city];
        acc.name = city;
      }
      return acc;
    },
    { count: 0, name: '' }
  );

  return bestCity.name;
};

const OrdersDashboard = () => {
  const [editDialog, setEditDialog] = React.useState(null);
  const [displayedCount, setDisplayedCount] = React.useState(50);
  const theme = useTheme();

  const getAmount = order => {
    const commission = percentage(
      getPercentageWithVAT(parseNumber(order.shop.percentage)),
      parseNumber(order.total)
    );

    const deliveryFees =
      !!order.rusher && !!order.rusher.isAdmin ? order.deliveryFees : 0;

    return {
      commission: parseNumber(add(commission, order.appFees)),
      deliveryFees,
    };
  };

  const [orders, ordersLoading, ordersError] = useCollectionData(
    Firebase.firestore()
      .collection('orders')
      .orderBy('orderAt', 'desc')
      .limit(displayedCount),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const {
    rows,
    newOrdersCount,
    canceledCount,
    bestShop,
    bestCity,
  } = React.useMemo(() => {
    if (ordersLoading || !orders || !!ordersError) {
      return {
        rows: [],
        newOrdersCount: 0,
        canceledCount: 0,
        bestShop: '',
        bestCity: '',
      };
    }

    const rows = orders.map(order => ({
      id: order.id,
      clientID: order.clientID,
      shopID: order.shop.id,
      shopName: order.shop.name,
      name: order.name || 'Nom client',
      orderAt: DateTime.fromMillis(order.orderAt).toLocaleString(
        DateTime.DATETIME_SHORT
      ),
      status: getReadableStatus(order.status),
      deliveryFees: order.deliveryFees,
      appFees: order.appFees,
      discount: order.discount?.code,
      total: order.total,
      amount: getAmount(order),
      paymentURL: `${REACT_APP_STRIPE_DASHBOARD_URL}/payments/${order.paymentIntentId}`,
      rusher: !!order.rusher ? order.rusher.id : 'Pas encore de livreur',
      rusherDetails: !!order.rusher ? order.rusher : 'Pas encore de livreur',
      items: order.itemsList,
      canceledBy: order.canceledBy || '--',
      readyAt: !!order.readyAt
        ? DateTime.fromMillis(order.readyAt).toLocaleString(
            DateTime.DATETIME_SHORT
          )
        : '-',
      pickedAt: !!order.pickedAt
        ? DateTime.fromMillis(order.pickedAt).toLocaleString(
            DateTime.DATETIME_SHORT
          )
        : '-',
      deliverAt: !!order.deliverAt
        ? DateTime.fromMillis(order.deliverAt).toLocaleString(
            DateTime.DATETIME_SHORT
          )
        : '-',
    }));
    const canceledOrders = orders.filter(
      order => order.status === ORDER_STATUS.canceled
    );
    const bestShop = getBestShop(orders);
    const bestCity = getBestCity(orders);

    return {
      rows: rows,
      newOrdersCount: orders.length,
      canceledCount: canceledOrders.length,
      bestShop,
      bestCity,
    };
  }, [orders, ordersLoading, ordersError]);

  if (ordersError) {
    throw new Error(ordersError.toString());
  }

  const onRowClick = params => {
    const order = orders.find(doc => doc.id === params.row.id);
    setEditDialog({ data: params.row, orderDB: order });
  };

  return (
    <Page
      name="Les commandes"
      navbarLeftChild={
        <NavLink
          color={theme.headingColor}
          to={{ pathname: '/admin/dashboard' }}
        >
          <IconButton aria-label="retour">
            <ReturnIcon color={theme.headingColor} size="16px" />
          </IconButton>
        </NavLink>
      }
    >
      <Container justify="flex-start">
        <DashboardHeader title="Les commandes" withBackButton />

        <DashboardPageContainer theme={theme}>
          <BlockWrapper theme={theme}>
            <BlockHeader
              title={"Vue d'ensemble"}
              label={`Sur les ${displayedCount} dernières commandes`}
            />
            <BlockStatsRow>
              <StatCard label={'Total'} value={newOrdersCount} />
              <StatCard label={'Annulé'} value={canceledCount} />
              <StatCard label={'Établissement'} value={bestShop} small />
              <StatCard label={'Ville'} value={bestCity} small />
            </BlockStatsRow>
          </BlockWrapper>

          <BlockWrapper theme={theme} style={{ marginTop: SPACING[200] }}>
            <BlockHeader title={'Commandes'} label={`Tableau de bord`} />

            <TableFilters
              displayedCount={displayedCount}
              setDisplayedCount={setDisplayedCount}
            />

            <DashboardPageBlockContainer>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={25}
                stickyHeader
                loading={ordersLoading}
                onRowClick={onRowClick}
              />
            </DashboardPageBlockContainer>
          </BlockWrapper>

          {editDialog ? (
            <OrderDialog
              order={editDialog}
              isOpen={!!editDialog}
              onClose={() => setEditDialog(null)}
            />
          ) : null}
        </DashboardPageContainer>
      </Container>
    </Page>
  );
};

export default OrdersDashboard;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Button, Input } from '../../styleguide';
import { FormWrapper, ButtonsWrapper } from '../../styles/form';

const LoginForm = props => {
  const { onSubmit } = props;
  const [credentials, setCredentials] = React.useState({ email: '', pwd: '' });
  const { t } = useTranslation(['login', 'common']);

  const history = useHistory();

  const onSubmitForm = event => {
    const { email, pwd } = credentials;

    event.preventDefault();
    onSubmit(email, pwd);
  };

  const onPasswordReset = () => history.push('/reset_password');

  const canSubmit = !!credentials.email && !!credentials.pwd;

  return (
    <FormWrapper onSubmit={onSubmitForm}>
      <Input
        label={t('login:email')}
        size="small"
        variant="outlined"
        required
        placeholder="john@doe.com"
        type="email"
        fullWidth
        value={credentials.email}
        onChange={event =>
          setCredentials({ ...credentials, email: event.target.value })
        }
      />

      <Input
        label={t('login:password')}
        required
        placeholder="*********"
        type="password"
        size="small"
        variant="outlined"
        fullWidth
        value={credentials.pwd}
        onChange={event =>
          setCredentials({ ...credentials, pwd: event.target.value })
        }
      />

      <ButtonsWrapper>
        <Button
          minimal
          styles={{ marginBottom: '8px' }}
          onClick={onPasswordReset}
        >
          {t('login:forgot_password')}
        </Button>
        <Button type="submit" disabled={!canSubmit}>
          {t('login:login')}
        </Button>
      </ButtonsWrapper>
    </FormWrapper>
  );
};

export default LoginForm;

import React from 'react';
import { Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { SPACING } from '../../../../../styles/constants';
import { Heading } from '../../../../../styleguide';
import { useTheme } from '../../../../../hooks';
import {
  StepContentWrapper,
  ItemsWrapper,
  DetailsWrapper,
} from '../../../../../styles/order-tracking';
import CanceledIllustration from '../../../../../assets/images/orders/Cancel.svg';
import ProductLine from '../../../../basket/ProductLine';
import MenuLine from '../../../../basket/MenuLine';
import {
  Totals,
  PaymentMethodRow,
  IllustrationHeader,
  Header,
  AddressesBlock,
} from './Commons';

const CanceledStep = props => {
  const { order } = props;
  const theme = useTheme();
  const { t } = useTranslation(['orders']);

  return (
    <StepContentWrapper>
      <IllustrationHeader imgSrc={CanceledIllustration} />

      <Header
        order={order}
        customTitle={
          <Heading size={600}>
            {t('orders:tracking:your_order_at')}
            <span style={{ color: theme.primaryColor }}>{order.shop.name}</span>
            {t('orders:tracking:was_cancelled')} {'❌'}
          </Heading>
        }
      />
      <Divider style={{ width: '100%', marginBottom: `${SPACING[400]}` }} />

      <ItemsWrapper>
        {order.itemsList.map((shopItem, index) =>
          shopItem.item.isMenu ? (
            <>
              <MenuLine key={index} shopItem={shopItem} small />
              {order.itemsList.length - 1 !== index && (
                <Divider style={{ width: '100%', margin: `${SPACING[100]}` }} />
              )}
            </>
          ) : (
            <>
              <ProductLine key={index} shopItem={shopItem} small />
              {order.itemsList.length - 1 !== index && (
                <Divider style={{ width: '100%', margin: `${SPACING[100]}` }} />
              )}
            </>
          )
        )}
      </ItemsWrapper>
      <Divider style={{ width: '100%', marginBottom: `${SPACING[300]}` }} />

      <AddressesBlock order={order} />
      <Divider style={{ width: '100%', margin: `${SPACING[300]} 0` }} />

      <DetailsWrapper>
        <PaymentMethodRow paymentMethod={order.paymentMethod} />
      </DetailsWrapper>
      <Divider style={{ width: '100%', marginTop: `${SPACING[300]}` }} />

      <Totals order={order} />
      <Divider style={{ width: '100%', margin: `${SPACING[100]}` }} />
    </StepContentWrapper>
  );
};

export default CanceledStep;

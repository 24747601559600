import React from 'react';
import { add } from 'lodash';
import { Divider, Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { computeTimeFrom } from '../../../../../actions/orders/helpers/computeTimesHelpers';
import { Heading, Text } from '../../../../../styleguide';
import { SPACING } from '../../../../../styles/constants';
import {
  LocationIcon,
  CardIcon,
  ShopIcon,
  HomeIcon,
  DiscountIcon,
} from '../../../../../styleguide/icons';
import { useTheme } from '../../../../../hooks';
import {
  IllustationWrapper,
  Illustration,
  HeaderDetailsWrapper,
  SublineHeaderWrapper,
  DetailsWrapper,
  BlockCard,
  AddressDetailsWrapper,
  TotalsWrapper,
  PriceLine,
} from '../../../../../styles/order-tracking';
import { parseNumber } from '../../../../../common';

const AddressRow = props => {
  const { icon, name, address, blockProps } = props;
  const theme = useTheme();

  return (
    <BlockCard theme={theme} {...blockProps}>
      {icon}
      <AddressDetailsWrapper>
        <Text muted size={300} styles={{ display: 'inline-flex' }}>
          {name}
        </Text>
        <Text size={400} styles={{ color: theme.headingColor, marginTop: 2 }}>
          {address.street}, {address.postalCode} {address.city}
        </Text>
      </AddressDetailsWrapper>
    </BlockCard>
  );
};

const PaymentMethodRow = props => {
  const { paymentMethod } = props;
  const theme = useTheme();

  return (
    <BlockCard theme={theme}>
      <CardIcon
        margin={`0 ${SPACING[200]} 0 ${SPACING[100]}`}
        size="24px"
        color={theme.primaryColor}
      />
      <AddressDetailsWrapper>
        <Text muted size={300} styles={{ display: 'inline-flex' }}>
          {paymentMethod.billing_details.name}
        </Text>
        <Text size={400} styles={{ color: theme.headingColor }}>
          {`•••• •••• •••• ${paymentMethod.card.last4} - ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}
        </Text>
      </AddressDetailsWrapper>
    </BlockCard>
  );
};

const Totals = props => {
  const { order } = props;
  const theme = useTheme();
  const { t } = useTranslation(['common', 'orders']);

  const computedFees = parseNumber(
    add(parseNumber(order.deliveryFees), parseNumber(order.appFees))
  );
  const computedTotal = parseNumber(
    add(parseNumber(order.total), parseNumber(computedFees))
  );

  return (
    <TotalsWrapper theme={theme}>
      {order.discount && (
        <div
          style={{
            width: '100%',
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: SPACING[100],
          }}
        >
          <Text muted size={400}>
            {`${t('orders:tracking:discount')} :`}
          </Text>
          <Chip
            label={`${order.discount.code}`}
            variant="outlined"
            icon={
              <DiscountIcon
                padding="0 0 0 4px"
                style={{
                  transformx: 'scale(1.3)',
                }}
              />
            }
          />
        </div>
      )}
      <PriceLine>
        <Text muted>{`${t('orders:tracking:subtotal')} :`}</Text>
        <Text>{parseNumber(order.total).toFixed(2)}€</Text>
      </PriceLine>
      <PriceLine>
        <div styles={{ display: 'flex' }}>
          <Text muted>{`${t('orders:tracking:app_fees')} :`}</Text>
          {order.discount && (
            <Chip
              size="small"
              label={`-${order.discount.appFeesValue}%`}
              color={'primary'}
              variant={'outlined'}
              style={{ marginLeft: `${SPACING[100]}` }}
            />
          )}
        </div>
        <div styles={{ display: 'flex' }}>
          <Text>{parseNumber(order.appFees).toFixed(2)}€</Text>
        </div>
      </PriceLine>
      <PriceLine>
        <div styles={{ display: 'flex' }}>
          <Text muted>
            {t('orders:tracking:delivery')} -{' '}
            <b>{Math.round(order.distance / 100) / 10}km</b>
          </Text>
          {order.discount && (
            <Chip
              size="small"
              label={`-${order.discount.deliveryFeesValue}%`}
              color={'primary'}
              variant={'outlined'}
              style={{ marginLeft: `${SPACING[100]}` }}
            />
          )}
        </div>
        <div styles={{ display: 'flex' }}>
          <Text>{parseNumber(order.deliveryFees).toFixed(2)}€</Text>
        </div>
      </PriceLine>
      <PriceLine style={{ marginTop: SPACING[100] }}>
        <Heading style={{ color: theme.headingColor }} size={600}>
          {t('orders:tracking:total')} :
        </Heading>
        <Heading style={{ color: theme.primaryColor }} size={600}>
          {parseNumber(computedTotal).toFixed(2)}€
        </Heading>
      </PriceLine>
    </TotalsWrapper>
  );
};

const Details = props => {
  const { order } = props;
  const theme = useTheme();

  return (
    <DetailsWrapper>
      <AddressRow
        blockProps={{ margin: 0 }}
        icon={
          <LocationIcon
            margin={`0 ${SPACING[200]} 0 ${SPACING[100]}`}
            size="24px"
            color={theme.primaryColor}
          />
        }
        name={order.selectedAddress.name}
        address={order.selectedAddress}
      />

      <PaymentMethodRow paymentMethod={order.paymentMethod} />
    </DetailsWrapper>
  );
};

const IllustrationHeader = props => {
  const { imgSrc } = props;
  return (
    <IllustationWrapper>
      <Illustration
        src={imgSrc}
        alt="Illustration en cours de préparation"
        lazy
      />
    </IllustationWrapper>
  );
};

const Header = props => {
  const { order, title, customTitle, emoji, isDelivery } = props;
  const theme = useTheme();
  const orderAt = computeTimeFrom({ ts: order.orderAt });

  const itemsCount = order.itemsList.length;
  return (
    <HeaderDetailsWrapper>
      {customTitle || (
        <Heading size={600}>
          {title}{' '}
          {!isDelivery && (
            <span style={{ color: theme.primaryColor }}>{order.shop.name}</span>
          )}
          {emoji ? (
            <span role="img" aria-label="emoji">
              {'  '}
              {emoji}
            </span>
          ) : null}
        </Heading>
      )}

      <SublineHeaderWrapper>
        <Text muted size={400}>
          {`${orderAt} - ${itemsCount} article${itemsCount > 1 ? 's' : ''}`}
        </Text>
      </SublineHeaderWrapper>
    </HeaderDetailsWrapper>
  );
};

const AddressesBlock = props => {
  const { order } = props;
  const theme = useTheme();
  const { t } = useTranslation(['orders']);

  return (
    <DetailsWrapper>
      <AddressRow
        blockProps={{ margin: 0 }}
        icon={
          <ShopIcon
            margin={`0 ${SPACING[200]} 0 ${SPACING[100]}`}
            size="24px"
            color={theme.primaryColor}
          />
        }
        name={t('orders:tracking:shop_address')}
        address={order.shop.address}
      />
      <AddressRow
        blockProps={{ margin: 0 }}
        icon={
          <HomeIcon
            margin={`0 ${SPACING[200]} 0 ${SPACING[100]}`}
            size="24px"
            color={theme.primaryColor}
          />
        }
        name={order.selectedAddress.name}
        address={order.selectedAddress}
      />
    </DetailsWrapper>
  );
};

const MessageBlock = props => {
  const { order } = props;
  const theme = useTheme();
  const { t } = useTranslation(['common', 'orders']);

  if (!order.message) {
    return null;
  }

  return (
    <>
      <DetailsWrapper align="flex-start" padding={`${SPACING[200]} 0`}>
        <Heading size={500}>{t('orders:tracking:instructions')}</Heading>

        <SublineHeaderWrapper bg={theme.lightGray}>
          <Text muted size={400}>
            {order.message}
          </Text>
        </SublineHeaderWrapper>
      </DetailsWrapper>
      <Divider style={{ width: '100%', margin: `${SPACING[100]}` }} />
    </>
  );
};

export {
  Totals,
  Details,
  IllustrationHeader,
  Header,
  AddressesBlock,
  PaymentMethodRow,
  MessageBlock,
};

import styled from 'styled-components';

import { SPACING, colors } from './constants';

const HeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  padding: ${SPACING[200]} 0;
  width: 100%;
  max-width: 600px;
  margin: 0 auto;
`;

const MainWrapper = styled.div`
  max-width: 700px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: 0 auto;
  padding: ${props => (props.padding ? `0 ${SPACING[100]}` : 'initial')};
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: ${({ direction }) => direction || 'column'};
  margin-top: ${SPACING[200]};
`;

const LineWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: ${props => props.align || 'flex-start'};
  justify-content: ${props => props.justify || 'flex-start'};
  padding: ${SPACING[100]};
`;

const ItemImage = styled.div`
  width: ${props => props.w || '70px'};
  height: ${props => props.h || '50px'};
  background: url(${props => props.imageSRC});
  border-radius: 4px;
  background-position: center;
  background-size: cover;
  margin-right: ${props => (props.small ? SPACING[100] : SPACING[200])};
  display: flex;
  box-shadow: ${props => props.theme.boxShadow.md};
  background-color: ${({ theme }) => theme.black};
`;

const ItemContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const ItemHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ItemDetails = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding-left: ${({ paddingLeft }) =>
    paddingLeft ? SPACING[100] : 'initial'};
`;

const ItemFooter = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${props => (props.flexEnd ? 'flex-end' : 'space-between')};
  align-items: center;
`;

const ItemActionsWrapper = styled.div`
  padding: ${props => (props.small ? `0 ${SPACING[100]}` : SPACING[100])};
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ItemsCountWrapper = styled.div`
  border-radius: 50px;
  width: 30px;
  height: 30px;
  background-color: ${colors.lightGray};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const TotalsWrapper = styled.div`
  width: calc(100% - ${SPACING[200]});
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 ${SPACING[100]};
  padding-top: ${SPACING[200]};
  margin-top: ${SPACING[100]};
  border-top: 1px solid ${({ theme }) => theme.lightGray};
`;

const TitleColumn = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const PricesColumm = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: flex-start;
`;

const MenuDetails = styled.div`
  width: 100%;
  padding: ${props => (props.withoutPadding ? 0 : SPACING[100])};
  padding-left: ${({ paddingLeft }) =>
    paddingLeft ? SPACING[200] : 'initial'};
  margin: 0;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`;

const MenuDetailsItem = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
`;

const InlineFlexRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${({ justify }) => justify || 'flex-start'};
  align-items: ${({ align }) => align || 'center'};
  flex-wrap: ${({ wrap }) => wrap || 'nowrap'};
  ${({ otherStyles }) => (!!otherStyles ? otherStyles : '')}
`;

const TotalsRowsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: ${SPACING[100]};
  padding-top: ${SPACING[200]};
  margin-top: ${SPACING[100]};
  border-top: 1px solid ${({ theme }) => theme.lightGray};
`;

const TotalsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${SPACING[100]};
`;

export {
  HeaderWrapper,
  ContentWrapper,
  ButtonsWrapper,
  MainWrapper,
  LineWrapper,
  ItemImage,
  ItemContent,
  ItemHeader,
  ItemDetails,
  ItemFooter,
  ItemActionsWrapper,
  ItemsCountWrapper,
  TotalsWrapper,
  TitleColumn,
  PricesColumm,
  MenuDetails,
  MenuDetailsItem,
  InlineFlexRow,
  TotalsRowsWrapper,
  TotalsRow,
};

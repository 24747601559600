import React from 'react';
import {
  Chip,
  Dialog,
  DialogContent,
  DialogActions,
  Divider,
} from '@material-ui/core';
import orderBy from 'lodash/orderBy';
import { useTranslation } from 'react-i18next';

import { Heading, Button, Text } from '../../../../styleguide';
import { useTheme } from '../../../../hooks';
import { parseNumber } from '../../../../common';
import { SPACING } from '../../../../styles/constants';
import { ItemImage } from '../../../../styles/basket';
import {
  ShopProfilDialogSupplementsWrapper,
  ShopProfilTotalPrice,
  ShopProfilDialogHeader,
} from '../../../../styles/shop-profil';
import { FlexFieldsWrapper } from '../../../../styles/shop';
import SupplementsDialogContent from './SupplementsDialogContent';
import OptionsDialogContent from './OptionsDialogContent';

const ProductDialog = props => {
  const {
    onConfirm,
    selectedItem,
    setSelectedItem,
    onResetSelectedItem,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation(['common', 'shop', 'categories']);

  React.useEffect(() => {
    if (!!selectedItem.item) {
      if (selectedItem.total < selectedItem.initialItemPrice) {
        onResetSelectedItem();
      }
    }
  }, [selectedItem, onResetSelectedItem]);

  const onConfirmSelection = () => {
    onConfirm(selectedItem);
    onResetSelectedItem();
  };

  const onCancelSelection = () => {
    onResetSelectedItem();
  };

  const options = orderBy(selectedItem?.item?.options, i => i.order, 'asc');
  const supplements = selectedItem?.item?.supplements;
  const hasRequiredOptions = !!options?.find(o => o.required);

  const canAdd = !!hasRequiredOptions
    ? !!selectedItem?.options?.filter(o => !!o.required)?.every(o => !!o.max)
    : true;

  const { total, initial, supplementsTotal } = React.useMemo(() => {
    const total = selectedItem?.total || 0;
    const initial = selectedItem?.initialItemPrice || 0;
    const supplements = total - initial;

    return {
      total: parseNumber(total),
      initial: parseNumber(initial),
      supplementsTotal: parseNumber(supplements),
    };
  }, [selectedItem]);

  return (
    <Dialog
      open={!!selectedItem.item}
      onClose={() =>
        setSelectedItem({
          item: null,
          supplements: null,
          options: null,
          total: 0,
        })
      }
      fullWidth
      maxWidth="sm"
    >
      <ShopProfilDialogHeader>
        <FlexFieldsWrapper
          align="center"
          width="fit-content"
          padding="0px"
          wrap="no-wrap"
        >
          <ItemImage
            small
            w={'50px'}
            h={'40px'}
            imageSRC={selectedItem.item.pictureURL}
            theme={theme}
          />
          <Heading size={500}>{selectedItem.item.name}</Heading>
        </FlexFieldsWrapper>
        <Chip size="small" label={`${total.toFixed(2)}€`} />
      </ShopProfilDialogHeader>

      <DialogContent dividers>
        <div
          style={{
            padding: `${SPACING[200]} 0`,
          }}
        >
          <Text size={500} muted>
            {selectedItem.item.description}
          </Text>
        </div>

        {!!options?.length &&
          options.map(option => (
            <OptionsDialogContent
              key={option.id}
              option={option}
              selectedItem={selectedItem}
              setSelectedItem={setSelectedItem}
            />
          ))}

        {!!supplements?.length && (
          <>
            <ShopProfilDialogSupplementsWrapper>
              <Divider
                style={{
                  width: '100%',
                  margin: `${SPACING[200]} 0`,
                }}
              />
              <FlexFieldsWrapper padding={`${SPACING[100]} 4px`}>
                <Heading size={500}>Suppléments</Heading>
                <Chip
                  variant="outlined"
                  size="small"
                  label={t('shop:optionnal')}
                  style={{ marginLeft: SPACING[100] }}
                />
              </FlexFieldsWrapper>

              {supplements.map((supplement, index) => (
                <SupplementsDialogContent
                  key={index}
                  selectedItem={selectedItem}
                  setSelectedItem={setSelectedItem}
                  supplement={supplement}
                />
              ))}
            </ShopProfilDialogSupplementsWrapper>
            <Divider
              style={{
                width: '100%',
                margin: `${SPACING[200]} 0`,
              }}
            />
          </>
        )}

        {supplementsTotal > 0 ? (
          <ShopProfilTotalPrice>
            <Heading size={500} styles={{ marginRight: SPACING[100] }}>
              {t('shop:subtotal')}:{' '}
            </Heading>
            <Text size={500}>
              {`${initial.toFixed(2)}€ ${
                supplementsTotal > 0 ? ` + ${supplementsTotal.toFixed(2)}€` : ''
              }`}
            </Text>
          </ShopProfilTotalPrice>
        ) : null}
        <ShopProfilTotalPrice>
          <Heading size={500} styles={{ marginRight: SPACING[100] }}>
            Total:{' '}
          </Heading>
          <Text size={500}>{`${total.toFixed(2)}€`}</Text>
        </ShopProfilTotalPrice>
      </DialogContent>
      <DialogActions>
        <Button onClick={onCancelSelection} minimal small>
          {t('shop:cancel')}
        </Button>
        <Button onClick={onConfirmSelection} primary small disabled={!canAdd}>
          {t('shop:add_to_basket')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ProductDialog;

import React from 'react';
import Firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { DataGrid } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import { subtract } from 'lodash';
import { IconButton } from '@material-ui/core';

import { Container, NavLink } from '../../../../styleguide';
import { ReturnIcon } from '../../../../styleguide/icons';
import DashboardHeader from '../components/common/Header';
import { Page } from '../../../common';
import { useTheme } from '../../../../hooks';
import { updateRusher } from '../../../../actions/rusher';
import InvoicesDialog from '../components/common/InvoicesDialog';
import { RusherDialog } from '../components/common/Dialogs';
import StatCard from '../components/common/StatCard';
import BlockHeader from '../components/common/BlockHeader';
import {
  DashboardPageContainer,
  DashboardPageBlockContainer,
  BlockStatsRow,
  BlockWrapper,
} from '../../../../styles/admin';
import { SPACING } from '../../../../styles/constants';
import { parseNumber, INVOICES_STATUS } from '../../../../common';

const invoicesColumns = [
  {
    field: 'generatedAt',
    headerName: 'Crée le',
    width: 250,
  },
  {
    field: 'commission',
    headerName: 'Commission',
    width: 150,
    align: 'center',
    valueFormatter: ({ value }) => `${parseNumber(value).toFixed(2)}€`,
  },
  {
    field: 'total',
    headerName: 'Total HT',
    width: 150,
    align: 'center',
    valueFormatter: ({ value }) => `${parseNumber(value).toFixed(2)}€`,
  },
  {
    field: 'net',
    headerName: 'Total Net',
    width: 150,
    align: 'center',
    valueFormatter: ({ value }) => `${parseNumber(value).toFixed(2)}€`,
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'center',
    width: 200,
    valueFormatter: ({ value }) => INVOICES_STATUS[value],
  },
  {
    field: 'from',
    headerName: 'A partir du',
    width: 200,
  },
  {
    field: 'to',
    headerName: "Jusqu'au",
    width: 200,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 250,
  },
  {
    field: 'rusherID',
    headerName: 'rusher ID',
    width: 250,
  },
];

const rushersColumns = [
  {
    field: 'name',
    headerName: 'Nom',
    width: 200,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 300,
  },

  {
    field: 'isWorked',
    headerName: 'Actif',
    width: 150,
    align: 'center',
  },
  {
    field: 'available',
    headerName: 'Activé 🔐',
    width: 150,
    align: 'center',
  },
  {
    field: 'isVerified',
    headerName: 'Vérifié',
    width: 150,
    align: 'center',
  },
  {
    field: 'isPaidVAT',
    headerName: 'Soumis TVA',
    width: 150,
    align: 'center',
  },
  {
    field: 'percentage',
    headerName: 'Commission 💸',
    width: 150,
    align: 'center',
    valueFormatter: ({ value }) => `${value}%`,
  },
  {
    field: 'baseCity',
    headerName: 'Ville de base',
    width: 200,
  },
  {
    field: 'phone',
    headerName: 'Telephone',
    width: 300,
  },
];

const RushersDashboard = () => {
  const [invoiceDialog, setInvoiceDialog] = React.useState(null);
  const [rusherDialog, setRusherDialog] = React.useState(null);
  const theme = useTheme();

  const [invoices, invoicesLoading, invoicesError] = useCollectionData(
    Firebase.firestore()
      .collection('rushersInvoices')
      .where('status', '!=', 'pending')
      .orderBy('status', 'desc')
      .orderBy('createdAt', 'desc'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [rushers, rushersLoading, rushersError] = useCollectionData(
    Firebase.firestore().collection('rushers'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const { invoicesRow, payed, toPay } = React.useMemo(() => {
    if (invoicesLoading || invoicesError || !invoices) {
      return { invoicesRow: [], payed: 0, toPay: 0 };
    }

    const rows = invoices.map(invoice => ({
      number: invoice.number,
      id: invoice.id,
      generatedAt: DateTime.fromMillis(invoice.generatedAt).toLocaleString(
        DateTime.DATETIME_MED
      ),
      orders: invoice.orders,
      total: invoice.total,
      net: subtract(invoice.total, invoice.commission),
      from: DateTime.fromMillis(invoice.period.from).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
      to: DateTime.fromMillis(invoice.period.to).toLocaleString(
        DateTime.DATE_MED_WITH_WEEKDAY
      ),
      status: invoice.status,
      commission: invoice.commission,
      rusherID: invoice.rusherID,
    }));

    const toPay = rows.filter(row => row.status === 'sent').length;
    const payed = rows.filter(row => row.status === 'paid').length;

    return { invoicesRow: rows, payed, toPay };
  }, [invoicesLoading, invoicesError, invoices]);

  const { rows, rushersCount, availablesCount } = React.useMemo(() => {
    if (rushersLoading || !rushers || !!rushersError) {
      return {
        rows: [],
        rushersCount: 0,
        availablesCount: 0,
      };
    }

    const rows = rushers.map(rusher => ({
      id: rusher.id,
      name: rusher.name,
      available: rusher.available ? '✅' : '❌',
      isWorked: rusher.isWorked ? '✅' : '❌',
      baseCity: rusher.baseCity,
      phone: rusher.phone,
      isVerified: rusher.isVerified ? '✅' : '❌',
      percentage: rusher.percentage,
      isPaidVAT: rusher.isPaidVAT ? '✅' : '❌',
      pictureURL: rusher.pictureURL,
    }));

    const rushersCount = rushers.length;
    const availablesCount = rushers.filter(rusher => rusher.available).length;

    return {
      rows,
      rushersCount,
      availablesCount,
    };
  }, [rushers, rushersLoading, rushersError]);

  const onInvoiceRowClick = params => {
    setInvoiceDialog({ updatedData: null, data: params.row });
  };

  const onRusherRowClick = params => {
    setRusherDialog({ updatedData: null, data: params.row });
  };

  const onConfirmRusherUpdate = async () => {
    if (rusherDialog.data !== rusherDialog.updatedData) {
      updateRusher({
        id: rusherDialog.data.id,
        ...(rusherDialog.updatedData.isVerified
          ? {
              isVerified:
                rusherDialog.updatedData.isVerified === '❌' ? false : true,
            }
          : {}),
        ...(rusherDialog.updatedData.percentage
          ? {
              percentage: rusherDialog.updatedData.percentage,
            }
          : {}),
        ...(rusherDialog.updatedData.available
          ? {
              available:
                rusherDialog.updatedData.available === '❌' ? false : true,
            }
          : {}),
        ...(rusherDialog.updatedData.isPaidVAT
          ? {
              isPaidVAT:
                rusherDialog.updatedData.isPaidVAT === '❌' ? false : true,
            }
          : {}),
      });
    }
    setRusherDialog(null);
  };

  if (invoicesError) {
    throw new Error(invoicesError.toString());
  }

  return (
    <Page
      name="Les livreurs"
      navbarLeftChild={
        <NavLink
          color={theme.headingColor}
          to={{ pathname: '/admin/dashboard' }}
        >
          <IconButton aria-label="retour">
            <ReturnIcon color={theme.headingColor} size="16px" />
          </IconButton>
        </NavLink>
      }
    >
      <Container justify="flex-start">
        <DashboardHeader title="Les livreurs" withBackButton />

        <DashboardPageContainer theme={theme}>
          <BlockWrapper theme={theme}>
            <BlockHeader
              title={"Vue d'ensemble"}
              label={`Sur tous les livreurs`}
            />
            <BlockStatsRow>
              <StatCard label={'Livreurs'} value={rushersCount} />
              <StatCard label={'Dispo'} value={availablesCount} />
              <StatCard label={'Fact. Payé'} value={payed} />
              <StatCard label={'À payer'} value={toPay} />
            </BlockStatsRow>
          </BlockWrapper>

          <BlockWrapper theme={theme} style={{ marginTop: SPACING[200] }}>
            <BlockHeader title={'Livreurs'} label={`Tableau de bord`} />

            <DashboardPageBlockContainer height="50vh">
              <DataGrid
                rows={rows}
                columns={rushersColumns}
                pageSize={10}
                loading={rushersLoading}
                onRowClick={onRusherRowClick}
                stickyHeader
              />
            </DashboardPageBlockContainer>
          </BlockWrapper>
          <BlockWrapper theme={theme} style={{ marginTop: SPACING[200] }}>
            <BlockHeader title={'Factures'} label={`Tableau de bord`} />

            <DashboardPageBlockContainer height="50vh">
              <DataGrid
                rows={invoicesRow}
                columns={invoicesColumns}
                pageSize={25}
                loading={invoicesLoading}
                onRowClick={onInvoiceRowClick}
                stickyHeader
              />
            </DashboardPageBlockContainer>
          </BlockWrapper>

          {invoiceDialog ? (
            <InvoicesDialog
              invoice={invoiceDialog}
              invoices={invoices}
              setEditDialog={setInvoiceDialog}
              isOpen={!!invoiceDialog}
              onClose={() => setInvoiceDialog(null)}
              isLoading={invoicesLoading}
              withoutButton
            />
          ) : null}

          {rusherDialog ? (
            <RusherDialog
              rusher={rusherDialog}
              setEditDialog={setRusherDialog}
              isOpen={!!rusherDialog}
              onClose={() => setRusherDialog(null)}
              onConfirm={onConfirmRusherUpdate}
              cantConfirm={!rusherDialog.updatedData}
              isLoading={rushersLoading}
            />
          ) : null}
        </DashboardPageContainer>
      </Container>
    </Page>
  );
};

export default RushersDashboard;

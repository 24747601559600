import React from 'react';
import { subtract } from 'lodash';

import {
  InvoiceTemplateBlock,
  InvoiceHeaderBlock,
  InvoiceHeaderContent,
  InvoiceContentWrapper,
  InvoiceContentHeader,
  InvoiceHeaderTotalWrapper,
  InvoiceHeaderLineWrapper,
} from '../../../styles/invoiceTemplate';
import { categoriesMapper } from '../../../actions/orders/helpers/deliveryFeesCategories';
import { useTheme } from '../../../hooks';
import { Heading, Text } from '../../../styleguide';
import InvoiceResumeTable from './InvoiceResumeTable';
import { parseNumber } from '../../../common';

const InvoiceResumeTemplate = props => {
  const { rusher, invoice, displayList, date, orders } = props;
  const theme = useTheme();

  const classifiedOrders = categoriesMapper(orders);

  return (
    <InvoiceTemplateBlock theme={theme}>
      <InvoiceHeaderBlock theme={theme}>
        <InvoiceHeaderContent theme={theme}>
          <InvoiceHeaderLineWrapper theme={theme}>
            <Text size={400} muted>
              Date
            </Text>
            <Text size={400}>{date}</Text>
          </InvoiceHeaderLineWrapper>
          <InvoiceHeaderLineWrapper theme={theme}>
            <Text size={400} muted>
              ID client
            </Text>
            <Text size={400}>{rusher.id}</Text>
          </InvoiceHeaderLineWrapper>
          <InvoiceHeaderLineWrapper theme={theme} isLast>
            <Text size={400} muted>
              Facture numéro
            </Text>
            <Text size={400}>{invoice.number}</Text>
          </InvoiceHeaderLineWrapper>
        </InvoiceHeaderContent>
        <InvoiceHeaderTotalWrapper theme={theme}>
          <InvoiceHeaderLineWrapper theme={theme}>
            <Text size={400} muted>
              Total HT
            </Text>
            <Text size={400} color={theme.primaryColor}>
              {' '}
              {displayList ? '-- ' : parseNumber(invoice.total).toFixed(2)}€
            </Text>
          </InvoiceHeaderLineWrapper>
          <InvoiceHeaderLineWrapper theme={theme}>
            <Text size={400} muted>
              Frais Delyzon
            </Text>
            <Text size={400}>
              {' '}
              {displayList ? '-- ' : parseNumber(invoice.commission).toFixed(2)}
              €
            </Text>
          </InvoiceHeaderLineWrapper>
          <InvoiceHeaderLineWrapper theme={theme} isLast>
            <Text size={400} muted>
              Total Net
            </Text>
            <Text size={400} color={theme.primaryColor}>
              {displayList
                ? '-- '
                : parseNumber(
                    subtract(invoice.total, invoice.commission)
                  ).toFixed(2)}
              €
            </Text>
          </InvoiceHeaderLineWrapper>
        </InvoiceHeaderTotalWrapper>
      </InvoiceHeaderBlock>
      <InvoiceContentWrapper theme={theme}>
        <InvoiceContentHeader theme={theme}>
          <Heading size={500}>
            Intitulé - Paiement prestation(s) livraison(s)
          </Heading>
        </InvoiceContentHeader>

        <InvoiceResumeTable
          classifiedOrders={classifiedOrders}
          rusher={rusher}
        />
      </InvoiceContentWrapper>
    </InvoiceTemplateBlock>
  );
};

export default InvoiceResumeTemplate;

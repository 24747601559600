import React from 'react';
import styled from 'styled-components';
import { useStopwatch } from 'react-timer-hook';
import { SPACING } from '../styles/constants';

import Text from './text';

const StopWatchWrapper = styled.div`
  width: 100%;
  padding: ${SPACING[100]} 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const StopWatch = props => {
  const { startTS, color, message } = props;

  const now = Date.now();
  const offset = now - startTS;
  const stopwatchOffset = new Date();
  stopwatchOffset.setMilliseconds(stopwatchOffset.getMilliseconds() + offset);

  const { seconds, minutes, hours, reset } = useStopwatch({
    offsetTimestamp: stopwatchOffset,
    autoStart: true,
  });

  React.useEffect(() => {
    return () => {
      reset();
    };
  }, [startTS]);

  return (
    <StopWatchWrapper>
      <Text size={300} muted>
        {message || 'Commencé depuis :'}
      </Text>
      <Text
        size={500}
        styles={{ color }}
      >{`${hours}:${minutes}:${seconds}`}</Text>
    </StopWatchWrapper>
  );
};

export default StopWatch;

import React from 'react';
import { Divider } from '@material-ui/core';

import {
  ProductLine,
  MenuLine,
  Wrapper,
  Header,
  NameWrapper,
  ItemsWrapper,
  TotalsWrapper,
  TotalRow,
} from './commons';
import { Text, Heading } from '../../../../styleguide';
import { parseNumber } from '../../../../common';

const ClientReceipt = React.forwardRef((props, ref) => {
  const { order, orderAt, estimatedDeliveryTime } = props;
  const name = !!order.name ? order.name : 'Nom du client';

  return (
    <Wrapper ref={ref}>
      <Header>
        <Heading size={600} styles={{ color: 'black' }}>
          Delyzon
        </Heading>
      </Header>
      <NameWrapper>
        <Heading size={800} styles={{ color: 'white' }}>
          {name}
        </Heading>
      </NameWrapper>
      <Text size={600} styles={{ marginLeft: 8, color: 'black' }}>
        Commandé le: <b>{orderAt}</b>
      </Text>
      <Text size={600} styles={{ marginLeft: 8, color: 'black' }}>
        Heure livraison éstimé: <b>{estimatedDeliveryTime}</b>
      </Text>
      <Text size={600} styles={{ marginLeft: 8, color: 'black' }}>
        Numéro: <b>{order.id}</b>
      </Text>
      <Divider style={{ width: '100%', margin: 8 }} />
      <Heading size={800} styles={{ margin: 0, marginLeft: 8, color: 'black' }}>
        Panier
      </Heading>
      <ItemsWrapper>
        {order.itemsList.map((shopItem, index) =>
          shopItem.item.isMenu ? (
            <MenuLine
              key={`${index}-${shopItem.item.name}`}
              shopItem={shopItem}
            />
          ) : (
            <ProductLine
              key={`${index}-${shopItem.item.name}`}
              shopItem={shopItem}
            />
          )
        )}
      </ItemsWrapper>
      <Divider style={{ width: '100%', margin: 8 }} />
      {!!order.message && (
        <>
          <Heading size={800} styles={{ color: 'black', marginLeft: 8 }}>
            Message
          </Heading>
          <Text size={600} styles={{ color: 'black', marginLeft: 8 }}>
            <b>{order.message || "Pas d'instruction"}</b>
          </Text>
          <Divider style={{ width: '100%', margin: 8 }} />
        </>
      )}
      <Heading size={800} styles={{ margin: 0, marginLeft: 8, color: 'black' }}>
        Paiement
      </Heading>
      <TotalsWrapper>
        <TotalRow>
          <Heading
            size={500}
            styles={{
              marginTop: 8,
              marginLeft: 0,
              color: 'black',
            }}
          >
            Panier
          </Heading>
          <Text size={500} styles={{ marginTop: 8, color: 'black' }}>
            {parseNumber(order.total)} €
          </Text>
        </TotalRow>
        <TotalRow>
          <Heading
            size={500}
            styles={{
              marginTop: 8,
              marginLeft: 0,
              color: 'black',
            }}
          >
            Frais
          </Heading>
          <Text size={500} styles={{ marginTop: 8, color: 'black' }}>
            {parseNumber(
              parseNumber(order.deliveryFees) + parseNumber(order.appFees)
            )}
            {' €'}
          </Text>
        </TotalRow>
        <TotalRow>
          <Heading
            size={600}
            styles={{
              fontWeight: 'bold',
              marginTop: 8,
              marginLeft: 0,
              color: 'black',
            }}
          >
            Total
          </Heading>
          <Text
            size={600}
            styles={{ fontWeight: 'bold', marginTop: 8, color: 'black' }}
          >
            {parseNumber(
              parseNumber(order.total) +
                parseNumber(order.deliveryFees) +
                parseNumber(order.appFees)
            )}{' '}
            €
          </Text>
        </TotalRow>
      </TotalsWrapper>
      <Divider style={{ width: '100%', margin: 8, color: 'black' }} />
      <Text size={600} styles={{ color: 'black', marginLeft: 8 }}>
        Nous vous remercions pour votre commande passé chez {order.shop.name}
      </Text>
      <Heading size={800} styles={{ color: 'black', marginLeft: 8 }}>
        Delyzon
      </Heading>
    </Wrapper>
  );
});

export default ClientReceipt;

import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

import { prepareDocForUpdate } from '../helpers/firestoreHelpers';

const updateRusher = data => {
  const { id, ...values } = data;
  ReactGA.event({
    category: 'Rusher',
    action: 'Update rusher',
  });

  if (!values) {
    return;
  }

  try {
    return Firebase.firestore()
      .collection('rushers')
      .doc(id)
      .update(prepareDocForUpdate(values));
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

export { updateRusher };

import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  ListSubheader,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { CATEGORIES } from '../../common';
import { Input } from './Input';
import PhoneInput from './phoneinput';

const EmailField = props => {
  const { value, onChange } = props;
  const { t } = useTranslation(['forms']);

  return (
    <Input
      label={t('forms:email')}
      size="small"
      variant="outlined"
      required
      placeholder="john@doe.fr"
      type="email"
      fullWidth
      value={value}
      onChange={event => onChange(event.target.value)}
    />
  );
};

const NameField = props => {
  const { value, onChange } = props;
  const { t } = useTranslation(['forms']);

  return (
    <Input
      label={t('forms:name')}
      size="small"
      variant="outlined"
      required
      placeholder="John Doe"
      fullWidth
      value={value}
      onChange={event => onChange(event.target.value)}
    />
  );
};

const BaseCityField = props => {
  const { value, onChange } = props;
  const { t } = useTranslation(['forms']);

  return (
    <Input
      label={t('forms:city')}
      size="small"
      variant="outlined"
      required
      placeholder="Chamonix Mont-blanc"
      fullWidth
      value={value}
      onChange={event => onChange(event.target.value)}
    />
  );
};

const PhoneField = props => {
  const { value, onChange } = props;

  return (
    <PhoneInput
      value={value}
      onChange={event => onChange(event.target.value)}
    />
  );
};

const PasswordField = props => {
  const { value, onChange } = props;
  const { t } = useTranslation(['forms']);

  return (
    <Input
      label={t('forms:password')}
      size="small"
      variant="outlined"
      required
      fullWidth
      value={value}
      onChange={event => onChange(event.target.value)}
    />
  );
};

const LanguageField = props => {
  const { value, onChange } = props;
  const { t } = useTranslation(['forms']);

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel id="demo-simple-select-label">{t('forms:lng')}</InputLabel>
      <Select
        labelId="demo-simple-select-label"
        id="demo-simple-select"
        value={value}
        onChange={event => onChange(event.target.value)}
      >
        <MenuItem value={'fr'}>Français</MenuItem>
        <MenuItem value={'en'}>English</MenuItem>
      </Select>
    </FormControl>
  );
};

const DescriptionField = props => {
  const { value, onChange } = props;
  const { t } = useTranslation(['forms']);

  return (
    <Input
      label={t('forms:description')}
      size="small"
      variant="outlined"
      required
      placeholder="Mon restaurant est ...."
      rows={4}
      multiline
      fullWidth
      value={value}
      onChange={event => onChange(event.target.value)}
    />
  );
};

const LogoField = props => {
  const { value, onChange } = props;
  const { t } = useTranslation(['forms']);

  return (
    <Input
      label={t('forms:logo')}
      size="small"
      variant="outlined"
      required
      placeholder="mon-logo.png"
      fullWidth
      value={value}
      onChange={event => onChange(event.target.value)}
    />
  );
};

const BannerField = props => {
  const { value, onChange } = props;
  const { t } = useTranslation(['forms']);

  return (
    <Input
      label={t('forms:banner')}
      size="small"
      variant="outlined"
      required
      placeholder="ma-banniere.png"
      fullWidth
      value={value}
      onChange={event => onChange(event.target.value)}
    />
  );
};

const CategoryField = props => {
  const { value, onChange } = props;
  const { t } = useTranslation(['forms']);

  return (
    <FormControl style={{ width: '100%' }}>
      <InputLabel htmlFor="grouped-select">{t('forms:category')}</InputLabel>
      <Select
        id="grouped-select"
        value={value}
        onChange={event => onChange(event.target.value)}
      >
        {CATEGORIES.map(item => [
          <ListSubheader key={item.name}>{item.name}</ListSubheader>,
          item.values.map(value => <MenuItem value={value}>{value}</MenuItem>),
        ])}
      </Select>
    </FormControl>
  );
};

const PROPERTY_FIELDS = [
  {
    name: 'email',
    Field: EmailField,
  },
  {
    name: 'name',
    Field: NameField,
  },
  {
    name: 'phone',
    Field: PhoneField,
  },
  {
    name: 'pwd',
    Field: PasswordField,
  },
  {
    name: 'language',
    Field: LanguageField,
  },
  {
    name: 'description',
    Field: DescriptionField,
  },
  {
    name: 'logoURL',
    Field: LogoField,
  },
  {
    name: 'pictureURL',
    Field: LogoField,
  },
  {
    name: 'bannerURL',
    Field: BannerField,
  },
  {
    name: 'category',
    Field: CategoryField,
  },
  {
    name: 'baseCity',
    Field: BaseCityField,
  },
];

export default PROPERTY_FIELDS;

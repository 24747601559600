import ReactGA from 'react-ga4';

import authenticatedRequest from '../helpers/authenticatedRequest';
import { API } from '../../common';

const createPaymentMethod = async (
  stripe,
  cardElement,
  cardOwnerName,
  user,
  authUserID
) => {
  // console.log('Create Payment Method called', { cardElement, user });
  ReactGA.event({
    category: 'Stripe',
    action: 'Create payment method',
  });

  try {
    const { paymentMethod } = await stripe.createPaymentMethod({
      type: 'card',
      card: {
        ...cardElement,
        country: 'FR',
      },
      billing_details: {
        name: cardOwnerName,
        email: user.email,
        phone: user.phone,
        address: {
          country: 'FR',
        },
      },
      metadata: {
        uid: user.id,
      },
    });

    // console.log({ paymentMethod });

    await authenticatedRequest('post', API.stripe.attachPaymentMethod, {
      userID: user.id,
      paymentMethodID: paymentMethod.id,
    });
  } catch (error) {
    console.error('Error during PM creation', { error });
    return {
      error: {
        message:
          error?.response?.data?.message ||
          'Une erreur est survenue lors de la création du moyen de paiement',
      },
    };
  }
  // console.log('Creation ended, success');
  return true;
};

export default createPaymentMethod;

import * as yup from 'yup';

import { IDENTITY } from '../fields';

const signupGoogleUserIdentity = yup.object({
  email: IDENTITY.EMAIL_FIELD_VALIDATION,
  name: IDENTITY.NAME_FIELD_VALIDATION,
  phone: IDENTITY.PHONE_FIELD_VALIDATION,
});

const signupUserIdentity = yup.object({
  email: IDENTITY.EMAIL_FIELD_VALIDATION,
  name: IDENTITY.NAME_FIELD_VALIDATION,
  phone: IDENTITY.PHONE_FIELD_VALIDATION,
  pwd: IDENTITY.PWD_FIELD_VALIDATION,
});

export { signupUserIdentity, signupGoogleUserIdentity };

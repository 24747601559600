import React from 'react';
import { Switch, FormControlLabel, Divider } from '@material-ui/core';

import { FlexFieldsWrapper } from '../../../../../styles/shop';
import { Input, Text, Heading } from '../../../../../styleguide';
import { SPACING } from '../../../../../styles/constants';
import { parseNumber } from '../../../../../common';

const OptionsConfigFields = props => {
  const { currentOption, onChange, optionsCount } = props;
  const inputsStyle = { minWidth: '90px', maxWidth: '160px' };

  return (
    <>
      <FlexFieldsWrapper align="center">
        <Heading size={600}>Configuration</Heading>
      </FlexFieldsWrapper>
      <Divider
        style={{
          width: '100%',
          margin: `${SPACING[100]} 0`,
          marginBottom: SPACING[200],
        }}
      />
      <FlexFieldsWrapper padding={'0px'} wrap="nowrap">
        <Input
          name="name"
          wrapperProps={{ justify: 'flex-start', maxWidth: '180px' }}
          label="Nom"
          size="small"
          variant="outlined"
          required
          placeholder="Vos viandes"
          fullWidth
          style={inputsStyle}
          value={currentOption?.name}
          onChange={event => onChange('name', event.target.value)}
        />
        <Input
          wrapperProps={{
            justify: 'flex-start',
            maxWidth: '80px',
          }}
          required
          label="Ordre"
          size="small"
          variant="outlined"
          placeholder="2"
          fullWidth
          type="number"
          style={{ width: '80px' }}
          value={currentOption?.order || optionsCount}
          name="order"
          onChange={event => onChange('order', event.target.value)}
        />
      </FlexFieldsWrapper>
      <FlexFieldsWrapper padding="0px">
        <Input
          wrapperProps={{
            justify: 'flex-start',
            maxWidth: '100px',
          }}
          required
          label="Nbrs Choix"
          size="small"
          variant="outlined"
          placeholder="2"
          fullWidth
          type="number"
          style={{ width: '100px' }}
          value={currentOption?.count}
          name="count"
          onChange={event => onChange('count', parseNumber(event.target.value))}
        />
      </FlexFieldsWrapper>
      <FlexFieldsWrapper padding={'0px'} align="center">
        <FormControlLabel
          control={
            <Switch
              name="required"
              disabled={currentOption?.paying}
              checked={!currentOption?.paying ? currentOption?.required : false}
              onChange={event => onChange('required', event.target.checked)}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          label={<Text size={400}>Requis</Text>}
          style={{ width: '120px', paddingLeft: SPACING[100] }}
        />
        <FormControlLabel
          control={
            <Switch
              name="paying"
              disabled={currentOption?.required}
              checked={!currentOption?.required ? currentOption?.paying : false}
              onChange={event => onChange('paying', event.target.checked)}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          }
          label={<Text size={400}>Payant</Text>}
          style={{ width: '120px', paddingLeft: SPACING[100] }}
        />
      </FlexFieldsWrapper>
    </>
  );
};

export default OptionsConfigFields;

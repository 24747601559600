import React from 'react';
import styled from 'styled-components';
import { ImpulseSpinner } from 'react-spinners-kit';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../hooks';
import { parseNumber } from '../../common';
import { Heading, Text } from '../../styleguide';
import { SPACING } from '../../styles/constants';
import { NotesWrapper } from '../../styles/newOrder';
import AddressLine from './AddressLine';
import PaymentMethod from './PaymentMethod';
import ProductLine from '../basket/ProductLine';
import MenuLine from '../basket/MenuLine';

const LoaderWrapper = styled.div`
  min-width: 100vw;
  min-height: 100vh;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: ${props => props.background};
`;

const LoaderContentContainer = styled.div`
  width: 100%;
  max-width: 700px;
  padding: ${SPACING[300]} ${SPACING[200]};
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  margin: 0 auto;
`;

const LoaderHeaderRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DetailsWrapper = styled.div`
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
`;

const ContentWrapper = styled.div`
  width: 100%;
  max-width: 550px;
  margin: 0 auto;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: ${SPACING[300]};
`;

const SpinnerWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${SPACING[300]};
  margin-bottom: ${SPACING[200]};
`;

const OrderMessageWrapper = styled.div`
  margin-top: ${SPACING[100]};
  width: 100%;
  max-width: 550px;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${props => props.bg || 'none'};
  padding: ${SPACING[200]} ${SPACING[100]};
  margin: ${SPACING[100]} auto;
  border-radius: 4px;
`;

const CreationLoader = props => {
  const {
    itemsList,
    selectedAddress,
    total,
    selectedPaymentMethod,
    message,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation(['common', 'checkout']);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <LoaderWrapper background={theme.backgroundColor}>
      <LoaderContentContainer>
        <LoaderHeaderRow>
          <Text
            size={400}
            styles={{ color: theme.textColor, marginTop: SPACING[100] }}
          >
            {t('checkout:total')}
            {': '}
            <span style={{ color: theme.primaryColor, fontSize: '18px' }}>
              {parseNumber(total).toFixed(2)}€
            </span>
          </Text>
          <Text
            size={400}
            styles={{ color: theme.textColor, marginTop: SPACING[100] }}
          >
            <span style={{ color: theme.primaryColor, fontSize: '18px' }}>
              {itemsList.length}
            </span>{' '}
            {t('common:item')}
            {itemsList.length > 1 ? 's' : ''}
          </Text>
        </LoaderHeaderRow>

        <Heading
          styles={{ padding: `${SPACING[400]} 0`, textAlign: 'center' }}
          size={800}
        >
          {t('checkout:loading_page_title')}
        </Heading>

        <SpinnerWrapper>
          <ImpulseSpinner size={60} color={theme.primaryColor} loading />
        </SpinnerWrapper>

        <DetailsWrapper>
          <AddressLine selectedAddress={selectedAddress} lite />
          <PaymentMethod selectedPaymentMethod={selectedPaymentMethod} lite />
        </DetailsWrapper>

        {!!message ? (
          <NotesWrapper width="550px">
            <Heading styles={{ paddingTop: SPACING[200] }} size={500}>
              {t('checkout:message_title')}
            </Heading>
            <OrderMessageWrapper bg={theme.lightGray}>
              <Text muted size={400}>
                {message}
              </Text>
            </OrderMessageWrapper>
          </NotesWrapper>
        ) : null}

        <ContentWrapper>
          {itemsList.map((shopItem, index) =>
            shopItem.item.isMenu ? (
              <MenuLine key={index} shopItem={shopItem} />
            ) : (
              <ProductLine key={index} shopItem={shopItem} />
            )
          )}
        </ContentWrapper>
      </LoaderContentContainer>
    </LoaderWrapper>
  );
};

export default CreationLoader;

import React from 'react';

import {
  UserBlockDataGridWrapper,
  StatsWrapper,
  BlockContentWrapper,
} from '../../../../../styles/admin';

const BlockLayout = props => {
  const { statBlocks, children } = props;

  return (
    <BlockContentWrapper>
      <StatsWrapper>{statBlocks}</StatsWrapper>
      <UserBlockDataGridWrapper>{children}</UserBlockDataGridWrapper>
    </BlockContentWrapper>
  );
};

export default BlockLayout;

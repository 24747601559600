import _ from 'lodash';

const groupBy = (data, target) => {
  const sortedData = data.sort();

  return _.chain(sortedData)
    .groupBy(target)
    .map((items, category) => ({ category, items }))
    .value();
};

export default groupBy;

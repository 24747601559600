import ORDER_STATUS from '../../../../../actions/orders/helpers/STATUS';

import WaitingStep from './WaitingStep';
import InProgressStep from './InProgressStep';
import ReadyStep from './ReadyStep';
import DeliveryStep from './DeliveryStep';
import DeliveredStep from './DeliveredStep';
import CanceledStep from './CanceledStep';

const STEPS = [
  {
    index: 1,
    name: ORDER_STATUS.inValidation,
    Step: WaitingStep,
  },
  {
    index: 2,
    name: ORDER_STATUS.inProgress,
    Step: InProgressStep,
  },
  {
    index: 3,
    name: ORDER_STATUS.ready,
    Step: ReadyStep,
  },
  // TODO: translate these steps
  {
    index: 4,
    name: ORDER_STATUS.inDelivery,
    Step: DeliveryStep,
  },
  {
    index: 5,
    name: ORDER_STATUS.delivered,
    Step: DeliveredStep,
  },
  {
    index: 6,
    name: ORDER_STATUS.canceled,
    Step: CanceledStep,
  },
];

export default STEPS;

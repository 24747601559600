// catch errors in our app and show the error screen instead of just breaking
// https://reactjs.org/docs/error-boundaries.html

import React from 'react';
import * as Sentry from '@sentry/react';

import ErrorPage from './ErrorPage';

class ErrorBoundary extends React.Component {
  state = {
    error: null,
  };

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, info) {
    console.error('Error catch by ErrorBoundary:', { error, info });
    Sentry.captureException(error);
  }

  render() {
    const { error } = this.state;

    if (error) {
      console.error({ error });
      return <ErrorPage error={error} />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;

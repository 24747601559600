import ReactGA from 'react-ga4';

import authenticatedRequest from '../helpers/authenticatedRequest';
import { API } from '../../common';

const createPaymentIntent = async ({
  orderID,
  paymentMethodID,
  stripeCustomerID,
  total,
  shopID,
  fees,
  percentage,
}) => {
  // console.log('Create Intent Method called', {
  //   orderID,
  //   paymentMethodID,
  //   stripeCustomerID,
  //   shopID,
  //   total,
  //   fees,
  //   percentage,
  // });
  ReactGA.event({
    category: 'Stripe',
    action: 'Create payment intent',
  });

  try {
    const response = await authenticatedRequest(
      'post',
      `${API.stripe.createPaymentIntent}`,
      {
        orderID,
        paymentMethodID,
        stripeCustomerID,
        shopID,
        total,
        fees,
        percentage,
      }
    );

    return response.data.client_secret;
  } catch (error) {
    console.error('Error during Payment intent creation', {
      error: error,
    });
    return {
      error: error,
    };
  }
};

export default createPaymentIntent;

import React from 'react';

import { Heading } from '../../../../styleguide';
import { TabContentWrapper } from '../../../../styles/shop';
import {
  OrdersListWrapper,
  EmptyNewOrders,
} from '../../../../styles/orders-cards';
import TabPanel from '../../../shop/tabs/TabPanel';
import { DeliveredCard } from '../cards/Cards';
import EmptyTab from './EmptyTab';

const DeliveredTab = props => {
  const { value, orders } = props;

  const hasExistingOrders = !!orders.length;

  return (
    <TabPanel value={value} index={4}>
      <TabContentWrapper>
        {hasExistingOrders ? (
          <>
            <Heading size={800}>Les commandes livrés</Heading>

            <OrdersListWrapper>
              {orders.map(order => (
                <DeliveredCard key={order.id} order={order} />
              ))}
            </OrdersListWrapper>
          </>
        ) : (
          <EmptyNewOrders>
            <EmptyTab
              title={'Pas de commande livré'}
              description={'Aucune commande livrés pour le moment ! 📦'}
            />
          </EmptyNewOrders>
        )}
      </TabContentWrapper>
    </TabPanel>
  );
};

export default DeliveredTab;

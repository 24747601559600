import styled from 'styled-components';

import { SPACING } from './constants';

const OrderCardWrapper = styled.div`
  position: relative;
  width: 300px;
  border-radius: 4px;
  box-shadow: ${props => props.theme.boxShadow.md};
  padding: ${SPACING[200]};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  border: 1px solid ${props => props.theme.lightGray};
  opacity: ${props => (props.transparent ? 0.5 : 1)};
  margin: ${SPACING[100]};
  background-color: ${props => props.theme.cardBg};
`;

const OrdersListWrapper = styled.div`
  margin-top: ${SPACING[400]};
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
`;

const EmptyNewOrders = styled.div`
  width: 100%;
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const OrderCardOverlay = styled.div`
  width: 100%;
  padding: ${SPACING[300]};
  padding-top: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const OrderCardHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const OrderMessageWrapper = styled.div`
  margin-top: ${SPACING[100]};
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${props => props.bg || 'none'};
  padding: ${SPACING[200]} ${SPACING[100]};
  margin: ${SPACING[100]} 0;
  border-radius: 4px;
`;

const OrderCardItemsListWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: ${SPACING[200]};
  padding-top: ${SPACING[100]};
  border-top: 1px solid ${({ theme }) => theme.lightGray};
`;

const OrderCardDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-top: ${SPACING[200]};
  padding-top: ${props => (props.topBorder ? `${SPACING[100]}` : 'none')};
  border-top: ${props =>
    props.topBorder ? `1px solid ${props.theme.lightGray}` : 'none'};
`;

const OrderCardDetailsRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${SPACING[100]} 0;
  border-bottom: ${props =>
    props.bottomBorder ? `1px solid ${props.theme.lightGray}` : 'none'};
`;

const OrderCardActions = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${SPACING[100]};
  padding-top: ${SPACING[200]};
  border-top: 1px solid ${({ theme }) => theme.lightGray};
`;

const OrderTitleWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  margin-top: ${SPACING[200]};
  padding-top: ${SPACING[200]};
  border-top: 1px solid ${({ theme }) => theme.lightGray};
`;

const InProgressModalDetails = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: ${SPACING[200]} 0;
`;

const InProgressModalDetailsRowWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${SPACING[200]} 0;
`;

const InProgressModalDetailsRow = styled.div``;

const ActionWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${SPACING[100]};
`;

export {
  OrderCardWrapper,
  OrderCardHeader,
  OrderCardItemsListWrapper,
  OrderCardDetails,
  OrderCardActions,
  OrderCardDetailsRow,
  OrderCardOverlay,
  OrderTitleWrapper,
  OrdersListWrapper,
  EmptyNewOrders,
  InProgressModalDetails,
  InProgressModalDetailsRowWrapper,
  InProgressModalDetailsRow,
  OrderMessageWrapper,
  ActionWrapper,
};

import ReactGA from 'react-ga4';
import authenticatedRequest from '../helpers/authenticatedRequest';
import { API } from '../../common';

const cancelDiscountUsage = async ({ code, userID }) => {
  ReactGA.event({
    category: 'Discount',
    action: 'Cancel discount usage',
  });

  const response = await authenticatedRequest(
    'post',
    API.backend.cancelOrderDiscountUsage,
    {
      code,
      userID,
    }
  );

  return response;
};

export default cancelDiscountUsage;

import React from 'react';

import { Input } from '../../styleguide';

const MessageBlock = props => {
  const { onMessageUpdate, placeholder } = props;
  const [message, setMessage] = React.useState('');

  const onMessageSubmit = () => {
    onMessageUpdate(message);
  };

  return (
    <Input
      label="Message"
      placeholder={
        placeholder ||
        'Des précisions particulières concernant votre commande ?'
      }
      multiline
      rows={3}
      value={message}
      onChange={event => setMessage(event.target.value)}
      onBlur={onMessageSubmit}
    />
  );
};

export default MessageBlock;

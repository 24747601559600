// Helper functions for working with Firebase Firestore

import Firebase from 'firebase/app';
import 'firebase/auth';

const prepareDocForCreate = doc => {
  // timestamps
  if (!Firebase.auth().currentUser) {
    throw new Error('You need to be auth to do this action');
  }

  doc.createdAt = Firebase.firestore.Timestamp.now();

  return doc;
};

const prepareDocForUpdate = doc => {
  if (!Firebase.auth().currentUser) {
    throw new Error('You need to be auth to do this action');
  }

  // timestamps
  // doc.updatedBy = Firebase.auth().currentUser.uid;
  doc.updatedAt = Firebase.firestore.Timestamp.now();

  // don't save values that start with an underscore (these are calculated by the backend)
  Object.keys(doc).forEach(key => {
    if (key.indexOf('_') === 0) {
      delete doc[key];
    }
  });

  return doc;
};

export { prepareDocForCreate, prepareDocForUpdate };

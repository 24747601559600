import React from 'react';
import Helmet from 'react-helmet';

import { useSession } from '../../hooks';

function SEO({ description, lang, meta, title }) {
  const { user } = useSession();

  const lg = !!user ? user.settings.language : lang;
  const metaDescription =
    description ||
    'Bienvenue sur Delyzon, le service qui vous livre ce dont vous avez besoin quand vous en avez besoin.';

  return (
    <Helmet
      htmlAttributes={{
        lang: lg,
      }}
      title={`${title}`}
      titleTemplate={`%s | Delyzon`}
      defaultTitle="Delyzon"
      meta={[
        {
          name: `description`,
          content: metaDescription,
        },
        {
          property: `og:title`,
          content: title,
        },
        {
          property: `og:description`,
          content: metaDescription,
        },
        {
          property: `og:type`,
          content: `website`,
        },
        {
          name: `twitter:card`,
          content: `summary`,
        },
        {
          name: `twitter:creator`,
          content: 'Delyzon',
        },
        {
          name: `twitter:title`,
          content: title,
        },
        {
          name: `twitter:description`,
          content: metaDescription,
        },
      ].concat(meta)}
    />
  );
}

SEO.defaultProps = {
  lang: `fr`,
  meta: [],
  description: ``,
};

export default SEO;

import React from 'react';
import { Divider } from '@material-ui/core';

import { plurialize, capitalize } from '../../../../../common';
import createID from '../../../../../actions/helpers/createID';
import {
  FlexFieldsWrapper,
  OptionsFieldsWrapper,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  EmptyOptions,
  OptionsWrapper,
  OptionsContainer,
} from '../../../../../styles/shop';
import {
  EmptyPlaceholder,
  EmptyPicture,
  EmptyPlaceholderContent,
} from '../../../../../styles/common';
import { Text, Button, Heading } from '../../../../../styleguide';
import { SPACING } from '../../../../../styles/constants';
import { useTheme } from '../../../../../hooks';
import { ExpandIcon, CheckIcon } from '../../../../../styleguide/icons';
import NewItemButton from '../NewItemButton';
import EmptyBasket from '../../../../../assets/images/empty.svg';
import OptionsConfigFields from './OptionsConfigFields';
import OptionsItemsForm from './OptionsItemsForm';
import OptionCard from './OptionCard';
import { parseNumber } from '../../../../../common';

const optionValidator = option => {
  const { name, required, paying, count, items } = option;
  const itemsValid = items.length > 0;
  const countValid = count >= 1 && count <= items.length;
  const payingValid =
    paying === false || (paying === true && required === false);

  const isValid = !!name.length && itemsValid && payingValid && countValid;

  return isValid;
};

const OptionsForm = props => {
  const { options, onOptionsChange, onDelete } = props;
  const [currentOption, setCurrentOption] = React.useState(null);
  const [currentOptionItem, setCurrentOptionItem] = React.useState(null);
  const theme = useTheme();

  const DEFAULT_OPTION = {
    id: createID(24),
    name: '',
    required: false,
    // ONLY POSSIBLE IF NOT REQUIRED. If false, then the option price is not taken into account
    paying: false,
    count: 1,
    items: [],
    order: 0,
  };

  const DEFAULT_OPTION_ITEM = {
    id: createID(24),
    name: '',
    // Only used if option.paying is true
    price: 0,
    avaibility: true,
  };

  const onChange = (property, value) => {
    setCurrentOption({ ...currentOption, [property]: value });
  };

  const onChangeItem = (property, value) => {
    setCurrentOptionItem({ ...currentOptionItem, [property]: value });
  };

  const onSubmitOption = () => {
    if (!!currentOption && !!optionValidator(currentOption)) {
      onOptionsChange({
        ...currentOption,
        count:
          parseNumber(currentOption.count) === 1 && currentOption.paying
            ? currentOption.items.length
            : parseNumber(currentOption.count),
        order: !!currentOption.order
          ? parseNumber(currentOption.order)
          : parseNumber(options.length),
      });
      setCurrentOption(null);
      setCurrentOptionItem(null);
    }
  };

  const onCreateOption = () => {
    setCurrentOption({ ...DEFAULT_OPTION, order: options.length + 1 });
    setCurrentOptionItem(DEFAULT_OPTION_ITEM);
  };

  const onCancel = () => {
    setCurrentOption(null);
    setCurrentOptionItem(null);
  };

  const onConfirmItem = () => {
    const { id, name, price, avaibility } = currentOptionItem;
    const item = {
      id,
      name: capitalize(name),
      price: parseNumber(price),
      avaibility,
    };

    const alreadyExist = currentOption.items.find(i => i.id === id);

    if (alreadyExist) {
      setCurrentOption({
        ...currentOption,
        items: currentOption.items.map(i =>
          i.id === id ? { ...i, ...item } : i
        ),
      });
    } else {
      setCurrentOption({
        ...currentOption,
        items: [...currentOption.items, item],
      });
    }
    setCurrentOptionItem(DEFAULT_OPTION_ITEM);
  };

  const onEditItem = item => {
    setCurrentOptionItem(item);
  };

  const onDeleteItem = item => {
    setCurrentOption({
      ...currentOption,
      items: currentOption.items.filter(i => i.id !== item.id),
    });
  };

  const onEditOption = item => {
    setCurrentOption(item);
    setCurrentOptionItem(DEFAULT_OPTION_ITEM);
  };

  const canSubmit = !!currentOption && !!optionValidator(currentOption);

  return (
    <Accordion square style={{ width: '100%' }} defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandIcon size="16px" />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Heading size={500}>
          Les Options{' '}
          {!!options?.length
            ? plurialize({
                value: options.length,
                default: ' - ($value item)',
                more: ' - ($value items)',
              })
            : null}
        </Heading>
      </AccordionSummary>
      <AccordionDetails>
        <OptionsContainer>
          <OptionsWrapper>
            {!!currentOption && (
              <OptionsFieldsWrapper theme={theme}>
                <OptionsConfigFields
                  currentOption={currentOption}
                  onChange={onChange}
                  optionsCount={options?.length || 0}
                />

                <OptionsItemsForm
                  currentOptionItem={currentOptionItem}
                  onChangeItem={onChangeItem}
                  onConfirm={onConfirmItem}
                  items={currentOption.items}
                  onDeleteItem={onDeleteItem}
                  onEditItem={onEditItem}
                />
                <Divider
                  style={{
                    width: '100%',
                    margin: `${SPACING[100]} 0`,
                    marginBottom: SPACING[200],
                  }}
                />
                <FlexFieldsWrapper>
                  <Button
                    small
                    primary
                    onClick={onSubmitOption}
                    startIcon={
                      <CheckIcon color={theme.textColor} size="20px" />
                    }
                    disabled={!canSubmit}
                    styles={{
                      marginRight: SPACING[100],
                    }}
                  >
                    Terminer
                  </Button>
                  <Button minimal small onClick={onCancel}>
                    Annuler
                  </Button>
                </FlexFieldsWrapper>
              </OptionsFieldsWrapper>
            )}

            {!!options?.length ? (
              options.map(option =>
                option.id !== currentOption?.id ? (
                  <OptionCard
                    option={option}
                    onEditOption={onEditOption}
                    onDelete={onDelete}
                  />
                ) : null
              )
            ) : (
              <EmptyOptions>
                <EmptyPlaceholder>
                  <EmptyPicture src={EmptyBasket} />
                  <EmptyPlaceholderContent>
                    <Text size={600} centered>
                      Vous n'avez pas encore d'options pour votre produit !{' '}
                      {'🤷‍♂️ '}
                      <br /> Ajoutez-en une maintenant !
                    </Text>
                  </EmptyPlaceholderContent>
                </EmptyPlaceholder>{' '}
              </EmptyOptions>
            )}
          </OptionsWrapper>
          <FlexFieldsWrapper>
            <NewItemButton
              label="Nouvelle option"
              onClick={onCreateOption}
              disabled={!!currentOption}
            />
          </FlexFieldsWrapper>
        </OptionsContainer>
      </AccordionDetails>
    </Accordion>
  );
};

export default OptionsForm;

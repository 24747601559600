import React from 'react';
import { toaster } from 'evergreen-ui';
import { Redirect, useLocation } from 'react-router-dom';
import GoogleButton from 'react-google-button';
import { useTranslation } from 'react-i18next';

import { SecondaryTextWrapper } from '../../styles/login';
import { useSession } from '../../hooks';
import {
  NavLink,
  Container,
  Heading,
  Text,
  HoverableLink,
  LanguageSwitcher,
} from '../../styleguide';
import { colors, SPACING } from '../../styles/constants';
import { Page } from '../common';
import { logInWithGoogle, logInWithCredentials } from '../../actions/login';
import { ContentWrapper, ProvidersButtonsWrapper } from '../../styles/login';
import LoginForm from './LoginForm';

const Login = props => {
  const [error, setError] = React.useState(null);
  const { isLoggedIn } = useSession();
  const { t } = useTranslation(['login', 'common']);

  let location = useLocation();
  let { from } = location.state || { from: { pathname: '/' } };

  const onSubmit = (email, pwd) => {
    logInWithCredentials(email, pwd, loginErr =>
      setError({
        message:
          loginErr.code === 'auth/user-not-found'
            ? t('login:error_no_user')
            : loginErr.message,
      })
    );
  };

  if (isLoggedIn) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from },
        }}
      />
    );
  }

  if (error) {
    toaster.danger(t('login:toast_error'), {
      description: error.message,
    });
  }

  return (
    <Page
      navbarLeftChild={
        <NavLink to="/signup" color={colors.green}>
          <Text size={500} color={colors.green}>
            {t('login:nav_signup')}
          </Text>
        </NavLink>
      }
      navbarRightChild={
        <LanguageSwitcher
          wrapperProps={`
            margin-top: 0;
            justify-content: flex-end;
          `}
        />
      }
      name={t('login:login')}
    >
      <Container>
        <ContentWrapper>
          <Heading centered size={900} style={{ color: colors.black }}>
            {t('login:welcome')}
          </Heading>
          <SecondaryTextWrapper>
            <Text size={500} centered muted style={{ color: colors.grey }}>
              {t('login:no_account')}{' '}
              <HoverableLink to="/signup" color={colors.green}>
                {t('login:signup')}
              </HoverableLink>
            </Text>
          </SecondaryTextWrapper>

          <LoginForm onSubmit={onSubmit} />

          <ProvidersButtonsWrapper>
            <GoogleButton
              label={t('login:google_login')}
              style={{ width: 'fit-content', paddingRight: SPACING[200] }}
              type={'light'}
              disabled={false}
              onClick={() => {
                logInWithGoogle();
              }}
              onTouchStart={() => {
                logInWithGoogle();
              }}
            />
          </ProvidersButtonsWrapper>
        </ContentWrapper>
      </Container>
    </Page>
  );
};

export default Login;

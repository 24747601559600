import React from 'react';
import { toaster } from 'evergreen-ui';
import { useReactToPrint } from 'react-to-print';
import { DateTime } from 'luxon';

import ORDER_STATUS from '../../../../actions/orders/helpers/STATUS';
import updateOrder from '../../../../actions/orders/updateOrder';
import finishOrder from '../../../../actions/orders/finishOrder';
import { computeEstimatedTime } from '../../../../actions/orders/helpers/computeTimesHelpers';
import computePreparationTime from '../../../../actions/orders/helpers/computePreparationTime';
import { Heading, Button } from '../../../../styleguide';
import { OrdersIcon } from '../../../../styleguide/icons';
import { TabContentWrapper } from '../../../../styles/shop';
import { HeaderTabRow } from '../../../../styles/shoptabs';
import {
  OrdersListWrapper,
  EmptyNewOrders,
} from '../../../../styles/orders-cards';
import TabPanel from '../../tabs/TabPanel';
import { NewOrderCard } from '../cards/Cards';
import NewOrderDialog from '../dialogs/NewOrderDialog';
import EmptyTab from './EmptyTab';
import { useTheme } from '../../../../hooks';
import { KitchenReceipt } from '../receipts';

const NewsTab = props => {
  const { value, orders, toggleOrdersDrawer } = props;
  const theme = useTheme();
  const [confirmModal, setConfirmModal] = React.useState(null);
  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const [cancelInProgress, setCancelInProgress] = React.useState(null);
  const ref = React.useRef(null);

  React.useEffect(() => {
    if (!!selectedOrder && !!confirmModal) {
      const orderToUpdate = orders.find(o => o.id === selectedOrder.id);

      if (!orderToUpdate || !!orderToUpdate?.inTreatment) {
        setConfirmModal(null);
        return;
      }
    }
  }, [orders, selectedOrder, confirmModal]);

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    onPrintError: all => console.error({ all }),
  });

  const onConfirm = async (order, updatedPreparationTime) => {
    // TODO: Get order in orders to check if is inTreatment
    const orderToUpdate = orders.find(o => o.id === order.id);

    if (!orderToUpdate || !!orderToUpdate?.inTreatment) {
      setConfirmModal(null);
      toaster.warning(
        `⏳ Impossible commande en cours de traitement, réessayez plus tard`
      );
      return;
    }
    try {
      await updateOrder(orderToUpdate, {
        status: ORDER_STATUS.inProgress,
        acceptedAt: Date.now(),
        ...(updatedPreparationTime !== selectedOrder.estimatedTimeToDeliver
          ? {
              estimatedTimeToDeliver: updatedPreparationTime,
            }
          : {}),
      });

      toaster.success(`Commande acceptée 🚀`, {
        description: `La commande numéro ${orderToUpdate.id} est desormais en préparation`,
      });
      setConfirmModal(null);
      if (updatedPreparationTime !== selectedOrder.estimatedTimeToDeliver) {
        setSelectedOrder({
          ...selectedOrder,
          status: ORDER_STATUS.inProgress,
          acceptedAt: Date.now(),
          estimatedTimeToDeliver: updatedPreparationTime,
        });
      }
      if (ref.current) {
        handlePrint();
      }
    } catch (error) {
      setConfirmModal(null);

      toaster.danger(`Une erreur est survenue`, {
        description: `Une erreur est survenue avec la commande ${order.id}, contactez notre support`,
      });
    }
  };

  const onCancel = async order => {
    try {
      setCancelInProgress(order.id);
      setConfirmModal(null);
      await finishOrder(order, 'CANCELED', 'shop');

      toaster.success(`Commande annulé ❌`, {
        description: `La commande numéro ${order.id} est desormais annulé`,
      });
      setCancelInProgress(null);
    } catch (error) {
      setCancelInProgress(null);
      toaster.danger(`Une erreur est survenue`, {
        description: `Impossible d'annuler la commande ${order.id}`,
      });
    }
  };

  const hasExistingOrders = !!orders.length;
  const preparationTime = !selectedOrder
    ? 0
    : selectedOrder.estimatedTimeToDeliver ||
      computePreparationTime(selectedOrder.itemsList);

  return (
    <TabPanel value={value} index={0}>
      <TabContentWrapper>
        {hasExistingOrders ? (
          <>
            <HeaderTabRow>
              <Heading size={800}>Les nouvelles commandes</Heading>
              <Button
                small
                minimal
                startIcon={<OrdersIcon color={theme.black} size="16px" />}
                onClick={toggleOrdersDrawer}
              >
                Toutes les commandes
              </Button>
            </HeaderTabRow>

            <OrdersListWrapper>
              {orders.map(order => {
                return (
                  <>
                    <NewOrderCard
                      key={order.id}
                      onClickCancel={() => {
                        setConfirmModal('cancel');
                        setSelectedOrder(order);
                      }}
                      onClickConfirm={() => {
                        setConfirmModal('confirm');
                        setSelectedOrder(order);
                      }}
                      order={order}
                      cancelInProgress={cancelInProgress === order.id}
                    />
                  </>
                );
              })}
            </OrdersListWrapper>
            {!!confirmModal ? (
              <NewOrderDialog
                isOpen={!!confirmModal}
                confirmModal={confirmModal}
                preparationTime={preparationTime}
                onClose={() => setConfirmModal(null)}
                onConfirm={updatedPreparationTime =>
                  onConfirm(selectedOrder, updatedPreparationTime)
                }
                onCancel={() => onCancel(selectedOrder)}
                order={selectedOrder}
              />
            ) : null}
          </>
        ) : (
          <EmptyNewOrders>
            <EmptyTab
              title={'Pas de nouvelle commande'}
              description={
                'Ne vous inquietez pas, les commandes ne vont pas tarder à pleuvoir ⏳'
              }
            />
          </EmptyNewOrders>
        )}
        <div style={{ display: 'none' }}>
          <KitchenReceipt
            ref={ref}
            {...(!!selectedOrder
              ? {
                  order: selectedOrder,
                  orderAt: DateTime.fromMillis(
                    selectedOrder.orderAt
                  ).toLocaleString(DateTime.DATETIME_SHORT),
                  estimatedDeliveryTime: computeEstimatedTime({
                    ts: Date.now(),
                    withDelay: false,
                    minutes: preparationTime,
                    seconds: selectedOrder.duration,
                  }),
                }
              : {})}
          />
        </div>
      </TabContentWrapper>
    </TabPanel>
  );
};

export default NewsTab;

import React from 'react';

import { Heading, AddressForm } from '../../../styleguide';

const Address = props => {
  const { shop, onShopUpdate, onNext } = props;

  const onSubmitForm = value => {
    onShopUpdate({ address: value });
    onNext();
  };

  return (
    <>
      <Heading size={900}>Votre adresse</Heading>
      <AddressForm onSubmit={onSubmitForm} lite={shop.name} />
    </>
  );
};

export default Address;

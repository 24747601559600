import React from 'react';
import { Switch, FormControlLabel, Select } from '@material-ui/core';
import { isEqual } from 'lodash';

import { Heading, Text, Button } from '../../../../styleguide';
import { RowWrapper } from '../../../../styles/shoptabs';
import { SPACING } from '../../../../styles/constants';
import {
  HoursWrapper,
  HoursGrid,
  HoursGridItem,
  HoursGridItemContent,
  FlexFieldsWrapper,
} from '../../../../styles/shop';
import { DEFAULT_HOURS } from '../../../../actions/shop/createShop';
import { updateShop } from '../../../../actions/shop/updateShop';

const START_TIME = 11;
const END_TIME = 23;

const generateHoursSlotsSelect = () => {
  const hoursSlots = [];
  const quarterHours = ['00', '15', '30', '45'];

  for (let i = START_TIME; i < END_TIME; i++) {
    for (let j = 0; j < 4; j++) {
      hoursSlots.push(`${i}:${quarterHours[j]}`);
    }
  }

  return hoursSlots;
};

const HoursRow = props => {
  const { shop, theme } = props;
  const [hours, setHours] = React.useState(shop.hours || DEFAULT_HOURS);
  const [loading, setLoading] = React.useState(false);

  const existingHours = React.useMemo(() => shop.hours || null, [shop]);
  const hoursSlotsSelect = React.useMemo(() => generateHoursSlotsSelect(), []);
  const hoursHaveChange = React.useMemo(() => {
    if (!existingHours && !isEqual(hours, DEFAULT_HOURS)) {
      return true;
    }
    if (!existingHours && isEqual(hours, DEFAULT_HOURS)) {
      return false;
    }
    return hours.some(
      (hour, index) =>
        existingHours[index]?.opening !== hour.opening ||
        existingHours[index]?.closing !== hour.closing ||
        existingHours[index]?.isOpen !== hour.isOpen
    );
  }, [hours, existingHours]);

  const onChangeHours = (day, field, value) => {
    setHours(
      hours.map(hour => {
        if (day === hour.day) {
          return {
            ...hour,
            day,
            [field]: value,
          };
        }
        return hour;
      })
    );
  };

  const onSubmitUpdatedHours = async () => {
    setLoading(true);
    const updatedShop = {
      ...shop,
      hours,
    };

    if (!isEqual(shop, updatedShop)) {
      try {
        await updateShop(updatedShop);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        console.error(error);
        throw new Error(error);
      }
    }
  };

  return (
    <RowWrapper theme={theme}>
      <Heading size={800}>Vos horaires</Heading>
      <HoursWrapper>
        <FlexFieldsWrapper
          justify="space-between"
          align="center"
          style={{
            margin: '0 auto',
            maxWidth: '75em',
            gap: SPACING[100],
          }}
          padding={`${SPACING[200]} 0`}
        >
          <Text muted size={500} className="hours-description">
            Les horaires d'ouvertures sont uniquement utilisés pour l'affichage
            sur votre établissement. <br />
            Il ne modifie pas l'ouverture ou la fermeture de votre
            établissement.
          </Text>
          <Button
            small
            disabled={!hoursHaveChange}
            onClick={onSubmitUpdatedHours}
            isLoading={loading}
          >
            Sauvegarder
          </Button>
        </FlexFieldsWrapper>

        <HoursGrid>
          {hours.map(({ day, isOpen, opening, closing }) => (
            <HoursGridItem theme={theme} key={`${day}-${opening}-${closing}`}>
              <Heading size={500}>{day}</Heading>
              <HoursGridItemContent closed={!isOpen}>
                <FormControlLabel
                  labelPlacement="start"
                  control={
                    <Switch
                      checked={isOpen}
                      onChange={(event, checked) =>
                        onChangeHours(day, 'isOpen', checked)
                      }
                      size="small"
                      color="primary"
                    />
                  }
                  label={
                    <Text size={500} muted={isOpen}>
                      {isOpen ? 'Ouvert' : 'Fermé'}
                    </Text>
                  }
                />
                <FormControlLabel
                  control={
                    <Select
                      native
                      name="opening"
                      disabled={!isOpen}
                      variant="standard"
                      className="input"
                      value={opening}
                      onChange={event =>
                        onChangeHours(day, 'opening', event.target.value)
                      }
                    >
                      {hoursSlotsSelect.map(hour => (
                        <option key={hour} value={hour}>
                          {hour}
                        </option>
                      ))}
                    </Select>
                  }
                  label={
                    <Text size={500} muted>
                      De:
                    </Text>
                  }
                  className="field"
                  labelPlacement="start"
                />
                <FormControlLabel
                  control={
                    <Select
                      native
                      name="closing"
                      disabled={!isOpen}
                      variant="standard"
                      className="input"
                      value={closing}
                      onChange={event =>
                        onChangeHours(day, 'closing', event.target.value)
                      }
                    >
                      {hoursSlotsSelect.map(hour => (
                        <option key={hour} value={hour}>
                          {hour}
                        </option>
                      ))}
                    </Select>
                  }
                  className="field"
                  label={
                    <Text size={500} muted>
                      à:
                    </Text>
                  }
                  labelPlacement="start"
                />
              </HoursGridItemContent>
            </HoursGridItem>
          ))}
        </HoursGrid>
      </HoursWrapper>
    </RowWrapper>
  );
};

export default HoursRow;

import React from 'react';
import styled from 'styled-components';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { CheckBox, Button, HoverableLink, Text } from '../../styleguide';
import { SPACING } from '../../styles/constants';
import { BasicFlexBlock } from '../../styles/common';
import { useTheme } from '../../hooks';

const PaymentMethodWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const PaymentMethodModal = props => {
  const {
    isOpen,
    onClose,
    paymentMethodsList,
    setPaymentMethod,
    selectedPaymentMethod,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation(['common', 'checkout']);

  const onSelectPaymentMethod = address => {
    setPaymentMethod(address);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <DialogTitle>{t('checkout:payment_method_modal:title')}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t('checkout:payment_method_modal:message')}
        </DialogContentText>

        <PaymentMethodWrapper>
          {paymentMethodsList && paymentMethodsList.length
            ? paymentMethodsList.map(paymentMethod => (
                <FormControlLabel
                  key={paymentMethod.id}
                  control={
                    <CheckBox
                      checked={
                        !!selectedPaymentMethod &&
                        paymentMethod.id === selectedPaymentMethod.id
                      }
                      onChange={() => onSelectPaymentMethod(paymentMethod)}
                      name={paymentMethod.id}
                      wrapperProps={{ justify: 'flex-start' }}
                    />
                  }
                  label={
                    <div
                      style={{
                        width: '100%',
                        display: 'flex',
                        flexDirection: 'column',
                      }}
                    >
                      <Text
                        muted
                        size={300}
                        styles={{ display: 'inline-flex' }}
                      >
                        {paymentMethod.billing_details.name}
                      </Text>
                      <Text
                        size={400}
                        style={{
                          color: theme.headingColor,
                        }}
                      >
                        {`•••• •••• •••• ${paymentMethod.card.last4} - ${paymentMethod.card.exp_month}/${paymentMethod.card.exp_year}`}
                      </Text>
                    </div>
                  }
                />
              ))
            : null}
          <BasicFlexBlock padding={SPACING[200]}>
            <HoverableLink
              color={theme.primaryColor}
              to={{
                pathname: `/account/payment_methods/create`,
                state: { fromOrder: true },
              }}
            >
              {t('checkout:payment_method_modal:add_payment_method')}
            </HoverableLink>
          </BasicFlexBlock>
        </PaymentMethodWrapper>
      </DialogContent>

      <DialogActions>
        <Button small onClick={onClose} minimal>
          {t('common:close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PaymentMethodModal;

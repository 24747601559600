import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';
import isEmpty from 'lodash/isEmpty';

import { prepareDocForUpdate } from '../helpers/firestoreHelpers';

const updateDiscount = (data, id) => {
  // console.log('Update shop called', { data });
  ReactGA.event({
    category: 'Discount',
    action: 'Update Discount',
  });

  try {
    const updatedDiscount = prepareDocForUpdate({
      ...data,
      exclusive: !!data.exclusiveTo.length,
      exclusiveTo: !isEmpty(data.exclusiveTo)
        ? data.exclusiveTo
            .replace(/\s/g, '')
            .split(',')
            .filter(Boolean)
        : [],
    });

    return Firebase.firestore()
      .collection('discounts')
      .doc(id)
      .update(updatedDiscount);
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

export default updateDiscount;

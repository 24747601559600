import React from 'react';
import { ImpulseSpinner } from 'react-spinners-kit';
import { add, ceil } from 'lodash';
import { Chip } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { parseNumber } from '../../common';
import {
  computeDeliveryFees,
  computeTotalFees,
} from '../../actions/orders/helpers/computeWithDiscount';
import { Heading, Text } from '../../styleguide';
import {
  TotalsWrapper,
  TitleColumn,
  PricesColumm,
  TotalsRowsWrapper,
  TotalsRow,
} from '../../styles/basket';
import { TotalsLoader, TotalsLoadingWrapper } from '../../styles/newOrder';
import { SPACING } from '../../styles/constants';
import { useTheme } from '../../hooks';
import { APP_FEES } from '../../common/fees';

const Totals = props => {
  const { appFees, deliveryData, total, isLoading, discount } = props;
  const theme = useTheme();
  const { t } = useTranslation(['common', 'checkout']);

  if (isLoading || !deliveryData || !deliveryData.value) {
    return (
      <TotalsLoadingWrapper>
        <TotalsLoader theme={theme}>
          <ImpulseSpinner size={34} color={theme.primaryColor} loading />
        </TotalsLoader>
        <TotalsWrapper theme={theme}>
          <TitleColumn>
            <Text muted styles={{ padding: '8px 0' }}>
              {t('checkout:subtotal')}{' '}
            </Text>
            <Text muted styles={{ padding: '8px 0' }}>
              {t('checkout:app_fees')}{' '}
            </Text>
            <Text muted styles={{ padding: '8px 0' }}>
              {t('checkout:delivery_fee')}{' '}
            </Text>
            <Heading
              style={{ padding: '8px 0', color: theme.headingColor }}
              size={600}
            >
              {t('checkout:total')}
            </Heading>
          </TitleColumn>
          <PricesColumm>
            <Text muted styles={{ padding: '8px 0' }}>
              ...
            </Text>
            <Text muted styles={{ padding: '8px 0' }}>
              ...
            </Text>
            <Text muted styles={{ padding: '8px 0' }}>
              ...
            </Text>
            <Heading
              style={{ color: theme.primaryColor, padding: '8px 0' }}
              size={600}
            >
              ...
            </Heading>
          </PricesColumm>
        </TotalsWrapper>
      </TotalsLoadingWrapper>
    );
  }

  const deliveryFeesWithDiscount = computeDeliveryFees(
    discount,
    deliveryData.value.deliveryFees
  );
  const computedFees = computeTotalFees(appFees, deliveryFeesWithDiscount);
  const computedTotal = parseNumber(
    add(parseNumber(total), parseNumber(computedFees))
  ).toFixed(2);

  return (
    <TotalsRowsWrapper theme={theme}>
      <TotalsRow>
        <Text muted>{t('checkout:subtotal')} </Text>
        <Text>{parseNumber(total).toFixed(2)}€</Text>
      </TotalsRow>
      <TotalsRow>
        <div styles={{ display: 'flex' }}>
          <Text muted>{t('checkout:app_fees')} </Text>
          {discount && (
            <Chip
              size="small"
              label={`-${discount.appFeesValue}%`}
              color={'primary'}
              variant={'outlined'}
              style={{ marginLeft: `${SPACING[100]}` }}
            />
          )}
        </div>
        <div styles={{ display: 'flex' }}>
          {discount && (
            <Text
              muted
              styles={{
                textDecoration: 'line-through',
                marginRight: `${SPACING[100]}`,
              }}
            >
              {parseNumber(APP_FEES).toFixed(2)}€
            </Text>
          )}
          <Text>{parseNumber(appFees).toFixed(2)}€</Text>
        </div>
      </TotalsRow>
      <TotalsRow>
        <div styles={{ display: 'flex' }}>
          <Text muted>
            {t('checkout:delivery_fee')} -{' '}
            <b>{Math.round(deliveryData.value.distance / 100) / 10}km</b>
          </Text>
          {discount && (
            <Chip
              size="small"
              label={`-${discount.deliveryFeesValue}%`}
              color={'primary'}
              variant={'outlined'}
              style={{ marginLeft: `${SPACING[100]}` }}
            />
          )}
        </div>
        <div styles={{ display: 'flex' }}>
          {discount && (
            <Text
              muted
              styles={{
                textDecoration: 'line-through',
                marginRight: `${SPACING[100]}`,
              }}
            >
              {parseNumber(deliveryData.value.deliveryFees).toFixed(2)}€
            </Text>
          )}
          <Text>{parseNumber(deliveryFeesWithDiscount).toFixed(2)}€</Text>
        </div>
      </TotalsRow>
      <TotalsRow style={{ marginTop: SPACING[100] }}>
        <Heading style={{ color: theme.headingColor }} size={600}>
          {t('checkout:total')}
        </Heading>
        <Heading style={{ color: theme.primaryColor }} size={600}>
          {computedTotal}€
        </Heading>
      </TotalsRow>
    </TotalsRowsWrapper>
  );
};

export default Totals;

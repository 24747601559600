import styled from 'styled-components';

import { SPACING } from './constants';

const AddressesWrapper = styled.div`
  padding: ${SPACING[200]} 0;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
`;

const DeliveryTimeWrapper = styled.div`
  padding: ${SPACING[200]} 0;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  text-align: center;

  .details {
    width: 100%;
    display: flex;
    text-align: center;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
`;

const AddressDetailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const ItemsWrapper = styled.div`
  padding: ${SPACING[200]} 0;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
`;

const PaymentMethodWrapper = styled.div`
  padding: ${SPACING[200]} 0;
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
`;

const TotalsWrapper = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
`;

const NotesWrapper = styled.div`
  width: 100%;
  max-width: ${props => props.width || '700px'};
  margin: 0 auto;
  padding: ${SPACING[200]} 0;
`;

const TotalsLoadingWrapper = styled.div`
  width: 100%;
  position: relative;
`;

const TotalsLoader = styled.div`
  width: 100%;
  position: absolute;
  height: 140px;
  top: 20px;
  background: ${({ theme }) => theme.shadow};
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PMLoader = styled.div`
  width: calc(100% + 16px);
  position: absolute;
  height: 53px;
  left: -8px;
  top: -8px;
  border-radius: 4px;
  background: ${({ theme }) => theme.shadow};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  TotalsLoadingWrapper,
  TotalsLoader,
  AddressDetailsWrapper,
  AddressesWrapper,
  ItemsWrapper,
  TotalsWrapper,
  PaymentMethodWrapper,
  PMLoader,
  NotesWrapper,
  DeliveryTimeWrapper,
};

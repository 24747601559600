import React from 'react';
import { IconButton } from '@material-ui/core';
import { useLocation, Redirect } from 'react-router-dom';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTranslation } from 'react-i18next';

import { useSession } from '../../hooks';
import {
  NavLink,
  Container,
  Backdrop,
  Text,
  LanguageSwitcher,
} from '../../styleguide';
import { Page } from '../common';
import { signUpWithCredentials, logOut } from '../../actions/login';
import { createUser } from '../../actions/users';
import { ReturnIcon, LogoutIcon } from '../../styleguide/icons';
import { useTheme } from '../../hooks';
import SignupForm from './steps/SignupForm';
import AddressForm from './steps/AddressForm';

const STEPS = [
  {
    index: 1,
    name: 'signup form',
    Step: SignupForm,
  },
  {
    index: 2,
    name: 'address',
    Step: AddressForm,
  },
];

const CREDENTIALS_DATA = {
  name: '',
  pwd: '',
  email: '',
  phone: '',
  isValidPhone: false,
  address: null,
};

const SignUp = props => {
  const [currentStep, setStep] = React.useState(1);
  const [isLoading, setIsLoading] = React.useState(false);
  const [error, setError] = React.useState(null);
  const theme = useTheme();
  let location = useLocation();
  const { isLoggedIn, authUser, user } = useSession();
  const { t, i18n } = useTranslation(['signup', 'common']);

  const loggedInUserData = isLoggedIn
    ? {
        name: authUser.displayName,
        email: authUser.email,
        phone: authUser.phoneNumber,
      }
    : {};
  const [credentials, setCredentials] = React.useState({
    ...CREDENTIALS_DATA,
    ...loggedInUserData,
  });

  const { Step } = STEPS.filter(item => item.index === currentStep)[0];
  let { from } = location.state || { from: { pathname: '/login' } };

  const onSubmit = async () => {
    try {
      if (!isLoggedIn) {
        setIsLoading(true);
        const error = await signUpWithCredentials(credentials, i18n.language);

        if (error) {
          if (!!error.code && error.code === 'auth/email-already-in-use') {
            setError({
              code: t('signup:email_already_used_code'),
              message: t('signup:email_already_used'),
            });
          }
        }
      } else {
        setIsLoading(true);
        const credentialsClone = credentials;
        delete credentialsClone.pwd;
        await createUser(credentialsClone, authUser.uid, i18n.language);
      }
      setCredentials(CREDENTIALS_DATA);
      setIsLoading(false);
    } catch (error) {
      console.error('error', error);
      setIsLoading(false);
      throw new Error(error.toString());
    }
  };

  const onCredentialsUpdate = data => {
    setCredentials({ ...credentials, ...data });
  };

  const onNextStep = () => setStep(currentStep + 1);

  React.useEffect(() => {
    const isFinalStep =
      !!credentials.name &&
      !!credentials.email &&
      !!credentials.phone &&
      !!credentials.address &&
      (!!credentials.pwd || isLoggedIn);

    if (isFinalStep && !isLoading) {
      onSubmit();
      // console.log('final step');
    }
  });

  if (!isLoading && isLoggedIn && user) {
    // console.log(`already loggedin and user db exist, redirect to: /`, {
    //   isLoggedIn,
    //   user,
    // });
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from },
        }}
      />
    );
  }

  return (
    <Page
      navbarLeftChild={
        <NavLink color={theme.headingColor} to={from}>
          <IconButton aria-label="retour">
            <ReturnIcon color={theme.headingColor} size="16px" />
          </IconButton>
        </NavLink>
      }
      navbarRightChild={
        isLoggedIn ? (
          <div
            style={{
              display: 'flex',
              justifyContent: 'flex-end',
              alignItems: 'center',
            }}
          >
            <LanguageSwitcher
              wrapperProps={`
                margin-top: 0;
                justify-content: flex-end;
              `}
            />
            <IconButton aria-label="retour" onClick={logOut}>
              <LogoutIcon color={theme.headingColor} size="16px" />
            </IconButton>
          </div>
        ) : (
          <LanguageSwitcher
            wrapperProps={`
              margin-top: 0;
              justify-content: flex-end;
            `}
          />
        )
      }
      name={t('signup:name')}
    >
      <Container>
        {error && (
          <Alert severity="error" style={{ margin: '16px 0px' }}>
            <AlertTitle>{t('signup:global_error')}</AlertTitle>
            <Text muted>{error.code}</Text>
            <br />
            <Text>{error.message}</Text>
          </Alert>
        )}
        <Step
          credentialsData={credentials}
          onNext={onNextStep}
          onCredentialsUpdate={onCredentialsUpdate}
          isLoading={isLoading}
          currentStep={currentStep}
        />
        {isLoading && <Backdrop />}
      </Container>
    </Page>
  );
};

export default SignUp;

import React from 'react';
import Firebase from 'firebase/app';
import styled from 'styled-components';
import {
  Dialog,
  DialogContent,
  Slide,
  Toolbar,
  IconButton,
  InputAdornment,
  Paper,
} from '@material-ui/core';
import {
  Heading,
  Text,
  Input,
  Button,
  ContentLoader,
} from '../../../../../styleguide';
import { CloseIcon, SearchIcon } from '../../../../../styleguide/icons';
import { SPACING } from '../../../../../styles/constants';
import { useTheme } from '../../../../../hooks';
import { FormWrapper, FieldsRow } from '../../../../../styles/form';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ResultsList = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  gap: ${SPACING[100]};
`;

const SearchModal = props => {
  const { isOpen, onClose, onSelectResult, ctaLabel } = props;
  const theme = useTheme();
  const [value, setValue] = React.useState('');
  const [query, setQuery] = React.useState(null);
  const [results, setResults] = React.useState([]);
  const [isSearching, setIsSearching] = React.useState(false);

  React.useEffect(() => {
    const onSearch = async () => {
      if (!query) {
        return;
      }
      setIsSearching(true);
      const emailRes = await Firebase.firestore()
        .collection('users')
        .where('email', '==', query)
        .limit(10)
        .get();
      const idRes = await Firebase.firestore()
        .collection('users')
        .doc(query)
        .get();
      const nameRes = await Firebase.firestore()
        .collection('users')
        .where('name', 'in', [query])
        .limit(10)
        .get();

      const users = [
        ...(emailRes?.docs?.map(doc => ({ ...doc.data(), id: doc.id })) || []),
        ...(nameRes?.docs?.map(doc => ({ ...doc.data(), id: doc.id })) || []),
        idRes.exists && { ...idRes.data(), id: idRes.id },
      ].filter(Boolean);

      setIsSearching(false);
      setResults(users);
    };

    if (!!query) {
      onSearch();
    }
  }, [query]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      fullWidth
      maxWidth="sm"
    >
      <Toolbar>
        <Heading size={600} styles={{ flex: 1 }}>
          Recherche
        </Heading>
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon style={{ color: theme.headingColor }} />
        </IconButton>
      </Toolbar>
      <DialogContent style={{ minHeight: '500px' }}>
        <Text muted size={400}>
          Vous pouvez rechercher des utilisateurs par email ou id ou nom prénom.
        </Text>
        <FormWrapper
          maxWidth="100%"
          paddingV={SPACING[100]}
          paddingH="0"
          onSubmit={e => e.preventDefault()}
        >
          <FieldsRow>
            <Input
              type="text"
              name="data-search-modal-admin"
              label="Recherche"
              value={value}
              placeholder="test@test.fr ou 123456789 ou Nom Prénom"
              onChange={e => setValue(e.target.value)}
              wrapperProps={{
                padding: '0',
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <SearchIcon
                      style={{ color: theme.headingColor }}
                      size="16px"
                    />
                  </InputAdornment>
                ),
              }}
            />
            <Button
              small
              color="secondary"
              startIcon={<SearchIcon color={theme.white} size="16px" />}
              onClick={() => setQuery(value)}
            >
              GO
            </Button>
          </FieldsRow>
        </FormWrapper>
        <ResultsList>
          {isSearching && <ContentLoader text="Recherche en cours ..." />}
          {results.map(user => (
            <Paper
              style={{
                padding: SPACING[200],
                display: 'flex',
                alignItems: 'center',
              }}
            >
              <div
                style={{
                  flex: 1,
                  display: 'flex',
                  flexDirection: 'column',
                  gap: SPACING[100],
                }}
              >
                <Text size={500}>{user.name}</Text>
                <Text muted size={300}>
                  {user.id} - {user.email}
                </Text>
              </div>

              <Button
                small
                color="secondary"
                variant="outlined"
                onClick={() => {
                  onClose();
                  onSelectResult(user);
                }}
              >
                {ctaLabel || 'Voir'}
              </Button>
            </Paper>
          ))}
        </ResultsList>
      </DialogContent>
    </Dialog>
  );
};

export default SearchModal;

import styled from 'styled-components';
import { MenuAlt2 } from '@styled-icons/heroicons-outline/MenuAlt2';
import { Close } from '@styled-icons/material-rounded/Close';
import { DeleteForever } from '@styled-icons/material-rounded/DeleteForever';
import { Duplicate } from '@styled-icons/ionicons-sharp/Duplicate';
import { Settings } from '@styled-icons/feather/Settings';
import { LogIn } from '@styled-icons/feather';
import {
  Chat,
  ExpandMore,
  Dashboard,
  NotInterested,
  MoreVert,
} from '@styled-icons/material-outlined/';
import { Google2 } from '@styled-icons/icomoon';
import { Location, Shop } from '@styled-icons/entypo';
import { Clock } from '@styled-icons/heroicons-outline/Clock';
import { Percentage } from '@styled-icons/fa-solid/Percentage';
import { PhoneSquareAlt } from '@styled-icons/fa-solid/PhoneSquareAlt';
import { ChartBar as ChartBarFull } from '@styled-icons/heroicons-outline/ChartBar';
import { ShiftsAvailability } from '@styled-icons/fluentui-system-regular/ShiftsAvailability';
import {
  CreditCardFront,
  ArrowBack,
  Basket,
  User,
  HomeAlt,
  PlusCircle,
  Plus,
  MinusCircle,
  Euro,
  LogOutCircle,
  File,
  EditAlt,
  UserCheck,
  MailSend,
  CheckCircle,
  Circle,
  Printer,
  ChevronRight,
  SearchAlt2,
} from '@styled-icons/boxicons-regular';
import { LockOpen, LockClosed, ChartBar } from '@styled-icons/heroicons-solid';
import { Stripe } from '@styled-icons/boxicons-logos';
import { CopyAlt } from '@styled-icons/boxicons-regular/CopyAlt';
import { Discount } from '@styled-icons/boxicons-solid/Discount';

const ICONS = [
  {
    name: 'MenuIcon',
    Icon: MenuAlt2,
  },
  {
    name: 'SearchIcon',
    Icon: SearchAlt2,
  },
  {
    name: 'PrintIcon',
    Icon: Printer,
  },
  {
    name: 'ChartFullIcon',
    Icon: ChartBarFull,
  },
  {
    name: 'ChartEmptyIcon',
    Icon: ChartBar,
  },
  {
    name: 'DiscountIcon',
    Icon: Discount,
  },
  {
    name: 'NotAvailableIcon',
    Icon: NotInterested,
  },
  {
    name: 'AvailableIcon',
    Icon: UserCheck,
  },
  {
    name: 'ClockIcon',
    Icon: Clock,
  },
  {
    name: 'CheckIcon',
    Icon: CheckCircle,
  },
  {
    name: 'BasketIcon',
    Icon: Basket,
  },
  {
    name: 'CloseIcon',
    Icon: Close,
  },
  {
    name: 'DeleteIcon',
    Icon: DeleteForever,
  },
  {
    name: 'PhoneIcon',
    Icon: PhoneSquareAlt,
  },
  {
    name: 'DuplicateIcon',
    Icon: Duplicate,
  },
  {
    name: 'SendIcon',
    Icon: MailSend,
  },
  {
    name: 'ReturnIcon',
    Icon: ArrowBack,
  },
  {
    name: 'LoginIcon',
    Icon: LogIn,
  },
  {
    name: 'LogoutIcon',
    Icon: LogOutCircle,
  },
  {
    name: 'OrdersIcon',
    Icon: File,
  },
  {
    name: 'SettingsIcon',
    Icon: Settings,
  },
  {
    name: 'ChatIcon',
    Icon: Chat,
  },
  {
    name: 'GoogleIcon',
    Icon: Google2,
  },
  {
    name: 'LocationIcon',
    Icon: Location,
  },
  {
    name: 'CardIcon',
    Icon: CreditCardFront,
  },
  {
    name: 'UserIcon',
    Icon: User,
  },
  {
    name: 'HomeIcon',
    Icon: HomeAlt,
  },
  {
    name: 'ShopIcon',
    Icon: Shop,
  },
  {
    name: 'OpenIcon',
    Icon: LockOpen,
  },
  {
    name: 'ClosedIcon',
    Icon: LockClosed,
  },
  {
    name: 'PlusCircleIcon',
    Icon: PlusCircle,
  },
  {
    name: 'PlusIcon',
    Icon: Plus,
  },
  {
    name: 'PercentageIcon',
    Icon: Percentage,
  },
  {
    name: 'MinusIcon',
    Icon: MinusCircle,
  },
  {
    name: 'EuroIcon',
    Icon: Euro,
  },
  {
    name: 'ExpandIcon',
    Icon: ExpandMore,
  },
  {
    name: 'DashboardIcon',
    Icon: Dashboard,
  },
  {
    name: 'StripeIcon',
    Icon: Stripe,
  },
  {
    name: 'EditIcon',
    Icon: EditAlt,
  },
  {
    name: 'AvailabilityIcon',
    Icon: ShiftsAvailability,
  },
  {
    name: 'MoreVertIcon',
    Icon: MoreVert,
  },
  {
    name: 'CopyIcon',
    Icon: CopyAlt,
  },
  {
    name: 'CircleIcon',
    Icon: Circle,
  },
  {
    name: 'ChevronIcon',
    Icon: ChevronRight,
  },
];

const generateIcons = () => {
  const IconsComponents = {};
  ICONS.map(
    icon =>
      (IconsComponents[icon.name] = styled(icon.Icon)`
        color: ${props => (!!props.color ? props.color : 'currentColor')};
        width: ${props => (!!props.size ? props.size : '16px')};
        height: ${props => (!!props.size ? props.size : '16px')};
        margin: ${props => (!!props.margin ? props.margin : '0')};
        padding: ${props => (!!props.padding ? props.padding : '0')};
        transform: ${props => (!!props.return ? 'rotate(180deg)' : 'none')};
      `)
  );

  return IconsComponents;
};

export const {
  NotAvailableIcon,
  AvailableIcon,
  MenuIcon,
  SearchIcon,
  ChartFullIcon,
  ChartEmptyIcon,
  DiscountIcon,
  ClockIcon,
  CheckIcon,
  BasketIcon,
  CloseIcon,
  DeleteIcon,
  PhoneIcon,
  DuplicateIcon,
  SendIcon,
  ReturnIcon,
  LoginIcon,
  LogoutIcon,
  OrdersIcon,
  SettingsIcon,
  ChatIcon,
  GoogleIcon,
  LocationIcon,
  CardIcon,
  UserIcon,
  HomeIcon,
  ShopIcon,
  OpenIcon,
  ClosedIcon,
  PlusIcon,
  PlusCircleIcon,
  PercentageIcon,
  MinusIcon,
  EuroIcon,
  ExpandIcon,
  DashboardIcon,
  StripeIcon,
  EditIcon,
  AvailabilityIcon,
  MoreVertIcon,
  CopyIcon,
  CircleIcon,
  PrintIcon,
  ChevronIcon,
} = generateIcons();

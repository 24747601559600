import React from 'react';
import styled from 'styled-components';
import { useHistory, useLocation } from 'react-router-dom';
import { Avatar } from '@material-ui/core';
import { useUnstated } from '@gitbook/unstated';
import { useTranslation } from 'react-i18next';

import { BasketContainer } from '../../contexts';
import { logOut } from '../../actions/login';
import {
  NavDrawerHeader,
  NavDrawerGroup,
  NavDrawerHeaderAvatarWrapper,
  NavDrawerActionsWrapper,
  NavDrawerHeaderLogoutButton,
} from '../../styles/common';
import { useSession, useTheme } from '../../hooks';
import {
  Drawer,
  Heading,
  Button,
  Text,
  LanguageSwitcher,
} from '../../styleguide';
import { updateLanguage } from '../../actions/users/updateUser';
import { getDisplayedName, getInitials } from '../../common';
import { SPACING, colors } from '../../styles/constants';
import { OrdersIcon, DashboardIcon, LogoutIcon } from '../../styleguide/icons';
import { NAV_LINKS } from './NAV_LINKS';
import NavItem from './NavItem';
import { version } from '../../../package.json';

const MenuWrapper = styled.div`
  width: 300px;
  height: 92vh;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
`;

const LngIcon = styled.img`
  width: 32px;
  height: 32px;
  border-radius: 50%;
  border: ${({ active }) =>
    active ? `1px solid ${colors.green}` : '1px solid transparent'};

  &:hover {
    cursor: pointer;
    transform: ${({ active }) => (active ? 'scale(1)' : 'scale(1.1)')};
    cursor: ${({ active }) => (active ? 'initial' : 'pointer')};
  }
`;

const NavDrawer = props => {
  const { isOpen, toggleDrawer } = props;
  const { authUser, user } = useSession();
  const [logoutInProgress, setLogoutInProgress] = React.useState(false);
  const theme = useTheme();
  let location = useLocation();
  let history = useHistory();
  const basketContainer = useUnstated(BasketContainer);
  const { t, i18n } = useTranslation(['sidebar', 'common']);

  const initials = getInitials(user);
  const withAction = user.role !== 'client';
  const displayedName = getDisplayedName(user);

  const onClickRedirect = path => {
    toggleDrawer('');
    const { pathname } = location;
    if (pathname !== path) {
      history.push(path, { from: { pathname } });
    }
  };

  const onUpdateLanguage = async lng => {
    toggleDrawer();
    await updateLanguage({
      authID: authUser.uid,
      lng,
      settings: user.settings,
    });
  };

  const onLogout = async () => {
    setLogoutInProgress(true);
    if (!basketContainer.isEmpty) {
      basketContainer.resetBasket();
    }
    await logOut(authUser);
    setLogoutInProgress(false);
  };

  return (
    <Drawer
      anchor="left"
      isOpen={isOpen}
      toggleDrawer={toggleDrawer}
      name="nav"
    >
      <MenuWrapper>
        <NavDrawerHeader borderColor={theme.lightGray}>
          <NavDrawerHeaderLogoutButton>
            <Button
              small
              minimal
              variant="outlined"
              onClick={onLogout}
              disabled={logoutInProgress}
              endIcon={
                <LogoutIcon
                  return
                  size={'16px'}
                  padding={'2px 0 0 0'}
                  color={theme.headingColor}
                />
              }
              styles={{ fontSize: '11px' }}
            >
              {t('logout', { ns: 'common' })}
            </Button>
          </NavDrawerHeaderLogoutButton>
          <NavDrawerHeaderAvatarWrapper color={theme.lightGray}>
            <Avatar
              onClick={() => onClickRedirect('/')}
              style={{
                backgroundColor: theme.primaryColor,
                color: theme.white,
                width: '100px',
                height: '100px',
                fontSize: '42px',
                cursor: 'pointer',
              }}
            >
              {initials}
            </Avatar>
          </NavDrawerHeaderAvatarWrapper>
          <Heading size={800}>{displayedName}</Heading>
          <LanguageSwitcher
            activeLanguage={user?.settings?.lng}
            onLanguageUpdate={onUpdateLanguage}
          />
        </NavDrawerHeader>
        {withAction ? (
          <NavDrawerActionsWrapper borderColor={theme.lightGray}>
            {user.role === 'shop-owner' || user.role === 'shop-staff' ? (
              <>
                <Button
                  small
                  primary
                  onClick={() => onClickRedirect('/shopDashboard')}
                  startIcon={<DashboardIcon color={theme.white} size="16px" />}
                >
                  Gérer mon établissement
                </Button>
                <Button
                  small
                  primary
                  onClick={() => onClickRedirect('/shopDashboard/orders/')}
                  startIcon={<OrdersIcon color={theme.white} size="16px" />}
                  styles={{ marginTop: SPACING[200] }}
                >
                  Gestion commandes
                </Button>
              </>
            ) : null}
            {user.role === 'rusher' ? (
              <>
                <Button
                  small
                  primary
                  onClick={() => onClickRedirect('/rusher/profil')}
                  startIcon={<DashboardIcon color={theme.white} size="16px" />}
                >
                  Mon profil livreur
                </Button>
                <Button
                  small
                  primary
                  onClick={() => onClickRedirect('/rusher/orders')}
                  startIcon={<OrdersIcon color={theme.white} size="16px" />}
                  styles={{ marginTop: SPACING[200] }}
                >
                  Gestion commandes
                </Button>
              </>
            ) : null}
            {user.role === 'admin' ? (
              <>
                <Button
                  small
                  primary
                  onClick={() => onClickRedirect('/admin/dashboard')}
                  startIcon={<DashboardIcon color={theme.white} size="16px" />}
                >
                  Admin dashboard
                </Button>
                <Button
                  small
                  primary
                  onClick={() => onClickRedirect('/admin/orders')}
                  startIcon={<OrdersIcon color={theme.white} size="16px" />}
                  styles={{ marginTop: SPACING[200] }}
                >
                  Gestion commandes
                </Button>
              </>
            ) : null}
          </NavDrawerActionsWrapper>
        ) : null}
        <NavDrawerGroup>
          {NAV_LINKS.map(navItem => (
            <NavItem
              key={navItem.name}
              name={t(navItem.name)}
              Icon={navItem.icon}
              path={navItem.path}
              toggleDrawer={toggleDrawer}
              external={navItem.external}
            />
          ))}
        </NavDrawerGroup>
      </MenuWrapper>
      <div style={{ textAlign: 'center', margin: '0 auto', width: '100%' }}>
        <Text muted size={300}>
          v{version}
        </Text>
      </div>
    </Drawer>
  );
};

export default NavDrawer;

import React from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { useUnstated } from '@gitbook/unstated';

import { useSession, useTheme } from '../../../hooks';
import { updateAddresses, deleteAddress } from '../../../actions/users';
import {
  Container,
  Heading,
  Button,
  AddressForm,
  LineProperty,
  HoverableLink,
  NavLink,
} from '../../../styleguide';
import {
  EditIcon,
  DeleteIcon,
  CheckIcon,
  ReturnIcon,
} from '../../../styleguide/icons';
import { Page } from '../../common';
import {
  MainWrapper,
  ContentWrapper,
  DefaultAddressFlag,
  ButtonsWrapper,
} from '../../../styles/adresseslist';
import { BasketContainer } from '../../../contexts';
import { useTranslation } from 'react-i18next';
import { SPACING } from '../../../styles/constants';

const AddressesList = () => {
  let location = useLocation();
  const { t } = useTranslation(['common', 'account']);
  const fromOrder = !!location.state && !!location.state.fromOrder;
  const [editing, setEdition] = React.useState(fromOrder ? {} : null);
  let history = useHistory();
  const { user, authUser } = useSession();
  const theme = useTheme();
  const basketContainer = useUnstated(BasketContainer);
  const { addresses, defaultAddress } = user;
  const isAddressEdition = editing && !!editing.street && !!editing.city;

  const onSubmit = address => {
    const finalAddress = {
      ...address,
      details: address.details || '',
    };
    updateAddresses({
      authID: authUser.uid,
      addresses,
      address: finalAddress,
      isDefaultAddress: address.isDefault || false,
    });

    if (basketContainer.state.selectedAddress) {
      basketContainer.updateSelectedAddress(address);
    }

    setEdition(null);

    if (fromOrder) {
      history.push('/order/new');
    } else {
      history.push('/account/adresses');
    }
  };

  const onDeleteAddress = adress => {
    if (user.defaultAddress === adress.id) {
      const newAdress = addresses.filter(a => a.id !== adress.id)[0];
      basketContainer.updateSelectedAddress(newAdress);
    }

    return deleteAddress(user, addresses, adress);
  };

  const onSelectAdress = adress => {
    basketContainer.updateSelectedAddress(adress);
    history.push('/');
  };

  return (
    <Page
      name="Mes adresses"
      navbarLeftChild={
        <NavLink color={theme.headingColor} to="/account">
          <IconButton aria-label="retour">
            <ReturnIcon color={theme.headingColor} size="16px" />
          </IconButton>
        </NavLink>
      }
    >
      <Container justify="flex-start">
        {!!editing ? (
          <MainWrapper padding>
            <div>
              <Heading
                size={900}
                centered={!!editing}
                styles={{
                  marginBottom: SPACING[100],
                }}
              >
                {isAddressEdition
                  ? t('account:adresses:edit_page_title')
                  : t('account:adresses:new_page_title')}
              </Heading>
              <HoverableLink to="/account" color={theme.primaryColor}>
                {t('common:back_to_profile')}
              </HoverableLink>
            </div>

            <ContentWrapper>
              <AddressForm
                onSubmit={onSubmit}
                initialValues={editing}
                onCancel={() => setEdition(null)}
                defaultAddress={defaultAddress}
              />
            </ContentWrapper>
          </MainWrapper>
        ) : (
          <MainWrapper padding>
            <div>
              <Heading
                size={900}
                centered={false}
                styles={{
                  marginBottom: SPACING[100],
                }}
              >
                {t('account:adresses:list_page_title')}
              </Heading>
              <HoverableLink to="/account" color={theme.primaryColor}>
                {t('common:back_to_profile')}
              </HoverableLink>
            </div>
            <ContentWrapper>
              {addresses.map(item => (
                <LineProperty
                  key={item.id}
                  value={`${item.street} - ${item.city}`}
                  label={
                    <>
                      {item.name}
                      {defaultAddress === item.id && (
                        <>
                          {' - '}
                          <DefaultAddressFlag color={theme.primaryColor}>
                            {t('common:default')}
                          </DefaultAddressFlag>
                        </>
                      )}
                    </>
                  }
                  actions={
                    <>
                      <IconButton
                        aria-label={t('common:delete')}
                        onClick={() => onDeleteAddress(item)}
                      >
                        <DeleteIcon color={theme.red} size="20px" />
                      </IconButton>
                      <IconButton
                        aria-label={t('common:edit')}
                        onClick={() => setEdition(item)}
                      >
                        <EditIcon color={theme.textColor} size="20px" />
                      </IconButton>
                      <IconButton
                        aria-label={t('common:use')}
                        onClick={() => onSelectAdress(item)}
                      >
                        <CheckIcon color={theme.primaryColor} size="20px" />
                      </IconButton>
                    </>
                  }
                />
              ))}
            </ContentWrapper>
            <ButtonsWrapper>
              <Button onClick={() => setEdition({})}>
                {t('account:adresses:add_new')}
              </Button>
            </ButtonsWrapper>
          </MainWrapper>
        )}
      </Container>
    </Page>
  );
};

export default AddressesList;

import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

import { prepareDocForUpdate } from '../helpers/firestoreHelpers';

const updateOpening = data => {
  // console.log('Update app opening', { data });
  ReactGA.event({
    category: 'App',
    action: 'Update app opening',
  });

  try {
    return Firebase.firestore()
      .collection('app')
      .doc(data.docID)
      .update(prepareDocForUpdate(data.value));
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

const updateStrongPresence = data => {
  // console.log('Update app strong presence', { data });
  ReactGA.event({
    category: 'App',
    action: 'Update app strong presence',
  });

  try {
    return Firebase.firestore()
      .collection('app')
      .doc(data.docID)
      .update(prepareDocForUpdate(data.value));
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

export { updateOpening, updateStrongPresence };

import { Duration } from 'luxon';

const getSecondsFromExpiry = (expiry, now) => {
  const milliSecondsDistance = expiry - now;
  if (milliSecondsDistance > 0) {
    return milliSecondsDistance / 1000;
  }
  return 0;
};

const computeFinalPreparationTime = (acceptedAt, readyAt) => {
  const offset = readyAt - acceptedAt;

  const finalDuration = Duration.fromObject({ milliseconds: offset })
    .shiftTo('hours', 'minutes', 'seconds')
    .toObject();

  return finalDuration;
};

const computeFinalDeliveryTime = pickedAt => {
  const now = new Date().getTime();
  const offset = now - pickedAt;
  const stopwatchOffset = new Date();
  stopwatchOffset.setMilliseconds(stopwatchOffset.getMilliseconds() + offset);
  const sec = getSecondsFromExpiry(stopwatchOffset, now);

  const finalDuration = Duration.fromObject({ seconds: sec })
    .shiftTo('hours', 'minutes', 'seconds')
    .toObject();

  return finalDuration;
};

export { computeFinalPreparationTime, computeFinalDeliveryTime };

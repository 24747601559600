import authenticatedRequest from '../helpers/authenticatedRequest';
import { API } from '../../common';

const validateOrderDiscount = async ({ code, shopID, userID }) => {
  const response = await authenticatedRequest(
    'post',
    API.backend.confirmOrderDiscount,
    {
      code,
      shopID,
      userID,
    }
  );

  return response;
};

export default validateOrderDiscount;

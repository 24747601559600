import React from 'react';
import { useTranslation } from 'react-i18next';

import {
  FooterBlock,
  FooterContainer,
  FooterGrid,
  FooterColumn,
  FooterLogo,
} from '../../styles/layout';
import { SPACING } from '../../styles/constants';
import {
  Heading,
  Logo,
  ExternalHoverableLink,
  HoverableLink,
} from '../../styleguide';

const Footer = ({ theme }) => {
  const { t } = useTranslation(['footer']);

  return (
    <FooterBlock theme={theme}>
      <FooterContainer theme={theme}>
        <FooterGrid>
          <FooterColumn first>
            <FooterLogo>
              <Logo width={48} height={34} light />
              <Heading size={800} color={theme.white}>
                Delyzon
              </Heading>
            </FooterLogo>
          </FooterColumn>
          <FooterColumn>
            <Heading
              size={600}
              color={theme.white}
              styles={{ marginBottom: SPACING[200] }}
            >
              {t('about')}
            </Heading>
            <ExternalHoverableLink
              href="https://www.delyzon.com"
              wrapperStyles={{ paddingBottom: SPACING[100] }}
            >
              {t('more_infos')}
            </ExternalHoverableLink>
            <ExternalHoverableLink
              href="https://www.delyzon.com/mentions-legales"
              wrapperStyles={{ paddingBottom: SPACING[100] }}
            >
              {t('mentions')}
            </ExternalHoverableLink>
            <ExternalHoverableLink
              href="https://www.delyzon.com/cgpsu"
              wrapperStyles={{ paddingBottom: SPACING[100] }}
            >
              {t('cgpsu')}
            </ExternalHoverableLink>
            <ExternalHoverableLink
              href="https://www.instagram.com/delyzonfr/"
              wrapperStyles={{ paddingBottom: SPACING[100] }}
            >
              {t('instagram')}
            </ExternalHoverableLink>
            <ExternalHoverableLink
              href="https://www.facebook.com/delyzonfr"
              wrapperStyles={{ paddingBottom: SPACING[100] }}
            >
              {t('facebook')}
            </ExternalHoverableLink>
          </FooterColumn>
          <FooterColumn>
            <Heading
              size={600}
              color={theme.white}
              styles={{ marginBottom: SPACING[200] }}
            >
              {t('shops')}
            </Heading>
            <HoverableLink
              wrapperStyles={{
                paddingBottom: SPACING[100],
                textDecoration: 'none',
              }}
              to="/"
            >
              {t('all_shops')}
            </HoverableLink>
          </FooterColumn>
          <FooterColumn>
            <Heading
              size={600}
              color={theme.white}
              styles={{ marginBottom: SPACING[200] }}
            >
              {t('help')}
            </Heading>
            <ExternalHoverableLink
              href="https://m.me/108163771705869"
              wrapperStyles={{ paddingBottom: SPACING[100] }}
            >
              {t('need_help')}
            </ExternalHoverableLink>
            <ExternalHoverableLink
              href="https://www.delyzon.com/faq"
              wrapperStyles={{ paddingBottom: SPACING[100] }}
            >
              {t('faq')}
            </ExternalHoverableLink>
            <ExternalHoverableLink
              href="mailto:contact@delyzon.com"
              wrapperStyles={{ paddingBottom: SPACING[100] }}
              withoutTarget
            >
              {t('contact_us')}
            </ExternalHoverableLink>
          </FooterColumn>
        </FooterGrid>
      </FooterContainer>
    </FooterBlock>
  );
};

export default Footer;

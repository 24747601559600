const SPACING = {
  100: '8px',
  200: '16px',
  300: '24px',
  400: '32px',
  500: '40px',
  600: '48px',
  700: '56px',
  800: '64px',
  900: '72px',
  1000: '80px',
};

export default SPACING;

import * as yup from 'yup';
import parsePhoneNumber from 'libphonenumber-js';

const EMAIL_FIELD_VALIDATION = yup
  .string('Votre email')
  .email('Veuillez entrer un email valide')
  .required('Votre email est requis');

const PWD_FIELD_VALIDATION = yup
  .string('Mot de passe')
  .min(8, 'Votre mot de passe doit faire au contenir 8 caractères au minimum')
  .required('Mot de passe requis');

const PHONE_FIELD_VALIDATION = yup
  .string('Numéro de téléphone')
  .test(
    'phone',
    "Le numéro de téléphone saisi est invalide, si le numéro n'est pas français ajoutez votre préfixe international.",
    value => {
      if (value !== undefined) {
        return parsePhoneNumber(value, 'FR')?.isValid() || false;
      }
      return false;
    }
  );

const NAME_FIELD_VALIDATION = yup
  .string('Vos nom et prénom')
  .required('Vos nom et prénom sont requis');

const IDENTITY = {
  EMAIL_FIELD_VALIDATION,
  PWD_FIELD_VALIDATION,
  PHONE_FIELD_VALIDATION,
  NAME_FIELD_VALIDATION,
};

export {
  IDENTITY,
  EMAIL_FIELD_VALIDATION,
  PWD_FIELD_VALIDATION,
  PHONE_FIELD_VALIDATION,
  NAME_FIELD_VALIDATION,
};

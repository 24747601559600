import React from 'react';
import { RotateSpinner } from 'react-spinners-kit';
import styled from 'styled-components';

import { colors } from '../styles/constants';

const LoaderWrapper = styled.div`
  width: 100vw;
  height: 100vh;
  position: absolute;
  z-index: 100;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${props => props.background};
  color: ${props => props.color};
`;

const Loader = props => {
  const { color, background } = props;

  return (
    <LoaderWrapper
      color={color || colors.white}
      background={background || colors.green}
    >
      <RotateSpinner size={42} color={color || colors.white} loading />
    </LoaderWrapper>
  );
};

export default Loader;

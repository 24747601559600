import computePreparationTime from './helpers/computePreparationTime';
import createID from '../helpers/createID';
import { computeDeliveryFees } from './helpers/computeWithDiscount';
import { cancelPaymentIntent } from '../stripe';
import {
  confirmDiscount,
  handlePayment,
  createOrder,
} from './createOrderMethods';

const onCreateOrder = async ({
  selectedAddress,
  selectedPaymentMethod,
  deliveryFees,
  appFees,
  duration,
  distance,
  user,
  shop,
  total,
  itemsList,
  onFinishCb,
  onErrorCb,
  stripe,
  message,
  discount,
}) => {
  const IdForOrder = createID();
  let paymentResult;

  if (!!discount) {
    const { status } = await confirmDiscount({
      code: discount.code,
      shopID: shop.id,
      userID: user.id,
    });

    if (status === 'discount_expired') {
      onErrorCb({
        code: status,
      });
      return;
    }
  }

  const deliveryFeesWithDiscount = computeDeliveryFees(discount, deliveryFees);

  try {
    paymentResult = await handlePayment({
      orderID: IdForOrder,
      paymentMethodID: selectedPaymentMethod.id,
      stripeCustomerID: user.stripeCustomerId,
      shop,
      total,
      appFees,
      deliveryFeesWithDiscount,
      stripe,
    });

    if (!!paymentResult.error) {
      onErrorCb(paymentResult.error);
      return;
    }
  } catch (error) {
    onErrorCb(error);
    return;
  }

  if (paymentResult.paymentIntent.status === 'requires_capture') {
    try {
      const { status, orderID } = await createOrder({
        orderID: IdForOrder,
        shop,
        user,
        selectedAddress,
        paymentIntentId: paymentResult.paymentIntent.id,
        duration,
        estimatedTimeToDeliver: computePreparationTime(itemsList),
        deliveryFees: deliveryFeesWithDiscount,
        distance,
        appFees,
        total,
        itemsList,
        paymentMethod: selectedPaymentMethod,
        orderAt: Date.now(),
        message,
        discount,
      });

      if (status === 'success') {
        onFinishCb(orderID);
        return;
      } else {
        await cancelPaymentIntent({
          paymentIntentID: paymentResult.paymentIntent.id,
        });
        onErrorCb(
          "Une erreur s'est produite lors de la création de votre commande. Veuillez réessayer, si le problème persiste, contactez notre équipe."
        );
        return;
      }
    } catch (error) {
      await cancelPaymentIntent({
        paymentIntentID: paymentResult.paymentIntent.id,
      });
      onErrorCb(error);
    }
  } else {
    // Normally, this should not happen
    await cancelPaymentIntent({
      paymentIntentID: paymentResult.paymentIntent.id,
    });
    onErrorCb(
      "Une erreur s'est produite lors de la création de votre commande. Veuillez réessayer, si le problème persiste, contactez notre équipe."
    );
  }
};

export default onCreateOrder;

import * as yup from 'yup';

const NAME_SHOP_FIELD_VALIDATION = yup.string('Nom établissement').required();

const DESCRIPTION_SHOP_FIELD_VALIDATION = yup
  .string('Description')
  .min(
    20,
    'La description doit faire au moins 1 ligne. Ne la négligez pas elle vous permettra de vous demarquer 😉'
  )
  .required('La description est indispensable pour vous demarquer !');

const LOGO_SHOP_FIELD_VALIDATION = yup
  .string('LogoURL')
  .url('Vous devez fournir le lien de votre logo !')
  .required('Le lien de votre logo est requis !');

const SHOP = {
  NAME_SHOP_FIELD_VALIDATION,
  DESCRIPTION_SHOP_FIELD_VALIDATION,
  LOGO_SHOP_FIELD_VALIDATION,
};

export { SHOP };

import React from 'react';

import { Heading, LineProperty, PropertyEdit } from '../../../../styleguide';
import {
  TabContentWrapper,
  SettingsContentWrapper,
  FlexFieldsWrapper,
} from '../../../../styles/shop';
import { SPACING } from '../../../../styles/constants';
import { updateShop } from '../../../../actions/shop';
import TabPanel from '../TabPanel';

const SettingsTab = props => {
  const { value, shop } = props;
  const [edition, setEdition] = React.useState(null);
  const { bannerURL, category, description, logoURL, name } = shop;

  const initialValue = shop[edition];
  const smLinePropertyStyles = {
    minWidth: 300,
    maxWidth: 500,
    padding: `0 ${SPACING[100]}`,
  };

  const linePropertyStyles = {
    minWidth: 300,
    maxWidth: 700,
    padding: `0 ${SPACING[100]}`,
  };

  const onUpdate = val => {
    const updatedShop = {
      ...shop,
      ...val,
    };
    updateShop(updatedShop);
  };

  return (
    <TabPanel value={value} index={2}>
      <TabContentWrapper>
        <Heading size={800}>Paramétres</Heading>

        <SettingsContentWrapper>
          {!edition ? (
            <>
              <FlexFieldsWrapper>
                <LineProperty
                  value={bannerURL}
                  label="Photo de couverture"
                  onClick={() => setEdition('bannerURL')}
                  isBanner
                />
              </FlexFieldsWrapper>

              <FlexFieldsWrapper justify="space-between">
                <LineProperty
                  value={logoURL}
                  label="Logo"
                  onClick={() => setEdition('logoURL')}
                  styles={smLinePropertyStyles}
                  isLogo
                  buttonContent="Changer"
                />
                <LineProperty
                  value={name}
                  label="Nom de l'établissement"
                  onClick={() => setEdition('name')}
                  styles={linePropertyStyles}
                />
              </FlexFieldsWrapper>

              <FlexFieldsWrapper justify="space-between">
                <LineProperty
                  value={category}
                  label="Type d'établisement"
                  onClick={() => setEdition('category')}
                  styles={smLinePropertyStyles}
                />
                <LineProperty
                  value={description}
                  label="Description de l'établissement"
                  onClick={() => setEdition('description')}
                  styles={linePropertyStyles}
                />
              </FlexFieldsWrapper>
            </>
          ) : (
            <PropertyEdit
              property={edition}
              setEdition={setEdition}
              initialValue={initialValue}
              onUpdate={onUpdate}
            />
          )}
        </SettingsContentWrapper>
      </TabContentWrapper>
    </TabPanel>
  );
};

export default SettingsTab;

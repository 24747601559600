import React from 'react';
import { subtract } from 'lodash';
import { DateTime } from 'luxon';
import {
  Box,
  Collapse,
  IconButton,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableBody,
  TableRow,
  Paper,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import percentage from '../../../actions/orders/helpers/percentage';
import { Heading } from '../../../styleguide';
import { InvoiceContentTableWrapper } from '../../../styles/invoiceTemplate';
import { ExpandIcon } from '../../../styleguide/icons';
import { getInitialDeliveryFees } from '../../../common/fees';
import { parseNumber } from '../../../common';

const formatDate = ts => {
  return DateTime.fromMillis(ts).toLocaleString(DateTime.DATETIME_MED);
};

const createData = (category, rusher) => {
  return {
    quantity: category.orders.length,
    name: category.name,
    unitCost: category.cost,
    orders: category.orders,
    total: category.total,
    commission: percentage(rusher.percentage, category.total),
    revenu: subtract(
      category.total,
      percentage(rusher.percentage, category.total)
    ),
  };
};

const useRowStyles = makeStyles({
  root: {
    '& > *': {
      borderBottom: 'unset',
    },
  },
});

function Row(props) {
  const { row, rusher } = props;
  const [open, setOpen] = React.useState(false);
  const classes = useRowStyles();

  return (
    <React.Fragment>
      <TableRow>
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <ExpandIcon return /> : <ExpandIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row" align="left" size="small">
          {row.quantity}
        </TableCell>
        <TableCell align="center">{row.name}</TableCell>
        <TableCell align="right" size="small">
          {parseNumber(row.unitCost).toFixed(2)}€
        </TableCell>
        <TableCell align="right" size="small">
          {parseNumber(row.total).toFixed(2)}€
        </TableCell>
        <TableCell align="right" size="small">
          {parseNumber(row.commission).toFixed(2)}€
        </TableCell>
        <TableCell align="right" size="small">
          {parseNumber(row.revenu).toFixed(2)}€
        </TableCell>
      </TableRow>
      <TableRow className={classes.root}>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box margin={1}>
              <Heading size={500}>Détails</Heading>
              <InvoiceContentTableWrapper>
                <Table
                  size="small"
                  aria-label="commandes"
                  style={{ width: '100%' }}
                >
                  <TableHead>
                    <TableRow>
                      <TableCell>ID</TableCell>
                      <TableCell>Date</TableCell>
                      <TableCell align="right">Montant</TableCell>
                      <TableCell align="right">Retenue</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {row.orders.map(order => {
                      const deliveryFees = order.discount
                        ? getInitialDeliveryFees(order.distance)
                        : order.deliveryFees;
                      return (
                        <TableRow key={order.id}>
                          <TableCell component="th" scope="row">
                            {order.id}
                          </TableCell>
                          <TableCell>{formatDate(order.orderAt)}</TableCell>
                          <TableCell align="right">{deliveryFees}€</TableCell>
                          <TableCell align="right">
                            {percentage(rusher.percentage, deliveryFees)}€
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  </TableBody>
                </Table>
              </InvoiceContentTableWrapper>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

const InvoiceResumeTable = props => {
  const { rusher, classifiedOrders } = props;

  const rows = Object.keys(classifiedOrders).map(categoryKey =>
    createData(classifiedOrders[categoryKey], rusher)
  );

  return (
    <TableContainer component={Paper}>
      <InvoiceContentTableWrapper>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow>
              <TableCell />
              <TableCell>Quantité</TableCell>
              <TableCell align="right">Dénomination</TableCell>
              <TableCell align="right">Coût unitaire</TableCell>
              <TableCell align="right">Total&nbsp;HT</TableCell>
              <TableCell align="right">Retenue</TableCell>
              <TableCell align="right">Total&nbsp;Net</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {rows.map(row => (
              <Row key={row.name} row={row} rusher={rusher} />
            ))}
          </TableBody>
        </Table>
      </InvoiceContentTableWrapper>
    </TableContainer>
  );
};

export default InvoiceResumeTable;

import styled from 'styled-components';
import { isMobile } from 'react-device-detect';

import { SPACING } from './constants';

const ShopOrdersHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${isMobile ? 'center' : 'space-between'};
  align-items: center;
  padding: ${SPACING[100]} 0 ${SPACING[500]} 0;
`;

export { ShopOrdersHeaderWrapper };

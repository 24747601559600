import ReactGA from 'react-ga4';

import authenticatedRequest from '../helpers/authenticatedRequest';
import { API } from '../../common';

const deletePaymentMethod = async (userID, paymentMethodID) => {
  // console.log('Delete Payment Method called', { paymentMethodID, userID });
  ReactGA.event({
    category: 'Stripe',
    action: 'Delete payment method',
  });

  try {
    await authenticatedRequest('post', API.stripe.deletePaymentMethod, {
      userID,
      paymentMethodID,
    });
  } catch (error) {
    console.error('Error during PM deletion', { error });
    return {
      error: {
        message: error?.response?.data?.message,
      },
    };
  }
  // console.log('Deletion ended, success');
  return true;
};

export default deletePaymentMethod;

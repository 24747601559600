import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { FormControlLabel } from '@material-ui/core';
import { useFormik } from 'formik';
import { useTranslation } from 'react-i18next';

import Button from '../button';
import { Input } from './Input';
import SearchLocationInput from './SearchLocationInput';
import CheckBox from './CheckBox';
import { FormWrapper, ButtonsWrapper } from '../../styles/form';
import { addressSchema } from '../../forms';

const SUPPORTED_ZIP_CODE = ['74400', '74310', '74190', '74170', '74700'];
const BLACKLISTED_CITIES = [
  'cordon',
  'contamines montjoie',
  'contamines',
  // Not possisble because it's a part of Saint-Gervais les bains,
  // so it's the same postal code and city
  // 'saint-nicolas de véroce',
  // 'saint-nicolas',
];

const isValidAddress = (zipCode, city) => {
  const cleanedCity = city.trim().toLowerCase();
  const blacklistedCity = BLACKLISTED_CITIES.reduce((acc, c) => {
    if (!!cleanedCity.includes(c)) {
      acc = true;
    }
    return acc;
  }, false);

  return SUPPORTED_ZIP_CODE.includes(zipCode) && !blacklistedCity;
};

const AddressForm = props => {
  const {
    onSubmit,
    initialValues,
    onCancel,
    lite,
    isLoading,
    defaultAddress,
  } = props;
  const [error, setError] = React.useState(null);
  const { t } = useTranslation(['signup', 'common']);

  const initialData = {
    isDefault:
      !!defaultAddress && !!initialValues.id
        ? defaultAddress === initialValues.id
        : true,
    street: initialValues ? initialValues.street : '',
    city: initialValues ? initialValues.city : '',
    details: initialValues ? initialValues.details || '' : '',
    postalCode: initialValues ? initialValues.postalCode : '',
    name: initialValues ? initialValues.name || '' : '',
    id: initialValues ? initialValues.id : null,
  };

  const formik = useFormik({
    initialValues: { ...initialData, name: lite || initialData.name },
    validationSchema: addressSchema,
    onChange: () => {
      if (error) {
        setError(null);
      }
    },
    onSubmit: values => {
      if (!isValidAddress(values.postalCode, values.city)) {
        setError(getError());
      } else {
        const finalAddress = {
          ...values,
          name: !!values.name ? values.name : 'Adresse',
          isDefault: !!values.isDefault ? values.isDefault : true,
        };
        onSubmit(finalAddress);
      }
    },
  });

  const canSubmit =
    !!formik.values.street &&
    !!formik.values.city &&
    !!formik.values.postalCode &&
    !!formik.values.id &&
    !error;

  const getError = () => {
    return {
      title: t('signup:addresse:invalid_address'),
      message: t('signup:addresse:invalid_address_error'),
      id: 'submit',
    };
  };

  const onSelectAddress = ({ street, city, zipCode, place_id }) => {
    if (!isValidAddress(zipCode, city) && !error) {
      setError(getError());
    }

    formik.setValues({
      ...formik.values,
      street,
      city,
      postalCode: zipCode,
      id: place_id,
    });
  };

  const onChecked = value => {
    if (error) {
      setError(null);
    }
    formik.setValues({ ...formik.values, isDefault: value });
  };

  const onFocusAndBlur = () => {
    if (error) {
      setError(null);
    }
  };

  return (
    <>
      {!!error && (
        <Alert severity="warning" style={{ marginTop: '8px' }}>
          <AlertTitle>{error.title}</AlertTitle>
          {error.message}
        </Alert>
      )}

      <FormWrapper
        onSubmit={formik.handleSubmit}
        margin={`0 auto`}
        paddingV={0}
      >
        <SearchLocationInput
          initialValue={
            !!formik.values.id
              ? `${formik.values.street}, ${formik.values.city}, France`
              : ''
          }
          onSelectHandler={onSelectAddress}
          error={error}
          onFocus={onFocusAndBlur}
          onBlur={onFocusAndBlur}
        />

        {!lite ? (
          <Input
            name="details"
            label={t('signup:addresse:details')}
            fullWidth
            multiline
            rows={4}
            size="small"
            variant="outlined"
            placeholder={t('signup:addresse:details_placeholder')}
            value={formik.values.details}
            onChange={formik.handleChange}
            error={formik.touched.details && Boolean(formik.errors.details)}
            helperText={formik.touched.details && formik.errors.details}
          />
        ) : null}

        {!lite ? (
          <Input
            name="name"
            label={t('signup:addresse:name')}
            size="small"
            variant="outlined"
            placeholder={t('signup:addresse:name_placeholder')}
            fullWidth
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        ) : null}

        {!!onCancel && (
          <FormControlLabel
            control={
              <CheckBox
                name="isDefault"
                checked={formik.values.isDefault}
                onChange={isChecked => onChecked(isChecked)}
                error={
                  formik.touched.isDefault && Boolean(formik.errors.isDefault)
                }
                helperText={formik.touched.isDefault && formik.errors.isDefault}
              />
            }
            label={t('signup:addresse:default_label')}
          />
        )}

        <ButtonsWrapper>
          {!!onCancel && (
            <Button minimal onClick={onCancel}>
              {t('common:cancel')}
            </Button>
          )}
          <Button type="submit" disabled={!canSubmit || isLoading}>
            {t('common:confirm')}
          </Button>
        </ButtonsWrapper>
      </FormWrapper>
    </>
  );
};

export default AddressForm;

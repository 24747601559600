import React from 'react';
import { IconButton } from '@material-ui/core';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useLocation, useHistory } from 'react-router-dom';
import { useUnstated } from '@gitbook/unstated';
import Firebase from 'firebase/app';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../hooks';
import { BasketContainer } from '../../contexts';
import { NavLink, Container, Button } from '../../styleguide';
import { Page, EmptyPage } from '../common';
import { CloseIcon } from '../../styleguide/icons';
import {
  ContentWrapper,
  ButtonsWrapper,
  MainWrapper,
} from '../../styles/basket';
import ProductLine from './ProductLine';
import MenuLine from './MenuLine';
import Totals from './Totals';
import Header from './Header';

const { REACT_APP_SETTINGS_DOC_ID } = process.env;
const DELIVERY_FEE_START = 4;

const Basket = props => {
  const theme = useTheme();
  let location = useLocation();
  let history = useHistory();
  const basketContainer = useUnstated(BasketContainer);
  const { t } = useTranslation(['basket', 'common']);

  const isEmpty = basketContainer.isEmpty;
  const itemsCount = basketContainer.itemsCount;
  const { total, itemsList, shop } = basketContainer.state;
  const to =
    location.state && location.state.from.pathname !== '/basket'
      ? location.state.from
      : { pathname: '/' };
  const shopID = !!shop ? shop.id : '';

  const [shopValue, shopValueLoading, shopValueError] = useCollection(
    Firebase.firestore()
      .collection('shops')
      .where(`id`, '==', shopID),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [settings, settingsLoading, settingsError] = useDocumentData(
    Firebase.firestore().doc(`app/${REACT_APP_SETTINGS_DOC_ID}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (settingsError || shopValueError) {
    if (settingsError) {
      throw new Error(settingsError.toString());
    } else if (shopValueError) {
      throw new Error(shopValueError.toString());
    }
  }

  const currentShop =
    !shopValueLoading && !!shopValue.docs[0] ? shopValue.docs[0].data() : null;
  const currentShopIsAvailable = !currentShop ? false : currentShop.available;

  const canOrder = !!settings && settings.open;

  return (
    <Page
      navbarLeftChild="disabled"
      navbarRightChild={
        <NavLink color={theme.headingColor} to={to}>
          <IconButton aria-label="retour">
            <CloseIcon color={theme.headingColor} size="16px" />
          </IconButton>
        </NavLink>
      }
      name="Mon Panier"
      withoutWidget
    >
      <Container>
        {isEmpty ? (
          <EmptyPage
            title={t('empty.title')}
            description={t('empty.description')}
          />
        ) : (
          <MainWrapper padding>
            <Header
              itemsCount={itemsCount}
              shop={shop}
              to={to}
              title={t('basket:page:title')}
            />

            {!settingsLoading && !shopValueLoading ? (
              <>
                {!currentShopIsAvailable && (
                  <Alert severity="warning" style={{ margin: '8px 0px' }}>
                    <AlertTitle>
                      {`${t('common:shop_closed:title')}`}
                      <span role="img" aria-label="smiley-cry">
                        😢
                      </span>
                    </AlertTitle>
                    {`${t('common:shop_closed:message')}`}
                  </Alert>
                )}

                {!!currentShop && currentShop.strongPresence && canOrder ? (
                  <Alert severity="warning" style={{ margin: '8px 0px' }}>
                    <AlertTitle>
                      {`${t('common:shop_strong_presence_alert:title')}`} {`💥`}
                    </AlertTitle>
                    {`${t('common:shop_strong_presence_alert:message')}`}
                  </Alert>
                ) : null}

                {!!settings && settings.strongPresence ? (
                  <Alert severity="warning" style={{ margin: '8px 0px' }}>
                    <AlertTitle>
                      {`${t('common:strong_presence_alert:title')}`} {`💥`}
                    </AlertTitle>
                    {`${t('common:strong_presence_alert:message')}`}
                  </Alert>
                ) : null}

                {!canOrder && (
                  <Alert severity="error" style={{ margin: '8px 0px' }}>
                    <AlertTitle>
                      {`${t('common:opening_alert:title')}`}{' '}
                      <span role="img" aria-label="smiley-closed">
                        ❌
                      </span>
                    </AlertTitle>
                    {`${t('common:opening_alert:title')}`}
                  </Alert>
                )}
              </>
            ) : null}

            <ContentWrapper>
              {itemsList.map((shopItem, index) =>
                shopItem.item.isMenu ? (
                  <MenuLine
                    key={index}
                    shopItem={shopItem}
                    onDelete={() => basketContainer.deleteItem(shopItem)}
                  />
                ) : (
                  <ProductLine
                    key={index}
                    shopItem={shopItem}
                    onDelete={() => basketContainer.deleteItem(shopItem)}
                    small
                  />
                )
              )}

              <Totals total={total} deliveryFees={DELIVERY_FEE_START} />
            </ContentWrapper>
            <ButtonsWrapper>
              <Button
                onClick={() => history.push('/order/new', { from: to })}
                isLoading={shopValueLoading || settingsLoading}
                disabled={
                  shopValueLoading ||
                  settingsLoading ||
                  !currentShopIsAvailable ||
                  !canOrder
                }
              >
                {t('basket:page:order_button')}
              </Button>
              <Button onClick={() => history.push(`/shop/${shop.id}`)} minimal>
                {t('basket:page:continue')}
              </Button>
            </ButtonsWrapper>
          </MainWrapper>
        )}
      </Container>
    </Page>
  );
};

export default Basket;

import React from 'react';
import { Chip } from '@material-ui/core';
import { subtract, add } from 'lodash';

import { CheckBox, Text } from '../../../../styleguide';
import { useTheme } from '../../../../hooks';
import { SPACING } from '../../../../styles/constants';
import { ShopProfilDialogSupplementLine } from '../../../../styles/shop-profil';
import { parseNumber } from '../../../../common';

const SupplementsDialogContent = props => {
  const { selectedItem, setSelectedItem, supplement, ...rest } = props;
  const theme = useTheme();

  const onAddSupplement = (supplement, isAdding) => {
    if (!isAdding) {
      setSelectedItem({
        ...selectedItem,
        item: selectedItem.item,
        supplements: selectedItem.supplements.filter(
          i => i.id !== supplement.id
        ),
        total: parseNumber(
          subtract(
            parseNumber(selectedItem.total),
            parseNumber(supplement.price)
          )
        ),
      });
    } else {
      setSelectedItem({
        ...selectedItem,
        item: selectedItem.item,
        supplements: [...selectedItem.supplements, supplement],
        total: parseNumber(
          add(parseNumber(selectedItem.total), parseNumber(supplement.price))
        ),
      });
    }
  };

  return (
    <ShopProfilDialogSupplementLine {...rest}>
      <CheckBox
        checked={selectedItem.supplements.includes(supplement)}
        onChange={isChecked => onAddSupplement(supplement, isChecked)}
        name={supplement.name}
        wrapperProps={{
          justify: 'flex-start',
          align: 'center',
          padding: `${SPACING[100]} ${SPACING[100]} 0 ${SPACING[100]}`,
        }}
        disabled={!supplement.avaibility}
        label={
          <Text
            size={400}
            color={
              !supplement.avaibility ? theme.lightGray : theme.headingColor
            }
          >
            {supplement.name} {!supplement.avaibility && ' (indisponible)'}
          </Text>
        }
      />

      <Chip
        size="small"
        label={`${parseNumber(supplement.price).toFixed(2)}€`}
      />
    </ShopProfilDialogSupplementLine>
  );
};

export default SupplementsDialogContent;

import ReactGA from 'react-ga4';

import authenticatedRequest from '../helpers/authenticatedRequest';
import { API } from '../../common';

const capturePaymentIntent = async ({ orderID }) => {
  // console.log('Capture Intent Method called', {
  //   paymentIntentID,
  // });
  ReactGA.event({
    category: 'Stripe',
    action: 'Capture payment intent',
  });

  try {
    await authenticatedRequest('post', `${API.stripe.capturePaymentIntent}`, {
      orderID,
    });
  } catch (error) {
    console.error('Error during Payment intent capture', {
      error: error,
      payment_intent_status:
        error?.response?.data?.error?.payment_intent?.status,
    });
    return {
      error: error,
      payment_intent_status:
        error?.response?.data?.error?.payment_intent?.status,
    };
  }
};

export default capturePaymentIntent;

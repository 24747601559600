import React from 'react';
import {
  IconButton,
  MenuList,
  MenuItem,
  Divider,
  ClickAwayListener,
  Grow,
  Paper,
  Popper,
} from '@material-ui/core';
import { MoreVertIcon } from '../../../../styleguide/icons';
import { Text } from '../../../../styleguide';
import { toaster } from 'evergreen-ui';
import { SPACING } from '../../../../styles/constants';
import { createShopItem, deleteShopItem } from '../../../../actions/shopItems';
import {
  DeleteIcon,
  DuplicateIcon,
  CopyIcon,
  AvailabilityIcon,
} from '../../../../styleguide/icons';
import { useTheme } from '../../../../hooks';

const ItemSettingsMenu = props => {
  const {
    item,
    onChangeAvaibility,
    setConfirm,
    shop,
    onCopyOptions,
    onCopySupplements,
  } = props;
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);

  const handleToggle = () => {
    setOpen(prevOpen => !prevOpen);
  };

  const handleListKeyDown = event => {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  };

  const handleAction = action => {
    action();
    setOpen(false);
  };

  const duplicateShopItem = async item => {
    try {
      const newItem = { ...item, name: `${item.name} (copie)` };
      delete newItem.createdAt;
      delete newItem.updatedAt;
      delete newItem.id;
      delete newItem.isInWeeklySelection;
      delete newItem.shopIsPartner;

      await createShopItem(newItem, shop);
      toaster.success(`L'item ${item.name} est dupliqué`);
      setConfirm(null);
    } catch (error) {
      toaster.danger(error.message);
      throw new Error(error);
    }
  };

  const duplicateItem = (item, isConfirm) => {
    if (isConfirm) {
      return duplicateShopItem(item);
    } else {
      setConfirm({
        title: "Dupliquer l'article",
        description: `Voulez-vous vraiment dupliquer l'article ${item.name} ?`,
        onConfirm: () => {
          duplicateItem(item, true);
        },
      });
    }
  };

  const deleteItem = (item, isConfirm) => {
    if (isConfirm) {
      deleteShopItem(item.id).then(() => {
        toaster.success(`L'item ${item.name} à était supprimé`);
      });
      setConfirm(null);
    } else {
      setConfirm({
        title: `Etes vous sur de vouloir supprimer ${item.name} ?`,
        description:
          'La suppression est definitive, vous ne pourrez pas annuler.',
        onConfirm: () => {
          deleteItem(item, true);
        },
      });
    }
  };

  return (
    <>
      <IconButton
        ref={anchorRef}
        aria-label="more"
        aria-controls="item-settings-menu"
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <MoreVertIcon />
      </IconButton>
      <Popper
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        style={{ zIndex: 10 }}
        placement={'top-end'}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps}>
            <Paper>
              <ClickAwayListener onClickAway={() => setOpen(false)}>
                <MenuList
                  autoFocusItem={open}
                  id="menu-list-grow"
                  onKeyDown={handleListKeyDown}
                >
                  <MenuItem
                    selected={false}
                    onClick={() => handleAction(() => onChangeAvaibility(item))}
                    style={{
                      padding: `${SPACING[100]} ${SPACING[200]}`,
                    }}
                  >
                    <AvailabilityIcon
                      size="1.2rem"
                      color={!item.avaibility ? theme.primaryColor : theme.red}
                      style={{ marginRight: SPACING[100] }}
                    />
                    <Text
                      size={400}
                      color={!item.avaibility ? theme.primaryColor : theme.red}
                    >
                      {!!item.avaibility
                        ? 'Rendre indisponible'
                        : 'Rendre disponible'}
                    </Text>
                  </MenuItem>
                  <MenuItem
                    selected={false}
                    onClick={() => handleAction(() => duplicateItem(item))}
                    style={{
                      padding: `${SPACING[100]} ${SPACING[200]}`,
                    }}
                  >
                    <DuplicateIcon
                      size="1.1rem"
                      color={theme.textColor}
                      style={{ marginRight: 9 }}
                    />
                    <Text size={400} muted>
                      Dupliquer le produit
                    </Text>
                  </MenuItem>
                  {!item.isMenu && [
                    <MenuItem
                      key="cp-options"
                      selected={false}
                      onClick={() => handleAction(() => onCopyOptions())}
                      style={{
                        padding: `${SPACING[100]} ${SPACING[200]}`,
                      }}
                      disabled={!item?.options?.length}
                    >
                      <CopyIcon
                        size="1.2rem"
                        color={theme.textColor}
                        style={{ marginRight: SPACING[100] }}
                      />
                      <Text size={400} muted>
                        Copier les options
                      </Text>
                    </MenuItem>,
                    <MenuItem
                      key="cp-supplements"
                      selected={false}
                      onClick={() => handleAction(() => onCopySupplements())}
                      style={{
                        padding: `${SPACING[100]} ${SPACING[200]}`,
                      }}
                      disabled={!item?.supplements?.length}
                    >
                      <CopyIcon
                        size="1.2rem"
                        color={theme.textColor}
                        style={{ marginRight: SPACING[100] }}
                      />
                      <Text size={400} muted>
                        Copier les suppléments
                      </Text>
                    </MenuItem>,
                  ]}
                  <Divider style={{ margin: `${SPACING[100]} 0` }} />
                  <MenuItem
                    selected={false}
                    onClick={() => handleAction(() => deleteItem(item))}
                    style={{
                      padding: `${SPACING[100]} ${SPACING[200]}`,
                    }}
                  >
                    <DeleteIcon
                      size="1.3rem"
                      color={theme.red}
                      style={{ marginRight: 7 }}
                    />
                    <Text size={400} color={theme.red}>
                      Supprimer
                    </Text>
                  </MenuItem>
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </>
  );
};

export default ItemSettingsMenu;

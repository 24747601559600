import React from 'react';
import styled from 'styled-components';

import { Button } from '../../../../styleguide';
import { useTheme } from '../../../../hooks';
import { SPACING } from '../../../../styles/constants';
import { DashboardIcon } from '../../../../styleguide/icons';

const EmptyButtonWrapper = styled.div`
  margin: ${SPACING[200]};
`;

const ReorderButton = props => {
  const { onClick, label, minimal, ...rest } = props;
  const theme = useTheme();

  return (
    <EmptyButtonWrapper>
      <Button
        minimal={minimal}
        small
        primary
        onClick={onClick}
        startIcon={<DashboardIcon color={theme.white} size="24px" />}
        {...rest}
      >
        {label}
      </Button>
    </EmptyButtonWrapper>
  );
};

export default ReorderButton;

import React from 'react';
import { Divider } from '@material-ui/core';

import {
  ProductLine,
  MenuLine,
  Wrapper,
  Header,
  NameWrapper,
  ItemsWrapper,
} from './commons';
import { Text, Heading } from '../../../../styleguide';
import computePreparationTime from '../../../../actions/orders/helpers/computePreparationTime';

const KitchenReceipt = React.forwardRef((props, ref) => {
  const { order, orderAt, estimatedDeliveryTime } = props;

  if (!order) {
    return <Wrapper ref={ref}></Wrapper>;
  }

  const name = !!order.name ? order.name : 'Nom du client';
  const preparationTime =
    order.estimatedTimeToDeliver || computePreparationTime(order.itemsList);

  return (
    <Wrapper ref={ref}>
      <Header>
        <Heading size={700} styles={{ color: 'black' }}>
          Delyzon
        </Heading>
      </Header>
      <NameWrapper>
        <Heading size={800} styles={{ color: 'white' }}>
          {name}
        </Heading>
      </NameWrapper>
      <Text size={600} styles={{ marginLeft: 8, color: 'black' }}>
        Commandé le: <b>{orderAt}</b>
      </Text>
      <Text size={600} styles={{ marginLeft: 8, color: 'black' }}>
        Temps préparation éstimé: <b>{preparationTime}mn</b>
      </Text>
      <Text size={600} styles={{ marginLeft: 8, color: 'black' }}>
        Heure livraison éstimé: <b>{estimatedDeliveryTime}</b>
      </Text>
      <Text size={600} styles={{ marginLeft: 8, color: 'black' }}>
        Numéro: <b>{order.id}</b>
      </Text>
      <Divider style={{ width: '100%', margin: 8 }} />
      <Heading size={700} styles={{ margin: 0, marginLeft: 8, color: 'black' }}>
        Panier
      </Heading>
      <ItemsWrapper>
        {order.itemsList.map((shopItem, index) =>
          shopItem.item.isMenu ? (
            <MenuLine
              key={`${index}-${shopItem.item.name}`}
              shopItem={shopItem}
              lg
              withPrice={false}
            />
          ) : (
            <ProductLine
              key={`${index}-${shopItem.item.name}`}
              shopItem={shopItem}
              lg
              withPrice={false}
            />
          )
        )}
      </ItemsWrapper>
      <Divider style={{ width: '100%', margin: 8 }} />
      {!!order.message && (
        <>
          <Heading size={700} styles={{ color: 'black', marginLeft: 8 }}>
            Message
          </Heading>
          <Text size={600} styles={{ color: 'black', marginLeft: 8 }}>
            <b>{order.message || "Pas d'instruction"}</b>
          </Text>
          <Divider style={{ width: '100%', margin: 8 }} />
        </>
      )}
      <Divider style={{ width: '100%', margin: 8 }} />
    </Wrapper>
  );
});

export default KitchenReceipt;

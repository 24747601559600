import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

import { prepareDocForUpdate } from '../../helpers/firestoreHelpers';

const targetedOrdersUpdate = async (ordersIDs, data) => {
  try {
    return Promise.all(
      ordersIDs.map(async id => {
        await Firebase.firestore()
          .collection('orders')
          .doc(id)
          .update(
            prepareDocForUpdate({
              metadata: {
                ...data,
              },
            })
          );
      })
    );
  } catch (error) {
    console.error(`Whoops, error during orders updates: ${error.message}`, {
      data,
    });
  }
};

const onInvoiceCancel = async invoice => {
  console.log('Cancel invoice called', { invoice });
  ReactGA.event({
    category: 'Rusher',
    action: 'Cancel invoice',
  });

  try {
    await targetedOrdersUpdate(invoice.orders, {
      rusherIsPaid: false,
      rusherPaidAt: null,
      invoiceId: null,
      isBilled: false,
    });

    // console.log('Targeted orders succesfully reset');
  } catch (error) {
    console.error(`Whoops, couldn't reset invoice's orders: ${error.message}`, {
      invoice,
    });
    return;
  }

  const updatedInvoice = {
    ...invoice,
    status: 'cancelled',
    cancelledAt: Date.now(),
    total: 0,
    commission: 0,
    orders: [],
  };

  try {
    await Firebase.firestore()
      .collection('rushersInvoices')
      .doc(invoice.id)
      .set(updatedInvoice);

    // console.log('Document successfully deleted!');
  } catch (error) {
    console.error(`Whoops, couldn't cancel the invoice: ${error.message}`, {
      invoice,
    });
  }
};

export { targetedOrdersUpdate, onInvoiceCancel };

import axios from 'axios';
import Firebase from 'firebase/app';
import 'firebase/auth';

const { REACT_APP_FIREBASE_CF_BASE_URL } = process.env;

const CF_BASE_URL_DEV = 'http://localhost:5001/delyzon-dev-banban/us-central1';

// Only use it to make authenticated request to our api
const authenticatedRequest = (method, url, data, isDev) => {
  if (!Firebase.auth().currentUser) {
    throw new Error('You need to be auth to do this request');
  }

  try {
    return Firebase.auth()
      .currentUser.getIdToken()
      .then(token => {
        const request = {
          method: method,
          url: `${
            isDev ? CF_BASE_URL_DEV : REACT_APP_FIREBASE_CF_BASE_URL
          }/${url}/`, // The slash at the end prevent 404 error from firebase. https://github.com/firebase/functions-samples/issues/101
          headers: {
            Authorization: `Bearer ${token}`,
          },
          ...(!!data ? { data } : {}),
        };

        if (isDev) {
          console.info('Making authenticated request:', method, url, request);
        }

        // TODO: Regarder useQuery
        return axios(request);
      });
  } catch (error) {
    throw new Error(error);
  }
};

export default authenticatedRequest;

import React from 'react';
import { InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { PhoneIcon } from '../icons';
import { Input } from './Input';
import { FieldWrapper } from '../../styles/form';
import { colors } from '../../styles/constants';

const PhoneInputComp = props => {
  const { t } = useTranslation(['forms']);

  return (
    <FieldWrapper padding={0}>
      <Input
        name="phone"
        label={t('forms:phone')}
        required
        placeholder="06 01 02 03 04"
        type="phone"
        size="small"
        variant="outlined"
        fullWidth
        {...props}
        // InputProps={{
        //   startAdornment: (
        //     <InputAdornment position="start">
        //       <PhoneIcon style={{ color: colors.grey }} size="16px" />
        //     </InputAdornment>
        //   ),
        // }}
      />
    </FieldWrapper>
  );
};

export default PhoneInputComp;

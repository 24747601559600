import { nanoid } from 'nanoid';

const createID = length => {
  if (!!length) {
    return nanoid(length);
  }

  return nanoid(16);
};

export default createID;

import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

const resetPassword = email => {
  ReactGA.event({
    category: 'User',
    action: 'Log out',
  });

  try {
    return Firebase.auth().sendPasswordResetEmail(email);
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

export default resetPassword;

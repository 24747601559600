import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';
import isEmpty from 'lodash/isEmpty';

import createID from '../helpers/createID';
import { prepareDocForCreate } from '../helpers/firestoreHelpers';

const createDiscount = async ({
  adminUserID, // *required* string admin uid
  adminUserName, // *required* string admin name
  code, // *required* string e.g. DELYHOME50
  deliveryFeesValue, // *required* number in %
  appFeesValue, // *required* number in %
  active, // *required* boolean
  minTotal, // number: Minimum required in €
  maxUsageCount, // number Max usage count
  maxUsageCountByUser, // number Max usage count by user: ONLY WHEN EXCLUSIVE
  exclusiveTo, // array of user id
  exclusiveShop, // optionnal shop id
}) => {
  ReactGA.event({
    category: 'Discount',
    action: 'Create discount code',
  });

  const discount = {
    id: createID(24),
    createdBy: adminUserID,
    code,
    deliveryFeesValue,
    appFeesValue,
    active,
    usedBy: [],
    minTotal: minTotal || 0,
    exclusive: !!exclusiveTo.length,
    maxUsageCount: maxUsageCount || 0,
    maxUsageCountByUser: maxUsageCountByUser || 0,
    exclusiveTo:
      exclusiveTo.length > 0
        ? exclusiveTo.split(', ').filter(i => !isEmpty(i))
        : [],
    exclusiveShop: exclusiveShop || null,
    // auto generated
    updatedAt: null,
  };

  try {
    return Firebase.firestore()
      .collection('discounts')
      .doc(discount.id)
      .set(prepareDocForCreate(discount));
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

export default createDiscount;

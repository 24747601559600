import React from 'react';
import styled from 'styled-components';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { SPACING, colors } from '../styles/constants';
import FrIcon from '../assets/images/fr.png';
import EnIcon from '../assets/images/en.png';

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${SPACING[100]};

  ${({ wrapperProps }) => wrapperProps}
`;

const LngIcon = styled.img`
  width: 26px;
  height: 26px;
  border-radius: 50%;
  border: ${({ active }) =>
    active ? `1px solid ${colors.green}` : '1px solid transparent'};

  &:hover {
    cursor: pointer;
    transform: ${({ active }) => (active ? 'scale(1)' : 'scale(1.1)')};
    cursor: ${({ active }) => (active ? 'initial' : 'pointer')};
  }
`;

const LanguageSwitcher = props => {
  const { activeLng, onLanguageUpdate, wrapperProps } = props;
  const { i18n } = useTranslation(['sidebar', 'common']);

  const currentLng = activeLng ?? i18n.language;

  const onUpdateLanguage = async lng => {
    i18n.changeLanguage(lng);
    if (onLanguageUpdate) {
      onLanguageUpdate(lng);
    }
  };

  return (
    <Wrapper wrapperProps={wrapperProps}>
      <IconButton
        aria-label="To french version"
        onClick={() => onUpdateLanguage('fr')}
        style={{ padding: 0, marginRight: SPACING[100] }}
      >
        <LngIcon active={currentLng === 'fr'} src={FrIcon} alt="fr icon" />
      </IconButton>
      <IconButton
        aria-label="To english version"
        onClick={() => onUpdateLanguage('en')}
        style={{ padding: 0, marginRight: SPACING[100] }}
      >
        <LngIcon active={currentLng === 'en'} src={EnIcon} alt="en icon" />
      </IconButton>
    </Wrapper>
  );
};

export default LanguageSwitcher;

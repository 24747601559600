import * as yup from 'yup';

import { RUSHER, LEGAL } from '../fields';

const signupRusherName = yup.object({
  name: RUSHER.NAME_RUSHER_FIELD_VALIDATION,
});

const signupRusherDetails = yup.object({
  phone: RUSHER.PHONE_RUSHER_FIELD_VALIDATION,
  baseCity: RUSHER.CITY_RUSHER_FIELD_VALIDATION,
});

const signupRusherLogo = yup.object({
  pictureURL: RUSHER.LOGO_RUSHER_FIELD_VALIDATION,
});

const signupRusherLegal = yup.object({
  email: LEGAL.EMAIL_LEGAL_FIELD_VALIDATION,
  ownerName: yup
    .string('Votre nom')
    .required('Votre nom et prenom sont requis !'),
  phone: LEGAL.PHONE_LEGAL_FIELD_VALIDATION,
  siret: LEGAL.SIREN_LEGAL_FIELD_VALIDATION,
  status: LEGAL.STATUS_LEGAL_FIELD_VALIDATION,
  address: yup.string('Votre adresse').required('Votre adresse est requise !'),
});

export {
  signupRusherLogo,
  signupRusherName,
  signupRusherDetails,
  signupRusherLegal,
};

import React from 'react';
import Firebase from 'firebase/app';
import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import { IconButton } from '@material-ui/core';

import { NavLink, Container, Loader } from '../../../../styleguide';
import { Page } from '../../../common';
import { ReturnIcon } from '../../../../styleguide/icons';
import { MainWrapper } from '../../../../styles/order';
import { StepWrapper } from '../../../../styles/order-tracking';
import { useTheme } from '../../../../hooks';
import STEPS from './steps';

const { REACT_APP_SETTINGS_DOC_ID } = process.env;

const OrderTracking = props => {
  const { match } = props;
  const theme = useTheme();

  const [orderValue, orderLoading, orderError] = useCollectionData(
    Firebase.firestore()
      .collection('orders')
      .where(`id`, '==', match.params.id),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [settings, settingsLoading, settingsError] = useDocumentData(
    Firebase.firestore().doc(`app/${REACT_APP_SETTINGS_DOC_ID}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (settingsLoading || orderLoading) {
    return (
      <Loader color={theme.primaryColor} background={theme.backgroundColor} />
    );
  }

  if (settingsError || orderError) {
    if (settingsError) {
      throw new Error(settingsError.toString());
    } else if (orderError) {
      throw new Error(orderError.toString());
    }
  }

  if (!orderValue.length && !orderLoading) {
    return <></>;
  }

  const order = orderValue[0];
  const { Step } = STEPS.filter(item => item.name === order.status)[0];
  const delayedDelivery = !!settings && settings.strongPresence;

  return (
    <Page
      name="Suivi commande"
      navbarLeftChild={
        <NavLink color={theme.headingColor} to="/account/orders">
          <IconButton aria-label="retour">
            <ReturnIcon color={theme.headingColor} size="16px" />
          </IconButton>
        </NavLink>
      }
    >
      <Container>
        <MainWrapper padding>
          <StepWrapper>
            <Step order={order} delayedDelivery={delayedDelivery} />
          </StepWrapper>
        </MainWrapper>
      </Container>
    </Page>
  );
};

export default OrderTracking;

import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

import { prepareDocForUpdate } from '../../helpers/firestoreHelpers';
import { targetedOrdersUpdate } from './onInvoiceCancel';

const flagInvoiceAsPaid = async invoice => {
  // console.log('flagInvoiceAsPaid called', { invoice });
  ReactGA.event({
    category: 'Rusher',
    action: 'Flag invoice paid',
  });

  const updatedInvoice = {
    status: 'paid',
    paidAt: Date.now(),
  };

  try {
    await targetedOrdersUpdate(invoice.orders, {
      rusherIsPaid: true,
      rusherPaidAt: Date.now(),
      invoiceId: invoice.id,
      isBilled: true,
    });

    // console.log('Targeted orders succesfully update');
  } catch (error) {
    console.error(
      `Whoops, couldn't update invoice's orders: ${error.message}`,
      {
        invoice,
      }
    );
    return;
  }

  try {
    await Firebase.firestore()
      .collection('rushersInvoices')
      .doc(invoice.id)
      .update(prepareDocForUpdate(updatedInvoice));

    // console.log('Document successfully updated!');
  } catch (error) {
    console.error(`Whoops, couldn't flag the invoice: ${error.message}`, {
      invoice,
    });
  }
};

export default flagInvoiceAsPaid;

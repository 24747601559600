const replaceVariable = (text, variable) => {
  return text.replace(/(\$value|\$\[value])/gm, variable);
};

const defaultFormatValue = value =>
  value !== null && value !== undefined ? value.toString() : '';

// Eg.
// title += plurialize({
//   value: item.length,
//   default: " ($value) plat",
//   more: " ($value) plats",
// });
const plurialize = ({
  value,
  one,
  default: defaultText,
  empty,
  zero,
  more,
  formatValue = defaultFormatValue,
}) => {
  const strValue = formatValue(value);
  if (value === null || value === undefined) {
    return replaceVariable(empty || defaultText, strValue);
  }
  if (value === 0) {
    return replaceVariable(zero || defaultText, strValue);
  }
  if (value === 1) {
    return replaceVariable(one || defaultText, strValue);
  }
  if (value > 1) {
    return replaceVariable(more || defaultText, strValue);
  }

  return replaceVariable(defaultText, strValue);
};

export default plurialize;

import React from 'react';
import Firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { DataGrid } from '@material-ui/data-grid';
import { DateTime } from 'luxon';

import { BlockWrapper } from '../../../../../styles/admin';
import { useTheme } from '../../../../../hooks';
import BlockLayout from '../common/BlockLayout';
import StatCard from '../common/StatCard';
import BlockHeader from '../common/BlockHeader';
import updateDiscount from '../../../../../actions/discounts/updateDiscount';
import DiscountModal from '../common/DiscountModal';

const columns = [
  {
    field: 'code',
    headerName: 'Code',
    width: 120,
  },
  {
    field: 'active',
    headerName: 'Active',
    width: 110,
  },
  {
    field: 'active',
    headerName: 'Active',
    width: 110,
    valueFormatter: ({ value }) => (value ? '✅' : '❌'),
  },
  {
    field: 'appFeesValue',
    headerName: '% frais app',
    width: 160,
    valueFormatter: ({ value }) => `${value}%`,
  },
  {
    field: 'deliveryFeesValue',
    headerName: '% frais livraison',
    width: 160,
    valueFormatter: ({ value }) => `${value}%`,
  },
  {
    field: 'exclusive',
    headerName: 'Exclusive',
    width: 110,
    valueFormatter: ({ value }) => (value ? '✅' : '❌'),
  },
  {
    field: 'exclusiveShop',
    headerName: 'Exclusif shop',
    width: 220,
    valueFormatter: ({ value }) => (value ? `✅: ${value}` : '❌'),
  },
  {
    field: 'createdAt',
    headerName: 'Crée le',
    width: 220,
  },
  {
    field: 'createdBy',
    headerName: 'Crée par',
    width: 230,
  },
  {
    field: 'usedByCount',
    headerName: 'Nombre utilisation',
    width: 220,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 280,
  },
];

const DiscountsBlock = props => {
  const [editDialog, setEditDialog] = React.useState(null);
  const theme = useTheme();

  const [discounts, discountsLoading, discountsError] = useCollectionData(
    Firebase.firestore()
      .collection('discounts')
      .orderBy('createdAt', 'desc'),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
        timestampsInSnapshots: true,
      },
    }
  );

  const onRowClick = params => {
    const discount = discounts.find(d => d.id === params.row.id);
    setEditDialog({ updatedData: null, data: discount });
  };

  const onSubmit = async (discount, id) => {
    await updateDiscount(discount, id);
    setEditDialog(null);
  };

  const { rows, discountsCount, activeCount } = React.useMemo(() => {
    if (discountsLoading || !discounts?.length || !!discountsError) {
      return {
        rows: [],
        discountsCount: 0,
        activeCount: 0,
      };
    }
    const rows = discounts.map(discount => ({
      code: discount.code,
      active: discount.active,
      createdBy: discount.createdBy,
      createdAt: DateTime.fromSeconds(
        discount.createdAt.seconds
      ).toLocaleString(DateTime.DATETIME_SHORT),
      appFeesValue: discount.appFeesValue,
      deliveryFeesValue: discount.deliveryFeesValue,
      usedByCount: discount.usedBy.length,
      exclusive: discount.exclusive,
      id: discount.id,
      exclusiveShop: discount.exclusiveShop,
    }));

    const discountsCount = discounts.length;
    const activeCount = discounts.filter(discount => discount.active).length;

    return {
      rows,
      discountsCount,
      activeCount,
    };
  }, [discountsLoading, discounts, discountsError]);

  if (discountsError) {
    throw new Error(discountsError.toString());
  }

  return (
    <BlockWrapper theme={theme}>
      <BlockHeader
        title="Les promotions"
        path="/admin/dashboard/discounts"
        label={`Toutes les promotions`}
      />

      <BlockLayout
        statBlocks={
          <>
            <StatCard label={'Promos'} value={discountsCount} />
            <StatCard label={'Actives'} value={activeCount} />
          </>
        }
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          stickyHeader
          loading={discountsLoading}
          onRowClick={onRowClick}
        />
      </BlockLayout>

      {editDialog ? (
        <DiscountModal
          onSubmit={(discount, id) => onSubmit(discount, id)}
          onClose={() => setEditDialog(null)}
          existingDiscount={editDialog?.data}
          open={!!editDialog}
        />
      ) : null}
    </BlockWrapper>
  );
};

export default DiscountsBlock;

import React from 'react';
import parsePhoneNumber from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';

import Container from '../container';
import Button from '../button';
// import PhoneValidationForm from './PhoneValidationForm';
import { useSession } from '../../hooks';
import { FormWrapper, ButtonsWrapper, FieldWrapper } from '../../styles/form';
import PROPERTY_FIELDS from './Fields';
import {
  EMAIL_FIELD_VALIDATION,
  PHONE_FIELD_VALIDATION,
} from '../../forms/fields/identity';

const parsePhoneBeforeSubmit = phone => {
  if (!phone) {
    return '';
  }
  return parsePhoneNumber(phone, 'FR').format('INTERNATIONAL');
};

const PropertyEdit = props => {
  const { property, initialValue, setEdition, onUpdate } = props;
  const { user, authUser } = useSession();
  const [editedValue, setEditedValue] = React.useState({
    [property]: initialValue || '',
  });
  const { t } = useTranslation(['common']);

  const { Field } = PROPERTY_FIELDS.filter(item => item.name === property)[0];

  const canSubmit =
    editedValue[property] !== initialValue && !!editedValue[property];

  const onChange = value => {
    setEditedValue({ [property]: value });
  };

  const onSubmitForm = (event, isValid) => {
    if (event) {
      event.preventDefault();
    }

    if (property === 'phone') {
      const isValidPhone = PHONE_FIELD_VALIDATION.isValidSync(
        editedValue.phone
      );

      if (!!isValidPhone) {
        onUpdate(
          { phone: parsePhoneBeforeSubmit(editedValue.phone) },
          authUser.uid
        );
      }
      setEdition(null);
    } else if (property === 'email') {
      const isValidEmail = EMAIL_FIELD_VALIDATION.isValidSync(
        editedValue.email
      );

      if (!!isValidEmail) {
        onUpdate({ email: editedValue.email }, authUser.uid);
      }
      setEdition(null);
    } else if (property === 'pwd') {
      setEdition(null);
      return;
    } else if (property === 'language') {
      onUpdate(
        { settings: { ...user.settings, language: editedValue.language } },
        authUser.uid
      );
    } else {
      onUpdate(editedValue, authUser.uid);
      setEdition(null);
    }
  };

  return (
    <Container justify="flex-start">
      {/* {property === 'phone' && phoneConfirmation ? (
        <PhoneValidationForm
          onSubmit={() => {
            setPhoneConfirmation({
              inProgress: false,
            });
            onSubmitForm(null, true);
          }}
          phone={editedValue[property]}
        />
      ) : ( */}
      <>
        <FormWrapper onSubmit={onSubmitForm}>
          <FieldWrapper>
            <Field value={editedValue[property]} onChange={onChange} />
          </FieldWrapper>

          <ButtonsWrapper>
            <Button minimal onClick={() => setEdition(null)}>
              {t('common:cancel')}
            </Button>
            {/* {property === 'phone' && !phoneConfirmation ? (
              <Button type="submit" disabled={!canSubmit}>
                Vérifer
              </Button>
            ) : ( */}
            <Button type="submit" disabled={!canSubmit}>
              {t('common:save')}
            </Button>
            {/* )} */}
          </ButtonsWrapper>
        </FormWrapper>
      </>
      {/* )} */}
    </Container>
  );
};

export default PropertyEdit;

import React from 'react';
import { toaster } from 'evergreen-ui';

import updateOrder from '../../../actions/orders/updateOrder';
import { Heading } from '../../../styleguide';
import { TabContentWrapper } from '../../../styles/shop';
import {
  OrdersListWrapper,
  EmptyNewOrders,
} from '../../../styles/orders-cards';
import TabPanel from '../../shop/tabs/TabPanel';
import { NewOrderCard } from './Cards';
import NewOrderDialog from './dialogs/NewOrderDialog';
import EmptyTab from './EmptyTab';

const NewsTab = props => {
  const { value, orders, rusher } = props;
  const [confirmModal, setConfirmModal] = React.useState(null);
  const [selectedOrder, setSelectedOrder] = React.useState(null);

  React.useEffect(() => {
    if (!!selectedOrder && !!confirmModal) {
      const orderToUpdate = orders.find(o => o.id === selectedOrder.id);

      if (!orderToUpdate || !!orderToUpdate?.inTreatment) {
        setConfirmModal(null);
        return;
      }
    }
  }, [orders, selectedOrder, confirmModal]);

  const onConfirm = async order => {
    try {
      const orderToUpdate = orders.find(o => o.id === order.id);

      if (!orderToUpdate || !!orderToUpdate?.inTreatment) {
        setConfirmModal(null);
        toaster.warning(
          `⏳ Impossible commande en cours de traitement, réessayez plus tard`
        );
        return;
      }

      if (rusher.isWorked && rusher.isVerified && rusher.available) {
        await updateOrder(orderToUpdate, {
          rusher: rusher,
        });
        toaster.success(`Commande attribué 🚀`, {
          description: `La commande numéro ${orderToUpdate.id} vous est desormais attribué`,
        });
        setConfirmModal(null);
      } else if (rusher.isVerified && !rusher.available) {
        toaster.danger(`Attribution impossible ❌`, {
          description: `Votre compte à temporairement était suspendu par notre équipe, contactez nous pour plus d'informations.`,
        });
        setConfirmModal(null);
      } else {
        toaster.danger(`Attribution impossible ❌`, {
          description: `Vous n'êtes pas disponible pour la livraison, vous ne pouvez donc pas vous attribuez cette commande`,
        });
        setConfirmModal(null);
      }
    } catch (error) {
      setConfirmModal(null);
      toaster.danger(`Une erreur est survenue`, {
        description: `Impossible d'attribuer la commande ${order.id}, contactez notre équipe pour plus d'informations`,
      });
    }
  };

  const hasExistingOrders = !!orders.length;

  return (
    <TabPanel value={value} index={0}>
      <TabContentWrapper>
        {hasExistingOrders ? (
          <>
            <Heading size={800}>Les nouvelles commandes</Heading>

            <OrdersListWrapper>
              {orders.map(order => {
                return (
                  <NewOrderCard
                    key={order.id}
                    onClickCancel={() => {
                      setConfirmModal('cancel');
                      setSelectedOrder(order);
                    }}
                    onClickConfirm={() => {
                      setConfirmModal('confirm');
                      setSelectedOrder(order);
                    }}
                    order={order}
                    canTakeNewOrder={
                      rusher.available && rusher.isVerified && rusher.isWorked
                    }
                  />
                );
              })}
            </OrdersListWrapper>
            {!!confirmModal ? (
              <NewOrderDialog
                isOpen={!!confirmModal}
                confirmModal={confirmModal}
                selectedOrder={selectedOrder}
                onClose={() => setConfirmModal(null)}
                onConfirm={() => onConfirm(selectedOrder)}
              />
            ) : null}
          </>
        ) : (
          <EmptyNewOrders>
            <EmptyTab
              title={'Pas de nouvelle commande'}
              description={
                'Ne vous inquietez pas, les commandes ne vont pas tarder à pleuvoir ⏳'
              }
            />
          </EmptyNewOrders>
        )}
      </TabContentWrapper>
    </TabPanel>
  );
};

export default NewsTab;

import ReactGA from 'react-ga4';

import authenticatedRequest from '../helpers/authenticatedRequest';
import { API } from '../../common';

const verifyCode = async ({ id, token }) => {
  // console.log('verifyCode called', {
  //   id,
  //   token,
  // });
  ReactGA.event({
    category: 'Phone-verify',
    action: 'Verify phone verification',
  });

  try {
    const {
      data: { status },
    } = await authenticatedRequest('POST', `${API.backend.verifyCode}`, {
      id,
      token,
    });

    return {
      status,
    };
  } catch (error) {
    console.error('Error during verifyCode', {
      error: error,
    });
    return {
      error: error,
    };
  }
};

export default verifyCode;

import React from 'react';
import Firebase from 'firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';
import { DataGrid } from '@material-ui/data-grid';
import { IconButton } from '@material-ui/core';

import { Container, NavLink } from '../../../../styleguide';
import { ReturnIcon } from '../../../../styleguide/icons';
import { ShopDialog } from '../components/common/Dialogs';
import {
  DashboardPageContainer,
  DashboardPageBlockContainer,
  BlockStatsRow,
  BlockWrapper,
} from '../../../../styles/admin';
import { updateShop, setShopAsPartner } from '../../../../actions/shop';
import { useTheme } from '../../../../hooks';
import DashboardHeader from '../components/common/Header';
import { Page } from '../../../common';
import BlockHeader from '../components/common/BlockHeader';
import StatCard from '../components/common/StatCard';
import { SPACING } from '../../../../styles/constants';

const {
  REACT_APP_STRIPE_DASHBOARD_URL,
  REACT_APP_FIREBASE_WEBAPP_URL,
} = process.env;

const columns = [
  {
    field: 'name',
    headerName: 'Nom',
    width: 200,
  },
  {
    field: 'available',
    headerName: 'Ouvert',
    width: 110,
    align: 'center',
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 300,
  },
  {
    field: 'isValidated',
    headerName: 'Vérifié 🔐',
    width: 120,
    align: 'center',
  },
  {
    field: 'isPartner',
    headerName: 'Favoris 🥇',
    width: 140,
    align: 'center',
  },
  {
    field: 'owner',
    headerName: 'Propriétaire',
    width: 300,
    align: 'center',
  },
  {
    field: 'percentage',
    headerName: 'Commission 💸',
    width: 150,
    align: 'center',
    valueFormatter: ({ value }) => `${value}%`,
  },
  {
    field: 'stripeCustomerId',
    headerName: 'ID Stripe',
    width: 300,
  },
  {
    field: 'stripeURL',
    headerName: 'Compte Stripe',
    width: 200,
  },
  {
    field: 'profilURL',
    headerName: 'Profil - app',
    width: 200,
  },
  {
    field: 'dashboardURL',
    headerName: 'Dashboard - app',
    width: 200,
  },
];

const OrdersDashboard = () => {
  const [editDialog, setEditDialog] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const theme = useTheme();

  const [shopsDocs, shopsLoading, shopsError] = useCollection(
    Firebase.firestore().collection('shops'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const { rows, count, opened, partners, nonVerified } = React.useMemo(() => {
    if (shopsLoading || shopsError || !shopsDocs?.size) {
      return { rows: [], count: 0, opened: 0, partners: 0, nonVerified: 0 };
    }

    const shops = shopsDocs.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }));

    const rows = shops.map(shop => ({
      id: shop.id,
      name: shop.name,
      available: shop.available ? '✅' : '❌',
      owner: shop.ownerID,
      percentage: shop.percentage,
      stripeCustomerId: shop.stripeCustomerId,
      isValidated: shop.isValidated ? '✅' : '❌',
      isPartner: shop.isPartner ? '✅' : '❌',
      stripeURL: shop.stripeCustomerId
        ? `${REACT_APP_STRIPE_DASHBOARD_URL}/connect/accounts/${shop.stripeCustomerId}`
        : '',
      profilURL: `${REACT_APP_FIREBASE_WEBAPP_URL}/shop/${shop.id}`,
      dashboardURL: `${REACT_APP_FIREBASE_WEBAPP_URL}/shopDashboard/${shop.id}`,
      logoURL: shop.logoURL,
      phone: shop.legal.phone,
    }));

    const count = shops.length;
    const opened = shops.filter(shop => shop.available).length;
    const partners = shops.filter(shop => shop.isPartner).length;
    const nonVerified = shops.filter(shop => !shop.isValidated).length;

    return { rows, count, opened, partners, nonVerified };
  }, [shopsDocs, shopsLoading, shopsError]);

  const onRowClick = params => {
    setEditDialog({ updatedData: null, data: params.row });
  };

  const onConfirmShopUpdate = async () => {
    if (editDialog.data !== editDialog.updatedData) {
      setLoading(true);

      updateShop({
        id: editDialog.data.id,
        ...(editDialog.updatedData.isValidated
          ? {
              isValidated:
                editDialog.updatedData.isValidated === '❌' ? false : true,
            }
          : {}),
        ...(!!editDialog.updatedData.percentage
          ? {
              percentage: editDialog.updatedData.percentage,
            }
          : {}),
      });

      if (!!editDialog.updatedData.isPartner) {
        await setShopAsPartner(
          editDialog.data.id,
          editDialog.updatedData.isPartner === '❌' ? false : true,
          editDialog.updatedData.percentage || editDialog.data.percentage
        );
      }
    }
    setLoading(false);
    setEditDialog(null);
  };

  if (shopsError) {
    throw new Error(shopsError.toString());
  }

  return (
    <Page
      name="Dashboard Shops"
      navbarLeftChild={
        <NavLink
          color={theme.headingColor}
          to={{ pathname: '/admin/dashboard' }}
        >
          <IconButton aria-label="retour">
            <ReturnIcon color={theme.headingColor} size="16px" />
          </IconButton>
        </NavLink>
      }
    >
      <Container justify="flex-start">
        <DashboardHeader title="Les établissements" withBackButton />

        <DashboardPageContainer theme={theme}>
          <BlockWrapper theme={theme}>
            <BlockHeader
              title={"Vue d'ensemble"}
              label={`Sur tous les établissements`}
            />
            <BlockStatsRow>
              <StatCard label={'Total'} value={count} />
              <StatCard label={'Ouverts'} value={opened} />
              <StatCard label={'Favoris'} value={partners} />
              <StatCard label={'Non vérifiés'} value={nonVerified} />
            </BlockStatsRow>
          </BlockWrapper>

          <BlockWrapper theme={theme} style={{ marginTop: SPACING[200] }}>
            <BlockHeader title={'Établissements'} label={`Tableau de bord`} />

            <DashboardPageBlockContainer>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={25}
                loading={shopsLoading}
                onRowClick={onRowClick}
                stickyHeader
              />
            </DashboardPageBlockContainer>
          </BlockWrapper>

          {editDialog ? (
            <ShopDialog
              shop={editDialog}
              isOpen={!!editDialog}
              onClose={() => setEditDialog(null)}
              setEditDialog={setEditDialog}
              onConfirm={onConfirmShopUpdate}
              cantConfirm={!editDialog.updatedData}
              isLoading={isLoading}
            />
          ) : null}
        </DashboardPageContainer>
      </Container>
    </Page>
  );
};

export default OrdersDashboard;

import React from 'react';
import Firebase from 'firebase/app';
import { isMobile } from 'react-device-detect';
import { useCollection } from 'react-firebase-hooks/firestore';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import { Chip } from '@material-ui/core';
import { toaster } from 'evergreen-ui';
import { Alert, AlertTitle } from '@material-ui/lab';

import {
  Button,
  Heading,
  Container,
  ConfirmDialog,
  Loader,
} from '../../styleguide';
import { OrdersActionsWrapper } from '../../styles/shop';
import { SPACING } from '../../styles/constants';
import {
  DashboardIcon,
  ChartEmptyIcon,
  ChartFullIcon,
} from '../../styleguide/icons';
import { ShopOrdersHeaderWrapper } from '../../styles/shop-orders';
import { Page } from '../common';
import { useSession, useTheme } from '../../hooks';
import { updateShop } from '../../actions/shop';
import ShopOrdersTabs from './orders';

const ShopOrdersFollow = props => {
  const { match } = props;
  const [confirm, setConfirm] = React.useState(null);

  let location = useLocation();
  const theme = useTheme();
  const { user } = useSession();
  let history = useHistory();

  const shopID =
    user.role === 'admin' && match.params.id ? match.params.id : user.id;
  const query =
    user.role === 'admin' && match.params.id
      ? ['id', shopID]
      : ['ownerID', user.id];

  const [value, loading, error] = useCollection(
    Firebase.firestore()
      .collection('shops')
      .where(query[0], '==', query[1]),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const onToggleStrongPresence = canToggle => {
    const currentPresence = shop.strongPresence;

    if (canToggle) {
      setConfirm(null);
      updateShop({ ...shop, strongPresence: !currentPresence }).then(() => {
        if (currentPresence) {
          toaster.danger(`Le mode "Forte Affluence" est maintenant desactivé`);
        } else {
          toaster.danger(`Le mode "Forte Affluence" est maintenant activé`);
        }
      });
    } else {
      const titleContext = currentPresence ? 'desactiver' : 'activer';
      const description = !currentPresence
        ? 'En activant le mode forte affluence, les délais de préparation seront augmentés.'
        : 'En desactivant le mode forte affluence, les délais de préparation reviendront à la normale.';
      setConfirm({
        title: `Voulez vous ${titleContext} le mode "Forte Affluence"`,
        description,
        onConfirm: () => {
          onToggleStrongPresence(true);
        },
      });
    }
  };

  if (loading) {
    return (
      <Loader color={theme.primaryColor} background={theme.backgroundColor} />
    );
  }

  if (error) {
    throw new Error(error.toString());
  }

  if (!value || !value.docs || !value.docs.length) {
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />
    );
  }

  const shop = { ...value.docs[0].data(), id: value.docs[0].id };

  if (!shop.stripeCustomerId) {
    return (
      <Redirect
        to={{
          pathname: '/shop/stripe/accountCreation',
          state: { from: location },
        }}
      />
    );
  }

  return (
    <Page name="Nos commandes">
      <Container justify="flex-start">
        <ShopOrdersHeaderWrapper background={theme.backgroundColor}>
          {!isMobile ? (
            <Button
              small
              primary
              onClick={() => history.push(`/shopDashboard/${shop.id}`)}
              startIcon={<DashboardIcon color={theme.white} size="16px" />}
            >
              Retour
            </Button>
          ) : null}
          <Heading
            size={800}
            styles={{
              marginRight: SPACING[100],
              textShadow:
                user.settings.theme !== 'light'
                  ? `2px 2px 2px ${theme.black}`
                  : 'none',
            }}
            centered
            {...(shop.bannerURL ? { color: theme.primaryColor } : {})}
          >
            Les commandes
          </Heading>
          {!isMobile ? (
            <Chip
              label={`${shop.strongPresence ? 'Activé' : 'Desactivé'}`}
              clickable
              color={shop.strongPresence ? 'primary' : 'secondary'}
              variant={'default'}
              onClick={() => onToggleStrongPresence()}
              deleteIcon={
                shop.strongPresence ? <ChartEmptyIcon /> : <ChartFullIcon />
              }
              onDelete={() => onToggleStrongPresence()}
            />
          ) : null}
        </ShopOrdersHeaderWrapper>

        {isMobile ? (
          <OrdersActionsWrapper align={'space-between'}>
            <Button
              small
              primary
              onClick={() => history.push(`/shopDashboard/${shop.id}`)}
              startIcon={<DashboardIcon color={theme.white} size="16px" />}
            >
              Retour
            </Button>
            <Chip
              label={`${shop.strongPresence ? 'Activé' : 'Desactivé'}`}
              clickable
              color={shop.strongPresence ? 'primary' : 'secondary'}
              variant={'default'}
              onClick={() => onToggleStrongPresence()}
              deleteIcon={
                shop.strongPresence ? <ChartEmptyIcon /> : <ChartFullIcon />
              }
              onDelete={() => onToggleStrongPresence()}
            />
          </OrdersActionsWrapper>
        ) : null}

        {!shop.isValidated ? (
          <Alert severity="info" style={{ margin: '8px auto' }}>
            <AlertTitle>En attente de validation</AlertTitle>
            Une fois votre compte validé par notre équipe, vous pourrez recevoir
            vos premières commandes 💸
          </Alert>
        ) : null}

        <ShopOrdersTabs shop={shop} />
      </Container>
      {confirm && (
        <ConfirmDialog
          isOpen={!!confirm}
          onClose={() => setConfirm(null)}
          onConfirm={confirm.onConfirm}
          title={confirm.title}
          description={confirm.description}
          cantConfirm={!shop.isValidated}
          info={
            !shop.isValidated ? (
              <Alert severity="info" style={{ margin: '8px auto' }}>
                <AlertTitle>En attente de validation</AlertTitle>
                Une fois votre compte validé par notre équipe, vous pourrez
                recevoir vos premières commandes 💸
              </Alert>
            ) : null
          }
        />
      )}
    </Page>
  );
};

export default ShopOrdersFollow;

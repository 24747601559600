import React from 'react';
import usePlacesAutocomplete, {
  getGeocode,
  getZipCode,
} from 'use-places-autocomplete';
import useOnclickOutside from 'react-cool-onclickoutside';
import { useTranslation } from 'react-i18next';

import { Input } from './Input';
import { Menu, MenuItem } from '../menu';

const SearchLocationInput = props => {
  const { initialValue, onSelectHandler, error, onFocus, ...rest } = props;
  const { t } = useTranslation(['signup', 'common']);

  const [anchorEl, setAnchorEl] = React.useState(null);
  const {
    ready,
    value,
    suggestions: { status, data },
    setValue,
    clearSuggestions,
  } = usePlacesAutocomplete({
    requestOptions: {
      /* Define search scope here */
      componentRestrictions: { country: 'fr' },
      types: ['address'],
    },
    debounce: 600,
  });

  const ref = useOnclickOutside(() => {
    // When user clicks outside of the component, we can dismiss
    // the searched suggestions by calling this method
    clearSuggestions();
  });

  const handleInput = e => {
    // Update the keyword of the input element
    setValue(e.target.value);
  };

  const handleFocus = event => {
    if (!anchorEl) {
      setAnchorEl(event.currentTarget);
    }

    if (!!onFocus) {
      onFocus();
    }
  };

  const handleSelect = ({ description, place_id }) => {
    // When user selects a place, we can replace the keyword without request data from API
    // by setting the second parameter as "false"
    setValue(description, false);
    clearSuggestions();

    // Get latitude and longitude via utility functions
    getGeocode({ address: description })
      .then(results => getZipCode(results[0], true))
      .then(zipCode => {
        const [address, city] = description.split(', ');
        onSelectHandler({
          street: address,
          city,
          zipCode,
          place_id,
        });
      })
      .catch(error => {
        console.error('😱 Error: ', error);
      });
  };

  return (
    <div ref={ref} style={{ width: '100%' }}>
      <Input
        onFocus={handleFocus}
        onChange={handleInput}
        placeholder={t('signup:addresse:address_placeholder')}
        value={!anchorEl && !value ? initialValue : value}
        disabled={!ready}
        required
        label={t('signup:addresse:address')}
        error={!!error}
        {...(!!error ? { helperText: error.title } : {})}
        {...rest}
      />
      {status === 'OK' && (
        <Menu anchorEl={anchorEl}>
          {data.map(suggestion => {
            const {
              id,
              structured_formatting: { main_text, secondary_text },
            } = suggestion;

            return (
              <MenuItem
                key={id}
                onClickItem={() => handleSelect(suggestion)}
                content={main_text}
                subContent={secondary_text}
              />
            );
          })}
        </Menu>
      )}
    </div>
  );
};

export default SearchLocationInput;

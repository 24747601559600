import { add } from 'lodash';
import { AREAS_FEES } from '../../../common/fees';

const categoriesMapper = orders => {
  const categories = {
    area_1: {
      name: 'Zone 1 | -4km',
      ...AREAS_FEES.area_1,
      orders: [],
      total: 0,
    },
    area_2: {
      name: 'Zone 2 | -6km',
      ...AREAS_FEES.area_2,
      orders: [],
      total: 0,
    },
    area_3: {
      name: 'Zone 3 | -8km',
      ...AREAS_FEES.area_3,
      orders: [],
      total: 0,
    },
    area_4: {
      name: 'Zone 4 | -10km',
      ...AREAS_FEES.area_4,
      orders: [],
      total: 0,
    },
    area_5: {
      name: 'Zone 5 | -15km',
      ...AREAS_FEES.area_5,
      orders: [],
      total: 0,
    },
    area_6: {
      name: 'Zone 6 | -20km',
      ...AREAS_FEES.area_6,
      orders: [],
      total: 0,
    },
    area_7: {
      name: 'Zone 7 | -25km',
      ...AREAS_FEES.area_7,
      orders: [],
      total: 0,
    },
    area_8: {
      name: 'Zone 8 | -30km',
      ...AREAS_FEES.area_8,
      orders: [],
      total: 0,
    },
    area_9: {
      name: 'Zone 9 | -35km',
      ...AREAS_FEES.area_9,
      orders: [],
      total: 0,
    },
    area_10: {
      name: 'Zone 10 | -40km',
      ...AREAS_FEES.area_10,
      orders: [],
      total: 0,
    },
    area_11: {
      name: 'Zone 11 | -45km',
      ...AREAS_FEES.area_11,
      orders: [],
      total: 0,
    },
    area_max: {
      name: 'Zone 12 | +45km',
      ...AREAS_FEES.area_max,
      orders: [],
      total: 0,
    },
  };

  if (!!orders && orders.length) {
    orders.map(order => {
      if (order.distance <= categories.area_1.maxDistance) {
        categories.area_1.total = add(
          categories.area_1.total,
          categories.area_1.cost
        );
        return categories.area_1.orders.push(order);
      } else if (order.distance <= categories.area_2.maxDistance) {
        categories.area_2.total = add(
          categories.area_2.total,
          categories.area_2.cost
        );
        return categories.area_2.orders.push(order);
      } else if (order.distance <= categories.area_3.maxDistance) {
        categories.area_3.total = add(
          categories.area_3.total,
          categories.area_3.cost
        );
        return categories.area_3.orders.push(order);
      } else if (order.distance <= categories.area_4.maxDistance) {
        categories.area_4.total = add(
          categories.area_4.total,
          categories.area_4.cost
        );
        return categories.area_4.orders.push(order);
      } else if (order.distance <= categories.area_5.maxDistance) {
        categories.area_5.total = add(
          categories.area_5.total,
          categories.area_5.cost
        );
        return categories.area_5.orders.push(order);
      } else if (order.distance <= categories.area_6.maxDistance) {
        categories.area_6.total = add(
          categories.area_6.total,
          categories.area_6.cost
        );
        return categories.area_6.orders.push(order);
      } else if (order.distance <= categories.area_7.maxDistance) {
        categories.area_7.total = add(
          categories.area_7.total,
          categories.area_7.cost
        );
        return categories.area_7.orders.push(order);
      } else if (order.distance <= categories.area_8.maxDistance) {
        categories.area_8.total = add(
          categories.area_8.total,
          categories.area_8.cost
        );
        return categories.area_8.orders.push(order);
      } else if (order.distance <= categories.area_9.maxDistance) {
        categories.area_9.total = add(
          categories.area_9.total,
          categories.area_9.cost
        );
        return categories.area_9.orders.push(order);
      } else if (order.distance <= categories.area_10.maxDistance) {
        categories.area_10.total = add(
          categories.area_10.total,
          categories.area_10.cost
        );
        return categories.area_10.orders.push(order);
      } else if (order.distance <= categories.area_11.maxDistance) {
        categories.area_11.total = add(
          categories.area_11.total,
          categories.area_11.cost
        );
        return categories.area_11.orders.push(order);
      } else {
        categories.area_max.total = add(
          categories.area_max.total,
          categories.area_max.cost
        );
        return categories.area_max.orders.push(order);
      }
    });

    // Only keep categories with orders
    Object.keys(categories).map(key => {
      if (!categories[key].orders.length) {
        delete categories[key];
      }
    });
  }

  return categories;
};

export { categoriesMapper };

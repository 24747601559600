import styled from 'styled-components';
import { SPACING, devices } from './constants';

const ContentWrapper = styled.div`
  padding: ${SPACING[200]} 0;
`;

const BasicFlexBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${props => props.padding || 0};
`;

const ListHeaderWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${({ justify }) => justify || 'space-between'};
  align-items: center;
  padding: ${props => props.padding || SPACING[100]};
  flex-wrap: wrap;
`;

const ListHeaderActionsWrapper = styled.div`
  flex: 1;
  display: flex;
  justify-content: ${({ justify }) => justify || 'flex-end'};
  align-items: center;
  padding: 0 ${SPACING[100]};
  flex-wrap: wrap;
`;

const SubHeaderContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: 0 0 ${SPACING[200]} ${SPACING[100]};
  flex-wrap: wrap;
`;

const BlockDataGridWrapper = styled.div`
  width: 100%;
  height: 500px;
  padding: ${SPACING[200]} 0;
`;

const BasicColumnBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: ${({ justify }) => justify || 'center'};
  flex-direction: column;
  padding: ${props => props.padding || 0};
`;

const BlockWrapper = styled.div`
  width: ${props => props.width || `calc(100% - ${SPACING[200]})`};
  max-width: ${props => props.maxWidth || '100%'};
  border-radius: 4px;
  border: 1px solid
    ${props => (!props.transparent ? props.theme.lightGray : 'transparent')};
  box-shadow: ${props =>
    !props.transparent ? props.theme.boxShadow.md : 'none'};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${props => props.margin || `${SPACING[100]}`};
  padding: ${props => props.padding || SPACING[100]};
  position: relative;

  &:hover {
    box-shadow: ${props =>
      !props.transparent ? props.theme.boxShadow.lg : 'none'};
    cursor: ${props => (!props.transparent ? 'pointer' : 'initial')};
  }
`;

const EmptyPlaceholder = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-bottom: ${props => props.paddingBottom || SPACING[200]};
`;

const EmptyPlaceholderContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${SPACING[200]} 0;
  text-align: center;
`;

const EmptyPlaceholderDescription = styled.div`
  padding-top: ${SPACING[200]};
  text-align: center;
  width: 100%;
`;

const EmptyPicture = styled.img`
  width: ${props => props.width || '162px'};
  height: ${props => props.height || '130px'};
  margin: 0 auto;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  ${({ overrides }) => overrides}
`;

const FullPageHorizontalSlider = styled.div`
  width: ${props => props.width || `100%`};
  display: flex;
  align-items: flex-start;
  overflow-x: ${props => (props.centered ? 'hidden' : 'scroll')};
  overflow-y: ${props => (props.centered ? 'hidden' : 'hidden')};
  justify-content: ${props => (props.centered ? 'center' : 'flex-start')};
  scrollbar-color: transparent transparent;
  scrollbar-width: thin;
  padding: ${({ padding }) => padding || `${SPACING[200]} 0`};
  padding-right: ${SPACING[100]};

  @media ${devices.laptop} {
    padding: ${({ padding }) => padding || `${SPACING[200]} 0`};
    padding-right: ${SPACING[100]};
  }

  &.noPadding {
    padding-left: 0 !important;
    padding-right: 0 !important;
  }

  &::-webkit-scrollbar {
    margin: 0 10px;
    height: ${props => props.scrollbarH || '4px'};
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    background: ${props => props.theme.cardBg};
    border-radius: 25px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.lightGray};
    border-radius: 25px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: gray;
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb:focus {
    background: gray;
    cursor: pointer;
  }
`;

const NavDrawerHeader = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border-bottom: 1px solid ${props => props.borderColor};
  padding: ${SPACING[200]} 0;
`;

const NavDrawerHeaderLogoutButton = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  margin-bottom: ${SPACING[400]};
`;

const NavDrawerGroup = styled.div`
  width: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${SPACING[200]} ${SPACING[100]};
`;

const NavDrawerHeaderAvatarWrapper = styled.div`
  padding: 10px;
  border-radius: 100%;
  background-color: ${props => props.color};
  margin-bottom: ${SPACING[200]};
`;

const NavDrawerActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${SPACING[300]} ${SPACING[100]};
  border-bottom: 1px solid ${props => props.borderColor};
`;

const NavDrawerIconButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: ${SPACING[100]};
`;

export {
  BasicFlexBlock,
  BlockWrapper,
  ContentWrapper,
  EmptyPlaceholder,
  EmptyPicture,
  ButtonsWrapper,
  EmptyPlaceholderContent,
  EmptyPlaceholderDescription,
  FullPageHorizontalSlider,
  NavDrawerHeader,
  NavDrawerGroup,
  NavDrawerHeaderAvatarWrapper,
  NavDrawerActionsWrapper,
  NavDrawerIconButtonsWrapper,
  NavDrawerHeaderLogoutButton,
  BasicColumnBlock,
  BlockDataGridWrapper,
  ListHeaderActionsWrapper,
  SubHeaderContent,
  ListHeaderWrapper,
};

import CATEGORIES from './CATEGORIES';
import plurialize from './plurialize';
import { APP_FEES, AREAS_FEES } from './fees';

const capitalize = str => `${str[0].toUpperCase()}${str.slice(1)}`;

const getDisplayedName = user => {
  const [firstname, lastName] = user.name.split(' ');

  if (lastName && lastName.length > 0) {
    return `${firstname} ${lastName.split('')?.[0].toUpperCase() || ''}.`;
  }

  return `${firstname}`;
};

const getInitials = user => {
  const [firstname, lastName] = user.name.split(' ');
  const initials = `${firstname.charAt(0).toUpperCase()}${
    lastName && lastName.length > 0
      ? lastName.charAt(0).toUpperCase()
      : firstname.charAt(1).toUpperCase()
  }`;

  return initials;
};

const parseNumber = value => parseFloat(parseFloat(`${value}`).toFixed(2));

const INVOICES_STATUS = {
  paid: 'Payée',
  pending: 'En attente',
  sent: 'Envoyée',
  cancelled: 'Annulée',
};

const API = {
  backend: {
    computeDeliveryFees: 'api/order/deliveryFees/compute',
    createOrder: 'api/order/create',
    confirmOrderDiscount: 'api/order/discount/confirm',
    cancelOrderDiscountUsage: 'api/order/discount/cancel',
    sendCode: 'api/verify/send',
    verifyCode: 'api/verify/validation',
  },
  stripe: {
    listPaymentMethods: 'stripeApi/paymentMethods/list',
    createPaymentIntent: 'stripeApi/paymentIntents/create',
    capturePaymentIntent: 'stripeApi/paymentIntents/capture',
    cancelPaymentIntent: 'stripeApi/paymentIntents/cancel',
    attachPaymentMethod: 'stripeApi/paymentMethod/attach',
    deletePaymentMethod: 'stripeApi/paymentMethod/delete',
  },
};

export {
  CATEGORIES,
  capitalize,
  plurialize,
  APP_FEES,
  AREAS_FEES,
  getDisplayedName,
  getInitials,
  parseNumber,
  API,
  INVOICES_STATUS,
};

import React from 'react';
import { TextField } from '@material-ui/core';

import { FieldWrapper } from '../../styles/form';

const Input = props => {
  const { wrapperProps, InputLabelProps, ...rest } = props;
  return (
    <FieldWrapper {...wrapperProps}>
      <TextField
        fullWidth
        size="small"
        variant="outlined"
        {...rest}
        InputLabelProps={{
          ...InputLabelProps,
          shrink: true,
        }}
      />
    </FieldWrapper>
  );
};

const InputWithRef = React.forwardRef((props, ref) => {
  const { wrapperProps, InputLabelProps, ...rest } = props;
  return (
    <FieldWrapper {...wrapperProps}>
      <TextField
        inputRef={ref}
        fullWidth
        size="small"
        variant="outlined"
        InputLabelProps={{
          ...InputLabelProps,
          shrink: true,
        }}
        {...rest}
      />
    </FieldWrapper>
  );
});

export { Input, InputWithRef };

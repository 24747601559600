import React from 'react';

import { useTheme } from '../../../../hooks';
import { Button, Text } from '../../../../styleguide';
import computePreparationTime from '../../../../actions/orders/helpers/computePreparationTime';
import { computeFinalPreparationTime } from '../../../../actions/orders/helpers/computeFinalPreparationTime';
import {
  InProgressModalDetails,
  InProgressModalDetailsRow,
  InProgressModalDetailsRowWrapper,
} from '../../../../styles/orders-cards';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import ORDER_STATUS from '../../../../actions/orders/helpers/STATUS';

const InProgressDialog = props => {
  const { isOpen, onClose, onConfirm, order } = props;
  const theme = useTheme();

  React.useEffect(() => {
    if (order.status !== ORDER_STATUS.inProgress) {
      onClose();
    }
  }, [order, onClose]);

  const preparationTime =
    order.estimatedTimeToDeliver || computePreparationTime(order.itemsList);
  const { hours, minutes } = computeFinalPreparationTime(
    order.acceptedAt,
    Date.now()
  );
  const temporaryDisabled = !!order?.inTreatment;

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="order-alert-dialog-title"
    >
      <DialogTitle id="order-alert-dialog-title">
        Terminer la commande
      </DialogTitle>
      <DialogContent>
        <Text size={400}>
          En terminant la commande, vous la marquerez comme prête et le livreur
          viendra la recupérer, tout est prêt ?{' '}
        </Text>
        <InProgressModalDetails>
          <Text size={500}>Récapitulatif temps de préparation : </Text>
          <InProgressModalDetailsRowWrapper>
            <InProgressModalDetailsRow>
              <Text size={400} color={theme.headingColor}>
                Temps de préparation estimé :
              </Text>
              <Text size={500} color={theme.textColor}>
                {` ${preparationTime}`}min{' '}
              </Text>
            </InProgressModalDetailsRow>
            <InProgressModalDetailsRow>
              <Text size={400} color={theme.headingColor}>
                Temps de préparation final :
              </Text>
              <Text size={500} color={theme.primaryColor}>
                {hours > 0 ? `${hours}h${minutes}` : `${minutes}min`}
              </Text>
            </InProgressModalDetailsRow>
          </InProgressModalDetailsRowWrapper>
        </InProgressModalDetails>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} small minimal>
          Attendre
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          small
          disabled={temporaryDisabled}
        >
          Terminer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default InProgressDialog;

import { injectGlobal } from 'styled-components';

// https://www.styled-components.com/docs/api#injectglobal
injectGlobal`
  *:before,
  *:after,
  * {
    box-sizing: border-box;
    font-family: "SF UI Display", -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol" !important;
    font-weight: 500;
    -webkit-tap-highlight-color: rgba(0,0,0,0);
  }

  html,
  body {
    margin: 0;
    padding: 0;
    line-height: 1.4;
  }

  html,
  body,
  #root {
    min-height: 100vh;
  }

  p {
    margin: 0 0 1rem;
  }

  hr {
    border: none;
    height: 1px;
    background: #eee;
    margin: 1rem 0;
  }

  a {
    text-decoration: none !important;
  }

  /* Override react phone input color and border color */
  .react-tel-input .form-control:focus {
    border-color: #34C47C !important;
    box-shadow: 0 0 0 1px #34C47C !important;
  }
  .react-tel-input .form-control:focus+div:before {
    color: #34C47C !important;
  }
  .react-tel-input .form-control.invalid-number+div:before {
    content: 'Erreur' !important;
  }
  .react-tel-input .form-control+div:before {
    content: 'Telephone' !important;
  }

  /* Override react phone code verification input border color */
  .custom-phone-input input {
    border-radius: 0px !important;
  }
  .custom-phone-input input:focus {
    border: 1px solid #34C47C !important;
    caret-color: #34C47C !important;
  }

  .Stripe-CardElement {
    display: block;
    color: '#424770';
    margin: 16px 0 16px 0;
    max-width: 800px;
    padding: 16px;
    font-size: 24px;
    box-shadow: rgba(50, 50, 93, 0.14902) 0px 1px 3px,
      rgba(0, 0, 0, 0.0196078) 0px 1px 0px;
    border: 0;
    outline: 0;
    border-radius: 4px;
    background: white;
  }

  .inline-nav-block-light {
    width: 100%;
    z-index: 10;
    background: white;
    padding: 0;
  }

  .inline-nav-block-dark {
    width: 100%;
    z-index: 10;
    background: #1A1824;
    padding: 0;
    transition: all 300ms;
  }

  .inline-nav-block-active {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06)
  }

  .MuiDataGridMenu-root {
    z-index: 1000;
  }

  div[role="tooltip"] {
    z-index: 1000;
  }
`;

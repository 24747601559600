import React from 'react';

import { SPACING, colors } from '../../styles/constants';
import { useTheme } from '../../hooks';
import { AddressDetailsWrapper } from '../../styles/newOrder';
import { BlockWrapper } from '../../styles/common';
import { LocationIcon } from '../../styleguide/icons';
import { Text } from '../../styleguide';
import AddressModal from './AddressModal';

const AddressLine = props => {
  const { selectedAddress, addresses, setSelectedAddress, lite } = props;
  const [isOpen, setOpen] = React.useState(false);

  const theme = useTheme();

  if (lite) {
    return (
      <BlockWrapper onClick={() => setOpen(true)} theme={theme}>
        <LocationIcon
          margin={`0 ${SPACING[200]} 0 ${SPACING[100]}`}
          size="24px"
          color={colors.green}
        />
        <AddressDetailsWrapper>
          <Text muted size={300} styles={{ display: 'inline-flex' }}>
            {selectedAddress.name}
          </Text>
          <Text
            size={400}
            style={{ color: theme.headingColor, marginTop: SPACING[100] }}
          >
            {selectedAddress.street}, {selectedAddress.postalCode}{' '}
            {selectedAddress.city}
          </Text>
        </AddressDetailsWrapper>
      </BlockWrapper>
    );
  }

  return (
    <>
      <BlockWrapper onClick={() => setOpen(true)} theme={theme}>
        <LocationIcon
          margin={`0 ${SPACING[200]} 0 ${SPACING[100]}`}
          size="24px"
          color={colors.green}
        />
        <AddressDetailsWrapper>
          <Text muted size={300} styles={{ display: 'inline-flex' }}>
            {selectedAddress.name}
          </Text>
          <Text
            size={400}
            style={{ color: theme.headingColor, marginTop: SPACING[100] }}
          >
            {selectedAddress.street}, {selectedAddress.postalCode}{' '}
            {selectedAddress.city}
          </Text>
        </AddressDetailsWrapper>
      </BlockWrapper>

      <AddressModal
        isOpen={isOpen}
        onClose={() => setOpen(false)}
        addresses={addresses}
        setSelectedAddress={setSelectedAddress}
        selectedAddress={selectedAddress}
      />
    </>
  );
};

export default AddressLine;

import React from 'react';
import { toaster } from 'evergreen-ui';
import { IconButton, Switch } from '@material-ui/core';

import { Button, Heading, ItemCard, Input } from '../../../../styleguide';
import {
  editShopItemsCategory,
  updateShopItem,
  editShopItemsAvaibility,
} from '../../../../actions/shopItems';
import { orderedGroupedItems } from '../../../../actions/helpers/orderedGroupedItems';
import { EditIcon, CheckIcon } from '../../../../styleguide/icons';
import {
  ItemsColumnWrapper,
  ItemsListingWrapper,
  ItemsColumnHeader,
} from '../../../../styles/shop';
import { SPACING } from '../../../../styles/constants';
import { useTheme } from '../../../../hooks';
import ItemSettingsMenu from './ItemSettingsMenu';
import PasteModal from './PasteModal';

const ItemsListing = props => {
  const { items, onItemEdit, setConfirm, shop } = props;
  const [categoryEdit, setCategoryEdit] = React.useState(null);
  const [itemToCopy, setItemToCopy] = React.useState(null);

  const theme = useTheme();
  const orderedItems = orderedGroupedItems(items);

  const onClick = item => {
    onItemEdit(item);
  };

  const onSelectItemToCopy = (item, type) => {
    setItemToCopy({
      selected: item,
      type,
    });
  };

  const editItemsCategory = async (itemsToUpdate, menuItemsToUpdate) => {
    try {
      await editShopItemsCategory(
        categoryEdit.new,
        itemsToUpdate,
        menuItemsToUpdate,
        categoryEdit.old
      );
      toaster.success(
        `La catégorie ${categoryEdit.old} a bien était modifiée`,
        {
          description: `${categoryEdit.old} -> ${categoryEdit.new} - Les articles sont maintenant dans la catégorie ${categoryEdit.new}, et les menus incluant cette catégorie sont modifiés`,
        }
      );
      setConfirm(null);
      setCategoryEdit(null);
    } catch (error) {
      toaster.danger(error.message);
      throw new Error(error);
    }
  };

  const onEditCategory = isConfirm => {
    if (isConfirm) {
      if (categoryEdit.old !== categoryEdit.new && !!categoryEdit.new.length) {
        const itemsToUpdate = orderedItems.find(
          e => e.category === categoryEdit.old
        ).items;
        const menuItemsToUpdate = items.filter(
          item =>
            !!item?.categories?.length &&
            item.categories.includes(categoryEdit.old)
        );
        return editItemsCategory(itemsToUpdate, menuItemsToUpdate);
      } else {
        setConfirm(null);
        setCategoryEdit(null);
      }
    } else {
      setConfirm({
        title: `Êtes vous sûr de vouloir modifier la catégorie ${categoryEdit.old} ?`,
        description: 'Tout les produits de cette catégorie seront mis à jour.',
        onConfirm: () => {
          onEditCategory(true);
        },
      });
    }
  };

  const getCategoryAvaibility = (category, isConfirm) => {
    const items = orderedItems.find(e => e.category === category).items;
    const avaibility = items.some(item => !!item.avaibility);
    return avaibility;
  };

  const onChangeCategoryAvaibilities = async (category, isConfirm) => {
    const items = orderedItems.find(e => e.category === category).items;
    const avaibility = items.some(item => !!item.avaibility);

    if (isConfirm) {
      await editShopItemsAvaibility(items, !avaibility);
      toaster.success(
        `La catégorie ${category} est désormais ${
          !avaibility ? 'disponible' : 'indisponible'
        }`
      );
      setConfirm(null);
      return;
    } else {
      setConfirm({
        title: `Souhaitez-vous rendre vos ${category} ${
          !avaibility ? 'disponibles' : 'indisponibles'
        } ?`,
        description: `Tout les produits de cette catégorie seront désormais ${
          !avaibility ? 'disponibles' : 'indisponibles'
        }.`,
        onConfirm: () => {
          onChangeCategoryAvaibilities(category, true);
        },
      });
    }
  };

  const onChangeAvaibility = async item => {
    const currentAvaibility = item.avaibility;
    const newItem = { ...item, avaibility: !currentAvaibility };

    return updateShopItem(newItem);
  };

  return (
    <>
      <ItemsListingWrapper theme={theme}>
        {orderedItems.map((item, index) => (
          <ItemsColumnWrapper key={index}>
            <ItemsColumnHeader color={theme.textColor} theme={theme}>
              <Switch
                name="avaibility"
                checked={getCategoryAvaibility(item.category)}
                onChange={() => onChangeCategoryAvaibilities(item.category)}
                color="primary"
                inputProps={{ 'aria-label': 'primary checkbox' }}
              />
              {!!categoryEdit && categoryEdit?.old === item.category ? (
                <>
                  <Input
                    name="category-edit"
                    value={categoryEdit.new}
                    onChange={e =>
                      setCategoryEdit({
                        old: categoryEdit?.old,
                        new: e.target.value,
                      })
                    }
                  />
                  <IconButton onClick={onEditCategory} style={{ marginTop: 4 }}>
                    <CheckIcon size="16px" color={theme.textColor} />
                  </IconButton>
                </>
              ) : (
                <>
                  <Heading
                    color={theme.headingColor}
                    size={700}
                    styles={{
                      width: '150px',
                      whiteSpace: 'nowrap',
                      overflow: 'hidden',
                      textOverflow: 'ellipsis',
                    }}
                  >
                    {item.category}
                  </Heading>
                  <IconButton
                    onClick={() =>
                      setCategoryEdit({
                        old: item.category,
                        new: item.category,
                      })
                    }
                    style={{ marginTop: 4 }}
                  >
                    <EditIcon size="16px" color={theme.textColor} />
                  </IconButton>
                </>
              )}
            </ItemsColumnHeader>
            {item.items.map(item => (
              <ItemCard
                withoutPartnerBadge
                key={item.id}
                item={item}
                withChip
                style={{ margin: '0 auto' }}
                actionsChildren={
                  <>
                    <Switch
                      name="avaibility"
                      checked={!!item.avaibility}
                      onChange={() => onChangeAvaibility(item)}
                      color="primary"
                      inputProps={{ 'aria-label': 'primary checkbox' }}
                    />
                    <Button
                      small
                      primary
                      onClick={() => onClick(item)}
                      styles={{ marginRight: SPACING[100] }}
                    >
                      Modifier
                    </Button>
                    <ItemSettingsMenu
                      item={item}
                      setConfirm={setConfirm}
                      shop={shop}
                      onChangeAvaibility={onChangeAvaibility}
                      onCopyOptions={() => onSelectItemToCopy(item, 'options')}
                      onCopySupplements={() =>
                        onSelectItemToCopy(item, 'supplements')
                      }
                    />
                  </>
                }
              />
            ))}
          </ItemsColumnWrapper>
        ))}
      </ItemsListingWrapper>
      {!!itemToCopy && (
        <PasteModal
          isOpen={!!itemToCopy}
          onClose={() => setItemToCopy(null)}
          items={items}
          type={itemToCopy.type}
          selected={itemToCopy.selected}
        />
      )}
    </>
  );
};

export default ItemsListing;

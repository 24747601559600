import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

import { prepareDocForUpdate } from '../helpers/firestoreHelpers';
import { parseNumber } from '../../common';

const setShopAsPartner = async (shopID, isPartner, percentage) => {
  // console.log('Set shop partner called', { shopID, isPartner });
  ReactGA.event({
    category: 'Shop',
    action: 'Set shop partner',
  });

  const updatedShopData = {
    isPartner: isPartner,
    percentage: !!percentage ? parseNumber(percentage) : isPartner ? 17.5 : 20,
  };

  try {
    const shopItemsSnapshot = await Firebase.firestore()
      .collection('shopItems')
      .where('shopID', '==', shopID)
      .get();

    await Firebase.firestore()
      .collection('shops')
      .doc(shopID)
      .update(prepareDocForUpdate(updatedShopData));

    if (!shopItemsSnapshot.empty) {
      const shopItems = shopItemsSnapshot.docs.map(snapshot => snapshot.data());
      await Promise.all(
        shopItems.map(async item => {
          const updatedItem = {
            ...item,
            shopIsPartner: isPartner,
          };
          await Firebase.firestore()
            .collection('shopItems')
            .doc(updatedItem.id)
            .update(prepareDocForUpdate(updatedItem));
        })
      );
    }
  } catch (error) {
    console.error(`Whoops, couldn't update the user: ${error.message}`, {
      shopID,
      updatedShopData,
    });
  }
};

const updateShop = data => {
  // console.log('Update shop called', { data });
  ReactGA.event({
    category: 'Shop',
    action: 'Update shop',
  });

  try {
    const updatedShopData = {
      ...data,
      ...(!!data.percentage
        ? { percentage: parseNumber(data.percentage) }
        : {}),
    };

    return Firebase.firestore()
      .collection('shops')
      .doc(data.id)
      .update(prepareDocForUpdate(updatedShopData));
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

export { updateShop, setShopAsPartner };

import React from 'react';
import { useHistory } from 'react-router-dom';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  Container,
  Text,
  SimpleHoverableAction,
  ExternalHoverableLink,
  HoverableLink,
} from '../../styleguide';
import { SPACING } from '../../styles/constants';
import { Page, EmptyPage } from '../common';
import { logOut } from '../../actions/login';
import { useTheme, useSession } from '../../hooks';
import { LogoutIcon } from '../../styleguide/icons';
import Mail from '../../assets/images/mail.svg';

const getMinutesBetweenDates = (startDate, endDate) => {
  const diff = endDate - startDate;
  return diff / 6000;
};

const getDisabledLS = () => {
  const lsValue = window.localStorage.getItem('delyzon-mailConfirm-disabled');

  return lsValue ? lsValue.split('_')[0] : null;
};

const NotVerifiedPage = props => {
  const theme = useTheme();
  const history = useHistory();
  const { user, authUser } = useSession();
  const { t } = useTranslation(['auth', 'common']);

  const disabledLS = getDisabledLS();

  const isDisabled = disabledLS
    ? getMinutesBetweenDates(disabledLS, Date.now()) < 60
    : false;

  const [disabled, setDisabled] = React.useState(isDisabled);

  const buttonContent = !disabledLS
    ? t('auth:email:cta_send')
    : disabled
    ? t('auth:email:cta_sended')
    : t('auth:email:cta_resend');

  const onClick = () => {
    window.localStorage.setItem(
      'delyzon-mailConfirm-disabled',
      `${Date.now()}_${authUser.uid}`
    );
    setDisabled(true);
    return authUser.sendEmailVerification();
  };

  React.useEffect(() => {
    if (!!user.isVerified || !!authUser.emailVerified) {
      history.push('/');
    }
  }, [user, authUser, history]);

  return (
    <Page
      name={t('auth:email:title')}
      navbarLeftChild={<></>}
      navbarRightChild={
        <IconButton aria-label="retour" onClick={logOut}>
          <LogoutIcon color={theme.headingColor} size="16px" />
        </IconButton>
      }
    >
      <Container>
        <EmptyPage
          title={t('auth:email:title')}
          details={
            <div>
              {!!disabledLS ? (
                <>
                  <div style={{ paddingBottom: SPACING[100] }}>
                    <Text muted>
                      {t('auth:email:subtitle1')} <b>{t('auth:email:spams')}</b>
                      {', '}
                      {t('auth:email:subtile2')}{' '}
                      <b>{t('auth:email:subject')}</b>{' '}
                      {t('auth:email:subtile3')}
                    </Text>
                  </div>
                  <div>
                    <Text muted>{t('auth:email:subtile4')} </Text>
                    <SimpleHoverableAction
                      muted
                      onClick={() => window.location.reload()}
                      color={theme.primaryColor}
                    >
                      {t('auth:email:reload')}{' '}
                    </SimpleHoverableAction>
                    <Text muted>{t('auth:email:subtile5')}</Text>
                    {' 😉'}
                  </div>
                  <div>
                    <ExternalHoverableLink
                      muted
                      href="https://m.me/108163771705869"
                      styles={{ fontSize: '14px' }}
                    >
                      {t('auth:email:contact_us')}
                    </ExternalHoverableLink>
                    <Text muted> {t('auth:email:contact_label')}</Text>
                    {' ⛑'}
                  </div>
                </>
              ) : (
                <div style={{ paddingBottom: SPACING[100] }}>
                  <Text muted>{t('auth:email:cta_subtitle')}</Text>
                </div>
              )}

              <div style={{ paddingTop: SPACING[200] }}>
                <Text muted>{t('auth:email:sms_activation')} </Text>
                <HoverableLink
                  to="/activation/phone"
                  color={theme.primaryColor}
                  style={{ fontSize: '14px' }}
                >
                  {t('auth:email:sms_activation_link')}
                </HoverableLink>
              </div>
            </div>
          }
          illustration={Mail}
          buttonContent={buttonContent}
          action={onClick}
          container
          buttonProps={{
            disabled: disabled,
            minimal: !!disabledLS,
            primary: !disabledLS,
          }}
        />
      </Container>
    </Page>
  );
};

export default NotVerifiedPage;

import React from 'react';

import { Heading } from '../../../styleguide';
import { TabContentWrapper } from '../../../styles/shop';
import {
  OrdersListWrapper,
  EmptyNewOrders,
} from '../../../styles/orders-cards';
import TabPanel from '../../shop/tabs/TabPanel';
import { DeliveredCard } from './Cards';
import EmptyTab from './EmptyTab';

const EndedTab = props => {
  const { value, orders } = props;

  const hasExistingOrders = !!orders.length;

  return (
    <TabPanel value={value} index={3}>
      <TabContentWrapper>
        {hasExistingOrders ? (
          <>
            <Heading size={800}>Les commandes terminés</Heading>

            <OrdersListWrapper>
              {orders.map(order => {
                return <DeliveredCard key={order.id} order={order} />;
              })}
            </OrdersListWrapper>
          </>
        ) : (
          <EmptyNewOrders>
            <EmptyTab
              title={'Pas de nouvelle terminé'}
              description={
                'Vous retrouverez ici toutes les commandes que vous avez livrez 🎉'
              }
            />
          </EmptyNewOrders>
        )}
      </TabContentWrapper>
    </TabPanel>
  );
};

export default EndedTab;

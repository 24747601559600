import styled from 'styled-components';

import { SPACING } from './constants';

const RowsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const RowWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding: ${SPACING[300]} ${SPACING[100]};
  border-bottom: 1px solid ${props => props.theme.lightGray};
`;

const RowContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: ${SPACING[400]} 0;
`;

const HeaderTabRow = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export { RowsContainer, RowWrapper, RowContent, HeaderTabRow };

const sizes = {
  mobile: '375px',
  mobileLg: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
  desktop: '2560px',
};

const devices = {
  mobileM: `(min-width: ${sizes.mobile})`,
  mobileLG: `(min-width: ${sizes.mobileLg})`,
  tablet: `(min-width: ${sizes.tablet})`,
  laptop: `(min-width: ${sizes.laptop})`,
  laptopL: `(min-width: ${sizes.laptopL})`,
  desktop: `(min-width: ${sizes.desktop})`,
};

export { sizes, devices };

import React from 'react';
import { useHistory } from 'react-router-dom';

import { EmptyPage } from '../../common';
import {
  TabContentWrapper,
  EmptyTabWrapper,
  ItemButtonsWrapper,
} from '../../../styles/shop';
import { RowsContainer, RowWrapper } from '../../../styles/shoptabs';
import { SPACING } from '../../../styles/constants';
import { Heading, Button } from '../../../styleguide';
import { OrdersIcon } from '../../../styleguide/icons';
import { useTheme } from '../../../hooks';
import TabPanel from '../../shop/tabs/TabPanel';

const OverviewTab = props => {
  const { value } = props;
  const theme = useTheme();
  const history = useHistory();
  const orders = null;

  const onRedirectToBilling = () => {
    history.push('/rusher/billing');
  };

  return (
    <TabPanel value={value} index={0}>
      <ItemButtonsWrapper theme={theme}>
        <Button
          small
          primary
          onClick={onRedirectToBilling}
          startIcon={<OrdersIcon color={theme.white} size="24px" />}
        >
          Mes factures
        </Button>
      </ItemButtonsWrapper>
      <TabContentWrapper>
        <RowsContainer>
          <RowWrapper theme={theme}>
            <Heading size={800}>Vos paiments</Heading>

            {!orders ? (
              <EmptyTabWrapper>
                <EmptyPage
                  titleSize={700}
                  title="Vous n'avez pas encore reçu de paiement"
                  description="Pour le moment, vous n'avez terminé aucune commande, donc vous n'avez pas encore reçu de paiement 💸"
                  buttonContent={'Créer une facture'}
                  action={onRedirectToBilling}
                  containerProps={{
                    padding: `${SPACING[300]} ${SPACING[100]}`,
                  }}
                />
              </EmptyTabWrapper>
            ) : null}
          </RowWrapper>
          <RowWrapper theme={theme}>
            <Heading size={800}>Statistiques commandes</Heading>

            {!orders ? (
              <EmptyTabWrapper>
                <EmptyPage
                  titleSize={700}
                  withoutButton
                  title="Vous n'avez pas encore livré de commande"
                  description="Pour le moment, vous n'avez livré aucune commande, mais cela ne va pas tarder 🙃"
                />
              </EmptyTabWrapper>
            ) : null}
          </RowWrapper>
        </RowsContainer>
      </TabContentWrapper>
    </TabPanel>
  );
};

export default OverviewTab;

import React from 'react';
import { Chip } from '@material-ui/core';

import Heading from './heading';
import PartnerBadge from './partner-badge';
import Text from './text';
import {
  ItemWrapper,
  ItemBgImage,
  ItemContentWrapper,
  ItemContentHeaderWrapper,
  ItemContentButtonsWrapper,
  ItemBgImageChipWrapper,
} from '../styles/shop';
import { useTheme } from '../hooks';

const ShopCard = props => {
  const {
    shop,
    styles,
    actionsChildren,
    onClick,
    truncatedDescription,
  } = props;
  const theme = useTheme();

  const city = shop.address.city.replace(/\d{5}/, '');

  const getTruncatedDescription = text => {
    const max = 134;
    return text.length > max ? text.slice(0, max) + '...' : text;
  };

  return (
    <ItemWrapper
      theme={theme}
      color={theme.textColor}
      style={styles || {}}
      size="lg"
      {...(onClick ? { onClick } : {})}
    >
      <ItemBgImage imageSRC={shop.bannerURL} theme={theme} size="lg">
        <ItemBgImageChipWrapper spaceBetween={!!shop.isPartner}>
          {shop.isPartner ? <PartnerBadge theme={theme} /> : null}
          <Chip
            label={`${shop.available ? 'Ouvert' : 'Fermé'}`}
            color={shop.available ? 'primary' : 'secondary'}
          />
        </ItemBgImageChipWrapper>
      </ItemBgImage>
      <ItemContentWrapper>
        <ItemContentHeaderWrapper theme={theme}>
          <Heading size={500}>{shop.name}</Heading>
          <Text muted>{city}</Text>
        </ItemContentHeaderWrapper>
        <Text muted styles={{ whiteSpace: 'pre-line' }}>
          {!!truncatedDescription
            ? getTruncatedDescription(shop.description)
            : shop.description}
        </Text>
      </ItemContentWrapper>
      {actionsChildren ? (
        <ItemContentButtonsWrapper>{actionsChildren}</ItemContentButtonsWrapper>
      ) : null}{' '}
    </ItemWrapper>
  );
};

export default ShopCard;

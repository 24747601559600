import { Container } from '@gitbook/unstated';

class ThemeContainer extends Container {
  state = {
    theme: 'light',
  };

  updateTheme(newTheme) {
    this.setState({ theme: newTheme });
  }
}

export default ThemeContainer;

import React from 'react';

import {
  ListHeaderWrapper,
  BasicColumnBlock,
  ListHeaderActionsWrapper,
} from '../../../styles/common';
import { SPACING } from '../../../styles/constants';
import { useTheme } from '../../../hooks';
import { Heading, Button } from '../../../styleguide';
import InvoiceResumeTemplate from './InvoiceResumeTemplate';

const NewInvoiceResume = props => {
  const {
    disabled,
    rusher,
    onSubmitNewInvoice,
    invoice,
    displayList,
    now,
  } = props;
  const theme = useTheme();

  if (disabled || displayList) {
    return (
      <BasicColumnBlock justify="flex-start">
        <ListHeaderWrapper justify="flex-start">
          <Heading
            size={500}
            styles={{ padding: `${SPACING[100]} 0` }}
            color={theme.textColor}
          >
            2 - Récapitulatif de votre facture
          </Heading>
        </ListHeaderWrapper>
      </BasicColumnBlock>
    );
  }

  return (
    <BasicColumnBlock justify="flex-start">
      <ListHeaderWrapper
        style={{ width: '100%' }}
        {...(displayList
          ? { justify: 'flex-start' }
          : { justify: 'space-between' })}
      >
        <Heading
          size={500}
          styles={{ padding: `${SPACING[100]} 0` }}
          color={theme.headingColor}
        >
          2 - Récapitulatif de votre facture
        </Heading>

        <ListHeaderActionsWrapper>
          {!displayList ? (
            <Button
              small
              styles={{ margin: SPACING[100] }}
              disabled={disabled}
              onClick={onSubmitNewInvoice}
            >
              Terminer
            </Button>
          ) : null}
        </ListHeaderActionsWrapper>
      </ListHeaderWrapper>

      <InvoiceResumeTemplate
        rusher={rusher}
        invoice={invoice.data}
        displayList={displayList}
        date={now}
        orders={invoice.data.selectedOrders}
      />
    </BasicColumnBlock>
  );
};

export default NewInvoiceResume;

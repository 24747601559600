import React from 'react';

import { Heading, StripeButton, Text } from '../../../../styleguide';
import { RowWrapper, RowContent } from '../../../../styles/shoptabs';
import { useSession, useTheme } from '../../../../hooks';

const { REACT_APP_FIREBASE_CF_BASE_URL } = process.env;

const STRIPE_DASHBOARD_LINK = `${REACT_APP_FIREBASE_CF_BASE_URL}/getStripeDashboardLink`;

const StripeRow = props => {
  const { shop } = props;
  const { user } = useSession();
  const theme = useTheme();

  return (
    <RowWrapper theme={theme}>
      <Heading size={800}>Finance</Heading>

      <RowContent>
        <Text
          muted
          size={500}
          styles={{ maxWidth: '800px', padding: '16px 0' }}
        >
          Toutes les informations relatives au paiements et au virements sont
          intégralements conservés et gérés par stripe. Pour voir vos
          informations, accéder à votre tableau de bord Stripe.
        </Text>

        <a
          href={`${STRIPE_DASHBOARD_LINK}?shopID=${shop.id}&authUserID=${user.id}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <StripeButton content="Dashboard" marginTop="24px" />
        </a>
      </RowContent>
    </RowWrapper>
  );
};

export default StripeRow;

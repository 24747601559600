import React from 'react';
import Firebase from 'firebase/app';
import {
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import { Alert, AlertTitle } from '@material-ui/lab';
import { toaster } from 'evergreen-ui';

import { Page } from '../../common';
import { Backdrop, Container } from '../../../styleguide';
import finishOrder from '../../../actions/orders/finishOrder';
import ORDER_STATUS from '../../../actions/orders/helpers/STATUS';
import groupBy from '../../../actions/helpers/groupBy';
import { Tabs, Tab, Loader } from '../../../styleguide';
import { SPACING } from '../../../styles/constants';
import { useTheme } from '../../../hooks';
import {
  NewsTab,
  InProgressTab,
  DeliveryTab,
  DeliveredTab,
  ReadyTab,
} from './tabs';
import CancelDialog from './dialogs/CancelDialog';
import OrdersHeader from './Header';

const { REACT_APP_SETTINGS_DOC_ID } = process.env;

const AdminOrders = () => {
  const [currentTab, setCurrentTab] = React.useState(0);
  const [cancelModal, displayCancelModal] = React.useState(null);
  const [cancelInProgress, setCancelInProgress] = React.useState(false);

  const theme = useTheme();

  const [orders, loading, error] = useCollectionData(
    Firebase.firestore()
      .collection('orders')
      .where('status', 'in', [
        ORDER_STATUS.inValidation,
        ORDER_STATUS.inProgress,
        ORDER_STATUS.ready,
        ORDER_STATUS.inDelivery,
      ])
      .orderBy('orderAt', 'desc')
      .limit(130),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [endedOrders, endedOrdersLoading, endedOrdersError] = useCollectionData(
    Firebase.firestore()
      .collection('orders')
      .where('status', '==', ORDER_STATUS.delivered)
      .orderBy('orderAt', 'desc')
      .limit(50),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [settings, settingsLoading, settingsError] = useDocumentData(
    Firebase.firestore().doc(`app/${REACT_APP_SETTINGS_DOC_ID}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const onTabChange = (event, value) => {
    setCurrentTab(value);
  };

  const onCancelOrder = async order => {
    try {
      setCancelInProgress(true);
      displayCancelModal(null);
      await finishOrder(order, 'CANCELED', 'admin');
      toaster.success(`Commande annulé ❌`, {
        description: `La commande numéro ${order.id} est desormais annulé`,
      });
      setCancelInProgress(false);
    } catch (error) {
      setCancelInProgress(false);
      toaster.danger(`Une erreur est survenue`, {
        description: `Impossible d'annuler la commande ${order.id}`,
      });
    }
  };

  if (loading || endedOrdersLoading || settingsLoading) {
    return (
      <Loader color={theme.primaryColor} background={theme.backgroundColor} />
    );
  }

  if (error || endedOrdersError || settingsError) {
    throw new Error(
      error.toString() ||
        endedOrdersError.toString() ||
        settingsError.toString()
    );
  }

  const groupedOrders = groupBy(orders, 'status');

  const displayedOrders = {
    news: [],
    inProgress: [],
    ready: [],
    delivery: [],
  };

  if (groupedOrders.length) {
    groupedOrders.forEach(group => {
      if (group.category === ORDER_STATUS.inValidation) {
        displayedOrders.news = [...group.items];
      } else if (group.category === ORDER_STATUS.inProgress) {
        displayedOrders.inProgress = [...group.items];
      } else if (group.category === ORDER_STATUS.ready) {
        displayedOrders.ready = [...group.items];
      } else if (group.category === ORDER_STATUS.inDelivery) {
        displayedOrders.delivery = [
          ...group.items,
          ...displayedOrders.delivery,
        ];
      }
    });
  }

  return (
    <Page name="Dashboard admin">
      <Container justify="flex-start">
        <OrdersHeader settings={settings} />
        <Tabs
          style={{ width: '100%', margin: `${SPACING[300]} 0` }}
          value={currentTab}
          onChange={onTabChange}
        >
          <Tab
            label={
              <span>
                Attente (
                <span
                  style={{
                    color:
                      displayedOrders.news.length > 1 ? theme.red : 'inherit',
                    fontWeight:
                      displayedOrders.news.length > 1 ? 'bold' : 'normal',
                  }}
                >
                  {displayedOrders.news.length}
                </span>
                )
              </span>
            }
          />
          <Tab label={`Préparation (${displayedOrders.inProgress.length})`} />
          <Tab
            label={
              <span>
                Prête (
                <span
                  style={{
                    color:
                      displayedOrders.ready.length >= 1 ? theme.red : 'inherit',
                    fontWeight:
                      displayedOrders.ready.length >= 1 ? 'bold' : 'normal',
                  }}
                >
                  {displayedOrders.ready.length}
                </span>
                )
              </span>
            }
          />
          <Tab label={`Livraison (${displayedOrders.delivery.length})`} />
          <Tab label={`Livré (${endedOrders.length})`} />
        </Tabs>

        {settings.strongPresence ? (
          <Alert
            severity="warning"
            style={{ margin: `${SPACING[300]} auto`, marginBottom: 0 }}
          >
            <AlertTitle>Mode forte affluence activé {`⏱`}</AlertTitle>
            Les clients sont désormais informés du fait que les livraisons des
            commandes sont actuellement retardés.
          </Alert>
        ) : null}

        <NewsTab
          value={currentTab}
          orders={displayedOrders.news}
          onCancelOrder={order => displayCancelModal(order)}
        />
        <InProgressTab
          value={currentTab}
          orders={displayedOrders.inProgress}
          onCancelOrder={order => displayCancelModal(order)}
        />
        <ReadyTab
          value={currentTab}
          orders={displayedOrders.ready}
          onCancelOrder={order => displayCancelModal(order)}
        />
        <DeliveryTab
          value={currentTab}
          orders={displayedOrders.delivery}
          onCancelOrder={order => displayCancelModal(order)}
        />
        <DeliveredTab value={currentTab} orders={endedOrders} />

        {cancelInProgress ? <Backdrop /> : null}

        {cancelModal ? (
          <CancelDialog
            isOpen={!!cancelModal}
            order={cancelModal}
            onClose={() => displayCancelModal(null)}
            cancelOrder={onCancelOrder}
          />
        ) : null}
      </Container>
    </Page>
  );
};

export default AdminOrders;

import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

import createID from '../helpers/createID';
import { prepareDocForCreate } from '../helpers/firestoreHelpers';

export const DAYS = [
  'Lundi',
  'Mardi',
  'Mercredi',
  'Jeudi',
  'Vendredi',
  'Samedi',
  'Dimanche',
];

export const DEFAULT_HOURS = DAYS.map(day => ({
  opening: '11:30',
  closing: '22:00',
  day,
  isOpen: true,
}));

const createShop = data => {
  // console.log('Create shop called', { data });
  ReactGA.event({
    category: 'Shop',
    action: 'Create shop',
  });

  const shop = {
    id: createID(),
    name: data.name,
    description: data.description,
    address: data.address,
    legal: data.legal,
    ownerID: data.ownerID,
    available: false,
    category: data.category,
    logoURL: data.logoURL,
    bannerURL: '',
    stripeCustomerId: '',
    strongPresence: false,
    isValidated: false,
    percentage: 20,
    isPartner: false,
    hours: DEFAULT_HOURS,
  };

  try {
    return Firebase.firestore()
      .collection('shops')
      .doc(shop.id)
      .set(prepareDocForCreate(shop));
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

export default createShop;

import React from 'react';
import { useUnstated } from '@gitbook/unstated';

import SEO from './Seo';
import NavBar from './navbar';
import { useSession } from '../../hooks';
import { ContentWrapper } from '../../styles/page';
import { BasketContainer } from '../../contexts';
import NavDrawer from './NavDrawer';
import FloatingBasketResume from './FloatingBasketResume';

// This Wrapper prevent to refresh the complete page each time the Drawer is open or close.
const NavWrapper = props => {
  const { leftChildren, rightChildren, minimalNav, hasBasket } = props;
  const [drawerOpen, toggleDrawer] = React.useState('');
  const { isLoggedIn, user } = useSession();

  return (
    <>
      <NavBar
        leftChildren={leftChildren}
        rightChildren={rightChildren}
        minimal={minimalNav}
        openMenu={() => toggleDrawer('nav')}
        hasBasket={hasBasket}
      />
      {isLoggedIn && user && (
        <NavDrawer isOpen={drawerOpen === 'nav'} toggleDrawer={toggleDrawer} />
      )}
    </>
  );
};

const Page = props => {
  const {
    children,
    navbarLeftChild,
    navbarRightChild,
    minimalNav = false,
    name,
    withoutSpacing,
    withoutWidget,
    isShopProfilPage,
  } = props;
  // const { isLoggedIn } = useSession();
  const basketContainer = useUnstated(BasketContainer);

  // console.log(`render Page: ${name} - user loggedIn: ${isLoggedIn}`, {
  //   basketContainer,
  // });

  return (
    <>
      <SEO title={name} />
      <NavWrapper
        leftChildren={navbarLeftChild}
        rightChildren={navbarRightChild}
        minimal={minimalNav}
        hasBasket={!basketContainer.isEmpty}
      />
      <ContentWrapper withoutSpacing={withoutSpacing}>
        {children}

        {!basketContainer.isEmpty && !withoutWidget ? (
          <FloatingBasketResume isShopProfilPage={isShopProfilPage} />
        ) : null}
      </ContentWrapper>
    </>
  );
};

const PageWithoutContainer = props => {
  const {
    children,
    navbarLeftChild,
    navbarRightChild,
    minimalNav = false,
    name,
    withoutSpacing,
  } = props;
  // const { isLoggedIn } = useSession();

  // console.log(
  //   `render PageWithoutContainer: ${name} - user loggedIn: ${isLoggedIn}`
  // );

  return (
    <>
      <SEO title={name} />
      <NavWrapper
        leftChildren={navbarLeftChild}
        rightChildren={navbarRightChild}
        minimal={minimalNav}
      />
      <ContentWrapper withoutSpacing={withoutSpacing}>
        {children}
      </ContentWrapper>
    </>
  );
};

export { PageWithoutContainer, Page };

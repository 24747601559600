import React from 'react';
import { useFormik } from 'formik';

import { Button, Heading, Input, Text } from '../../../styleguide';
import { SignupFormPolicyText } from '../../../styles/signup';
import { FormWrapper, ButtonsWrapper } from '../../../styles/form';
import { signupRusherName } from '../../../forms';

const NameForm = props => {
  const { onRusherUpdate, onNext } = props;

  const formik = useFormik({
    initialValues: {
      name: '',
    },
    validationSchema: signupRusherName,
    onSubmit: values => {
      const { name } = values;
      onRusherUpdate({ name });
      onNext();
    },
  });

  const canSubmit = !!formik.values.name;

  return (
    <>
      <Heading size={900} centered>
        Nom et description
      </Heading>
      <FormWrapper onSubmit={formik.handleSubmit}>
        <Input
          name="name"
          label="Nom de livreur"
          required
          placeholder="John"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />

        <SignupFormPolicyText>
          <Text size={300} muted>
            Le nom sera public, il sera utilisé sur les commandes.
          </Text>
        </SignupFormPolicyText>

        <ButtonsWrapper>
          <Button type="submit" disabled={!canSubmit}>
            Continuer
          </Button>
        </ButtonsWrapper>
      </FormWrapper>
    </>
  );
};

export default NameForm;

import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';

import { deleteUserToken } from '../messaging';

const logOut = async authUser => {
  ReactGA.event({
    category: 'User',
    action: 'Log out',
  });

  try {
    if (authUser && authUser.emailVerified) {
      const { uid } = authUser;
      const isNotificationAvailable =
        !!window.Notification && !!window.Notification.permission;
      const notificationPermission = isNotificationAvailable
        ? window.Notification.permission === 'granted'
        : false;

      if (notificationPermission) {
        await deleteUserToken(uid);
      }
    }

    await Firebase.auth().signOut();

    Sentry.configureScope(scope => {
      scope.setUser(null);
    });
    window.location.reload(true);
    // return false to prevent default behavior
    return false;
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

export default logOut;

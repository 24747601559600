import React from 'react';
import { useTheme } from '../../../../hooks';
import { Button, Text, Input } from '../../../../styleguide';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
} from '@material-ui/core';
import { SPACING } from '../../../../styles/constants';
import ORDER_STATUS from '../../../../actions/orders/helpers/STATUS';

const CancelDialog = props => {
  const { isOpen, onClose, cancelOrder, order } = props;
  const [inputValue, setInputValue] = React.useState('');
  const theme = useTheme();
  const temporaryDisabled = !!order?.inTreatment;

  React.useEffect(() => {
    if (
      order.status === ORDER_STATUS.canceled ||
      order.status === ORDER_STATUS.delivered
    ) {
      onClose();
    }
  }, [order, onClose]);

  const onConfirm = () => {
    if (!!inputValue && inputValue === order.id) {
      cancelOrder(order);
    }
  };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="order-alert-dialog-title"
    >
      <DialogTitle id="order-alert-dialog-title">
        Annulation de la commande
      </DialogTitle>
      <DialogContent>
        <Text size={400}>
          Vous êtes sur le point d'annuler la commande numéro {order.id} pour
          l'établissement{' '}
          <span style={{ color: theme.primaryColor }}>{order.shop.name}</span>.
        </Text>

        <Divider style={{ margin: `${SPACING[200]} 0` }} />

        <Text size={400}>
          Pour supprimer la commande, veuillez entrer l'id de la commande pour
          confirmer <br />
          ID: <span style={{ color: theme.textColor }}>{order.id}</span>
        </Text>
        <Input
          wrapperProps={{ style: { marginTop: SPACING[200] } }}
          label={'Id de la commande'}
          value={inputValue}
          onChange={e => setInputValue(e.target.value)}
          placeholder={order.id}
        />
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} small minimal>
          Retour
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          styles={
            !inputValue || inputValue !== order.id
              ? {}
              : { backgroundColor: theme.red }
          }
          small
          disabled={!inputValue || inputValue !== order.id || temporaryDisabled}
        >
          Annuler la commande
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default CancelDialog;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { Chip, Avatar } from '@material-ui/core';
import { toaster } from 'evergreen-ui';
import { Alert, AlertTitle } from '@material-ui/lab';

import {
  Button,
  Heading,
  Container,
  Tabs,
  Tab,
  ConfirmDialog,
} from '../../styleguide';
import { SPACING } from '../../styles/constants';
import {
  OpenIcon,
  ClosedIcon,
  OrdersIcon,
  ShopIcon,
} from '../../styleguide/icons';
import { MainShopHeaderWrapper, OrdersActionsWrapper } from '../../styles/shop';
import { Page } from '../common';
import { useSession, useTheme } from '../../hooks';
import { updateShop } from '../../actions/shop';
import { MenusTab, OverviewTab, SettingsTab } from './tabs';

const ShopDashboard = props => {
  const { shop } = props;
  const [currentTab, setCurrentTab] = React.useState(0);
  const [confirm, setConfirm] = React.useState(null);

  const theme = useTheme();
  const { user } = useSession();
  let history = useHistory();

  const onToggleAvailability = canToggle => {
    const currentAvailability = shop.available;

    if (canToggle) {
      setConfirm(null);
      updateShop({ ...shop, available: !currentAvailability }).then(() => {
        if (currentAvailability) {
          toaster.danger(`Votre établissement est désormais fermé.`);
        } else {
          toaster.success(`Votre établissement est désormais ouvert.`);
        }
      });
    } else {
      const titleContext = currentAvailability ? 'fermer' : 'ouvrir';
      const description = currentAvailability
        ? 'En fermant votre restaurant, vous ne recevrez plus de commandes.'
        : 'En ouvrant votre restaurant, vous recevrez des commandes.';
      setConfirm({
        title: `Voulez vous ${titleContext} votre établissement`,
        description,
        onConfirm: () => {
          onToggleAvailability(true);
        },
      });
    }
  };

  const onTabChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <Page name="Mon établissement">
      <Container justify="flex-start">
        <MainShopHeaderWrapper background={theme.backgroundColor}>
          <Avatar src={shop.logoURL} style={{ width: 50, height: 50 }} />
          <Heading
            size={800}
            styles={{
              marginRight: SPACING[100],
              textShadow:
                user.settings.theme !== 'light'
                  ? `2px 2px 2px ${theme.black}`
                  : 'none',
            }}
            centered
            {...(shop.bannerURL ? { color: theme.primaryColor } : {})}
          >
            {shop.name}
          </Heading>
          <Chip
            label={`${shop.available ? 'Ouvert' : 'Fermé'}`}
            clickable
            color={shop.available ? 'primary' : 'secondary'}
            variant={'default'}
            onClick={() => onToggleAvailability()}
            deleteIcon={shop.available ? <OpenIcon /> : <ClosedIcon />}
            onDelete={() => onToggleAvailability()}
          />
        </MainShopHeaderWrapper>

        <OrdersActionsWrapper>
          <Button
            small
            primary
            onClick={() =>
              history.push(
                `/shopDashboard/orders${
                  user.role === 'admin' ? `/${shop.id}` : ''
                }`
              )
            }
            startIcon={<OrdersIcon color={theme.white} size="16px" />}
            styles={{ marginRight: '8px', marginBottom: '8px' }}
          >
            Écran commandes
          </Button>
          <Button
            small
            minimal
            onClick={() => history.push(`/shop/${shop.id}`)}
            startIcon={<ShopIcon color={theme.headingColor} size="16px" />}
            styles={{ marginBottom: '8px' }}
          >
            Voir le profil
          </Button>
        </OrdersActionsWrapper>

        <Tabs
          style={{ width: '100%', margin: `${SPACING[300]} 0` }}
          value={currentTab}
          onChange={onTabChange}
        >
          <Tab label="Ma Carte" />
          <Tab label="Informations" />
          <Tab label="Paramétres" />
        </Tabs>

        {!shop.isValidated ? (
          <Alert severity="info" style={{ margin: '8px auto' }}>
            <AlertTitle>En attente de validation</AlertTitle>
            Une fois votre compte validé par notre équipe, vous pourrez recevoir
            vos premières commandes 💸
          </Alert>
        ) : null}

        <MenusTab value={currentTab} shop={shop} setConfirmOpen={setConfirm} />
        <OverviewTab value={currentTab} shop={shop} />
        <SettingsTab value={currentTab} shop={shop} />
      </Container>
      {confirm && (
        <ConfirmDialog
          isOpen={!!confirm}
          onClose={() => setConfirm(null)}
          onConfirm={confirm.onConfirm}
          title={confirm.title}
          description={confirm.description}
          cantConfirm={!shop.isValidated && user.role !== 'admin'}
          info={
            !shop.isValidated ? (
              <Alert severity="info" style={{ margin: '8px auto' }}>
                <AlertTitle>En attente de validation</AlertTitle>
                Une fois votre compte validé par notre équipe, vous pourrez
                recevoir vos premières commandes 💸
              </Alert>
            ) : null
          }
        />
      )}
    </Page>
  );
};

export default ShopDashboard;

import React from 'react';
import { InputAdornment, Switch, FormControlLabel } from '@material-ui/core';
import { useFormik } from 'formik';

import { FormWrapper } from '../../../../../styles/form';
import {
  HeaderDialog,
  ContentBlockDialog,
  HeaderDialogFlexBlock,
} from '../../../../../styles/admin';
import { useTheme, useSession } from '../../../../../hooks';
import { SPACING } from '../../../../../styles/constants';
import {
  FlexFieldsWrapper,
  AccordionDetailsContent,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from '../../../../../styles/shop';
import {
  Input,
  FullscreenDialog,
  Heading,
  Text,
} from '../../../../../styleguide';
import { ExpandIcon, PercentageIcon } from '../../../../../styleguide/icons';
import { discountSchema } from '../../../../../forms';

const DiscountModal = props => {
  const { onSubmit, onClose, existingDiscount, open, isLoading } = props;
  const theme = useTheme();
  const { user } = useSession();

  const formik = useFormik({
    initialValues: !!existingDiscount
      ? {
          adminUserID: existingDiscount.createdBy,
          code: existingDiscount.code,
          deliveryFeesValue: existingDiscount.deliveryFeesValue,
          appFeesValue: existingDiscount.appFeesValue,
          active: existingDiscount.active,
          minTotal: existingDiscount.minTotal || 0,
          exclusive: existingDiscount.exclusive,
          maxUsageCount: existingDiscount.maxUsageCount || 0,
          maxUsageCountByUser: existingDiscount.maxUsageCountByUser || 0,
          exclusiveTo: existingDiscount?.exclusiveTo?.length
            ? existingDiscount?.exclusiveTo
                ?.map(uid => uid.replace(/\s/g, ''))
                .join(', ')
            : '',
          exclusiveShop: existingDiscount.exclusiveShop || '',
        }
      : {
          adminUserID: user.id,
          code: '',
          deliveryFeesValue: 0,
          appFeesValue: 0,
          active: false,
          minTotal: 0,
          exclusive: false,
          maxUsageCount: 0,
          maxUsageCountByUser: 0,
          exclusiveTo: '',
          exclusiveShop: '',
        },
    validationSchema: discountSchema,
    onSubmit: values => {
      const discount = {
        ...values,
      };
      onSubmit(discount, existingDiscount?.id);
      onClose();
    },
  });

  const canSubmit =
    !!formik.dirty && !formik.isSubmitting && !formik.isValidating;

  return (
    <FullscreenDialog
      isOpen={open}
      onClose={onClose}
      onConfirm={formik.handleSubmit}
      title={
        !!existingDiscount?.code
          ? `Modification promotion ${existingDiscount?.code}`
          : "Création d'une promotion"
      }
      cantConfirm={!canSubmit}
      isLoading={isLoading}
      theme={theme}
      buttonText={!!existingDiscount?.code ? 'Modifier' : 'Créer'}
    >
      <HeaderDialog>
        <Text muted size={400}>
          Lors de la création de votre promotion, vous pouvez lui attribuer un
          code, une réduction en pourcentage, une date de début et de fin, et
          définir si elle est active ou non.
        </Text>
      </HeaderDialog>

      <FormWrapper
        style={{ margin: '0 auto', padding: 0, paddingTop: SPACING[100] }}
        maxWidth="1260px"
      >
        <Accordion square style={{ width: '100%' }} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandIcon size="16px" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Heading size={500}>Configuration</Heading>
          </AccordionSummary>
          <AccordionDetails>
            <AccordionDetailsContent>
              <FlexFieldsWrapper align="stretch">
                <ContentBlockDialog theme={theme}>
                  <HeaderDialogFlexBlock
                    style={{ paddingBottom: SPACING[100] }}
                  >
                    <Heading size={500} styles={{ paddingRight: SPACING[100] }}>
                      Code
                    </Heading>
                  </HeaderDialogFlexBlock>
                  <HeaderDialogFlexBlock>
                    <Input
                      type="text"
                      name="code"
                      wrapperProps={{ justify: 'flex-start', maxWidth: 400 }}
                      label="Code"
                      size="small"
                      variant="outlined"
                      required
                      placeholder="ex: DLYZN20"
                      fullWidth
                      value={formik.values.code}
                      onChange={formik.handleChange}
                      style={{ maxWidth: '220px' }}
                      error={formik.touched.code && Boolean(formik.errors.code)}
                    />
                  </HeaderDialogFlexBlock>
                </ContentBlockDialog>
                <ContentBlockDialog theme={theme}>
                  <HeaderDialogFlexBlock
                    style={{ paddingBottom: SPACING[100] }}
                  >
                    <Heading size={500} styles={{ paddingRight: SPACING[100] }}>
                      État
                    </Heading>
                  </HeaderDialogFlexBlock>
                  <FormControlLabel
                    control={
                      <Switch
                        name="active"
                        checked={formik.values.active}
                        onChange={formik.handleChange}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        error={
                          formik.touched.active && Boolean(formik.errors.active)
                        }
                      />
                    }
                    label={
                      <Text size={400} muted>
                        Active
                      </Text>
                    }
                  />
                </ContentBlockDialog>
                <ContentBlockDialog theme={theme}>
                  <HeaderDialogFlexBlock
                    style={{ paddingBottom: SPACING[100] }}
                  >
                    <Heading size={500} styles={{ paddingRight: SPACING[100] }}>
                      Établissement exclusif (optionnel)
                    </Heading>
                  </HeaderDialogFlexBlock>
                  <HeaderDialogFlexBlock>
                    <Input
                      name="exclusiveShop"
                      wrapperProps={{ justify: 'flex-start', maxWidth: 400 }}
                      label="ID établissement"
                      size="small"
                      variant="outlined"
                      placeholder="shopID"
                      fullWidth
                      value={formik.values.exclusiveShop}
                      onChange={formik.handleChange}
                      style={{ maxWidth: '240px' }}
                      error={
                        formik.touched.exclusiveShop &&
                        Boolean(formik.errors.exclusiveShop)
                      }
                    />
                  </HeaderDialogFlexBlock>
                </ContentBlockDialog>
                <ContentBlockDialog theme={theme}>
                  <HeaderDialogFlexBlock
                    style={{ paddingBottom: SPACING[100] }}
                  >
                    <Heading size={500} styles={{ paddingRight: SPACING[100] }}>
                      Montant minimum d'achat (optionnel)
                    </Heading>
                  </HeaderDialogFlexBlock>
                  <HeaderDialogFlexBlock>
                    <Input
                      disabled
                      name="minTotal"
                      wrapperProps={{ justify: 'flex-start' }}
                      label="Montant minimum d'achat"
                      size="small"
                      variant="outlined"
                      placeholder="INACTIF"
                      fullWidth
                      type="number"
                      value={formik.values.minTotal}
                      onChange={formik.handleChange}
                      error={
                        formik.touched.minTotal &&
                        Boolean(formik.errors.minTotal)
                      }
                    />
                  </HeaderDialogFlexBlock>
                </ContentBlockDialog>
              </FlexFieldsWrapper>
              <FlexFieldsWrapper mobileColumn>
                <Input
                  name="deliveryFeesValue"
                  wrapperProps={{ justify: 'flex-start' }}
                  label="Frais de livraison"
                  size="small"
                  variant="outlined"
                  required
                  placeholder="20"
                  fullWidth
                  type="number"
                  value={formik.values.deliveryFeesValue}
                  onChange={formik.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentageIcon size="16px" />
                      </InputAdornment>
                    ),
                  }}
                  error={
                    formik.touched.deliveryFeesValue &&
                    Boolean(formik.errors.deliveryFeesValue)
                  }
                />
                <Input
                  name="appFeesValue"
                  wrapperProps={{ justify: 'flex-start' }}
                  label="Frais de service"
                  size="small"
                  variant="outlined"
                  required
                  placeholder="50"
                  fullWidth
                  type="number"
                  value={formik.values.appFeesValue}
                  onChange={formik.handleChange}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <PercentageIcon size="16px" />
                      </InputAdornment>
                    ),
                  }}
                  error={
                    formik.touched.appFeesValue &&
                    Boolean(formik.errors.appFeesValue)
                  }
                />
              </FlexFieldsWrapper>
              <FlexFieldsWrapper mobileColumn>
                <Input
                  name="maxUsageCountByUser"
                  wrapperProps={{ justify: 'flex-start' }}
                  label="Max usage (UTILISATEUR) - 0 illimité"
                  size="small"
                  variant="outlined"
                  placeholder="Si 0 illimité"
                  fullWidth
                  type="number"
                  value={formik.values.maxUsageCountByUser}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.maxUsageCountByUser &&
                    Boolean(formik.errors.maxUsageCountByUser)
                  }
                />
                <Input
                  name="maxUsageCount"
                  wrapperProps={{ justify: 'flex-start' }}
                  label="Max usage (GLOBAL) - 0 illimité"
                  size="small"
                  variant="outlined"
                  placeholder="Si 0 illimité"
                  fullWidth
                  type="number"
                  value={formik.values.maxUsageCount}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.maxUsageCount &&
                    Boolean(formik.errors.maxUsageCount)
                  }
                />
              </FlexFieldsWrapper>
              <FlexFieldsWrapper mobileColumn>
                <Input
                  name="exclusiveTo"
                  wrapperProps={{ justify: 'flex-start' }}
                  label="Exclusif à (optionnel)"
                  size="small"
                  variant="outlined"
                  placeholder="userID, userID, userID"
                  fullWidth
                  value={formik.values.exclusiveTo}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.exclusiveTo &&
                    Boolean(formik.errors.exclusiveTo)
                  }
                />
              </FlexFieldsWrapper>
            </AccordionDetailsContent>
          </AccordionDetails>
        </Accordion>
      </FormWrapper>
    </FullscreenDialog>
  );
};

export default DiscountModal;

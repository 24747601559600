import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Button from './button';

const ConfirmDialog = props => {
  const {
    isOpen,
    onClose,
    onConfirm,
    title,
    description,
    cantConfirm,
    info,
  } = props;
  const { t } = useTranslation(['common']);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        {info ? info : null}
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} small minimal>
          {t('common:no')}
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          small
          disabled={cantConfirm}
        >
          {t('common:yes')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;

import React from 'react';
import { Avatar } from '@material-ui/core';

import {
  Container,
  Heading,
  LineProperty,
  ClickableCard,
} from '../../../styleguide';
import { LocationIcon, OrdersIcon, CardIcon } from '../../../styleguide/icons';
import { useTheme, useSession } from '../../../hooks';
import {
  AccountHeader,
  AvatarWrapper,
  DetailsWrapper,
  MainWrapper,
  CardLinksWrapper,
} from '../../../styles/account';
import { useTranslation } from 'react-i18next';

const Profile = props => {
  const { setEdition } = props;
  const { user } = useSession();
  const { t } = useTranslation(['common', 'account']);
  const theme = useTheme();

  const [name, lastName] = user.name.split(' ');

  const initials = `${name.charAt(0).toUpperCase()}${
    lastName ? lastName.charAt(0).toUpperCase() : name.charAt(1).toUpperCase()
  }`;

  const onClick = name => {
    setEdition(name);
  };

  return (
    <Container justify="flex-start">
      <MainWrapper padding>
        <AccountHeader>
          <AvatarWrapper color={theme.shadow}>
            <Avatar
              style={{
                backgroundColor: theme.primaryColor,
                color: theme.white,
                width: '100px',
                height: '100px',
                fontSize: '42px',
              }}
            >
              {initials}
            </Avatar>
          </AvatarWrapper>

          <Heading
            size={900}
            styles={{
              textAlign: 'center',
            }}
          >
            {t('account:profile:page_title')}
          </Heading>
        </AccountHeader>

        <CardLinksWrapper>
          <ClickableCard
            icon={<OrdersIcon />}
            to={'/account/orders/history'}
            label={t('common:orders')}
          />
          <ClickableCard
            icon={<LocationIcon />}
            to={'/account/adresses'}
            label={t('common:addresses')}
          />
          <ClickableCard
            icon={<CardIcon />}
            to={'/account/payment_methods'}
            label={t('common:payment_methods')}
          />
        </CardLinksWrapper>

        <DetailsWrapper>
          <LineProperty
            value={user.name}
            label={t('account:profile:name_lastname')}
            onClick={() => onClick('name')}
            buttonContent={t('common:edit')}
          />
          <LineProperty
            value={user.email}
            label={t('account:profile:email')}
            onClick={() => onClick('email')}
            buttonContent={t('common:edit')}
          />
          <LineProperty
            value={user.phone}
            label={t('account:profile:phone')}
            onClick={() => onClick('phone')}
            buttonContent={t('common:edit')}
          />
        </DetailsWrapper>
      </MainWrapper>
    </Container>
  );
};

export default Profile;

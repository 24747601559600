import React from 'react';

import { Heading, Button, Text } from '../../../styleguide';
import { DescriptionWrapper } from '../../../styles/signup';
import { ButtonsWrapper } from '../../../styles/form';

const Welcome = props => {
  const { onNext } = props;

  return (
    <>
      <Heading size={900} centered>
        Création de votre établissement
      </Heading>
      <DescriptionWrapper>
        <Text muted>
          Offrez un service unique aux clients permettant de faire livrer vos
          produits n'importe ou dans la vallée et ce dans les meilleures
          conditions possibles.
        </Text>
      </DescriptionWrapper>
      <ButtonsWrapper>
        <Button styles={{ marginBottom: '8px' }} onClick={onNext}>
          Continuer
        </Button>
      </ButtonsWrapper>
    </>
  );
};

export default Welcome;

import { add } from 'lodash';
import { parseNumber } from '../../../common';

// Compute estimated preparation time with the items.preparationTime values
const computePreparationTime = itemsList => {
  const preparationTimeList = itemsList.map(item =>
    parseNumber(item.item.preparationTime)
  );
  let result = 0;

  preparationTimeList.forEach(
    time => (result = add(parseNumber(result), parseNumber(time)))
  );

  return result;
};

export default computePreparationTime;

import React from 'react';
import Firebase from 'firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';
import { DataGrid } from '@material-ui/data-grid';
import { DateTime } from 'luxon';
import { IconButton } from '@material-ui/core';

import { Container, NavLink, Button } from '../../../../styleguide';
import { ReturnIcon } from '../../../../styleguide/icons';
import DashboardHeader from '../components/common/Header';
import { Page } from '../../../common';
import {
  DashboardPageContainer,
  DashboardPageBlockContainer,
  BlockStatsRow,
  BlockWrapper,
} from '../../../../styles/admin';
import { UserDialog } from '../components/common/Dialogs';
import { updateUser } from '../../../../actions/users';
import { SearchIcon } from '../../../../styleguide/icons';
import { useTheme } from '../../../../hooks';
import StatCard from '../components/common/StatCard';
import { SPACING } from '../../../../styles/constants';
import BlockHeader from '../components/common/BlockHeader';
import SearchModal from '../components/common/SearchModal';
import TableFilters from '../components/common/TableFilters';

const { REACT_APP_STRIPE_DASHBOARD_URL } = process.env;

const columns = [
  {
    field: 'email',
    headerName: 'Email',
    width: 280,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 280,
  },
  {
    field: 'name',
    headerName: 'Nom et prenom',
    width: 200,
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 150,
  },
  {
    field: 'verified',
    headerName: 'Activé',
    width: 150,
  },
  {
    field: 'phone',
    headerName: 'Téléphone',
    width: 200,
  },
  {
    field: 'stripeURL',
    headerName: 'Compte Stripe',
    width: 200,
  },
  {
    field: 'createdAt',
    headerName: 'Crée le',
    width: 200,
  },
  {
    field: 'updatedAt',
    headerName: 'Mis à jour le',
    width: 200,
  },
  {
    field: 'verifiedBy',
    headerName: 'Vérifié par',
    width: 150,
  },
];

const UsersDashboard = () => {
  const [searchDialog, setSearchDialog] = React.useState(false);
  const [editDialog, setEditDialog] = React.useState(null);
  const [displayedCount, setDisplayedCount] = React.useState(50);
  const theme = useTheme();

  const limitTs = React.useMemo(
    () =>
      Firebase.firestore.Timestamp.fromDate(
        new Date(new Date().setDate(new Date().getDate() - 30))
      ),
    []
  );

  const [usersDocs, usersLoading, usersError] = useCollection(
    Firebase.firestore()
      .collection('users')
      .orderBy('createdAt', 'desc')
      .limit(displayedCount),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const onRowClick = params => {
    setEditDialog({ updatedData: null, data: params.row });
  };

  const onConfirmRoleUpdate = async () => {
    if (editDialog.data.role !== editDialog.updatedData.role) {
      await updateUser(
        { role: editDialog.updatedData.role },
        editDialog.data.id
      );
      setEditDialog(null);
    } else {
      setEditDialog(null);
    }
  };

  const {
    rows,
    newUsersCounts,
    newClientsCounts,
    newNonClientsCounts,
    newNonVerifiedCounts,
  } = React.useMemo(() => {
    if (usersLoading || !usersDocs.size || !!usersError) {
      return {
        rows: [],
        newUsersCounts: 0,
        newClientsCounts: 0,
        newNonClientsCounts: 0,
        newNonVerifiedCounts: 0,
      };
    }
    const users = usersDocs.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }));

    const rows = users.map(user => ({
      email: user.email,
      id: user.id,
      name: user.name,
      phone: user.phone,
      role: user.role,
      verified: !!user.isVerified ? '✅' : '🚫',
      verifiedBy: !user.verifiedBy
        ? '--'
        : user.verifiedBy === 'phone'
        ? 'Téléphone'
        : 'Email',
      stripeURL: user.stripeCustomerId
        ? `${REACT_APP_STRIPE_DASHBOARD_URL}/customers/${user.stripeCustomerId}`
        : 'Aucun paiement',
      createdAt: DateTime.fromMillis(user.createdAt.toMillis()).toLocaleString(
        DateTime.DATETIME_SHORT
      ),
      updatedAt: !!user.updatedAt
        ? DateTime.fromMillis(user.updatedAt.toMillis()).toLocaleString(
            DateTime.DATETIME_SHORT
          )
        : '---',
    }));
    const newUsersCounts = users.length;
    const newClientsCounts = users.filter(user => !!user.stripeCustomerId)
      .length;
    const newNonClientsCounts = users.filter(user => !user.stripeCustomerId)
      .length;
    const newNonVerifiedCounts = users.filter(user => !user.isVerified).length;

    return {
      rows,
      newUsersCounts,
      newClientsCounts,
      newNonClientsCounts,
      newNonVerifiedCounts,
    };
  }, [usersDocs, usersLoading, usersError, limitTs]);

  if (usersError) {
    throw new Error(usersError.toString());
  }

  return (
    <Page
      name="Les utilisateurs"
      navbarLeftChild={
        <NavLink
          color={theme.headingColor}
          to={{ pathname: '/admin/dashboard' }}
        >
          <IconButton aria-label="retour">
            <ReturnIcon color={theme.headingColor} size="16px" />
          </IconButton>
        </NavLink>
      }
    >
      <Container justify="flex-start">
        <DashboardHeader title="Les utilisateurs" withBackButton />

        <DashboardPageContainer theme={theme}>
          <BlockWrapper theme={theme}>
            <BlockHeader
              title={"Vue d'ensemble"}
              label={`Sur les ${displayedCount} derniers utilisateurs`}
            />
            <BlockStatsRow>
              <StatCard label={'Total'} value={newUsersCounts} />
              <StatCard label={'Clients'} value={newClientsCounts} />
              <StatCard label={'Non clients'} value={newNonClientsCounts} />
              <StatCard label={'Non vérifiés'} value={newNonVerifiedCounts} />
            </BlockStatsRow>
          </BlockWrapper>

          <BlockWrapper theme={theme} style={{ marginTop: SPACING[200] }}>
            <BlockHeader
              title={'Utilisateurs'}
              label={`Tableau de bord`}
              button={
                <Button
                  small
                  color="secondary"
                  startIcon={<SearchIcon color={theme.white} size="16px" />}
                  styles={{ marginRight: '8px' }}
                  onClick={() => setSearchDialog(true)}
                >
                  Recherche
                </Button>
              }
            />

            <TableFilters
              displayedCount={displayedCount}
              setDisplayedCount={setDisplayedCount}
            />

            <DashboardPageBlockContainer>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={25}
                stickyHeader
                loading={usersLoading}
                onRowClick={onRowClick}
              />
            </DashboardPageBlockContainer>
          </BlockWrapper>

          <SearchModal
            isOpen={searchDialog}
            onClose={() => setSearchDialog(false)}
            filters={['id', 'email', 'name']}
            operator="=="
            onSelectResult={user =>
              setEditDialog({
                updatedData: null,
                data: {
                  email: user.email,
                  id: user.id,
                  name: user.name,
                  phone: user.phone,
                  role: user.role,
                  verified: !!user.isVerified ? '✅' : '🚫',
                  verifiedBy: !user.verifiedBy
                    ? '--'
                    : user.verifiedBy === 'phone'
                    ? 'Téléphone'
                    : 'Email',
                  stripeURL: user.stripeCustomerId
                    ? `${REACT_APP_STRIPE_DASHBOARD_URL}/customers/${user.stripeCustomerId}`
                    : 'Aucun paiement',
                  createdAt: DateTime.fromMillis(
                    user.createdAt.toMillis()
                  ).toLocaleString(DateTime.DATETIME_SHORT),
                  updatedAt: !!user.updatedAt
                    ? DateTime.fromMillis(
                        user.updatedAt.toMillis()
                      ).toLocaleString(DateTime.DATETIME_SHORT)
                    : '---',
                },
              })
            }
          />

          {editDialog ? (
            <UserDialog
              user={editDialog}
              isOpen={!!editDialog}
              onClose={() => setEditDialog(null)}
              onConfirm={onConfirmRoleUpdate}
              cantConfirm={!editDialog.updatedData}
              setEditDialog={setEditDialog}
            />
          ) : null}
        </DashboardPageContainer>
      </Container>
    </Page>
  );
};

export default UsersDashboard;

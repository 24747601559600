import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

/**
 * Log in with Google
 * @returns Nothing.
 */
const logInWithGoogle = () => {
  ReactGA.event({
    category: 'User',
    action: 'Log in google',
  });
  try {
    const provider = new Firebase.auth.GoogleAuthProvider();
    return Firebase.auth().signInWithRedirect(provider);
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

/**
 * Log in with email and password
 * @param email - The email address of the user.
 * @param pwd - The password to log in with.
 * @param errorCb - A callback function that is called when an error occurs.
 * @returns Nothing.
 */
const logInWithCredentials = (email, pwd, errorCb) => {
  ReactGA.event({
    category: 'User',
    action: 'Log in email / password',
  });

  return Firebase.auth()
    .signInWithEmailAndPassword(email, pwd)
    .catch(error => {
      console.error({ error });
      errorCb({
        code: error.code,
        message: error.message,
      });
    });
};

export { logInWithCredentials, logInWithGoogle };

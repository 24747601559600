import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';
import { uniq, isEqual } from 'lodash';

import { prepareDocForUpdate } from '../helpers/firestoreHelpers';

const updateUser = (data, authID) => {
  // console.log('Update user called', { data, authID });
  ReactGA.event({
    category: 'User',
    action: 'Update user',
  });

  try {
    return Firebase.firestore()
      .collection('users')
      .doc(authID)
      .update(prepareDocForUpdate(data));
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

const updateNotificationsTokens = async (token, authID) => {
  ReactGA.event({
    category: 'User',
    action: 'Update NotificationsTokens',
  });

  const userDoc = await Firebase.firestore()
    .collection('users')
    .doc(authID)
    .get();

  if (!userDoc.exists) {
    console.error(`Whoops, couldn't find the user: ${authID}`);
    return;
  }

  const user = userDoc.data();
  const existingTokens = user.settings.notificationsTokens || [];
  const uniqDevicesTokens = existingTokens.filter(t => {
    return t.split(':')[0] !== token.split(':')[0];
  });
  const updatedTokens = uniq([...uniqDevicesTokens, token]);

  const updatedSettings = {
    ...user.settings,
    notificationsTokens: updatedTokens,
  };

  if (isEqual(existingTokens, updatedTokens)) {
    // console.log('return isEqual');
    return;
  } else {
    try {
      return Firebase.firestore()
        .collection('users')
        .doc(authID)
        .update(prepareDocForUpdate({ settings: updatedSettings }));
    } catch (error) {
      console.error({ error });
      throw new Error(error);
    }
  }
};

const deleteNotificationToken = async (token, authID) => {
  ReactGA.event({
    category: 'User',
    action: 'Update NotificationsTokens',
  });

  const userDoc = await Firebase.firestore()
    .collection('users')
    .doc(authID)
    .get();

  if (!userDoc.exists) {
    console.error(`Whoops, couldn't find the user: ${authID}`);
    return;
  }

  const user = userDoc.data();
  const existingTokens = user.settings.notificationsTokens || [];
  const updatedTokens = uniq(existingTokens.filter(t => t !== token));

  const updatedSettings = {
    ...user.settings,
    notificationsTokens: updatedTokens,
  };

  if (isEqual(existingTokens, updatedTokens)) {
    // console.log('return isEqual');
    return;
  } else {
    try {
      return Firebase.firestore()
        .collection('users')
        .doc(authID)
        .update(prepareDocForUpdate({ settings: updatedSettings }));
    } catch (error) {
      console.error({ error });
      throw new Error(error);
    }
  }
};

const deleteAddress = (user, addresses, address) => {
  // console.log('deleteAddress called', { uid: user.id, addresses, address });
  ReactGA.event({
    category: 'User - addresses',
    action: 'delete user address',
  });

  const updatedAddresses = [
    ...addresses.filter(item => item.id !== address.id),
  ];

  const defaultAddressUpdate =
    address.id === user.defaultAddress && updatedAddresses.length >= 1
      ? {
          defaultAddress: updatedAddresses[0].id,
        }
      : {};

  try {
    return Firebase.firestore()
      .collection('users')
      .doc(user.id)
      .update(
        prepareDocForUpdate({
          addresses: updatedAddresses,
          ...defaultAddressUpdate,
        })
      );
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

const updateAddresses = ({ authID, addresses, address, isDefaultAddress }) => {
  // console.log('updateAddresses called', {
  //   authID,
  //   addresses,
  //   address,
  //   isDefaultAddress,
  // });
  ReactGA.event({
    category: 'User - adresses',
    action: 'Update user adresses',
  });

  if (address.isDefault) {
    delete address.isDefault;
  }

  const updatedAddresses = [
    ...addresses.filter(item => item.id !== address.id),
    address,
  ];

  try {
    return Firebase.firestore()
      .collection('users')
      .doc(authID)
      .update(
        prepareDocForUpdate({
          addresses: updatedAddresses,
          ...(isDefaultAddress ? { defaultAddress: address.id } : {}),
        })
      );
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

const updateLanguage = ({ authID, lng, settings }) => {
  ReactGA.event({
    category: 'User - language',
    action: 'Update user language',
  });

  if (!!settings.language) {
    delete settings.language;
  }

  try {
    return Firebase.firestore()
      .collection('users')
      .doc(authID)
      .update(
        prepareDocForUpdate({
          settings: {
            ...settings,
            lng,
          },
        })
      );
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

export {
  updateAddresses,
  updateUser,
  deleteAddress,
  updateNotificationsTokens,
  deleteNotificationToken,
  updateLanguage,
};

import React from 'react';
import {
  Dialog,
  DialogContent,
  Slide,
  Toolbar,
  IconButton,
} from '@material-ui/core';
import { CloseIcon } from './icons';
import Button from './button';
import Heading from './heading';
import { SPACING } from '../styles/constants';

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const FullscreenDialog = props => {
  const {
    isOpen,
    onClose,
    onConfirm,
    title,
    cantConfirm,
    children,
    isLoading,
    theme,
    buttonText,
    styles,
  } = props;

  return (
    <Dialog
      fullScreen
      open={isOpen}
      onClose={onClose}
      TransitionComponent={Transition}
      styles={styles}
    >
      <Toolbar>
        <Heading size={500} styles={{ flex: 1 }}>
          {title}
        </Heading>
        {!!onConfirm ? (
          <Button
            onClick={onConfirm}
            color="primary"
            small
            disabled={cantConfirm}
            isLoading={isLoading || false}
            styles={{ marginRight: SPACING[300] }}
          >
            {buttonText || 'Sauvegarder'}
          </Button>
        ) : null}
        <IconButton
          edge="start"
          color="inherit"
          onClick={onClose}
          aria-label="close"
        >
          <CloseIcon style={{ color: theme.headingColor }} />
        </IconButton>
      </Toolbar>
      <DialogContent>{children}</DialogContent>
    </Dialog>
  );
};

export default FullscreenDialog;

import React from 'react';
import {
  createMuiTheme,
  ThemeProvider as MaterialUiThemeProvider,
} from '@material-ui/core/styles';

import { ThemeContext } from '../../contexts';
import { useSession } from '../../hooks';
import { MainWrapper, MainContainer, GlobalWrapper } from '../../styles/layout';
import LoggedInLayout from './LoggedInLayout';
import { THEMES_COLORS } from '../../styles/constants';
import CookiesBanner from './CookiesBanner';
import Footer from './Footer';

const Layout = ({ children }) => {
  const { isLoggedIn } = useSession();

  if (!isLoggedIn) {
    const currentTheme = THEMES_COLORS.light;
    const muiTheme = createMuiTheme({
      overrides: {
        MuiDrawer: {
          paperAnchorLeft: {
            background: currentTheme.backgroundColor,
          },
        },
        MuiDialog: {
          paper: {
            background: currentTheme.backgroundColor,
          },
        },
        MuiDialogTitle: {
          root: {
            color: currentTheme.headingColor,
          },
        },
        MuiDialogContentText: {
          root: {
            color: currentTheme.textColor,
          },
        },
        MuiExpansionPanelDetails: {
          root: {
            width: '100%',
          },
        },
      },
      palette: {
        primary: {
          contrastText: currentTheme.white,
          main: currentTheme.primaryColor,
        },
        secondary: {
          main: currentTheme.headingColor,
        },
      },
    });

    return (
      <ThemeContext.Provider value={currentTheme}>
        <MaterialUiThemeProvider theme={muiTheme}>
          <GlobalWrapper theme={currentTheme}>
            <MainWrapper theme={currentTheme} id="main-wrapper">
              <MainContainer>{children}</MainContainer>
            </MainWrapper>
            <Footer theme={currentTheme} />
            <CookiesBanner />
          </GlobalWrapper>
        </MaterialUiThemeProvider>
      </ThemeContext.Provider>
    );
  }

  return <LoggedInLayout>{children}</LoggedInLayout>;
};

export default Layout;

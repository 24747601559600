import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

import { prepareDocForCreate } from '../helpers/firestoreHelpers';

const createRusher = async data => {
  ReactGA.event({
    category: 'Rusher',
    action: 'Create rusher',
  });

  const rusher = {
    ...data,
    available: false,
    isWorked: false,
    isVerified: false,
    percentage: 10,
    isPaidVAT: false,
  };

  try {
    return Firebase.firestore()
      .collection('rushers')
      .doc(rusher.id)
      .set(prepareDocForCreate(rusher));
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

export default createRusher;

import styled from 'styled-components';
import ShopPattern from '../assets/images/shop-pattern.svg';

import { SPACING, devices } from './constants';

const ShopProfilBanner = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  height: 250px;
  background-color: ${props => props.background};
  background-image: url(${props => props.imageURL || ShopPattern});
  background-size: cover;
  background-position: center;
  padding: ${SPACING[200]};
`;

const ShopProfilContentWrapper = styled.div`
  padding: ${SPACING[300]} 0;
`;

const ShopProfilHeaderWrapper = styled.div`
  padding: ${SPACING[300]} ${SPACING[200]};
  margin-bottom: 0;
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  flex-direction: column;
`;

const ShopProfilCategoryWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const ShopProfilHeaderCategoryWrapper = styled.div`
  width: calc(100% - ${SPACING[400]});
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${SPACING[300]} 0;
  margin: 0 ${SPACING[200]};
  margin-bottom: ${SPACING[300]};
  border-bottom: 1px solid ${({ theme }) => theme.lightGray};
`;

const ShopProfilItemsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  overflow: scroll;
  padding: ${SPACING[200]};
`;

const ShopProfilDialogSupplementsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;

  @media ${devices.tablet} {
    flex-wrap: wrap;
  }
`;

const ShopProfilDialogHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${SPACING[300]};
  flex: auto;
`;

const ShopProfilDialogSupplementLine = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.padding || 0};
`;

const ShopProfilTotalPrice = styled.div`
  padding: ${SPACING[200]} 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const ShopProfilMenuDialogCategoryWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

const ShopProfilMenuDialogLineWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
`;

const ShopProfilMenuDialogLineHeader = styled.div`
  width: 100%;
  padding: ${SPACING[100]} 0;
  padding-left: 0;
  margin-bottom: ${SPACING[100]};
  border-bottom: 1px solid ${({ theme }) => theme.lightGray};
`;

const ShopProfilNameWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;

  @media ${devices.tablet} {
    width: auto;
  }
`;

const ShopProfilCityWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  margin-top: ${SPACING[200]};

  @media ${devices.tablet} {
    margin-top: 0;
  }
`;

const ShopProfilTitleContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  @media ${devices.laptop} {
    flex-wrap: nowrap;
  }
`;

const ShopProfilBadgesWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

export {
  ShopProfilBanner,
  ShopProfilContentWrapper,
  ShopProfilHeaderWrapper,
  ShopProfilCategoryWrapper,
  ShopProfilItemsWrapper,
  ShopProfilHeaderCategoryWrapper,
  ShopProfilDialogSupplementsWrapper,
  ShopProfilDialogSupplementLine,
  ShopProfilTotalPrice,
  ShopProfilDialogHeader,
  ShopProfilMenuDialogCategoryWrapper,
  ShopProfilMenuDialogLineWrapper,
  ShopProfilMenuDialogLineHeader,
  ShopProfilNameWrapper,
  ShopProfilBadgesWrapper,
  ShopProfilTitleContainer,
  ShopProfilCityWrapper,
};

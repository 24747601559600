import React from 'react';

import ORDER_STATUS from '../../../../actions/orders/helpers/STATUS';
import { Heading, Button } from '../../../../styleguide';
import { OrdersIcon } from '../../../../styleguide/icons';
import { useTheme } from '../../../../hooks';
import { TabContentWrapper } from '../../../../styles/shop';
import { HeaderTabRow } from '../../../../styles/shoptabs';
import {
  OrdersListWrapper,
  EmptyNewOrders,
} from '../../../../styles/orders-cards';
import TabPanel from '../../tabs/TabPanel';
import { InDeliveryCard, DeliveredCard, CanceledCard } from '../cards/Cards';
import EmptyTab from './EmptyTab';

const EndedTab = props => {
  const { value, orders, toggleOrdersDrawer } = props;
  const theme = useTheme();

  const hasExistingOrders = !!orders.length;

  return (
    <TabPanel value={value} index={3}>
      <TabContentWrapper>
        {hasExistingOrders ? (
          <>
            <HeaderTabRow>
              <Heading size={800}>Les commandes terminés</Heading>
              <Button
                small
                minimal
                startIcon={<OrdersIcon color={theme.black} size="16px" />}
                onClick={toggleOrdersDrawer}
              >
                Toutes les commandes
              </Button>
            </HeaderTabRow>

            <OrdersListWrapper>
              {orders.map(order => {
                if (order.status === ORDER_STATUS.inDelivery) {
                  return <InDeliveryCard key={order.id} order={order} />;
                } else if (order.status === ORDER_STATUS.canceled) {
                  return <CanceledCard key={order.id} order={order} />;
                } else {
                  return <DeliveredCard key={order.id} order={order} />;
                }
              })}
            </OrdersListWrapper>
          </>
        ) : (
          <EmptyNewOrders>
            <EmptyTab
              title={'Pas de commande terminé'}
              description={
                'Les commandes terminés sont les commandes prête, en livraison et livré 📦'
              }
            />
          </EmptyNewOrders>
        )}
      </TabContentWrapper>
    </TabPanel>
  );
};

export default EndedTab;

import Firebase from 'firebase/app';

import { prepareDocForUpdate } from '../../helpers/firestoreHelpers';

const targetedOrdersMetaDataUpdate = async (orders, data) => {
  // console.log('Create targetedOrdersUpdate', { orders });

  if (!orders || !orders.length) {
    return;
  }

  try {
    return Promise.all(
      orders.map(async order => {
        const updatedMetadata = {
          ...order.metadata,
          ...data,
        };
        const updatedOrder = {
          ...order,
          metadata: {
            ...updatedMetadata,
          },
        };
        await Firebase.firestore()
          .collection('orders')
          .doc(updatedOrder.id)
          .update(prepareDocForUpdate(updatedOrder));
      })
    );
  } catch (error) {
    console.error(`Whoops, error during orders updates: ${error.message}`, {
      data,
    });
  }
};

export default targetedOrdersMetaDataUpdate;

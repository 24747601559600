import React from 'react';
import Firebase from 'firebase/app';
import { useLocation, Redirect, useHistory } from 'react-router-dom';
import { useUnstated } from '@gitbook/unstated';
import { Chip, IconButton, Avatar } from '@material-ui/core';
import { useIntersectionObserver } from '@researchgate/react-intersection-observer';
import {
  useCollection,
  useCollectionData,
  useDocumentData,
} from 'react-firebase-hooks/firestore';
import { Alert, AlertTitle } from '@material-ui/lab';
import { isMobile } from 'react-device-detect';
import { useTranslation } from 'react-i18next';

import { parseNumber } from '../../common';
import { DEFAULT_HOURS, DAYS } from '../../actions/shop/createShop';
import { orderedGroupedItems } from '../../actions/helpers/orderedGroupedItems';
import {
  generateShopHoursText,
  getNextOpeningDay,
} from '../../actions/helpers/generateShopHoursText';
import {
  Loader,
  Heading,
  Button,
  ItemCard,
  NavLink,
  ConfirmDialog,
  Text,
  PartnerBadge,
} from '../../styleguide';
import { ReturnIcon, DashboardIcon } from '../../styleguide/icons';
import { FullPageHorizontalSlider } from '../../styles/common';
import {
  ShopProfilBanner,
  ShopProfilContentWrapper,
  ShopProfilHeaderWrapper,
  ShopProfilCategoryWrapper,
  ShopProfilNameWrapper,
  ShopProfilHeaderCategoryWrapper,
  ShopProfilBadgesWrapper,
  ShopProfilTitleContainer,
  ShopProfilCityWrapper,
} from '../../styles/shop-profil';
import { SPACING } from '../../styles/constants';
import { Page } from '../common';
import { useSession, useTheme } from '../../hooks';
import { BasketContainer } from '../../contexts';
import MenuDialog from './profil/menu/MenuDialog';
import ProductDialog from './profil/product/ProductDialog';
import InlineCategoriesNav from './profil/InlineCategoriesNav';
import { FlexFieldsWrapper } from '../../styles/shop';

const { REACT_APP_SETTINGS_DOC_ID } = process.env;

const CategoryBlock = props => {
  const { onDisplay, children, name, ...rest } = props;
  const [ref] = useIntersectionObserver(
    entry => {
      if (!!entry.isIntersecting) {
        onDisplay(name, entry.intersectionRatio);
      }
    },
    { threshold: isMobile ? 0.75 : 1 }
  );

  return (
    <ShopProfilCategoryWrapper innerRef={ref} {...rest}>
      {children}
    </ShopProfilCategoryWrapper>
  );
};

const ShopProfil = props => {
  const { match } = props;
  const [confirmDialog, setConfirmDialog] = React.useState(null);
  const [activeSection, setActiveSection] = React.useState(null);
  const [selectedItem, setSelectedItem] = React.useState({
    item: null,
    supplements: null,
    options: null,
    products: null,
    total: 0,
    initialItemPrice: 0,
  });
  const { t } = useTranslation(['common', 'shop', 'categories']);
  const basketContainer = useUnstated(BasketContainer);
  const theme = useTheme();
  const { user } = useSession();
  let location = useLocation();
  let history = useHistory();
  const { addresses, defaultAddress } = user;
  const userDefaultAddress = addresses.find(el => el.id === defaultAddress);

  const to =
    location.state && location.state.from.pathname !== match.url
      ? location.state.from
      : { pathname: '/' };

  const resetSelectedItem = () => {
    setSelectedItem({
      item: null,
      supplements: null,
      options: null,
      products: null,
      total: 0,
      initialItemPrice: 0,
    });
  };

  const onSectionDisplay = (section, ratio) => {
    if (!activeSection || section !== activeSection.section) {
      setActiveSection({ section, ratio });
    }
  };

  const onConfirmSelection = shop => {
    const { selectedPaymentMethod, selectedAddress } = basketContainer.state;

    const shopIsDifferent = basketContainer.updateBasket({
      item: selectedItem.item,
      shop,
      itemTotal: selectedItem.total,
      supplements: selectedItem.supplements,
      options: selectedItem.options,
      menuItems: selectedItem.products,
      selectedAddress: selectedAddress || userDefaultAddress,
    });

    if (shopIsDifferent) {
      setConfirmDialog({
        initialShop: shopIsDifferent.info.currentShop,
        newShop: shopIsDifferent.info.shop,
        title: t('shop:existing_basket'),
        description: t('shop:create_new_basket'),
        onConfirm: () => {
          basketContainer.resetBasket();

          basketContainer.updateBasket({
            item: selectedItem.item,
            shop,
            itemTotal: selectedItem.total,
            supplements: selectedItem.supplements,
            options: selectedItem.options,
            menuItems: selectedItem.products,
            selectedAddress,
            selectedPaymentMethod,
          });

          setConfirmDialog(null);
        },
        onClose: () => {
          history.push(`/shop/${shopIsDifferent.info.currentShop.id}`, {
            from: to,
          });
          setConfirmDialog(null);
        },
      });
    } else {
      resetSelectedItem();
    }
  };

  const [shopValue, shopLoading, shopError] = useCollection(
    Firebase.firestore()
      .collection('shops')
      .where(`id`, '==', match.params.id),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const [settings, settingsLoading, settingsError] = useDocumentData(
    Firebase.firestore().doc(`app/${REACT_APP_SETTINGS_DOC_ID}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );
  const [shopItemsValue, shopItemsLoading, shopItemsError] = useCollectionData(
    Firebase.firestore()
      .collection('shopItems')
      .where(`shopID`, '==', match.params.id),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (settingsLoading || shopLoading || shopItemsLoading) {
    return (
      <Loader color={theme.primaryColor} background={theme.backgroundColor} />
    );
  }

  if (settingsError || shopError || shopItemsError) {
    if (settingsError) {
      throw new Error(settingsError.toString());
    } else if (shopError) {
      throw new Error(shopError.toString());
    } else if (shopItemsError) {
      throw new Error(shopItemsError.toString());
    }
  }

  if (!shopValue.docs.length) {
    return (
      <Redirect
        to={{
          pathname: '/',
        }}
      />
    );
  }

  const shop = { ...shopValue.docs[0].data(), id: shopValue.docs[0].id };
  const isPartner = shop ? shop.isPartner : false;

  const orderedItems = orderedGroupedItems(shopItemsValue);
  const categories = orderedItems.map(el => el.category);

  const canManageShop =
    (user.role !== 'client' && shop.ownerID === user.id) ||
    user.role === 'admin';

  const canOrder = settings.open && shop.available;
  const city = shop.address.city.replace(/\d{5}/, '');

  const hours = generateShopHoursText(shop.hours || DEFAULT_HOURS, t);
  const today = DAYS[new Date().getDay() - 1];

  const nextOpenDay = getNextOpeningDay(shop, t);

  return (
    <Page
      name={shop.name || t('shop:shop_name')}
      withoutSpacing
      isShopProfilPage={shop.id}
      navbarLeftChild={
        <NavLink color={theme.headingColor} to={to}>
          <IconButton aria-label="retour">
            <ReturnIcon color={theme.headingColor} size="16px" />
          </IconButton>
        </NavLink>
      }
    >
      <ShopProfilBanner imageURL={shop.bannerURL}>
        <Chip
          label={`${t('shop:actually')} ${
            canOrder ? t('shop:open') : t('shop:closed')
          }`}
          color={canOrder ? 'primary' : 'secondary'}
          variant={'default'}
        />
      </ShopProfilBanner>
      <ShopProfilHeaderWrapper>
        <ShopProfilTitleContainer>
          <ShopProfilNameWrapper>
            <Avatar
              src={shop.logoURL}
              style={{ width: 40, height: 40, marginRight: SPACING[100] }}
            />
            <Heading size={900}>{shop.name}</Heading>
          </ShopProfilNameWrapper>
          <ShopProfilCityWrapper>
            <Text muted size={500}>
              {city}
            </Text>
          </ShopProfilCityWrapper>
        </ShopProfilTitleContainer>

        <Text
          size={500}
          styles={{
            padding: `${SPACING[300]} 0`,
            whiteSpace: 'pre-line',
            lineHeight: '1.5em',
          }}
        >
          {shop.description}
        </Text>

        <ShopProfilBadgesWrapper>
          {isPartner ? (
            <PartnerBadge
              theme={theme}
              wrapperProps={{ style: { marginRight: SPACING[200] } }}
            />
          ) : null}

          <Chip
            label={<Text size={400}>{t(`categories:${shop.category}`)}</Text>}
            styles={{ textTransform: 'capitalize' }}
          />
        </ShopProfilBadgesWrapper>

        <FlexFieldsWrapper
          align="center"
          padding={`${SPACING[300]} 0 ${SPACING[200]} 0`}
          classNames={`gap: ${SPACING[100]};`}
          mobileColumn
        >
          {hours.map((hour, index) => {
            const isToday = hour.days.includes(today);
            if (isToday) {
              return (
                <Chip
                  key={index}
                  label={
                    <Text size={400} key={index}>
                      <Text size={400} muted>
                        {hour.label}
                      </Text>
                      <span
                        style={{
                          fontWeight: isToday ? 'bold' : 'normal',
                        }}
                      >
                        {hour.value}
                      </span>
                    </Text>
                  }
                  variant={'outlined'}
                />
              );
            }
            return (
              <Text size={400} key={index}>
                <Text size={400} muted>
                  {hour.label}
                </Text>
                {hour.value}
              </Text>
            );
          })}
        </FlexFieldsWrapper>

        {canManageShop ? (
          <Button
            small
            onClick={() =>
              history.push(`/shopDashboard/${shop.id}`, { from: to })
            }
            startIcon={<DashboardIcon color={theme.white} size="16px" />}
          >
            Gérer mon établissement
          </Button>
        ) : null}

        {(!shop.available || !settings.open || !!shop.strongPresence) && (
          <div style={{ width: '100%' }}>
            {!!shop.strongPresence && !!settings.open && !!shop.available ? (
              <Alert
                variant="outlined"
                severity="info"
                style={{
                  marginTop: `${SPACING[300]}`,
                  marginBottom: 0,
                }}
              >
                <AlertTitle>
                  {t('common:strong_presence_alert:title')} {`💥`}
                </AlertTitle>
                {t('common:strong_presence_alert:message')}
              </Alert>
            ) : null}
            {!settings.open ? (
              <Alert
                variant="outlined"
                severity="info"
                style={{
                  marginTop: `${SPACING[300]}`,
                  padding: `${SPACING[100]} ${SPACING[300]}`,
                }}
              >
                <AlertTitle>
                  {t('common:opening_alert:title')} {`❌`}
                </AlertTitle>
                {t('common:opening_alert:message')}
              </Alert>
            ) : null}

            {!shop.available && !!settings.open ? (
              <Alert
                severity="error"
                variant="outlined"
                style={{
                  marginTop: `${SPACING[300]}`,
                  padding: `${SPACING[100]} ${SPACING[300]}`,
                }}
              >
                <AlertTitle>
                  {t('shop:opening_details:title')} {`❌`}
                </AlertTitle>
                {t('shop:opening_details:description_1')}
                {nextOpenDay ? (
                  <>
                    {t('shop:opening_details:description_2')}
                    <b>
                      {nextOpenDay.day} {t('common:opening:from')}{' '}
                      {nextOpenDay.opening} {t('common:opening:to')}{' '}
                      {nextOpenDay.closing}
                    </b>
                  </>
                ) : (
                  t('shop:opening_details:description_3')
                )}
              </Alert>
            ) : null}
          </div>
        )}
      </ShopProfilHeaderWrapper>
      <ShopProfilContentWrapper>
        {!!categories?.length ? (
          <InlineCategoriesNav
            shop={shop}
            categories={categories}
            activeSection={activeSection?.section}
            settings={settings}
          />
        ) : null}
        {orderedItems.map((group, index) => (
          <CategoryBlock
            key={index}
            id={`category-${group.category}`}
            onDisplay={onSectionDisplay}
            name={group.category}
          >
            <ShopProfilHeaderCategoryWrapper theme={theme}>
              <Heading size={800}>{group.category}</Heading>
            </ShopProfilHeaderCategoryWrapper>
            <FullPageHorizontalSlider theme={theme} width="100%">
              {group.items.map((item, i) => {
                return (
                  <ItemCard
                    withoutPartnerBadge
                    key={item.id}
                    styles={{
                      margin: 0,
                      marginRight: SPACING[100],
                      marginLeft: i === 0 ? SPACING[200] : 0,
                      cursor:
                        !!canOrder && !!item.avaibility ? 'pointer' : 'default',
                      opacity: !!canOrder && !!item.avaibility ? '1' : '0.8',
                    }}
                    item={item}
                    disabled={!item.avaibility}
                    actionsChildren={
                      <Button
                        small
                        primary
                        disabled={!canOrder || !item.avaibility}
                      >
                        {!item.avaibility
                          ? t('shop:unavailable')
                          : t('shop:add')}
                      </Button>
                    }
                    {...(item.isMenu ? { size: 'lg' } : {})}
                    {...(!!canOrder && !!item.avaibility
                      ? {
                          onClick: () =>
                            setSelectedItem({
                              item,
                              supplements: [],
                              products: [],
                              total: parseNumber(item.price),
                              initialItemPrice: parseNumber(item.price),
                              options: !!item.options?.length
                                ? item.options.map(o => {
                                    return {
                                      ...o,
                                      items: [],
                                    };
                                  })
                                : [],
                            }),
                        }
                      : {})}
                  />
                );
              })}
            </FullPageHorizontalSlider>
          </CategoryBlock>
        ))}
      </ShopProfilContentWrapper>
      {!!selectedItem.item ? (
        selectedItem.item.isMenu ? (
          <MenuDialog
            onConfirm={() => onConfirmSelection(shop)}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            onResetSelectedItem={resetSelectedItem}
            groupedShopItems={orderedItems}
          />
        ) : (
          <ProductDialog
            onConfirm={() => onConfirmSelection(shop)}
            selectedItem={selectedItem}
            setSelectedItem={setSelectedItem}
            onResetSelectedItem={resetSelectedItem}
          />
        )
      ) : null}
      {!!confirmDialog ? (
        <ConfirmDialog
          isOpen={!!confirmDialog}
          onClose={confirmDialog.onClose}
          onConfirm={confirmDialog.onConfirm}
          title={confirmDialog.title}
          description={confirmDialog.description}
        />
      ) : null}
    </Page>
  );
};

export default ShopProfil;

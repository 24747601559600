import React from 'react';
import styled from 'styled-components';
import { SwipeableDrawer } from '@material-ui/core';

import { SPACING } from '../styles/constants';

const ContentWrapper = styled.div`
  padding: ${SPACING[200]} ${SPACING[300]};
`;

const Drawer = props => {
  const { isOpen, toggleDrawer, anchor, children, name } = props;

  return (
    <SwipeableDrawer
      anchor={anchor}
      open={isOpen}
      onClose={() => toggleDrawer('')}
      onOpen={() => toggleDrawer(name)}
    >
      <ContentWrapper>{children}</ContentWrapper>
    </SwipeableDrawer>
  );
};

export default Drawer;

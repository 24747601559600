import * as yup from 'yup';

const TITLE_FIELD_VALIDATION = yup
  .string('Nom du produit')
  .required('Le nom du produit est requis !');

const PICTURE_FIELD_VALIDATION = yup
  .string('Photo')
  .url("Le lien n'est pas valide !");

const PRICE_FIELD_VALIDATION = yup
  .number('Prix TTC')
  .positive()
  .min(0.1, "Le prix n'est pas valide !")
  .typeError("Le prix n'est pas valide !")
  .required('Le prix du produit est requis !');

const VAT_FIELD_VALIDATION = yup
  .number('TVA')
  .positive()
  .min(5.5, "La TVA n'est pas valide !")
  .typeError("La TVA n'est pas valide !")
  .required('La TVA du produit est requise !');

const AVAILABLE_FIELD_VALIDATION = yup.boolean('Disponible');

const MENU_FIELD_VALIDATION = yup.boolean();

const SUPPLEMENTS_FIELD_VALIDATION = yup.array();

const OPTIONS_FIELD_VALIDATION = yup.array();

const PREPARATION_TIME_FIELD_VALIDATION = yup
  .number('Temps de préparation')
  .positive()
  .min(1, "Le temps de préparation n'est pas valide !")
  .typeError("Le temps de préparation n'est pas valide !")
  .required('Le temps de préparation est requis !');

const CATEGORIES_FIELD_VALIDATION = yup
  .string('Categories')
  .required('La catégorie est requise !');

const DESCRIPTION_FIELD_VALIDATION = yup
  .string('Description')
  .min(1)
  .required('La description est requise !');

const PRODUCT = {
  TITLE_FIELD_VALIDATION,
  PICTURE_FIELD_VALIDATION,
  PRICE_FIELD_VALIDATION,
  VAT_FIELD_VALIDATION,
  AVAILABLE_FIELD_VALIDATION,
  PREPARATION_TIME_FIELD_VALIDATION,
  CATEGORIES_FIELD_VALIDATION,
  DESCRIPTION_FIELD_VALIDATION,
  MENU_FIELD_VALIDATION,
  SUPPLEMENTS_FIELD_VALIDATION,
  OPTIONS_FIELD_VALIDATION,
};

export {
  PRODUCT,
  TITLE_FIELD_VALIDATION,
  PICTURE_FIELD_VALIDATION,
  PRICE_FIELD_VALIDATION,
  VAT_FIELD_VALIDATION,
  AVAILABLE_FIELD_VALIDATION,
  PREPARATION_TIME_FIELD_VALIDATION,
  CATEGORIES_FIELD_VALIDATION,
  DESCRIPTION_FIELD_VALIDATION,
  MENU_FIELD_VALIDATION,
  SUPPLEMENTS_FIELD_VALIDATION,
  OPTIONS_FIELD_VALIDATION,
};

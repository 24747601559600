import React from 'react';
import { useHistory } from 'react-router-dom';
import { useAsync } from 'react-use';
import { useUnstated } from '@gitbook/unstated';
import { IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { useSession, useTheme } from '../../../hooks';
import authenticatedRequest from '../../../actions/helpers/authenticatedRequest';
import { deletePaymentMethod } from '../../../actions/stripe';
import {
  Container,
  Heading,
  Button,
  ContentLoader,
  LineProperty,
  Backdrop,
  NavLink,
} from '../../../styleguide';
import { ReturnIcon } from '../../../styleguide/icons';
import { Page, EmptyPage } from '../../common';
import { HeaderWrapper, ButtonsWrapper } from '../../../styles/basket';
import { MainWrapper, ContentWrapper } from '../../../styles/adresseslist';
import { BasketContainer } from '../../../contexts';
import { API } from '../../../common';

const PaymentMethods = props => {
  const [isLoading, setLoading] = React.useState(false);
  const basketContainer = useUnstated(BasketContainer);
  const { t } = useTranslation(['account']);

  const theme = useTheme();
  const { user } = useSession();
  let history = useHistory();

  const paymentsMethodsList = useAsync(async () => {
    if (user.stripeCustomerId) {
      const response = await authenticatedRequest(
        'get',
        `${API.stripe.listPaymentMethods}?userID=${user.id}`
      );

      if (!!response.data && !!response.data.data) {
        return response.data.data;
      }
    }

    return [];
  }, []);

  const onDeletePM = async paymentMethodID => {
    setLoading(true);
    const response = await deletePaymentMethod(user.id, paymentMethodID);
    setLoading(false);

    if (response.error) {
      console.error('Error during PM deletion', { response });
    }

    if (basketContainer.paymentMethod?.id === paymentMethodID) {
      basketContainer.updateSelectedPaymentMethod(null);
    }

    history.push('/account');
    return;
  };

  const hasPaymentMethods =
    !!paymentsMethodsList &&
    !!paymentsMethodsList.value &&
    !!paymentsMethodsList.value.length;

  return (
    <Page
      name={t('account:pm:page_title')}
      navbarLeftChild={
        <NavLink color={theme.headingColor} to="/account">
          <IconButton aria-label="retour">
            <ReturnIcon color={theme.headingColor} size="16px" />
          </IconButton>
        </NavLink>
      }
    >
      <Container {...(!hasPaymentMethods ? {} : { justify: 'flex-start' })}>
        {!hasPaymentMethods && !paymentsMethodsList.loading ? (
          <EmptyPage
            title={t('account:pm:empty_title')}
            description={t('account:pm:empty_subtitle')}
            action={() => history.push('/account/payment_methods/create')}
            buttonContent={t('account:pm:add_new')}
          />
        ) : (
          <MainWrapper padding>
            <HeaderWrapper>
              <Heading size={900} centered={false}>
                {t('account:pm:page_title')}
              </Heading>
            </HeaderWrapper>
            <ContentWrapper>
              {paymentsMethodsList.loading ? (
                <ContentLoader text={t('account:pm:loading')} />
              ) : (
                paymentsMethodsList.value.map(pm => (
                  <LineProperty
                    key={pm.id}
                    buttonContent={t('account:pm:delete')}
                    onClick={() => onDeletePM(pm.id)}
                    value={`•••• •••• •••• ${pm.card.last4} - ${pm.card.exp_month}/${pm.card.exp_year}`}
                    label={`${
                      pm.billing_details.name
                    } - ${pm.card.brand.toUpperCase()}`}
                  />
                ))
              )}
              {isLoading && <Backdrop />}
            </ContentWrapper>
            <ButtonsWrapper>
              <Button
                onClick={() => history.push('/account/payment_methods/create')}
              >
                {t('account:pm:add')}
              </Button>
            </ButtonsWrapper>
          </MainWrapper>
        )}
      </Container>
    </Page>
  );
};

export default PaymentMethods;

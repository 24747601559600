import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import { Button, Text } from '../../../../styleguide';
import { displayDurationFrom } from '../../../../actions/orders/helpers/computeTimesHelpers';
import { useTheme } from '../../../../hooks';
import { computeFinalDeliveryTime } from '../../../../actions/orders/helpers/computeFinalPreparationTime';
import {
  InProgressModalDetails,
  InProgressModalDetailsRow,
  InProgressModalDetailsRowWrapper,
} from '../../../../styles/orders-cards';
import ORDER_STATUS from '../../../../actions/orders/helpers/STATUS';

const DeliveryDialog = props => {
  const { isOpen, onClose, onConfirm, order } = props;
  const theme = useTheme();
  const { hours, minutes } = computeFinalDeliveryTime(order.pickedAt);
  const temporaryDisabled = !!order?.inTreatment;

  const estimatedDeliveryTime = displayDurationFrom({
    seconds: order.duration,
  });

  React.useEffect(() => {
    if (
      order.status === ORDER_STATUS.canceled ||
      order.status === ORDER_STATUS.delivered
    ) {
      onClose();
    }
  }, [order, onClose]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="order-alert-dialog-title"
    >
      <DialogTitle id="order-alert-dialog-title">
        Terminer la commande
      </DialogTitle>
      <DialogContent>
        <Text size={400}>
          En terminant la commande, vous la marquerez comme livré, veillez à ne
          pas terminer la commande tant que le client n'a pas recupéré sa
          commande.
        </Text>

        {!order.rusher.isAdmin ? (
          <Alert severity="error" style={{ margin: '16px 0px' }}>
            <AlertTitle>Attention {'⚠️'}</AlertTitle>
            Attention, cette commande est livré par un autre livreur, êtes vous
            sur que le client à bien récupéré sa commande ?
          </Alert>
        ) : null}

        <InProgressModalDetails>
          <Text size={500}>Récapitulatif livraison : </Text>
          <InProgressModalDetailsRowWrapper>
            <InProgressModalDetailsRow>
              <Text size={400} color={theme.headingColor}>
                Temps de livraison estimé :
              </Text>
              <Text size={500} color={theme.headingColor}>
                {' '}
                {estimatedDeliveryTime}
              </Text>
            </InProgressModalDetailsRow>
            <InProgressModalDetailsRow>
              <Text size={400} color={theme.headingColor}>
                Temps de livraison final :
              </Text>
              <Text size={500} color={theme.headingColor}>
                {hours > 0 ? ` ${hours}h${minutes}` : ` ${minutes}min`}
              </Text>
            </InProgressModalDetailsRow>
          </InProgressModalDetailsRowWrapper>
        </InProgressModalDetails>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} small minimal>
          Attendre
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          small
          disabled={temporaryDisabled}
        >
          Go
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeliveryDialog;

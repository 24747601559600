import React from 'react';
import { useFormik } from 'formik';

import { Button, Heading, Input } from '../../../styleguide';
import { FormWrapper, ButtonsWrapper } from '../../../styles/form';
import { signupRusherLegal } from '../../../forms';

const LegalForm = props => {
  const { onRusherUpdate } = props;

  const formik = useFormik({
    initialValues: {
      email: '',
      ownerName: '',
      phone: '',
      siret: '',
      status: '',
      address: '',
    },
    validationSchema: signupRusherLegal,
    onSubmit: values => {
      onRusherUpdate({ legal: values });
    },
  });

  const canSubmit =
    !!formik.values.email &&
    !!formik.values.phone &&
    !!formik.values.ownerName &&
    !!formik.values.address &&
    !!formik.values.siret &&
    !!formik.values.status;

  return (
    <>
      <Heading size={900} centered>
        Informations légales
      </Heading>
      <FormWrapper onSubmit={formik.handleSubmit}>
        <Input
          name="ownerName"
          label="Nom et prénom"
          required
          placeholder="John Doe"
          value={formik.values.ownerName}
          onChange={formik.handleChange}
          error={formik.touched.ownerName && Boolean(formik.errors.ownerName)}
          helperText={formik.touched.ownerName && formik.errors.ownerName}
        />

        <Input
          name="address"
          label="Adresse de l'entreprise"
          required
          placeholder="10 place de la poste, 74400 Chamonix"
          value={formik.values.address}
          onChange={formik.handleChange}
          error={formik.touched.address && Boolean(formik.errors.address)}
          helperText={formik.touched.address && formik.errors.address}
        />

        <Input
          name="phone"
          label="Telephone"
          required
          placeholder="0450580909"
          type="phone"
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />

        <Input
          name="email"
          label="Email"
          required
          type="email"
          placeholder="john@doe.fr"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <Input
          name="siret"
          label="SIRET"
          required
          placeholder="000 000 000 00000"
          value={formik.values.siret}
          onChange={formik.handleChange}
          error={formik.touched.siret && Boolean(formik.errors.siret)}
          helperText={formik.touched.siret && formik.errors.siret}
        />

        <Input
          name="status"
          label="Status"
          required
          placeholder="Auto-entreprise"
          value={formik.values.status}
          onChange={formik.handleChange}
          error={formik.touched.status && Boolean(formik.errors.status)}
          helperText={formik.touched.status && formik.errors.status}
        />

        <ButtonsWrapper>
          <Button type="submit" disabled={!canSubmit}>
            Terminer
          </Button>
        </ButtonsWrapper>
      </FormWrapper>
    </>
  );
};

export default LegalForm;

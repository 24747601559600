import React from 'react';
import Firebase from 'firebase/app';
import { isMobile } from 'react-device-detect';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Redirect, useLocation, useHistory } from 'react-router-dom';
import { Chip } from '@material-ui/core';
import { toaster } from 'evergreen-ui';
import { Alert, AlertTitle } from '@material-ui/lab';

import { OrdersActionsWrapper } from '../../styles/shop';
import { Page } from '../common';
import {
  Button,
  Heading,
  Container,
  ConfirmDialog,
  Loader,
} from '../../styleguide';
import { useSession, useTheme } from '../../hooks';

import { SPACING } from '../../styles/constants';
import {
  NotAvailableIcon,
  AvailableIcon,
  OrdersIcon,
} from '../../styleguide/icons';
import { ShopOrdersHeaderWrapper } from '../../styles/shop-orders';
import { updateRusher } from '../../actions/rusher';
import RusherBillingTabs from './billing';

const RusherBilling = props => {
  const { match } = props;
  const [confirm, setConfirm] = React.useState(null);

  const theme = useTheme();
  const { user } = useSession();
  let location = useLocation();
  const history = useHistory();

  const rusherID =
    user.role === 'admin' && match.params.id ? match.params.id : user.id;

  const [value, loading, error] = useCollectionData(
    Firebase.firestore()
      .collection('rushers')
      .where('id', '==', rusherID),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const onToggleAvailability = canToggle => {
    const currentAvailability = rusher.isWorked;

    if (canToggle) {
      setConfirm(null);
      updateRusher({ ...rusher, isWorked: !currentAvailability }).then(() => {
        if (currentAvailability) {
          toaster.danger(
            `Vous etes desormais indisponible pour les futures livraisons.`
          );
        } else {
          toaster.success(
            `Vous etes desormais disponible pour les futures livraisons.`
          );
        }
      });
    } else {
      const titleContext = !currentAvailability ? 'Disponible' : 'Indisponible';
      const description = currentAvailability
        ? 'En devenant indisponible, vous ne pourrez plus livrez de commande.'
        : 'En devenant disponible, vous pourrez livrez des commandes.';
      setConfirm({
        title: `Voulez vous passer ${titleContext}`,
        description,
        onConfirm: () => {
          onToggleAvailability(true);
        },
      });
    }
  };

  if (error) {
    throw new Error(error.toString());
  }

  if (loading) {
    return (
      <Loader color={theme.primaryColor} background={theme.backgroundColor} />
    );
  }

  if (!value || !value.length) {
    return (
      <Redirect
        to={{
          pathname: '/rusher/signup',
          state: { from: location },
        }}
      />
    );
  }

  const rusher = value[0];

  return (
    <Page name="Votre profil">
      <Container justify="flex-start">
        <ShopOrdersHeaderWrapper background={theme.backgroundColor}>
          {!isMobile ? (
            <Button
              small
              primary
              onClick={() => history.push(`/rusher/profil/`)}
              startIcon={<OrdersIcon color={theme.white} size="16px" />}
              styles={{ marginRight: '8px' }}
            >
              Profil
            </Button>
          ) : null}

          <Heading
            size={800}
            styles={{
              marginRight: SPACING[100],
              textShadow:
                user.settings.theme !== 'light'
                  ? `2px 2px 2px ${theme.black}`
                  : 'none',
            }}
            centered
          >
            Facturation
          </Heading>
          {!isMobile ? (
            <Chip
              label={`${rusher.isWorked ? 'Disponible' : 'Non disponible'}`}
              clickable
              color={rusher.isWorked ? 'primary' : 'secondary'}
              variant={'default'}
              onClick={() => onToggleAvailability()}
              deleteIcon={
                rusher.isWorked ? <AvailableIcon /> : <NotAvailableIcon />
              }
              onDelete={() => onToggleAvailability()}
            />
          ) : null}
        </ShopOrdersHeaderWrapper>

        {isMobile ? (
          <OrdersActionsWrapper align={'space-between'}>
            <Button
              small
              primary
              onClick={() => history.push(`/rusher/profil/`)}
              startIcon={<OrdersIcon color={theme.white} size="16px" />}
              styles={{ marginRight: '8px' }}
            >
              Profil
            </Button>

            <Chip
              label={`${rusher.isWorked ? 'Disponible' : 'Non disponible'}`}
              clickable
              color={rusher.isWorked ? 'primary' : 'secondary'}
              variant={'default'}
              onClick={() => onToggleAvailability()}
              deleteIcon={
                rusher.isWorked ? <AvailableIcon /> : <NotAvailableIcon />
              }
              onDelete={() => onToggleAvailability()}
            />
          </OrdersActionsWrapper>
        ) : null}

        <RusherBillingTabs rusher={rusher} />
      </Container>
      {confirm && (
        <ConfirmDialog
          isOpen={!!confirm}
          onClose={() => setConfirm(null)}
          onConfirm={confirm.onConfirm}
          title={confirm.title}
          description={confirm.description}
          cantConfirm={!rusher.available}
          info={
            !rusher.isVerified ? (
              <Alert severity="info" style={{ margin: '8px auto' }}>
                <AlertTitle>En attente de validation</AlertTitle>
                Une fois votre compte validé par notre équipe, vous pourrez
                faire vos premières livraisons 💸
              </Alert>
            ) : null
          }
        />
      )}
    </Page>
  );
};

export default RusherBilling;

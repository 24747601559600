import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

import { prepareDocForUpdate } from '../helpers/firestoreHelpers';

const editShopItemsAvaibility = async (items, avaibility) => {
  ReactGA.event({
    category: 'Shop',
    action: 'Update ShopItems Category',
  });

  try {
    const updatedItemsData = items.map(item => ({
      ...item,
      avaibility,
    }));
    return Promise.all(
      updatedItemsData.map(
        async item =>
          await Firebase.firestore()
            .collection('shopItems')
            .doc(item.id)
            .update(prepareDocForUpdate(item))
      )
    );
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export default editShopItemsAvaibility;

import * as yup from 'yup';

const NAME_ADDRESS_FIELD_VALIDATION = yup.string("Nom de l'adresse");

const DETAILS_ADDRESS_FIELD_VALIDATION = yup.string('Details');

// All the following fields are autogenerated by GmapsAPI response data,
// so whe just ensure they are correct types
const STREET_FIELD_VALIDATION = yup.string().required();
const CITY_FIELD_VALIDATION = yup.string().required();
const POSTALCODE_FIELD_VALIDATION = yup.number().required();
const ID_FIELD_VALIDATION = yup.string().required();
const DEFAULT_FIELD_VALIDATION = yup.boolean('Par défault');

const ADDRESS = {
  NAME_ADDRESS_FIELD_VALIDATION,
  DETAILS_ADDRESS_FIELD_VALIDATION,
  STREET_FIELD_VALIDATION,
  CITY_FIELD_VALIDATION,
  POSTALCODE_FIELD_VALIDATION,
  ID_FIELD_VALIDATION,
  DEFAULT_FIELD_VALIDATION,
};

export { ADDRESS };

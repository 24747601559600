import React from 'react';

import { Button, Text } from '../../styleguide';
import { SPACING } from '../../styles/constants';
import {
  CookiesBannerContainer,
  CookiesBannerWrapper,
} from '../../styles/layout';
import { useTheme } from '../../hooks';

const CookiesBanner = props => {
  const isAlreadyAccepted = window.localStorage.getItem('hasAcceptedCookies');
  const [isDisplayed, setDisplayed] = React.useState(!isAlreadyAccepted);
  const theme = useTheme();

  const onClickAccept = () => {
    localStorage.setItem('hasAcceptedCookies', true);
    setDisplayed(false);
  };

  if (!isDisplayed) {
    return null;
  }

  return (
    <CookiesBannerContainer bg={theme.backgroundColor}>
      <CookiesBannerWrapper>
        <Text size={300} color={theme.textColor}>
          En utilisant les services de Delyzon, vous acceptez notre politique
          d'utilisation des cookies.
          <br />
          Ces derniers sont uniquement utilisés à des fins fonctionnelles.
        </Text>
        <Button
          small
          onClick={onClickAccept}
          variant="outlined"
          styles={{ marginLeft: SPACING[100], textTransform: 'capitalize' }}
        >
          Accepter
        </Button>
      </CookiesBannerWrapper>
    </CookiesBannerContainer>
  );
};

export default CookiesBanner;

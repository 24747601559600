import React from 'react';
import Firebase from 'firebase/app';
import {
  useCollection,
  useDocumentData,
  useCollectionData,
} from 'react-firebase-hooks/firestore';
import { Switch } from '@material-ui/core';
import { DateTime } from 'luxon';

import {
  updateOpening,
  updateStrongPresence,
} from '../../../../../actions/admin';
import { useTheme } from '../../../../../hooks';
import { ContentLoader } from '../../../../../styleguide';
import { BlockWrapper, BlockContentRow } from '../../../../../styles/admin';
import StatCard from '../common/StatCard';
import BlockHeader from '../common/BlockHeader';
import { getBestCity, getBestShop } from '../../pages/orders';

const { REACT_APP_SETTINGS_DOC_ID } = process.env;

const AppBlock = props => {
  const { setDialog } = props;
  const theme = useTheme();

  const limitTs = React.useMemo(
    () =>
      Firebase.firestore.Timestamp.fromDate(
        DateTime.local()
          .minus({
            days: 2,
          })
          .toJSDate()
      ),
    []
  );

  const [orders, ordersLoading] = useCollectionData(
    Firebase.firestore()
      .collection('orders')
      .where('orderAt', '>=', DateTime.fromSeconds(limitTs.seconds).toMillis())
      .orderBy('orderAt', 'desc'),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
        timestampsInSnapshots: true,
      },
    }
  );

  const [newUsers, newUsersLoading] = useCollection(
    Firebase.firestore()
      .collection('users')
      .where('createdAt', '>=', limitTs)
      .orderBy('createdAt', 'desc'),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
        timestampsInSnapshots: true,
      },
    }
  );

  const [settings, settingsLoading] = useDocumentData(
    Firebase.firestore().doc(`app/${REACT_APP_SETTINGS_DOC_ID}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const onClickUpdateOpening = e => {
    const value = e.target.checked;

    const titleContext = !!value ? 'lancer' : 'arrêter';
    const description = !!value
      ? 'En lancant le service, vous recevrez des commandes'
      : 'En arrêtant le service, vous ne recevrez plus aucune commande';

    setDialog({
      title: `Voulez vous ${titleContext} le service ?`,
      description: description,
      onConfirm: () => {
        updateOpening({
          value: { open: value },
          docID: REACT_APP_SETTINGS_DOC_ID,
        });
        setDialog(null);
      },
    });
  };

  const onClickUpdateStrongPresence = e => {
    const value = e.target.checked;

    const titleContext = !!value ? 'Activater' : 'Désactiver';
    const description = !!value
      ? 'En activant le mode forte affluence, tout les délais de livraison seront augmenté de 20min'
      : 'En désactivant le mode forte affluence, les délais de livraisons reviendront à la normale.';

    setDialog({
      title: `${titleContext} le mode "Forte Affluence"`,
      description: description,
      onConfirm: () => {
        updateStrongPresence({
          value: { strongPresence: value },
          docID: REACT_APP_SETTINGS_DOC_ID,
        });
        setDialog(null);
      },
    });
  };

  const isLoading = !!settingsLoading || !!ordersLoading || !!newUsersLoading;

  const {
    ordersCount,
    newUsersCount,
    bestCity,
    bestShop,
  } = React.useMemo(() => {
    if (isLoading) {
      return {
        ordersCount: 0,
        newUsersCount: 0,
        bestCity: '',
        bestShop: '',
      };
    }
    return {
      ordersCount: orders ? orders?.length : 0,
      newUsersCount: newUsers ? newUsers?.size : 0,
      bestCity: getBestCity(orders) || '--',
      bestShop: getBestShop(orders) || '--',
    };
  }, [orders, newUsers, isLoading]);

  return (
    <BlockWrapper theme={theme}>
      <BlockHeader
        full
        title="Paramètres et aperçu"
        label={`Depuis le ${DateTime.fromSeconds(
          limitTs.seconds
        ).toLocaleString(DateTime.DATETIME_SHORT)}`}
      />

      {isLoading ? (
        <ContentLoader text="Chargement en cours" />
      ) : (
        <BlockContentRow>
          <StatCard label={'Ouverture'}>
            <Switch
              checked={!!settings.open}
              onChange={onClickUpdateOpening}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          </StatCard>
          <StatCard label={'Forte affluence'}>
            <Switch
              checked={!!settings.strongPresence}
              onChange={onClickUpdateStrongPresence}
              color="primary"
              inputProps={{ 'aria-label': 'primary checkbox' }}
              disabled={!settings.open}
            />
          </StatCard>
          <StatCard label={'Utilisateurs'} value={newUsersCount} />
          <StatCard label={'Commandes'} value={ordersCount} />
          <StatCard label={'Ville'} value={bestCity} small />
          <StatCard label={'Établissement'} value={bestShop} small />
        </BlockContentRow>
      )}
    </BlockWrapper>
  );
};

export default AppBlock;

import React from 'react';
import { useTheme } from '../../../../hooks';
import { Button, Text, Input } from '../../../../styleguide';
import { ClockIcon } from '../../../../styleguide/icons';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Divider,
  InputAdornment,
} from '@material-ui/core';
import { SPACING } from '../../../../styles/constants';
import ORDER_STATUS from '../../../../actions/orders/helpers/STATUS';
import { parseNumber } from '../../../../common';

const NewOrderDialog = props => {
  const {
    isOpen,
    confirmModal,
    preparationTime,
    onClose,
    onConfirm,
    onCancel,
    order,
  } = props;
  const theme = useTheme();
  const [customPreparationTime, setCustomPreparationTime] = React.useState(
    preparationTime
  );
  const temporaryDisabled = !!order?.inTreatment;

  React.useEffect(() => {
    if (order.status !== ORDER_STATUS.inValidation) {
      onClose();
    }
  }, [order, onClose]);

  const onConfirmOrder = () => {
    onConfirm(customPreparationTime);
  };

  const confirmDialogData =
    confirmModal === 'confirm'
      ? {
          title: 'Confirmation de la commande',
          description:
            "D'après les temps de préparations que vous nous avez fournis, nous estimons que la commande sera prête dans: ",
          inputLabel:
            'Si vous estimez que le temps réel sera différent, vous pouvez directement entrer le temps que VOUS avez estimez (10min minimum)',
        }
      : {
          title: 'Annulation de la commande',
          description:
            'Attention, les annulations de commandes vous donne image négative, veillez à fermer votre etablissement plutot que de refuser directement les commandes des clients.',
        };

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="order-alert-dialog-title"
    >
      <DialogTitle id="order-alert-dialog-title">
        {confirmDialogData.title}
      </DialogTitle>
      <DialogContent>
        {confirmModal === 'confirm' ? (
          <>
            <Text size={400}>{confirmDialogData.description}</Text>
            <Text
              size={500}
              style={{ color: theme.primaryColor, marginTop: SPACING[100] }}
            >
              {` ${preparationTime}`}min{' '}
            </Text>

            <Divider style={{ margin: `${SPACING[200]} 0` }} />

            <Text size={400}>{confirmDialogData.inputLabel}</Text>
            <Input
              type="number"
              wrapperProps={{ style: { marginTop: SPACING[200] } }}
              label={'Temps de préparation - 10mn minimum'}
              value={customPreparationTime}
              onChange={e =>
                setCustomPreparationTime(parseNumber(e.target.value))
              }
              min={10}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <ClockIcon />
                  </InputAdornment>
                ),
              }}
            />
          </>
        ) : (
          <Text size={400}>{confirmDialogData.description}</Text>
        )}
      </DialogContent>

      {confirmModal === 'confirm' ? (
        <DialogActions>
          <Button onClick={onClose} small minimal>
            Attendre
          </Button>
          <Button
            onClick={onConfirmOrder}
            color="primary"
            small
            disabled={temporaryDisabled || customPreparationTime < 10}
          >
            Confirmer
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={onClose} small minimal>
            Retour
          </Button>
          <Button
            onClick={onCancel}
            color="primary"
            styles={{ backgroundColor: theme.red }}
            small
            disabled={temporaryDisabled}
          >
            Refuser
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default NewOrderDialog;

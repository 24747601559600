import React from 'react';
import { Button as MaterialButton } from '@material-ui/core';
import { ImpulseSpinner } from 'react-spinners-kit';

import { useTheme } from '../hooks';

const Button = props => {
  const {
    minimal,
    children,
    styles,
    primary,
    small,
    large,
    variant,
    isLoading,
    loaderPadding,
    ...rest
  } = props;
  const theme = useTheme();

  const sizeStyles =
    large || small
      ? {}
      : {
          width: '100%',
          maxWidth: '300px',
          minHeight: '45px',
          borderRadius: '6px',
          fontSize: '16px',
        };

  return (
    <MaterialButton
      variant={!variant ? (minimal ? 'text' : 'contained') : variant}
      color={minimal && !primary ? 'secondary' : 'primary'}
      style={{
        ...sizeStyles,
        ...styles,
      }}
      size={large ? 'large' : small ? 'small' : 'medium'}
      disabled={rest.disabled || isLoading}
      {...rest}
    >
      {isLoading && (
        <div style={{ padding: loaderPadding, position: 'absolute' }}>
          <ImpulseSpinner size={34} color={theme.primaryColor} loading />
        </div>
      )}
      {children}
    </MaterialButton>
  );
};

export default Button;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Heading, AddressForm, Text } from '../../../styleguide';
import { colors } from '../../../styles/constants';
import { ContentWrapper, SecondaryTextWrapper } from '../../../styles/login';
import SignupStepper from './SignupStepper';

const Address = props => {
  const { onCredentialsUpdate, isLoading, currentStep } = props;
  const { t } = useTranslation(['signup']);

  const onSubmitForm = address => {
    onCredentialsUpdate({ address });
  };

  return (
    <ContentWrapper>
      <Heading size={900} style={{ color: colors.black }}>
        {`${t('signup:addresse:title')} 🏠`}
      </Heading>
      <SecondaryTextWrapper>
        <Text size={500} centered muted style={{ color: colors.grey }}>
          {t('signup:addresse:subtitle')}
        </Text>
      </SecondaryTextWrapper>
      <SignupStepper currentStep={currentStep} />
      <AddressForm onSubmit={onSubmitForm} isLoading={isLoading} />
    </ContentWrapper>
  );
};

export default Address;

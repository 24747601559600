import React from 'react';
import { useHistory, useLocation, Redirect } from 'react-router-dom';
import Firebase from 'firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';
import { IconButton } from '@material-ui/core';
import { toaster } from 'evergreen-ui';

import { useSession } from '../../hooks';
import { NavLink, Container, Loader } from '../../styleguide';
import { Page } from '../common';
import { createShop } from '../../actions/shop';
import { ImageStyled } from '../../styles/signup';
import { CloseIcon } from '../../styleguide/icons';
import STEPS from './steps';
import { useTheme } from '../../hooks';
import FoodImage from '../../assets/images/food.svg';

const SHOP_DATA = {
  name: '',
  description: '',
  ownerID: '',
  available: false,
  category: '',
  legal: null,
  address: null,
  logoURL: '',
};

const CreateShop = props => {
  const { authUser, user } = useSession();
  const theme = useTheme();
  const history = useHistory();
  const location = useLocation();

  const [currentStep, setStep] = React.useState(1);
  const [creationError, setError] = React.useState(null);
  const [shop, setShop] = React.useState({
    ...SHOP_DATA,
    ownerID: authUser.uid,
  });

  const imageSize =
    currentStep === 1 ? {} : { width: '150px', height: '150px' };

  const onSubmit = () => {
    createShop(shop);
    history.push('/shop/stripe/accountCreation');
  };

  const onShopUpdate = data => {
    setShop({ ...shop, ...data });
  };

  const onNextStep = () => setStep(currentStep + 1);

  const onSkip = () => setStep(4);

  React.useEffect(() => {
    const isFinalStep =
      !!shop.name &&
      !!shop.category &&
      !!shop.legal &&
      !!shop.address &&
      !!shop.logoURL;

    if (isFinalStep) {
      onSubmit();
    }
  });

  const [value, loading, error] = useCollection(
    Firebase.firestore()
      .collection('shops')
      .where('ownerID', '==', user.id),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (loading) {
    return (
      <Loader color={theme.primaryColor} background={theme.backgroundColor} />
    );
  }

  const alreadyHasAShop = !value.empty;

  if (alreadyHasAShop) {
    const stripeConnected = !!value.docs[0].data().stripeCustomerId;
    if (!stripeConnected) {
      return (
        <Redirect
          to={{
            pathname: '/shop/stripe/accountCreation',
            state: { from: location },
          }}
        />
      );
    }
  }

  if (creationError) {
    toaster.danger("Erreur lors de la création de l'établissement", {
      description: creationError.message,
    });
  }

  const { Step } = STEPS.filter(item => item.index === currentStep)[0];

  return (
    <Page
      navbarLeftChild="disabled"
      navbarRightChild={
        <NavLink color={theme.headingColor} to={{ pathname: '/' }}>
          <IconButton aria-label="retour">
            <CloseIcon color={theme.headingColor} size="16px" />
          </IconButton>
        </NavLink>
      }
      name="Nouvel établissement"
    >
      <Container>
        <ImageStyled src={FoodImage} {...imageSize} />

        <Step
          shop={shop}
          onNext={onNextStep}
          onSkip={onSkip}
          onShopUpdate={onShopUpdate}
        />
      </Container>
    </Page>
  );
};

export default CreateShop;

import React from 'react';
import { Backdrop as BackdropMaterial } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import styled from 'styled-components';

import { RotateSpinner } from 'react-spinners-kit';

import { colors, rgba } from '../styles/constants';
import { useTheme } from '../hooks';

const useStyles = makeStyles(theme => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: colors.white,
  },
}));

const ContentBackdrop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-color: ${({ theme }) => rgba(theme.backgroundColor, 0.6)};
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1;
`;

const Backdrop = props => {
  const { isContentBackdrop } = props;
  const classes = useStyles();
  const theme = useTheme();

  if (isContentBackdrop) {
    return (
      <ContentBackdrop theme={theme}>
        <RotateSpinner size={42} color={theme.primaryColor} loading />
      </ContentBackdrop>
    );
  }

  return (
    <BackdropMaterial className={classes.backdrop} open>
      <RotateSpinner size={42} color={theme.white} loading />
    </BackdropMaterial>
  );
};

export default Backdrop;

import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';

import { Tabs, Tab } from '../../../styleguide';
import { SPACING } from '../../../styles/constants';
import SettingsTab from './SettingsTab';
import OverviewTab from './OverviewTab';

const RusherProfilTabs = props => {
  const { rusher } = props;
  const [currentTab, setCurrentTab] = React.useState(0);

  const onTabChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <>
      <Tabs
        style={{ width: '100%', margin: `${SPACING[300]} 0` }}
        value={currentTab}
        onChange={onTabChange}
      >
        <Tab label={`Résumé`} />
        <Tab label={`Informations`} />
      </Tabs>

      {!rusher.isVerified ? (
        <Alert severity="info" style={{ margin: '8px auto' }}>
          <AlertTitle>En attente de validation</AlertTitle>
          Une fois votre compte validé par notre équipe, vous pourrez faire vos
          premières livraisons 💸
        </Alert>
      ) : null}

      <SettingsTab value={currentTab} rusher={rusher} />
      <OverviewTab value={currentTab} rusher={rusher} />
    </>
  );
};

export default RusherProfilTabs;

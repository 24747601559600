import { add, subtract } from 'lodash';
import { parseNumber } from '../../../common';

function computeAppFees(discount, initialAppFees) {
  const computedFees = !!discount
    ? parseNumber(
        subtract(
          parseNumber(initialAppFees),
          parseNumber((initialAppFees * discount.appFeesValue) / 100)
        ),
        2
      )
    : parseNumber(initialAppFees);

  return computedFees;
}

function computeDeliveryFees(discount, deliveryFees) {
  const computedFees = !!discount
    ? parseNumber(
        subtract(
          parseNumber(deliveryFees),
          parseNumber((deliveryFees * discount.deliveryFeesValue) / 100)
        ),
        2
      )
    : parseNumber(deliveryFees);

  return computedFees;
}

function computeTotalFees(appFees, deliveryFees) {
  return parseNumber(add(appFees, deliveryFees));
}

export { computeAppFees, computeDeliveryFees, computeTotalFees };

import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';
import pull from 'lodash/pull';

import { prepareDocForUpdate } from '../helpers/firestoreHelpers';

const editShopItemsCategory = async (
  category,
  items,
  menuItems,
  oldCategory
) => {
  // console.log('Update shop item called', { shopItem });
  ReactGA.event({
    category: 'Shop',
    action: 'Update ShopItems Category',
  });

  try {
    const menuItemsUpdated = !!menuItems?.length
      ? menuItems.map(item => ({
          ...item,
          categories: [...pull(item.categories, oldCategory), category] || [],
        }))
      : [];

    const updatedItemsData = [
      ...items.map(item => ({
        ...item,
        category,
      })),
      ...menuItemsUpdated,
    ];

    return Promise.all(
      updatedItemsData.map(
        async item =>
          await Firebase.firestore()
            .collection('shopItems')
            .doc(item.id)
            .update(prepareDocForUpdate(item))
      )
    );
  } catch (error) {
    console.error(error);
    throw new Error(error);
  }
};

export default editShopItemsCategory;

import React from 'react';
import { Avatar } from '@material-ui/core';

import Profile from './Profile';
import { Page } from '../../common';
import { updateUser } from '../../../actions/users';
import { PropertyEdit } from '../../../styleguide';
import { useSession, useTheme } from '../../../hooks';
import { AccountHeader, AvatarWrapper } from '../../../styles/account';

const Account = () => {
  const { user } = useSession();
  const [editing, setEdition] = React.useState(null);
  const theme = useTheme();

  const initialValue = user[editing];
  const [name, lastName] = user.name.split(' ');
  const initials = `${name.charAt(0).toUpperCase()}${
    lastName ? lastName.charAt(0).toUpperCase() : ''
  }`;

  return (
    <Page name="Mon profil">
      {!editing ? (
        <Profile setEdition={setEdition} />
      ) : (
        <>
          <AccountHeader>
            <AvatarWrapper color={theme.lightGray}>
              <Avatar
                style={{
                  backgroundColor: theme.primaryColor,
                  color: theme.white,
                  width: '100px',
                  height: '100px',
                  fontSize: '42px',
                }}
              >
                {initials}
              </Avatar>
            </AvatarWrapper>
          </AccountHeader>

          <PropertyEdit
            property={editing}
            setEdition={setEdition}
            initialValue={initialValue}
            onUpdate={updateUser}
          />
        </>
      )}
    </Page>
  );
};

export default Account;

import React from 'react';
import Firebase from 'firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';

import { Page } from '../common';
import { useSession, useTheme } from '../../hooks';
import {
  Loader,
  Heading,
  StripeButton,
  Container,
  Text,
} from '../../styleguide';
import { TextWrapper } from '../../styles/shop';
import { ButtonsWrapper } from '../../styles/form';

const stripeOAuthUrl = process.env.REACT_APP_STRIPE_OAUTH_URL;
const stripeClientID = process.env.REACT_APP_STRIPE_CLIENT_ID;
const appURL = process.env.REACT_APP_FIREBASE_WEBAPP_URL;

const StripeOAuth = props => {
  const { user } = useSession();
  const theme = useTheme();

  const [value, loading, error] = useCollection(
    Firebase.firestore()
      .collection('shops')
      .where('ownerID', '==', user.id),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (loading) {
    return (
      <Loader color={theme.primaryColor} background={theme.backgroundColor} />
    );
  }

  if (error) {
    throw new Error(error.toString());
  }

  const shop = { ...value.docs[0].data(), id: value.docs[0].id };
  const shopProfilURL = `${appURL}/shop/${shop.id}`;
  const [firstName, lastName] = user.name.split(' ');

  const OAuthURL = `
    ${stripeOAuthUrl}?client_id=${stripeClientID}&state=${
    shop.id
  }&stripe_user[email]=${
    user.email
  }&stripe_user[url]=${shopProfilURL}&stripe_user[business_name]=${
    shop.name
  }&stripe_user[first_name]=${firstName}&stripe_user[last_name]=${lastName ||
    ''}
  `;

  return (
    <Page name="Création compte Stripe">
      <Container>
        <Heading size={900} centered>
          Finalisation inscription - Stripe
        </Heading>
        <TextWrapper withTopMargin>
          <Text size={500} muted styles={{ marginTop: 8 }}>
            Pour finaliser votre inscription, vous devez créer votre compte
            Stripe. Cela vous prendra moins de 5min.
          </Text>
        </TextWrapper>
        <TextWrapper>
          <Text size={400} muted>
            La gestion des paiements et des virements est faite par Stripe, un
            outil respectant toutes les normes et lois afin de vous offrir un
            service de qualité.
          </Text>
        </TextWrapper>
        <ButtonsWrapper>
          <a
            href={OAuthURL}
            target="_blank"
            rel="noopener noreferrer"
            style={{ textDecoration: 'none' }}
          >
            <StripeButton content="Connexion stripe" />
          </a>
        </ButtonsWrapper>
      </Container>
    </Page>
  );
};

export default StripeOAuth;

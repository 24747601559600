import React from 'react';
import { useFormik } from 'formik';
import parsePhoneNumber from 'libphonenumber-js';

import { Button, Heading, Input, PhoneInput } from '../../../styleguide';
import { FormWrapper, ButtonsWrapper } from '../../../styles/form';
import { signupShopLegal } from '../../../forms';

const parsePhoneBeforeSubmit = phone => {
  if (!phone) {
    return '';
  }
  return parsePhoneNumber(phone, 'FR').format('INTERNATIONAL');
};

const LegalForm = props => {
  const { onShopUpdate } = props;

  const formik = useFormik({
    initialValues: {
      email: '',
      ownerFirstname: '',
      ownerLastname: '',
      phone: '',
      siret: '',
      denomination: '',
      status: '',
    },
    validationSchema: signupShopLegal,
    onSubmit: values => {
      onShopUpdate({
        legal: { ...values, phone: parsePhoneBeforeSubmit(values.phone) },
      });
    },
  });

  const canSubmit =
    !!formik.values.email &&
    !!formik.values.phone &&
    !!formik.values.ownerFirstname &&
    !!formik.values.ownerLastname &&
    !!formik.values.denomination &&
    !!formik.values.siret &&
    !!formik.values.status;

  return (
    <>
      <Heading size={900} centered>
        Informations légales
      </Heading>
      <FormWrapper onSubmit={formik.handleSubmit}>
        <Input
          name="denomination"
          label="Dénomination"
          required
          placeholder="Mon commerce SAS"
          value={formik.values.denomination}
          onChange={formik.handleChange}
          error={
            formik.touched.denomination && Boolean(formik.errors.denomination)
          }
          helperText={formik.touched.denomination && formik.errors.denomination}
        />

        <Input
          name="ownerLastname"
          label="Nom du dirigeant"
          required
          placeholder="John"
          value={formik.values.ownerLastname}
          onChange={formik.handleChange}
          error={
            formik.touched.ownerLastname && Boolean(formik.errors.ownerLastname)
          }
          helperText={
            formik.touched.ownerLastname && formik.errors.ownerLastname
          }
        />

        <Input
          name="ownerFirstname"
          label="Prenom du dirigeant"
          required
          placeholder="Doe"
          value={formik.values.ownerFirstname}
          onChange={formik.handleChange}
          error={
            formik.touched.ownerFirstname &&
            Boolean(formik.errors.ownerFirstname)
          }
          helperText={
            formik.touched.ownerFirstname && formik.errors.ownerFirstname
          }
        />

        <PhoneInput
          required
          placeholder="06 01 02 03 04"
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />

        <Input
          name="email"
          label="Email"
          required
          type="email"
          placeholder="The best restaurant"
          value={formik.values.email}
          onChange={formik.handleChange}
          error={formik.touched.email && Boolean(formik.errors.email)}
          helperText={formik.touched.email && formik.errors.email}
        />

        <Input
          name="siret"
          label="SIRET ou SIREN"
          required
          placeholder="000 000 000"
          value={formik.values.siret}
          onChange={formik.handleChange}
          error={formik.touched.siret && Boolean(formik.errors.siret)}
          helperText={formik.touched.siret && formik.errors.siret}
        />

        <Input
          name="status"
          label="Status"
          required
          placeholder="SAS"
          value={formik.values.status}
          onChange={formik.handleChange}
          error={formik.touched.status && Boolean(formik.errors.status)}
          helperText={formik.touched.status && formik.errors.status}
        />

        <ButtonsWrapper>
          <Button type="submit" disabled={!canSubmit}>
            Finaliser
          </Button>
        </ButtonsWrapper>
      </FormWrapper>
    </>
  );
};

export default LegalForm;

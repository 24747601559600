import React from 'react';
import { InputAdornment, IconButton } from '@material-ui/core';

import plurialize from '../../../../common/plurialize';
import createID from '../../../../actions/helpers/createID';
import {
  FlexFieldsWrapper,
  SupplementsFieldsWrapper,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from '../../../../styles/shop';
import { Input, Button, Heading } from '../../../../styleguide';
import { useTheme } from '../../../../hooks';
import {
  EuroIcon,
  ExpandIcon,
  DeleteIcon,
  EditIcon,
  CheckIcon,
  AvailabilityIcon,
} from '../../../../styleguide/icons';
import { parseNumber } from '../../../../common';

const SupplementsForm = props => {
  const {
    supplements,
    onSupplementsUpdate,
    onSupplementChange,
    onDelete,
  } = props;
  const DEFAULT_SUPPLEMENT = {
    id: createID(),
    name: '',
    price: 0,
    avaibility: true,
  };
  const [currentSupplement, setCurrentSupplement] = React.useState(
    DEFAULT_SUPPLEMENT
  );
  const [editSupplement, setEditSupplement] = React.useState(null);
  const theme = useTheme();

  const onChange = (property, value) => {
    setCurrentSupplement({ ...currentSupplement, [property]: value });
  };

  const onEditChange = (property, value) => {
    setEditSupplement({ ...editSupplement, [property]: value });
  };

  const onSubmitSupplement = () => {
    onSupplementChange({
      ...currentSupplement,
      price: parseNumber(currentSupplement.price),
    });
    setCurrentSupplement(DEFAULT_SUPPLEMENT);
  };

  const onSubmitUpdate = () => {
    const updated = {
      ...editSupplement,
      price: parseNumber(editSupplement.price),
    };

    const updatedSupplements = supplements.map(supplement => {
      if (supplement.id === editSupplement.id) {
        return updated;
      }
      return supplement;
    });

    onSupplementsUpdate(updatedSupplements);
    setEditSupplement(null);
  };

  const inputsStyle = { minWidth: '200px' };
  const canSubmit =
    !currentSupplement ||
    (!!currentSupplement.name && !!currentSupplement.price);
  const canConfirmUpdate =
    !!editSupplement &&
    !!editSupplement.name &&
    !!editSupplement.price &&
    !!editSupplement.id;

  return (
    <Accordion
      square
      style={{ width: '100%' }}
      defaultExpanded={!!supplements.length}
    >
      <AccordionSummary
        expandIcon={<ExpandIcon size="16px" />}
        aria-controls="panel2a-content"
        id="panel2a-header"
      >
        <Heading size={500}>
          Suppléments{' '}
          {!!supplements?.length
            ? plurialize({
                value: supplements.length,
                default: ' - ($value item)',
                more: ' - ($value items)',
              })
            : null}
        </Heading>
      </AccordionSummary>
      <AccordionDetails>
        <SupplementsFieldsWrapper>
          {!!supplements?.length
            ? supplements.map(item => (
                <React.Fragment key={item.id}>
                  <FlexFieldsWrapper align="center" padding={0}>
                    <Input
                      disabled={editSupplement?.id !== item.id}
                      wrapperProps={{ justify: 'flex-start' }}
                      label="Nom"
                      size="small"
                      variant="outlined"
                      required
                      placeholder="Fromage"
                      fullWidth
                      style={inputsStyle}
                      value={
                        editSupplement?.id !== item.id
                          ? item.name
                          : editSupplement.name
                      }
                      {...(editSupplement?.id === item.id
                        ? {
                            onChange: event =>
                              onEditChange('name', event.target.value),
                          }
                        : {})}
                    />
                    <Input
                      disabled={editSupplement?.id !== item.id}
                      wrapperProps={{ justify: 'flex-start' }}
                      label="Prix TTC"
                      size="small"
                      variant="outlined"
                      required
                      placeholder="2.50"
                      fullWidth
                      style={inputsStyle}
                      value={
                        editSupplement?.id !== item.id
                          ? item.price
                          : editSupplement.price
                      }
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EuroIcon size="16px" />
                          </InputAdornment>
                        ),
                      }}
                      {...(editSupplement?.id === item.id
                        ? {
                            onChange: event =>
                              onEditChange('price', event.target.value),
                          }
                        : {})}
                    />
                    {editSupplement?.id === item.id ? (
                      <>
                        <IconButton
                          disabled={!canConfirmUpdate}
                          minimal
                          onClick={onSubmitUpdate}
                        >
                          <CheckIcon
                            size="20px"
                            color={
                              !canConfirmUpdate
                                ? theme.textColor
                                : theme.primaryColor
                            }
                          />
                        </IconButton>
                        <IconButton
                          minimal
                          onClick={() =>
                            onEditChange(
                              'avaibility',
                              !editSupplement.avaibility
                            )
                          }
                        >
                          <AvailabilityIcon
                            size="20px"
                            color={
                              !!editSupplement.avaibility
                                ? theme.primaryColor
                                : theme.red
                            }
                          />
                        </IconButton>
                      </>
                    ) : (
                      <>
                        <IconButton
                          minimal
                          onClick={() => setEditSupplement(item)}
                        >
                          <EditIcon size="20px" />
                        </IconButton>
                        <AvailabilityIcon
                          size="20px"
                          color={
                            !!item.avaibility ? theme.primaryColor : theme.red
                          }
                        />
                      </>
                    )}
                    <IconButton minimal onClick={() => onDelete(item.id)}>
                      <DeleteIcon size="20px" />
                    </IconButton>
                  </FlexFieldsWrapper>
                  <hr />
                </React.Fragment>
              ))
            : null}

          {currentSupplement ? (
            <FlexFieldsWrapper>
              <Input
                wrapperProps={{ justify: 'flex-start' }}
                label="Nom"
                size="small"
                variant="outlined"
                required
                placeholder="Fromage"
                fullWidth
                style={inputsStyle}
                onChange={event => onChange('name', event.target.value)}
                value={currentSupplement.name}
              />
              <Input
                wrapperProps={{ justify: 'flex-start' }}
                label="Prix TTC"
                size="small"
                variant="outlined"
                required
                placeholder="2.50"
                fullWidth
                style={inputsStyle}
                onChange={event => onChange('price', event.target.value)}
                value={currentSupplement.price}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <EuroIcon size="16px" />
                    </InputAdornment>
                  ),
                }}
              />
            </FlexFieldsWrapper>
          ) : null}

          <FlexFieldsWrapper>
            <Button
              small
              primary
              onClick={onSubmitSupplement}
              startIcon={<CheckIcon color={theme.textColor} size="20px" />}
              disabled={!canSubmit}
            >
              Valider
            </Button>
          </FlexFieldsWrapper>
        </SupplementsFieldsWrapper>
      </AccordionDetails>
    </Accordion>
  );
};

export default SupplementsForm;

import React from 'react';

import { EmptyPage } from '../../common';
import {
  TabContentWrapper,
  EmptyTabWrapper,
  ItemButtonsWrapper,
} from '../../../styles/shop';
import { SPACING } from '../../../styles/constants';
import { Heading, ContentLoader } from '../../../styleguide';
import { useTheme } from '../../../hooks';
import TabPanel from '../../shop/tabs/TabPanel';
import OrdersListTable from './OrdersListTable';

const OrdersTab = props => {
  const { value, rusher, orders, ordersLoading } = props;
  const theme = useTheme();

  return (
    <TabPanel value={value} index={1}>
      <TabContentWrapper>
        <Heading size={800}>Vos Commandes</Heading>

        <ItemButtonsWrapper theme={theme}></ItemButtonsWrapper>

        {ordersLoading ? (
          <ContentLoader text="Vos commandes sont en cours de chargements." />
        ) : !orders || !orders.length ? (
          <EmptyTabWrapper>
            <EmptyPage
              titleSize={700}
              withoutButton
              title="Vous n'avez pas encore livré de commandes"
              description={`
                  Vous n'avez livré aucune commande pour le moment. Mais cela ne va pas tarder 💸
                  `}
              containerProps={{
                padding: `${SPACING[300]} ${SPACING[100]}`,
              }}
            />
          </EmptyTabWrapper>
        ) : (
          <OrdersListTable orders={orders} rusher={rusher} />
        )}
      </TabContentWrapper>
    </TabPanel>
  );
};

export default OrdersTab;

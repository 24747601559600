import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { useTheme } from '../../../../hooks';
import { Button, Text } from '../../../../styleguide';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import ORDER_STATUS from '../../../../actions/orders/helpers/STATUS';

const confirmDialogData = {
  title: 'Confirmation de la commande',
  description:
    "D'après les temps de préparation fourni par l'établissement, le temps de préparation estimé est : ",
  inputLabel:
    'Si vous estimé que le temps réel sera différent, vous pouvez directement entrer le temps que VOUS avez estimé',
};

const NewOrderDialog = props => {
  const {
    isOpen,
    confirmModal,
    preparationTime,
    onClose,
    onConfirm,
    onCancel,
    order,
  } = props;
  const theme = useTheme();
  const temporaryDisabled = !!order?.inTreatment;

  React.useEffect(() => {
    if (order.status !== ORDER_STATUS.inValidation) {
      onClose();
    }
  }, [order, onClose]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="order-alert-dialog-title"
    >
      <DialogTitle id="order-alert-dialog-title">
        {confirmDialogData.title}
      </DialogTitle>
      <DialogContent>
        <Alert severity="error" style={{ marginBottom: '16px' }}>
          <AlertTitle>Attention {'⚠️'}</AlertTitle>
          Vous êtes sur le point d'accepter une commande pour un établissement,
          en êtes vous sur ?
        </Alert>
        <Text size={400}>{confirmDialogData.description}</Text>
        <Text size={500} color={theme.primaryColor}>
          {` ${preparationTime}`}min{' '}
        </Text>
      </DialogContent>

      {confirmModal === 'confirm' ? (
        <DialogActions>
          <Button onClick={onClose} small minimal>
            Attendre
          </Button>
          <Button
            onClick={onConfirm}
            color="primary"
            small
            disabled={temporaryDisabled}
          >
            Confirmer
          </Button>
        </DialogActions>
      ) : (
        <DialogActions>
          <Button onClick={onClose} small minimal>
            Retour
          </Button>
          <Button
            onClick={onCancel}
            color="primary"
            styles={{ backgroundColor: theme.red }}
            small
            disabled={temporaryDisabled}
          >
            Refuser
          </Button>
        </DialogActions>
      )}
    </Dialog>
  );
};

export default NewOrderDialog;

import React from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';

import { useTheme } from '../../../../hooks';
import { Button, Text } from '../../../../styleguide';
import computePreparationTime from '../../../../actions/orders/helpers/computePreparationTime';
import { computeEstimatedTime } from '../../../../actions/orders/helpers/computeTimesHelpers';
import ORDER_STATUS from '../../../../actions/orders/helpers/STATUS';

const AttributionDialog = props => {
  const { isOpen, onClose, onConfirm, selectedOrder, isReady } = props;
  const theme = useTheme();
  const preparationTime =
    selectedOrder.estimatedTimeToDeliver ||
    computePreparationTime(selectedOrder.itemsList);

  React.useEffect(() => {
    if (
      selectedOrder.status === ORDER_STATUS.canceled ||
      selectedOrder.status === ORDER_STATUS.delivered
    ) {
      onClose();
    }
  }, [selectedOrder, onClose]);

  const temporaryDisabled = !!selectedOrder?.inTreatment;

  if (isReady) {
    const estimatedDeliveryTime = computeEstimatedTime({
      ts: selectedOrder.readyAt,
      withDelay: true,
      seconds: selectedOrder.duration,
    });

    return (
      <Dialog
        open={isOpen}
        onClose={onClose}
        aria-labelledby="order-alert-dialog-title"
      >
        <DialogTitle id="order-alert-dialog-title">
          Attribution de la commande
        </DialogTitle>
        <DialogContent>
          <Text size={400}>
            Attention, si vous vous attribuez la commande, vous devrez pouvoir
            la livrer à{' '}
            <span style={{ color: theme.primaryColor }}>
              {estimatedDeliveryTime}
            </span>
          </Text>
        </DialogContent>

        <DialogActions>
          <Button onClick={onClose} small minimal>
            Attendre
          </Button>
          <Button
            onClick={onConfirm}
            color="primary"
            small
            disabled={temporaryDisabled}
          >
            Confirmer
          </Button>
        </DialogActions>
      </Dialog>
    );
  }

  const estimatedDeliveryTime = computeEstimatedTime({
    ts: selectedOrder.acceptedAt,
    withDelay: true,
    minutes: preparationTime,
    seconds: selectedOrder.duration,
  });

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="order-alert-dialog-title"
    >
      <DialogTitle id="order-alert-dialog-title">
        Attribution de la commande
      </DialogTitle>
      <DialogContent>
        <Text size={400}>
          D'après les temps de préparation fournis par l'établissement, nous
          estimons que la commande sera prête dans:{' '}
          <span style={{ color: theme.primaryColor }}>
            {preparationTime}min
          </span>
        </Text>
        <br />
        <Text size={400}>
          Attention, si vous vous attribuez la commande, vous devrez pouvoir
          livrer la commande à{' '}
          <span style={{ color: theme.primaryColor }}>
            {estimatedDeliveryTime}
          </span>
        </Text>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} small minimal>
          Attendre
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          small
          disabled={temporaryDisabled}
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AttributionDialog;

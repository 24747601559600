import React from 'react';
import { toaster } from 'evergreen-ui';

import finishOrder from '../../../actions/orders/finishOrder';
import { Heading } from '../../../styleguide';
import { TabContentWrapper } from '../../../styles/shop';
import {
  OrdersListWrapper,
  EmptyNewOrders,
} from '../../../styles/orders-cards';
import TabPanel from '../../shop/tabs/TabPanel';
import { DeliveryCard } from './Cards';
import DeliveryDialog from './dialogs/DeliveryDialog';
import EmptyTab from './EmptyTab';

const DeliveryTab = props => {
  const { value, orders } = props;
  const [confirmModal, setConfirmModal] = React.useState(null);
  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const [isLoading, setLoading] = React.useState(null);

  React.useEffect(() => {
    if (!!selectedOrder && !!confirmModal) {
      const orderToUpdate = orders.find(o => o.id === selectedOrder.id);

      if (!orderToUpdate || !!orderToUpdate?.inTreatment) {
        setConfirmModal(null);
        return;
      }
    }
  }, [orders, selectedOrder, confirmModal]);

  const onConfirm = async order => {
    try {
      setLoading(order.id);
      const orderToUpdate = orders.find(o => o.id === order.id);

      if (!orderToUpdate || !!orderToUpdate?.inTreatment) {
        setConfirmModal(null);
        toaster.warning(
          `⏳ Impossible commande en cours de traitement, réessayez plus tard`
        );
        return;
      }

      await finishOrder(orderToUpdate, 'DELIVERED');
      setConfirmModal(null);

      toaster.success(`Commande livré 🎉`, {
        description: `La commande numéro ${order.id} vous est désormais livré, félicitations 👏`,
      });
      setLoading(null);
    } catch (error) {
      setLoading(false);
      console.error({ error });
      toaster.danger(`Une erreur est survenue`, {
        description: `Impossible de terminer la commande ${order.id}, contactez notre équipe`,
      });
    }
  };

  const hasExistingOrders = !!orders.length;

  return (
    <TabPanel value={value} index={2}>
      <TabContentWrapper>
        {hasExistingOrders ? (
          <>
            <Heading size={800}>Les commandes en livraison</Heading>

            <OrdersListWrapper>
              {orders.map(order => {
                return (
                  <DeliveryCard
                    key={order.id}
                    onClickConfirm={() => {
                      setConfirmModal('confirm');
                      setSelectedOrder(order);
                    }}
                    order={order}
                    isLoading={isLoading === order.id}
                  />
                );
              })}
            </OrdersListWrapper>
            {!!confirmModal ? (
              <DeliveryDialog
                isOpen={!!confirmModal}
                confirmModal={confirmModal}
                order={selectedOrder}
                onClose={() => setConfirmModal(null)}
                onConfirm={() => onConfirm(selectedOrder)}
              />
            ) : null}
          </>
        ) : (
          <EmptyNewOrders>
            <EmptyTab
              title={'Aucune commande en livraison'}
              description={
                'Vous retrouvez vos commandes en cours de livraison 🚛'
              }
            />
          </EmptyNewOrders>
        )}
      </TabContentWrapper>
    </TabPanel>
  );
};

export default DeliveryTab;

const ORDER_STATUS = {
  canceled: 'CANCELED',
  inValidation: 'WAITING_VALIDATION',
  inProgress: 'IN_PROGRESS',
  ready: 'READY_TO_GO',
  inDelivery: 'IN_DELIVERY',
  delivered: 'DELIVERED',
};

export default ORDER_STATUS;

import React from 'react';
import { isEqual, orderBy } from 'lodash';
import { InputAdornment, Switch, FormControlLabel } from '@material-ui/core';
import { useFormik } from 'formik';

import { FormWrapper } from '../../../../styles/form';
import {
  HeaderDialog,
  ContentBlockDialog,
  HeaderDialogFlexBlock,
} from '../../../../styles/admin';
import { useTheme } from '../../../../hooks';
import { SPACING } from '../../../../styles/constants';
import {
  FlexFieldsWrapper,
  AccordionDetailsContent,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from '../../../../styles/shop';
import { Input, FullscreenDialog, Heading, Text } from '../../../../styleguide';
import {
  EuroIcon,
  ExpandIcon,
  PercentageIcon,
} from '../../../../styleguide/icons';
import SupplementsForm from './SupplementsForm';
import OptionsForm from './Options';
import { itemSchema } from '../../../../forms';

const ProductModal = props => {
  const { onSubmit, onClose, initialValue, open } = props;
  const theme = useTheme();

  const initialProduct = {
    ...initialValue,
    VAT: initialValue?.VAT || 5.5,
    options: initialValue?.options || [],
  } || {
    name: '',
    price: null,
    avaibility: true,
    category: '',
    supplements: [],
    options: [],
    pictureURL: '',
    preparationTime: 10,
    isMenu: false,
    description: '',
    VAT: 5.5,
  };
  const initialSupplement = initialValue ? initialValue.supplements : [];
  const initialOptions = initialValue ? initialProduct.options : [];
  const [supplements, setSupplements] = React.useState(initialSupplement);
  const [options, setOptions] = React.useState(initialOptions);

  const formik = useFormik({
    initialValues: {
      ...initialProduct,
      VAT: initialProduct?.VAT || 5.5,
    },
    validationSchema: itemSchema,
    onSubmit: values => {
      const finalProduct = {
        ...values,
        supplements,
        options,
      };
      onSubmit(finalProduct);
      onClose();
    },
  });

  const onSupplementChange = supplement => {
    setSupplements([...supplements, supplement]);
  };

  const onSupplementsUpdate = supplements => {
    setSupplements(supplements);
  };

  const onDeleteSupplement = itemID => {
    const filteredSupplements = supplements.filter(item => item.id !== itemID);
    setSupplements(filteredSupplements);
  };

  const onOptionChange = option => {
    const filteredOptions = options.filter(item => item.id !== option.id);
    setOptions([...filteredOptions, option]);
  };

  const onDeleteOption = optionID => {
    const filteredOptions = options.filter(option => option.id !== optionID);
    setOptions(filteredOptions);
  };

  const inputsStyle = { minWidth: '150px' };
  const canSubmit =
    (!!formik.dirty ||
      !isEqual(supplements, initialSupplement) ||
      !isEqual(options, initialOptions)) &&
    !formik.isSubmitting;

  const orderedOptions = options => {
    return orderBy(options, i => i.order, 'asc');
  };

  return (
    <FullscreenDialog
      isOpen={open}
      onClose={onClose}
      onConfirm={formik.handleSubmit}
      title={
        !!initialValue?.name
          ? `Modification d'un produit`
          : "Création d'un produit"
      }
      cantConfirm={!canSubmit}
      isLoading={false}
      theme={theme}
      buttonText={!initialValue?.name ? 'Créer' : 'Enregistrer'}
    >
      <HeaderDialog>
        <Text muted size={400}>
          Lors de la création de votre produit, vous pouvez lui attribuer une
          catégorie, c'est grace à celle ci que votre carte sera organisée.{' '}
          <br />
          Vous pouvez aussi ajouter des suppléments ou des options à votre
          produit. <br />
          Cela vous donne la fléxibilité pour créer les produits qui
          correspondent à vos besoins.
        </Text>
      </HeaderDialog>

      <FormWrapper
        style={{ margin: '0 auto', padding: 0, paddingTop: SPACING[100] }}
        maxWidth="1260px"
      >
        <Accordion square style={{ width: '100%' }} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandIcon size="16px" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Heading size={500}>Informations</Heading>
          </AccordionSummary>
          <AccordionDetails>
            <AccordionDetailsContent>
              <FlexFieldsWrapper align="stretch">
                <ContentBlockDialog theme={theme}>
                  <HeaderDialogFlexBlock
                    style={{ paddingBottom: SPACING[100] }}
                  >
                    <Heading size={500} styles={{ paddingRight: SPACING[100] }}>
                      Actions
                    </Heading>
                  </HeaderDialogFlexBlock>
                  <FormControlLabel
                    control={
                      <Switch
                        name="avaibility"
                        checked={formik.values.avaibility}
                        onChange={formik.handleChange}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        error={
                          formik.touched.avaibility &&
                          Boolean(formik.errors.avaibility)
                        }
                        helperText={
                          formik.touched.avaibility && formik.errors.avaibility
                        }
                      />
                    }
                    label={
                      <Text size={400} muted>
                        Disponible
                      </Text>
                    }
                  />
                </ContentBlockDialog>
                <ContentBlockDialog theme={theme}>
                  <HeaderDialogFlexBlock
                    style={{ paddingBottom: SPACING[100] }}
                  >
                    <Heading size={500} styles={{ paddingRight: SPACING[100] }}>
                      Catégorie
                    </Heading>
                  </HeaderDialogFlexBlock>
                  <Input
                    name="category"
                    wrapperProps={{ justify: 'flex-start' }}
                    label="Catégorie"
                    size="small"
                    variant="outlined"
                    required
                    placeholder="Cheeseburger"
                    fullWidth
                    value={formik.values.category}
                    style={inputsStyle}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.category && Boolean(formik.errors.category)
                    }
                    helperText={
                      formik.touched.category && formik.errors.category
                    }
                  />
                </ContentBlockDialog>
                <ContentBlockDialog theme={theme}>
                  <HeaderDialogFlexBlock
                    style={{ paddingBottom: SPACING[100] }}
                  >
                    <Heading size={500} styles={{ paddingRight: SPACING[100] }}>
                      Temps préparation (mn)
                    </Heading>
                  </HeaderDialogFlexBlock>
                  <HeaderDialogFlexBlock>
                    <Input
                      name="preparationTime"
                      wrapperProps={{ justify: 'flex-start', maxWidth: 400 }}
                      label="Temps"
                      size="small"
                      variant="outlined"
                      required
                      type="number"
                      placeholder="10"
                      fullWidth
                      value={formik.values.preparationTime}
                      onChange={formik.handleChange}
                      style={{ maxWidth: '140px' }}
                      inputProps={{
                        min: '0',
                      }}
                      error={
                        formik.touched.preparationTime &&
                        Boolean(formik.errors.preparationTime)
                      }
                      helperText={
                        formik.touched.preparationTime &&
                        formik.errors.preparationTime
                      }
                    />
                  </HeaderDialogFlexBlock>
                </ContentBlockDialog>
                <ContentBlockDialog theme={theme}>
                  <HeaderDialogFlexBlock
                    style={{ paddingBottom: SPACING[100] }}
                  >
                    <Heading size={500} styles={{ paddingRight: SPACING[100] }}>
                      Tarification
                    </Heading>
                  </HeaderDialogFlexBlock>
                  <HeaderDialogFlexBlock>
                    <Input
                      name="price"
                      wrapperProps={{ justify: 'flex-start' }}
                      label="Prix TTC"
                      size="small"
                      variant="outlined"
                      required
                      placeholder="5.50"
                      fullWidth
                      type="number"
                      value={formik.values.price}
                      onChange={formik.handleChange}
                      style={{ maxWidth: '120px' }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EuroIcon size="16px" />
                          </InputAdornment>
                        ),
                      }}
                      error={
                        formik.touched.price && Boolean(formik.errors.price)
                      }
                      helperText={formik.touched.price && formik.errors.price}
                    />
                    <Input
                      name="VAT"
                      wrapperProps={{ justify: 'flex-start' }}
                      label="TVA"
                      size="small"
                      variant="outlined"
                      required
                      placeholder="5.5"
                      fullWidth
                      type="number"
                      value={formik.values.VAT}
                      onChange={formik.handleChange}
                      style={{ maxWidth: '120px' }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PercentageIcon size="16px" />
                          </InputAdornment>
                        ),
                      }}
                      error={formik.touched.VAT && Boolean(formik.errors.VAT)}
                      helperText={formik.touched.VAT && formik.errors.VAT}
                    />
                  </HeaderDialogFlexBlock>
                </ContentBlockDialog>
              </FlexFieldsWrapper>
              <FlexFieldsWrapper>
                <Input
                  name="name"
                  wrapperProps={{ justify: 'flex-start' }}
                  label="Titre"
                  size="small"
                  variant="outlined"
                  required
                  placeholder="Cheeseburger"
                  fullWidth
                  value={formik.values.name}
                  onChange={formik.handleChange}
                  style={inputsStyle}
                  error={formik.touched.name && Boolean(formik.errors.name)}
                  helperText={formik.touched.name && formik.errors.name}
                />
                <Input
                  name="pictureURL"
                  wrapperProps={{ justify: 'flex-start' }}
                  label="Photo"
                  size="small"
                  variant="outlined"
                  placeholder="cheeseburger.png"
                  fullWidth
                  value={formik.values.pictureURL}
                  onChange={formik.handleChange}
                  style={inputsStyle}
                  error={
                    formik.touched.pictureURL &&
                    Boolean(formik.errors.pictureURL)
                  }
                  helperText={
                    formik.touched.pictureURL && formik.errors.pictureURL
                  }
                />
              </FlexFieldsWrapper>

              <FlexFieldsWrapper>
                <Input
                  name="description"
                  wrapperProps={{ justify: 'flex-start' }}
                  label="Description"
                  size="small"
                  variant="outlined"
                  required
                  placeholder="Salade - Tomate - Oignons"
                  multiline
                  rows={4}
                  fullWidth
                  value={formik.values.description}
                  style={inputsStyle}
                  onChange={formik.handleChange}
                  error={
                    formik.touched.description &&
                    Boolean(formik.errors.description)
                  }
                  helperText={
                    formik.touched.description && formik.errors.description
                  }
                />
              </FlexFieldsWrapper>
            </AccordionDetailsContent>
          </AccordionDetails>
        </Accordion>

        <OptionsForm
          options={orderedOptions(options)}
          onOptionsChange={onOptionChange}
          onDelete={onDeleteOption}
        />

        <SupplementsForm
          supplements={supplements}
          onSupplementChange={onSupplementChange}
          onSupplementsUpdate={onSupplementsUpdate}
          onDelete={onDeleteSupplement}
        />
      </FormWrapper>
    </FullscreenDialog>
  );
};

export default ProductModal;

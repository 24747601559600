import React from 'react';
import Firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { IconButton } from '@material-ui/core';
import { DataGrid } from '@material-ui/data-grid';
import { DateTime } from 'luxon';

import DashboardHeader from '../components/common/Header';
import { Page } from '../../../common';
import {
  DashboardPageContainer,
  DashboardPageBlockContainer,
  BlockStatsRow,
  BlockWrapper,
} from '../../../../styles/admin';
import { Button, Container, NavLink } from '../../../../styleguide';
import createDiscount from '../../../../actions/discounts/createDiscount';
import updateDiscount from '../../../../actions/discounts/updateDiscount';
import { SearchIcon, PlusIcon, ReturnIcon } from '../../../../styleguide/icons';

import { useTheme } from '../../../../hooks';
import StatCard from '../components/common/StatCard';
import { SPACING } from '../../../../styles/constants';
import BlockHeader from '../components/common/BlockHeader';
import SearchModal from '../components/common/SearchModal';
import DiscountModal from '../components/common/DiscountModal';

const columns = [
  {
    field: 'code',
    headerName: 'Code',
    width: 120,
  },
  {
    field: 'active',
    headerName: 'Active',
    width: 110,
  },
  {
    field: 'active',
    headerName: 'Active',
    width: 110,
    valueFormatter: ({ value }) => (value ? '✅' : '❌'),
  },
  {
    field: 'appFeesValue',
    headerName: '% frais app',
    width: 160,
    valueFormatter: ({ value }) => `${value}%`,
  },
  {
    field: 'deliveryFeesValue',
    headerName: '% frais livraison',
    width: 160,
    valueFormatter: ({ value }) => `${value}%`,
  },
  {
    field: 'exclusive',
    headerName: 'Exclusive',
    width: 110,
    valueFormatter: ({ value }) => (value ? '✅' : '❌'),
  },
  {
    field: 'exclusiveShop',
    headerName: 'Exclusif shop',
    width: 220,
    valueFormatter: ({ value }) => (value ? `✅: ${value}` : '❌'),
  },
  {
    field: 'createdAt',
    headerName: 'Crée le',
    width: 220,
  },
  {
    field: 'createdBy',
    headerName: 'Crée par',
    width: 230,
  },
  {
    field: 'usedByCount',
    headerName: 'Nombre utilisation',
    width: 220,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 280,
  },
];

const DiscountsDashboard = () => {
  const [searchDialog, setSearchDialog] = React.useState(false);
  const [dialog, setDialog] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);

  const theme = useTheme();

  const [discounts, discountsLoading, discountsError] = useCollectionData(
    Firebase.firestore()
      .collection('discounts')
      .orderBy('createdAt', 'desc'),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
        timestampsInSnapshots: true,
      },
    }
  );

  const onRowClick = params => {
    const discount = discounts.find(d => d.id === params.row.id);
    setDialog({ updatedData: null, data: discount });
  };

  const onSubmit = async (discount, id) => {
    setLoading(true);

    if (!!dialog?.data) {
      await updateDiscount(discount, id);
    } else {
      await createDiscount(discount);
    }

    setLoading(false);
    setDialog(null);
  };

  const {
    rows,
    discountsCount,
    activeCount,
    exclusiveCount,
    usedTotal,
  } = React.useMemo(() => {
    if (discountsLoading || !discounts?.length || !!discountsError) {
      return {
        rows: [],
        discountsCount: 0,
        activeCount: 0,
        exclusiveCount: 0,
        endedCount: 0,
      };
    }

    const rows = discounts.map(discount => ({
      code: discount.code,
      active: discount.active,
      createdBy: discount.createdBy,
      createdAt: DateTime.fromSeconds(
        discount.createdAt.seconds
      ).toLocaleString(DateTime.DATETIME_SHORT),
      appFeesValue: discount.appFeesValue,
      deliveryFeesValue: discount.deliveryFeesValue,
      usedByCount: discount.usedBy.length,
      exclusive: discount.exclusive,
      id: discount.id,
      exclusiveShop: discount.exclusiveShop,
    }));

    const discountsCount = discounts.length;
    const activeCount = discounts.filter(discount => discount.active).length;
    const exclusiveCount = discounts.filter(discount => discount.exclusive)
      .length;
    const usedTotal = discounts.reduce(
      (acc, discount) => acc + discount.usedBy.length,
      0
    );

    return {
      rows,
      discountsCount,
      activeCount,
      exclusiveCount,
      usedTotal,
    };
  }, [discountsLoading, discounts, discountsError]);

  if (discountsError) {
    throw new Error(discountsError.toString());
  }

  return (
    <Page
      name="Les promotions"
      navbarLeftChild={
        <NavLink
          color={theme.headingColor}
          to={{ pathname: '/admin/dashboard' }}
        >
          <IconButton aria-label="retour">
            <ReturnIcon color={theme.headingColor} size="16px" />
          </IconButton>
        </NavLink>
      }
    >
      <Container justify="flex-start">
        <DashboardHeader title="Les promotions" withBackButton />

        <DashboardPageContainer theme={theme}>
          <BlockWrapper theme={theme}>
            <BlockHeader
              title={"Vue d'ensemble"}
              label={`Sur l'ensemble des promotions`}
              button={
                <Button
                  small
                  primary
                  startIcon={<PlusIcon color={theme.white} size="16px" />}
                  styles={{ marginRight: '8px' }}
                  onClick={() => setDialog({ updatedData: null, data: null })}
                >
                  Créer une promotion
                </Button>
              }
            />
            <BlockStatsRow>
              <StatCard label={'Total'} value={discountsCount} />
              <StatCard label={'Active'} value={activeCount} />
              <StatCard label={'Exclusive'} value={exclusiveCount} />
              <StatCard label={'Utilisations'} value={usedTotal} />
            </BlockStatsRow>
          </BlockWrapper>
          <BlockWrapper theme={theme} style={{ marginTop: SPACING[200] }}>
            <BlockHeader
              title={'Promotions'}
              label={`Tableau de bord`}
              button={
                <Button
                  small
                  color="secondary"
                  startIcon={<SearchIcon color={theme.white} size="16px" />}
                  styles={{ marginRight: '8px' }}
                  onClick={() => setSearchDialog(true)}
                >
                  Recherche user
                </Button>
              }
            />

            <DashboardPageBlockContainer>
              <DataGrid
                rows={rows}
                columns={columns}
                pageSize={25}
                stickyHeader
                loading={discountsLoading}
                onRowClick={onRowClick}
              />
            </DashboardPageBlockContainer>
          </BlockWrapper>
          <SearchModal
            isOpen={searchDialog}
            onClose={() => setSearchDialog(false)}
            ctaLabel={'Copier ID'}
            filters={['id', 'email', 'name']}
            operator="=="
            onSelectResult={async user => {
              await navigator.clipboard.writeText(user.id);
            }}
          />
          {!!dialog && (
            <DiscountModal
              onSubmit={(discount, id) => onSubmit(discount, id)}
              onClose={() => setDialog(null)}
              existingDiscount={dialog?.data}
              open={!!dialog}
              isLoading={isLoading}
            />
          )}
        </DashboardPageContainer>
      </Container>
    </Page>
  );
};

export default DiscountsDashboard;

import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

import { prepareDocForUpdate } from '../../helpers/firestoreHelpers';

const onSendInvoice = async invoice => {
  // console.log('Send invoice called', { invoice });
  ReactGA.event({
    category: 'Rusher',
    action: 'Send invoice',
  });

  const updatedInvoice = {
    status: 'sent',
  };

  try {
    await Firebase.firestore()
      .collection('rushersInvoices')
      .doc(invoice.id)
      .update(prepareDocForUpdate(updatedInvoice));

    console.log('Document successfully updated!');
  } catch (error) {
    console.error(`Whoops, couldn't send the invoice: ${error.message}`, {
      invoice,
    });
  }
};

export default onSendInvoice;

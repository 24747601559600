import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

import { prepareDocForUpdate } from '../helpers/firestoreHelpers';

const updateShopItemsCategoryOrders = (categoriesWithOrder, items) => {
  // console.log('Update Category Order called', { categoriesWithOrder });
  ReactGA.event({
    category: 'Shop',
    action: 'Update Category Order',
  });

  try {
    const updatedItems = items.map(item => {
      const order = categoriesWithOrder.filter(
        c => c.category === item.category
      )[0].order;

      item.order = order;

      return item;
    });

    return Promise.all(
      updatedItems.map(async item => {
        await Firebase.firestore()
          .collection('shopItems')
          .doc(item.id)
          .update(prepareDocForUpdate(item));
      })
    );
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

export default updateShopItemsCategoryOrders;

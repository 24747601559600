import React from 'react';
import { find, subtract, add } from 'lodash';
import { DataGrid } from '@material-ui/data-grid';
import { DateTime } from 'luxon';

import { parseNumber } from '../../../common';
import {
  BlockDataGridWrapper,
  ListHeaderWrapper,
  SubHeaderContent,
  ListHeaderActionsWrapper,
  BasicFlexBlock,
} from '../../../styles/common';
import { SPACING } from '../../../styles/constants';
import percentage from '../../../actions/orders/helpers/percentage';
import { useTheme } from '../../../hooks';
import {
  Heading,
  Button,
  Text,
  SimpleHoverableAction,
} from '../../../styleguide';
import { getInitialDeliveryFees } from '../../../common/fees';

const columns = [
  {
    field: 'id',
    headerName: 'ID',
    width: 250,
  },
  {
    field: 'amount',
    headerName: 'Montant HT',
    width: 150,
    type: 'number',
    valueFormatter: ({ value }) => `${parseNumber(value).toFixed(2)}€`,
  },
  {
    field: 'netAmount',
    headerName: 'Montant Net',
    type: 'number',
    width: 150,
    valueFormatter: ({ value }) => `${parseNumber(value).toFixed(2)}€`,
  },
  {
    field: 'orderAt',
    headerName: 'Commandé à',
    width: 150,
  },
  {
    field: 'deliverAt',
    headerName: 'Livré à',
    width: 200,
  },
];

const InvoiceDialogOrdersList = props => {
  const {
    orders,
    rusher,
    setInvoiceCreation,
    invoice,
    displayList,
    setListDisplay,
  } = props;
  const [selectedOrders, setSelectedOrders] = React.useState({
    orders: [],
    total: 0,
  });
  const theme = useTheme();

  const computeInitialSelection = () => {
    if (!!selectedOrders.orders.length) {
      const initialSelection = selectedOrders.orders.map(order => order.id);

      return initialSelection;
    } else {
      return [];
    }
  };

  const onSelectRows = ({ selectionModel }) => {
    if (!!selectionModel.length) {
      let total = 0;

      const targetedOrders = selectionModel.map(id => {
        const order = find(orders, o => o.id === id);
        const deliveryFees = order.discount
          ? getInitialDeliveryFees(order.distance)
          : order.deliveryFees;
        total = add(total, deliveryFees);
        return order;
      });

      setSelectedOrders({
        orders: [...targetedOrders],
        total,
      });
    } else {
      setSelectedOrders({
        orders: [],
        total: 0,
      });
    }
  };

  const onConfirmOrdersSelection = () => {
    setInvoiceCreation({
      ...invoice,
      data: {
        ...invoice.data,
        selectedOrders: selectedOrders.orders,
        total: selectedOrders.total,
        commission: percentage(rusher.percentage, selectedOrders.total),
        period: {
          from: selectedOrders.orders[selectedOrders.orders.length - 1].orderAt,
          to: selectedOrders.orders[0].orderAt,
        },
      },
    });
    setListDisplay(false);
  };

  const rows = orders.map(order => {
    const deliveryFees = order.discount
      ? getInitialDeliveryFees(order.distance)
      : order.deliveryFees;
    return {
      id: order.id,
      orderAt: DateTime.fromMillis(order.orderAt).toLocaleString(
        DateTime.DATETIME_SHORT
      ),
      amount: deliveryFees,
      netAmount: parseNumber(
        subtract(deliveryFees, percentage(rusher.percentage, deliveryFees))
      ),
      deliverAt: !!order.deliverAt
        ? DateTime.fromMillis(order.deliverAt).toLocaleString(
            DateTime.DATETIME_SHORT
          )
        : '-',
    };
  });

  const canContinue =
    !!selectedOrders.orders.length && selectedOrders.total >= 50;

  return (
    <BlockDataGridWrapper
      style={{
        padding: `${SPACING[100]} 0`,
        height: 'auto',
      }}
    >
      <ListHeaderWrapper>
        <Heading size={500} styles={{ padding: `${SPACING[100]} 0` }}>
          1 - Choisissez les commandes à facturer
        </Heading>

        <ListHeaderActionsWrapper>
          {displayList ? (
            <Button
              small
              styles={{ margin: SPACING[100] }}
              disabled={!canContinue}
              onClick={onConfirmOrdersSelection}
            >
              Continuer
            </Button>
          ) : (
            <Text size={500} styles={{ paddingRight: SPACING[100] }}>
              Total HT:{' '}
              <span
                style={{
                  color:
                    selectedOrders.total >= 50
                      ? theme.primaryColor
                      : theme.textColor,
                }}
              >
                {parseNumber(selectedOrders.total).toFixed(2)}€
              </span>
            </Text>
          )}
        </ListHeaderActionsWrapper>

        {displayList ? (
          <SubHeaderContent>
            <Text size={500} styles={{ paddingRight: SPACING[100] }}>
              Total HT:{' '}
              <span
                style={{
                  color:
                    selectedOrders.total >= 50
                      ? theme.primaryColor
                      : theme.textColor,
                }}
              >
                {parseNumber(selectedOrders.total).toFixed(2)}€
              </span>
            </Text>
            <Text size={300} muted>
              {'Minimum facturation de 50.00€ HT'}
            </Text>
          </SubHeaderContent>
        ) : null}
      </ListHeaderWrapper>

      {!displayList ? (
        <BasicFlexBlock padding={`${SPACING[400]} 0 ${SPACING[200]} 0`}>
          <SimpleHoverableAction
            size={500}
            onClick={() => setListDisplay(true)}
          >
            Voir la liste de commandes
          </SimpleHoverableAction>
        </BasicFlexBlock>
      ) : (
        <BlockDataGridWrapper>
          <DataGrid
            rows={rows}
            columns={columns}
            pageSize={15}
            stickyHeader
            checkboxSelection
            disableSelectionOnClick={true}
            onSelectionModelChange={onSelectRows}
            selectionModel={computeInitialSelection()}
          />
        </BlockDataGridWrapper>
      )}
    </BlockDataGridWrapper>
  );
};

export default InvoiceDialogOrdersList;

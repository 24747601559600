import React from 'react';
import { Chip } from '@material-ui/core';
import { subtract, add } from 'lodash';

import { CheckBox, Text } from '../../../../styleguide';
import { useTheme } from '../../../../hooks';
import { SPACING } from '../../../../styles/constants';
import { ShopProfilDialogSupplementLine } from '../../../../styles/shop-profil';
import { parseNumber } from '../../../../common';

const SupplementsDialogContent = props => {
  const { selectedItem, setSelectedItem, supplement, currentProduct } = props;
  const theme = useTheme();

  const onSupplementsUpdate = (currentProduct, supplement, isCreation) => {
    if (!isCreation) {
      setSelectedItem({
        ...selectedItem,
        products: [
          // prevent duplicate item
          ...selectedItem.products.filter(p => p.id !== currentProduct.id),
          {
            ...currentProduct,
            supplements: currentProduct.supplements.filter(
              s => s.id !== supplement.id
            ),
            total: parseNumber(
              subtract(currentProduct.total, parseNumber(supplement.price))
            ),
          },
        ],
        total: parseNumber(
          subtract(selectedItem.total, parseNumber(supplement.price))
        ),
      });
    } else {
      const itemTotal = parseNumber(
        add(currentProduct.total, parseNumber(supplement.price))
      );
      setSelectedItem({
        ...selectedItem,
        products: [
          // prevent duplicate item
          ...selectedItem.products.filter(p => p.id !== currentProduct.id),
          {
            ...currentProduct,
            supplements: [...currentProduct.supplements, supplement],
            total: itemTotal,
          },
        ],
        total: parseNumber(
          add(subtract(selectedItem.total - currentProduct.total), itemTotal)
        ),
      });
    }
  };

  return (
    <ShopProfilDialogSupplementLine key={supplement.id}>
      <CheckBox
        checked={currentProduct.supplements.includes(supplement)}
        onChange={isChecked =>
          onSupplementsUpdate(currentProduct, supplement, isChecked)
        }
        name={supplement.name}
        wrapperProps={{
          justify: 'flex-start',
          align: 'center',
          padding: `${SPACING[100]} ${SPACING[100]} 0 ${SPACING[100]}`,
        }}
        disabled={!supplement.avaibility}
        label={
          <Text
            size={400}
            color={
              !supplement.avaibility ? theme.lightGray : theme.headingColor
            }
          >
            {supplement.name} {!supplement.avaibility && ' (indisponible)'}
          </Text>
        }
      />

      <Chip
        size="small"
        label={`${parseNumber(supplement.price).toFixed(2)}€`}
      />
    </ShopProfilDialogSupplementLine>
  );
};

export default SupplementsDialogContent;

import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Heading, Button, Text } from '../../styleguide';
import {
  ContentWrapper,
  EmptyPlaceholder,
  EmptyPicture,
  ButtonsWrapper,
  EmptyPlaceholderContent,
  EmptyPlaceholderDescription,
  BasicColumnBlock,
} from '../../styles/common';
import EmptyBasket from '../../assets/images/empty.svg';

const EmptyPage = props => {
  const {
    illustration,
    title,
    description,
    buttonContent,
    action,
    withoutButton,
    titleSize,
    containerProps,
    buttonProps,
    details,
    illusWidth,
    illusHeight,
  } = props;
  const history = useHistory();
  const { t } = useTranslation(['common']);

  const pictureSrc = illustration || EmptyBasket;
  const buttonChild = buttonContent || t('see_offers');

  const onClick = () => {
    if (!action) {
      history.push('/');
      return;
    }

    action();
  };

  return (
    <BasicColumnBlock {...containerProps}>
      <ContentWrapper>
        <EmptyPlaceholder>
          <EmptyPicture
            src={pictureSrc}
            width={illusWidth}
            height={illusHeight}
          />
          <EmptyPlaceholderContent>
            <Heading size={titleSize || 900} centered>
              {title}
            </Heading>
            <EmptyPlaceholderDescription>
              <Text muted>{description}</Text>
            </EmptyPlaceholderDescription>
            {!!details && (
              <EmptyPlaceholderDescription>
                {details}
              </EmptyPlaceholderDescription>
            )}
          </EmptyPlaceholderContent>
        </EmptyPlaceholder>
      </ContentWrapper>
      {!withoutButton ? (
        <ButtonsWrapper>
          <Button onClick={onClick} {...buttonProps}>
            {buttonChild}
          </Button>
        </ButtonsWrapper>
      ) : null}
    </BasicColumnBlock>
  );
};

export default EmptyPage;

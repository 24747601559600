import React from 'react';
import { add } from 'lodash';
import { useTranslation } from 'react-i18next';

import { Heading, Text } from '../../styleguide';
import { TotalsWrapper, TitleColumn, PricesColumm } from '../../styles/basket';
import { useTheme } from '../../hooks';
import { parseNumber } from '../../common';

const Totals = props => {
  const { total, deliveryFees } = props;
  const theme = useTheme();
  const computedTotal = parseNumber(
    add(parseNumber(total), parseNumber(deliveryFees))
  ).toFixed(2);
  const { t } = useTranslation(['common']);

  return (
    <TotalsWrapper theme={theme}>
      <TitleColumn>
        <Text muted styles={{ padding: '8px 0' }}>
          {t('common:subtotal')} :{' '}
        </Text>
        <Text muted styles={{ padding: '8px 0' }}>
          {t('common:delivery_fee')} :{' '}
        </Text>
        <Heading
          style={{ padding: '8px 0', color: theme.headingColor }}
          size={600}
        >
          {t('common:total')} :{' '}
        </Heading>
      </TitleColumn>
      <PricesColumm>
        <Text muted styles={{ padding: '8px 0' }}>
          {parseNumber(total).toFixed(2)}€
        </Text>
        <Text muted styles={{ padding: '8px 0' }}>
          <span style={{ fontSize: '12px' }}>{t('common:started_from')}</span>{' '}
          {deliveryFees}€
        </Text>
        <Heading
          style={{ color: theme.primaryColor, padding: '8px 0' }}
          size={600}
        >
          {computedTotal}€
        </Heading>
      </PricesColumm>
    </TotalsWrapper>
  );
};

export default Totals;

import React from 'react';
import { useLocation, useHistory, Redirect } from 'react-router-dom';
import { useUnstated } from '@gitbook/unstated';
import { useStripe } from '@stripe/react-stripe-js';
import { Alert, AlertTitle } from '@material-ui/lab';
import { add } from 'lodash';
import { Chip, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { parseNumber } from '../../common';
import onCreateOrder from '../../actions/orders/onCreateOrder';
import {
  computeDeliveryFees,
  computeTotalFees,
} from '../../actions/orders/helpers/computeWithDiscount';
import { SPACING } from '../../styles/constants';
import { useSession, useTheme } from '../../hooks';
import { BasketContainer } from '../../contexts';
import {
  Heading,
  ConfirmDialog,
  Text,
  Container,
  Button,
  Loader,
} from '../../styleguide';
import { DiscountIcon, CloseIcon } from '../../styleguide/icons';
import useNewOrder from '../../hooks/useNewOrder';
import { Page } from '../common';
import Header from '../basket/Header';
import ProductLine from '../basket/ProductLine';
import MenuLine from '../basket/MenuLine';
import Totals from './Totals';
import { ButtonsWrapper, MainWrapper } from '../../styles/basket';
import {
  AddressesWrapper,
  ItemsWrapper,
  TotalsWrapper,
  NotesWrapper,
  PaymentMethodWrapper,
  DeliveryTimeWrapper,
} from '../../styles/newOrder';
import AddressLine from './AddressLine';
import PaymentMethod from './PaymentMethod';
import CreationLoader from './CreationLoader';
import MessageBlock from './MessageBlock';

const NewOrder = props => {
  const [confirmDialog, setConfirmDialog] = React.useState(false);
  const [isProcessing, setProcessing] = React.useState(false);
  const [error, setError] = React.useState(null);
  const [message, setMessage] = React.useState('');
  const { t } = useTranslation(['common', 'checkout']);

  const stripe = useStripe();
  const theme = useTheme();
  let location = useLocation();
  let history = useHistory();
  const { user } = useSession();
  const basketContainer = useUnstated(BasketContainer);
  const to =
    location.state && location.state.from.pathname !== '/order/new'
      ? location.state.from
      : { pathname: '/' };

  const {
    total,
    itemsList,
    shop,
    selectedAddress,
    selectedPaymentMethod,
    discount,
  } = basketContainer.state;

  const {
    currentShop,
    shopValueLoading,
    shopValueError,
    settings,
    settingsLoading,
    settingsError,
    paymentsMethodsList,
    deliveryData,
    currentShopIsAvailable,
    canOrder,
    estimatedDeliveryTimes,
    appFeesWithDiscount,
    validateDiscount,
  } = useNewOrder({
    user,
  });

  const onConfirmOrder = (deliveryFees, duration, distance) => {
    setProcessing(true);
    setError(null);

    return onCreateOrder({
      selectedAddress,
      selectedPaymentMethod,
      deliveryFees,
      appFees: appFeesWithDiscount,
      duration,
      distance,
      user,
      shop,
      total,
      itemsList,
      message,
      discount,
      stripe,
      onFinishCb: orderID => {
        setProcessing(false);
        basketContainer.resetBasket();
        history.push(`/account/orders/${orderID}`, { from: to });
      },
      onErrorCb: error => {
        if (error.code === 'discount_expired') {
          basketContainer.updateDiscount(null);
        }
        setError(error);
        setProcessing(false);
        setConfirmDialog(false);
      },
    });
  };

  if (basketContainer.isEmpty) {
    return (
      <Redirect
        to={{
          pathname: to.pathname,
          state: { from: to },
        }}
      />
    );
  }

  if (shopValueLoading || settingsLoading) {
    return (
      <Loader color={theme.primaryColor} background={theme.backgroundColor} />
    );
  }

  if (isProcessing) {
    const deliveryFeesWithDiscount = computeDeliveryFees(
      discount,
      deliveryData.value.deliveryFees
    );
    const computedFees = computeTotalFees(
      appFeesWithDiscount,
      deliveryFeesWithDiscount
    );

    return (
      <CreationLoader
        selectedAddress={selectedAddress}
        total={parseNumber(add(parseNumber(total), computedFees))}
        selectedPaymentMethod={selectedPaymentMethod}
        itemsList={itemsList}
        message={message}
      />
    );
  }

  if (settingsError || shopValueError) {
    if (settingsError) {
      throw new Error(settingsError.toString());
    } else if (shopValueError) {
      throw new Error(shopValueError.toString());
    }
  }

  return (
    <Page name="Nouvelle commande" withoutWidget>
      <Container>
        <MainWrapper padding>
          <Header
            itemsCount={basketContainer.itemsCount}
            shop={shop}
            to={to}
            title={t('checkout:title')}
          />

          {!currentShopIsAvailable && (
            <Alert severity="warning" style={{ margin: '8px 0px' }}>
              <AlertTitle>
                {t('common:shop_closed:title')}{' '}
                <span role="img" aria-label="smiley-cry">
                  😢
                </span>
              </AlertTitle>
              {t('common:shop_closed:message')}
            </Alert>
          )}

          {!!currentShop && currentShop.strongPresence && canOrder ? (
            <Alert severity="warning" style={{ margin: '8px 0px' }}>
              <AlertTitle>
                {t('common:shop_strong_presence_alert:title')} {`💥`}
              </AlertTitle>
              {t('common:shop_strong_presence_alert:message')}
            </Alert>
          ) : null}

          {canOrder && settings.strongPresence ? (
            <Alert severity="warning" style={{ margin: '8px 0px' }}>
              <AlertTitle>
                {t('common:strong_presence_alert:title')} {`⏳`}
              </AlertTitle>
              {t('common:strong_presence_alert:message')} {`😔`}
            </Alert>
          ) : null}

          {error && (
            <Alert
              severity="error"
              style={{ margin: '8px 0px', width: '100%' }}
            >
              <AlertTitle>
                {error.code !== 'discount_expired'
                  ? t('checkout:errors:titles:payment')
                  : t('checkout:errors:titles:discount')}{' '}
              </AlertTitle>
              {error.code === 'discount_expired' ? (
                <>
                  <Text>{t('checkout:errors:discount:title')}</Text>
                  <br />
                  <Text>{t('checkout:errors:discount:message')}</Text>
                </>
              ) : error.code === 'card_declined' ? (
                <>
                  <Text>{t('checkout:errors:card:title')}</Text>
                  <br />
                  <Text>{t('checkout:errors:card:message')}</Text>
                </>
              ) : (
                <>
                  <Text>{t('checkout:errors:payment:title')}</Text>
                  <br />
                  <Text>{t('checkout:errors:payment:message')}</Text>
                </>
              )}
            </Alert>
          )}

          {!canOrder && (
            <Alert severity="error" style={{ margin: '8px 0px' }}>
              <AlertTitle>
                {t('common:opening_alert:title')}{' '}
                <span role="img" aria-label="smiley-closed">
                  ❌
                </span>
              </AlertTitle>
              {t('common:opening_alert:message')}
            </Alert>
          )}

          <DeliveryTimeWrapper>
            <Heading styles={{ paddingBottom: SPACING[200] }} size={700}>
              {t('checkout:estimated_time_title')}
            </Heading>
            {deliveryData.loading || !estimatedDeliveryTimes ? (
              <Text muted className="details">
                {t('checkout:loading')}
              </Text>
            ) : (
              <div className="details">
                <Text size={600} styles={{ margin: `${SPACING[100]} 0` }} muted>
                  {t('checkout:between')}{' '}
                  <span
                    style={{
                      margin: `0 ${SPACING[100]}`,
                      color: theme.primaryColor,
                      fontSize: '2rem',
                    }}
                  >{` ${estimatedDeliveryTimes.from} `}</span>{' '}
                  {t('checkout:and')}{' '}
                  <span
                    style={{
                      margin: `0 ${SPACING[100]}`,
                      fontSize: '2rem',
                      color: theme.primaryColor,
                    }}
                  >{` ${estimatedDeliveryTimes.to}*`}</span>
                </Text>
                <Text
                  muted
                  size={300}
                  styles={{ textAlign: 'center', maxWidth: '300px' }}
                >
                  {t('checkout:estimated_time_helper')}
                </Text>
              </div>
            )}
          </DeliveryTimeWrapper>

          <AddressesWrapper>
            <Heading styles={{ paddingBottom: SPACING[200] }} size={700}>
              {t('checkout:address_title')}
            </Heading>
            <AddressLine
              selectedAddress={selectedAddress}
              addresses={user.addresses}
              setSelectedAddress={address =>
                basketContainer.updateSelectedAddress(address)
              }
            />
          </AddressesWrapper>

          <NotesWrapper>
            <Heading styles={{ paddingBottom: SPACING[200] }} size={700}>
              {t('checkout:message_title')}
            </Heading>
            <MessageBlock
              onMessageUpdate={setMessage}
              placeholder={t('checkout:message_placeholder')}
            />
          </NotesWrapper>

          <PaymentMethodWrapper>
            <Heading styles={{ paddingBottom: SPACING[100] }} size={700}>
              {t('checkout:payment_title')}
            </Heading>
            {discount && (
              <div
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'flex-start',
                  alignItems: 'center',
                  paddingBottom: SPACING[100],
                  paddingLeft: SPACING[100],
                  gap: SPACING[100],
                }}
              >
                <Text muted size={400}>
                  {t('checkout:discount_confirm')}{' '}
                </Text>
                <Chip
                  label={`${discount.code}`}
                  icon={
                    <DiscountIcon
                      padding="0 0 0 4px"
                      style={{
                        transformx: 'scale(1.3)',
                      }}
                    />
                  }
                />

                <IconButton
                  onClick={() => basketContainer.updateDiscount(null)}
                >
                  <CloseIcon />
                </IconButton>
              </div>
            )}
            <PaymentMethod
              paymentsMethodsList={paymentsMethodsList.value}
              setSelectedPaymentMethod={pm => {
                basketContainer.updateSelectedPaymentMethod(pm);
                if (error) setError(null);
              }}
              selectedPaymentMethod={selectedPaymentMethod}
              isLoading={paymentsMethodsList.loading}
              discount={discount}
              shop={shop}
            />
          </PaymentMethodWrapper>

          <ItemsWrapper>
            <Heading styles={{ paddingBottom: SPACING[200] }} size={700}>
              {t('checkout:products_title')}
            </Heading>

            {itemsList.map((shopItem, index) =>
              shopItem.item.isMenu ? (
                <MenuLine key={index} shopItem={shopItem} />
              ) : (
                <ProductLine key={index} shopItem={shopItem} />
              )
            )}
          </ItemsWrapper>

          <TotalsWrapper theme={theme}>
            <Totals
              total={total}
              deliveryData={deliveryData}
              isLoading={deliveryData.loading}
              appFees={appFeesWithDiscount}
              discount={discount}
            />
          </TotalsWrapper>

          <ButtonsWrapper
            style={{
              position: 'sticky',
              zIndex: 10,
              bottom: 0,
              background: theme.backgroundColor,
              padding: `${SPACING[200]} ${SPACING[100]}`,
              boxShadow: 'rgb(0 0 0 / 12%) 1px -10px 11px -12px',
              borderTopLeftRadius: 16,
              borderTopRightRadius: 16,
            }}
            direction="row"
          >
            <Button
              onClick={() => history.push(`/shop/${shop.id}`)}
              minimal
              variant="outlined"
              styles={{
                marginRight: SPACING[100],
              }}
            >
              {t('checkout:continue_action')}
            </Button>
            <Button
              disabled={
                !selectedPaymentMethod ||
                deliveryData.loading ||
                shopValueLoading ||
                settingsLoading ||
                !canOrder ||
                !currentShopIsAvailable
              }
              isLoading={
                settingsLoading || shopValueLoading || deliveryData.loading
              }
              onClick={async () => {
                if (!!discount) {
                  const isValid = await validateDiscount();
                  if (!isValid) {
                    return;
                  }
                }
                setConfirmDialog(true);
              }}
            >
              {t('checkout:order_action')}
            </Button>
          </ButtonsWrapper>
        </MainWrapper>

        <ConfirmDialog
          isOpen={!!confirmDialog}
          onClose={() => setConfirmDialog(false)}
          onConfirm={() => {
            if (!!deliveryData?.value && !deliveryData.loading) {
              onConfirmOrder(
                deliveryData.value.deliveryFees,
                deliveryData.value.duration,
                deliveryData.value.distance
              );
            }
          }}
          title={t('checkout:confirm:title')}
          info={
            !!selectedAddress && !!selectedPaymentMethod ? (
              <>
                <Text size={400}>{t('checkout:confirm:first')}</Text>
                <br />
                <hr />
                <Text size={400}>
                  <b>{t('checkout:confirm:second')}</b> {selectedAddress.street}
                  , {selectedAddress.postalCode} {selectedAddress.city}
                </Text>
                <br />
                <hr />
                <Text size={400} styles={{ display: 'inline-flex' }}>
                  <b>{t('checkout:confirm:third')}</b>{' '}
                </Text>
                <Text
                  size={400}
                  style={{ color: theme.headingColor, marginTop: SPACING[100] }}
                >
                  {`  ${selectedPaymentMethod.billing_details.name} - ${selectedPaymentMethod.card.last4} - ${selectedPaymentMethod.card.exp_month}/${selectedPaymentMethod.card.exp_year}`}
                </Text>
                <br />
                <hr />
                <Text size={300} muted>
                  {t('checkout:confirm:fourth')}{' '}
                </Text>
              </>
            ) : null
          }
        />
      </Container>
    </Page>
  );
};

export default NewOrder;

import React from 'react';
import { Divider, Chip } from '@material-ui/core';

import {
  FlexFieldsWrapper,
  OptionsItemsListItem,
  OptionsFieldsWrapper,
} from '../../../../../styles/shop';
import { Text, Button, Heading } from '../../../../../styleguide';
import { useTheme } from '../../../../../hooks';
import { parseNumber } from '../../../../../common';
import { SPACING } from '../../../../../styles/constants';
import {
  AvailabilityIcon,
  EditIcon,
  DeleteIcon,
} from '../../../../../styleguide/icons';

const OptionCard = props => {
  const { option, onEditOption, onDelete } = props;
  const theme = useTheme();

  return (
    <OptionsFieldsWrapper theme={theme} key={option.id}>
      <FlexFieldsWrapper align="center" justify="space-between" wrap="nowrap">
        <Heading size={500}>{option.name}</Heading>
        <FlexFieldsWrapper padding="0px" width="fit-content" justify="flex-end">
          <Chip
            size="small"
            label={option?.order ? `n°${option.order}` : 'Non défini'}
          />
          <Chip
            variant="outlined"
            label={!!option.required ? 'Requis' : 'Optionnel'}
            size="small"
          />
          <Chip label={!!option.paying ? 'Payant €' : 'Gratuit'} size="small" />
        </FlexFieldsWrapper>
      </FlexFieldsWrapper>
      <Divider
        style={{
          width: '100%',
          margin: `${SPACING[100]} 0`,
          marginBottom: SPACING[200],
        }}
      />
      {option.items.map(item => (
        <OptionsItemsListItem key={item.id}>
          <FlexFieldsWrapper padding={'4px'} wrap="nowrap">
            <Text size={500}>{`- ${item.name}`}</Text>
            <AvailabilityIcon
              size="20px"
              margin={`0 ${SPACING[200]}`}
              color={!!item.avaibility ? theme.primaryColor : theme.red}
            />
            <Chip
              size="small"
              label={`${parseNumber(item.price).toFixed(2)}€`}
            />
          </FlexFieldsWrapper>
        </OptionsItemsListItem>
      ))}
      <Divider
        style={{
          width: '100%',
          margin: `${SPACING[100]} 0`,
          marginBottom: SPACING[200],
          marginTop: 'auto',
        }}
      />
      <FlexFieldsWrapper>
        <Button
          small
          primary
          onClick={() => onEditOption(option)}
          startIcon={<EditIcon color={theme.textColor} size="20px" />}
          styles={{
            marginRight: SPACING[100],
          }}
        >
          Modifier
        </Button>
        <Button
          minimal
          small
          startIcon={<DeleteIcon color={theme.textColor} size="20px" />}
          onClick={() => onDelete(option.id)}
        >
          Supprimer
        </Button>
      </FlexFieldsWrapper>
    </OptionsFieldsWrapper>
  );
};

export default OptionCard;

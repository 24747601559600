import React from 'react';
import { Provider } from '@gitbook/unstated';
import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';
import { BrowserRouter, Route } from 'react-router-dom';
import { useAuthState } from 'react-firebase-hooks/auth';
import { Settings } from 'luxon';
import { toaster } from 'evergreen-ui';
import { useTranslation } from 'react-i18next';

import { colors } from '../styles/constants';
import { Loader } from '../styleguide';
import { AuthUserContext } from '../contexts';
import { Routes } from '../routing';
import ErrorBoundary from './misc/ErrorBoundary';
import Layout from './layout/Layout';
import '../styles/global';

const AppComponent = () => {
  const [user, loading, error] = useAuthState(Firebase.auth());
  const { i18n } = useTranslation();

  Settings.defaultZoneName = 'Europe/Paris';
  Settings.defaultLocale = i18n.language || 'fr';

  if (loading) {
    // console.log('render Loader App');
    return <Loader />;
  }

  if (error) {
    console.error({ error });
    toaster.danger('Erreur de connexion', {
      description: error.message,
    });
  }

  return (
    <BrowserRouter>
      <ErrorBoundary>
        <AuthUserContext.Provider
          value={{
            authUser: user,
          }}
        >
          <Provider>
            <Layout>
              <Route path="/" component={ScrollToTop} />
              <Route
                path="/"
                name="Analytics"
                component={props => <Analytics userID={user?.uid} {...props} />}
              />
              <Routes />
            </Layout>
          </Provider>
        </AuthUserContext.Provider>
      </ErrorBoundary>
    </BrowserRouter>
  );
};

// scroll to top on route change
// https://github.com/ReactTraining/react-router/blob/master/packages/react-router-dom/docs/guides/scroll-restoration.md#scroll-to-top
class ScrollToTop extends React.Component {
  componentDidUpdate(prevProps) {
    if (this.props.location !== prevProps.location) {
      window.scrollTo(0, 0);
    }
  }
  render() {
    return null;
  }
}

// Track Google Analytics page view for every route
// https://github.com/react-ga4/react-ga4/issues/122#issuecomment-319546248
const Analytics = ({ userID, location }) => {
  const page = location.pathname + location.search;
  ReactGA.set({ page, userID });
  return null;
};

const App = () => {
  return (
    <React.Suspense fallback={<Loader />}>
      <AppComponent />
    </React.Suspense>
  );
};

export default App;

import React from 'react';
import { FormControlLabel, Checkbox } from '@material-ui/core';

import { FieldWrapper } from '../../styles/form';

const CheckBox = props => {
  const { checked, onChange, label, wrapperProps, disabled, ...rest } = props;

  return (
    <FieldWrapper {...wrapperProps}>
      <FormControlLabel
        control={
          <Checkbox
            checked={checked}
            onChange={event => onChange(event.target.checked)}
            color="primary"
            disabled={disabled}
            {...rest}
          />
        }
        label={label}
      />
    </FieldWrapper>
  );
};

export default CheckBox;

import React from 'react';
import Firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { Loader, Heading } from '../../../../styleguide';
import { TabContentWrapper, ItemButtonsWrapper } from '../../../../styles/shop';
import { useTheme } from '../../../../hooks';
import TabPanel from '../TabPanel';
import NewItemButton from './NewItemButton';
import ReorderButton from './ReorderButton';
import ItemModal from './ItemModal';
import CategoriesModal from './CategoriesModal';
import ItemsListing from './ItemsListing';

const MenusTab = props => {
  const { value, shop, setConfirmOpen } = props;
  const [modal, toggleModalOpening] = React.useState(null);
  const [reorderModal, toggleReorderModalOpening] = React.useState(null);
  const [editedItem, setEditedItem] = React.useState(null);

  const theme = useTheme();

  React.useEffect(() => {
    if (editedItem) {
      if (editedItem.isMenu) {
        toggleModalOpening('menu');
      } else {
        toggleModalOpening('product');
      }
    }
  }, [editedItem]);

  const [data, loading, error] = useCollectionData(
    Firebase.firestore()
      .collection('shopItems')
      .where(`shopID`, '==', shop.id),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (loading) {
    return (
      <Loader color={theme.primaryColor} background={theme.backgroundColor} />
    );
  }

  if (error) {
    throw new Error(error.toString());
  }

  const onClose = () => {
    toggleModalOpening(false);
    setEditedItem(null);
  };

  const hasExistingMenu = !!data.length;
  return (
    <TabPanel value={value} index={0}>
      <TabContentWrapper>
        <Heading size={800}>Vos menus et produits</Heading>
        <ItemButtonsWrapper theme={theme}>
          <NewItemButton
            label="Produit"
            onClick={() => toggleModalOpening('product')}
          />

          <NewItemButton
            disabled={!hasExistingMenu}
            label="Menu"
            onClick={() => toggleModalOpening('menu')}
          />

          <ReorderButton
            disabled={!hasExistingMenu}
            label="Réordonner"
            onClick={() => toggleReorderModalOpening(true)}
          />
        </ItemButtonsWrapper>
        {hasExistingMenu && (
          <ItemsListing
            shop={shop}
            items={data}
            onItemEdit={setEditedItem}
            setConfirm={setConfirmOpen}
          />
        )}
      </TabContentWrapper>

      {!!modal && (
        <ItemModal
          initialValue={editedItem}
          shop={shop}
          isOpen={!!modal}
          kind={modal}
          onClose={onClose}
          hasExistingMenu={hasExistingMenu}
          categoriesData={data}
        />
      )}

      {!!reorderModal && (
        <CategoriesModal
          shop={shop}
          isOpen={reorderModal}
          onClose={() => toggleReorderModalOpening(false)}
          items={data}
        />
      )}
    </TabPanel>
  );
};

export default MenusTab;

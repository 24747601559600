import styled from 'styled-components';
import { SPACING } from './constants';

const ContentWrapper = styled.div``;

const ProvidersButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const DescriptionWrapper = styled.div`
  max-width: 370px;
  text-align: center;
  padding: ${SPACING[500]} ${SPACING[100]};
  margin: 0 auto;
`;

const ImageStyled = styled.img`
  width: ${props => props.width || '281px'};
  height: ${props => props.height || '234px'};
  margin: 0 auto;
  margin-bottom: ${SPACING[500]};
`;

const SignupFormPolicyText = styled.div`
  padding: ${SPACING[200]};
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  text-align: left;

  & .PrivateSwitchBase-input-8 {
    padding: 0;
  }
`;

export {
  ContentWrapper,
  ProvidersButtonsWrapper,
  DescriptionWrapper,
  ImageStyled,
  SignupFormPolicyText,
};

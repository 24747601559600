import React from 'react';
import Firebase from 'firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';
import { Redirect, useLocation } from 'react-router-dom';

import { Loader } from '../../styleguide';
import { useSession, useTheme } from '../../hooks';
import ShopDashboard from './ShopDashboard';

const Shop = props => {
  const { match } = props;
  const theme = useTheme();
  const { user } = useSession();
  let location = useLocation();

  const shopID =
    user.role === 'admin' && match.params.id ? match.params.id : user.id;
  const query =
    user.role === 'admin' && match.params.id
      ? ['id', shopID]
      : ['ownerID', user.id];

  const [value, loading, error] = useCollection(
    Firebase.firestore()
      .collection('shops')
      .where(query[0], '==', query[1]),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (loading) {
    return (
      <Loader color={theme.primaryColor} background={theme.backgroundColor} />
    );
  }

  if (error) {
    throw new Error(error.toString());
  }

  if (!value || !value.docs || !value.docs.length) {
    return (
      <Redirect
        to={{
          pathname: '/shop/new',
          state: { from: location },
        }}
      />
    );
  }

  const shop = { ...value.docs[0].data(), id: value.docs[0].id };

  if (!shop.stripeCustomerId && user.role !== 'admin') {
    return (
      <Redirect
        to={{
          pathname: '/shop/stripe/accountCreation',
          state: { from: location },
        }}
      />
    );
  }

  return <ShopDashboard shop={shop} />;
};

export default Shop;

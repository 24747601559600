import Welcome from './Welcome';
import NameForm from './NameForm';
import LogoForm from './LogoForm';
import CategorySelect from './CategorySelect';
import Address from './Address';
import LegalForm from './LegalForm';

const STEPS = [
  {
    index: 1,
    name: 'welcome',
    Step: Welcome,
  },
  {
    index: 2,
    name: 'name',
    Step: NameForm,
  },
  {
    index: 3,
    name: 'logo',
    Step: LogoForm,
  },
  {
    index: 4,
    name: 'category',
    Step: CategorySelect,
  },
  {
    index: 5,
    name: 'address',
    Step: Address,
  },
  {
    index: 6,
    name: 'legal',
    Step: LegalForm,
  },
];

export default STEPS;

import React from 'react';
import { Alert, AlertTitle } from '@material-ui/lab';
import { Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { computeEstimatedTime } from '../../../../../actions/orders/helpers/computeTimesHelpers';
import computePreparationTime from '../../../../../actions/orders/helpers/computePreparationTime';
import { Heading, Text } from '../../../../../styleguide';
import { useTheme } from '../../../../../hooks';
import { SPACING } from '../../../../../styles/constants';
import {
  StepContentWrapper,
  ItemsWrapper,
  TimelineRow,
} from '../../../../../styles/order-tracking';
import InProgressIllustration from '../../../../../assets/images/orders/InProgress.svg';
import ProductLine from '../../../../basket/ProductLine';
import MenuLine from '../../../../basket/MenuLine';
import {
  Totals,
  Details,
  IllustrationHeader,
  Header,
  MessageBlock,
} from './Commons';

const InProgressStep = props => {
  const { order, delayedDelivery } = props;
  const theme = useTheme();
  const { t } = useTranslation(['common', 'orders']);

  // TODO: When all orders have been updated, use order.estimatedTimeToDeliver instead
  const preparationTime =
    order.estimatedTimeToDeliver || computePreparationTime(order.itemsList);

  const estimatedDeliveryTime = computeEstimatedTime({
    ts: order.acceptedAt,
    withDelay: false,
    minutes: preparationTime,
    seconds: order.duration,
  });

  return (
    <StepContentWrapper>
      <IllustrationHeader imgSrc={InProgressIllustration} />

      <Header
        order={order}
        title={t('orders:tracking:inprogress_title')}
        emoji="👨‍🍳"
      />
      <Divider style={{ width: '100%', marginBottom: `${SPACING[300]}` }} />

      {delayedDelivery ? (
        <Alert severity="warning" style={{ margin: '8px 0px' }}>
          <AlertTitle>
            {t('common:strong_presence_alert:title')} {`⏱`}
          </AlertTitle>
          {t('common:strong_presence_alert:message')}
        </Alert>
      ) : null}

      {preparationTime >= 120 ? (
        <TimelineRow>
          <Heading size={500}>
            {t('orders:tracking:max_delivery_time')}{' '}
          </Heading>
          <Heading
            size={900}
            color={theme.primaryColor}
            styles={{ marginTop: SPACING[100] }}
          >
            ~1h30
          </Heading>
        </TimelineRow>
      ) : preparationTime >= 60 ? (
        <TimelineRow>
          <Heading size={500}>
            {t('orders:tracking:max_delivery_time')}{' '}
          </Heading>
          <Heading
            size={900}
            color={theme.primaryColor}
            styles={{ marginTop: SPACING[100] }}
          >
            ~1h
          </Heading>
        </TimelineRow>
      ) : (
        <TimelineRow>
          <Heading size={500}>
            {t('orders:tracking:estimated_delivery_hours')}{' '}
          </Heading>
          <Heading
            size={900}
            color={theme.primaryColor}
            styles={{ marginTop: SPACING[100] }}
          >
            {estimatedDeliveryTime}
          </Heading>
          <Text muted styles={{ marginTop: SPACING[200] }} size={400}>
            {t('orders:tracking:estimated_preparation_time')}{' '}
          </Text>
          <Text muted>
            <b>{preparationTime}mn</b>
          </Text>
        </TimelineRow>
      )}
      <Divider style={{ width: '100%', margin: `${SPACING[300]}` }} />

      <Details order={order} />

      <Divider style={{ width: '100%', marginTop: `${SPACING[300]}` }} />

      <Totals order={order} />

      <Divider style={{ width: '100%', margin: `${SPACING[100]}` }} />

      <ItemsWrapper>
        {order.itemsList.map((shopItem, index) =>
          shopItem.item.isMenu ? (
            <>
              <MenuLine key={index} shopItem={shopItem} small />
              {order.itemsList.length - 1 !== index && (
                <Divider style={{ width: '100%', margin: `${SPACING[100]}` }} />
              )}
            </>
          ) : (
            <>
              <ProductLine key={index} shopItem={shopItem} small />
              {order.itemsList.length - 1 !== index && (
                <Divider style={{ width: '100%', margin: `${SPACING[100]}` }} />
              )}
            </>
          )
        )}
      </ItemsWrapper>
      <Divider style={{ width: '100%', margin: `${SPACING[100]}` }} />

      <MessageBlock order={order} />
    </StepContentWrapper>
  );
};

export default InProgressStep;

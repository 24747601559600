import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

import createUser from '../users/createUser';

const signUpWithCredentials = async (userData, lng) => {
  ReactGA.event({
    category: 'New user',
    action: 'New signup email / password',
  });

  try {
    const credentials = await Firebase.auth().createUserWithEmailAndPassword(
      userData.email,
      userData.pwd
    );

    if (credentials && credentials.user) {
      delete userData.pwd;
      await createUser(userData, credentials.user.uid, lng);
    }
  } catch (error) {
    console.error('could not sign up with those credentials', error);
    return error;
  }
};

export { signUpWithCredentials };

import React from 'react';
import { DataGrid } from '@material-ui/data-grid';
import { DateTime } from 'luxon';

import { BlockDataGridWrapper } from '../../../styles/common';
import { parseNumber } from '../../../common';

const columns = [
  {
    field: 'orderAt',
    headerName: 'Commandé à',
    width: 200,
  },
  {
    field: 'deliverAt',
    headerName: 'Livré à',
    width: 200,
  },
  {
    field: 'amount',
    headerName: 'Montant HT',
    valueFormatter: ({ value }) => `${parseNumber(value).toFixed(2)}€`,
    width: 150,
  },
  {
    field: 'isPaid',
    headerName: 'Payé',
    width: 100,
  },
  {
    field: 'isBilled',
    headerName: 'Facturé',
    width: 100,
  },
  {
    field: 'invoiceId',
    headerName: 'ID facture',
    width: 250,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 250,
  },
];

const OrdersListTable = props => {
  const { orders } = props;

  const rows = orders.map(order => ({
    id: order.id,
    orderAt: DateTime.fromMillis(order.orderAt).toLocaleString(
      DateTime.DATETIME_MED
    ),
    amount: order.deliveryFees,
    invoiceId: !!order.metadata.isBilled
      ? order.metadata.invoiceId
      : 'Pas encore facturé',
    isPaid: order.metadata.rusherIsPaid ? '✅' : '❌',
    isBilled: order.metadata.isBilled ? '✅' : '❌',
    deliverAt: !!order.deliverAt
      ? DateTime.fromMillis(order.deliverAt).toLocaleString(
          DateTime.DATETIME_MED
        )
      : '-',
  }));

  return (
    <BlockDataGridWrapper>
      <DataGrid rows={rows} columns={columns} pageSize={15} stickyHeader />
    </BlockDataGridWrapper>
  );
};

export default OrdersListTable;

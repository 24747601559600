import React from 'react';
import Firebase from 'firebase/app';
import { toaster } from 'evergreen-ui';
import { useCollectionData } from 'react-firebase-hooks/firestore';

import { parseNumber } from '../../../common';
import { EmptyPage } from '../../common';
import { createInvoice } from '../../../actions/rusher';
import {
  TabContentWrapper,
  EmptyTabWrapper,
  ItemButtonsWrapper,
} from '../../../styles/shop';
import { SPACING } from '../../../styles/constants';
import { Heading, ContentLoader } from '../../../styleguide';
import { useTheme } from '../../../hooks';
import TabPanel from '../../shop/tabs/TabPanel';
import NewItemButton from '../../shop/tabs/menus/NewItemButton';
import InvoicesListTable from './InvoicesListTable';
import NewInvoiceDialog from './NewInvoiceDialog';

const InvoicesTab = props => {
  const { value, rusher, ordersLoading, orders } = props;
  const [invoiceCreation, setInvoiceCreation] = React.useState(null);
  const [creationLoading, setCreationLoading] = React.useState(false);
  const theme = useTheme();

  const [invoices, invoicesLoading, invoicesError] = useCollectionData(
    Firebase.firestore()
      .collection('rushersInvoices')
      .where(`rusherID`, '==', rusher.id)
      .orderBy('generatedAt', 'desc'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const onClickNewInvoice = () => {
    const invoiceNumber =
      !!invoices && !!invoices.length ? invoices.length + 1 : 0;
    setInvoiceCreation({
      data: {
        rusherID: rusher.id,
        selectedOrders: [],
        total: 0,
        commission: parseNumber(rusher.percentage),
        number: invoiceNumber,
        period: {
          from: null,
          to: null,
        },
      },
    });
  };

  const onSubmitNewInvoice = async () => {
    setCreationLoading(true);
    console.info(
      `Invoice number ${invoiceCreation.data.number} creation's is confirmed`,
      { invoiceCreation }
    );
    await createInvoice(invoiceCreation.data);
    setInvoiceCreation(null);
    setCreationLoading(false);

    toaster.success(`Facture crée 🧾`, {
      description: `La facture numéro ${invoiceCreation.data.number} a bien était crée 💸`,
    });
  };

  if (invoicesError) {
    throw new Error(invoicesError.toString());
  }

  const isLoading = ordersLoading || invoicesLoading;

  const canConfirm =
    !!invoiceCreation &&
    !!invoiceCreation.data &&
    !!invoiceCreation.data.selectedOrders &&
    !!invoiceCreation.data.selectedOrders.length &&
    invoiceCreation.data.total > 0 &&
    !!invoiceCreation.data.period.from &&
    !!invoiceCreation.data.period.to;

  return (
    <TabPanel value={value} index={0}>
      <TabContentWrapper>
        <Heading size={800}>Vos Factures</Heading>

        <ItemButtonsWrapper theme={theme}>
          <NewItemButton
            label="Créer une facture"
            onClick={onClickNewInvoice}
            isLoading={isLoading}
          />
        </ItemButtonsWrapper>

        {isLoading ? (
          <ContentLoader text="Vos factures sont en cours de chargements." />
        ) : !invoices && !invoices.length ? (
          <EmptyTabWrapper>
            <EmptyPage
              titleSize={700}
              withoutButton
              title="Vous n'avez pas encore crée de factures"
              description={`
                  Vous n'avez créer aucune facture pour le moment, cela ne va pas tarder 💸
                  `}
              containerProps={{
                padding: `${SPACING[300]} ${SPACING[100]}`,
              }}
            />
          </EmptyTabWrapper>
        ) : (
          <InvoicesListTable
            invoices={invoices}
            rusher={rusher}
            orders={orders}
          />
        )}
      </TabContentWrapper>

      {invoiceCreation ? (
        <NewInvoiceDialog
          rusher={rusher}
          invoices={invoices}
          invoice={invoiceCreation}
          orders={orders}
          isOpen={!!invoiceCreation}
          cantConfirm={!canConfirm}
          setInvoiceCreation={setInvoiceCreation}
          onClose={() => setInvoiceCreation(null)}
          onSubmitNewInvoice={onSubmitNewInvoice}
          creationLoading={creationLoading}
        />
      ) : null}
    </TabPanel>
  );
};

export default InvoicesTab;

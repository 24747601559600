import authenticatedRequest from '../helpers/authenticatedRequest';
import validateOrderDiscount from '../discounts/validateOrderDiscount';
import percentage from './helpers/percentage';
import { getPercentageWithVAT } from './helpers/commissionHelpers';
import { createPaymentIntent } from '../stripe';
import { computeTotalFees } from './helpers/computeWithDiscount';
import { parseNumber } from '../../common';
import { API } from '../../common';

const confirmDiscount = async ({ code, shopID, userID }) => {
  try {
    const { data } = await validateOrderDiscount({
      code,
      shopID,
      userID,
    });

    if (data.status === 'success') {
      return {
        status: 'success',
      };
    } else {
      return {
        status: 'discount_expired',
      };
    }
  } catch (error) {
    return {
      status: 'discount_expired',
    };
  }
};

const handlePayment = async ({
  orderID,
  paymentMethodID,
  stripeCustomerID,
  shop,
  total,
  appFees,
  deliveryFeesWithDiscount,
  stripe,
}) => {
  const computedFees = computeTotalFees(appFees, deliveryFeesWithDiscount);
  // We need to add the VAT here, all our commission are without VAT
  const netPercentage = parseNumber(
    percentage(getPercentageWithVAT(shop.percentage), total)
  );

  const client_secret = await createPaymentIntent({
    orderID,
    paymentMethodID,
    stripeCustomerID,
    shopID: shop.id,
    total,
    fees: computedFees,
    percentage: netPercentage,
  });

  const result = await stripe.confirmCardPayment(client_secret);

  return result;
};

const createOrder = async ({
  orderID,
  shop,
  user,
  selectedAddress,
  paymentIntentId,
  duration,
  estimatedTimeToDeliver,
  deliveryFees,
  distance,
  appFees,
  total,
  itemsList,
  paymentMethod,
  orderAt,
  message,
  discount,
}) => {
  try {
    const orderPayload = {
      id: orderID,
      shop,
      user,
      selectedAddress,
      paymentIntentId,
      duration,
      estimatedTimeToDeliver,
      deliveryFees,
      distance,
      appFees,
      total,
      itemsList,
      paymentMethod,
      orderAt,
      message,
      discount,
    };

    const res = await authenticatedRequest(
      'post',
      `${API.backend.createOrder}`,
      orderPayload
    );

    return {
      status: 'success',
      orderID,
    };
  } catch (error) {
    return {
      status: 'errored',
    };
  }
};

export { confirmDiscount, handlePayment, createOrder };

import React from 'react';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';

import { updateUser } from '../actions/users';
import { UserContext, AuthUserContext } from '../contexts';

const handleUserVerification = authUser => {
  return updateUser({ isVerified: true, verifiedBy: 'email' }, authUser.uid);
};

const useSession = () => {
  const { currentUser } = React.useContext(UserContext);
  const { authUser } = React.useContext(AuthUserContext);

  const currentUserExist =
    typeof currentUser === 'object' && !!currentUser
      ? Object.keys(currentUser).length > 1
      : !!currentUser;

  // Prevent error when user isLoggedin for the first time and wasn't created in db
  if (!!currentUserExist && !!authUser) {
    Sentry.setUser({
      id: authUser.uid,
      email: authUser.email,
      username: authUser.displayName,
    });
    ReactGA.set({ userId: authUser.uid });

    if (!!authUser.emailVerified && currentUser?.isVerified === false) {
      handleUserVerification(authUser);
    }
  }

  return {
    authUser: authUser,
    isLoggedIn: !!authUser,
    ...(currentUserExist ? { user: { ...currentUser, id: authUser.uid } } : {}),
  };
};

export default useSession;

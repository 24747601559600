import React from 'react';
import { Chip } from '@material-ui/core';
import { add } from 'lodash';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../../hooks';
import getReadableStatus from '../../../actions/orders/helpers/getReadableStatus';
import { computeFinalPreparationTime } from '../../../actions/orders/helpers/computeFinalPreparationTime';
import { computeTimeFrom } from '../../../actions/orders/helpers/computeTimesHelpers';
import { BlockWrapper } from '../../../styles/common';
import { SPACING } from '../../../styles/constants';
import { Text } from '../../../styleguide';
import {
  ContentWrapper,
  DetailsWrapper,
  OrderCardPicture,
  LineWrapper,
} from '../../../styles/order';
import { parseNumber } from '../../../common';

const OrderCard = props => {
  const { order, onClickOnOrder, maxWidth, rusherCard } = props;
  const theme = useTheme();
  const { t } = useTranslation(['orders', 'common']);

  const orderAt = computeTimeFrom({ ts: order.orderAt });
  const totalFees = parseNumber(
    add(parseNumber(order.deliveryFees), parseNumber(order.appFees))
  );
  const total = parseNumber(add(parseNumber(order.total), totalFees)).toFixed(
    2
  );

  if (!!rusherCard) {
    const { hours, minutes } =
      !!order.pickedAt && !!order.deliverAt
        ? computeFinalPreparationTime(order.pickedAt, order.deliverAt)
        : { hours: '--', minutes: '--' };

    return (
      <BlockWrapper
        margin={`${SPACING[200]} 0`}
        padding={SPACING[100]}
        onClick={onClickOnOrder}
        theme={theme}
        maxWidth={maxWidth || '100%'}
      >
        <ContentWrapper>
          <OrderCardPicture src={order.shop.logoURL} />
          <DetailsWrapper
            overrides={`
              height: 120px;
              justify-content: space-around;
            `}
          >
            <LineWrapper>
              <Text muted size={300} styles={{ display: 'inline-flex' }}>
                {orderAt}
              </Text>
              <Chip
                variant="outlined"
                size="small"
                label={t(`orders:status:${order.status}`)}
              />
            </LineWrapper>
            <LineWrapper>
              <Text
                size={500}
                styles={{
                  color: theme.headingColor,
                  margin: `${SPACING[100]} 0`,
                }}
              >
                {order.shop.name} | {order.name}
              </Text>
            </LineWrapper>
            <LineWrapper wrap="wrap">
              <Text muted size={400} styles={{ marginRight: SPACING[100] }}>
                {parseNumber(order.deliveryFees).toFixed(2)}
                {' € - '}
                {hours > 0 ? `${hours}h${minutes}` : `${minutes}min`}
              </Text>
              <Text
                size={300}
                muted
                styles={{
                  margin: `${SPACING[100]} 0`,
                }}
              >
                ID: {order.id}
              </Text>
            </LineWrapper>
          </DetailsWrapper>
        </ContentWrapper>
      </BlockWrapper>
    );
  }

  return (
    <BlockWrapper
      width={'100%'}
      margin={`${SPACING[100]} 0`}
      padding={SPACING[100]}
      onClick={onClickOnOrder}
      theme={theme}
      maxWidth={maxWidth || '100%'}
    >
      <ContentWrapper>
        <OrderCardPicture src={order.shop.logoURL} />
        <DetailsWrapper
          overrides={`
              height: 120px;
              justify-content: space-around;
            `}
        >
          <LineWrapper>
            <Text muted size={300} styles={{ display: 'inline-flex' }}>
              {orderAt}
            </Text>
            <Chip
              variant="outlined"
              size="small"
              label={t(`orders:status:${order.status}`)}
            />
          </LineWrapper>
          <LineWrapper>
            <Text
              size={500}
              styles={{
                color: theme.headingColor,
                margin: `${SPACING[100]} 0`,
              }}
            >
              {order.shop.name}
            </Text>
          </LineWrapper>
          <LineWrapper wrap="wrap">
            <Text muted size={400} styles={{ display: 'inline-flex' }}>
              {`${order.itemsList.length} ${t('orders:product')}${
                order.itemsList.length > 1 ? 's' : ''
              }`}{' '}
              - {parseNumber(total).toFixed(2)}€
            </Text>
            <Text
              size={300}
              muted
              styles={{
                margin: `${SPACING[100]} 0`,
              }}
            >
              ID: {order.id}
            </Text>
          </LineWrapper>
        </DetailsWrapper>
      </ContentWrapper>
    </BlockWrapper>
  );
};

export default OrderCard;

import ReactGA from 'react-ga4';

import authenticatedRequest from '../helpers/authenticatedRequest';
import { API } from '../../common';

const sendCode = async ({ phone }) => {
  // console.log('sendCode called', {
  //   phone,
  // });
  ReactGA.event({
    category: 'Phone-verify',
    action: 'Create phone verification',
  });

  const cleanedPhone = phone.replace(/\D/g, '');

  try {
    const {
      data: { id, status },
    } = await authenticatedRequest('POST', `${API.backend.sendCode}`, {
      phone: cleanedPhone,
    });

    return {
      id,
      status,
    };
  } catch (error) {
    console.error('Error during sendCode', {
      error: error,
    });
    return {
      error: error,
    };
  }
};

export default sendCode;

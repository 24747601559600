import React from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Container,
  Text,
  Heading,
  Button,
  SimpleHoverableAction,
} from '../../styleguide';
import {
  ContentWrapper,
  EmptyPlaceholder,
  EmptyPicture,
  ButtonsWrapper,
  EmptyPlaceholderContent,
  EmptyPlaceholderDescription,
  BasicColumnBlock,
} from '../../styles/common';
import { Page } from '../common';
import { logOut } from '../../actions/login';
import { useSession } from '../../hooks';
import { LogoutIcon } from '../../styleguide/icons';
import Validation from '../../assets/images/welcome.svg';
import PhonePage from './PhonePage';
import MailPage from './MailPage';

const ActivationPage = props => {
  const history = useHistory();
  const { user, authUser } = useSession();
  const { t } = useTranslation(['auth', 'common']);

  React.useEffect(() => {
    if (!!user.isVerified || !!authUser.emailVerified) {
      history.push('/');
    }
  }, [user, authUser, history]);

  return (
    <Page
      name={t('auth:activate:title')}
      navbarLeftChild={<></>}
      navbarRightChild={
        <SimpleHoverableAction onClick={logOut} size={400}>
          <LogoutIcon style={{ marginRight: '6px' }} />
          {t('logout', { ns: 'common' })}
        </SimpleHoverableAction>
      }
    >
      <Container>
        <BasicColumnBlock>
          <ContentWrapper>
            <EmptyPlaceholder>
              <EmptyPicture src={Validation} width={'auto'} height={'auto'} />
              <EmptyPlaceholderContent>
                <Heading size={900} centered>
                  {`${t('auth:activate:title')} 🔓`}
                </Heading>
                <EmptyPlaceholderDescription>
                  <Text muted>
                    {t('auth:activate:subtitle1')}
                    <br />
                    {t('auth:activate:subtitle2')}
                  </Text>
                </EmptyPlaceholderDescription>
              </EmptyPlaceholderContent>
            </EmptyPlaceholder>
          </ContentWrapper>

          <ButtonsWrapper>
            <Button onClick={() => history.push('/activation/phone')}>
              {t('auth:activate:cta')}
            </Button>
          </ButtonsWrapper>
        </BasicColumnBlock>
      </Container>
    </Page>
  );
};

export default ActivationPage;
export { PhonePage, MailPage };

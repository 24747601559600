import styled from 'styled-components';
import { SPACING, devices } from './constants';
import ShopPattern from '../assets/images/shop-pattern.svg';

const HomePageContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const HomePageCategoryWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${props => props.marginBottom || SPACING[200]};
  border-bottom: ${props =>
    props.withoutBorder ? 'none' : `1px solid ${props.theme.lightGray}`};
  padding: ${SPACING[200]} 0 ${SPACING[100]} 0;

  @media ${devices.tablet} {
    padding: ${SPACING[200]} 0;
  }
`;

const HomePageCategoryHeader = styled.div`
  width: calc(100% - ${SPACING[200]} - ${SPACING[200]});
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${SPACING[200]} 0;
  margin: 0 ${SPACING[200]};
  margin-bottom: ${SPACING[200]};
  border-bottom: 1px solid ${({ theme }) => theme.lightGray};
`;

const HomePageCategoryShopContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding: ${SPACING[200]};
`;

const HeaderTitleWrapper = styled.div`
  width: calc(100% - ${SPACING[400]});
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${SPACING[200]} 0;
  margin: ${SPACING[200]};
  border-bottom: 1px solid ${({ theme }) => theme.lightGray};
  flex-wrap: wrap;

  & > .selected-adress-label {
    text-decoration: none;
  }

  @media ${devices.mobileLG} {
    flex-wrap: nowrap;
  }
`;

const SelectedAdressBlock = styled.div`
  width: fit-content;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: ${SPACING[100]};
  border-radius: 50em;
  margin-left: 0;
  margin-top: ${({ marginTop }) => marginTop || SPACING[200]};
  box-shadow: ${({ theme }) => theme.boxShadow.base};
  background: ${({ theme }) => theme.backgroundColor};
  gap: ${SPACING[100]};

  &:hover {
    cursor: pointer;
    box-shadow: ${({ theme }) => theme.boxShadow.md};
  }

  @media ${devices.mobileLG} {
    margin-left: ${SPACING[200]};
    margin-top: 0;
  }
`;

const SelectedAdressContent = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
`;

const CategoryCard = styled.div`
  width: max-content;
  min-width: max-content;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  box-shadow: ${({ theme }) => theme.boxShadow.base};
  margin-right: ${SPACING[200]};
  fill: red;
  background: url(${props => props.imageURL || ShopPattern});
  background-size: 6em;
  overflow: hidden;
  cursor: pointer;
  transition: 300ms all;
  /* border: 1px solid; */
  /* border-color: rgba(52, 196, 124, 0.3); */

  &:first-child {
    margin-left: ${SPACING[200]};
  }

  &:hover {
    transform: scale(1.02);
    box-shadow: ${({ theme }) => theme.boxShadow.md};
    border-color: rgba(52, 196, 124, 1);
  }
`;

const CategoryPatternBackground = styled.div`
  /* color: ${({ color }) => `rgb(${color})`}; */
  padding: ${SPACING[200]} ${SPACING[300]};
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  transition: 1s background;
  box-shadow: ${({ theme }) => theme.boxShadow.inner};
  min-width: 180px;

  &:hover {
    background: linear-gradient(
      170deg,
      rgba(52, 196, 124, 0.3) 13%,
      rgba(52, 196, 124, 0.2) 40%,
      rgba(52, 196, 124, 0.05) 60%,
      rgba(52, 196, 124, 0) 80%
    );
  }
`;

export {
  HomePageContentWrapper,
  HomePageCategoryWrapper,
  HomePageCategoryHeader,
  HomePageCategoryShopContent,
  HeaderTitleWrapper,
  SelectedAdressBlock,
  SelectedAdressContent,
  CategoryCard,
  CategoryPatternBackground,
};

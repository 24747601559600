import styled from 'styled-components';
import { SPACING } from './constants';

const StepWrapper = styled.div`
  max-width: 700px;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  margin: 0 auto;
`;

const StepContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding-bottom: ${SPACING[200]};
`;

const IllustationWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${SPACING[200]};
`;

const Illustration = styled.img`
  width: 270px;
  height: 160px;
`;

const HeaderDetailsWrapper = styled.div`
  padding: ${SPACING[200]} 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const SublineHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  border: 1px solid ${props => props.bg || 'none'};
  padding: ${SPACING[100]} 0;
  border-radius: 4px;
`;

const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: ${props => props.align || 'center'};
  flex-direction: column;
  margin: 0 auto;
  margin: ${props => props.padding || '0'};
`;

const ItemsWrapper = styled.div`
  padding: ${SPACING[200]} 0;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
`;

const BlockCard = styled.div`
  width: 100%;
  border-radius: 4px;
  border: 1px solid ${props => props.theme.lightGray};
  box-shadow: ${props => props.theme.boxShadow.base};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin: ${props => props.margin || `${SPACING[100]} 0`};
  padding: ${props => props.padding || SPACING[100]};
`;

const AddressDetailsWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const TotalsWrapper = styled.div`
  width: 88%;
  margin-top: ${SPACING[200]};
  padding: ${SPACING[200]} 0;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const PriceLine = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: ${props => (props.first ? 0 : `${SPACING[100]};`)};
  padding-bottom: ${props => (props.withoutBorder ? 0 : `${SPACING[100]};`)};
  border-bottom: ${props =>
    props.withoutBorder
      ? 'none'
      : `1px solid ${({ theme }) => theme.lightGray}`};
`;

const TimelineRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-align: center;
  padding: ${SPACING[200]} 0;
`;

const RusherRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${SPACING[200]} 0;
`;

export {
  StepWrapper,
  StepContentWrapper,
  IllustationWrapper,
  Illustration,
  HeaderDetailsWrapper,
  SublineHeaderWrapper,
  ItemsWrapper,
  DetailsWrapper,
  BlockCard,
  AddressDetailsWrapper,
  TotalsWrapper,
  PriceLine,
  TimelineRow,
  RusherRow,
};

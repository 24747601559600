import React from 'react';
import { Divider } from '@material-ui/core';

import {
  FlexFieldsWrapper,
  CategoriesFieldsWrapper,
  AccordionDetailsContent,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from '../../../../styles/shop';
import { Button, CheckBox, Text, Heading } from '../../../../styleguide';
import { SPACING } from '../../../../styles/constants';
import { ExpandIcon } from '../../../../styleguide/icons';

const CompositionForm = props => {
  const { categories, onCategoriesChange, currentSelectedCategories } = props;
  const [selectedCategories, setSelectedCategories] = React.useState([]);

  const onSelectedCategoriesChange = (item, isCreation) => {
    if (!isCreation) {
      const updatedCategories = selectedCategories.filter(i => i !== item);
      setSelectedCategories(updatedCategories);
    } else {
      setSelectedCategories([...selectedCategories, item]);
    }
  };

  const onConfirm = () => {
    onCategoriesChange(selectedCategories);
    setSelectedCategories([]);
  };

  const onDelete = () => {
    onCategoriesChange([]);
  };

  return (
    <Accordion square style={{ width: '100%' }} defaultExpanded>
      <AccordionSummary
        expandIcon={<ExpandIcon size="16px" />}
        aria-controls="panel1a-content"
        id="panel1a-header"
      >
        <Heading size={500}>Composition</Heading>
      </AccordionSummary>
      <AccordionDetails>
        <AccordionDetailsContent>
          <Text muted size={400}>
            Pour créer des menus, choisissez simplement les differentes
            categories de produit, ainsi les clients pourront uniquement choisir
            1 produit dans chaque categorie.
          </Text>
          <CategoriesFieldsWrapper>
            {!!categories?.length ? (
              categories.map((category, index) => (
                <React.Fragment key={index}>
                  <CheckBox
                    checked={
                      !selectedCategories.length
                        ? currentSelectedCategories.includes(category)
                        : selectedCategories.includes(category)
                    }
                    onChange={isChecked =>
                      onSelectedCategoriesChange(category, isChecked)
                    }
                    name={category}
                    wrapperProps={{
                      justify: 'flex-start',
                      padding: `0 ${SPACING[100]}`,
                      maxWidth: '250px',
                      flex: 'auto',
                    }}
                    label={category}
                  />
                  <Divider orientation="vertical" variant="middle" flexItem />
                </React.Fragment>
              ))
            ) : (
              <Text>Aucune categorie sélectionné</Text>
            )}
          </CategoriesFieldsWrapper>

          <FlexFieldsWrapper>
            <Button
              primary
              small
              disabled={!selectedCategories.length}
              onClick={onConfirm}
              style={{ marginRight: 8 }}
            >
              Valider
            </Button>
            <Button minimal small onClick={onDelete}>
              Annuler
            </Button>
          </FlexFieldsWrapper>
        </AccordionDetailsContent>
      </AccordionDetails>
    </Accordion>
  );
};

export default CompositionForm;

import React from 'react';
import { Chip } from '@material-ui/core';

import {
  computeEstimatedTime,
  computeTimeFrom,
} from '../../../../actions/orders/helpers/computeTimesHelpers';
import {
  Button,
  HeaderCard,
  ItemListing,
  OrderDetails,
  OrderTimeDetails,
  OrderTitle,
  OrderTimeEnded,
  Text,
} from '../../../../styleguide';
import { useTheme } from '../../../../hooks';
import computePreparationTime from '../../../../actions/orders/helpers/computePreparationTime';
import {
  OrderCardOverlay,
  OrderCardWrapper,
  OrderCardActions,
  OrderCardHeader,
} from '../../../../styles/orders-cards';
import { SPACING } from '../../../../styles/constants';
import { ClockIcon } from '../../../../styleguide/icons';

const NewOrderCard = props => {
  const { order, onClickConfirm, onCancelOrder } = props;
  const theme = useTheme();

  const temporaryDisabled = !!order?.inTreatment;

  return (
    <OrderCardWrapper theme={theme}>
      <HeaderCard order={order} kind="admin" />
      <OrderTitle order={order} withShopName />
      <ItemListing order={order} />
      <OrderDetails order={order} kind="admin" />
      <OrderCardActions theme={theme}>
        <Button
          onClick={() => onCancelOrder(order)}
          small
          color="primary"
          styles={{ marginRight: SPACING[100], backgroundColor: theme.red }}
          loaderPadding={SPACING[100]}
          disabled={temporaryDisabled}
        >
          Refuser
        </Button>
        <Button onClick={onClickConfirm} small disabled={temporaryDisabled}>
          Accepter
        </Button>
      </OrderCardActions>
    </OrderCardWrapper>
  );
};

const InProgressOrderCard = props => {
  const { order, onClickConfirm, onCancelOrder, onClickRemoveRusher } = props;
  const theme = useTheme();
  const orderFrom = computeTimeFrom({ ts: order.orderAt });

  const temporaryDisabled = !!order?.inTreatment;

  const preparationTime =
    order.estimatedTimeToDeliver || computePreparationTime(order.itemsList);

  const estimatedDeliveryTime = computeEstimatedTime({
    ts: order.acceptedAt,
    withDelay: false,
    minutes: preparationTime,
    seconds: order.duration,
  });

  return (
    <OrderCardWrapper theme={theme}>
      <OrderCardHeader>
        <Chip icon={<ClockIcon />} label={estimatedDeliveryTime} />
        <Chip icon={<ClockIcon />} label={orderFrom} />
      </OrderCardHeader>
      <OrderTitle order={order} withShopName />
      <OrderTimeDetails order={order} timeColor={theme.primaryColor} />
      <ItemListing order={order} />
      <OrderDetails order={order} type="in-progress" kind="admin" />
      <OrderCardActions theme={theme}>
        <Button
          onClick={() => onCancelOrder(order)}
          small
          color="primary"
          styles={{ marginRight: SPACING[100], backgroundColor: theme.red }}
          loaderPadding={SPACING[100]}
          disabled={temporaryDisabled}
        >
          Annuler
        </Button>
        {!order.rusher ? (
          <Button
            onClick={onClickConfirm}
            small
            disabled={order.rusher || temporaryDisabled}
          >
            Attribuer
          </Button>
        ) : (
          <Button
            onClick={() => onClickRemoveRusher(order)}
            small
            disabled={temporaryDisabled}
          >
            Changer livreur
          </Button>
        )}
      </OrderCardActions>
    </OrderCardWrapper>
  );
};

const ReadyOrderCard = props => {
  const { order, onClickConfirm, onCancelOrder, onClickRemoveRusher } = props;
  const theme = useTheme();
  const temporaryDisabled = !!order?.inTreatment;

  return (
    <OrderCardWrapper theme={theme}>
      <HeaderCard order={order} kind="admin" />
      <OrderTitle order={order} withShopName />
      <OrderTimeDetails order={order} timeColor={theme.primaryColor} isReady />
      <ItemListing order={order} expanded={false} />
      <OrderDetails type="ready" order={order} />
      <OrderCardActions theme={theme}>
        <Button
          onClick={() => onCancelOrder(order)}
          small
          color="primary"
          styles={{ marginRight: SPACING[100], backgroundColor: theme.red }}
          loaderPadding={SPACING[100]}
          disabled={temporaryDisabled}
        >
          Annuler
        </Button>
        {!order.rusher ? (
          <Button
            onClick={onClickConfirm}
            small
            disabled={order.rusher || temporaryDisabled}
          >
            Attribuer
          </Button>
        ) : (
          <Button
            onClick={() => onClickRemoveRusher(order)}
            small
            disabled={temporaryDisabled}
          >
            Changer livreur
          </Button>
        )}
      </OrderCardActions>
    </OrderCardWrapper>
  );
};

const InDeliveryCard = props => {
  const { order, onClickConfirm, isLoading, onCancelOrder } = props;
  const theme = useTheme();
  const temporaryDisabled = !!order?.inTreatment;

  return (
    <OrderCardWrapper theme={theme}>
      <HeaderCard order={order} kind="admin" />
      <OrderTitle order={order} withShopName />
      <OrderTimeDetails
        order={order}
        timeColor={theme.primaryColor}
        inDelivery
      />
      <ItemListing order={order} expanded={false} />
      <OrderDetails type="ready" order={order} kind="admin" />

      <OrderCardActions theme={theme}>
        <Button
          onClick={() => onCancelOrder(order)}
          small
          color="primary"
          styles={{ marginRight: SPACING[100], backgroundColor: theme.red }}
          loaderPadding={SPACING[100]}
          disabled={temporaryDisabled}
        >
          Annuler
        </Button>
        <Button
          onClick={onClickConfirm}
          small
          isLoading={isLoading}
          disabled={temporaryDisabled}
        >
          Livrer
        </Button>
      </OrderCardActions>
    </OrderCardWrapper>
  );
};

const DeliveredCard = props => {
  const { order } = props;
  const theme = useTheme();

  return (
    <OrderCardWrapper theme={theme}>
      <OrderCardOverlay>
        <Text
          size={500}
          styles={{
            color: theme.primaryColor,
            backgroundColor: theme.cardBg,
            fontWeight: 'bold',
          }}
        >
          Commande livrée{' 🎉'}
        </Text>
      </OrderCardOverlay>
      <HeaderCard order={order} kind="admin" />
      <OrderTitle order={order} withShopName />
      <OrderTimeEnded order={order} />

      <ItemListing order={order} expanded={false} />
      <OrderDetails order={order} expanded={false} />
    </OrderCardWrapper>
  );
};

export {
  NewOrderCard,
  InProgressOrderCard,
  ReadyOrderCard,
  InDeliveryCard,
  DeliveredCard,
};

import React from 'react';

import {
  HeaderDialog,
  BodyBlocksDialog,
  ContentBlockDialog,
  HeaderDialogBlock,
  HeaderBodyBlocksDialog,
  RoleDetailsWrapper,
} from '../../../styles/admin';
import { BasicColumnBlock } from '../../../styles/common';
import { onInvoiceCancel, onSendInvoice } from '../../../actions/rusher';
import { Text, Heading, FullscreenDialog, Button } from '../../../styleguide';
import { DeleteIcon, SendIcon } from '../../../styleguide/icons';
import { SPACING } from '../../../styles/constants';
import { useTheme } from '../../../hooks';
import InvoiceResumeTemplate from './InvoiceResumeTemplate';
import { parseNumber } from '../../../common';

const InvoiceDialog = props => {
  const { invoice, onClose, orders, rusher, invoices, ...rest } = props;
  const [isLoading, setLoading] = React.useState(false);

  const targetedOrders = invoice.orders.map(
    id => orders.filter(order => order.id === id)[0]
  );

  const theme = useTheme();

  const cancelInvoice = async () => {
    setLoading(true);
    const invoiceToCancel = invoices.find(i => i.id === invoice.id);
    await onInvoiceCancel(invoiceToCancel);
    setLoading(false);
    onClose();
  };

  const sendInvoice = async () => {
    setLoading(true);
    const invoiceToFlag = invoices.find(i => i.id === invoice.id);
    await onSendInvoice(invoiceToFlag);
    setLoading(false);
    onClose();
  };

  return (
    <FullscreenDialog
      theme={theme}
      title="Facturation"
      onClose={onClose}
      {...rest}
    >
      <HeaderDialog>
        <HeaderDialogBlock>
          <Heading size={700} styles={{ paddingBottom: SPACING[100] }}>
            Facture numéro - {invoice.number} / {invoice.id}
          </Heading>
          <Text size={500} muted styles={{ paddingLeft: SPACING[100] }}>
            {invoice.generatedAt}
          </Text>
        </HeaderDialogBlock>
      </HeaderDialog>

      {invoice.status === 'pending' ? (
        <>
          <RoleDetailsWrapper>
            <Button
              small
              minimal
              variant="outlined"
              onClick={cancelInvoice}
              startIcon={<DeleteIcon color={theme.headingColor} size="16px" />}
              isLoading={isLoading}
              styles={{ marginRight: SPACING[100] }}
            >
              Annuler
            </Button>
            <Button
              small
              onClick={sendInvoice}
              startIcon={<SendIcon color={theme.white} size="16px" />}
              isLoading={isLoading}
            >
              Envoyer
            </Button>
          </RoleDetailsWrapper>
        </>
      ) : null}

      <BodyBlocksDialog>
        <HeaderBodyBlocksDialog>
          <Heading size={500}>Informations générales</Heading>
        </HeaderBodyBlocksDialog>

        <ContentBlockDialog theme={theme} style={{ alignItems: 'center' }}>
          <Text size={400} muted>
            Status
          </Text>
          <Text size={500} color={theme.primaryColor}>
            {invoice.status}
          </Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme} style={{ alignItems: 'center' }}>
          <Text size={400} muted>
            Total HT facturé
          </Text>
          <Text size={500} color={theme.primaryColor}>
            {parseNumber(invoice.total).toFixed(2)}€
          </Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme} style={{ alignItems: 'center' }}>
          <Text size={400} muted>
            Total Net facturé
          </Text>
          <Text size={500}>{parseNumber(invoice.net).toFixed(2)}€</Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme} style={{ alignItems: 'center' }}>
          <Text size={400} muted>
            Commandes facturées
          </Text>
          <Text size={500} color={theme.primaryColor}>
            {invoice.orders.length}
          </Text>
        </ContentBlockDialog>

        <HeaderBodyBlocksDialog>
          <Heading size={500}>Récapitulatif de votre facture</Heading>
        </HeaderBodyBlocksDialog>

        <BasicColumnBlock justify="flex-start">
          <InvoiceResumeTemplate
            rusher={rusher}
            invoice={invoice}
            displayList={false}
            date={invoice.generatedAt}
            orders={targetedOrders}
          />
        </BasicColumnBlock>
      </BodyBlocksDialog>
    </FullscreenDialog>
  );
};

export default InvoiceDialog;

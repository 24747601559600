import React from 'react';
import Firebase from 'firebase/app';
import { useDocumentData } from 'react-firebase-hooks/firestore';
import { useHistory } from 'react-router-dom';
import { Chip } from '@material-ui/core';

import {
  MainShopHeaderWrapper,
  OrdersActionsWrapper,
} from '../../../../../styles/shop';
import { Button, Heading, Loader } from '../../../../../styleguide';
import { useSession, useTheme } from '../../../../../hooks';

import { SPACING } from '../../../../../styles/constants';
import { OrdersIcon, DashboardIcon } from '../../../../../styleguide/icons';

const { REACT_APP_SETTINGS_DOC_ID } = process.env;

const DashboardHeader = props => {
  const { title, withBackButton } = props;
  const theme = useTheme();
  const { user } = useSession();
  const history = useHistory();

  const [settings, settingsLoading, settingsError] = useDocumentData(
    Firebase.firestore().doc(`app/${REACT_APP_SETTINGS_DOC_ID}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (!!settingsLoading) {
    return (
      <Loader color={theme.primaryColor} background={theme.backgroundColor} />
    );
  }

  if (settingsError) {
    throw new Error(settingsError.toString());
  }

  return (
    <>
      <MainShopHeaderWrapper
        background={theme.backgroundColor}
        justify={'center'}
      >
        <Heading
          size={800}
          styles={{
            color: theme.headingColor,
            marginRight: SPACING[100],
            background: theme.backgroundColor,
            textShadow:
              user.settings.theme !== 'light'
                ? `2px 2px 2px ${theme.black}`
                : 'none',
          }}
          centered
        >
          {title}
        </Heading>
      </MainShopHeaderWrapper>
      <OrdersActionsWrapper align={'space-between'}>
        {withBackButton ? (
          <Button
            small
            primary
            onClick={() => history.push(`/admin/dashboard`)}
            startIcon={<DashboardIcon color={theme.white} size="16px" />}
          >
            Retour
          </Button>
        ) : (
          <Button
            small
            primary
            onClick={() => history.push(`/admin/orders/`)}
            startIcon={<OrdersIcon color={theme.white} size="16px" />}
            styles={{ marginRight: '8px' }}
          >
            Commandes
          </Button>
        )}

        <Chip
          label={`${settings.open ? 'Ouvert' : 'Fermé'}`}
          color={settings.open ? 'primary' : 'secondary'}
          variant={'default'}
        />
      </OrdersActionsWrapper>
    </>
  );
};

export default DashboardHeader;

import React from 'react';
import { useHistory } from 'react-router-dom';

import { HeaderRow } from '../../../../../styles/admin';
import { Button, Heading, Text } from '../../../../../styleguide';
import { useTheme } from '../../../../../hooks';
import { OrdersIcon } from '../../../../../styleguide/icons';

const BlockHeader = props => {
  const { title, label, path, full, button } = props;
  const theme = useTheme();
  const history = useHistory();

  return (
    <HeaderRow>
      <div style={{ maxWidth: full ? '100%' : '58%' }}>
        <Heading size={600} style={{ marginBottom: 4 }}>
          {title}
        </Heading>
        <Text muted size={400}>
          {label}
        </Text>
      </div>

      {path && (
        <Button
          small
          color="secondary"
          onClick={() => history.push(path)}
          startIcon={<OrdersIcon color={theme.white} size="16px" />}
          styles={{ marginRight: '8px' }}
        >
          Voir plus
        </Button>
      )}
      {button}
    </HeaderRow>
  );
};

export default BlockHeader;

import React from 'react';
import styled from 'styled-components';

const Panel = styled.div`
  width: 100%;
`;

const TabPanel = props => {
  const { value, index, children, ...rest } = props;

  return (
    <Panel
      role="tabpanel"
      hidden={value !== index}
      id={`nav-tabpanel-${index}`}
      aria-labelledby={`nav-tab-${index}`}
      {...rest}
    >
      {value === index && children}
    </Panel>
  );
};

export default TabPanel;

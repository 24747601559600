import React from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import { SPACING } from '../../../styles/constants';
import { Button, Heading, Text } from '../../../styleguide';
import { ButtonsWrapper } from '../../../styles/form';

const MainWrapper = styled.div`
  width: 100%;
  max-width: 700px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: ${SPACING[200]} 0;
`;

const DescriptionWrapper = styled.div`
  padding: ${SPACING[200]} 0;
`;

const CongratulationStep = props => {
  const { rusher } = props;
  const history = useHistory();

  const onClickRedirect = () => {
    history.push('/rusher/profil');
  };

  return (
    <MainWrapper>
      <Heading size={900} centered>
        Félicitations {rusher.name} 🎉
      </Heading>

      <DescriptionWrapper>
        <Text size={400}>
          Vous êtes désormais inscrit en tant que livreur, une fois que nous
          aurons validé votre compte, vous pourrez livrer vos premières
          commandes.
        </Text>
      </DescriptionWrapper>

      <ButtonsWrapper>
        <Button onClick={onClickRedirect}>Accéder à mon profil</Button>
      </ButtonsWrapper>
    </MainWrapper>
  );
};

export default CongratulationStep;

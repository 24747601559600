import React from 'react';
import { Button, Text } from '../../../../styleguide';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import ORDER_STATUS from '../../../../actions/orders/helpers/STATUS';

const ReadyOrderDialog = props => {
  const { isOpen, onClose, onConfirm, order } = props;

  const temporaryDisabled = !!order?.inTreatment;

  React.useEffect(() => {
    if (order.status !== ORDER_STATUS.ready) {
      onClose();
    }
  }, [order, onClose]);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="order-alert-dialog-title"
    >
      <DialogTitle id="order-alert-dialog-title">
        Départ de la commande
      </DialogTitle>
      <DialogContent>
        <Text size={400}>
          En confirmant le départ de la commande, le client sera mis au courant
          que sa commande est en chemin 🛣
        </Text>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} small minimal>
          Attendre
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          small
          disabled={temporaryDisabled}
        >
          Go
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default ReadyOrderDialog;

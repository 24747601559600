const AREAS_FEES = {
  area_1: {
    maxDistance: 4500,
    cost: 5,
  },
  area_2: {
    maxDistance: 7000,
    cost: 7,
  },
  area_3: {
    maxDistance: 9000,
    cost: 9,
  },
  area_4: {
    maxDistance: 11000,
    cost: 13,
  },
  area_5: {
    maxDistance: 15000,
    cost: 18,
  },
  area_6: {
    maxDistance: 20000,
    cost: 25,
  },
  area_7: {
    maxDistance: 25000,
    cost: 30,
  },
  area_8: {
    maxDistance: 30000,
    cost: 35,
  },
  area_9: {
    maxDistance: 35000,
    cost: 45,
  },
  area_10: {
    maxDistance: 40000,
    cost: 60,
  },
  area_11: {
    maxDistance: 45000,
    cost: 70,
  },
  area_max: {
    cost: 80,
  },
};

const APP_FEES = 2;

const getInitialDeliveryFees = distance => {
  const result = {
    deliveryFees: 0,
    distance,
  };

  if (distance <= AREAS_FEES.area_1.maxDistance) {
    result.deliveryFees = AREAS_FEES.area_1.cost;
  } else if (distance <= AREAS_FEES.area_2.maxDistance) {
    result.deliveryFees = AREAS_FEES.area_2.cost;
  } else if (distance <= AREAS_FEES.area_3.maxDistance) {
    result.deliveryFees = AREAS_FEES.area_3.cost;
  } else if (distance <= AREAS_FEES.area_4.maxDistance) {
    result.deliveryFees = AREAS_FEES.area_4.cost;
  } else if (distance <= AREAS_FEES.area_5.maxDistance) {
    result.deliveryFees = AREAS_FEES.area_5.cost;
  } else if (distance <= AREAS_FEES.area_6.maxDistance) {
    result.deliveryFees = AREAS_FEES.area_6.cost;
  } else if (distance <= AREAS_FEES.area_7.maxDistance) {
    result.deliveryFees = AREAS_FEES.area_7.cost;
  } else if (distance <= AREAS_FEES.area_8.maxDistance) {
    result.deliveryFees = AREAS_FEES.area_8.cost;
  } else if (distance <= AREAS_FEES.area_9.maxDistance) {
    result.deliveryFees = AREAS_FEES.area_9.cost;
  } else if (distance <= AREAS_FEES.area_10.maxDistance) {
    result.deliveryFees = AREAS_FEES.area_10.cost;
  } else if (distance <= AREAS_FEES.area_11.maxDistance) {
    result.deliveryFees = AREAS_FEES.area_11.cost;
  } else {
    result.deliveryFees = AREAS_FEES.area_max.cost;
  }

  return result.deliveryFees;
};

export { APP_FEES, AREAS_FEES, getInitialDeliveryFees };

import React from 'react';
import { Link } from 'react-router-dom';
import { Heading, Text } from '../../styleguide';
import { useTheme } from '../../hooks';
import { useTranslation } from 'react-i18next';

import { HeaderWrapper } from '../../styles/basket';

const Header = props => {
  const { itemsCount, shop, title } = props;
  const theme = useTheme();
  const { t } = useTranslation(['basket', 'common']);

  return (
    <HeaderWrapper>
      <Heading size={900} centered={false}>
        {title}
      </Heading>

      <Text muted size={500} styles={{ padding: '8px', paddingLeft: 0 }}>
        {`${itemsCount} ${t('common:item')}${itemsCount > 1 ? 's' : ''}`}{' '}
        {t('basket:page:at')}{' '}
        <Link
          style={{ color: theme.primaryColor, textDecoration: 'none' }}
          to={`/shop/${shop.id}`}
        >
          {shop.name}
        </Link>
      </Text>
    </HeaderWrapper>
  );
};

export default Header;

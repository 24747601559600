import React from 'react';
import Firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { IconButton } from '@material-ui/core';

import {
  Heading,
  Container,
  Loader,
  Button,
  HoverableLink,
  SimpleHoverableAction,
  NavLink,
} from '../../../styleguide';
import { ReturnIcon } from '../../../styleguide/icons';
import { Page, EmptyPage } from '../../common';
import { OrdersWrapper, MainWrapper, OrdersBlock } from '../../../styles/order';
import { useSession, useTheme } from '../../../hooks';
import OrderCard from './OrderCard';
import ORDER_STATUS from '../../../actions/orders/helpers/STATUS';
import IllustrationSrc from '../../../assets/images/welcome.svg';
import { ButtonsWrapper } from '../../../styles/common';
import { SPACING } from '../../../styles/constants';

const ActiveOrders = props => {
  const { user } = useSession();
  const theme = useTheme();
  let history = useHistory();
  const { t } = useTranslation(['account']);
  const [ordersLimit, setOrdersLimit] = React.useState(10);

  const [ordersValues, ordersLoading, ordersError] = useCollectionData(
    Firebase.firestore()
      .collection('orders')
      .where(`clientID`, '==', user.id)
      .where(`status`, 'in', [
        ORDER_STATUS.inValidation,
        ORDER_STATUS.inProgress,
        ORDER_STATUS.ready,
        ORDER_STATUS.inDelivery,
      ])
      .orderBy('orderAt', 'desc')
      .limit(ordersLimit),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (ordersLoading) {
    return (
      <Loader color={theme.primaryColor} background={theme.backgroundColor} />
    );
  }

  if (ordersError) {
    throw new Error(ordersError.toString());
  }

  const onClickOnOrder = orderID => {
    history.push(`/account/orders/${orderID}`);
  };

  const hasOrders = !!ordersValues.length;

  return (
    <Page
      name={t('account:orders:active:page_title')}
      navbarLeftChild={
        <NavLink color={theme.headingColor} to="/account">
          <IconButton aria-label="retour">
            <ReturnIcon color={theme.headingColor} size="16px" />
          </IconButton>
        </NavLink>
      }
    >
      <Container justify={hasOrders ? 'flex-start' : 'center'}>
        <MainWrapper centered={!hasOrders}>
          {hasOrders ? (
            <OrdersBlock>
              <div>
                <Heading
                  size={900}
                  styles={{
                    marginBottom: SPACING[200],
                  }}
                >
                  {t('account:orders:active:page_title')} ({ordersValues.length}
                  )
                </Heading>
                <HoverableLink
                  to="/account/orders/history"
                  color={theme.primaryColor}
                >
                  {/* {t('orders:phone:email_activation_link')} */}
                  {t('account:orders:history_link_label')}
                </HoverableLink>
              </div>

              <OrdersWrapper>
                {ordersValues.map(order => (
                  <OrderCard
                    key={order.id}
                    order={order}
                    onClickOnOrder={() => onClickOnOrder(order.id)}
                  />
                ))}
                {ordersValues.length === ordersLimit && (
                  <SimpleHoverableAction
                    onClick={() => {
                      setOrdersLimit(ordersLimit + 10);
                    }}
                    wrapperStyles={{
                      margin: '0 auto',
                    }}
                  >
                    {t('account:orders:active:load_more')}
                  </SimpleHoverableAction>
                )}
              </OrdersWrapper>
            </OrdersBlock>
          ) : (
            <>
              <EmptyPage
                illustration={IllustrationSrc}
                title={t('account:orders:active:empty_title')}
                description={t('account:orders:active:empty_subtitle')}
                withoutButton
              />

              <ButtonsWrapper
                overrides={`
                  gap: ${SPACING[200]};
                `}
              >
                <Button
                  onClick={() => {
                    history.push('/account');
                  }}
                >
                  {t('account:orders:active:back_to_account')}
                </Button>
                <Button
                  minimal
                  onClick={() => {
                    history.push('/account/orders/history');
                  }}
                >
                  {t('account:orders:active:all_orders')}
                </Button>
              </ButtonsWrapper>
            </>
          )}
        </MainWrapper>
      </Container>
    </Page>
  );
};

export default ActiveOrders;

import React from 'react';
import { Divider } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import {
  StepContentWrapper,
  ItemsWrapper,
} from '../../../../../styles/order-tracking';
import { SPACING } from '../../../../../styles/constants';
import { Button } from '../../../../../styleguide';
// import finishOrder from '../../../../../actions/orders/finishOrder';
// import { differenceBetweenTS } from '../../../../../actions/orders/helpers/computeTimesHelpers';
// import { toaster } from 'evergreen-ui';
import WaitingIllustration from '../../../../../assets/images/orders/Waiting.svg';
import ProductLine from '../../../../basket/ProductLine';
import MenuLine from '../../../../basket/MenuLine';
import {
  Totals,
  Details,
  IllustrationHeader,
  Header,
  MessageBlock,
} from './Commons';
// import CancelModal from './CancelModal';

const WaitingStep = props => {
  const { order } = props;
  const { t } = useTranslation(['orders']);

  // const [isLoading, setIsLoading] = React.useState(false);
  // const [confirmModal, setConfirmModal] = React.useState(null);
  // const [canCancel, setCanCancel] = React.useState(false);
  // const temporaryDisabled = !!order?.inTreatment;

  // React.useEffect(() => {
  //   if (order && !canCancel) {
  //     const hasWaitMoreThan5Min =
  //       differenceBetweenTS(Date.now(), order.orderAt, true)?.minutes >= 5;
  //     if (!!hasWaitMoreThan5Min) {
  //       setCanCancel(true);
  //     } else {
  //       const interval = setInterval(() => {
  //         const waitingDuration = differenceBetweenTS(
  //           Date.now(),
  //           order.orderAt,
  //           true
  //         );

  //         if (!!waitingDuration?.minutes && waitingDuration?.minutes >= 5) {
  //           setCanCancel(true);
  //         }
  //         // console.log('cant cancel', waitingDuration);
  //       }, 10000);

  //       return () => clearInterval(interval);
  //     }
  //   }
  // }, [order, setCanCancel, canCancel]);

  // const onCancel = async () => {
  //   try {
  //     setIsLoading(true);
  //     await finishOrder(order, 'CANCELED', 'user');
  //     toaster.success(`Commande annulé ❌`, {
  //       description: `Votre commande numéro ${order.id} est annulé`,
  //     });
  //     setIsLoading(false);
  //     setConfirmModal(null);
  //   } catch (error) {
  //     setIsLoading(false);
  //     toaster.danger(`Une erreur est survenue`, {
  //       description: `Impossible d'annuler la commande ${order.id}`,
  //     });
  //     setConfirmModal(null);
  //   }
  // };

  return (
    <StepContentWrapper>
      <IllustrationHeader
        imgSrc={WaitingIllustration}
        alt="Illustration attente confirmation"
      />
      <Header
        order={order}
        title={t('orders:tracking:waiting_title')}
        emoji="⏳"
      />
      <Divider style={{ width: '100%', marginBottom: `${SPACING[400]}` }} />

      <Details order={order} />

      <Totals order={order} />
      <Divider style={{ width: '100%', margin: `${SPACING[100]}` }} />

      <ItemsWrapper>
        {order.itemsList.map((shopItem, index) =>
          shopItem.item.isMenu ? (
            <>
              <MenuLine key={index} shopItem={shopItem} small />
              {order.itemsList.length - 1 !== index && (
                <Divider style={{ width: '100%', margin: `${SPACING[100]}` }} />
              )}
            </>
          ) : (
            <>
              <ProductLine key={index} shopItem={shopItem} small />
              {order.itemsList.length - 1 !== index && (
                <Divider style={{ width: '100%', margin: `${SPACING[100]}` }} />
              )}
            </>
          )
        )}
      </ItemsWrapper>
      <MessageBlock order={order} />
      <Button
        onClick={() =>
          window.open(
            'https://m.me/108163771705869',
            '_blank',
            'noopener noreferrer'
          )
        }
        small
        color="secondary"
        minimal
        styles={{ marginRight: SPACING[100] }}
        loaderPadding={SPACING[100]}
      >
        {t('orders:tracking:cancel_button')}
      </Button>

      {/*confirmModal ? (
        <CancelModal
          isOpen={confirmModal}
          onClose={() => setConfirmModal(null)}
          onConfirm={onCancel}
          disabled={temporaryDisabled}
        />
      ) : null} */}
    </StepContentWrapper>
  );
};

export default WaitingStep;

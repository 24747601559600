import styled from 'styled-components';
import { SPACING, colors, BOX_SHADOW, devices } from './constants';

const GlobalWrapper = styled.main`
  width: 100vw;
  height: auto;
  position: relative;
  background-color: ${props => props.theme.backgroundColor};
`;

const MainWrapper = styled.div`
  width: 100vw;
  min-height: 100vh;
  background-color: ${props => props.theme.backgroundColor};
  position: relative;
  border-bottom-right-radius: ${SPACING[200]};
  border-bottom-left-radius: ${SPACING[200]};
  z-index: 1;
  box-shadow: ${BOX_SHADOW['2xl']};
  padding-bottom: ${props => (props.pBottomLg ? SPACING[600] : 0)};
  margin-bottom: 40em;

  @media ${devices.laptop} {
    margin-bottom: 20em;
  }
`;

const FooterBlock = styled.footer`
  width: 100vw;
  min-height: 135vh;
  top: auto;
  background-color: ${props => props.theme.black};
  color: ${props => props.theme.footerColor};
  margin-top: -${SPACING[200]};
  position: fixed;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
`;

const FooterContainer = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  min-height: 20em;
  width: 100%;
  padding: ${SPACING[400]};
  bottom: 0;
  position: relative;
`;

const FooterGrid = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  width: 100%;
  flex-direction: column;
  flex-wrap: nowrap;

  @media ${devices.laptop} {
    margin-bottom: 0;
    padding-left: 0;
    min-width: 200px;
    flex-direction: row;
    flex-wrap: wrap;
  }
`;

const FooterColumn = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: fit-content;
  min-width: 300px;
  margin-top: ${props => (!props.first ? SPACING[100] : 0)};
  margin-bottom: ${SPACING[200]};

  @media ${devices.laptop} {
    margin-bottom: 0;
    padding-left: 0;
    min-width: 200px;
  }
`;

const FooterLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${SPACING[200]};

  img {
    margin-right: ${SPACING[200]};
  }

  @media ${devices.mobileLG} {
    margin-bottom: 0;
  }
`;

const MainContainer = styled.div`
  margin: 0 auto;
  max-width: 1440px;
  min-height: 100vh;
`;

const CookiesBannerContainer = styled.div`
  width: 100%;
  left: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 100;
  background-color: ${props => props.bg || colors.white};
  box-shadow: 0 -8px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  position: fixed;
`;

const CookiesBannerWrapper = styled.div`
  padding: ${SPACING[200]} ${SPACING[100]};
  width: 100%;
  max-width: 1440px;
  display: flex;
  justify-content: space-around;
  align-items: center;

  & > span {
    max-width: 70%;
  }
`;

const NotificationsContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

export {
  MainWrapper,
  MainContainer,
  CookiesBannerContainer,
  CookiesBannerWrapper,
  NotificationsContentWrapper,
  FooterBlock,
  GlobalWrapper,
  FooterContainer,
  FooterColumn,
  FooterGrid,
  FooterLogo,
};

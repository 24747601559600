import React from 'react';
import styled from 'styled-components';
import { Link, useLocation } from 'react-router-dom';
import DarkLogo from '../assets/images/asap-dark.png';
import LightLogo from '../assets/images/asap-light.png';

const ImageLogo = styled.img`
  width: ${props => props.width || '30px'};
  height: ${props => props.height || '21px'};
`;

const Logo = props => {
  const { light = false, width, height } = props;
  const location = useLocation();

  const Wrapper = location.pathname === '/' ? React.Fragment : Link;
  const wrapperProps =
    location.pathname === '/'
      ? {}
      : { to: '/', style: { display: 'inline-flex' } };

  if (light) {
    return (
      <Wrapper {...wrapperProps}>
        <ImageLogo
          width={width}
          height={height}
          src={DarkLogo}
          alt="Delyzon chx logo"
          lazy
        />
      </Wrapper>
    );
  }

  return (
    <Wrapper {...wrapperProps}>
      <ImageLogo
        width={width}
        height={height}
        src={LightLogo}
        alt="Delyzon chx logo"
        lazy
      />
    </Wrapper>
  );
};

export default Logo;

import React from 'react';
import { toaster } from 'evergreen-ui';

import ORDER_STATUS from '../../../../actions/orders/helpers/STATUS';
import updateOrder from '../../../../actions/orders/updateOrder';
import computePreparationTime from '../../../../actions/orders/helpers/computePreparationTime';
import { Heading } from '../../../../styleguide';
import { TabContentWrapper } from '../../../../styles/shop';
import {
  OrdersListWrapper,
  EmptyNewOrders,
} from '../../../../styles/orders-cards';
import TabPanel from '../../../shop/tabs/TabPanel';
import { NewOrderCard } from '../cards/Cards';
import NewOrderDialog from '../dialogs/NewOrderDialog';
import EmptyTab from './EmptyTab';

const NewsTab = props => {
  const { value, orders, onCancelOrder } = props;
  const [confirmModal, setConfirmModal] = React.useState(null);
  const [selectedOrder, setSelectedOrder] = React.useState(null);

  React.useEffect(() => {
    if (!!selectedOrder && !!confirmModal) {
      const orderToUpdate = orders.find(o => o.id === selectedOrder.id);

      if (!orderToUpdate || !!orderToUpdate?.inTreatment) {
        setConfirmModal(null);
        return;
      }
    }
  }, [orders, selectedOrder, confirmModal]);

  const onConfirm = async order => {
    try {
      const orderToUpdate = orders.find(o => o.id === order.id);

      if (!orderToUpdate || !!orderToUpdate?.inTreatment) {
        setConfirmModal(null);
        toaster.warning(
          `⏳ Impossible commande en cours de traitement, réessayez plus tard`
        );
        return;
      }

      await updateOrder(orderToUpdate, {
        status: ORDER_STATUS.inProgress,
        acceptedAt: Date.now(),
      });
      toaster.success(`Commande acceptée 🚀`, {
        description: `La commande numéro ${order.id} est desormais en préparation`,
      });
      setConfirmModal(null);
    } catch (error) {
      setConfirmModal(null);
      toaster.danger(`Une erreur est survenue`, {
        description: `Impossible d'accepter la commande ${order.id}`,
      });
    }
  };

  // const onCancel = async order => {
  //   setCancelInProgress(order.id);
  //   setConfirmModal(null);
  //   await finishOrder(order, 'CANCELED');
  //   toaster.success(`Commande annulé ❌`, {
  //     description: `La commande numéro ${order.id} est desormais annulé`,
  //   });
  //   setCancelInProgress(null);
  // };

  const hasExistingOrders = !!orders.length;
  const preparationTime = !selectedOrder
    ? 0
    : selectedOrder.estimatedTimeToDeliver ||
      computePreparationTime(selectedOrder.itemsList);

  return (
    <TabPanel value={value} index={0}>
      <TabContentWrapper>
        {hasExistingOrders ? (
          <>
            <Heading size={800}>Les nouvelles commandes</Heading>

            <OrdersListWrapper>
              {orders.map(order => {
                return (
                  <NewOrderCard
                    key={order.id}
                    onClickConfirm={() => {
                      setConfirmModal('confirm');
                      setSelectedOrder(order);
                    }}
                    order={order}
                    onCancelOrder={onCancelOrder}
                  />
                );
              })}
            </OrdersListWrapper>
            {!!confirmModal ? (
              <NewOrderDialog
                isOpen={!!confirmModal}
                confirmModal={confirmModal}
                preparationTime={preparationTime}
                onClose={() => setConfirmModal(null)}
                onConfirm={() => onConfirm(selectedOrder)}
                order={selectedOrder}
              />
            ) : null}
          </>
        ) : (
          <EmptyNewOrders>
            <EmptyTab
              title={'Pas de nouvelle commande'}
              description={
                'Ne vous inquietez pas, les commandes ne vont pas tarder à pleuvoir ⏳'
              }
            />
          </EmptyNewOrders>
        )}
      </TabContentWrapper>
    </TabPanel>
  );
};

export default NewsTab;

import styled from 'styled-components';
import { SPACING, BOX_SHADOW, devices } from './constants';

const NavBarContainer = styled.div`
  width: 100%;
  height: 60px;
  padding: ${SPACING[100]};
  background-color: ${props => props.bg};
  max-width: 1440px;
  padding-bottom: ${SPACING[100]};
  box-shadow: ${props => (props.sticky ? BOX_SHADOW.inset : 'none')};
  transition: all 200ms ease-in-out;
  display: flex;

  @media ${devices.mobileLG} {
    padding: ${SPACING[100]} ${SPACING[200]};
  }
`;

export { NavBarContainer };

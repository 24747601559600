import React from 'react';

import { updateUser } from '../../../actions/users';
import { Page } from '../../common';

import { useSession } from '../../../hooks';
import {
  Heading,
  Container,
  PropertyEdit,
  LineProperty,
} from '../../../styleguide';
import { DetailsWrapper } from '../../../styles/account';
import { MainWrapper } from '../../../styles/adresseslist';

const Settings = () => {
  const { user, authUser } = useSession();
  const [editing, setEdition] = React.useState(null);

  const { settings } = user;
  const initialValue = settings[editing];

  const onSwitch = (prop, checked) => {
    if (prop === 'theme') {
      if (settings.theme === 'light' && checked) {
        updateUser({ settings: { ...settings, theme: 'dark' } }, authUser.uid);
      }

      if (settings.theme === 'dark' && !checked) {
        updateUser({ settings: { ...settings, theme: 'light' } }, authUser.uid);
      }

      return;
    }
    updateUser({ settings: { ...settings, [prop]: checked } }, authUser.uid);
  };

  // Useless settings for the moment
  const displayed = false;

  return (
    <Page name="Paramétres">
      <Container justify="flex-start">
        <MainWrapper padding>
          <Heading size={900}>Réglages</Heading>

          {!editing ? (
            <>
              <DetailsWrapper>
                {displayed ? (
                  <LineProperty
                    value={settings.language}
                    label="Langue"
                    onClick={() => setEdition('language')}
                  />
                ) : null}
                <LineProperty
                  contentValue={`Passer à la version ${
                    settings.theme === 'light' ? `"Dark"` : `"Light"`
                  }`}
                  value={settings.theme}
                  label="Theme"
                  onSwitch={event => onSwitch('theme', event.target.checked)}
                  isChecked={settings.theme !== 'light'}
                />
                {displayed ? (
                  <LineProperty
                    contentValue="Souhaitez vous recevoir nos offres spéciales ?"
                    value={settings.specialOffers}
                    label="Offres speciales"
                    onSwitch={event =>
                      onSwitch('specialOffers', event.target.checked)
                    }
                    isChecked={settings.specialOffers}
                  />
                ) : null}
              </DetailsWrapper>
            </>
          ) : (
            <PropertyEdit
              property={editing}
              setEdition={setEdition}
              initialValue={initialValue}
              onUpdate={updateUser}
            />
          )}
        </MainWrapper>
      </Container>
    </Page>
  );
};

export default Settings;

import React from 'react';
import styled from 'styled-components';
import { Popper, Fade } from '@material-ui/core';

import { useTheme } from '../hooks';
import Text from './text';
import { SPACING, BOX_SHADOW, colors } from '../styles/constants';

const MenuWrapper = styled.div`
  box-shadow: ${props => props.boxShadow.lg};
  padding: ${SPACING[200]};
  border-radius: 4px;
  background-color: ${props => props.backgroundColor};
  z-index: 10;
  max-width: 400px;
  min-width: 340px;
  width: 100%;
  text-align: left;
`;

const MenuItemWrapper = styled.div`
  padding: ${SPACING[200]};
  border-bottom: 1px solid ${props => props.lightGray};
  cursor: pointer;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const Menu = props => {
  const { anchorEl, children } = props;

  const theme = useTheme();
  const open = Boolean(anchorEl);
  const id = open ? `transitions-popper` : undefined;
  const boxShadow = theme === 'light' ? BOX_SHADOW : theme.boxShadow;
  const backgroundColor =
    theme === 'light' ? colors.white : theme.backgroundColor;

  return (
    <Popper
      id={id}
      open={open}
      anchorEl={anchorEl}
      transition
      disablePortal={true}
      style={{ zIndex: 20 }}
    >
      {({ TransitionProps }) => (
        <Fade {...TransitionProps} timeout={350}>
          <MenuWrapper boxShadow={boxShadow} backgroundColor={backgroundColor}>
            {children}
          </MenuWrapper>
        </Fade>
      )}
    </Popper>
  );
};

const MenuItem = props => {
  const { content, subContent, onClickItem } = props;

  return (
    <MenuItemWrapper onClick={onClickItem} lightGray={'#f3f4f6'}>
      <Text size={400}>{content}</Text>
      <Text muted size={300}>
        {subContent}
      </Text>
    </MenuItemWrapper>
  );
};

export { Menu, MenuItem };

import styled from 'styled-components';

import { SPACING } from './constants';

const ContentWrapper = styled.div`
  text-align: center;
  width: 100%;
`;

const ProvidersButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const SecondaryTextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: ${SPACING[200]};
`;

export { ContentWrapper, ProvidersButtonsWrapper, SecondaryTextWrapper };

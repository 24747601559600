import React from 'react';
import styled from 'styled-components';

import Text from './text';
import { SPACING, colors } from '../styles/constants';
import LightLogo from '../assets/images/asap-light.png';

const PartnerBadgeWrapper = styled.div`
  display: inline-flex;
  width: fit-content;
  max-height: 30px;
  background-color: ${colors.white};
  box-shadow: ${props => props.theme.boxShadow.base};
  border-radius: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${SPACING[100]};
`;

const PartnerBadgeIcon = styled.img`
  width: 18px;
  height: 14px;
  margin-right: ${SPACING[100]};
`;

const PartnerBadge = props => {
  const { theme, wrapperProps } = props;

  return (
    <PartnerBadgeWrapper theme={theme} {...wrapperProps}>
      <PartnerBadgeIcon theme={theme} src={LightLogo} lazy />
      <Text size={400} color={theme.primaryColor}>
        {'Favoris 🥇'}
      </Text>
    </PartnerBadgeWrapper>
  );
};

export default PartnerBadge;

import React from 'react';
import orderBy from 'lodash/orderBy';
import {
  LineWrapper,
  ItemImage,
  ItemContent,
  ItemHeader,
  ItemDetails,
  ItemFooter,
  InlineFlexRow,
} from '../../styles/basket';
import { Heading, Text } from '../../styleguide';
import { SPACING } from '../../styles/constants';
import { useTheme } from '../../hooks';
import ItemControls from './ItemControls';
import { parseNumber } from '../../common';

const ProductLine = props => {
  const { shopItem, onDelete, lite, withoutDescription, small } = props;
  const theme = useTheme();

  const orderedOptions = options => {
    return orderBy(options, i => i.order, 'asc');
  };

  if (lite) {
    return (
      <LineWrapper>
        <ItemImage
          imageSRC={shopItem.item.pictureURL}
          theme={theme}
          small
          w={'45px'}
          h={'35px'}
        />
        <ItemContent>
          <ItemHeader>
            <Heading size={500}>{shopItem.item.name}</Heading>
          </ItemHeader>
          <ItemFooter flexEnd={!onDelete}>
            <Text
              style={{ color: theme.headingColor, fontWeight: 500 }}
              size={400}
            >
              {parseNumber(shopItem.price).toFixed(2)}
              {' €'}
            </Text>
          </ItemFooter>
        </ItemContent>
      </LineWrapper>
    );
  }

  return (
    <LineWrapper>
      <ItemImage
        small
        imageSRC={shopItem.item.pictureURL}
        theme={theme}
        w={'45px'}
        h={'35px'}
      />
      <ItemContent>
        <ItemHeader>
          <Heading size={small ? 400 : 500}>{shopItem.item.name}</Heading>
        </ItemHeader>
        <ItemDetails>
          <Text
            muted
            size={small ? 300 : 400}
            styles={{
              margin: `0 ${SPACING[100]}`,
            }}
          >
            {withoutDescription ? '' : shopItem.item.description}
          </Text>
          {!!shopItem.options?.length &&
            orderedOptions(shopItem.options).map((opt, i) => (
              <InlineFlexRow
                key={i}
                otherStyles={`
                padding: 0 ${SPACING[100]};
              `}
              >
                <Text
                  size={300}
                  key={`${opt.name}-${i}`}
                  styles={
                    i < opt.items.length - 1
                      ? { marginRight: SPACING[100] }
                      : {}
                  }
                >
                  {opt.name} :{' '}
                  {opt.items.map((item, i) => (
                    <Text
                      is="span"
                      size={300}
                      styles={{ color: theme.headingColor, fontWeight: 'bold' }}
                      key={`${item.name}-${i}`}
                    >
                      {item.name} {i < opt.items.length - 1 && ' + '}
                    </Text>
                  ))}
                </Text>
              </InlineFlexRow>
            ))}
          {shopItem.supplements && shopItem.supplements.length ? (
            <InlineFlexRow
              otherStyles={`
                padding: 0 ${SPACING[100]};
              `}
            >
              <Text size={300}>
                Suppléments :{' '}
                {shopItem.supplements.map((supp, i) => (
                  <Text
                    key={`${supp.name}-${i}`}
                    is="span"
                    size={300}
                    styles={{ color: theme.headingColor, fontWeight: 'bold' }}
                  >
                    {supp.name} {i < shopItem.supplements.length - 1 && ' + '}
                  </Text>
                ))}
              </Text>
            </InlineFlexRow>
          ) : null}
        </ItemDetails>
        <ItemFooter flexEnd>
          {!!onDelete ? <ItemControls small onDelete={onDelete} /> : null}
          <Text
            style={{ color: theme.headingColor, fontWeight: 500 }}
            size={500}
          >
            {parseNumber(shopItem.price).toFixed(2)}
            {' €'}
          </Text>
        </ItemFooter>
      </ItemContent>
    </LineWrapper>
  );
};

export default ProductLine;

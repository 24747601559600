import colors from './colors';
import SPACING from './spacing';
import { sizes, devices } from './media';
import { BOX_SHADOW, LIGHT_BOX_SHADOW, DARK_BOX_SHADOW } from './shadow';

const THEMES_COLORS = {
  light: {
    headingColor: colors.black,
    textColor: colors.lightGrayText,
    backgroundColor: colors.white,
    primaryColor: colors.green,
    spinnerColor: colors.green,
    white: colors.white,
    black: colors.black,
    red: colors.red,
    shadow: colors.darkShadow,
    lightGray: colors.lightGray,
    boxShadow: LIGHT_BOX_SHADOW,
    cardBg: colors.white,
    footerColor: colors.lightWhite,
  },
  dark: {
    headingColor: colors.white,
    textColor: colors.lightWhite,
    backgroundColor: colors.black,
    primaryColor: colors.green,
    spinnerColor: colors.green,
    white: colors.white,
    black: colors.black,
    red: colors.red,
    shadow: colors.lightShadow,
    lightGray: colors.lightWhite,
    boxShadow: DARK_BOX_SHADOW,
    cardBg: colors.darkCardBg,
    footerColor: colors.lightWhite,
  },
};

const getMuiTheme = currentTheme => ({
  overrides: {
    MuiDrawer: {
      paperAnchorLeft: {
        background: currentTheme.backgroundColor,
      },
    },
    MuiDialog: {
      paper: {
        background: currentTheme.backgroundColor,
      },
    },
    MuiDialogTitle: {
      root: {
        color: currentTheme.headingColor,
      },
    },
    MuiDialogContentText: {
      root: {
        color: currentTheme.textColor,
      },
    },
    MuiExpansionPanelDetails: {
      root: {
        width: '100%',
        background: currentTheme.backgroundColor,
      },
    },
    MuiExpansionPanelSummary: {
      root: {
        background: currentTheme.backgroundColor,
        color: currentTheme.headingColor,
      },
    },
    MuiCheckbox: {
      root: {
        color: currentTheme.headingColor,
      },
    },
    MuiFormControlLabel: {
      root: {
        color: currentTheme.headingColor,
      },
    },
    MuiChip: {
      outlinedSecondary: {
        color: currentTheme.textColor,
        borderColor: currentTheme.textColor,
      },
    },
    MuiFormLabel: {
      root: {
        color: currentTheme.textColor,
      },
    },
    MuiDataGrid: {
      root: {
        color: currentTheme.headingColor,
      },
    },
    MuiTablePagination: {
      root: {
        color: currentTheme.textColor,
      },
    },
    MuiInputBase: {
      input: {
        color: currentTheme.headingColor,
        borderColor: currentTheme.headingColor,
      },
      notchedOutline: {
        color: currentTheme.textColor,
      },
    },
  },
  palette: {
    primary: {
      contrastText: currentTheme.white,
      main: currentTheme.primaryColor,
    },
    secondary: {
      main: currentTheme.headingColor,
    },
  },
});

const hexToRgb = hexProp => {
  // http://stackoverflow.com/a/5624139
  const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i;
  const hex = hexProp.replace(shorthandRegex, function(m, r, g, b) {
    return r + r + g + g + b + b;
  });

  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  return result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
};

const rgba = (hex, alpha) => {
  const color = hexToRgb(hex);
  if (!!color) {
    return `rgba(${color.r}, ${color.g}, ${color.b}, ${alpha})`;
  }
};

export {
  THEMES_COLORS,
  SPACING,
  colors,
  BOX_SHADOW,
  getMuiTheme,
  sizes,
  devices,
  rgba,
};

import React from 'react';
import { Text as TextUI } from 'evergreen-ui';
import { useTheme } from '../hooks';

const Text = props => {
  const { size, children, styles, centered, color, muted, ...rest } = props;
  const theme = useTheme();

  return (
    <TextUI
      size={size}
      style={{
        color: !color ? (muted ? theme.textColor : theme.headingColor) : color,
        textAlign: centered ? 'center' : 'left',
        ...styles,
      }}
      {...rest}
    >
      {children}
    </TextUI>
  );
};

export default Text;

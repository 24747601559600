import * as yup from 'yup';

import { ADDRESS } from '../fields';

const addressSchema = yup.object({
  street: ADDRESS.STREET_FIELD_VALIDATION,
  city: ADDRESS.CITY_FIELD_VALIDATION,
  details: ADDRESS.DETAILS_ADDRESS_FIELD_VALIDATION,
  postalCode: ADDRESS.POSTALCODE_FIELD_VALIDATION,
  name: ADDRESS.NAME_ADDRESS_FIELD_VALIDATION,
  isDefault: ADDRESS.DEFAULT_FIELD_VALIDATION,
  id: ADDRESS.ID_FIELD_VALIDATION,
});

export { addressSchema };

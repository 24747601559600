import React from 'react';
import uniq from 'lodash/uniq';

import { createShopItem, updateShopItem } from '../../../../actions/shopItems';
import MenuModal from './MenuModal';
import ProductModal from './ProductModal';

const ItemModal = props => {
  const {
    isOpen,
    onClose,
    kind,
    shop,
    initialValue,
    hasExistingMenu,
    categoriesData,
  } = props;

  const [categories, setCategories] = React.useState(null);

  React.useEffect(
    () =>
      !!hasExistingMenu
        ? setCategories(
            uniq(
              categoriesData
                .map(item => item.category)
                .filter(i => i !== 'Menus')
            )
          )
        : [],
    [categoriesData, hasExistingMenu]
  );

  const onSubmit = item => {
    if (initialValue) {
      return updateShopItem(item);
    }

    return createShopItem(item, shop);
  };

  return kind === 'menu' ? (
    <MenuModal
      onSubmit={onSubmit}
      onClose={onClose}
      initialValue={initialValue}
      categories={categories}
      open={isOpen}
    />
  ) : (
    <ProductModal
      onSubmit={onSubmit}
      onClose={onClose}
      initialValue={initialValue}
      open={isOpen}
    />
  );
};

export default ItemModal;

import * as yup from 'yup';

import { SHOP, LEGAL } from '../fields';

const signupShopName = yup.object({
  name: SHOP.NAME_SHOP_FIELD_VALIDATION,
  description: SHOP.DESCRIPTION_SHOP_FIELD_VALIDATION,
});

const signupShopLogo = yup.object({
  logoURL: SHOP.LOGO_SHOP_FIELD_VALIDATION,
});

const signupShopLegal = yup.object({
  email: LEGAL.EMAIL_LEGAL_FIELD_VALIDATION,
  ownerFirstname: LEGAL.FIRSTNAME_LEGAL_FIELD_VALIDATION,
  ownerLastname: LEGAL.LASTNAME_LEGAL_FIELD_VALIDATION,
  phone: LEGAL.PHONE_LEGAL_FIELD_VALIDATION,
  siret: LEGAL.SIREN_LEGAL_FIELD_VALIDATION,
  denomination: LEGAL.DENOMINATION_LEGAL_FIELD_VALIDATION,
  status: LEGAL.STATUS_LEGAL_FIELD_VALIDATION,
});

export { signupShopName, signupShopLogo, signupShopLegal };

import authenticatedRequest from '../helpers/authenticatedRequest';
import { API } from '../../common';

const computeDeliveryFees = async ({ shopAddress, clientAddress }) => {
  const response = await authenticatedRequest(
    'get',
    `${API.backend.computeDeliveryFees}?shopAddressID=${shopAddress.id}&clientAddressID=${clientAddress.id}`,
    {}
  );

  return response;
};

export default computeDeliveryFees;

import React from 'react';
import { Redirect, useLocation } from 'react-router-dom';
import Firebase from 'firebase/app';
import 'firebase/messaging';
import { toaster } from 'evergreen-ui';
import { useDocument } from 'react-firebase-hooks/firestore';
import {
  createMuiTheme,
  ThemeProvider as MaterialUiThemeProvider,
} from '@material-ui/core/styles';
import { useUnstated } from '@gitbook/unstated';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { useTranslation } from 'react-i18next';

import { logOut } from '../../actions/login';
import {
  MainWrapper,
  MainContainer,
  NotificationsContentWrapper,
  GlobalWrapper,
} from '../../styles/layout';
import { THEMES_COLORS, getMuiTheme } from '../../styles/constants';
import { ThemeContext, ThemeContainer } from '../../contexts';
import { useSession } from '../../hooks';
import { UserContext } from '../../contexts';
import { Loader, Text } from '../../styleguide';
import CookiesBanner from './CookiesBanner';

import { requestFirebaseNotificationPermission } from '../../actions/messaging';
import alertSound from '../../assets/order-notif.mp3';
import bubbleSound from '../../assets/bubble-notif.mp3';
import Footer from './Footer';

const { PUBLIC_URL } = process.env;

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);

const LoggedInLayout = ({ children }) => {
  const [notification, setNotification] = React.useState(null);
  const [hasPermission, setPermission] = React.useState(false);
  const themeContainer = useUnstated(ThemeContainer);
  const { isLoggedIn, authUser } = useSession();
  const { i18n } = useTranslation();

  const isVerified = authUser.emailVerified;

  const notif = React.useMemo(() => {
    return new Audio(alertSound);
  }, []);

  const bubble = React.useMemo(() => {
    return new Audio(bubbleSound);
  }, []);

  let location = useLocation();

  const playNotif = React.useCallback(() => {
    if (!!notif) {
      notif.volume = 1;
      return notif.play();
    }
  }, [notif]);

  const playBubble = React.useCallback(() => {
    if (!!bubble) {
      bubble.volume = 1;
      return bubble.play();
    }
  }, [bubble]);

  const onNewNotification = React.useCallback(
    payload => {
      const isNewOrderNotification =
        !!payload?.data?.title &&
        payload?.data?.title.includes('Nouvelle commande');

      setNotification({
        title: payload.data.title,
        body: payload.data.body,
      });

      if (!!isNewOrderNotification) {
        playNotif();
      } else {
        playBubble();
      }
    },
    [playNotif, playBubble]
  );

  const onCloseNotification = () => {
    const openedToasts = toaster.getToasts();
    const targetToast = openedToasts.filter(
      t => t.title === notification.title
    );
    if (targetToast.length) {
      targetToast[0].close();
    }
    setNotification(null);
  };

  const [value, loading, error] = useDocument(
    Firebase.firestore().doc(`users/${authUser.uid}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  // We need to ask user permissions for notifications
  React.useEffect(() => {
    async function checkToken() {
      // console.log('checkToken');
      await requestFirebaseNotificationPermission(authUser.uid, setPermission);
    }

    if (!hasPermission && isVerified) {
      checkToken();
    }
  }, [hasPermission, authUser, isVerified]);

  React.useEffect(() => {
    if (hasPermission && isVerified) {
      const messaging = Firebase.messaging();

      return messaging.onMessage(payload => {
        onNewNotification(payload);
      });
    }

    return async () => {
      if (hasPermission && isVerified) {
        // console.log({ hasPermission });
        const registration = await navigator.serviceWorker.getRegistration(
          PUBLIC_URL
        );
        // console.log({ registration });
        if (registration) {
          return registration.unregister();
        }
      }
    };
  }, [hasPermission, isVerified, onNewNotification]);

  if (error) {
    console.error("Erreur lors de la recuperation de l'utilisateur", {
      message: error.message,
    });
    return logOut(authUser);
  }

  if (loading) {
    return <Loader />;
  }

  if (
    isLoggedIn &&
    !error &&
    !loading &&
    !value.exists &&
    location.pathname !== '/signup' &&
    location.pathname !== '/activation'
  ) {
    return <Redirect to={{ pathname: '/signup', state: { from: location } }} />;
  }

  const user = value.data();

  if (user && user.settings.theme !== themeContainer.state.theme) {
    themeContainer.updateTheme(user.settings.theme);
  }

  if (!user && !themeContainer.state.theme) {
    themeContainer.updateTheme('light');
  }

  // lng is new, coming with i18n. Else some user will have their app in fr instead of auto detect value
  if (!!user?.settings?.lng && i18n.language !== user?.settings?.lng) {
    i18n.changeLanguage(user.settings.lng);
  }

  const currentTheme = THEMES_COLORS[themeContainer.state.theme];
  const muiThemeTemplate = getMuiTheme(currentTheme);
  const muiTheme = createMuiTheme(muiThemeTemplate);

  if (!!notification && !!hasPermission && user) {
    const toasterOptions = {
      isShown: !!notification,
      onRemove: onCloseNotification(),
      description: (
        <NotificationsContentWrapper onClick={onCloseNotification}>
          <Text size={400}>{notification.body}</Text>
        </NotificationsContentWrapper>
      ),
    };
    toaster.notify(notification.title, toasterOptions);
  }
  // console.log('render LoggedInLayout', {
  //   authUser,
  //   isLoggedIn,
  //   user: user || 'pas de value poto',
  //   notification,
  //   isVerified,
  // });

  return (
    <UserContext.Provider value={{ currentUser: { ...user } }}>
      <ThemeContext.Provider value={currentTheme}>
        <MaterialUiThemeProvider theme={muiTheme}>
          <Elements stripe={stripePromise}>
            <GlobalWrapper theme={currentTheme}>
              <MainWrapper theme={currentTheme} pBottomLg id="main-wrapper">
                <MainContainer>{children}</MainContainer>
              </MainWrapper>
              <Footer theme={currentTheme} />
              <CookiesBanner />
            </GlobalWrapper>
          </Elements>
        </MaterialUiThemeProvider>
      </ThemeContext.Provider>
    </UserContext.Provider>
  );
};

export default LoggedInLayout;

import React from 'react';
import styled from 'styled-components';
import { ImpulseSpinner } from 'react-spinners-kit';
import { useTranslation } from 'react-i18next';

import { SPACING, colors } from '../../styles/constants';
import { useTheme } from '../../hooks';
import { AddressDetailsWrapper } from '../../styles/newOrder';
import { BlockWrapper } from '../../styles/common';
import { CardIcon } from '../../styleguide/icons';
import { Button, SimpleHoverableAction, Text } from '../../styleguide';
import PaymentMethodModal from './PaymentMethodModal';
import { PMLoader } from '../../styles/newOrder';
import OrderDiscountModal from './OrderDiscountModal';

const EmptyBlockWrapper = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const PaymentMethod = props => {
  const {
    paymentsMethodsList,
    setSelectedPaymentMethod,
    selectedPaymentMethod,
    isLoading,
    lite,
    discount,
    shop,
  } = props;
  const [isPMOpen, setPMOpen] = React.useState(false);
  const [isDisOpen, setDisOpen] = React.useState(false);
  const { t } = useTranslation(['common', 'checkout']);

  const theme = useTheme();

  const onPmBlockClick = () => {
    if (!isLoading) {
      setPMOpen(true);
    }
  };

  const onDisBlockClick = () => {
    if (!isLoading) {
      setDisOpen(true);
    }
  };

  React.useEffect(() => {
    if (!!paymentsMethodsList?.length && !selectedPaymentMethod) {
      setSelectedPaymentMethod(paymentsMethodsList[0]);
    }
  }, [paymentsMethodsList, selectedPaymentMethod, setSelectedPaymentMethod]);

  if (lite) {
    return (
      <BlockWrapper theme={theme}>
        <CardIcon
          margin={`0 ${SPACING[200]} 0 ${SPACING[100]}`}
          size="24px"
          color={colors.green}
        />
        <AddressDetailsWrapper>
          <Text muted size={300} styles={{ display: 'inline-flex' }}>
            {selectedPaymentMethod.billing_details.name}
          </Text>
          <Text
            size={400}
            style={{ color: theme.headingColor, marginTop: SPACING[100] }}
          >
            {`•••• •••• •••• ${selectedPaymentMethod.card.last4} - ${selectedPaymentMethod.card.exp_month}/${selectedPaymentMethod.card.exp_year}`}
          </Text>
        </AddressDetailsWrapper>
      </BlockWrapper>
    );
  }

  return (
    <>
      <BlockWrapper
        margin={`${SPACING[100]} ${SPACING[100]} ${SPACING[200]} ${SPACING[100]}`}
        onClick={onPmBlockClick}
        theme={theme}
        transparent={!selectedPaymentMethod && !isLoading}
      >
        {isLoading ? (
          <EmptyBlockWrapper>
            <PMLoader theme={theme}>
              <ImpulseSpinner size={34} color={theme.primaryColor} loading />
            </PMLoader>
            <Text
              size={500}
              style={{
                color: theme.primaryColor,
                padding: `${SPACING[100]} 0`,
              }}
            >
              {t('checkout:loading')}
            </Text>
          </EmptyBlockWrapper>
        ) : !!selectedPaymentMethod ? (
          <>
            <CardIcon
              margin={`0 ${SPACING[200]} 0 ${SPACING[100]}`}
              size="24px"
              color={colors.green}
            />
            <AddressDetailsWrapper>
              <Text muted size={300} styles={{ display: 'inline-flex' }}>
                {selectedPaymentMethod.billing_details.name}
              </Text>
              <Text
                size={400}
                style={{ color: theme.headingColor, marginTop: SPACING[100] }}
              >
                {`•••• •••• •••• ${selectedPaymentMethod.card.last4} - ${selectedPaymentMethod.card.exp_month}/${selectedPaymentMethod.card.exp_year}`}
              </Text>
            </AddressDetailsWrapper>
          </>
        ) : (
          <Button primary styles={{ margin: `${SPACING[200]} auto` }} large>
            {t('checkout:payment_method:choose_title')}
          </Button>
        )}
      </BlockWrapper>

      {!discount && (
        <SimpleHoverableAction
          underline
          onClick={onDisBlockClick}
          size={500}
          wrapperStyles={{
            paddingTop: SPACING[200],
            marginLeft: SPACING[100],
          }}
        >
          {t('checkout:payment_method:add_discount')}
        </SimpleHoverableAction>
      )}

      <OrderDiscountModal
        isOpen={isDisOpen}
        onClose={() => setDisOpen(false)}
        shop={shop}
      />

      <PaymentMethodModal
        isOpen={isPMOpen}
        onClose={() => setPMOpen(false)}
        paymentMethodsList={paymentsMethodsList}
        setPaymentMethod={setSelectedPaymentMethod}
        selectedPaymentMethod={selectedPaymentMethod}
      />
    </>
  );
};

export default PaymentMethod;

import React from 'react';
import { Switch, Avatar } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import Button from '../button';
import { SPACING } from '../../styles/constants';
import { Text } from '../../styleguide';
import { useTheme } from '../../hooks';
import { LineWrapper, TextWrapper, BannerField } from '../../styles/account';

const LineProperty = props => {
  const {
    value,
    label,
    onClick,
    buttonContent,
    onSwitch,
    contentValue,
    isChecked,
    styles,
    isLogo,
    isBanner,
    actions,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation(['common']);

  return (
    <LineWrapper id="test" style={styles || {}} padding={isBanner ? '0' : null}>
      <TextWrapper>
        <Text muted size={400} styles={{ display: 'inline-flex' }}>
          {label}
        </Text>
        {isBanner ? (
          <BannerField src={value} theme={theme} lazy>
            <Button small onClick={onClick}>
              {buttonContent || 'Changer'}
            </Button>
          </BannerField>
        ) : isLogo ? (
          <Avatar src={value} style={{ width: 50, height: 50, marginTop: 8 }} />
        ) : (
          <Text
            size={500}
            style={{
              color: theme.headingColor,
              marginTop: SPACING[100],
              whiteSpace: 'pre-line',
            }}
          >
            {contentValue || value}
          </Text>
        )}
      </TextWrapper>
      {isBanner ? null : onSwitch ? (
        <Switch
          checked={isChecked}
          onChange={onSwitch}
          color="primary"
          name={`${label}`}
          inputProps={{ 'aria-label': 'primary checkbox' }}
        />
      ) : (
        actions || (
          <Button minimal small primary onClick={onClick}>
            {buttonContent || t('common:edit')}
          </Button>
        )
      )}
    </LineWrapper>
  );
};

export default LineProperty;

import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import { Text } from '../styleguide';
import { SPACING } from '../styles/constants';
import { useTheme } from '../hooks';

const Card = styled(Link)`
  width: 106px;
  height: 80px;
  padding: ${SPACING[100]} ${SPACING[100]};
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  border: 1px solid ${props => props.theme.lightGray};
  gap: ${SPACING[100]};

  &:hover {
    box-shadow: ${props => props.theme.boxShadow.md};
  }

  & > svg {
    fill: ${props => props.theme.primaryColor};
    width: 26px;
    height: 26px;
  }
`;

const ClickableCard = props => {
  const { icon, to, label } = props;
  const theme = useTheme();

  return (
    <Card to={to} theme={theme}>
      {icon}

      <Text
        styles={{
          textAlign: 'center',
        }}
        size={300}
      >
        {label}
      </Text>
    </Card>
  );
};

export default ClickableCard;

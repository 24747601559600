import React from 'react';

import { EmptyPage } from '../../../common';
import { TabContentWrapper, EmptyTabWrapper } from '../../../../styles/shop';
import { RowsContainer, RowWrapper } from '../../../../styles/shoptabs';
import { Heading } from '../../../../styleguide';
import { useTheme } from '../../../../hooks';
import TabPanel from '../TabPanel';
import HoursRow from './HoursRow';
import StripeRow from './StripeRow';

const OverviewTab = props => {
  const { value, shop } = props;

  const theme = useTheme();
  const orders = null;

  return (
    <TabPanel value={value} index={1}>
      <TabContentWrapper>
        <RowsContainer>
          <HoursRow shop={shop} theme={theme} />
          <StripeRow shop={shop} />
          <RowWrapper theme={theme}>
            <Heading size={800}>Commandes</Heading>

            {!orders ? (
              <EmptyTabWrapper>
                <EmptyPage
                  titleSize={800}
                  withoutButton
                  title="Les statistiques seront bientôt disponibles"
                  description="Les statistiques ne sont pas encore disponibles, mais pas d'inquiétude, cela ne va pas tarder ⏱"
                />
              </EmptyTabWrapper>
            ) : null}
          </RowWrapper>
        </RowsContainer>
      </TabContentWrapper>
    </TabPanel>
  );
};

export default OverviewTab;

import React from 'react';
import { InputAdornment, IconButton, Chip, Divider } from '@material-ui/core';

import {
  FlexFieldsWrapper,
  OptionsItemsListWrapper,
  OptionsItemWrapper,
  OptionsItemsListItem,
  OptionsItemsList,
} from '../../../../../styles/shop';
import { Input, Heading, Text } from '../../../../../styleguide';
import { useTheme } from '../../../../../hooks';
import { parseNumber } from '../../../../../common';
import { SPACING } from '../../../../../styles/constants';
import {
  EuroIcon,
  AvailabilityIcon,
  CheckIcon,
  EditIcon,
  DeleteIcon,
} from '../../../../../styleguide/icons';

const OptionsItemForm = props => {
  const {
    onChangeItem,
    items,
    currentOptionItem,
    onConfirm,
    onEditItem,
    onDeleteItem,
  } = props;
  const theme = useTheme();

  const canSubmitItem =
    !!currentOptionItem &&
    !!currentOptionItem.name &&
    currentOptionItem.price >= 0;

  return (
    <OptionsItemsListWrapper>
      <FlexFieldsWrapper align="center">
        <Heading size={600}>Items</Heading>
      </FlexFieldsWrapper>
      <Divider
        style={{
          width: '100%',
          margin: `${SPACING[100]} 0`,
        }}
      />
      <OptionsItemsList>
        {!!items?.length ? (
          items.map(item => (
            <OptionsItemsListItem key={item.id}>
              <FlexFieldsWrapper>
                <Text size={500}>{`- ${item.name}`}</Text>
                <AvailabilityIcon
                  size="20px"
                  margin={`0 ${SPACING[200]}`}
                  color={!!item.avaibility ? theme.primaryColor : theme.red}
                />
                <Chip
                  size="small"
                  label={`${parseNumber(item.price).toFixed(2)}€`}
                />
              </FlexFieldsWrapper>
              <FlexFieldsWrapper justify="end">
                <IconButton minimal onClick={() => onEditItem(item)}>
                  <EditIcon size="20px" />
                </IconButton>
                <IconButton minimal onClick={() => onDeleteItem(item)}>
                  <DeleteIcon size="20px" />
                </IconButton>
              </FlexFieldsWrapper>
            </OptionsItemsListItem>
          ))
        ) : (
          <FlexFieldsWrapper padding={'0px'} align="center" justify="center">
            <Text size={500} muted>
              Aucun item pour le moment
            </Text>
          </FlexFieldsWrapper>
        )}
      </OptionsItemsList>
      <Divider
        style={{
          width: '100%',
          margin: `${SPACING[100]} 0`,
          marginBottom: SPACING[200],
        }}
      />
      <OptionsItemWrapper>
        <FlexFieldsWrapper align="center">
          <Input
            wrapperProps={{
              justify: 'flex-start',
              maxWidth: 'fit-content',
            }}
            label="Nom"
            size="small"
            variant="outlined"
            required
            placeholder="Poulet"
            fullWidth
            style={{ minWidth: '120px' }}
            value={currentOptionItem.name}
            onChange={event => onChangeItem('name', event.target.value)}
          />
          <Input
            wrapperProps={{
              justify: 'flex-start',
              maxWidth: 'fit-content',
            }}
            label="Prix TTC"
            size="small"
            variant="outlined"
            placeholder="2.50"
            fullWidth
            type="number"
            style={{ minWidth: '70px' }}
            value={currentOptionItem.price}
            InputProps={{
              min: 0,
              startAdornment: (
                <InputAdornment position="start">
                  <EuroIcon size="16px" />
                </InputAdornment>
              ),
            }}
            onChange={event => onChangeItem('price', event.target.value)}
          />
          <IconButton
            minimal
            onClick={() =>
              onChangeItem('avaibility', !currentOptionItem.avaibility)
            }
          >
            <AvailabilityIcon
              size="20px"
              color={
                !!currentOptionItem.avaibility ? theme.primaryColor : theme.red
              }
            />
          </IconButton>
          <IconButton disabled={!canSubmitItem} minimal onClick={onConfirm}>
            <CheckIcon
              size="20px"
              color={!canSubmitItem ? theme.textColor : theme.primaryColor}
            />
          </IconButton>
        </FlexFieldsWrapper>
      </OptionsItemWrapper>
    </OptionsItemsListWrapper>
  );
};

export default OptionsItemForm;

import React from 'react';
import { Chip, Divider } from '@material-ui/core';
import { subtract, add } from 'lodash';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../../../hooks';
import { Heading, CheckBox, Text } from '../../../../styleguide';
import { SPACING, colors } from '../../../../styles/constants';
import { ShopProfilDialogSupplementsWrapper } from '../../../../styles/shop-profil';
import { FlexFieldsWrapper } from '../../../../styles/shop';
import { parseNumber } from '../../../../common';

import { ShopProfilDialogSupplementLine } from '../../../../styles/shop-profil';

const OptionsDialogContent = props => {
  const {
    option,
    selectedItem,
    setSelectedItem,
    onOptionsUpdate,
    ...rest
  } = props;
  const theme = useTheme();
  const { t } = useTranslation(['common', 'shop', 'categories']);

  const onOptionSelection = (o, isAdding, parentID) => {
    if (!!onOptionsUpdate) {
      onOptionsUpdate(o, isAdding, parentID);
      return;
    }

    if (!isAdding) {
      setSelectedItem({
        ...selectedItem,
        item: selectedItem.item,
        options: selectedItem.options.map(opt => {
          if (opt.id === option.id) {
            const items = opt.items.filter(i => i.id !== o.id);
            return {
              ...opt,
              items,
              max: items.length >= opt.count,
            };
          }
          return opt;
        }),
        ...(!!option.paying
          ? {
              total: parseNumber(
                subtract(parseNumber(selectedItem.total), parseNumber(o.price))
              ),
            }
          : {
              total: parseNumber(selectedItem.total),
            }),
      });
    } else {
      setSelectedItem({
        ...selectedItem,
        item: selectedItem.item,
        options: selectedItem.options.map(opt => {
          if (opt.id === option.id) {
            const items = [...opt.items, o];
            return {
              ...opt,
              items,
              max: items.length >= opt.count,
            };
          }
          return opt;
        }),
        ...(!!option.paying
          ? {
              total: parseNumber(
                add(parseNumber(selectedItem.total), parseNumber(o.price))
              ),
            }
          : {
              total: parseNumber(selectedItem.total),
            }),
      });
    }
  };

  const isCurrentlySelected = item => {
    const items = selectedItem.options?.find(o => o.id === option.id)?.items;

    return !!items?.includes(item);
  };

  const itemIsDisabled = item => {
    if (!item.avaibility) {
      return true;
    }

    if (isCurrentlySelected(item)) {
      return false;
    }

    return !!selectedItem.options?.find(o => o.id === option.id)?.max;
  };

  const getSelectedOptionItemsCount = option => {
    const items = selectedItem.options?.find(o => o.id === option.id)?.items;
    return items?.length || 0;
  };

  const selectedOptionItemsCount = getSelectedOptionItemsCount(option);

  return (
    <ShopProfilDialogSupplementsWrapper key={option.id}>
      <Divider
        style={{
          width: '100%',
          margin: `${SPACING[200]} 0`,
        }}
      />
      <FlexFieldsWrapper
        align="center"
        style={{ marginBottom: SPACING[100] }}
        padding={`${SPACING[100]} 4px`}
      >
        <Heading size={500} styles={{ marginRight: SPACING[100] }}>
          {option.name}
        </Heading>
        {!!option.required ? (
          <Chip
            variant="outlined"
            size="small"
            label={
              <Text size={300}>
                <span
                  style={{
                    fontWeight: 'bold',
                    color:
                      selectedOptionItemsCount < option.count
                        ? colors.red
                        : colors.green,
                  }}
                >
                  {`${selectedOptionItemsCount} / ${option.count}`}
                </span>{' '}
                {t('shop:required')}
              </Text>
            }
          />
        ) : (
          <>
            <Chip size="small" label={t('shop:optionnal')} />
            {option.count > 1 && (
              <Chip
                variant="outlined"
                size="small"
                label={
                  <Text size={300}>
                    <span
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {`${selectedOptionItemsCount} / ${option.count}`}
                    </span>{' '}
                    Max
                  </Text>
                }
                style={{ marginLeft: SPACING[100] }}
              />
            )}
          </>
        )}
      </FlexFieldsWrapper>
      {option.items.map(item => (
        <ShopProfilDialogSupplementLine key={item.id} {...rest}>
          <CheckBox
            checked={isCurrentlySelected(item)}
            onChange={isChecked =>
              onOptionSelection(item, isChecked, option.id)
            }
            name={item.name}
            wrapperProps={{
              justify: 'flex-start',
              align: 'center',
              padding: `${SPACING[100]} ${SPACING[100]} 0 ${SPACING[100]}`,
            }}
            disabled={itemIsDisabled(item)}
            label={
              <Text
                size={400}
                color={!item.avaibility ? theme.lightGray : theme.headingColor}
              >
                {item.name}
                {!item.avaibility && ' (indisponible)'}
              </Text>
            }
          />
          {!!option.paying && item.price > 0 ? (
            <Chip
              size="small"
              label={`${parseNumber(item.price).toFixed(2)}€`}
            />
          ) : null}
        </ShopProfilDialogSupplementLine>
      ))}
    </ShopProfilDialogSupplementsWrapper>
  );
};

export default OptionsDialogContent;

import React from 'react';
import { toaster } from 'evergreen-ui';

import ORDER_STATUS from '../../../actions/orders/helpers/STATUS';
import updateOrder from '../../../actions/orders/updateOrder';
import { Heading } from '../../../styleguide';
import { TabContentWrapper } from '../../../styles/shop';
import {
  OrdersListWrapper,
  EmptyNewOrders,
} from '../../../styles/orders-cards';
import TabPanel from '../../shop/tabs/TabPanel';
import { AttributedCard } from './Cards';
import EmptyTab from './EmptyTab';
import ReadyOrderDialog from './dialogs/ReadyOrderDialog';

const InProgressTab = props => {
  const { value, orders } = props;
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState(null);

  const hasExistingOrders = !!orders.length;

  const onConfirm = async order => {
    try {
      const orderToUpdate = orders.find(o => o.id === order.id);

      if (!orderToUpdate || !!orderToUpdate?.inTreatment) {
        setConfirmModal(null);
        toaster.warning(
          `⏳ Impossible commande en cours de traitement, réessayez plus tard`
        );
        return;
      }

      await updateOrder(orderToUpdate, {
        status: ORDER_STATUS.inDelivery,
        pickedAt: Date.now(),
      });
      toaster.success(`Départ commande 🚀`, {
        description: `La commande numéro ${orderToUpdate.id} est desormais en livraison`,
      });
      setConfirmModal(null);
    } catch (error) {
      setConfirmModal(null);

      toaster.danger(`Une erreur est survenue`, {
        description: `Une erreur est survenue avec la commande ${order.id}, contactez notre support`,
      });
    }
  };

  return (
    <TabPanel value={value} index={1}>
      <TabContentWrapper>
        {hasExistingOrders ? (
          <>
            <Heading size={800}>Les commandes attribués</Heading>

            <OrdersListWrapper>
              {orders.map(order => {
                return (
                  <AttributedCard
                    key={order.id}
                    order={order}
                    onClickConfirm={() => {
                      setConfirmModal(true);
                      setSelectedOrder(order);
                    }}
                  />
                );
              })}
            </OrdersListWrapper>

            {confirmModal ? (
              <ReadyOrderDialog
                isOpen={confirmModal}
                onClose={() => {
                  setConfirmModal(null);
                  setSelectedOrder(null);
                }}
                onConfirm={() => onConfirm(selectedOrder)}
                order={selectedOrder}
              />
            ) : null}
          </>
        ) : (
          <EmptyNewOrders>
            <EmptyTab
              title={'Pas de nouvelle attribué'}
              description={
                'Vous retrouverez ici vos commandes attribué à récupérer 📦'
              }
            />
          </EmptyNewOrders>
        )}
      </TabContentWrapper>
    </TabPanel>
  );
};

export default InProgressTab;

import React from 'react';
import { subtract } from 'lodash';
import { DataGrid } from '@material-ui/data-grid';
import { DateTime } from 'luxon';

import { BlockDataGridWrapper } from '../../../styles/common';
import InvoiceDialog from './InvoiceDialog';
import { parseNumber, INVOICES_STATUS } from '../../../common';

const columns = [
  {
    field: 'number',
    headerName: 'N',
    width: 75,
  },
  {
    field: 'generatedAt',
    headerName: 'Crée le',
    width: 250,
  },
  {
    field: 'total',
    headerName: 'Total HT',
    width: 150,
    align: 'center',
    valueFormatter: ({ value }) => `${parseNumber(value).toFixed(2)}€`,
  },
  {
    field: 'net',
    headerName: 'Total Net',
    width: 150,
    align: 'center',
    valueFormatter: ({ value }) => `${parseNumber(value).toFixed(2)}€`,
  },
  {
    field: 'status',
    headerName: 'Status',
    align: 'center',
    width: 200,
    valueFormatter: ({ value }) => INVOICES_STATUS[value],
  },
  {
    field: 'from',
    headerName: 'A partir du',
    width: 200,
  },
  {
    field: 'to',
    headerName: "Jusqu'au",
    width: 200,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 250,
  },
];

const InvoicesListTable = props => {
  const { orders, invoices, rusher } = props;
  const [openedInvoice, setOpenedInvoice] = React.useState(null);

  const onRowClick = data => {
    setOpenedInvoice(data.row);
  };

  const rows = invoices.map(invoice => ({
    number: invoice.number,
    id: invoice.id,
    generatedAt: DateTime.fromMillis(invoice.generatedAt).toLocaleString(
      DateTime.DATETIME_MED
    ),
    orders: invoice.orders,
    total: invoice.total,
    net: subtract(invoice.total, invoice.commission),
    from: DateTime.fromMillis(invoice.period.from).toLocaleString(
      DateTime.DATE_MED_WITH_WEEKDAY
    ),
    to: DateTime.fromMillis(invoice.period.to).toLocaleString(
      DateTime.DATE_MED_WITH_WEEKDAY
    ),
    status: invoice.status,
    commission: invoice.commission,
  }));

  return (
    <BlockDataGridWrapper>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={15}
        stickyHeader
        onRowClick={onRowClick}
      />

      {openedInvoice ? (
        <InvoiceDialog
          withoutButton
          isOpen={!!openedInvoice}
          invoice={openedInvoice}
          invoices={invoices}
          onClose={() => setOpenedInvoice(null)}
          orders={orders}
          rusher={rusher}
        />
      ) : null}
    </BlockDataGridWrapper>
  );
};

export default InvoicesListTable;

import React from 'react';
import orderBy from 'lodash/orderBy';
import {
  LineWrapper,
  ItemImage,
  ItemContent,
  ItemHeader,
  ItemDetails,
  ItemFooter,
  MenuDetails,
  MenuDetailsItem,
  InlineFlexRow,
} from '../../styles/basket';
import { Heading, Text } from '../../styleguide';
import { useTheme } from '../../hooks';
import ItemControls from './ItemControls';
import { SPACING } from '../../styles/constants';
import { parseNumber } from '../../common';

const MenuLine = props => {
  const { shopItem, onDelete, lite, withoutDescription, small } = props;
  const theme = useTheme();

  const orderedOptions = options => {
    return orderBy(options, i => i.order, 'asc');
  };

  if (lite) {
    return (
      <LineWrapper>
        <ItemImage
          imageSRC={shopItem.item.pictureURL}
          theme={theme}
          small
          w={'45px'}
          h={'35px'}
        />
        <ItemContent>
          <ItemHeader>
            <Heading size={small ? 400 : 500}>{shopItem.item.name}</Heading>
          </ItemHeader>
          <MenuDetails withoutPadding={lite}>
            {shopItem.products.map((product, index) => (
              <MenuDetailsItem key={index}>
                <Text
                  size={small ? 300 : 400}
                  style={{ margin: '2px 0', color: theme.headingColor }}
                >
                  - {product.item.name}
                </Text>
              </MenuDetailsItem>
            ))}
          </MenuDetails>
          <ItemFooter flexEnd>
            <Text
              style={{ color: theme.textColor, fontWeight: 500 }}
              size={small ? 300 : 400}
            >
              {parseNumber(shopItem.price).toFixed(2)}
              {' €'}
            </Text>
          </ItemFooter>
        </ItemContent>
      </LineWrapper>
    );
  }

  return (
    <LineWrapper>
      <ItemImage
        small
        imageSRC={shopItem.item.pictureURL}
        theme={theme}
        w={'45px'}
        h={'35px'}
      />
      <ItemContent>
        <ItemHeader>
          <Heading size={small ? 400 : 500}>{shopItem.item.name}</Heading>
        </ItemHeader>
        {!withoutDescription && (
          <ItemDetails>
            <Text muted size={small ? 300 : 400}>
              {shopItem.item.description}
            </Text>
          </ItemDetails>
        )}
        <MenuDetails withoutPadding>
          {shopItem.products.map((product, index) => (
            <MenuDetailsItem key={index}>
              <Text
                size={300}
                styles={{
                  fontWeight: 500,
                  marginTop: SPACING[100],
                  textDecoration: 'underline',
                }}
                color={theme.headingColor}
              >
                {product.item.name}
              </Text>
              {!!product.options?.length &&
                orderedOptions(product.options).map((opt, i) => (
                  <InlineFlexRow
                    otherStyles={`
                      padding: 0 ${SPACING[100]};
                    `}
                  >
                    <Text
                      size={300}
                      key={`${opt.name}-${i}`}
                      styles={
                        i < opt.items.length - 1
                          ? { marginRight: SPACING[100] }
                          : {}
                      }
                    >
                      {opt.name} :{' '}
                      {opt.items.map((item, i) => (
                        <Text
                          is="span"
                          size={300}
                          styles={{
                            color: theme.headingColor,
                            fontWeight: 'bold',
                          }}
                          key={`${item.name}-${i}`}
                        >
                          {item.name} {i < opt.items.length - 1 && ' + '}
                        </Text>
                      ))}
                    </Text>
                  </InlineFlexRow>
                ))}
              {!!product.supplements?.length && (
                <InlineFlexRow
                  otherStyles={`
                  padding: 0 ${SPACING[100]};
                `}
                >
                  <Text size={300}>
                    Suppléments :{' '}
                    {product.supplements.map((supp, i) => (
                      <Text
                        is="span"
                        size={300}
                        styles={{
                          color: theme.headingColor,
                          fontWeight: 'bold',
                        }}
                      >
                        {supp.name}{' '}
                        {i < product.supplements.length - 1 && ' + '}
                      </Text>
                    ))}
                  </Text>
                </InlineFlexRow>
              )}
            </MenuDetailsItem>
          ))}
        </MenuDetails>
        <ItemFooter flexEnd>
          {!!onDelete ? <ItemControls small onDelete={onDelete} /> : null}

          <Text
            style={{ color: theme.headingColor, fontWeight: 500 }}
            size={500}
          >
            {parseNumber(shopItem.price).toFixed(2)}
            {' €'}
          </Text>
        </ItemFooter>
      </ItemContent>
    </LineWrapper>
  );
};

export default MenuLine;

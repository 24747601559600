import styled from 'styled-components';
import { SPACING } from './constants';

const MainWrapper = styled.div`
  max-width: 700px;
  width: 100%;
  display: flex;
  justify-content: ${props => (props.centered ? 'center' : 'flex-start')};
  align-items: ${props => (props.centered ? 'center' : 'flex-start')};
  flex-direction: column;
  margin: 0 auto;
  padding: ${props => (props.padding ? `0 ${SPACING[100]}` : 'initial')};
`;

const ContentWrapper = styled.div`
  width: 100%;
  padding: ${SPACING[300]} 0;
`;

const DefaultAddressFlag = styled.div`
  color: ${props => props.color};
  margin-left: 4px;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: ${SPACING[400]};
`;

export { MainWrapper, ContentWrapper, DefaultAddressFlag, ButtonsWrapper };

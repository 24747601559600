import React from 'react';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { Chip } from '@material-ui/core';

import {
  MainShopHeaderWrapper,
  OrdersActionsWrapper,
} from '../../../styles/shop';
import { Button, Heading } from '../../../styleguide';
import { useSession, useTheme } from '../../../hooks';

import { SPACING } from '../../../styles/constants';
import { OrdersIcon } from '../../../styleguide/icons';

const OrdersHeader = props => {
  const { settings } = props;
  const theme = useTheme();
  const { user } = useSession();
  const history = useHistory();

  return (
    <>
      <MainShopHeaderWrapper
        background={theme.backgroundColor}
        justify={'center'}
      >
        <Heading
          size={800}
          styles={{
            color: theme.headingColor,
            marginRight: SPACING[100],
            background: theme.backgroundColor,
            textShadow:
              user.settings.theme !== 'light'
                ? `2px 2px 2px ${theme.black}`
                : 'none',
          }}
          centered
        >
          Les commandes
        </Heading>
      </MainShopHeaderWrapper>
      <OrdersActionsWrapper align={'space-between'}>
        <Button
          small
          primary
          onClick={() => history.push(`/admin/dashboard`)}
          startIcon={<OrdersIcon color={theme.white} size="16px" />}
          styles={{ marginRight: '8px' }}
        >
          Dashboard
        </Button>

        <Chip
          label={`${settings.open ? 'Ouvert' : 'Fermé'}`}
          color={settings.open ? 'primary' : 'secondary'}
          variant={'default'}
        />
      </OrdersActionsWrapper>
    </>
  );
};

export default OrdersHeader;

import * as yup from 'yup';

import { PRODUCT } from '../fields';

const itemSchema = yup.object({
  name: PRODUCT.TITLE_FIELD_VALIDATION,
  price: PRODUCT.PRICE_FIELD_VALIDATION,
  avaibility: PRODUCT.AVAILABLE_FIELD_VALIDATION,
  category: PRODUCT.CATEGORIES_FIELD_VALIDATION,
  pictureURL: PRODUCT.PICTURE_FIELD_VALIDATION,
  preparationTime: PRODUCT.PREPARATION_TIME_FIELD_VALIDATION,
  description: PRODUCT.DESCRIPTION_FIELD_VALIDATION,
  VAT: PRODUCT.VAT_FIELD_VALIDATION,
  isMenu: PRODUCT.MENU_FIELD_VALIDATION,
  supplements: PRODUCT.SUPPLEMENTS_FIELD_VALIDATION,
  options: PRODUCT.SUPPLEMENTS_FIELD_VALIDATION,
});

const menuSchema = yup.object({
  name: PRODUCT.TITLE_FIELD_VALIDATION,
  price: PRODUCT.PRICE_FIELD_VALIDATION,
  avaibility: PRODUCT.AVAILABLE_FIELD_VALIDATION,
  category: PRODUCT.CATEGORIES_FIELD_VALIDATION,
  pictureURL: PRODUCT.PICTURE_FIELD_VALIDATION,
  preparationTime: PRODUCT.PREPARATION_TIME_FIELD_VALIDATION,
  isMenu: PRODUCT.MENU_FIELD_VALIDATION,
  description: PRODUCT.DESCRIPTION_FIELD_VALIDATION,
  categories: yup.array(),
  VAT: PRODUCT.VAT_FIELD_VALIDATION,
  options: PRODUCT.SUPPLEMENTS_FIELD_VALIDATION,
});

export { menuSchema, itemSchema };

import React from 'react';
import { Chip } from '@material-ui/core';
import { DateTime } from 'luxon';
import { DataGrid } from '@material-ui/data-grid';
import Firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { CSVLink } from 'react-csv';

import { parseNumber } from '../../../../common';
import getReadableStatus from '../../../../actions/orders/helpers/getReadableStatus';
import {
  HeaderDialog,
  BodyBlocksDialog,
  ContentBlockDialog,
  HeaderDialogBlock,
  HeaderRow,
} from '../../../../styles/admin';
import {
  Text,
  Heading,
  FullscreenDialog,
  Button,
} from '../../../../styleguide';
import { SPACING } from '../../../../styles/constants';
import { useTheme } from '../../../../hooks';
import percentage from '../../../../actions/orders/helpers/percentage';
import { getPercentageWithVAT } from '../../../../actions/orders/helpers/commissionHelpers';

const csvFileHeaders = [
  {
    key: 'id',
    label: 'Numéro de commande',
  },
  {
    key: 'orderAt',
    label: 'Crée à',
  },
  {
    key: 'name',
    label: 'Nom client',
  },
  {
    key: 'status',
    label: 'Status',
  },
  {
    key: 'total',
    label: 'Total commande',
  },
  {
    key: 'payed',
    label: 'Montant payé ',
  },
  {
    key: 'commissionHT',
    label: 'Commission HT',
  },
  {
    key: 'commissionTTC',
    label: 'Commission TTC',
  },
  {
    key: 'clientID',
    label: 'Numéro de client',
  },
  {
    key: 'readyAt',
    label: 'Prêt á',
  },
  {
    key: 'pickedAt',
    label: 'Récupéré à',
  },
  {
    key: 'deliverAt',
    label: 'Livré à',
  },
];

const INITIAL_DISPLAYED_ORDERS_COUNT = 50;

const columns = [
  {
    field: 'orderAt',
    headerName: 'Crée à',
    width: 150,
  },
  {
    field: 'name',
    headerName: 'Nom client',
    width: 200,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
  },
  {
    field: 'total',
    headerName: 'Total commande',
    width: 250,
    valueFormatter: ({ value }) => `${parseNumber(value).toFixed(2)}€`,
  },
  {
    field: 'payed',
    headerName: 'Montant payé ',
    width: 150,
    valueFormatter: ({ value }) => `${parseNumber(value).toFixed(2)}€`,
  },
  {
    field: 'commissionHT',
    headerName: 'Commission HT',
    width: 200,
    valueFormatter: ({ value }) => `${parseNumber(value).toFixed(2)}€`,
  },
  {
    field: 'commissionTTC',
    headerName: 'Commission TTC',
    width: 200,
    valueFormatter: ({ value }) => `${parseNumber(value).toFixed(2)}€`,
  },
  {
    field: 'percentageHT',
    headerName: 'Pourcentage HT',
    width: 200,
    valueFormatter: ({ value }) => `${value}%`,
  },
  {
    field: 'percentage',
    headerName: 'Pourcentage TTC',
    width: 200,
    valueFormatter: ({ value }) => `${value}%`,
  },
  {
    field: 'id',
    headerName: 'Numéro de commande',
    width: 250,
  },
  {
    field: 'clientID',
    headerName: 'Numéro de client',
    width: 300,
  },
  {
    field: 'readyAt',
    headerName: 'Prêt á',
    width: 200,
  },
  {
    field: 'pickedAt',
    headerName: 'Récupéré à',
    width: 200,
  },
  {
    field: 'deliverAt',
    headerName: 'Livré à',
    width: 200,
  },
];

const OrderDrawer = props => {
  const { shop, children, ...rest } = props;
  const [displayedOrdersCount, setDispayedOrdersCount] = React.useState(
    INITIAL_DISPLAYED_ORDERS_COUNT
  );
  const theme = useTheme();

  const [orders, loading, error] = useCollectionData(
    Firebase.firestore()
      .collection('orders')
      .where('shop.id', '==', shop.id)
      .orderBy('orderAt', 'desc')
      .limit(displayedOrdersCount),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const rows =
    !loading && !!orders.length
      ? orders.map(order => ({
          id: order.id,
          clientID: order.clientID,
          name: order.name || 'Nom client',
          orderAt: DateTime.fromMillis(order.orderAt).toLocaleString(
            DateTime.DATETIME_SHORT
          ),
          status: getReadableStatus(order.status),
          total: order.total,
          payed: parseNumber(
            order.total -
              parseNumber(
                percentage(
                  getPercentageWithVAT(order.shop.percentage),
                  order.total
                )
              )
          ),
          commissionHT: parseNumber(
            percentage(order.shop.percentage, order.total)
          ),
          commissionTTC: parseNumber(
            percentage(getPercentageWithVAT(order.shop.percentage), order.total)
          ),
          percentageHT: order.shop.percentage,
          percentage: getPercentageWithVAT(order.shop.percentage),
          readyAt: !!order.readyAt
            ? DateTime.fromMillis(order.readyAt).toLocaleString(
                DateTime.DATETIME_SHORT
              )
            : '-',
          pickedAt: !!order.pickedAt
            ? DateTime.fromMillis(order.pickedAt).toLocaleString(
                DateTime.DATETIME_SHORT
              )
            : '-',
          deliverAt: !!order.deliverAt
            ? DateTime.fromMillis(order.deliverAt).toLocaleString(
                DateTime.DATETIME_SHORT
              )
            : '-',
        }))
      : [];

  if (error) {
    throw new Error(error.toString());
  }

  return (
    <FullscreenDialog title="Commandes" theme={theme} {...rest}>
      <HeaderDialog>
        <HeaderDialogBlock>
          <Heading size={700} styles={{ paddingBottom: SPACING[100] }}>
            Toutes vos commandes{' '}
            <Chip
              label={shop.available ? 'Ouvert' : 'Fermé'}
              color={shop.available ? 'primary' : 'secondary'}
            />
          </Heading>
          <Text size={500} muted>
            {shop.name}
          </Text>
        </HeaderDialogBlock>
      </HeaderDialog>

      <BodyBlocksDialog>
        <ContentBlockDialog theme={theme}>
          <HeaderRow>
            <Button
              disabled={
                loading ||
                displayedOrdersCount === INITIAL_DISPLAYED_ORDERS_COUNT
              }
              onClick={() => {
                if (
                  displayedOrdersCount > orders.length &&
                  displayedOrdersCount !== orders.length &&
                  !loading
                ) {
                  setDispayedOrdersCount(
                    displayedOrdersCount - INITIAL_DISPLAYED_ORDERS_COUNT
                  );
                }
              }}
              color="secondary"
              variant="outlined"
              small
              styles={{
                marginRight: SPACING[100],
              }}
            >
              -
            </Button>
            <Text
              size={400}
              muted
              style={{
                marginRight: SPACING[100],
              }}
            >
              Commandes affichées:
            </Text>
            <Text size={500}>{displayedOrdersCount}</Text>
            <Button
              disabled={loading || displayedOrdersCount > orders.length}
              onClick={() => {
                if (displayedOrdersCount <= orders.length && !loading) {
                  setDispayedOrdersCount(
                    displayedOrdersCount + INITIAL_DISPLAYED_ORDERS_COUNT
                  );
                }
              }}
              color="secondary"
              variant="outlined"
              small
              styles={{
                marginLeft: SPACING[100],
              }}
            >
              +
            </Button>
          </HeaderRow>
        </ContentBlockDialog>
        {!!rows && (
          <ContentBlockDialog theme={theme}>
            <CSVLink
              headers={csvFileHeaders}
              data={rows}
              filename="results.csv"
              target="_blank"
            >
              <Button
                small
                color="secondary"
                variant="outlined"
                styles={{
                  marginLeft: SPACING[100],
                }}
              >
                Exporter les commandes affichées
              </Button>
            </CSVLink>
          </ContentBlockDialog>
        )}
      </BodyBlocksDialog>

      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={25}
        stickyHeader
        loading={loading}
        showToolbar
      />
    </FullscreenDialog>
  );
};

export default OrderDrawer;

import React from 'react';
import Firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Alert, AlertTitle } from '@material-ui/lab';

import { Tabs, Tab } from '../../../styleguide';
import { SPACING } from '../../../styles/constants';
import OrdersTab from './OrdersTab';
import InvoicesTab from './InvoicesTab';
import ORDER_STATUS from '../../../actions/orders/helpers/STATUS';

const RusherBillingTabs = props => {
  const { rusher } = props;
  const [currentTab, setCurrentTab] = React.useState(0);

  const [ordersDoc, ordersLoading, ordersError] = useCollectionData(
    Firebase.firestore()
      .collection('orders')
      .where(`rusher.id`, '==', rusher.id)
      .orderBy('orderAt', 'desc'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const orders = React.useMemo(() => {
    if (ordersDoc) {
      return ordersDoc.filter(
        o => o.status === ORDER_STATUS.delivered && !!o?.distance
      );
    }
    return [];
  }, [ordersDoc]);

  if (ordersError) {
    throw new Error(ordersError.toString());
  }

  const onTabChange = (event, value) => {
    setCurrentTab(value);
  };

  return (
    <>
      <Tabs
        style={{ width: '100%', margin: `${SPACING[300]} 0` }}
        value={currentTab}
        onChange={onTabChange}
      >
        <Tab label={`Mes factures`} />
        <Tab label={`Mes commandes`} />
      </Tabs>

      {!rusher.isVerified ? (
        <Alert severity="info" style={{ margin: '8px auto' }}>
          <AlertTitle>En attente de validation</AlertTitle>
          Une fois votre compte validé par notre équipe, vous pourrez faire vos
          premières livraisons et créer vos premiéres factures 💸
        </Alert>
      ) : null}

      <InvoicesTab
        value={currentTab}
        rusher={rusher}
        orders={orders}
        ordersLoading={ordersLoading}
      />
      <OrdersTab
        value={currentTab}
        rusher={rusher}
        orders={orders}
        ordersLoading={ordersLoading}
      />
    </>
  );
};

export default RusherBillingTabs;

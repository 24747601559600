import styled from 'styled-components';

import { SPACING, colors, BOX_SHADOW } from './constants';

const MainWrapper = styled.div`
  max-width: 700px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
`;

const IllustationWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: ${SPACING[200]};
`;

const Illustration = styled.img`
  width: 300px;
  height: 230px;
`;

const HeaderTitle = styled.div`
  text-align: center;
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${SPACING[300]} 0;
`;

const HeaderDescription = styled.div`
  text-align: center;
  width: 100%;
  max-width: 500px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: ${SPACING[300]};
  flex-direction: column;
`;

const ErrorContentWrapper = styled.div`
  font-family: monospace;
  width: 100%;
  max-width: 90%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: ${SPACING[200]} ${SPACING[300]};
  margin: ${SPACING[200]} auto;
  background-color: ${colors.lightGray};
  border-radius: 4px;
  box-shadow: ${BOX_SHADOW.md};
  overflow-wrap: anywhere;
  text-overflow: ellipsis;
`;

export {
  MainWrapper,
  IllustationWrapper,
  Illustration,
  HeaderTitle,
  HeaderDescription,
  ErrorContentWrapper,
};

import React from 'react';
import styled from 'styled-components';

import { StripeIcon } from './icons';

const StripeButtonWrapper = styled.div`
  display: flex;
  align-items: center;
  margin-top: ${props => props.marginTop || '0'};
  margin-bottom: 1px;
  background-image: -webkit-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
  background-image: -moz-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
  background-image: -ms-linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
  background-image: linear-gradient(#7dc5ee, #008cdd 85%, #30a2e4);
  -webkit-font-smoothing: antialiased;
  border: 0;
  padding: 12px;
  height: 32px;
  text-decoration: none;
  border-radius: 4px;
  box-shadow: 0 1px 0 rgba(0, 0, 0, 0.2);
  cursor: pointer;
  user-select: none;
`;

const StripeButtonText = styled.span`
  margin-left: 12px;
  height: 32px;
  font-size: 14px;
  line-height: 32px;
  color: white;
  font-weight: bold;
  font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.2);
  text-decoration: none;
`;

const StripeButton = props => {
  const { content, marginTop } = props;

  return (
    <StripeButtonWrapper marginTop={marginTop}>
      <StripeIcon size="22px" color="white" />
      <StripeButtonText>{content}</StripeButtonText>
    </StripeButtonWrapper>
  );
};

export default StripeButton;

import ORDER_STATUS from './STATUS';

const STATUS_READABLE = {
  [ORDER_STATUS.canceled]: 'Annulé',
  [ORDER_STATUS.inValidation]: 'Confirmation',
  [ORDER_STATUS.inProgress]: 'En preparation',
  [ORDER_STATUS.ready]: 'Prête',
  [ORDER_STATUS.inDelivery]: 'En livraison',
  [ORDER_STATUS.delivered]: 'Livré',
};

const getReadableStatus = currentStatus => {
  return STATUS_READABLE[currentStatus];
};

export default getReadableStatus;

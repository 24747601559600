import React from 'react';
import { DateTime } from 'luxon';
import add from 'lodash/add';
import Firebase from 'firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';
import { DataGrid } from '@material-ui/data-grid';

import getReadableStatus from '../../../../../actions/orders/helpers/getReadableStatus';
import percentage from '../../../../../actions/orders/helpers/percentage';
import { getPercentageWithVAT } from '../../../../../actions/orders/helpers/commissionHelpers';
import { BlockWrapper } from '../../../../../styles/admin';
import { parseNumber } from '../../../../../common';
import { OrderDialog } from '../common/Dialogs';
import { useTheme } from '../../../../../hooks';
import ORDER_STATUS from '../../../../../actions/orders/helpers/STATUS';
import BlockLayout from '../common/BlockLayout';
import StatCard from '../common/StatCard';
import BlockHeader from '../common/BlockHeader';

const { REACT_APP_STRIPE_DASHBOARD_URL } = process.env;

const columns = [
  {
    field: 'orderAt',
    headerName: 'Crée à',
    width: 150,
  },
  {
    field: 'name',
    headerName: 'Nom client',
    width: 200,
  },
  {
    field: 'status',
    headerName: 'Status',
    width: 150,
  },
  {
    field: 'shopName',
    headerName: 'Shop',
    width: 150,
  },
  {
    field: 'discount',
    headerName: 'Promotion 🏷️',
    width: 150,
    valueFormatter: ({ value }) => value || '--',
  },
  {
    field: 'deliveryFees',
    headerName: 'Frais livraison',
    width: 150,
    valueFormatter: ({ value }) => `${parseNumber(value).toFixed(2)}€`,
  },
  {
    field: 'appFees',
    headerName: 'Frais app',
    width: 150,
    valueFormatter: ({ value }) => `${parseNumber(value).toFixed(2)}€`,
  },
  {
    field: 'total',
    headerName: 'Montant',
    width: 150,
    valueFormatter: ({ value }) => `${parseNumber(value).toFixed(2)}€`,
  },
  {
    field: 'amount',
    headerName: '💸💸💸💸💸',
    width: 200,
    valueFormatter: ({ value: { commission, deliveryFees } }) =>
      `${parseNumber(commission).toFixed(2)}€ 
        ${
          deliveryFees > 0 ? ` + ${parseNumber(deliveryFees).toFixed(2)}€` : ''
        }`,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 250,
  },
  {
    field: 'clientID',
    headerName: 'ID client',
    width: 300,
  },
  {
    field: 'paymentURL',
    headerName: 'Voir le paiement',
    width: 250,
  },
  {
    field: 'rusher',
    headerName: 'ID livreur',
    width: 250,
  },
  {
    field: 'canceledBy',
    headerName: 'Annulé par',
    width: 200,
  },
  {
    field: 'readyAt',
    headerName: 'Prêt á',
    width: 200,
  },
  {
    field: 'pickedAt',
    headerName: 'Récupéré à',
    width: 200,
  },
  {
    field: 'deliverAt',
    headerName: 'Livré à',
    width: 200,
  },
];

const OrderBlock = props => {
  const [editDialog, setEditDialog] = React.useState(null);
  const theme = useTheme();

  const limitTs = React.useMemo(
    () =>
      Firebase.firestore.Timestamp.fromDate(
        new Date(new Date().setDate(new Date().getDate() - 30))
      ),
    []
  );

  const [orders, ordersLoading, ordersError] = useCollection(
    Firebase.firestore()
      .collection('orders')
      .where('orderAt', '>=', DateTime.fromSeconds(limitTs.seconds).toMillis())
      .orderBy('orderAt', 'desc'),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
        timestampsInSnapshots: true,
      },
    }
  );

  const onRowClick = params => {
    const order = orders.docs.find(doc => doc.id === params.row.id);
    setEditDialog({ data: params.row, orderDB: order.data() });
  };

  const getAmount = order => {
    const commission = percentage(
      getPercentageWithVAT(parseNumber(order.shop.percentage)),
      parseNumber(order.total)
    );

    const deliveryFees =
      !!order.rusher && !!order.rusher.isAdmin ? order.deliveryFees : 0;

    return {
      commission: parseNumber(add(commission, order.appFees)),
      deliveryFees,
    };
  };

  const { rows, ordersCount, canceledOrdersCount } = React.useMemo(() => {
    if (ordersLoading || !orders?.size || !!ordersError) {
      return {
        rows: [],
        ordersCount: 0,
        canceledOrdersCount: 0,
      };
    }

    const ordersData = orders.docs.map(doc => ({
      ...doc.data(),
    }));

    const rows = ordersData.map(order => ({
      id: order.id,
      clientID: order.clientID,
      shopName: order.shop.name,
      name: order.name || 'Nom client',
      orderAt: DateTime.fromMillis(order.orderAt).toLocaleString(
        DateTime.DATETIME_SHORT
      ),
      status: getReadableStatus(order.status),
      deliveryFees: order.deliveryFees,
      appFees: order.appFees,
      discount: order.discount?.code,
      total: order.total,
      amount: getAmount(order),
      paymentURL: `${REACT_APP_STRIPE_DASHBOARD_URL}/payments/${order.paymentIntentId}`,
      rusher: !!order.rusher ? order.rusher.id : 'Pas encore de livreur',
      rusherDetails: !!order.rusher ? order.rusher : 'Pas encore de livreur',
      items: order.itemsList,
      canceledBy: order.canceledBy || '--',
      readyAt: !!order.readyAt
        ? DateTime.fromMillis(order.readyAt).toLocaleString(
            DateTime.DATETIME_SHORT
          )
        : '-',
      pickedAt: !!order.pickedAt
        ? DateTime.fromMillis(order.pickedAt).toLocaleString(
            DateTime.DATETIME_SHORT
          )
        : '-',
      deliverAt: !!order.deliverAt
        ? DateTime.fromMillis(order.deliverAt).toLocaleString(
            DateTime.DATETIME_SHORT
          )
        : '-',
    }));

    const ordersCount = ordersData.length;
    const canceledOrdersCount = ordersData.filter(
      order => order.status === ORDER_STATUS.canceled
    ).length;

    return {
      rows,
      ordersCount,
      canceledOrdersCount,
    };
  }, [ordersLoading, orders, ordersError]);

  if (ordersError) {
    throw new Error(ordersError.toString());
  }

  return (
    <BlockWrapper theme={theme}>
      <BlockHeader
        title="Les commandes"
        path="/admin/dashboard/orders"
        label={`Depuis le ${DateTime.fromSeconds(
          limitTs.seconds
        ).toLocaleString(DateTime.DATE_SHORT)}`}
      />

      <BlockLayout
        statBlocks={
          <>
            <StatCard label={'Commandes'} value={ordersCount} />
            <StatCard label={'Annulées'} value={canceledOrdersCount} />
          </>
        }
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          stickyHeader
          loading={ordersLoading}
          onRowClick={onRowClick}
        />
      </BlockLayout>

      {editDialog ? (
        <OrderDialog
          order={editDialog}
          isOpen={!!editDialog}
          onClose={() => setEditDialog(null)}
        />
      ) : null}
    </BlockWrapper>
  );
};

export default OrderBlock;

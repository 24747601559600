import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

const deleteShopItem = shopItemID => {
  // console.log('Delete shop item called', { shopItemID });
  ReactGA.event({
    category: 'Shop',
    action: 'Delete ShopItem',
  });

  try {
    return Firebase.firestore()
      .collection('shopItems')
      .doc(shopItemID)
      .delete();
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

export default deleteShopItem;

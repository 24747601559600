import React from 'react';

import { Heading, LineProperty, PropertyEdit } from '../../../styleguide';
import {
  TabContentWrapper,
  SettingsContentWrapper,
  FlexFieldsWrapper,
} from '../../../styles/shop';
import { updateRusher } from '../../../actions/rusher';
import TabPanel from '../../shop/tabs/TabPanel';

const SettingsTab = props => {
  const { value, rusher } = props;
  const [edition, setEdition] = React.useState(null);
  const { baseCity, name, phone, pictureURL } = rusher;

  const initialValue = rusher[edition];
  const linePropertyStyles = {
    minWidth: 300,
    maxWidth: 500,
    padding: '0 8px',
  };

  const onUpdate = val => {
    const updatedRusher = {
      ...rusher,
      ...val,
    };
    updateRusher(updatedRusher);
  };

  return (
    <TabPanel value={value} index={1}>
      <TabContentWrapper>
        <Heading size={800}>Informations</Heading>

        <SettingsContentWrapper>
          {!edition ? (
            <>
              <FlexFieldsWrapper>
                <LineProperty
                  value={pictureURL}
                  label="Avatar"
                  onClick={() => setEdition('pictureURL')}
                  styles={linePropertyStyles}
                  isLogo
                  buttonContent="Changer"
                />
                <LineProperty
                  value={name}
                  label="Nom de livreur"
                  onClick={() => setEdition('name')}
                  styles={linePropertyStyles}
                />
              </FlexFieldsWrapper>

              <FlexFieldsWrapper>
                <LineProperty
                  value={baseCity}
                  label="Ville de départ"
                  onClick={() => setEdition('baseCity')}
                  styles={linePropertyStyles}
                />
                <LineProperty
                  value={phone}
                  label="Votre numéro"
                  onClick={() => setEdition('phone')}
                  styles={linePropertyStyles}
                />
              </FlexFieldsWrapper>
            </>
          ) : (
            <PropertyEdit
              property={edition}
              setEdition={setEdition}
              initialValue={initialValue}
              onUpdate={onUpdate}
            />
          )}
        </SettingsContentWrapper>
      </TabContentWrapper>
    </TabPanel>
  );
};

export default SettingsTab;

import React from 'react';
import { subtract } from 'lodash';
import orderBy from 'lodash/orderBy';
import {
  Chip,
  Dialog,
  Divider,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { parseNumber } from '../../../../common';
import { ItemImage } from '../../../../styles/basket';
import { useTheme } from '../../../../hooks';
import { Heading, Button, CheckBox, Text } from '../../../../styleguide';
import { SPACING } from '../../../../styles/constants';
import { FlexFieldsWrapper } from '../../../../styles/shop';
import {
  ShopProfilMenuDialogCategoryWrapper,
  ShopProfilMenuDialogLineWrapper,
  ShopProfilTotalPrice,
  ShopProfilDialogHeader,
  ShopProfilMenuDialogLineHeader,
  ShopProfilDialogSupplementsWrapper,
} from '../../../../styles/shop-profil';

import OptionsDialogContent from './OptionsDialogContent';
import SupplementsDialogContent from './SupplementsDialogContent';

const MenuDialog = props => {
  const {
    onConfirm,
    selectedItem,
    setSelectedItem,
    groupedShopItems,
    onResetSelectedItem,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation(['common', 'shop', 'categories']);

  React.useEffect(() => {
    if (!!selectedItem.item) {
      if (selectedItem.total < selectedItem.initialItemPrice) {
        onResetSelectedItem();
      }
    }
  }, [selectedItem, onResetSelectedItem]);

  const orderedOptions = options => {
    return orderBy(options, i => i.order, 'asc');
  };

  const onConfirmSelection = () => {
    onConfirm(selectedItem);
    onResetSelectedItem();
  };

  const onCancelSelection = () => {
    onResetSelectedItem();
  };

  const onItemUpdate = (product, isCreation) => {
    if (!isCreation) {
      const itemToRemove = selectedItem.products.filter(
        i => i.item.id === product.id
      )[0];

      setSelectedItem({
        ...selectedItem,
        products: selectedItem.products
          .filter(i => i.item.id !== product.id)
          .sort(),
        total: selectedItem.total - itemToRemove.total,
      });
    } else {
      const hasAlreadyItemInCategory = selectedItem.products.some(
        p => p.category === product.category
      );

      // Création
      if (!hasAlreadyItemInCategory) {
        setSelectedItem({
          ...selectedItem,
          products: [
            ...selectedItem.products,
            {
              id: product.id,
              item: product,
              category: product.category,
              supplements: [],
              options: !!product.options?.length
                ? product.options.map(o => {
                    return {
                      ...o,
                      items: [],
                    };
                  })
                : [],
              total: 0,
            },
          ],
        });
      } else {
        const itemToRemove = selectedItem.products.filter(
          i => i.category === product.category
        );

        setSelectedItem({
          ...selectedItem,
          products: [
            ...selectedItem.products.filter(
              i => i.category !== product.category
            ),
            {
              id: product.id,
              item: product,
              category: product.category,
              supplements: [],
              options: !!product.options?.length
                ? product.options.map(o => {
                    return {
                      ...o,
                      items: [],
                    };
                  })
                : [],
              total: 0,
            },
          ],
          total: parseNumber(
            subtract(selectedItem.total, itemToRemove[0].total)
          ),
        });
      }
    }
  };

  const categoriesItems = groupedShopItems
    .map(group => {
      if (selectedItem.item.categories.includes(group.category)) {
        return group;
      }
      return null;
    })
    .filter(val => val);

  const options = selectedItem.products.reduce(
    (acc, p) => [...acc, ...p.options],
    []
  );
  const requiredOptions = options?.filter(o => !!o.required);

  const optionsAreValid = !!requiredOptions?.length
    ? !!requiredOptions.every(o => !!o.max)
    : true;

  const canConfirm =
    selectedItem.products.length === selectedItem.item.categories.length &&
    !!optionsAreValid;

  return (
    <Dialog
      open={!!selectedItem.item}
      onClose={() =>
        setSelectedItem({
          item: null,
          supplements: null,
          total: 0,
        })
      }
      fullWidth
      maxWidth="sm"
    >
      {selectedItem.item ? (
        <>
          <ShopProfilDialogHeader>
            <FlexFieldsWrapper
              align="center"
              width="fit-content"
              padding="0px"
              wrap="no-wrap"
            >
              <ItemImage
                small
                w={'50px'}
                h={'40px'}
                imageSRC={selectedItem.item.pictureURL}
                theme={theme}
              />
              <Heading size={500}>{selectedItem.item.name}</Heading>
            </FlexFieldsWrapper>
            <Chip
              size="small"
              label={`${parseNumber(selectedItem.item.price).toFixed(2)}€`}
            />
          </ShopProfilDialogHeader>

          <DialogContent dividers>
            <div
              style={{
                padding: `${SPACING[200]} 0`,
              }}
            >
              <Text size={500} muted>
                {selectedItem.item.description}
              </Text>
            </div>

            {categoriesItems.map((group, index) => (
              <ShopProfilMenuDialogCategoryWrapper key={index}>
                <ShopProfilMenuDialogLineHeader theme={theme}>
                  <Heading size={600}>{group.category}</Heading>
                </ShopProfilMenuDialogLineHeader>

                {group.items.map(item => {
                  const currentProduct = selectedItem.products.filter(
                    p => p.item.id === item.id
                  )[0];
                  return (
                    <ShopProfilMenuDialogLineWrapper key={item.id}>
                      <CheckBox
                        checked={!!currentProduct}
                        onChange={isChecked => onItemUpdate(item, isChecked)}
                        name={item.name}
                        wrapperProps={{ justify: 'flex-start' }}
                        disabled={!item.avaibility}
                        label={
                          <Text
                            size={500}
                            color={
                              !item.avaibility
                                ? theme.lightGray
                                : theme.headingColor
                            }
                          >
                            {`${item.name}${
                              !item.avaibility ? ' (indisponible)' : ''
                            }`}
                          </Text>
                        }
                      />
                      {!!currentProduct &&
                      (!!item?.options?.length ||
                        !!item.supplements?.length) ? (
                        <div
                          style={{
                            padding: `0 ${SPACING[100]}`,
                            marginLeft: `${SPACING[100]}`,
                            paddingBottom: `${SPACING[200]}`,
                            marginBottom: `${SPACING[200]}`,
                            width: '100%',
                            borderLeft: `1px solid ${theme.lightGray}`,
                            borderBottom: `1px solid ${theme.lightGray}`,
                            borderBottomLeftRadius: '4px',
                          }}
                        >
                          {!!item?.options?.length
                            ? orderedOptions(
                                item.options
                              ).map((option, index) => (
                                <OptionsDialogContent
                                  key={option.id}
                                  option={option}
                                  selectedItem={selectedItem}
                                  setSelectedItem={setSelectedItem}
                                  currentProduct={currentProduct}
                                  withDivider={
                                    item?.options?.length !== index + 1
                                  }
                                />
                              ))
                            : null}

                          {!!item.supplements?.length ? (
                            <ShopProfilDialogSupplementsWrapper>
                              {!!item?.options?.length && (
                                <Divider
                                  style={{
                                    width: '100%',
                                    margin: `${SPACING[200]} 0`,
                                  }}
                                />
                              )}
                              <FlexFieldsWrapper
                                padding={`${SPACING[100]} 4px`}
                              >
                                <Heading size={500}>
                                  {t('shop:supplements')}
                                </Heading>
                                <Chip
                                  variant="outlined"
                                  size="small"
                                  label={t('shop:optionnal')}
                                  style={{ marginLeft: SPACING[100] }}
                                />
                              </FlexFieldsWrapper>

                              {item.supplements.map(supplement => (
                                <SupplementsDialogContent
                                  key={supplement.id}
                                  selectedItem={selectedItem}
                                  setSelectedItem={setSelectedItem}
                                  supplement={supplement}
                                  currentProduct={currentProduct}
                                />
                              ))}
                            </ShopProfilDialogSupplementsWrapper>
                          ) : null}
                        </div>
                      ) : null}
                    </ShopProfilMenuDialogLineWrapper>
                  );
                })}
              </ShopProfilMenuDialogCategoryWrapper>
            ))}
            <Divider
              style={{
                width: '100%',
                margin: `${SPACING[200]} 0`,
              }}
            />
            <ShopProfilTotalPrice>
              <Heading size={600} styles={{ marginRight: 8 }}>
                Total:{' '}
              </Heading>
              <Chip label={`${parseNumber(selectedItem.total).toFixed(2)}€`} />
            </ShopProfilTotalPrice>
          </DialogContent>
          <DialogActions>
            <Button onClick={onCancelSelection} minimal small>
              {t('shop:cancel')}
            </Button>
            <Button
              onClick={onConfirmSelection}
              primary
              small
              disabled={!canConfirm}
            >
              {t('shop:add_to_basket')}
            </Button>
          </DialogActions>
        </>
      ) : null}
    </Dialog>
  );
};

export default MenuDialog;

import {
  LocationIcon,
  OrdersIcon,
  SettingsIcon,
  ChatIcon,
  CardIcon,
  UserIcon,
} from '../../styleguide/icons';

const NAV_HEADER_LINKS = [
  {
    name: 'Profil',
    icon: UserIcon,
    redirect: true,
    method: () => '/account',
    isVisible: () => true,
  },
  {
    name: 'Paramétres',
    icon: SettingsIcon,
    redirect: true,
    method: () => '/account/settings',
    isVisible: () => true,
  },
];

const NAV_LINKS = [
  {
    name: 'account',
    icon: UserIcon,
    redirect: true,
    path: '/account',
    external: false,
  },
  {
    name: 'active_orders',
    icon: OrdersIcon,
    redirect: true,
    path: '/account/orders',
    external: false,
  },
  // {
  //   name: 'Vos Adresses',
  //   icon: LocationIcon,
  //   redirect: true,
  //   path: '/account/adresses',
  //   external: false,
  // },
  // {
  //   name: 'Moyens de Paiements',
  //   icon: CardIcon,
  //   redirect: true,
  //   path: '/account/payment_methods',
  //   external: false,
  // },
  {
    name: 'contact_us',
    icon: ChatIcon,
    redirect: true,
    path: 'https://m.me/108163771705869',
    external: true,
  },
];

export { NAV_HEADER_LINKS, NAV_LINKS };

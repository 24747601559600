import React from 'react';
import {
  FormControl,
  InputLabel,
  Select,
  ListSubheader,
  MenuItem,
} from '@material-ui/core';

import { Button, Heading } from '../../../styleguide';
import {
  FormWrapper,
  ButtonsWrapper,
  FieldWrapper,
} from '../../../styles/form';
import { CATEGORIES } from '../../../common';

const CategorySelect = props => {
  const { onShopUpdate, onNext } = props;

  const [category, setCategory] = React.useState('');

  const onSubmitForm = event => {
    event.preventDefault();
    onShopUpdate({ category });
    onNext();
  };

  const canSubmit = !!category;

  return (
    <>
      <Heading size={900}>Catégorie</Heading>
      <FormWrapper onSubmit={onSubmitForm}>
        <FieldWrapper>
          <FormControl style={{ width: '100%' }}>
            <InputLabel htmlFor="grouped-select">Categorie</InputLabel>
            <Select
              id="grouped-select"
              value={category}
              onChange={event => setCategory(event.target.value)}
            >
              <MenuItem value="">
                <em>None</em>
              </MenuItem>
              {CATEGORIES.map(item => [
                <ListSubheader key={item.name}>{item.name}</ListSubheader>,
                item.values.map(value => (
                  <MenuItem value={value}>{value}</MenuItem>
                )),
              ])}
            </Select>
          </FormControl>
        </FieldWrapper>

        <ButtonsWrapper>
          <Button type="submit" disabled={!canSubmit}>
            Continuer
          </Button>
        </ButtonsWrapper>
      </FormWrapper>
    </>
  );
};

export default CategorySelect;

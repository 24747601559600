import React from 'react';
import { Chip, Divider } from '@material-ui/core';
import { subtract, add } from 'lodash';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../../../hooks';
import { Heading, CheckBox, Text } from '../../../../styleguide';
import { SPACING, colors } from '../../../../styles/constants';
import { ShopProfilDialogSupplementsWrapper } from '../../../../styles/shop-profil';
import { FlexFieldsWrapper } from '../../../../styles/shop';
import { parseNumber } from '../../../../common';

import { ShopProfilDialogSupplementLine } from '../../../../styles/shop-profil';

const OptionsDialogContent = props => {
  const {
    option,
    selectedItem,
    setSelectedItem,
    currentProduct,
    withDivider,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation(['common', 'shop']);

  const onOptionsUpdate = (option, parent, isCreation) => {
    if (!isCreation) {
      setSelectedItem({
        ...selectedItem,
        products: [
          // prevent duplicate item
          ...selectedItem.products.filter(p => p.id !== currentProduct.id),
          {
            ...currentProduct,
            options: currentProduct.options.map(opt => {
              if (opt.id === parent.id) {
                const items = opt.items.filter(i => i.id !== option.id);
                return {
                  ...opt,
                  items,
                  max: items.length >= opt.count,
                };
              }
              return opt;
            }),
            ...(!!parent.paying
              ? {
                  total: parseNumber(
                    subtract(
                      parseNumber(currentProduct.total),
                      parseNumber(option.price)
                    )
                  ),
                }
              : {}),
          },
        ],
        ...(!!parent.paying
          ? {
              total: parseNumber(
                subtract(
                  parseNumber(selectedItem.total),
                  parseNumber(option.price)
                )
              ),
            }
          : {}),
      });
    } else {
      const itemTotal = !!parent.paying
        ? parseNumber(
            add(parseNumber(currentProduct.total), parseNumber(option.price))
          )
        : 0;

      setSelectedItem({
        ...selectedItem,
        products: [
          // prevent duplicate item
          ...selectedItem.products.filter(p => p.id !== currentProduct.id),
          {
            ...currentProduct,
            options: currentProduct.options.map(opt => {
              if (opt.id === parent.id) {
                const items = [...opt.items, option];
                return {
                  ...opt,
                  items,
                  max: items.length >= opt.count,
                };
              }
              return opt;
            }),
            total: itemTotal,
          },
        ],
        ...(!!parent.paying
          ? {
              total: parseNumber(
                add(
                  subtract(selectedItem.total - currentProduct.total),
                  itemTotal
                )
              ),
            }
          : {}),
      });
    }
  };

  const isCurrentlySelected = item => {
    const items = currentProduct.options?.find(o => o.id === option.id)?.items;

    return !!items?.includes(item);
  };

  const itemIsDisabled = item => {
    if (!item.avaibility) {
      return true;
    }

    if (isCurrentlySelected(item)) {
      return false;
    }

    return !!currentProduct.options?.find(o => o.id === option.id)?.max;
  };

  const getSelectedOptionItemsCount = option => {
    const items = currentProduct.options?.find(o => o.id === option.id)?.items;
    return items?.length || 0;
  };

  const selectedOptionItemsCount = getSelectedOptionItemsCount(option);

  return (
    <ShopProfilDialogSupplementsWrapper key={option.id}>
      <FlexFieldsWrapper align="center" padding={`${SPACING[100]} 4px`}>
        <Heading size={500} styles={{ marginRight: SPACING[100] }}>
          {option.name}
        </Heading>
        {!!option.required ? (
          <Chip
            variant="outlined"
            size="small"
            label={
              <Text size={300}>
                <span
                  style={{
                    fontWeight: 'bold',
                    color:
                      selectedOptionItemsCount < option.count
                        ? colors.red
                        : colors.green,
                  }}
                >
                  {`${selectedOptionItemsCount} / ${option.count}`}
                </span>{' '}
                Requis
              </Text>
            }
          />
        ) : (
          <>
            <Chip size="small" label={t('shop:optionnal')} />
            {option.count > 1 && (
              <Chip
                variant="outlined"
                size="small"
                label={
                  <Text size={300}>
                    <span
                      style={{
                        fontWeight: 'bold',
                      }}
                    >
                      {`${selectedOptionItemsCount} / ${option.count}`}
                    </span>{' '}
                    Max
                  </Text>
                }
                style={{ marginLeft: SPACING[100] }}
              />
            )}
          </>
        )}
      </FlexFieldsWrapper>
      {option.items.map(item => (
        <ShopProfilDialogSupplementLine key={item.id}>
          <CheckBox
            checked={isCurrentlySelected(item)}
            onChange={isChecked => onOptionsUpdate(item, option, isChecked)}
            name={item.name}
            wrapperProps={{
              justify: 'flex-start',
              align: 'center',
            }}
            disabled={itemIsDisabled(item)}
            label={
              <Text
                size={400}
                color={!item.avaibility ? theme.lightGray : theme.headingColor}
              >
                {item.name}
                {!item.avaibility && ' (indisponible)'}
              </Text>
            }
          />
          {!!option.paying && item.price > 0 ? (
            <Chip
              size="small"
              label={`${parseNumber(item.price).toFixed(2)}€`}
            />
          ) : null}
        </ShopProfilDialogSupplementLine>
      ))}
      {withDivider && (
        <Divider
          style={{
            width: '100%',
            margin: `${SPACING[200]} 0`,
          }}
        />
      )}
    </ShopProfilDialogSupplementsWrapper>
  );
};

export default OptionsDialogContent;

import React from 'react';

import { displayDurationFrom } from '../../../../actions/orders/helpers/computeTimesHelpers';
import { useTheme } from '../../../../hooks';
import { Button, Text } from '../../../../styleguide';
import { computeFinalDeliveryTime } from '../../../../actions/orders/helpers/computeFinalPreparationTime';
import {
  InProgressModalDetails,
  InProgressModalDetailsRow,
  InProgressModalDetailsRowWrapper,
} from '../../../../styles/orders-cards';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
} from '@material-ui/core';
import ORDER_STATUS from '../../../../actions/orders/helpers/STATUS';

const DeliveryDialog = props => {
  const { isOpen, onClose, onConfirm, order } = props;
  const theme = useTheme();

  const temporaryDisabled = !!order?.inTreatment;

  React.useEffect(() => {
    if (order.status !== ORDER_STATUS.inDelivery) {
      onClose();
    }
  }, [order, onClose]);

  const { hours, minutes } = computeFinalDeliveryTime(order.pickedAt);

  const estimatedDeliveryTime = displayDurationFrom({
    seconds: order.duration,
  });

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="order-alert-dialog-title"
    >
      <DialogTitle id="order-alert-dialog-title">
        Terminer la commande
      </DialogTitle>
      <DialogContent>
        <Text size={400}>
          En terminant la commande, vous la marquerez comme livré, veillez à ne
          pas terminer la commande tant que le client n'a pas recupéré sa
          commande.
        </Text>
        <InProgressModalDetails>
          <Text size={500}>Récapitulatif livraison : </Text>
          <InProgressModalDetailsRowWrapper>
            <InProgressModalDetailsRow>
              <Text size={400} color={theme.headingColor}>
                Temps de livraison estimé :
              </Text>
              <Text size={500} color={theme.textColor}>
                {estimatedDeliveryTime}
              </Text>
            </InProgressModalDetailsRow>
            <InProgressModalDetailsRow>
              <Text size={400} color={theme.headingColor}>
                Temps de livraison final :
              </Text>
              <Text size={500} color={theme.textColor}>
                {' '}
                {hours > 0 ? `${hours}h${minutes}` : `${minutes}min`}
              </Text>
            </InProgressModalDetailsRow>
          </InProgressModalDetailsRowWrapper>
        </InProgressModalDetails>
      </DialogContent>

      <DialogActions>
        <Button onClick={onClose} small minimal>
          Attendre
        </Button>
        <Button
          onClick={onConfirm}
          color="primary"
          small
          disabled={temporaryDisabled}
        >
          Terminer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DeliveryDialog;

import styled from 'styled-components';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import ShopPattern from '../assets/images/shop-pattern.svg';

import { SPACING, colors, devices, sizes } from './constants';

const MainShopHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${props => props.justify || 'space-between'};
  align-items: center;
  padding: ${SPACING[800]} 0;
  background-color: ${props => props.background};
  background-image: url(${props => props.imageURL || ShopPattern});
  background-size: ${props => (props.imageURL ? 'cover' : 'contain')};
  background-repeat: ${props => (props.imageURL ? 'no-repeat' : 'repeat')};
  background-position: center;
`;

const OrdersActionsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${props => props.align || 'flex-start'};
  align-items: center;
  flex-wrap: wrap;
  padding: ${SPACING[200]} 0 ${SPACING[100]} 0;
`;

const TabContentWrapper = styled.div`
  width: 100%;
  padding: ${SPACING[200]} 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

const FlexFieldsWrapper = styled.div`
  width: ${props => props.width || '100%'};
  display: flex;
  justify-content: ${props => props.justify || 'flex-start'};
  align-items: ${props => props.align || 'flex-start'};
  flex-wrap: ${props => props.wrap || 'wrap'};
  padding: ${props => props.padding || SPACING[100]};
  ${({ classNames }) => classNames || ''}

  @media (max-width: ${sizes.mobileLg}) {
    flex-direction: ${({ mobileColumn }) => (mobileColumn ? 'column' : 'row')};
    align-items: flex-start;
  }
`;

const AccordionDetailsContent = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

const ItemButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  padding: ${SPACING[300]} ${SPACING[100]};
  border-bottom: 1px solid ${colors.lightGray};
`;

const SupplementsFieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
`;

const CategoriesFieldsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  padding: ${SPACING[200]} 0;
  gap: ${SPACING[100]};
`;

const ItemsListingWrapper = styled.div`
  width: 100%;
  display: flex;
  overflow: scroll;
  scrollbar-color: transparent ${props => props.theme.lightGray};
  scrollbar-width: thin;

  &::-webkit-scrollbar {
    margin: 0 10px;
    height: 10px;
    cursor: pointer;
  }
  &::-webkit-scrollbar-track {
    background: ${props => props.theme.cardBg};
    border-radius: 25px;
  }
  &::-webkit-scrollbar-thumb {
    background: ${props => props.theme.lightGray};
    border-radius: 25px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background: gray;
    cursor: pointer;
  }
  &::-webkit-scrollbar-thumb:focus {
    background: gray;
    cursor: pointer;
  }
`;

const ItemsColumnWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  padding: ${SPACING[200]};
  margin: ${SPACING[100]};
`;

const ItemsColumnHeader = styled.div`
  width: 100%;
  display: flex;
  color: ${props => props.color};
  justify-content: center;
  align-items: center;
  padding: ${SPACING[200]};
  border-bottom: 1px solid ${({ theme }) => theme.lightGray};
  margin-bottom: ${SPACING[300]};
`;

const ItemWrapper = styled.div`
  position: relative;
  width: ${props => (props.size === 'lg' ? '300px' : '294px')};
  border-radius: 4px;
  box-shadow: ${props => props.theme.boxShadow.md};
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-direction: column;
  margin-bottom: ${SPACING[300]};
  background-color: ${props => props.theme.cardBg};
  cursor: pointer;
  align-self: stretch;

  @media ${devices.laptop} {
    width: ${props => (props.size === 'lg' ? '380px' : '330px')};
  }
`;

const ItemBgImage = styled.div`
  background: url(${props => props.imageSRC || ShopPattern});
  width: ${props => (props.size === 'lg' ? '300px' : '294px')};
  height: 180px;
  border-radius: 4px;
  background-position: center;
  background-size: cover;
  padding: ${props => (props.withoutP ? '0' : SPACING[200])};
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  box-shadow: ${({ theme }) => theme.boxShadow.base};
  background-color: ${({ theme }) => theme.black};

  @media ${devices.laptop} {
    width: ${props => (props.size === 'lg' ? '380px' : '330px')};
    height: 180px;
  }
`;

const ItemBgImageChipWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: ${props =>
    props.spaceBetween ? 'space-between' : 'flex-end'};
  align-items: center;
`;

const ItemBg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: ${props =>
    props.userTheme === 'light'
      ? `rgba(255, 255, 255, 0.35)`
      : `rgba(0, 0, 0, 0.45)`};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  cursor: default;
`;

const ItemContentWrapper = styled.div`
  padding: ${SPACING[300]} ${SPACING[200]};
  padding-bottom: 4px;
  width: 100%;
`;

const ItemContentHeaderWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${SPACING[100]};
  padding-bottom: ${SPACING[100]};
  border-bottom: 1px solid ${({ theme }) => theme.lightGray};
`;

const ItemContentHeaderDetailsWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
`;

const ItemContentButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: ${SPACING[200]};
  padding-top: 0;
  margin-top: auto;
`;

const EmptyTabWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: ${SPACING[200]};
`;

const SettingsContentWrapper = styled.div`
  padding: ${SPACING[200]} 0;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const StripeButton = styled.img`
  width: 180px;
  margin: 0 auto;
`;

const HoursWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;

  & > .hours-description {
    padding: ${SPACING[200]} ${SPACING[100]};
  }
`;

const HoursGrid = styled.div`
  width: 100%;
  max-width: 75em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  gap: ${SPACING[200]};
  margin: ${SPACING[200]} auto;

  @media ${devices.mobileLG} {
    grid-template-columns: repeat(2, 1fr);
    display: grid;
    grid-template-rows: auto;
    grid-gap: ${SPACING[200]};
  }
`;

const HoursGridItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: stretch;
  width: 100%;
  padding: ${SPACING[200]};
  border-radius: 4px;
  background-color: ${props => props.theme.cardBg};
  box-shadow: ${({ theme }) => theme.boxShadow.base};
`;

const HoursGridItemContent = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  flex-wrap: wrap;
  gap: ${SPACING[100]};
  padding: ${SPACING[100]};

  & .input {
    width: 6em;
    padding: ${SPACING[100]};
    margin: 0 ${SPACING[100]};
  }

  & > * {
    flex: 1;
  }

  & > .field {
    ${({ closed }) => closed && `opacity: 0.5;`}
  }
`;

const TextWrapper = styled.div`
  margin-top: ${props => (props.withTopMargin ? SPACING[200] : '0')};
  padding: ${SPACING[200]} 0;
  max-width: 380px;
  text-align: center;
`;

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: 'none',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    borderBottom: '1px solid rgba(0, 0, 0, .125)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles(theme => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const OptionsContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const EmptyOptions = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${SPACING[200]};
`;

const OptionsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: stretch;
  flex-wrap: wrap;
  padding: ${SPACING[200]} 0;
`;

const OptionsFieldsWrapper = styled.div`
  width: 100%;
  max-width: 22em;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${SPACING[100]};
  border-radius: 6px;
  border: 1px solid ${({ theme }) => theme.lightGray};
  margin: ${SPACING[100]};
`;

const OptionsItemsListWrapper = styled.div`
  width: 100%;
  margin-top: ${SPACING[200]};
`;

const OptionsItemWrapper = styled.div`
  width: 100%;
`;

const OptionsItemsList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: ${SPACING[200]};
`;

const OptionsItemsListItem = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: ${SPACING[200]};
`;

export {
  MainShopHeaderWrapper,
  OrdersActionsWrapper,
  TabContentWrapper,
  FlexFieldsWrapper,
  ItemButtonsWrapper,
  AccordionDetailsContent,
  SupplementsFieldsWrapper,
  ItemsListingWrapper,
  ItemsColumnWrapper,
  ItemsColumnHeader,
  ItemWrapper,
  ItemBgImage,
  ItemContentWrapper,
  ItemContentHeaderWrapper,
  ItemContentHeaderDetailsWrapper,
  ItemContentButtonsWrapper,
  CategoriesFieldsWrapper,
  EmptyTabWrapper,
  ItemBg,
  SettingsContentWrapper,
  StripeButton,
  TextWrapper,
  ItemBgImageChipWrapper,
  AccordionDetails,
  AccordionSummary,
  Accordion,
  EmptyOptions,
  OptionsWrapper,
  OptionsFieldsWrapper,
  OptionsItemsListWrapper,
  OptionsItemWrapper,
  OptionsItemsListItem,
  OptionsItemsList,
  OptionsContainer,
  HoursWrapper,
  HoursGrid,
  HoursGridItem,
  HoursGridItemContent,
};

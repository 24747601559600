import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
} from '@material-ui/core';
import { CheckBox, Button, HoverableLink } from '../../styleguide';
import { BasicFlexBlock } from '../../styles/common';
import { SPACING } from '../../styles/constants';
import { useTheme } from '../../hooks';

const AddressesSelectionWrapper = styled.div``;

const AddressModal = props => {
  const {
    isOpen,
    onClose,
    addresses,
    setSelectedAddress,
    selectedAddress,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation(['common', 'checkout']);

  const onSelectAddress = address => {
    setSelectedAddress(address);
    onClose();
  };

  return (
    <Dialog fullWidth maxWidth="sm" open={isOpen} onClose={onClose}>
      <DialogTitle>{t('checkout:address_modal:title')}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {t('checkout:address_modal:message')}
        </DialogContentText>

        <AddressesSelectionWrapper>
          {addresses.map(address => (
            <FormControlLabel
              key={address.id}
              control={
                <CheckBox
                  checked={address.id === selectedAddress.id}
                  onChange={() => onSelectAddress(address)}
                  name={address.name}
                  wrapperProps={{ justify: 'flex-start' }}
                />
              }
              label={`${address.street} - ${address.postalCode} - ${address.city}`}
            />
          ))}
          <BasicFlexBlock padding={SPACING[200]}>
            <HoverableLink
              color={theme.primaryColor}
              to={{
                pathname: `/account/adresses`,
                state: { fromOrder: true },
              }}
            >
              {t('checkout:address_modal:add_address')}
            </HoverableLink>
          </BasicFlexBlock>
        </AddressesSelectionWrapper>
      </DialogContent>

      <DialogActions>
        <Button small onClick={onClose} minimal>
          {t('common:close')}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default AddressModal;

import React from 'react';
import { useFormik } from 'formik';

import { Button, Heading, Input, Text } from '../../../styleguide';
import { SignupFormPolicyText } from '../../../styles/signup';
import { FormWrapper, ButtonsWrapper } from '../../../styles/form';
import { signupShopName } from '../../../forms';

const NameForm = props => {
  const { onShopUpdate, onNext } = props;

  const formik = useFormik({
    initialValues: {
      name: '',
      description: '',
    },
    validationSchema: signupShopName,
    onSubmit: values => {
      const { name, description } = values;
      onShopUpdate({ name, description });
      onNext();
    },
  });

  const canSubmit = !!formik.values.name && !!formik.values.description;

  return (
    <>
      <Heading size={900} centered>
        Nom et description
      </Heading>
      <FormWrapper onSubmit={formik.handleSubmit}>
        <Input
          name="name"
          label="Nom établissement"
          required
          placeholder="The best restaurant"
          value={formik.values.name}
          onChange={formik.handleChange}
          error={formik.touched.name && Boolean(formik.errors.name)}
          helperText={formik.touched.name && formik.errors.name}
        />

        <Input
          name="description"
          label="Description"
          required
          placeholder="Notre restaurant est ..."
          multiline
          rows={4}
          value={formik.values.description}
          onChange={formik.handleChange}
          error={
            formik.touched.description && Boolean(formik.errors.description)
          }
          helperText={formik.touched.description && formik.errors.description}
        />

        <SignupFormPolicyText>
          <Text size={300} muted>
            Le nom et la description sont publics, ils seront utilisés sur le
            profil de votre établissement.
          </Text>
        </SignupFormPolicyText>

        <ButtonsWrapper>
          <Button type="submit" disabled={!canSubmit}>
            Continuer
          </Button>
        </ButtonsWrapper>
      </FormWrapper>
    </>
  );
};

export default NameForm;

import React from 'react';
import styled from 'styled-components';
import uniqBy from 'lodash/uniqBy';
import {
  DialogActions,
  Dialog,
  DialogContent,
  Divider,
  DialogTitle,
} from '@material-ui/core';
import { Button, Backdrop, Text } from '../../../../styleguide';
import { CheckIcon, CircleIcon } from '../../../../styleguide/icons';
import { orderedGroupedItems } from '../../../../actions/helpers/orderedGroupedItems';
import { updateShopItem } from '../../../../actions/shopItems';
import { SPACING, BOX_SHADOW } from '../../../../styles/constants';
import { useTheme } from '../../../../hooks';

const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
`;

const CategoryRowWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  padding: ${SPACING[200]} 0px;
`;

const CategoryRowHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: ${SPACING[100]};
`;

const CategoryRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  overflow: scroll;
  gap: ${SPACING[200]};
  padding: ${SPACING[100]} 0px;
`;

const ItemCard = styled.div`
  width: max-content;
  padding: ${SPACING[200]};
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid
    ${({ theme, active }) => (!!active ? theme.primaryColor : theme.lightGray)};
  border-radius: 4px;
  box-shadow: ${BOX_SHADOW.base};
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    box-shadow: ${BOX_SHADOW.md};
  }
`;

const PasteModal = props => {
  const { isOpen, onClose, items, type, selected } = props;
  const theme = useTheme();
  const isSupplements = type === 'supplements';
  const orderedItems = orderedGroupedItems(
    items.filter(item => !item.isMenu && item.id !== selected.id)
  );
  const [isLoading, setLoading] = React.useState(false);
  const [target, setTarget] = React.useState(null);

  const onSubmit = async () => {
    if (target) {
      setLoading(true);
      const updatedValue = isSupplements
        ? {
            supplements: uniqBy(
              [...selected.supplements, ...(target.supplements || [])],
              'id'
            ),
          }
        : {
            options: uniqBy(
              [...selected.options, ...(target.options || [])],
              'id'
            ),
          };
      const updatedItem = {
        ...target,
        ...updatedValue,
      };
      await updateShopItem(updatedItem);
      setLoading(false);
    }
    onClose();
  };

  const onSelectTarget = item => {
    if (!!target && target.id === item.id) {
      setTarget(null);
    } else {
      setTarget(item);
    }
  };

  return (
    <Dialog maxWidth="md" open={!!isOpen} onClose={onClose}>
      <DialogTitle>
        Coller les {isSupplements ? 'suppléments' : 'options'}
      </DialogTitle>

      <DialogContent dividers style={{ position: 'relative' }}>
        {isLoading && <Backdrop isContentBackdrop />}
        <div>
          <Text size={500} muted>
            Choisissez dans quel produit vous souhaitez coller les{' '}
            {isSupplements ? 'suppléments' : 'options'}.
          </Text>
          <br />
          <Text size={500} muted>
            Si votre produit contient déjà des{' '}
            {isSupplements ? ' suppléments' : ' options'}, alors{' '}
            {isSupplements ? ' ils ' : ' elles '} seront fusionnés.
          </Text>
        </div>
        <Container maxWidth="100%" paddingH={'0px'} paddingV={SPACING[200]}>
          {orderedItems.map(({ category, items }, index) => (
            <React.Fragment key={`${index}-${category}`}>
              <CategoryRowWrapper key={index}>
                <CategoryRowHeader>
                  <Text size={500}>{category}</Text>
                </CategoryRowHeader>
                <CategoryRow>
                  {items.map(item => (
                    <ItemCard
                      key={item.id}
                      theme={theme}
                      active={!!target && target.id === item.id}
                      onClick={() => onSelectTarget(item)}
                    >
                      {!!target && target.id === item.id ? (
                        <CheckIcon
                          color={theme.primaryColor}
                          size="1.2rem"
                          margin={`0 ${SPACING[100]} 0 0`}
                        />
                      ) : (
                        <CircleIcon
                          color={theme.lightGray}
                          size="1.2rem"
                          margin={`0 ${SPACING[100]} 0 0`}
                        />
                      )}

                      <Text size={500}>{item.name}</Text>
                    </ItemCard>
                  ))}
                </CategoryRow>
              </CategoryRowWrapper>
              <Divider style={{ width: '100%' }} />
            </React.Fragment>
          ))}
        </Container>
      </DialogContent>

      <DialogActions>
        <Button small onClick={onClose} minimal disabled={isLoading}>
          Fermer
        </Button>
        <Button
          small
          type="submit"
          primary
          onClick={onSubmit}
          isLoading={isLoading}
          disabled={isLoading || !target}
        >
          Confirmer
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default PasteModal;

import React from 'react';
import Firebase from 'firebase/app';
import { useHistory } from 'react-router-dom';
import { Switch } from '@material-ui/core';
import { ImpulseSpinner } from 'react-spinners-kit';
import {
  useCollection,
  useCollectionData,
} from 'react-firebase-hooks/firestore';

import { orderedGroupedItems } from '../../../../../actions/helpers/orderedGroupedItems';
import { updateShopItem } from '../../../../../actions/shopItems';
import {
  BlockContentData,
  RoleDetailsWrapper,
  ListLoader,
  ItemsListDialogWrapper,
  ItemsListDialogHeader,
  ContentBlockDialog,
} from '../../../../../styles/admin';
import {
  LineWrapper,
  ItemImage,
  ItemContent,
  ItemHeader,
  ItemDetails,
  ItemFooter,
  MenuDetails,
  MenuDetailsItem,
} from '../../../../../styles/basket';
import ProductLine from '../../../../basket/ProductLine';
import MenuLine from '../../../../basket/MenuLine';
import OrderCard from '../../../../account/orders/OrderCard';
import { Text, Button, Heading } from '../../../../../styleguide';
import { ShopIcon, DashboardIcon } from '../../../../../styleguide/icons';
import { SPACING } from '../../../../../styles/constants';
import { useTheme } from '../../../../../hooks';
import { parseNumber } from '../../../../../common';

const HeadingRowData = props => {
  const { title, details, text, dataColor } = props;

  return (
    <BlockContentData>
      <Text muted size={400} styles={{ padding: SPACING[100] }}>
        {title}
      </Text>
      {text !== null && !details ? (
        <Text size={500} color={dataColor} styles={{ padding: SPACING[100] }}>
          {text}
        </Text>
      ) : null}
      {details || null}
    </BlockContentData>
  );
};

const HeadingDetails = props => {
  const { children } = props;

  const theme = useTheme();

  return (
    <Text
      size={300}
      style={{ color: theme.textColor, padding: SPACING[100], paddingLeft: 0 }}
    >
      {children}
    </Text>
  );
};

const ValueDetails = props => {
  const { children } = props;

  const theme = useTheme();

  return (
    <Text
      size={400}
      style={{
        color: theme.headingColor,
        padding: SPACING[100],
        paddingRight: 0,
      }}
    >
      {children}
    </Text>
  );
};

/**
 * Buttons to navigate to essentials pages of each kind of data
 */
const RoleDetailsButton = props => {
  const { type, userID, shopID, isLoading } = props;

  const history = useHistory();
  const theme = useTheme();

  if (type === 'rusherProfil') {
    return (
      <Button
        small
        minimal
        onClick={() => history.push(`/rusher/profil/${userID}`)}
        startIcon={<DashboardIcon color={theme.headingColor} size="16px" />}
        isLoading={isLoading}
      >
        Voir Profil
      </Button>
    );
  }

  if (type === 'shopDashboard') {
    return (
      <Button
        small
        minimal
        onClick={() => history.push(`/shopDashboard/${shopID}`)}
        startIcon={<DashboardIcon color={theme.headingColor} size="16px" />}
        styles={{ marginLeft: '8px' }}
        isLoading={isLoading}
      >
        Voir Dashboard
      </Button>
    );
  }

  if (type === 'shopProfil') {
    return (
      <Button
        small
        minimal
        onClick={() => history.push(`/shop/${shopID}`)}
        startIcon={<ShopIcon color={theme.headingColor} size="16px" />}
        isLoading={isLoading}
      >
        Voir Établissement
      </Button>
    );
  }

  return null;
};

const ShopRolesDetailsDialog = props => {
  const { user } = props;

  const [shopVal, shopLoading, shopError] = useCollection(
    Firebase.firestore()
      .collection('shops')
      .where(`ownerID`, '==', user.id),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (shopLoading) {
    return (
      <RoleDetailsWrapper>
        <RoleDetailsButton
          type="shopProfil"
          user={user}
          isLoading={shopLoading}
        />
        <RoleDetailsButton
          type="shopDashboard"
          user={user}
          isLoading={shopLoading}
        />
      </RoleDetailsWrapper>
    );
  }

  if (shopError) {
    throw new Error(shopError.toString());
  }

  const shop = !!shopVal.docs.length
    ? { ...shopVal.docs[0].data(), id: shopVal.docs[0].id }
    : { id: '' };

  return (
    <RoleDetailsWrapper>
      <RoleDetailsButton type="shopProfil" user={user} shopID={shop.id} />
      <RoleDetailsButton type="shopDashboard" user={user} shopID={shop.id} />
    </RoleDetailsWrapper>
  );
};

const RolesDetailsDialog = props => {
  const { type, user, shop, rusher } = props;

  if (type === 'shop-owner') {
    return <ShopRolesDetailsDialog user={user} />;
  }

  if (type === 'shop') {
    return (
      <RoleDetailsWrapper>
        <RoleDetailsButton type="shopProfil" shopID={shop.id} />
        <RoleDetailsButton type="shopDashboard" shopID={shop.id} />
      </RoleDetailsWrapper>
    );
  }

  return (
    <RoleDetailsWrapper>
      <RoleDetailsButton type="rusherProfil" userID={rusher.id} />
    </RoleDetailsWrapper>
  );
};

/**
 * List order for each kind of profils (user / shop / rusher)
 */

const RusherOrdersListDialog = props => {
  const { rusher } = props;
  const theme = useTheme();

  const [orders, ordersLoading, ordersError] = useCollectionData(
    Firebase.firestore()
      .collection('orders')
      .where(`rusher.id`, '==', rusher.id)
      .orderBy('orderAt', 'desc'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (ordersLoading) {
    return (
      <ItemsListDialogWrapper>
        <ItemsListDialogHeader>
          <Heading size={600}>Les commandes - (...)</Heading>
        </ItemsListDialogHeader>
        <ListLoader>
          <ImpulseSpinner size={34} color={theme.primaryColor} loading />
        </ListLoader>
      </ItemsListDialogWrapper>
    );
  }

  if (ordersError) {
    throw new Error(ordersError.toString());
  }

  if (!orders || !orders.length) {
    return null;
  }

  return (
    <ItemsListDialogWrapper>
      <ItemsListDialogHeader>
        <Heading size={600}>
          Les commandes - ({!!orders ? orders.length : '0'})
        </Heading>
      </ItemsListDialogHeader>
      {orders.map(order => (
        <OrderCard order={order} key={order.id} maxWidth="350px" rusherCard />
      ))}
    </ItemsListDialogWrapper>
  );
};

const ShopOrdersListCardItem = props => {
  const { shopItem, theme, shop } = props;

  const [isInSelection, setIsInSelection] = React.useState(
    shopItem.isInWeeklySelection
  );

  const onItemSelectionUpdate = () => {
    updateShopItem({ ...shopItem, isInWeeklySelection: isInSelection });
  };

  return (
    <ContentBlockDialog theme={theme} maxWidth={'300px'}>
      <LineWrapper>
        <ItemImage small imageSRC={shopItem.pictureURL} theme={theme} />
        <ItemContent>
          <ItemHeader>
            <Heading size={500}>{shopItem.name}</Heading>
          </ItemHeader>
          <ItemDetails>
            <Text size={300} muted>
              {shopItem.description}
              {shopItem.supplements && shopItem.supplements.length
                ? shopItem.supplements.map((supp, i) => ` |> ${supp.name}`)
                : null}
            </Text>
          </ItemDetails>
          <ItemFooter flexEnd>
            <Text
              style={{ color: theme.primaryColor, fontWeight: 500 }}
              size={500}
            >
              {parseNumber(shopItem.price).toFixed(2)}
              {' €'}
            </Text>
          </ItemFooter>
        </ItemContent>
      </LineWrapper>
      <LineWrapper align="center" justify="space-between">
        <Text size={400} muted>
          Séléction:
        </Text>

        <Switch
          checked={isInSelection}
          onChange={event => setIsInSelection(event.target.checked)}
          color="primary"
          name={`Selection`}
          id="shop-is-in-selection-switch"
          inputProps={{ 'aria-label': 'Selection checkbox' }}
          disabled={shop.isValidated === '❌'}
        />

        <Button
          primary
          small
          onClick={onItemSelectionUpdate}
          disabled={isInSelection === shopItem.isInWeeklySelection}
        >
          Confirmer
        </Button>
      </LineWrapper>
    </ContentBlockDialog>
  );
};

const ShopOrdersListCardMenu = props => {
  const { shopItem, theme, shop } = props;
  const [isInSelection, setIsInSelection] = React.useState(
    shopItem.isInWeeklySelection
  );

  const onItemSelectionUpdate = () => {
    updateShopItem({ ...shopItem, isInWeeklySelection: isInSelection });
  };

  return (
    <ContentBlockDialog theme={theme} maxWidth={'300px'}>
      <LineWrapper>
        <ItemImage small={false} imageSRC={shopItem.pictureURL} theme={theme} />
        <ItemContent>
          <ItemHeader>
            <Heading size={500}>{shopItem.name} - Menu</Heading>
          </ItemHeader>
          <ItemDetails>
            <Text muted>{shopItem.description}</Text>
          </ItemDetails>
          <MenuDetails withoutPadding>
            {!!shopItem.categories
              ? shopItem.categories.map((category, index) => (
                  <MenuDetailsItem key={index}>
                    <Text
                      size={300}
                      style={{ margin: '4px 0', color: theme.headingColor }}
                    >
                      - {category}
                    </Text>
                  </MenuDetailsItem>
                ))
              : null}
          </MenuDetails>
          <ItemFooter flexEnd>
            <Text
              style={{ color: theme.primaryColor, fontWeight: 500 }}
              size={500}
            >
              {parseNumber(shopItem.price).toFixed(2)}
              {' €'}
            </Text>
          </ItemFooter>
        </ItemContent>
      </LineWrapper>
      <LineWrapper align="center" justify="space-between">
        <>
          <Text size={400} muted>
            Séléction:
          </Text>

          <Switch
            checked={isInSelection}
            onChange={event => setIsInSelection(event.target.checked)}
            color="primary"
            name={`Selection`}
            id="shop-is-in-selection-switch"
            inputProps={{ 'aria-label': 'Selection checkbox' }}
            disabled={shop.isValidated === '❌'}
          />
        </>

        <Button
          primary
          small
          onClick={onItemSelectionUpdate}
          disabled={isInSelection === shopItem.isInWeeklySelection}
        >
          Confirmer
        </Button>
      </LineWrapper>
    </ContentBlockDialog>
  );
};

const ShopOrdersListDialog = props => {
  const { shop } = props;
  const theme = useTheme();

  const [shopItems, shopItemsLoading, shopItemsError] = useCollectionData(
    Firebase.firestore()
      .collection('shopItems')
      .where(`shopID`, '==', shop.id),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (shopItemsLoading) {
    return (
      <ItemsListDialogWrapper>
        <ItemsListDialogHeader>
          <Heading size={600}>Les produits - (0)</Heading>
        </ItemsListDialogHeader>
        <ListLoader>
          <ImpulseSpinner size={34} color={theme.primaryColor} loading />
        </ListLoader>
      </ItemsListDialogWrapper>
    );
  }

  if (shopItemsError) {
    throw new Error(shopItemsError.toString());
  }

  if (!shopItems || !shopItems.length) {
    return null;
  }

  const orderedItems = orderedGroupedItems(shopItems);

  return (
    <ItemsListDialogWrapper>
      <ItemsListDialogHeader>
        <Heading size={600}>Les produits</Heading>
      </ItemsListDialogHeader>
      {orderedItems.map((group, index) => (
        <React.Fragment key={index}>
          <ItemsListDialogHeader>
            <Heading size={500} color={theme.textColor}>
              {group.category} - ({group.items.length})
            </Heading>
          </ItemsListDialogHeader>
          {group.items.map(shopItem => {
            if (!!shopItem.isMenu) {
              return (
                <ShopOrdersListCardMenu
                  shopItem={shopItem}
                  theme={theme}
                  key={shopItem.id}
                  shop={shop}
                />
              );
            }
            return (
              <ShopOrdersListCardItem
                shopItem={shopItem}
                theme={theme}
                key={shopItem.id}
                shop={shop}
              />
            );
          })}
        </React.Fragment>
      ))}
    </ItemsListDialogWrapper>
  );
};

const UserOrdersListDialog = props => {
  const { user } = props;
  const theme = useTheme();

  const [orders, ordersLoading, ordersError] = useCollectionData(
    Firebase.firestore()
      .collection('orders')
      .where(`clientID`, '==', user.id)
      .orderBy('orderAt', 'desc'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  if (ordersLoading) {
    return (
      <ListLoader>
        <ImpulseSpinner size={34} color={theme.primaryColor} loading />
      </ListLoader>
    );
  }

  if (ordersError) {
    throw new Error(ordersError.toString());
  }

  if (!orders.length) {
    return null;
  }

  return (
    <ItemsListDialogWrapper>
      <ItemsListDialogHeader>
        <Heading size={600}>Les commandes - ({orders.length})</Heading>
      </ItemsListDialogHeader>
      {orders.map(order => (
        <OrderCard order={order} key={order.id} maxWidth="350px" />
      ))}
    </ItemsListDialogWrapper>
  );
};

const OrderItemsListDialog = props => {
  const { order } = props;
  const theme = useTheme();
  const { items } = order;

  return (
    <ItemsListDialogWrapper>
      <ItemsListDialogHeader>
        <Heading size={600}>Les produits - ({items.length})</Heading>
      </ItemsListDialogHeader>
      {items.map(shopItem => {
        if (!!shopItem.item.isMenu) {
          return (
            <ContentBlockDialog
              theme={theme}
              maxWidth={'300px'}
              key={shopItem.id}
            >
              <MenuLine shopItem={shopItem} key={shopItem.id} />
            </ContentBlockDialog>
          );
        }
        return (
          <ContentBlockDialog
            theme={theme}
            maxWidth={'300px'}
            key={shopItem.id}
          >
            <ProductLine shopItem={shopItem} key={shopItem.id} />
          </ContentBlockDialog>
        );
      })}
    </ItemsListDialogWrapper>
  );
};

const ItemsListDialog = props => {
  const { kind, user, shop, rusher, order } = props;

  if (kind === 'user') {
    return <UserOrdersListDialog user={user} />;
  }

  if (kind === 'rusher') {
    return <RusherOrdersListDialog rusher={rusher} />;
  }

  if (kind === 'shop') {
    return <ShopOrdersListDialog shop={shop} />;
  }

  if (kind === 'order') {
    return <OrderItemsListDialog order={order} />;
  }

  return null;
};

export {
  HeadingRowData,
  HeadingDetails,
  ValueDetails,
  RolesDetailsDialog,
  ItemsListDialog,
};

import React from 'react';
import { useUnstated } from '@gitbook/unstated';
import { Fab } from '@material-ui/core';
import { isMobile } from 'react-device-detect';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useTheme } from '../../hooks';
import { parseNumber } from '../../common';
import { Heading, Button } from '../../styleguide';
import {
  FloatingBasketResumeWrapper,
  FloatingBasketResumeCard,
  BasketResumeHeader,
  BasketResumeList,
  ButtonsWrapper,
  FloatingBasketContainer,
} from '../../styles/page';
import { BasketIcon } from '../../styleguide/icons';
import { SPACING } from '../../styles/constants';
import { BasketContainer } from '../../contexts';
import ProductLine from '../basket/ProductLine';
import MenuLine from '../basket/MenuLine';

const FloatingBasketResume = props => {
  const { isShopProfilPage } = props;
  const [displayed, setDisplayed] = React.useState(false);

  const { t } = useTranslation(['basket', 'common']);
  const theme = useTheme();
  const history = useHistory();
  const basketContainer = useUnstated(BasketContainer);
  const { total, itemsList, shop } = basketContainer.state;
  const isCurrentShop = !!shop && isShopProfilPage === shop.id;
  const isDefaultDisplayed = !isMobile && !!isCurrentShop;

  React.useEffect(() => {
    if (isDefaultDisplayed) {
      setDisplayed(true);
    }
  }, [isDefaultDisplayed]);

  return (
    <FloatingBasketContainer>
      <FloatingBasketResumeWrapper>
        {displayed && (
          <FloatingBasketResumeCard
            backgroundColor={theme.backgroundColor}
            theme={theme}
          >
            <BasketResumeHeader theme={theme}>
              <Heading size={600}>{t('basket:floating:title')} : </Heading>
              <Heading size={600} color={theme.primaryColor}>
                {parseNumber(total).toFixed(2)}€
              </Heading>
            </BasketResumeHeader>
            <BasketResumeList theme={theme}>
              {itemsList.map((shopItem, index) =>
                shopItem.item.isMenu ? (
                  <MenuLine
                    key={index}
                    shopItem={shopItem}
                    onDelete={() => basketContainer.deleteItem(shopItem)}
                    withoutDescription
                    small
                  />
                ) : (
                  <ProductLine
                    key={index}
                    shopItem={shopItem}
                    onDelete={() => basketContainer.deleteItem(shopItem)}
                    withoutDescription
                    small
                  />
                )
              )}
            </BasketResumeList>

            <ButtonsWrapper>
              {!isCurrentShop && (
                <Button
                  small
                  variant="outlined"
                  minimal
                  onClick={() => history.push(`/shop/${shop.id}`)}
                  styles={{ marginRight: SPACING[100] }}
                >
                  {t('common:continue')}
                </Button>
              )}
              <Button small onClick={() => history.push('/order/new')}>
                {isCurrentShop
                  ? t('basket:floating:button_alt')
                  : t('basket:floating:button')}
              </Button>
            </ButtonsWrapper>
          </FloatingBasketResumeCard>
        )}
        <Fab
          variant="extended"
          color="primary"
          aria-label="panier"
          onClick={() => setDisplayed(!displayed)}
          style={{ pointerEvents: 'auto', zIndex: 100 }}
        >
          <BasketIcon size="16px" color={theme.white} margin={'0 8px 0 0'} />
          {`${t('basket:floating:title')} : ${parseNumber(
            basketContainer.state.total
          ).toFixed(2)}€`}
        </Fab>
      </FloatingBasketResumeWrapper>
    </FloatingBasketContainer>
  );
};

export default FloatingBasketResume;

import styled from 'styled-components';
import { SPACING } from './constants';

const MainWrapper = styled.div`
  max-width: 700px;
  width: 100%;
  display: flex;
  justify-content: ${props => (props.centered ? 'center' : 'flex-start')};
  align-items: ${props => (props.centered ? 'center' : 'flex-start')};
  flex-direction: column;
  margin: 0 auto;
  padding: ${props => (props.padding ? `0 ${SPACING[100]}` : 'initial')};
`;

const AccountHeader = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const AvatarWrapper = styled.div`
  padding: 10px;
  border-radius: 100%;
  background-color: ${props => props.color};
  margin-bottom: ${SPACING[200]};
`;

const DetailsWrapper = styled.div`
  width: 100%;
  max-width: 700px;
  padding: ${SPACING[400]} 0;
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-direction: column;
`;

const LineWrapper = styled.div`
  flex: 1;
  width: 100%;
  padding: ${props => props.padding || `${SPACING[100]} 0`};
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TextWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  margin: ${SPACING[100]};
`;

const BannerField = styled.div`
  width: 100%;
  height: 150px;
  border-radius: 4px;
  background: url(${props => props.src});
  background-size: cover;
  background-position: center;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  padding: ${SPACING[200]};
  margin-top: ${SPACING[100]};
  box-shadow: ${props => props.theme.boxShadow.md};
`;

const CardLinksWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: ${SPACING[100]};
  margin-top: ${SPACING[400]};
`;

export {
  AccountHeader,
  AvatarWrapper,
  DetailsWrapper,
  LineWrapper,
  TextWrapper,
  BannerField,
  MainWrapper,
  CardLinksWrapper,
};

import React from 'react';

import { RowsContainer, RowWrapper } from '../../../../styles/shoptabs';
import { Heading, ConfirmDialog, Container } from '../../../../styleguide';
import { useTheme } from '../../../../hooks';

import { FlexBlocksContainer, BlocksContainer } from '../../../../styles/admin';
import {
  Overview,
  RushersBlock,
  ShopsBlock,
  UsersBlock,
  OrdersBlock,
  DiscountsBlock,
} from '../components/home';
import DashboardHeader from '../components/common/Header';
import { Page } from '../../../common';

const HomeDashboard = () => {
  const [confirm, setConfirm] = React.useState(null);
  const theme = useTheme();

  return (
    <Page name="Dashboard Home">
      <Container justify="flex-start">
        <DashboardHeader title="Tableau de bord" />

        <RowsContainer>
          <RowWrapper theme={theme}>
            <Heading size={800}>Vue d'ensemble</Heading>

            <BlocksContainer>
              <Overview setDialog={setConfirm} />
            </BlocksContainer>

            <Heading size={800}>Dashboards</Heading>
            <BlocksContainer>
              <UsersBlock />
              <OrdersBlock />
              <DiscountsBlock />
              <ShopsBlock />
              <RushersBlock />
            </BlocksContainer>
          </RowWrapper>
        </RowsContainer>

        {confirm && (
          <ConfirmDialog
            isOpen={!!confirm}
            onClose={() => setConfirm(null)}
            onConfirm={confirm.onConfirm}
            title={confirm.title}
            description={confirm.description}
          />
        )}
      </Container>
    </Page>
  );
};

export default HomeDashboard;

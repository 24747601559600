import React from 'react';
import {
  Select,
  MenuItem,
  FormControl,
  Chip,
  Avatar,
  Switch,
} from '@material-ui/core';
import parsePhoneNumber from 'libphonenumber-js';
import { useReactToPrint } from 'react-to-print';

import {
  HeaderDialog,
  BodyBlocksDialog,
  ContentBlockDialog,
  HeaderDialogBlock,
  HeaderDialogFlexBlock,
} from '../../../../../styles/admin';
import { parseNumber } from '../../../../../common';
import {
  Text,
  Heading,
  ExternalHoverableLink,
  PartnerBadge,
  Input,
  FullscreenDialog,
  Button,
} from '../../../../../styleguide';
import { PrintIcon } from '../../../../../styleguide/icons';
import { SPACING } from '../../../../../styles/constants';
import { useTheme } from '../../../../../hooks';
import { RolesDetailsDialog, ItemsListDialog } from './Skeletons';
import OrderReceipt from './OrderReceipt';

const OrderDialog = props => {
  const { order, children, ...rest } = props;
  const theme = useTheme();
  const ref = React.useRef(null);
  const { orderDB } = order;

  const handlePrint = useReactToPrint({
    content: () => ref.current,
    onPrintError: all => console.error({ all }),
  });

  return (
    <FullscreenDialog title="Commande" theme={theme} {...rest}>
      <HeaderDialog>
        <HeaderDialogBlock>
          <Heading size={700} styles={{ paddingBottom: SPACING[100] }}>
            {order.data.name}
            <Chip label={order.data.status} />
          </Heading>
          <Text size={500} muted>
            {order.data.shopName} - {order.data.id}
          </Text>
        </HeaderDialogBlock>
      </HeaderDialog>
      <RolesDetailsDialog type="shop" shop={{ id: order.data.shopID }} />
      {order.data.rusherDetails !== 'Pas encore de livreur' &&
      !order.data.rusherDetails.isAdmin ? (
        <RolesDetailsDialog type="rusher" rusher={{ id: order.data.rusher }} />
      ) : null}
      <BodyBlocksDialog>
        {order.data.rusherDetails !== 'Pas encore de livreur' ? (
          <ContentBlockDialog theme={theme}>
            <Text size={400} muted styles={{ paddingBottom: 2 }}>
              Livreur:
            </Text>
            <Chip
              variant="outlined"
              color="primary"
              label={order.data.rusherDetails.name}
              avatar={<Avatar src={order.data.rusherDetails.pictureURL} />}
            />
          </ContentBlockDialog>
        ) : null}
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            ID client:
          </Text>
          <Text size={500}>{order.data.clientID}</Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            ID shop:
          </Text>
          <Text size={500}>{order.data.shopID}</Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            ID livreur:
          </Text>
          <Text size={500}>{order.data.rusher}</Text>
        </ContentBlockDialog>
      </BodyBlocksDialog>

      <BodyBlocksDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Paiement :
          </Text>
          <ExternalHoverableLink href={order.data.paymentURL}>
            Voir le compte
          </ExternalHoverableLink>{' '}
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Frais livraison :
          </Text>
          <Text size={500}>
            {parseNumber(order.data.deliveryFees).toFixed(2)}€
          </Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Frais app :
          </Text>
          <Text size={500}>{parseNumber(order.data.appFees).toFixed(2)}€</Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Montant :
          </Text>
          <Text size={500}>{parseNumber(order.data.total).toFixed(2)}€</Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Promotion :
          </Text>
          <Text size={500}>{order.data.discount}</Text>
        </ContentBlockDialog>
        {order.data.status === 'Livré' && (
          <ContentBlockDialog theme={theme}>
            <Button
              small
              onClick={handlePrint}
              minimal
              variant="outlined"
              styles={{ marginRight: SPACING[100] }}
              startIcon={<PrintIcon color={theme.headingColor} size="16px" />}
            >
              Imprimer le reçu
            </Button>
          </ContentBlockDialog>
        )}
      </BodyBlocksDialog>

      <BodyBlocksDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Crée à :
          </Text>
          <Text size={500}>{order.data.orderAt}</Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Prêt à :
          </Text>
          <Text size={500}>{order.data.readyAt}</Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Récupéré à :
          </Text>
          <Text size={500}>{order.data.pickedAt}</Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Livré à :
          </Text>
          <Text size={500}>{order.data.deliverAt}</Text>
        </ContentBlockDialog>
        {order.data.canceledBy !== '--' && (
          <ContentBlockDialog theme={theme}>
            <Text size={400} muted>
              Annulé par :
            </Text>
            <Text size={500}>{order.data.canceledBy}</Text>
          </ContentBlockDialog>
        )}
      </BodyBlocksDialog>
      {order.data.status === 'Livré' && (
        <div style={{ display: 'none' }}>
          <OrderReceipt
            ref={ref}
            order={orderDB}
            orderAt={order.data.orderAt}
            readyAt={order.data.readyAt}
            deliverAt={order.data.deliverAt}
          />
        </div>
      )}
      <ItemsListDialog kind="order" order={order.data} />
    </FullscreenDialog>
  );
};

const ShopDialog = props => {
  const { shop, setEditDialog, children, ...rest } = props;
  const theme = useTheme();

  return (
    <FullscreenDialog
      theme={theme}
      title="Modification Établissement"
      {...rest}
    >
      <HeaderDialog>
        <Avatar
          src={shop.data.logoURL}
          style={{ width: 48, height: 48, marginRight: SPACING[200] }}
        />
        <HeaderDialogBlock>
          <HeaderDialogFlexBlock style={{ paddingBottom: SPACING[100] }}>
            <Heading size={700} styles={{ paddingRight: SPACING[100] }}>
              {shop.data.name}{' '}
            </Heading>
            {shop.data.isPartner === '✅' ? (
              <PartnerBadge theme={theme} />
            ) : null}
          </HeaderDialogFlexBlock>

          <Text size={500} muted>
            {shop.data.id}
          </Text>
        </HeaderDialogBlock>
      </HeaderDialog>

      <RolesDetailsDialog type="shop" shop={shop.data} />

      <BodyBlocksDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Ouvert
          </Text>
          <Text size={500}>{shop.data.available}</Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Téléphone
          </Text>
          <Text size={500}>{shop.data.phone}</Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Stripe
          </Text>
          {!!shop.data.stripeURL ? (
            <ExternalHoverableLink href={shop.data.stripeURL}>
              Voir le compte
            </ExternalHoverableLink>
          ) : (
            <Text size={500}>En cours d'inscription</Text>
          )}
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Propriétaire
          </Text>
          <Text size={500}>{shop.data.owner}</Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            ID Stripe
          </Text>
          <Text size={500}>{shop.data.stripeCustomerId}</Text>
        </ContentBlockDialog>
      </BodyBlocksDialog>
      <BodyBlocksDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Commission:
          </Text>

          <FormControl style={{ width: '100px' }}>
            <Input
              type="number"
              size="small"
              variant="outlined"
              required
              placeholder="`15.5%"
              fullWidth
              inputProps={{
                style: {
                  fontSize: '14px',
                },
                step: '0.1',
                min: '1',
                max: '25',
              }}
              value={
                !shop.updatedData
                  ? shop.data.percentage
                  : shop.updatedData.percentage
              }
              onChange={event =>
                setEditDialog({
                  ...shop,
                  updatedData: {
                    ...shop.updatedData,
                    percentage: event.target.value,
                  },
                })
              }
            />
          </FormControl>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Vérifié:
          </Text>

          <Switch
            checked={
              !!shop.updatedData && !!shop.updatedData.isValidated
                ? shop.updatedData.isValidated === '✅'
                : shop.data.isValidated === '✅'
            }
            onChange={event =>
              setEditDialog({
                ...shop,
                updatedData: {
                  ...shop.updatedData,
                  isValidated: event.target.checked ? '✅' : '❌',
                },
              })
            }
            color="primary"
            name={`Vérifié`}
            id="shop-isVerified-switch"
            inputProps={{ 'aria-label': 'Verified checkbox' }}
          />
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Favoris:
          </Text>

          <Switch
            checked={
              !!shop.updatedData && !!shop.updatedData.isPartner
                ? shop.updatedData.isPartner === '✅'
                : shop.data.isPartner === '✅'
            }
            onChange={event =>
              setEditDialog({
                ...shop,
                updatedData: {
                  ...shop.updatedData,
                  isPartner: event.target.checked ? '✅' : '❌',
                },
              })
            }
            color="primary"
            name={`Favoris`}
            id="shop-partner-switch"
            inputProps={{ 'aria-label': 'Favoris checkbox' }}
          />
        </ContentBlockDialog>
      </BodyBlocksDialog>
      <ItemsListDialog kind="shop" shop={shop.data} />
      {children}
    </FullscreenDialog>
  );
};

const UserDialog = props => {
  const { user, setEditDialog, children, ...rest } = props;
  const theme = useTheme();

  return (
    <FullscreenDialog theme={theme} title="Modification Utilisateur" {...rest}>
      <HeaderDialog>
        <HeaderDialogBlock>
          <Heading size={700} styles={{ paddingBottom: SPACING[100] }}>
            {user.data.name} <Chip label={user.data.role} />
          </Heading>
          <Text size={500} muted>
            {user.data.id}
          </Text>
        </HeaderDialogBlock>
      </HeaderDialog>
      {user.data.role === 'shop-owner' ? (
        <RolesDetailsDialog type="shop-owner" user={user.data} />
      ) : null}
      {user.data.role === 'rusher' ? (
        <RolesDetailsDialog type="rusher" rusher={user.data} />
      ) : null}

      <BodyBlocksDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            ID
          </Text>
          <Text size={500}>{user.data.id}</Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Email
          </Text>
          <Text size={500}>{user.data.email}</Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Téléphone
          </Text>
          <Text size={500}>
            {parsePhoneNumber(user.data.phone, 'FR').format('INTERNATIONAL') ||
              user.data.phone}
          </Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Stripe
          </Text>
          {user.data.stripeURL !== 'Aucun paiement' ? (
            <ExternalHoverableLink href={user.data.stripeURL}>
              Voir le compte
            </ExternalHoverableLink>
          ) : (
            <Text size={500}>Pas encore client</Text>
          )}
        </ContentBlockDialog>
      </BodyBlocksDialog>
      <BodyBlocksDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Role:
          </Text>
          <FormControl style={{ width: '120px' }}>
            <Select
              value={!user.updatedData ? user.data.role : user.updatedData.role}
              onChange={event =>
                setEditDialog({
                  ...user,
                  updatedData: { role: event.target.value },
                })
              }
            >
              <MenuItem value={'client'}>Client</MenuItem>
              <MenuItem value={'shop-owner'}>Propriétaire</MenuItem>
              <MenuItem value={'admin'}>Admin</MenuItem>
              <MenuItem value={'rusher'}>Livreur</MenuItem>
            </Select>
          </FormControl>{' '}
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Crée le
          </Text>
          <Text size={500}>{user.data.createdAt}</Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Mis à jour le
          </Text>
          <Text size={500}>{user.data.updatedAt}</Text>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Activé
          </Text>
          <Text size={500}>{user.data.verified}</Text>
        </ContentBlockDialog>
      </BodyBlocksDialog>
      <ItemsListDialog kind="user" user={user.data} />
    </FullscreenDialog>
  );
};

const RusherDialog = props => {
  const { rusher, setEditDialog, children, ...rest } = props;
  const theme = useTheme();

  return (
    <FullscreenDialog theme={theme} title="Modification Livreur" {...rest}>
      <HeaderDialog>
        <Avatar
          src={rusher.data.pictureURL}
          style={{ width: 48, height: 48, marginRight: SPACING[200] }}
        />
        <HeaderDialogBlock>
          <HeaderDialogFlexBlock style={{ paddingBottom: SPACING[100] }}>
            <Heading size={700} styles={{ paddingRight: SPACING[100] }}>
              {rusher.data.name}{' '}
            </Heading>
          </HeaderDialogFlexBlock>

          <Text size={500} muted>
            {rusher.data.id}
          </Text>
        </HeaderDialogBlock>
      </HeaderDialog>

      <RolesDetailsDialog type="rusher" rusher={rusher.data} />

      <BodyBlocksDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Actif
          </Text>
          <Text size={500}>{rusher.data.isWorked}</Text>
        </ContentBlockDialog>

        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Ville départ
          </Text>
          <Text size={500}>{rusher.data.baseCity}</Text>
        </ContentBlockDialog>

        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Téléphone
          </Text>
          <Text size={500}>{rusher.data.phone}</Text>
        </ContentBlockDialog>
      </BodyBlocksDialog>

      <BodyBlocksDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Commission:
          </Text>
          <FormControl style={{ width: '100px' }}>
            <Input
              type="number"
              size="small"
              variant="outlined"
              required
              placeholder="`15.5%"
              fullWidth
              inputProps={{
                style: {
                  fontSize: '14px',
                },
                step: '0.1',
                min: '1',
                max: '20',
              }}
              value={
                !rusher.updatedData
                  ? rusher.data.percentage
                  : rusher.updatedData.percentage
              }
              onChange={event =>
                setEditDialog({
                  ...rusher,
                  updatedData: {
                    ...rusher.updatedData,
                    percentage: event.target.value,
                  },
                })
              }
            />
          </FormControl>
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Vérifié:
          </Text>
          <Switch
            checked={
              !!rusher.updatedData && !!rusher.updatedData.isVerified
                ? rusher.updatedData.isVerified === '✅'
                : rusher.data.isVerified === '✅'
            }
            onChange={event =>
              setEditDialog({
                ...rusher,
                updatedData: {
                  ...rusher.updatedData,
                  isVerified: event.target.checked ? '✅' : '❌',
                },
              })
            }
            color="primary"
            name={`Vérifié`}
            id="switch-rusher-verified"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Soumis TVA:
          </Text>
          <Switch
            checked={
              !!rusher.updatedData && !!rusher.updatedData.isPaidVAT
                ? rusher.updatedData.isPaidVAT === '✅'
                : rusher.data.isPaidVAT === '✅'
            }
            onChange={event =>
              setEditDialog({
                ...rusher,
                updatedData: {
                  ...rusher.updatedData,
                  isPaidVAT: event.target.checked ? '✅' : '❌',
                },
              })
            }
            color="primary"
            name={`Vérifié`}
            id="switch-rusher-payedVAT"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </ContentBlockDialog>
        <ContentBlockDialog theme={theme}>
          <Text size={400} muted>
            Activé:
          </Text>
          <Switch
            checked={
              !!rusher.updatedData && !!rusher.updatedData.available
                ? rusher.updatedData.available === '✅'
                : rusher.data.available === '✅'
            }
            onChange={event =>
              setEditDialog({
                ...rusher,
                updatedData: {
                  ...rusher.updatedData,
                  available: event.target.checked ? '✅' : '❌',
                },
              })
            }
            color="primary"
            name={`Activé`}
            id="switch-rusher-available"
            inputProps={{ 'aria-label': 'primary checkbox' }}
          />
        </ContentBlockDialog>
      </BodyBlocksDialog>
      {children}
      <ItemsListDialog kind="rusher" rusher={rusher.data} />
    </FullscreenDialog>
  );
};

export { OrderDialog, UserDialog, ShopDialog, RusherDialog };

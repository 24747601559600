import styled from 'styled-components';
import { SPACING } from './constants';

const MainWrapper = styled.div`
  max-width: 700px;
  width: 100%;
  display: flex;
  justify-content: ${props => (props.centered ? 'center' : 'flex-start')};
  align-items: ${props => (props.centered ? 'center' : 'flex-start')};
  flex-direction: column;
  margin: 0 auto;
  padding: ${props => (props.padding ? `0 ${SPACING[100]}` : 'initial')};
`;

const OrdersBlock = styled.div`
  width: 100%;
  padding: ${SPACING[200]};
`;

const OrdersWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin: ${SPACING[200]} auto;
`;

const OrderCardPicture = styled.div`
  background: url(${props => props.src});
  width: 120px;
  height: 112px;
  background-position: center;
  background-size: cover;
  border-radius: 4px;
`;

const ContentWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`;

const DetailsWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  margin-left: ${SPACING[100]};

  ${({ overrides }) => overrides};
`;

const LineWrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: ${props => (props.wrap ? 'wrap' : 'no-wrap')};
`;

export {
  OrdersWrapper,
  MainWrapper,
  OrdersBlock,
  ContentWrapper,
  DetailsWrapper,
  OrderCardPicture,
  LineWrapper,
};

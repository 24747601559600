// the main routes of our app are defined here using react-router
// https://reacttraining.com/react-router/web/example/basic

import React from 'react';
import { Switch, Route } from 'react-router-dom';
import PrivateRoute from './PrivateRoute';

import HomePage from '../views/home/HomePage';
import ActivationPage, { MailPage, PhonePage } from '../views/activation';

import Account from '../views/account/profil/Account';
import PaymentMethods from '../views/account/payment-methods/PaymentMethods';
import CreatePaymentMethod from '../views/account/payment-methods/CreatePaymentMethod';
import AddressesList from '../views/account/addresses/AddressesList';
import Settings from '../views/account/settings/Settings';
import OrdersHistory from '../views/account/orders/OrdersHistory';
import OrderTracking from '../views/account/orders/tracking/OrderTracking';
import ActiveOrders from '../views/account/orders/ActiveOrders.js';

import ErrorPage from '../views/misc/ErrorPage';
import Login from '../views/login/Login';
import Signup from '../views/signup/Signup';
import PasswordReset from '../views/login/PasswordReset';

import NewOrder from '../views/order/NewOrder';
import Basket from '../views/basket/Basket';

import RusherProfil from '../views/rusher/RusherProfil';
import RusherOrders from '../views/rusher/RusherOrders';
import RusherSignup from '../views/rusher/RusherSignup';
import RusherBilling from '../views/rusher/RusherBilling';

import AdminOrders from '../views/admin/orders';
import AdminDashboardHome from '../views/admin/dashboard/pages/home';
import AdminDashboardUsers from '../views/admin/dashboard/pages/users';
import AdminDashboardOrders from '../views/admin/dashboard/pages/orders';
import AdminDashboardShops from '../views/admin/dashboard/pages/shops';
import AdminDashboardRushers from '../views/admin/dashboard/pages/rushers';
import AdminDashboardDiscounts from '../views/admin/dashboard/pages/discounts';

import Shop from '../views/shop/Shop';
import ShopOrdersFollow from '../views/shop/ShopOrdersFollow';
import CreateShop from '../views/shop/CreateShop';
import ShopProfil from '../views/shop/ShopProfil';
import StripeOAuth from '../views/shop/StripeOAuth';

const ADMIN_ROUTES = [
  <PrivateRoute
    kind="admin"
    exact
    path="/admin/orders"
    component={AdminOrders}
    name="AdminOrders"
  />,
  <PrivateRoute
    kind="admin"
    exact
    path="/admin/dashboard"
    component={AdminDashboardHome}
    name="AdminDashboard"
  />,
  <PrivateRoute
    kind="admin"
    exact
    path="/admin/dashboard/users"
    component={AdminDashboardUsers}
    name="AdminDashboardUsers"
  />,
  <PrivateRoute
    kind="admin"
    exact
    path="/admin/dashboard/orders"
    component={AdminDashboardOrders}
    name="AdminDashboardOrders"
  />,
  <PrivateRoute
    kind="admin"
    exact
    path="/admin/dashboard/shops"
    component={AdminDashboardShops}
    name="AdminDashboardShops"
  />,
  <PrivateRoute
    kind="admin"
    exact
    path="/admin/dashboard/rushers"
    component={AdminDashboardRushers}
    name="AdminDashboardRushers"
  />,
  <PrivateRoute
    kind="admin"
    exact
    path="/admin/dashboard/discounts"
    component={AdminDashboardDiscounts}
    name="AdminDashboardDiscounts"
  />,
];

const SHOP_ROUTES = [
  <PrivateRoute
    kind="shop"
    exact
    path="/shop/new"
    component={CreateShop}
    name="CreateShop"
  />,
  <PrivateRoute
    kind="shop"
    exact
    path="/shop/stripe/accountCreation"
    component={StripeOAuth}
    name="StripeOAuth"
  />,
  <PrivateRoute
    exact
    path="/shop/:id"
    component={ShopProfil}
    name="ShopProfil"
  />,
  <PrivateRoute
    kind="shop"
    exact
    path="/shopDashboard/orders/:id?"
    component={ShopOrdersFollow}
    name="ShopOrdersFollow"
  />,
  <PrivateRoute
    kind="shop"
    exact
    path="/shopDashboard/:id?"
    component={Shop}
    name="Shop"
  />,
];

const RUSHER_ROUTES = [
  <PrivateRoute
    exact
    path="/rusher/signup"
    component={RusherSignup}
    name="RusherSignup"
    kind="rusher"
  />,
  <PrivateRoute
    exact
    path="/rusher/profil/:id?"
    component={RusherProfil}
    name="RusherProfil"
    kind="rusher"
  />,
  <PrivateRoute
    exact
    path="/rusher/orders/:id?"
    component={RusherOrders}
    name="RusherOrders"
    kind="rusher"
  />,
  <PrivateRoute
    exact
    path="/rusher/billing"
    component={RusherBilling}
    name="RusherBilling"
    kind="rusher"
  />,
];

const Routes = () => {
  const MANAGE_ROUTES = [ADMIN_ROUTES, SHOP_ROUTES, RUSHER_ROUTES];

  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route exact path="/signup" component={Signup} />
      <Route exact path="/reset_password" component={PasswordReset} />

      {/* Main page */}
      <PrivateRoute exact path="/" component={HomePage} name="HomePage" />

      {/* Page for not-verified */}
      <PrivateRoute
        exact
        path="/activation"
        component={ActivationPage}
        name="NotVerifiedPage"
      />
      <PrivateRoute
        exact
        path="/activation/phone"
        component={PhonePage}
        name="PhonePage"
      />
      <PrivateRoute
        exact
        path="/activation/mail"
        component={MailPage}
        name="MailPage"
      />

      {/* Users account routes */}
      <PrivateRoute exact path="/account" component={Account} name="Account" />
      <PrivateRoute
        exact
        path="/account/adresses"
        component={AddressesList}
        name="AddressesList"
      />
      <PrivateRoute
        exact
        path="/account/payment_methods"
        component={PaymentMethods}
        name="Payment methods"
      />
      <PrivateRoute
        exact
        path="/account/payment_methods/create"
        component={CreatePaymentMethod}
        name="Create Payment Method"
      />
      <PrivateRoute
        exact
        path="/account/orders"
        component={ActiveOrders}
        name="Active orders"
      />
      <PrivateRoute
        exact
        path="/account/orders/history"
        component={OrdersHistory}
        name="Orders"
      />
      <PrivateRoute
        exact
        path="/account/orders/:id"
        component={OrderTracking}
        name="OrderTracking"
      />
      <PrivateRoute
        exact
        path="/account/settings"
        component={Settings}
        name="Settings"
      />

      {/* Orders related routes */}
      <PrivateRoute exact path="/basket" component={Basket} name="Basket" />
      <PrivateRoute
        exact
        path="/order/new"
        component={NewOrder}
        name="NewOrder"
      />

      {MANAGE_ROUTES}

      {/* Public routes */}
      <Route component={ErrorPage} />
    </Switch>
  );
};

export default Routes;

import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

import { prepareDocForCreate } from '../../helpers/firestoreHelpers';
import createID from '../../helpers/createID';
import targetedOrdersMetaDataUpdate from './updateOrdersBillingStatus';

const createInvoice = async data => {
  // console.log('Create invoice called', { data });
  ReactGA.event({
    category: 'Rusher',
    action: 'Create invoice',
  });

  const ordersList = data.selectedOrders;

  delete data.selectedOrders;

  const ordersIdsList = ordersList.map(order => order.id);

  const invoice = {
    ...data,
    id: createID(),
    status: 'pending', // pending, sent, paid, cancelled
    generatedAt: Date.now(),
    orders: ordersIdsList,
  };

  try {
    await Firebase.firestore()
      .collection('rushersInvoices')
      .doc(invoice.id)
      .set(prepareDocForCreate(invoice));

    // console.log('Document successfully written!', { invoiceResult });

    await targetedOrdersMetaDataUpdate(ordersList, {
      isBilled: true,
      invoiceId: invoice.id,
    });

    // console.log('Targeted orders succesfully written', {
    //   targetedOrdersUpdate,
    // });
  } catch (error) {
    console.error(`Whoops, couldn't create the rusher: ${error.message}`, {
      data,
    });
  }
};

export default createInvoice;

import React from 'react';
import { InputAdornment, Switch, FormControlLabel } from '@material-ui/core';
import { useFormik } from 'formik';

import { FormWrapper } from '../../../../styles/form';
import {
  HeaderDialog,
  ContentBlockDialog,
  HeaderDialogFlexBlock,
} from '../../../../styles/admin';
import { useTheme } from '../../../../hooks';
import { SPACING } from '../../../../styles/constants';
import {
  FlexFieldsWrapper,
  AccordionDetailsContent,
  AccordionDetails,
  AccordionSummary,
  Accordion,
} from '../../../../styles/shop';
import { Input, FullscreenDialog, Heading, Text } from '../../../../styleguide';
import {
  EuroIcon,
  ExpandIcon,
  PercentageIcon,
} from '../../../../styleguide/icons';
import CompositionForm from './CompositionForm';
import { menuSchema } from '../../../../forms';

const MenuModal = props => {
  const { onSubmit, onClose, categories, initialValue, open } = props;
  const theme = useTheme();
  const initialMenu = initialValue || {
    name: '',
    price: '',
    avaibility: true,
    category: 'Menus',
    pictureURL: '',
    preparationTime: 5,
    isMenu: true,
    description: '',
    categories: [],
    VAT: 20,
  };

  const formik = useFormik({
    initialValues: initialMenu,
    validationSchema: menuSchema,
    onSubmit: values => {
      onSubmit(values);
      onClose();
    },
  });

  const inputsStyle = { minWidth: '200px' };

  const canSubmit =
    !!formik.dirty && !formik.isSubmitting && !!formik.values.categories.length;

  return (
    <FullscreenDialog
      isOpen={open}
      onClose={onClose}
      onConfirm={formik.handleSubmit}
      title={
        !!initialValue?.name ? `Modification d'un menu` : "Création d'un menu"
      }
      cantConfirm={!canSubmit}
      isLoading={false}
      theme={theme}
      buttonText={!initialValue?.name ? 'Créer' : 'Enregistrer'}
      styles={{}}
    >
      <HeaderDialog
        style={{ maxWidth: '1260px', margin: '0 auto', padding: SPACING[100] }}
      >
        <Text muted size={400}>
          Lors de la création de votre menu, vous pouvez lui attribuer une liste
          de categorie. Cette liste de categorie correspond au choix des
          produits de ce menu. <br />
          Cela vous donne la fléxibilité pour créer les menu qui correspondent à
          vos besoins.
        </Text>
      </HeaderDialog>

      <FormWrapper
        style={{ margin: '0 auto', padding: 0, paddingTop: SPACING[100] }}
        maxWidth="1260px"
      >
        <Accordion square style={{ width: '100%' }} defaultExpanded>
          <AccordionSummary
            expandIcon={<ExpandIcon size="16px" />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            <Heading size={500}>Informations</Heading>
          </AccordionSummary>
          <AccordionDetails>
            <AccordionDetailsContent>
              <FlexFieldsWrapper align="stretch">
                <ContentBlockDialog theme={theme}>
                  <HeaderDialogFlexBlock
                    style={{ paddingBottom: SPACING[100] }}
                  >
                    <Heading size={500} styles={{ paddingRight: SPACING[100] }}>
                      Actions
                    </Heading>
                  </HeaderDialogFlexBlock>
                  <FormControlLabel
                    control={
                      <Switch
                        name="avaibility"
                        checked={formik.values.avaibility}
                        onChange={formik.handleChange}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        error={
                          formik.touched.avaibility &&
                          Boolean(formik.errors.avaibility)
                        }
                        helperText={
                          formik.touched.avaibility && formik.errors.avaibility
                        }
                      />
                    }
                    label={
                      <Text size={400} muted>
                        Disponible
                      </Text>
                    }
                  />
                </ContentBlockDialog>
                <ContentBlockDialog theme={theme}>
                  <HeaderDialogFlexBlock
                    style={{ paddingBottom: SPACING[100] }}
                  >
                    <Heading size={500} styles={{ paddingRight: SPACING[100] }}>
                      Catégorie
                    </Heading>
                  </HeaderDialogFlexBlock>
                  <Input
                    name="category"
                    wrapperProps={{ justify: 'flex-start' }}
                    label="Catégorie"
                    size="small"
                    variant="outlined"
                    required
                    placeholder="Cheeseburger"
                    fullWidth
                    value={formik.values.category}
                    style={inputsStyle}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.category && Boolean(formik.errors.category)
                    }
                    helperText={
                      formik.touched.category && formik.errors.category
                    }
                  />
                </ContentBlockDialog>
                <ContentBlockDialog theme={theme}>
                  <HeaderDialogFlexBlock
                    style={{ paddingBottom: SPACING[100] }}
                  >
                    <Heading size={500} styles={{ paddingRight: SPACING[100] }}>
                      Temps préparation (mn)
                    </Heading>
                  </HeaderDialogFlexBlock>
                  <HeaderDialogFlexBlock>
                    <Input
                      name="preparationTime"
                      wrapperProps={{ justify: 'flex-start', maxWidth: 400 }}
                      label="Temps"
                      size="small"
                      variant="outlined"
                      required
                      type="number"
                      placeholder="10"
                      fullWidth
                      value={formik.values.preparationTime}
                      onChange={formik.handleChange}
                      style={{ maxWidth: '140px' }}
                      inputProps={{
                        min: '0',
                      }}
                      error={
                        formik.touched.preparationTime &&
                        Boolean(formik.errors.preparationTime)
                      }
                      helperText={
                        formik.touched.preparationTime &&
                        formik.errors.preparationTime
                      }
                    />
                  </HeaderDialogFlexBlock>
                </ContentBlockDialog>
                <ContentBlockDialog theme={theme}>
                  <HeaderDialogFlexBlock
                    style={{ paddingBottom: SPACING[100] }}
                  >
                    <Heading size={500} styles={{ paddingRight: SPACING[100] }}>
                      Tarification
                    </Heading>
                  </HeaderDialogFlexBlock>
                  <HeaderDialogFlexBlock>
                    <Input
                      name="price"
                      wrapperProps={{ justify: 'flex-start' }}
                      label="Prix TTC"
                      size="small"
                      variant="outlined"
                      required
                      placeholder="5.50"
                      fullWidth
                      type="number"
                      value={formik.values.price}
                      onChange={formik.handleChange}
                      style={{ maxWidth: '120px' }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <EuroIcon size="16px" />
                          </InputAdornment>
                        ),
                      }}
                      error={
                        formik.touched.price && Boolean(formik.errors.price)
                      }
                      helperText={formik.touched.price && formik.errors.price}
                    />
                    <Input
                      name="VAT"
                      wrapperProps={{ justify: 'flex-start' }}
                      label="TVA"
                      size="small"
                      variant="outlined"
                      required
                      placeholder="5.5"
                      fullWidth
                      type="number"
                      value={formik.values.VAT}
                      onChange={formik.handleChange}
                      style={{ maxWidth: '120px' }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">
                            <PercentageIcon size="16px" />
                          </InputAdornment>
                        ),
                      }}
                      error={formik.touched.VAT && Boolean(formik.errors.VAT)}
                      helperText={formik.touched.VAT && formik.errors.VAT}
                    />
                  </HeaderDialogFlexBlock>
                </ContentBlockDialog>
              </FlexFieldsWrapper>
              <FlexFieldsWrapper>
                <FlexFieldsWrapper>
                  <Input
                    name="name"
                    wrapperProps={{ justify: 'flex-start' }}
                    label="Titre"
                    size="small"
                    variant="outlined"
                    required
                    placeholder="Cheeseburger"
                    fullWidth
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    style={inputsStyle}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />
                  <Input
                    name="pictureURL"
                    wrapperProps={{ justify: 'flex-start' }}
                    label="Photo"
                    size="small"
                    variant="outlined"
                    placeholder="cheeseburger.png"
                    fullWidth
                    value={formik.values.pictureURL}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.pictureURL &&
                      Boolean(formik.errors.pictureURL)
                    }
                    helperText={
                      formik.touched.pictureURL && formik.errors.pictureURL
                    }
                    style={inputsStyle}
                  />
                </FlexFieldsWrapper>

                <FlexFieldsWrapper>
                  <Input
                    name="description"
                    wrapperProps={{ justify: 'flex-start' }}
                    label="Description"
                    size="small"
                    variant="outlined"
                    required
                    multiline
                    rows={4}
                    fullWidth
                    value={formik.values.description}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.description &&
                      Boolean(formik.errors.description)
                    }
                    helperText={
                      formik.touched.description && formik.errors.description
                    }
                    style={inputsStyle}
                  />
                </FlexFieldsWrapper>
              </FlexFieldsWrapper>
            </AccordionDetailsContent>
          </AccordionDetails>
        </Accordion>

        <CompositionForm
          currentSelectedCategories={formik.values.categories}
          categories={categories}
          onCategoriesChange={selectedCategories =>
            formik.setFieldValue('categories', selectedCategories)
          }
        />
      </FormWrapper>
    </FullscreenDialog>
  );
};

export default MenuModal;

import React from 'react';
import { TextField } from '@material-ui/core';
import { useFormik } from 'formik';
import parsePhoneNumber from 'libphonenumber-js';
import { useTranslation } from 'react-i18next';

import { colors } from '../../../styles/constants';
import { SecondaryTextWrapper, ContentWrapper } from '../../../styles/login';
import {
  Button,
  Heading,
  Text,
  HoverableLink,
  CheckBox,
  ExternalHoverableLink,
} from '../../../styleguide';
import { SignupFormPolicyText } from '../../../styles/signup';
import {
  FormWrapper,
  ButtonsWrapper,
  FieldWrapper,
} from '../../../styles/form';
import { useSession } from '../../../hooks';
import { signupGoogleUserIdentity, signupUserIdentity } from '../../../forms';
import SignupStepper from './SignupStepper';

const parsePhoneBeforeSubmit = phone => {
  if (!phone) {
    return '';
  }
  return parsePhoneNumber(phone, 'FR').format('INTERNATIONAL');
};

const SignupForm = props => {
  const { credentialsData, onCredentialsUpdate, onNext, currentStep } = props;
  const { isLoggedIn } = useSession();
  const [hasAccept, setAccepted] = React.useState(false);
  const { t } = useTranslation(['signup', 'common']);

  const formik = useFormik({
    initialValues: {
      name: credentialsData.name || '',
      email: credentialsData.email || '',
      phone: credentialsData.phone || '',
      pwd: '',
    },
    validationSchema: !!isLoggedIn
      ? signupGoogleUserIdentity
      : signupUserIdentity,
    onSubmit: values => {
      onCredentialsUpdate({
        email: values.email,
        pwd: values.pwd,
        name: values.name,
        phone: parsePhoneBeforeSubmit(values.phone),
      });
      onNext();
    },
    validateOnBlur: true,
  });

  const canSubmit =
    !!formik.values.name &&
    !!formik.values.phone &&
    !!hasAccept &&
    (isLoggedIn || (!!formik.values.pwd && !!formik.values.email));

  return (
    <ContentWrapper>
      <Heading size={900} centered style={{ color: colors.black }}>
        {`${t('signup:informations:title')} 🤗`}
      </Heading>
      <SecondaryTextWrapper>
        <Text size={500} centered muted style={{ color: colors.grey }}>
          {t('signup:informations:already_have_account')}{' '}
          <HoverableLink to="/login" color={colors.green}>
            {t('signup:informations:login')}
          </HoverableLink>
        </Text>
      </SecondaryTextWrapper>
      <SignupStepper currentStep={currentStep} />
      <FormWrapper
        onSubmit={formik.handleSubmit}
        margin={`0 auto`}
        paddingV={0}
      >
        {!isLoggedIn && (
          <>
            <FieldWrapper>
              <TextField
                name="email"
                label={t('signup:informations:email')}
                size="small"
                variant="outlined"
                required
                placeholder="john@doe.com"
                type="text"
                fullWidth
                value={formik.values.email}
                onChange={formik.handleChange}
                error={formik.touched.email && Boolean(formik.errors.email)}
                helperText={formik.touched.email && formik.errors.email}
              />
            </FieldWrapper>

            <FieldWrapper>
              <TextField
                name="pwd"
                label={t('signup:informations:password')}
                required
                placeholder="*********"
                type="password"
                size="small"
                variant="outlined"
                fullWidth
                value={formik.values.pwd}
                onChange={formik.handleChange}
                error={formik.touched.pwd && Boolean(formik.errors.pwd)}
                helperText={formik.touched.pwd && formik.errors.pwd}
              />
            </FieldWrapper>
          </>
        )}

        <FieldWrapper>
          <TextField
            name="name"
            label={t('signup:informations:name')}
            size="small"
            variant="outlined"
            required
            placeholder="John Doe"
            fullWidth
            value={formik.values.name}
            onChange={formik.handleChange}
            error={formik.touched.name && Boolean(formik.errors.name)}
            helperText={formik.touched.name && formik.errors.name}
          />
        </FieldWrapper>

        <FieldWrapper>
          <TextField
            name="phone"
            label={t('signup:informations:phone')}
            required
            placeholder="06 01 02 03 04"
            type="phone"
            size="small"
            variant="outlined"
            fullWidth
            value={formik.values.phone}
            onChange={formik.handleChange}
            error={formik.touched.phone && Boolean(formik.errors.phone)}
            helperText={formik.touched.phone && formik.errors.phone}
          />
        </FieldWrapper>

        <SignupFormPolicyText>
          <CheckBox
            name="accept-cgpsu"
            checked={hasAccept}
            onChange={isChecked => setAccepted(isChecked)}
            required
            wrapperProps={{
              padding: 0,
            }}
            style={{ padding: 0 }}
          />
          <Text size={500} muted style={{ color: colors.grey }}>
            {t('signup:informations:cgu')}{' '}
            <ExternalHoverableLink href="https://www.delyzon.com/cgpsu">
              {t('signup:informations:cgu_link')}
            </ExternalHoverableLink>
          </Text>
        </SignupFormPolicyText>

        <ButtonsWrapper>
          <Button type="submit" disabled={!canSubmit}>
            {t('common:continue')}
          </Button>
        </ButtonsWrapper>
      </FormWrapper>
    </ContentWrapper>
  );
};

export default SignupForm;

import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Tabs as MaterialTabs, Tab as MaterialTab } from '@material-ui/core';

import { useTheme } from '../hooks';

const StyledTabs = withStyles({
  indicator: {
    display: 'flex',
    justifyContent: 'center',
    backgroundColor: 'transparent',
    '& > span': {
      maxWidth: 40,
      width: '100%',
      backgroundColor: props => props.theme.primaryColor,
    },
  },
})(props => (
  <MaterialTabs
    {...props}
    TabIndicatorProps={{ children: <span /> }}
    variant="fullWidth"
  />
));

const StyledTab = withStyles({
  root: {
    textTransform: 'none',
    fontWeight: 400,
    fontSize: '16px',
    color: props => props.theme.headingColor,
    '&:focus': {
      opacity: 1,
    },
  },
})(props => <MaterialTab disableRipple {...props} />);

const useStyles = makeStyles({
  container: {
    width: '100%',
    margin: '0 auto',
    backgroundColor: props => props.theme.backgroundColor,
    border: `1px solid ${props => props.theme.lightGray}`,
  },
});

const Tab = props => {
  const { children, ...rest } = props;
  const theme = useTheme();

  return (
    <StyledTab theme={theme} {...rest}>
      {children}
    </StyledTab>
  );
};

const Tabs = props => {
  const { value, handleChange, children, ...rest } = props;
  const theme = useTheme();
  const classes = useStyles({ theme });

  return (
    <div className={classes.container}>
      <StyledTabs value={value} onChange={handleChange} {...rest} theme={theme}>
        {children}
      </StyledTabs>
    </div>
  );
};

export { Tabs, Tab };

import React from 'react';
import { Heading as HeadingUI } from 'evergreen-ui';
import { useTheme } from '../hooks';

const Heading = props => {
  const { size, children, styles, centered, color, ...rest } = props;
  const theme = useTheme();

  return (
    <HeadingUI
      size={size}
      style={{
        color: color || theme.headingColor,
        textAlign: centered ? 'center' : 'left',
        ...styles,
      }}
      {...rest}
    >
      {children}
    </HeadingUI>
  );
};

export default Heading;

import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const StyledLink = styled(Link)`
  color: ${props => props.color};
  text-decoration: none;
`;

const NavLink = props => {
  const { to, color, children, ...rest } = props;

  return (
    <StyledLink color={color} to={to} {...rest}>
      {children}
    </StyledLink>
  );
};

export default NavLink;

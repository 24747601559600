import React from 'react';
import { Stepper, Step, StepLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const SignupStepper = props => {
  const { currentStep } = props;
  const { t } = useTranslation(['signup']);

  return (
    <Stepper
      activeStep={currentStep}
      style={{
        maxWidth: '400px',
        margin: `0 auto`,
      }}
      alternativeLabel
    >
      <Step
        key={t('signup:stepper:step_1')}
        active={currentStep === 1}
        completed={currentStep === 2}
      >
        <StepLabel>{`${t('signup:stepper:step_1')}`}</StepLabel>
      </Step>
      <Step
        key={t('signup:stepper:step_2')}
        active={currentStep === 2}
        completed={currentStep === 3}
      >
        <StepLabel>{`${t('signup:stepper:step_2')}`}</StepLabel>
      </Step>
    </Stepper>
  );
};

export default SignupStepper;

import React from 'react';

import { StatCard as StyledStatCard } from '../../../../../styles/admin';
import { Heading, Text } from '../../../../../styleguide';
import { useTheme } from '../../../../../hooks';

const StatCard = props => {
  const { label, value, children, small } = props;
  const theme = useTheme();

  return (
    <StyledStatCard theme={theme}>
      <Text size={300} styles={{ textTransform: 'uppercase' }}>
        {label}
      </Text>
      {children || (
        <Heading
          size={small ? 400 : 600}
          styles={{
            color: value > 0 ? theme.primaryColor : theme.textColor,
            textAlign: 'center',
          }}
        >
          {value}
        </Heading>
      )}
    </StyledStatCard>
  );
};

export default StatCard;

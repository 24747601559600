import React from 'react';
import { toaster } from 'evergreen-ui';
import ReactCodeInput from 'react-verification-code-input';
import { IconButton } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
  Button,
  Heading,
  Text,
  Container,
  SimpleHoverableAction,
  HoverableLink,
} from '../../styleguide';
import { FormWrapper, ButtonsWrapper, FieldWrapper } from '../../styles/form';
import { SPACING } from '../../styles/constants';
import { useTheme, useSession, useTimeout } from '../../hooks';
import { sendCode, verifyCode } from '../../actions/login';
import { Page } from '../common';
import { LogoutIcon } from '../../styleguide/icons';
import { logOut } from '../../actions/login';
import Validation from '../../assets/images/welcome.svg';
import {
  ContentWrapper,
  EmptyPlaceholder,
  EmptyPicture,
  EmptyPlaceholderDescription,
} from '../../styles/common';
import { updateUser } from '../../actions/users';

const getMinutesBetweenDates = (startDate, endDate) => {
  const diff = endDate - startDate;
  return diff / 6000;
};

const getCodeVerifDataAlreadySend = () => {
  const lsValue = window.localStorage.getItem('delyzon-phone-confirm-disabled');

  return lsValue
    ? {
        verifID: lsValue.split('_')[1],
        verifAt: lsValue.split('_')[0],
      }
    : {
        verifID: null,
        verifAt: null,
      };
};

const PhonePage = props => {
  const theme = useTheme();
  const history = useHistory();
  const { user, authUser } = useSession();
  const { t } = useTranslation(['auth', 'common']);

  const [code, setCode] = React.useState([]);
  const [error, setError] = React.useState(null);
  const [verificationID, setVerificationID] = React.useState(null);
  const [isLoading, setIsLoading] = React.useState(false);
  const [canResend, setCanResend] = React.useState(false);

  useTimeout(() => {
    if (!canResend) {
      setCanResend(!canResend);
    }
  }, 60000);

  React.useEffect(() => {
    if (!!user.isVerified || !!authUser.emailVerified) {
      history.push('/');
    }
  }, [user, history, authUser]);

  React.useEffect(() => {
    if (!!error) {
      toaster.danger(error.title, {
        description: error.message,
      });
    }
  }, [error]);

  const sendVerificationCode = async () => {
    try {
      setIsLoading(true);
      const { id, status } = await sendCode({ phone: user.phone });

      if (status === 'sent') {
        toaster.success('Code envoyé !', {
          description: 'Le code a été envoyé à votre numéro de téléphone.',
        });
        if (!!verificationID) {
          setCanResend(false);
        }

        setVerificationID(id);
        window.localStorage.setItem(
          'delyzon-phone-confirm-disabled',
          `${Date.now()}_${id}`
        );
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setError({
          title: "Erreur lors de l'envoi du code",
          description:
            'Veuillez réessayer et si le problème persiste, contactez-nous.',
        });
      }
    } catch (error) {
      setError({
        title: "Erreur lors de l'envoi du code",
        description: error,
      });
    }
  };

  const onResendCode = () => {
    const data = getCodeVerifDataAlreadySend();

    if (
      data.verifID
        ? getMinutesBetweenDates(data.verifAt, Date.now()) < 60
        : false
    ) {
      setVerificationID(null);
      window.localStorage.removeItem('delyzon-phone-confirm-disabled');
      return sendVerificationCode();
    }
  };

  const handleChange = value => {
    if (error) {
      setError(null);
    }

    if (value.length === 6) {
      setCode(value);
    }
  };

  const onSubmitForm = async event => {
    event.preventDefault();
    setIsLoading(true);
    // console.log(`Verification code given: ${code} - validCode: ${VALID_CODE}`);

    if (!!verificationID && !!code && code.length === 6) {
      const { status } = await verifyCode({ id: verificationID, token: code });

      if (status === 'verified') {
        await updateUser(
          { isVerified: true, verifiedBy: 'phone' },
          authUser.uid
        );
        history.push('/');
      } else {
        setError({
          title: 'Code invalide',
          message: 'Le code saisi est invalide ! Veuillez réessayer.',
        });
      }
      setIsLoading(false);
    }
  };

  const canSubmit = code.length === 6 && !!verificationID;

  return (
    <Page
      name={t('auth:phone:name')}
      navbarLeftChild={<></>}
      navbarRightChild={
        <IconButton aria-label="retour" onClick={logOut}>
          <LogoutIcon color={theme.headingColor} size="16px" />
        </IconButton>
      }
    >
      <Container>
        <ContentWrapper>
          <EmptyPlaceholder paddingBottom={0}>
            <EmptyPicture src={Validation} />
            <Heading styles={{ marginTop: SPACING[300] }} size={900} centered>
              {t('auth:phone:title')} <br />
              <span style={{ color: theme.primaryColor }}>{user.phone}</span>
            </Heading>
            <EmptyPlaceholderDescription>
              <Text muted>
                {!verificationID && (
                  <>
                    {t('auth:phone:subtitle1')}
                    <br />
                  </>
                )}
                {t('auth:phone:subtitle2')}
              </Text>
            </EmptyPlaceholderDescription>
            <EmptyPlaceholderDescription>
              <div style={{ paddingBottom: SPACING[200] }}>
                <Text muted>
                  {t('auth:phone:email_activation')}{' '}
                  <HoverableLink
                    to="/activation/mail"
                    color={theme.primaryColor}
                  >
                    {t('auth:phone:email_activation_link')}
                  </HoverableLink>
                </Text>{' '}
              </div>
              {!!verificationID && canResend ? (
                <Text muted>
                  {t('auth:phone:not_received')}{' '}
                  <SimpleHoverableAction
                    onClick={onResendCode}
                    color={theme.primaryColor}
                  >
                    {t('auth:phone:not_received_cta')}
                  </SimpleHoverableAction>
                </Text>
              ) : null}
            </EmptyPlaceholderDescription>
          </EmptyPlaceholder>
          <FormWrapper paddingV={'0'} onSubmit={onSubmitForm}>
            {!!verificationID && (
              <>
                <Text muted>{t('auth:phone:code_input')}</Text>
                <FieldWrapper padding={`${SPACING[200]} ${SPACING[100]}`}>
                  <ReactCodeInput
                    fieldWidth={42}
                    fieldHeight={45}
                    values={code}
                    loading={false}
                    onChange={handleChange}
                    className="custom-phone-input"
                  />
                </FieldWrapper>
              </>
            )}

            <ButtonsWrapper>
              {!verificationID ? (
                <Button
                  disabled={isLoading}
                  isLoading={isLoading}
                  onClick={sendVerificationCode}
                >
                  {t('auth:phone:cta_get_code')}
                </Button>
              ) : (
                <Button
                  type="submit"
                  disabled={!canSubmit || isLoading}
                  isLoading={isLoading}
                >
                  {t('auth:phone:cta_submit')}
                </Button>
              )}
            </ButtonsWrapper>
          </FormWrapper>
        </ContentWrapper>
      </Container>
    </Page>
  );
};

export default PhonePage;

import React from 'react';
import Firebase from 'firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';
import { DataGrid } from '@material-ui/data-grid';

import { ShopDialog } from '../common/Dialogs';
import { BlockWrapper } from '../../../../../styles/admin';
import { updateShop, setShopAsPartner } from '../../../../../actions/shop';
import { useTheme } from '../../../../../hooks';
import BlockLayout from '../common/BlockLayout';
import StatCard from '../common/StatCard';
import BlockHeader from '../common/BlockHeader';

const {
  REACT_APP_STRIPE_DASHBOARD_URL,
  REACT_APP_FIREBASE_WEBAPP_URL,
} = process.env;

const columns = [
  {
    field: 'name',
    headerName: 'Nom',
    width: 200,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 300,
  },
  {
    field: 'available',
    headerName: 'Ouvert',
    width: 110,
    align: 'center',
  },
  {
    field: 'isValidated',
    headerName: 'Vérifié 🔐',
    width: 120,
    align: 'center',
  },
  {
    field: 'isPartner',
    headerName: 'Favoris 🥇',
    width: 140,
    align: 'center',
  },
  {
    field: 'owner',
    headerName: 'Propriétaire',
    width: 300,
    align: 'center',
  },
  {
    field: 'percentage',
    headerName: 'Commission 💸',
    width: 150,
    align: 'center',
    valueFormatter: ({ value }) => `${value}%`,
  },
  {
    field: 'stripeCustomerId',
    headerName: 'ID Stripe',
    width: 300,
  },
  {
    field: 'stripeURL',
    headerName: 'Compte Stripe',
    width: 200,
  },
  {
    field: 'profilURL',
    headerName: 'Profil - app',
    width: 200,
  },
  {
    field: 'dashboardURL',
    headerName: 'Dashboard - app',
    width: 200,
  },
];

const ShopBlock = props => {
  const [editDialog, setEditDialog] = React.useState(null);
  const [isLoading, setLoading] = React.useState(false);
  const theme = useTheme();

  const [shopsDocs, shopsLoading, shopsError] = useCollection(
    Firebase.firestore()
      .collection('shops')
      .where('isValidated', '==', true)
      .orderBy('available', 'desc'),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const onRowClick = params => {
    setEditDialog({ updatedData: null, data: params.row });
  };

  const onConfirmShopUpdate = async () => {
    if (editDialog.data !== editDialog.updatedData) {
      setLoading(true);

      updateShop({
        id: editDialog.data.id,
        ...(editDialog.updatedData.isValidated
          ? {
              isValidated:
                editDialog.updatedData.isValidated === '❌' ? false : true,
            }
          : {}),
        ...(!!editDialog.updatedData.percentage
          ? {
              percentage: editDialog.updatedData.percentage,
            }
          : {}),
      });

      if (!!editDialog.updatedData.isPartner) {
        await setShopAsPartner(
          editDialog.data.id,
          editDialog.updatedData.isPartner === '❌' ? false : true,
          editDialog.updatedData.percentage || editDialog.data.percentage
        );
      }
    }
    setLoading(false);
    setEditDialog(null);
  };

  const { rows, openendCounts, partnerCounts } = React.useMemo(() => {
    if (shopsLoading || !shopsDocs?.size || !!shopsError) {
      return {
        rows: [],
        openendCounts: 0,
        partnerCounts: 0,
      };
    }

    const shops = shopsDocs.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }));

    const rows = shops.map(shop => ({
      id: shop.id,
      name: shop.name,
      available: shop.available ? '✅' : '❌',
      owner: shop.ownerID,
      percentage: shop.percentage,
      stripeCustomerId: shop.stripeCustomerId,
      isValidated: shop.isValidated ? '✅' : '❌',
      isPartner: shop.isPartner ? '✅' : '❌',
      stripeURL: shop.stripeCustomerId
        ? `${REACT_APP_STRIPE_DASHBOARD_URL}/connect/accounts/${shop.stripeCustomerId}`
        : '',
      profilURL: `${REACT_APP_FIREBASE_WEBAPP_URL}/shop/${shop.id}`,
      dashboardURL: `${REACT_APP_FIREBASE_WEBAPP_URL}/shopDashboard/${shop.id}`,
      logoURL: shop.logoURL,
      phone: shop.legal.phone,
    }));

    const openendCounts = shops.filter(shop => shop.available).length;
    const partnerCounts = shops.filter(shop => shop.isPartner).length;

    return {
      rows,
      openendCounts,
      partnerCounts,
    };
  }, [shopsLoading, shopsDocs, shopsError]);

  if (shopsError) {
    throw new Error(shopsError.toString());
  }

  return (
    <BlockWrapper theme={theme}>
      <BlockHeader
        title="Les établissements"
        path="/admin/dashboard/shops"
        label={`Établissements validés uniquement`}
      />

      <BlockLayout
        statBlocks={
          <>
            <StatCard label={'Ouvert'} value={openendCounts} />
            <StatCard label={'Favoris'} value={partnerCounts} />
          </>
        }
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          loading={shopsLoading}
          onRowClick={onRowClick}
          stickyHeader
        />
      </BlockLayout>

      {editDialog ? (
        <ShopDialog
          shop={editDialog}
          isOpen={!!editDialog}
          onClose={() => setEditDialog(null)}
          setEditDialog={setEditDialog}
          onConfirm={onConfirmShopUpdate}
          cantConfirm={!editDialog.updatedData}
          isLoading={isLoading}
        />
      ) : null}
    </BlockWrapper>
  );
};

export default ShopBlock;

import styled from 'styled-components';
import { SPACING, devices } from './constants';

const ContentWrapper = styled.div`
  padding: ${props =>
    !props.withoutSpacing ? `${SPACING[100]}` : `${SPACING[100]} 0`};

  @media ${devices.mobileLG} {
    padding: ${props =>
      !props.withoutSpacing ? `${SPACING[200]}` : `${SPACING[200]} 0`};
  }
`;

const FloatingBasketContainer = styled.div`
  position: fixed;
  bottom: 0;
  right: 0;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  width: 100%;
  pointer-events: none;
  z-index: 100;
`;

const FloatingBasketResumeWrapper = styled.div`
  width: 100%;
  max-width: 1440px;
  padding: ${SPACING[300]} ${SPACING[200]};
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  margin: 0 auto;
`;

const FloatingBasketResumeCard = styled.div`
  width: 290px;
  padding: ${SPACING[200]};
  margin: ${SPACING[200]} 0;
  background-color: ${props => props.backgroundColor};
  border: 1px solid ${props => props.theme.lightGray};
  border-radius: 4px;
  box-shadow: ${props => props.theme.boxShadow.xl};
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  pointer-events: auto;
`;

const BasketResumeHeader = styled.div`
  width: 100%;
  padding-bottom: ${SPACING[100]};
  margin-bottom: ${SPACING[100]};
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${({ theme }) => theme.lightGray};
`;

const BasketResumeList = styled.div`
  width: 100%;
  padding-bottom: ${SPACING[100]};
  margin-bottom: ${SPACING[100]};
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  border-bottom: 1px solid ${({ theme }) => theme.lightGray};
  max-height: 450px;
  overflow: scroll;
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  padding: ${SPACING[100]};
  display: flex;
  align-items: center;
  justify-content: center;
`;

export {
  ContentWrapper,
  FloatingBasketResumeWrapper,
  FloatingBasketResumeCard,
  BasketResumeHeader,
  BasketResumeList,
  ButtonsWrapper,
  FloatingBasketContainer,
};

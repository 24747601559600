import styled from 'styled-components';
import { SPACING } from '../styles/constants';

const Container = styled.div`
  width: 100%;
  min-height: 70vh;
  display: flex;
  justify-content: ${props => props.justify || 'center'};
  align-items: ${props => props.justify || 'center'};
  flex-direction: column;
  padding-top: ${SPACING[300]};
`;

export default Container;

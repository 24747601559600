import React from 'react';
import Firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { Alert, AlertTitle } from '@material-ui/lab';

import ORDER_STATUS from '../../../actions/orders/helpers/STATUS';
import groupBy from '../../../actions/helpers/groupBy';
import { Tabs, Tab, Loader } from '../../../styleguide';
import { SPACING } from '../../../styles/constants';
import { useTheme } from '../../../hooks';
import NewsTab from './NewsTab';
import InProgressTab from './InProgressTab';
import DeliveryTab from './DeliveryTab';
import EndedTab from './EndedTab';

const RusherOrdersTabs = props => {
  const { rusher } = props;
  const [currentTab, setCurrentTab] = React.useState(0);

  const theme = useTheme();

  const [news, newsLoading, newsError] = useCollectionData(
    Firebase.firestore()
      .collection('orders')
      .where('status', 'in', [ORDER_STATUS.inProgress, ORDER_STATUS.ready])
      .where('rusher', '==', null)
      .orderBy('orderAt', 'desc')
      .limit(60),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [attributed, loadingAttributed, errorAttributed] = useCollectionData(
    Firebase.firestore()
      .collection('orders')
      .where('status', 'in', [
        ORDER_STATUS.inProgress,
        ORDER_STATUS.ready,
        ORDER_STATUS.inDelivery,
        ORDER_STATUS.delivered,
      ])
      .where('rusher.id', '==', rusher.id)
      .orderBy('orderAt', 'desc')
      .limit(100),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const onTabChange = (event, value) => {
    setCurrentTab(value);
  };

  if (newsLoading || loadingAttributed) {
    return (
      <Loader color={theme.primaryColor} background={theme.backgroundColor} />
    );
  }

  if (errorAttributed || newsError) {
    if (errorAttributed) {
      throw new Error(errorAttributed.toString());
    } else if (newsError) {
      throw new Error(newsError.toString());
    }
  }

  const groupedOrders = groupBy(attributed, 'status');
  const displayedOrders = {
    news: news,
    attributed: [],
    inDelivery: [],
    ended: [],
  };

  if (groupedOrders.length) {
    groupedOrders.forEach(group => {
      if (group.category === ORDER_STATUS.inProgress) {
        displayedOrders.attributed = [
          ...displayedOrders.attributed,
          ...group.items,
        ];
      } else if (group.category === ORDER_STATUS.ready) {
        displayedOrders.attributed = [
          ...group.items,
          ...displayedOrders.attributed,
        ];
      } else if (group.category === ORDER_STATUS.inDelivery) {
        displayedOrders.inDelivery = [...group.items];
      } else if (group.category === ORDER_STATUS.delivered) {
        displayedOrders.ended = [...group.items];
      }
    });
  }

  const hasCurrentOrders =
    displayedOrders.attributed.length || displayedOrders.inDelivery.length;

  return (
    <>
      <Tabs
        style={{ width: '100%', margin: `${SPACING[300]} 0` }}
        value={currentTab}
        onChange={onTabChange}
      >
        <Tab label={`Nouvelles (${displayedOrders.news.length})`} />
        <Tab label={`Attribué (${displayedOrders.attributed.length})`} />
        <Tab label={`Livraison (${displayedOrders.inDelivery.length})`} />
        <Tab label={`Terminés (${displayedOrders.ended.length})`} />
      </Tabs>

      {!rusher.isWorked && hasCurrentOrders && rusher.available ? (
        <Alert severity="info" style={{ margin: '8px auto' }}>
          <AlertTitle>Actuellement indisponible</AlertTitle>
          Même si vous êtes passez indisponible, n'oubliez de livrer vos
          commandes en cours 😇
        </Alert>
      ) : null}

      {!rusher.available ? (
        <Alert severity="warning" style={{ margin: '8px auto' }}>
          <AlertTitle>Compte suspendu {'⚠️'}</AlertTitle>
          Votre compte à temporairement était suspendu par notre équipe,
          contactez nous pour plus d'informations.
        </Alert>
      ) : null}

      {!rusher.isVerified ? (
        <Alert severity="info" style={{ margin: '8px auto' }}>
          <AlertTitle>En attente de validation</AlertTitle>
          Une fois votre compte validé par notre équipe, vous pourrez faire vos
          premières livraisons 💸
        </Alert>
      ) : null}

      <NewsTab
        value={currentTab}
        rusher={rusher}
        orders={displayedOrders.news}
      />
      <InProgressTab
        value={currentTab}
        rusher={rusher}
        orders={displayedOrders.attributed}
      />
      <DeliveryTab
        value={currentTab}
        rusher={rusher}
        orders={displayedOrders.inDelivery}
      />
      <EndedTab
        value={currentTab}
        rusher={rusher}
        orders={displayedOrders.ended}
      />
    </>
  );
};

export default RusherOrdersTabs;

import React from 'react';

import { Heading, Button, Text } from '../../../styleguide';
import { DescriptionWrapper } from '../../../styles/signup';
import { ButtonsWrapper } from '../../../styles/form';

const Welcome = props => {
  const { onNext } = props;

  return (
    <>
      <Heading size={900} centered>
        Création de votre profil de livreur
      </Heading>
      <DescriptionWrapper>
        <Text muted>
          Grâce à Delyzon, effectuez des livraisons quand vous le souhaitez, et
          générez un revenu en étant votre propre patron {'💸'}
        </Text>
      </DescriptionWrapper>
      <ButtonsWrapper>
        <Button styles={{ marginBottom: '8px' }} onClick={onNext}>
          Continuer
        </Button>
      </ButtonsWrapper>
    </>
  );
};

export default Welcome;

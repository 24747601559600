import * as yup from 'yup';

const ADMINUID_FIELD_VALIDATION = yup
  .string('adminUserID')
  .required('adminUID is required');

const CODE_FIELD_VALIDATION = yup
  .string('code')
  .transform(value => value.toUpperCase())
  .required('Le code est requis pour la réduction');

const DELIVERYFEESVALUE_FIELD_VALIDATION = yup
  .number('deliveryFeesValue')
  .required('La valeur de la réduction est requise');

const APPFEESVALUE_FIELD_VALIDATION = yup
  .number('appFeesValue')
  .required('La valeur de la réduction est requise');

const ACTIVE_FIELD_VALIDATION = yup.boolean('active').required();

const MINTOTAL_FIELD_VALIDATION = yup
  .number('minTotal')
  .required('Le montant minimum est requis');

const EXCLUSIVE_FIELD_VALIDATION = yup.boolean('exclusive').required();

const MAXUSAGECOUNT_FIELD_VALIDATION = yup
  .number('maxUsageCount')
  .required("Le nombre maximum d'utilisation est requis");

const EXCLUSIVETO_FIELD_VALIDATION = yup.string('exclusiveTo');

// exclusiveShop
const EXCLUSIVESHOP_FIELD_VALIDATION = yup.string('exclusiveShop');

const DISCOUNT = {
  ADMINUID_FIELD_VALIDATION,
  CODE_FIELD_VALIDATION,
  DELIVERYFEESVALUE_FIELD_VALIDATION,
  APPFEESVALUE_FIELD_VALIDATION,
  ACTIVE_FIELD_VALIDATION,
  MINTOTAL_FIELD_VALIDATION,
  EXCLUSIVE_FIELD_VALIDATION,
  MAXUSAGECOUNT_FIELD_VALIDATION,
  EXCLUSIVETO_FIELD_VALIDATION,
  EXCLUSIVESHOP_FIELD_VALIDATION,
};

export default DISCOUNT;

import React from 'react';
import { useTranslation } from 'react-i18next';

import { Container, Heading, CreditCardForm } from '../../../styleguide';
import { Page } from '../../common';
import {
  HeaderWrapper,
  ContentWrapper,
  MainWrapper,
} from '../../../styles/basket';

const CreatePaymentMethod = props => {
  const { t } = useTranslation(['account']);

  return (
    <Page name={t('account:pm"create_page_title')}>
      <Container>
        <MainWrapper padding>
          <HeaderWrapper>
            <Heading size={900} centered={false}>
              {t('account:pm:create_page_title')}
            </Heading>
          </HeaderWrapper>
          <ContentWrapper>
            <CreditCardForm />
          </ContentWrapper>
        </MainWrapper>
      </Container>
    </Page>
  );
};

export default CreatePaymentMethod;

import Firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/firestore';
import 'firebase/messaging';
import React from 'react';
import ReactDOM from 'react-dom';
import ReactGA from 'react-ga4';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';

import App from './views/App';
import './i18n';

console.log('Delyzon:', `${process.env.REACT_APP_ENV}-${process.env.NODE_ENV}`);

// connect our app to firebase
const dbConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  appId: process.env.REACT_APP_FIREBASE_MESSAGING_APP_ID,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID,
};
Firebase.initializeApp(dbConfig);

// Google Analytics
// https://github.com/react-ga4/react-ga4#api
ReactGA.initialize(process.env.REACT_APP_GOOGLE_ANALYTICS_TRACKING_ID);

// Sentry
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_RAVEN_TRACKING_URL,
  integrations: [new Integrations.BrowserTracing()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 0.7,
});

// render the App component to our document root with React
ReactDOM.render(<App />, document.getElementById('root'));

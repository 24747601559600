import styled from 'styled-components';
import { SPACING } from './constants';

const InvoiceTemplateBlock = styled.div`
  width: calc(100% - 16px);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: ${SPACING[200]};
  border: 1px solid ${props => props.theme.lightGray};
  border-radius: 4px;
  box-shadow: ${props => props.theme.boxShadow.md};
  margin: ${SPACING[100]};
`;

const InvoiceHeaderBlock = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: 1px solid ${props => props.theme.lightGray};
  padding-bottom: ${SPACING[100]};
  margin-bottom: ${SPACING[100]};
`;

const InvoiceHeaderLineWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  border-bottom: ${props =>
    props.isLast ? 'none' : `1px solid ${props.theme.lightGray}`};
  padding: ${SPACING[100]};
`;

const InvoiceHeaderContent = styled.div`
  flex: 1;
  max-width: 350px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: ${SPACING[100]} ${SPACING[200]};
  border: 1px solid ${props => props.theme.lightGray};
  border-radius: 4px;
  box-shadow: ${props => props.theme.boxShadow.md};
  margin: ${SPACING[100]};
  background-color: ${props => props.theme.cardBg};
`;

const InvoiceHeaderTotalWrapper = styled.div`
  flex: 1;
  max-width: 350px;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
  padding: ${SPACING[100]} ${SPACING[200]};
  border: 1px solid ${props => props.theme.lightGray};
  border-radius: 4px;
  box-shadow: ${props => props.theme.boxShadow.md};
  margin: ${SPACING[100]};
  background-color: ${props => props.theme.cardBg};
`;

const InvoiceContentWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-direction: column;
`;

const InvoiceContentHeader = styled.div`
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding: ${SPACING[100]};
`;

const InvoiceContentTableWrapper = styled.div`
  width: 100%;
  padding: ${SPACING[200]};
  margin: 0 auto;
`;

export {
  InvoiceTemplateBlock,
  InvoiceHeaderBlock,
  InvoiceHeaderContent,
  InvoiceContentWrapper,
  InvoiceContentHeader,
  InvoiceContentTableWrapper,
  InvoiceHeaderTotalWrapper,
  InvoiceHeaderLineWrapper,
};

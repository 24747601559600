import React from 'react';
import { useFormik } from 'formik';

import { Button, Heading, Input, Text } from '../../../styleguide';
import { SignupFormPolicyText } from '../../../styles/signup';
import { FormWrapper, ButtonsWrapper } from '../../../styles/form';
import { signupShopLogo } from '../../../forms';

const LogoForm = props => {
  const { onShopUpdate, onNext } = props;

  const formik = useFormik({
    initialValues: {
      logoURL: '',
    },
    validationSchema: signupShopLogo,
    onSubmit: values => {
      const { logoURL } = values;
      onShopUpdate({ logoURL });
      onNext();
    },
  });

  const canSubmit = !!formik.values.logoURL;

  return (
    <>
      <Heading size={900} centered>
        Votre logo
      </Heading>
      <FormWrapper onSubmit={formik.handleSubmit}>
        <Input
          name="logoURL"
          label="Url de votre logo"
          required
          placeholder="https://mon-établissement.com/logo.png"
          value={formik.values.logoURL}
          onChange={formik.handleChange}
          error={formik.touched.logoURL && Boolean(formik.errors.logoURL)}
          helperText={formik.touched.logoURL && formik.errors.logoURL}
        />

        <SignupFormPolicyText>
          <Text size={300} muted>
            Vous pouvez utiliser un lien ou bien télécharger votre logo
            directement.
          </Text>
        </SignupFormPolicyText>

        <ButtonsWrapper>
          <Button type="submit" disabled={!canSubmit}>
            Continuer
          </Button>
        </ButtonsWrapper>
      </FormWrapper>
    </>
  );
};

export default LogoForm;

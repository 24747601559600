import * as yup from 'yup';
import parsePhoneNumber from 'libphonenumber-js';

const NAME_RUSHER_FIELD_VALIDATION = yup.string('Nom de livreur').required();

const CITY_RUSHER_FIELD_VALIDATION = yup
  .string('Ville de départ')
  .required('La ville de départ est requise !');

const LOGO_RUSHER_FIELD_VALIDATION = yup
  .string('Votre avatar')
  .url('Vous devez fournir un lien valide');

const PHONE_RUSHER_FIELD_VALIDATION = yup
  .string('Numéro de téléphone')
  .test(
    'phone',
    "Le numéro de téléphone saisi est invalide, si le numéro n'est pas français ajoutez votre préfixe international.",
    value => {
      if (value !== undefined) {
        return parsePhoneNumber(value, 'FR')?.isValid() || false;
      }
      return false;
    }
  );

const RUSHER = {
  NAME_RUSHER_FIELD_VALIDATION,
  LOGO_RUSHER_FIELD_VALIDATION,
  CITY_RUSHER_FIELD_VALIDATION,
  PHONE_RUSHER_FIELD_VALIDATION,
};

export { RUSHER };

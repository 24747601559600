import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

import { prepareDocForUpdate } from '../helpers/firestoreHelpers';

const updateShopItem = shopItem => {
  // console.log('Update shop item called', { shopItem });
  ReactGA.event({
    category: 'Shop',
    action: 'Update ShopItem',
  });

  try {
    return Firebase.firestore()
      .collection('shopItems')
      .doc(shopItem.id)
      .update(prepareDocForUpdate(shopItem));
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

export default updateShopItem;

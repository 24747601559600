import React from 'react';
import { toaster } from 'evergreen-ui';
import { IconButton } from '@material-ui/core';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { logOut, resetPassword } from '../../actions/login';
import { useSession } from '../../hooks';
import { Page, EmptyPage } from '../common';
import {
  ButtonsWrapper,
  FormWrapper,
  HelperTextWrapper,
} from '../../styles/form';
import {
  NavLink,
  Container,
  Heading,
  Button,
  Input,
  Text,
  LanguageSwitcher,
} from '../../styleguide';
import { CloseIcon } from '../../styleguide/icons';
import { colors } from '../../styles/constants';
import { useTheme } from '../../hooks';
import Mail from '../../assets/images/mail.svg';

const PasswordReset = props => {
  const [resetLinkSend, setResetLinkSend] = React.useState(false);
  const [email, setEmail] = React.useState('');
  const [error, setError] = React.useState(null);
  const theme = useTheme();
  const { isLoggedIn, authUser } = useSession();
  const { t } = useTranslation(['auth', 'common']);

  let location = useLocation();
  let { from } = location.state || { from: { pathname: '/' } };

  const onSubmitForm = async () => {
    resetPassword(email);
    setResetLinkSend(true);

    if (isLoggedIn) {
      await logOut(authUser);
    }
  };

  if (error) {
    toaster.danger('Adresse email non trouvé', {
      description: error.message,
    });
  }

  return (
    <Page
      navbarLeftChild={
        <NavLink color={colors.green} to="/signup">
          <Text size={400} color={colors.green}>
            {t('auth:pwd_forget:nav_cta')}
          </Text>
        </NavLink>
      }
      navbarRightChild={
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }}
        >
          <LanguageSwitcher
            wrapperProps={`
              justify-content: flex-end;
              margin-top: 0;
            `}
          />
          <NavLink color={theme.headingColor} to={from}>
            <IconButton aria-label="retour">
              <CloseIcon color={theme.headingColor} size="16px" />
            </IconButton>
          </NavLink>
        </div>
      }
      name={t('auth:pwd_forget:title')}
    >
      <Container>
        {resetLinkSend ? (
          <EmptyPage
            title={t('auth:pwd_forget:sended_title')}
            description={t('auth:pwd_forget:sended_description')}
            illustration={Mail}
            buttonContent={t('auth:pwd_forget:sended_cta')}
          />
        ) : (
          <>
            <Heading size={900}>{t('auth:pwd_forget:title')}</Heading>

            <FormWrapper onSubmit={onSubmitForm}>
              <Input
                label={t('auth:pwd_forget:input_label')}
                size="small"
                variant="outlined"
                required
                placeholder="john@doe.com"
                type="email"
                fullWidth
                value={email}
                onChange={event => setEmail(event.target.value)}
              />

              <HelperTextWrapper>
                <Text muted>
                  {t('auth:pwd_forget:subtitle1')}{' '}
                  <span role="img" aria-label="smile">
                    😃
                  </span>
                </Text>
              </HelperTextWrapper>

              <ButtonsWrapper>
                <Button type="submit" disabled={!email.length}>
                  {t('auth:pwd_forget:cta')}
                </Button>
              </ButtonsWrapper>
            </FormWrapper>
          </>
        )}
      </Container>
    </Page>
  );
};

export default PasswordReset;

import React from 'react';
import { Route, Redirect, useLocation } from 'react-router-dom';
import { useSession } from '../hooks';

const PrivateRoute = props => {
  const { children, name, kind, ...rest } = props;
  const { isLoggedIn, user, authUser } = useSession();
  let location = useLocation();

  // console.log(`private route ${name} - kind: ${kind}`, {
  //   authUser,
  //   isLoggedIn,
  //   test: isLoggedIn ? 'children' : 'redirect',
  //   user,
  // });

  if (!isLoggedIn) {
    // if we've already redirect to /login, do nothing to prevent infinite redirects
    // console.log('Private route redirect to logIn', { location });
    return (
      <Redirect
        to={{
          pathname: '/login',
          state: { from: location },
        }}
      />
    );
  }

  if (
    isLoggedIn &&
    user &&
    !user.isVerified &&
    location.pathname !== '/activation' &&
    location.pathname !== '/activation/phone' &&
    location.pathname !== '/activation/mail'
  ) {
    // Prevent not verified user to access to private routes
    return (
      <Redirect
        to={{
          pathname: '/activation',
          state: { from: location },
        }}
      />
    );
  }

  if (kind === 'shop' && (user.role === 'client' || user.role === 'rusher')) {
    // console.log('Private route redirect to / because client or rusher', {
    //   location,
    // });
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />
    );
  }

  if (kind === 'rusher' && (user.role === 'client' || user.role === 'shop')) {
    // console.log('Private route redirect to / because shop or client', {
    //   location,
    // });
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />
    );
  }

  if (kind === 'admin' && user.role !== 'admin') {
    // console.log('Private route redirect to / because not admin', {
    //   location,
    // });
    return (
      <Redirect
        to={{
          pathname: '/',
          state: { from: location },
        }}
      />
    );
  }

  return <Route {...rest} render={() => children} />;
};

export default PrivateRoute;

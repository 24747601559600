import Firebase from 'firebase/app';
import ReactGA from 'react-ga4';

import { prepareDocForCreate } from '../helpers/firestoreHelpers';
import createID from '../helpers/createID';

const createShopItem = (data, shop) => {
  // console.log('Create shop item called', { data });
  ReactGA.event({
    category: 'Shop',
    action: 'Create ShopItem',
  });

  const item = {
    ...data,
    id: createID(),
    isInWeeklySelection: false,
    shopID: shop.id,
    shopIsPartner: shop.isPartner || false,
  };

  try {
    return Firebase.firestore()
      .collection('shopItems')
      .doc(item.id)
      .set(prepareDocForCreate(item));
  } catch (error) {
    console.error({ error });
    throw new Error(error);
  }
};

export default createShopItem;

import { DateTime, Duration } from 'luxon';

const MAX_RUSHER_DELAY = 10;

// const computeEstimatedTime = computeEstimatedTime({ts: order.readyAt, withDelay: true, minutes: 0, seconds: order.duration });
const computeEstimatedTime = ({
  ts,
  withDelay = false,
  minutes = 0,
  seconds = 0,
}) => {
  const result = DateTime.fromMillis(ts)
    .plus({
      minutes: withDelay ? minutes + MAX_RUSHER_DELAY : minutes,
      seconds: seconds,
    })
    .toLocaleString(DateTime.TIME_24_SIMPLE);

  return result;
};

const computeEstimatedTimeBeforeOrder = ({ ts, minutes = 0, seconds = 0 }) => {
  const base = minutes + MAX_RUSHER_DELAY;
  const displayedMinutes = base < 60 ? base : 60;

  const from = DateTime.fromMillis(ts)
    .plus({
      minutes: displayedMinutes - (displayedMinutes >= 45 ? 15 : 10),
      seconds: seconds,
    })
    .toLocaleString(DateTime.TIME_24_SIMPLE);
  const to = DateTime.fromMillis(ts)
    .plus({
      minutes: displayedMinutes + (displayedMinutes >= 45 ? 10 : 15),
      seconds: seconds,
    })
    .toLocaleString(DateTime.TIME_24_SIMPLE);

  return {
    from,
    to,
  };
};

const computeTimeFrom = ({ ts }) => {
  const result = DateTime.fromMillis(ts).toRelative();

  return result;
};

const displayTimeFrom = ({ ts }) => {
  const result = DateTime.fromMillis(ts).toLocaleString(
    DateTime.TIME_24_SIMPLE
  );

  return result;
};

const displayDurationFrom = ({ seconds }) => {
  const result = Duration.fromObject({ seconds })
    .shiftTo('hours', 'minutes', 'seconds')
    .toObject();

  return `${result.hours > 0 ? `${result.hours}h ` : ''}${result.minutes +
    (result.seconds > 30 ? 1 : 0)} minutes`;
};

const differenceBetweenTS = (timestamp1, timestamp2, lite) => {
  const difference = timestamp1 - timestamp2;
  const seconds = Math.floor(difference / 1000);
  const result = Duration.fromObject({ seconds })
    .shiftTo('hours', 'minutes', 'seconds')
    .toObject();

  if (!!lite) {
    return result;
  }

  return `${result.hours > 0 ? `${result.hours}h ` : ''}${
    result.minutes > 0 ? `${result.minutes}min ` : ''
  }${result.seconds > 0 ? `${result.seconds}s ` : ''}`;
};

export {
  computeEstimatedTime,
  displayTimeFrom,
  displayDurationFrom,
  computeTimeFrom,
  computeEstimatedTimeBeforeOrder,
  differenceBetweenTS,
};

import React from 'react';
import { Redirect } from 'react-router-dom';
import Firebase from 'firebase/app';
import { useCollectionData } from 'react-firebase-hooks/firestore';
import { IconButton } from '@material-ui/core';
import { toaster } from 'evergreen-ui';

import { useSession } from '../../hooks';
import { NavLink, Container, Loader, Backdrop } from '../../styleguide';
import { Page } from '../common';
import { createRusher } from '../../actions/rusher';
import { ImageStyled } from '../../styles/signup';
import { CloseIcon } from '../../styleguide/icons';
import STEPS from './steps';
import { useTheme } from '../../hooks';
import DeliveryImage from '../../assets/images/orders/Delivery.svg';
import DoneImage from '../../assets/images/orders/Done.svg';

const RUSHER_DATA = {
  id: null,
  name: '',
  phone: '',
  baseCity: '',
  pictureURL: '',
  legal: null,
};

const RusherSignup = props => {
  const { authUser, user } = useSession();
  const theme = useTheme();

  const [currentStep, setStep] = React.useState(1);
  const [creationError, setError] = React.useState(null);
  const [creationLoading, setCreationLoading] = React.useState(false);

  const [rusher, setRusher] = React.useState({
    ...RUSHER_DATA,
    id: authUser.uid,
  });

  const imageSize =
    currentStep === 1 || currentStep === 6
      ? {}
      : { width: '150px', height: '150px' };

  const onSubmit = async () => {
    setCreationLoading(true);
    await createRusher(rusher);
    setCreationLoading(false);
    onNextStep();
  };

  const onRusherUpdate = data => {
    setRusher({ ...rusher, ...data });
  };

  const onNextStep = () => setStep(currentStep + 1);

  const [value, loading, error] = useCollectionData(
    Firebase.firestore()
      .collection('rushers')
      .where('id', '==', user.id),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  React.useEffect(() => {
    const isFinalStep =
      !!rusher.name && !!rusher.phone && !!rusher.baseCity && !!rusher.legal;

    if (isFinalStep && !loading && !value.length) {
      onSubmit();
    }
  });

  if (error) {
    throw new Error(error.toString());
  }

  if (loading) {
    return (
      <Loader color={theme.primaryColor} background={theme.backgroundColor} />
    );
  }

  if (!!value && value.length && currentStep < 5) {
    return (
      <Redirect
        to={{
          pathname: '/rusher/profil',
        }}
      />
    );
  }

  if (creationError) {
    toaster.danger('Erreur lors de la création du profil', {
      description: creationError.message,
    });
  }

  const { Step, index } = STEPS.filter(item => item.index === currentStep)[0];
  const imgSrc = index === STEPS.length ? DoneImage : DeliveryImage;

  return (
    <Page
      navbarLeftChild="disabled"
      navbarRightChild={
        <NavLink color={theme.headingColor} to={{ pathname: '/' }}>
          <IconButton aria-label="retour">
            <CloseIcon color={theme.headingColor} size="16px" />
          </IconButton>
        </NavLink>
      }
      name="Inscription livreur"
    >
      <Container>
        <ImageStyled src={imgSrc} {...imageSize} />

        <Step
          rusher={rusher}
          onNext={onNextStep}
          onRusherUpdate={onRusherUpdate}
        />
      </Container>

      {creationLoading && <Backdrop />}
    </Page>
  );
};

export default RusherSignup;

import React from 'react';
import { useSwipeable } from 'react-swipeable';
import styled from 'styled-components';
import { useUnstated } from '@gitbook/unstated';

import { ThemeContainer } from '../contexts/index';
import { colors, SPACING } from '../styles/constants';
import { useTheme } from '../hooks';
import { ChevronIcon } from './icons';

export const Paper = styled.div`
  box-shadow: 0px 3px 3px -2px rgb(0 0 0 / 20%),
    0px 3px 4px 0px rgb(0 0 0 / 14%), 0px 1px 8px 0px rgb(0 0 0 / 12%);
  border-radius: 4px;
  padding: 25px;
`;

export const NEXT = 'NEXT';
export const PREV = 'PREV';

export const CarouselContainer = styled.div`
  width: 100%;
  display: flex;
  transition: ${props => (props.sliding ? 'none' : 'transform 0.1s ease')};
  transform: ${props => {
    // if (!props.sliding) return 'translateX(calc(-20% - 20px))';
    // if (props.dir === PREV) return 'translateX(calc(2 * (-80% - 20px)))';
    return 'translateX(0%)';
  }};
`;

export const Wrapper = styled.div`
  width: 100%;
  overflow: hidden;
  padding: ${SPACING[200]} ${SPACING[100]};
  position: relative;
  gap: 16px;
  touch-action: pan-y;
`;

export const CarouselSlot = styled.div`
  flex: 1 0 fit-content;
  margin-right: ${SPACING[200]};
  order: ${props => props.order};
`;

export const SlideButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px;
  border-radius: 50%;
  cursor: pointer;
  color: ${props => props.theme.headingColor};
  outline: 0;
  box-shadow: ${props => props.theme.boxShadow.base};
  background-color: ${props => props.theme.backgroundColor};
  border: 1px solid transparent;
  transition: all 0.2s ease;

  &:hover {
    box-shadow: ${props => props.theme.boxShadow.md};
    border: 1px solid ${colors.lightGray};
  }

  &:focus {
    outline: 0;
  }
`;

export const SlideButtonContainer = styled.div`
  top: 0;
  width: ${props => (props.left ? '8rem' : '5rem')};
  display: flex;
  align-items: ${props => (props.left ? 'flex-start' : 'flex-end')};
  justify-content: center;
  flex-direction: column;
  position: absolute;
  height: 100%;
  position: absolute;
  opacity: ${props => (props.hidden ? 0 : 1)};
  z-index: 1;
  background-image: linear-gradient(
    ${props => (props.left ? 'to left' : 'to right')},
    ${props =>
      props.isLightTheme ? 'rgba(255, 255, 255, 0.1)' : 'rgba(0, 0, 0, 0.1)'},
    ${props => props.theme.backgroundColor}
  );
  ${props => (props.left ? 'left: 0;' : 'right: 0;')}
  ${props =>
    props.left
      ? `padding-left: ${SPACING[100]};`
      : `padding-right: ${SPACING[100]};`}
`;

export const PatternBox = styled.div`
  border: 2px solid black;
  width: 60%;
  margin: 20px auto;
  padding: 30px 20px;
  white-space: pre-line;
`;

const getOrder = (index, pos, numItems) => {
  return index - pos < 0 ? numItems - Math.abs(index - pos) : index - pos;
};

const getInitialState = numItems => ({
  pos: 0,
  sliding: false,
  dir: NEXT,
});

const Carousel = props => {
  const { offset } = props;
  const numItems = React.Children.count(props.children);
  const [state, dispatch] = React.useReducer(
    (state, action) => reducer(state, action, offset),
    getInitialState(numItems)
  );
  const theme = useTheme();
  const themeContainer = useUnstated(ThemeContainer);
  const isLightTheme = themeContainer.state.theme === 'light';

  const slide = dir => {
    dispatch({ type: dir, numItems });
    setTimeout(() => {
      dispatch({ type: 'stopSliding' });
    }, 50);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => slide(NEXT),
    onSwipedRight: () => slide(PREV),
    swipeDuration: 500,
    preventScrollOnSwipe: false,
    trackMouse: true,
  });

  return (
    <Wrapper {...handlers}>
      <SlideButtonContainer
        left
        hidden={state.pos === 0}
        theme={theme}
        isLightTheme={isLightTheme}
      >
        <SlideButton
          theme={theme}
          onClick={() => {
            if (state.pos > 0) {
              slide(PREV);
            }
          }}
        >
          <ChevronIcon return size="24px" />
        </SlideButton>
      </SlideButtonContainer>
      <CarouselContainer dir={state.dir} sliding={state.sliding}>
        {React.Children.map(props.children, (child, index) => (
          <CarouselSlot order={getOrder(index, state.pos, numItems)}>
            {child}
          </CarouselSlot>
        ))}
      </CarouselContainer>
      <SlideButtonContainer
        theme={theme}
        isLightTheme={isLightTheme}
        hidden={state.pos === numItems}
      >
        <SlideButton
          onClick={() => {
            if (state.pos < numItems) {
              slide(NEXT);
            }
          }}
          theme={theme}
        >
          <ChevronIcon size="24px" />
        </SlideButton>
      </SlideButtonContainer>
    </Wrapper>
  );
};

function reducer(state, action, offset) {
  switch (action.type) {
    case PREV:
      return {
        ...state,
        dir: PREV,
        sliding: true,
        pos: state.pos === 0 ? action.numItems - 1 : state.pos - offset,
      };
    case NEXT:
      return {
        ...state,
        dir: NEXT,
        sliding: true,
        pos: state.pos === action.numItems - 1 ? 0 : state.pos + offset,
      };
    case 'stopSliding':
      return { ...state, sliding: false };
    default:
      return state;
  }
}

export default Carousel;

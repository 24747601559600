import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from '../styles/constants';
import Text from './text';

const StyledLink = styled(Link)`
  color: ${props => props.color};
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};
  cursor: pointer;
`;

const WrapperHoverable = styled.span`
  cursor: pointer;
  color: ${props => props.color || colors.green} !important;
  text-decoration: ${props => (props.underline ? 'underline' : 'none')};

  &:hover {
    color: ${props => props.color || colors.green};
  }
`;

const HoverableLink = props => {
  const {
    to,
    color,
    children,
    wrapperStyles,
    underline = true,
    ...rest
  } = props;

  return (
    <WrapperHoverable
      color={color || colors.green}
      style={wrapperStyles || {}}
      underline={underline}
    >
      <StyledLink color={color || colors.green} to={to} {...rest}>
        {children}
      </StyledLink>
    </WrapperHoverable>
  );
};

const ExternalHoverableLink = props => {
  const {
    color,
    children,
    withoutTarget,
    styles,
    wrapperStyles,
    ...rest
  } = props;

  return (
    <WrapperHoverable color={color || colors.green} style={wrapperStyles || {}}>
      <a
        {...(withoutTarget
          ? {}
          : { target: '_blank', rel: 'noopener noreferrer' })}
        {...rest}
        style={{ color: color || colors.green, ...styles }}
      >
        {children}
      </a>
    </WrapperHoverable>
  );
};

const SimpleHoverableAction = props => {
  const { color, children, wrapperStyles, onClick, underline, ...rest } = props;

  return (
    <WrapperHoverable
      color={color || colors.green}
      onClick={onClick}
      underline={underline}
      style={wrapperStyles || {}}
    >
      <Text
        color={color || colors.green}
        styles={wrapperStyles || {}}
        {...rest}
      >
        {children}
      </Text>
    </WrapperHoverable>
  );
};

export { ExternalHoverableLink, HoverableLink, SimpleHoverableAction };

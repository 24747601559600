import styled from 'styled-components';
import { SPACING } from './constants';

const FieldWrapper = styled.div`
  width: ${props => props.width || '100%'};
  max-width: ${props => props.maxWidth || '100%'};
  flex: ${props => props.flex || '1'};
  display: flex;
  justify-content: ${props => props.justify || 'center'};
  align-items: center;
  padding: ${props => props.padding ?? SPACING[100]};
`;

const FormWrapper = styled.form`
  width: 100%;
  max-width: ${props => props.maxWidth ?? '400px'};
  padding: ${props =>
    `${props.paddingV ?? SPACING[400]} ${props.paddingH ?? SPACING[100]}`};
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: ${props => props.margin ?? `${SPACING[200]} auto`};
`;

const FieldsRow = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: ${SPACING[100]};
`;

const ButtonsWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-top: ${SPACING[300]};
`;

const HelperTextWrapper = styled.div`
  width: 100%;
  max-width: 350px;
  text-align: center;
  padding: ${SPACING[300]} ${SPACING[100]};
`;

export {
  FieldWrapper,
  FormWrapper,
  ButtonsWrapper,
  HelperTextWrapper,
  FieldsRow,
};

import React from 'react';
import Firebase from 'firebase/app';
import { useCollection } from 'react-firebase-hooks/firestore';
import { DataGrid } from '@material-ui/data-grid';
import { DateTime } from 'luxon';

import { BlockWrapper } from '../../../../../styles/admin';
import { UserDialog } from '../common/Dialogs';
import { updateUser } from '../../../../../actions/users';
import { useTheme } from '../../../../../hooks';
import BlockLayout from '../common/BlockLayout';
import StatCard from '../common/StatCard';
import BlockHeader from '../common/BlockHeader';

const { REACT_APP_STRIPE_DASHBOARD_URL } = process.env;

const columns = [
  {
    field: 'email',
    headerName: 'Email',
    width: 280,
  },
  {
    field: 'id',
    headerName: 'ID',
    width: 280,
  },
  {
    field: 'name',
    headerName: 'Nom et prenom',
    width: 200,
  },
  {
    field: 'role',
    headerName: 'Role',
    width: 150,
  },
  {
    field: 'verified',
    headerName: 'Activé',
    width: 150,
  },
  {
    field: 'phone',
    headerName: 'Téléphone',
    width: 200,
  },
  {
    field: 'stripeURL',
    headerName: 'Compte Stripe',
    width: 200,
  },
  {
    field: 'createdAt',
    headerName: 'Crée le',
    width: 200,
  },
  {
    field: 'updatedAt',
    headerName: 'Mis à jour le',
    width: 200,
  },
  {
    field: 'verifiedBy',
    headerName: 'Vérifié par',
    width: 150,
  },
];

const UserBlock = props => {
  const [editDialog, setEditDialog] = React.useState(null);
  const theme = useTheme();

  const limitTs = React.useMemo(
    () =>
      Firebase.firestore.Timestamp.fromDate(
        new Date(new Date().setDate(new Date().getDate() - 30))
      ),
    []
  );

  const [newUsers, newUsersLoading, newUsersError] = useCollection(
    Firebase.firestore()
      .collection('users')
      .where('createdAt', '>=', limitTs)
      .orderBy('createdAt', 'desc'),
    {
      snapshotListenOptions: {
        includeMetadataChanges: true,
        timestampsInSnapshots: true,
      },
    }
  );

  const onRowClick = params => {
    setEditDialog({ updatedData: null, data: params.row });
  };

  const onConfirmRoleUpdate = async () => {
    if (editDialog.data.role !== editDialog.updatedData.role) {
      await updateUser(
        { role: editDialog.updatedData.role },
        editDialog.data.id
      );
      setEditDialog(null);
    } else {
      setEditDialog(null);
    }
  };

  const { rows, usersCount, customersCount } = React.useMemo(() => {
    if (newUsersLoading || !newUsers?.size || !!newUsersError) {
      return {
        rows: [],
        usersCount: 0,
        customersCount: 0,
      };
    }

    const users = newUsers.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
    }));

    const rows = users.map(user => ({
      email: user.email,
      id: user.id,
      name: user.name,
      phone: user.phone,
      role: user.role,
      verified: !!user.isVerified ? '✅' : '🚫',
      verifiedBy: !user.verifiedBy
        ? '--'
        : user.verifiedBy === 'phone'
        ? 'Téléphone'
        : 'Email',
      stripeURL: user.stripeCustomerId
        ? `${REACT_APP_STRIPE_DASHBOARD_URL}/customers/${user.stripeCustomerId}`
        : 'Aucun paiement',
      createdAt: DateTime.fromMillis(user.createdAt.toMillis()).toLocaleString(
        DateTime.DATETIME_SHORT
      ),
      updatedAt: !!user.updatedAt
        ? DateTime.fromMillis(user.updatedAt.toMillis()).toLocaleString(
            DateTime.DATETIME_SHORT
          )
        : '---',
    }));

    const usersCount = users.length;
    const customersCount = users.filter(user => user.stripeCustomerId).length;

    return {
      rows,
      usersCount,
      customersCount,
    };
  }, [newUsers, newUsersLoading, newUsersError]);

  if (newUsersError) {
    throw new Error(newUsersError.toString());
  }

  return (
    <BlockWrapper theme={theme}>
      <BlockHeader
        title="Les utilisateurs"
        path="/admin/dashboard/users"
        label={`Depuis le ${DateTime.fromSeconds(
          limitTs.seconds
        ).toLocaleString(DateTime.DATE_SHORT)}`}
      />

      <BlockLayout
        statBlocks={
          <>
            <StatCard label={'Utilisateurs'} value={usersCount} />
            <StatCard label={'Clients'} value={customersCount} />
          </>
        }
      >
        <DataGrid
          rows={rows}
          columns={columns}
          pageSize={10}
          stickyHeader
          loading={newUsersLoading}
          onRowClick={onRowClick}
        />
      </BlockLayout>

      {editDialog ? (
        <UserDialog
          user={editDialog}
          isOpen={!!editDialog}
          onClose={() => setEditDialog(null)}
          onConfirm={onConfirmRoleUpdate}
          cantConfirm={!editDialog.updatedData}
          setEditDialog={setEditDialog}
        />
      ) : null}
    </BlockWrapper>
  );
};

export default UserBlock;

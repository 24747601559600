import { Container } from '@gitbook/unstated';
import { add, subtract, remove, isEmpty } from 'lodash';
import { parseNumber } from '../../common';

class BasketContainer extends Container {
  constructor() {
    super();
    const basket = JSON.parse(localStorage.getItem('delyzon-basket')) || {};
    this.state = !isEmpty(basket)
      ? basket
      : {
          itemsList: [],
          shop: null,
          total: 0,
          selectedAddress: null,
          selectedPaymentMethod: null,
          deliveryFees: 0,
          discount: null,
        };
  }

  updateState(value) {
    const updatedState = {
      ...this.state,
      ...value,
    };
    this.setState(updatedState);
    localStorage.setItem('delyzon-basket', JSON.stringify(updatedState));
  }

  updateDeliveryFees(deliveryFees) {
    this.updateState({
      deliveryFees,
    });
  }

  updateSelectedAddress(address) {
    this.updateState({
      selectedAddress: address,
    });
  }

  updateSelectedPaymentMethod(paymentMethod) {
    this.updateState({
      selectedPaymentMethod: paymentMethod,
    });
  }

  updateDiscount(discount) {
    this.updateState({
      discount,
    });
  }

  updateBasket({
    item,
    shop,
    itemTotal,
    supplements,
    options,
    menuItems,
    selectedAddress,
    selectedPaymentMethod,
    deliveryFees,
  }) {
    const { total, itemsList } = this.state;
    const { isMenu } = item;

    if (total > 0 && this.state.shop.id !== shop.id) {
      return {
        message: 'Not the same shop than the existing basket',
        info: {
          currentShop: this.state.shop,
          shop,
        },
      };
    } else {
      this.updateState({
        itemsList: [
          ...itemsList,
          {
            item,
            price: parseNumber(itemTotal),
            supplements,
            options: options.filter(option => !!option.items.length),
            ...(isMenu ? { products: menuItems } : {}),
          },
        ],
        shop,
        total: parseNumber(add(parseNumber(total), parseNumber(itemTotal))),
        selectedAddress,
        deliveryFees,
        ...(!!selectedPaymentMethod ? { selectedPaymentMethod } : {}),
      });
    }
  }

  resetBasket() {
    this.updateState({
      itemsList: [],
      shop: null,
      total: 0,
      deliveryFees: 0,
    });
    localStorage.removeItem('delyzon-basket');
  }

  deleteItem(item) {
    const { total, itemsList } = this.state;
    const itemToDeletePrice = parseNumber(item.price);
    const itemIndex = itemsList.indexOf(item);
    const itemOccuranceInList = itemsList.filter(
      i => i.item.id === item.item.id
    ).length;

    if (itemsList.length === 1) {
      this.resetBasket();
      return;
    }

    if (itemOccuranceInList > 1) {
      if (itemIndex > -1) {
        this.updateState({
          itemsList: [...remove(itemsList, (v, index) => index !== itemIndex)],
          total: parseNumber(
            subtract(parseNumber(total), parseNumber(itemToDeletePrice))
          ),
        });

        return;
      }
    }

    this.updateState({
      itemsList: [...itemsList.filter(i => i.item.id !== item.item.id)],
      total: parseNumber(
        subtract(parseNumber(total), parseNumber(itemToDeletePrice))
      ),
    });

    return;
  }

  get content() {
    return this.state;
  }

  get itemsCount() {
    return this.state.itemsList.length;
  }

  get isEmpty() {
    const { itemsList, shop, total } = this.state;
    return !shop && total === 0 && itemsList.length === 0;
  }

  get paymentMethod() {
    const { selectedPaymentMethod } = this.state;
    return selectedPaymentMethod;
  }
}

export default BasketContainer;

import React from 'react';
import { toaster } from 'evergreen-ui';
import { useReactToPrint } from 'react-to-print';
import { DateTime } from 'luxon';

import computePreparationTime from '../../../../actions/orders/helpers/computePreparationTime';
import { computeEstimatedTime } from '../../../../actions/orders/helpers/computeTimesHelpers';
import ORDER_STATUS from '../../../../actions/orders/helpers/STATUS';
import updateOrder from '../../../../actions/orders/updateOrder';
import { Heading, Button } from '../../../../styleguide';
import { OrdersIcon } from '../../../../styleguide/icons';
import { TabContentWrapper } from '../../../../styles/shop';
import {
  OrdersListWrapper,
  EmptyNewOrders,
} from '../../../../styles/orders-cards';
import { HeaderTabRow } from '../../../../styles/shoptabs';
import { useTheme } from '../../../../hooks';
import TabPanel from '../../tabs/TabPanel';
import { InProgressOrderCard } from '../cards/Cards';
import InProgressDialog from '../dialogs/InProgressDialog';
import EmptyTab from './EmptyTab';
import { ClientReceipt } from '../receipts';

const InProgressTab = props => {
  const { value, orders, toggleOrdersDrawer } = props;
  const [confirmModal, setConfirmModal] = React.useState(false);
  const [selectedOrder, setSelectedOrder] = React.useState(null);
  const theme = useTheme();
  const clientRef = React.useRef(null);

  React.useEffect(() => {
    if (!!selectedOrder && !!confirmModal) {
      const orderToUpdate = orders.find(o => o.id === selectedOrder.id);

      if (!orderToUpdate || !!orderToUpdate?.inTreatment) {
        setConfirmModal(null);
        return;
      }
    }
  }, [orders, selectedOrder, confirmModal]);

  const handlePrint = useReactToPrint({
    content: () => clientRef.current,
    onPrintError: all => console.error({ all }),
  });

  const onConfirm = async order => {
    try {
      const orderToUpdate = orders.find(o => o.id === order.id);

      if (!orderToUpdate || !!orderToUpdate?.inTreatment) {
        setConfirmModal(null);
        toaster.warning(
          `⏳ Impossible commande en cours de traitement, réessayez plus tard`
        );
        return;
      }
      handlePrint();
      await updateOrder(orderToUpdate, {
        status: ORDER_STATUS.ready,
        readyAt: Date.now(),
      });
      toaster.success(`Commande prête 📦`, {
        description: `La commande numéro ${orderToUpdate.id} est desormais en prête, le livreur arrivera bientôt.`,
      });
      setConfirmModal(null);
    } catch (error) {
      toaster.danger(`Une erreur est survenue`, {
        description: `Une erreur est survenue avec la commande ${order.id}, contactez notre support`,
      });
    }
  };

  const hasExistingOrders = !!orders.length;

  return (
    <TabPanel value={value} index={1}>
      <TabContentWrapper>
        {hasExistingOrders ? (
          <>
            <HeaderTabRow>
              <Heading size={800}>En cours de préparation</Heading>
              <Button
                small
                minimal
                startIcon={<OrdersIcon color={theme.black} size="16px" />}
                onClick={toggleOrdersDrawer}
              >
                Toutes les commandes
              </Button>
            </HeaderTabRow>

            <OrdersListWrapper>
              {orders.map(order => {
                return (
                  <InProgressOrderCard
                    key={order.id}
                    onClickConfirm={() => {
                      setConfirmModal(true);
                      setSelectedOrder(order);
                    }}
                    order={order}
                  />
                );
              })}
            </OrdersListWrapper>
            {!!selectedOrder && (
              <div style={{ display: 'none' }}>
                <ClientReceipt
                  order={selectedOrder}
                  ref={clientRef}
                  orderAt={DateTime.fromMillis(
                    selectedOrder.orderAt
                  ).toLocaleString(DateTime.DATETIME_SHORT)}
                  estimatedDeliveryTime={computeEstimatedTime({
                    ts: Date.now(),
                    withDelay: false,
                    minutes:
                      selectedOrder.estimatedTimeToDeliver ||
                      computePreparationTime(selectedOrder.itemsList),
                    seconds: selectedOrder.duration,
                  })}
                />
              </div>
            )}
            {confirmModal ? (
              <InProgressDialog
                isOpen={confirmModal}
                order={selectedOrder}
                onClose={() => setConfirmModal(false)}
                onConfirm={() => onConfirm(selectedOrder)}
              />
            ) : null}
          </>
        ) : (
          <EmptyNewOrders>
            <EmptyTab
              title={'Pas de commande en cours'}
              description={
                'Les commandes en cours sont vos commandes en cours de préparation 🥘'
              }
            />
          </EmptyNewOrders>
        )}
      </TabContentWrapper>
    </TabPanel>
  );
};

export default InProgressTab;

import uniqBy from 'lodash/uniqBy';
import orderBy from 'lodash/orderBy';
import groupBy from './groupBy';

const computeCategoriesWithOrder = items =>
  uniqBy(
    items.map((item, index) => ({
      category: item.category,
      order: item.order || index + 1,
    })),
    i => i.category
  );

const orderedGroupedItems = items => {
  const categoriesWithOrder = computeCategoriesWithOrder(items);
  const itemsWithOrder = items.map(item => ({
    ...item,
    order:
      item.order ||
      categoriesWithOrder.filter(c => c.category === item.category)[0].order,
  }));
  const groupedShopItems = groupBy(itemsWithOrder, 'category');
  const orderedItems = orderBy(groupedShopItems, i => i.items[0].order, [
    'asc',
  ]);

  return orderedItems;
};

export { computeCategoriesWithOrder, orderedGroupedItems };

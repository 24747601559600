import * as yup from 'yup';
import parsePhoneNumber from 'libphonenumber-js';

const EMAIL_LEGAL_FIELD_VALIDATION = yup
  .string('Votre email')
  .email('Veuillez entrer un email valide')
  .required('Votre email est requis');

const FIRSTNAME_LEGAL_FIELD_VALIDATION = yup
  .string('Votre prénom')
  .required('Votre prénom sont requis');

const LASTNAME_LEGAL_FIELD_VALIDATION = yup
  .string('Votre nom')
  .required('Votre nom sont requis');

const PHONE_LEGAL_FIELD_VALIDATION = yup
  .string('Numéro de téléphone')
  .test(
    'phone',
    "Le numéro de téléphone saisi est invalide, si le numéro n'est pas français ajoutez votre préfixe international.",
    value => {
      if (value !== undefined) {
        return parsePhoneNumber(value, 'FR')?.isValid() || false;
      }
      return false;
    }
  );

const SIREN_LEGAL_FIELD_VALIDATION = yup
  .string('Votre numéro SIREN/SIRET')
  .min(9)
  .max(14)
  .required('Votre numéro SIREN/SIRET est requis');

const DENOMINATION_LEGAL_FIELD_VALIDATION = yup
  .string('Votre status')
  .required('Votre status est requis');

const STATUS_LEGAL_FIELD_VALIDATION = yup
  .string('Votre status')
  .required('Votre status est requis');

const LEGAL = {
  EMAIL_LEGAL_FIELD_VALIDATION,
  FIRSTNAME_LEGAL_FIELD_VALIDATION,
  LASTNAME_LEGAL_FIELD_VALIDATION,
  PHONE_LEGAL_FIELD_VALIDATION,
  SIREN_LEGAL_FIELD_VALIDATION,
  DENOMINATION_LEGAL_FIELD_VALIDATION,
  STATUS_LEGAL_FIELD_VALIDATION,
};

export { LEGAL };

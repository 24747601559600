import React from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { toaster } from 'evergreen-ui';
import { useStripe, useElements, CardElement } from '@stripe/react-stripe-js';
import { useTranslation } from 'react-i18next';

import { colors, SPACING } from '../../styles/constants';
import { useSession } from '../../hooks';
import Backdrop from '../backdrop';
import { createPaymentMethod } from '../../actions/stripe';
import { FormWrapper } from '../../styles/form';
import Button from '../button';
import { Input } from './Input';
import Text from '../text';

const CardFormDetails = styled.div`
  width: 100%;
  padding: ${SPACING[300]} ${SPACING[100]};
  padding-top: 0;
  text-align: center;
`;

const options = {
  style: {
    base: {
      color: colors.black,
    },
    invalid: {
      color: colors.red,
    },
  },
  hidePostalCode: true,
};

const CreditCardForm = props => {
  const [name, setName] = React.useState('');
  const [stripeVal, setStripeVal] = React.useState(null);
  const [stripeLoading, setStripeLoading] = React.useState(true);
  const [isLoading, setLoading] = React.useState(false);
  const [error, setError] = React.useState(null);

  const { t } = useTranslation(['account', 'common']);
  const { authUser, user } = useSession();
  const stripe = useStripe();
  const elements = useElements();
  const history = useHistory();
  let location = useLocation();

  const to =
    location.state && location.state.fromOrder
      ? { pathname: '/order/new' }
      : { pathname: '/account/payment_methods' };

  if (stripe && elements && stripeLoading) {
    setStripeLoading(false);
  }

  const handleSubmit = async event => {
    event.preventDefault();

    if (!stripe || !elements) {
      setLoading(true);
      return;
    } else {
      setLoading(false);
    }

    setLoading(true);
    const { error } = await createPaymentMethod(
      stripe,
      elements.getElement(CardElement),
      name,
      user,
      authUser.uid
    );
    setLoading(false);

    if (error) {
      setError({ error });
    } else {
      toaster.success(t('account:pm:toast_success'), {
        description: t('account:pm:toast_success_subtitle'),
      });
      history.push(to.pathname, { from: to });
    }
  };

  const canSubmit =
    stripe && !isLoading && !error && !stripeLoading && !!stripeVal && !!name;

  if (error) {
    toaster.danger(t('account:pm:toast_error'), {
      description: t('account:pm:toast_error_subtitle'),
    });
  }

  return (
    <>
      <FormWrapper onSubmit={handleSubmit} maxWidth="800px">
        <Input
          label={t('account:pm:name_lastname')}
          required
          placeholder="Doe John"
          value={name}
          onChange={event => {
            setName(event.target.value);
            setError(null);
          }}
          InputLabelProps={{
            shrink: true,
          }}
        />

        <label style={{ width: '100%', padding: SPACING[100] }}>
          <CardElement
            className="Stripe-CardElement"
            options={options}
            onChange={value => {
              setStripeVal(value.complete);
              setError(null);
            }}
          />
        </label>
        <CardFormDetails>
          <Text muted size={400}>
            {t('account:pm:helper_text')}
          </Text>
        </CardFormDetails>

        <Button type="submit" disabled={!canSubmit}>
          {t('common:confirm')}
        </Button>
      </FormWrapper>

      {isLoading && <Backdrop />}
    </>
  );
};

export default CreditCardForm;

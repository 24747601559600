import React from 'react';
import { useAsync } from 'react-use';
import Firebase from 'firebase/app';
import { useCollection, useDocumentData } from 'react-firebase-hooks/firestore';
import { useUnstated } from '@gitbook/unstated';

import authenticatedRequest from '../actions/helpers/authenticatedRequest';
import computeDeliveryFees from '../actions/gmaps-api/computeDeliveryFees';
import { computeEstimatedTimeBeforeOrder } from '../actions/orders/helpers/computeTimesHelpers';
import computePreparationTime from '../actions/orders/helpers/computePreparationTime';
import { BasketContainer } from '../contexts';
import { APP_FEES } from '../common/fees';
import { computeAppFees } from '../actions/orders/helpers/computeWithDiscount';
import { toaster } from 'evergreen-ui';
import { API } from '../common';

const { REACT_APP_SETTINGS_DOC_ID } = process.env;

const useNewOrder = ({ user }) => {
  const [discontValidation, setDiscountValidation] = React.useState({
    isValid: false,
    discount: null,
  });
  const basketContainer = useUnstated(BasketContainer);
  const { itemsList, shop, selectedAddress, discount } = basketContainer.state;

  const shopID = !!shop ? shop.id : '';

  const computeEstimatedDeliveryTimes = (deliveryData, itemsList) => {
    if (!deliveryData.loading && !!deliveryData?.value) {
      return computeEstimatedTimeBeforeOrder({
        ts: Date.now(),
        minutes: computePreparationTime(itemsList),
        seconds: deliveryData?.value?.duration,
      });
    }
    return null;
  };

  const [shopValue, shopValueLoading, shopValueError] = useCollection(
    Firebase.firestore()
      .collection('shops')
      .where(`id`, '==', shopID),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const [settings, settingsLoading, settingsError] = useDocumentData(
    Firebase.firestore().doc(`app/${REACT_APP_SETTINGS_DOC_ID}`),
    {
      snapshotListenOptions: { includeMetadataChanges: true },
    }
  );

  const paymentsMethodsList = useAsync(async () => {
    if (user.stripeCustomerId) {
      const response = await authenticatedRequest(
        'get',
        `${API.stripe.listPaymentMethods}?userID=${user.id}`
      );

      if (!!response.data && !!response.data.data) {
        return response.data.data;
      }
    }

    return [];
  }, []);

  const deliveryData = useAsync(async () => {
    if (!basketContainer.isEmpty && !!selectedAddress) {
      const response = await computeDeliveryFees({
        shopAddress: shop.address,
        clientAddress: selectedAddress,
      });

      if (!!response.data) {
        return response.data;
      }
    }

    return [];
  }, [selectedAddress]);

  const validateDiscount = React.useCallback(async () => {
    const fetchedDiscount = await Firebase.firestore()
      .collection('discounts')
      .doc(discount.id)
      .get();

    if (fetchedDiscount.exists) {
      const { updatedAt } = fetchedDiscount.data();

      if (
        !updatedAt?.seconds ||
        updatedAt.seconds === discount.updatedAt['_seconds']
      ) {
        setDiscountValidation({
          isValid: true,
          discount: fetchedDiscount.data(),
        });
        return true;
      }
    }

    setDiscountValidation({
      isValid: false,
      discount,
    });
    basketContainer.updateDiscount(null);
    toaster.warning('Code promo', {
      description:
        'La code promo a expiré, veuillez le ressaisir pour bénéficier de la réduction.',
    });

    return false;
  }, [discount, basketContainer]);

  React.useEffect(() => {
    if (
      !discontValidation.isValid &&
      !!discount &&
      (!discontValidation.discount ||
        discontValidation.discount.id !== discount.id)
    ) {
      validateDiscount();
    }
  }, [discount, discontValidation, basketContainer, validateDiscount]);

  const estimatedDeliveryTimes = computeEstimatedDeliveryTimes(
    deliveryData,
    itemsList
  );
  const currentShop =
    !shopValueLoading && !!shopValue.docs[0] ? shopValue.docs[0].data() : null;
  const currentShopIsAvailable = !currentShop ? false : currentShop.available;
  const canOrder = !settingsLoading && settings.open;
  const appFeesWithDiscount = computeAppFees(discount, APP_FEES);

  return {
    shopValue,
    shopValueLoading,
    shopValueError,
    settings,
    settingsLoading,
    settingsError,
    paymentsMethodsList,
    deliveryData,
    currentShopIsAvailable,
    canOrder,
    estimatedDeliveryTimes,
    currentShop,
    appFeesWithDiscount,
    validateDiscount,
  };
};

export default useNewOrder;

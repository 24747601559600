import React from 'react';
import { useFormik } from 'formik';
import parsePhoneNumber from 'libphonenumber-js';

import { Button, Heading, Input, PhoneInput, Text } from '../../../styleguide';
import { SignupFormPolicyText } from '../../../styles/signup';
import { FormWrapper, ButtonsWrapper } from '../../../styles/form';
import { signupRusherDetails } from '../../../forms';

const parsePhoneBeforeSubmit = phone => {
  if (!phone) {
    return '';
  }
  return parsePhoneNumber(phone, 'FR').format('INTERNATIONAL');
};

const InfoForm = props => {
  const { onRusherUpdate, onNext } = props;

  const formik = useFormik({
    initialValues: {
      phone: '',
      baseCity: '',
    },
    validationSchema: signupRusherDetails,
    onSubmit: values => {
      const { baseCity, phone } = values;
      onRusherUpdate({ phone: parsePhoneBeforeSubmit(phone), baseCity });
      onNext();
    },
  });

  const canSubmit = !!formik.values.phone && !!formik.values.baseCity;

  return (
    <>
      <Heading size={900} centered>
        Numéro et ville de départ
      </Heading>
      <FormWrapper onSubmit={formik.handleSubmit}>
        <PhoneInput
          required
          placeholder="06 01 02 03 04"
          value={formik.values.phone}
          onChange={formik.handleChange}
          error={formik.touched.phone && Boolean(formik.errors.phone)}
          helperText={formik.touched.phone && formik.errors.phone}
        />

        <Input
          name="baseCity"
          label="Ville de base"
          required
          placeholder="Chamonix Mont-Blanc"
          value={formik.values.baseCity}
          onChange={formik.handleChange}
          error={formik.touched.baseCity && Boolean(formik.errors.baseCity)}
          helperText={formik.touched.baseCity && formik.errors.baseCity}
          wrapperProps={{
            style: {
              maxWidth: '316px',
            },
          }}
        />

        <SignupFormPolicyText>
          <Text size={300} muted>
            La ville de base nous permettra de vous suggérer les meilleures
            commandes en fonction de votre localité de départ.
          </Text>
        </SignupFormPolicyText>

        <ButtonsWrapper>
          <Button type="submit" disabled={!canSubmit}>
            Continuer
          </Button>
        </ButtonsWrapper>
      </FormWrapper>
    </>
  );
};

export default InfoForm;

import React from 'react';

import { Heading, Text } from '../../../styleguide';
import {
  ContentWrapper,
  EmptyPlaceholder,
  EmptyPicture,
  EmptyPlaceholderContent,
  EmptyPlaceholderDescription,
} from '../../../styles/common';
import EmptyBasket from '../../../assets/images/empty.svg';

const EmptyTab = props => {
  const { title, description } = props;

  const pictureSrc = EmptyBasket;

  return (
    <ContentWrapper>
      <EmptyPlaceholder>
        <EmptyPicture src={pictureSrc} />
        <EmptyPlaceholderContent>
          <Heading size={800} centered>
            {title}
          </Heading>
          <EmptyPlaceholderDescription>
            <Text muted>{description}</Text>
          </EmptyPlaceholderDescription>
        </EmptyPlaceholderContent>
      </EmptyPlaceholder>
    </ContentWrapper>
  );
};

export default EmptyTab;

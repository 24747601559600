import React from 'react';
import { useHistory } from 'react-router-dom';

import {
  MainWrapper,
  IllustationWrapper,
  Illustration,
  HeaderTitle,
  HeaderDescription,
  ErrorContentWrapper,
} from '../../styles/error';
import { Heading, Container, Button, Text } from '../../styleguide';
import { logOut } from '../../actions/login';
import { SPACING, colors } from '../../styles/constants';
import { PageWithoutContainer } from '../common';
import ErrorIllustration from '../../assets/images/error.svg';

const ErrorPage = ({ error }) => {
  console.error('ErrorPage', { error });
  const history = useHistory();

  const onClickRedirect = () => {
    if (!error) {
      history.push('/');
      return;
    }

    history.push('/');
    window.location.reload();
    return;
  };

  return (
    <PageWithoutContainer
      name="Erreur"
      navbarLeftChild={'disabled'}
      navbarRightChild={'disabled'}
    >
      <Container>
        <MainWrapper>
          <IllustationWrapper>
            <Illustration src={ErrorIllustration} lazy />
          </IllustationWrapper>

          <HeaderTitle>
            <Heading size={900} styles={{ textAlign: 'center' }}>
              Oups ...{' '}
              <span role="img" aria-label="emoji">
                🚧
              </span>
            </Heading>
          </HeaderTitle>

          <HeaderDescription>
            <Text muted size={500}>
              Désolé, une erreur c'est produite ou alors la page que vous
              cherchez n'existe pas. Retournez à la page d'acceuil et tout
              devrai rentrer dans l'ordre.
            </Text>

            <Button
              styles={{
                marginTop: SPACING[300],
                backgroundColor: colors.green,
              }}
              onClick={onClickRedirect}
            >
              Retour à l'acceuil
            </Button>
            <Button
              styles={{
                marginTop: SPACING[300],
              }}
              onClick={logOut}
              minimal
            >
              Déconnexion
            </Button>
          </HeaderDescription>

          {error && error.message ? (
            <ErrorContentWrapper>
              <Text size={400}>{error.message}</Text>
            </ErrorContentWrapper>
          ) : null}
        </MainWrapper>
      </Container>
    </PageWithoutContainer>
  );
};

export default ErrorPage;
